<template>
    <div>

        <!-- tabs title -->
		<ul 
			class="nav nav-tabs" 
			id="otrosProductos" 
			role="tablist">

			<li 
				class="nav-item" 
				role="presentation">

				<button 
					class="nav-link active" 
					id="home-tab" 
					data-bs-toggle="tab" 
					data-bs-target="#home" 
					type="button" 
					role="tab" 
					aria-controls="home" 
					aria-selected="true">Ahorro para el retiro</button>
			</li>
		</ul>

        <!-- tabs content -->
		<div 
			class="tab-content" 
			id="otrosProductosContent">

			<div 
				class="tab-pane fade show active" 
				id="home" 
				role="tabpanel" 
				aria-labelledby="home-tab">

				<div class="tab-body">

					<div class="flex flex-col">

						<span class="productos-calculadoras">Calculadoras disponibles</span>

						<div class="flex py-2 px-10 space-x-6">
							
							<!-- skandia -->
							<button
								@click="mostrarCalculadora(listaCalculadoras.SKANDIA)" 
								class="productos-calculadoras-opcion">
								<img
									class=""
									src="@/assets/images/otrosProductos/skandiaLogo.png"
									alt="Skandia Logo"
								/>
							</button>

							<!-- otro: asignar asi otras calculadores -->
							<!-- <div class="productos-calculadoras-opcion">
								<img
									class=""
									src="@/assets/images/otrosProductos/otroLogo.png"
									alt="Otro Logo"
								/>
							</div> -->

						</div>
						
					</div>

					<div v-if="calculadoraActual === 1">
						<Skandia />
					</div>

				</div>

			</div>
		</div>

    </div>
</template>

<script>
import Skandia from '@/views/Caso/OtrosProductos/Skandia.vue';

export default {
	components: {
		Skandia,
	},
	data() {
		return  {
			calculadoraActual: null,
			listaCalculadoras: { // constante para elegir calculadora
				SKANDIA: 1,
				OTRO: 2,
			}
		}
	},
	watch: {
		
	},
	computed: {

	},
	methods: {
		mostrarCalculadora(seleccion) {
			this.calculadoraActual = seleccion;
			// console.log( 'CALC VALUE', seleccion );
		}
	},
	created() {
	},
}
</script>

<style scoped>
.productos-calculadoras {
	font-weight: 600;
	font-size: 14px;
	color: #00368C;
	padding: .5rem 0.3rem;
}

.productos-calculadoras-opcion {
	border: none;
}

.productos-calculadoras-opcion img {
	border: 1px solid #8E8E8E;
	border-radius: 5px;
	background-color: #FFFFFF;
	padding: 5px;
    height: 100px;
}

.tab-body {
	border-top-right-radius: 10px;
}

.nav-item {
	border-bottom: 1px solid #dee2e6;
}

.nav-tabs {
	border-bottom: none;
}
</style>