<template>

              <vx-card class="grid-view-item overflow-hidden" v-on="$listeners">
                  <template slot="no-body">

                      <!-- ITEM IMAGE -->
                      <slot name="action-favs" />

                      <div class="item-img-container bg-white h-64 flex items-center justify-center mb-4 cursor-pointer">
                        <img :src="'https://cotizador.socasesores.com/uploads/'+ favoritos.Logo" :alt="favoritos.Banco" class="grid-view-img p-4">
                      </div>
                      <div class="item-details px-4">

                          <!-- RATING & PRICE -->
                          <div class="flex justify-between items-center">
                            <!-- <div class="text-warning flex self-end border border-solid border-warning py-1 px-2 rounded" v-if="favoritos.vctPromociones != ''">
                              <vx-tooltip color="primary" :text="promos" v-for="promos in favoritos.vctPromociones">
                                <feather-icon icon="StarIcon" svgClasses="h-4 w-4" />
                              </vx-tooltip>
                            </div> -->
                              <h6 class="font-bold">Mensualidad ${{ favoritos.Mensualidad }}</h6>
                          </div>

                          <!-- TITLE & DESCRIPTION -->
                          <div class="my-4">
                            <h6 class="item-name font-semibold mb-1 hover:text-primary cursor-pointer">{{ favoritos.Producto }}</h6>
                            <p class="span-placeholder mb-4">Pago <span class="font-semibold cursor-pointer">{{ favoritos.Esquema==2 ? 'Fijo' : 'Creciente' }}</span> durante <span class="font-semibold cursor-pointer">{{ favoritos.Plazo/12 }} años</span></p>
                            <p class="item-description span-placeholder">Tasa {{ favoritos.Tasa }}%</p>
                            <p class="item-description span-placeholder">Cat {{ favoritos.Cat }}%</p>
                            <p class="item-description span-placeholder">Ingreso mínimo ${{ favoritos.IngresoMinimo }}</p>
                            <p class="item-description span-placeholder">Pago Total ${{ favoritos.PagoTotal }}</p>
                            <p class="item-description span-placeholder">Aforo {{ favoritos.Aforo }}%</p>
                            <p class="item-description span-placeholder">Mensualidad Inicial ${{ favoritos.MensualidadInicial }}</p>

                              <!-- <h6 class="truncate font-semibold mb-1 hover:text-primary cursor-pointer" @click="navigate_to_detail_view">{{ item.name }}</h6>
                              <p class="item-description truncate span-placeholder">{{ item.description }}</p> -->
                          </div>
                          <slot name="action-buttons" />
                      </div>
                      <!-- SLOT: ACTION BUTTONS -->
                  </template>
              </vx-card>
</template>

<script>
export default {

  props: {
    favoritos: {
      type: Object,
      required: true
    }
  },
  data(){
    return{
      key:'',
      id:'',
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
  },
  methods: {
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    quitarFavorito(item){
      let objRequestFavoritoCaso = {
        strApiKey: this.key,
        strMetodo: 'NoFavorito',
        intVariante: item.Id,
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestFavoritoCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
            this.dameCaso()
            this.$vs.notify({
              title:`Eliminado exitosamente ${item.Id}`,
              text:`${item.Producto}`,
              position:'top-right',
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoAdeudos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let cotizacion=response.data.objContenido;
            this.favoritos=cotizacion.Favoritos
            for (var i = 0; i < this.favoritos.length; i++) {
              this.favoritos[i].MensualidadPromedio= this.favoritos[i].MensualidadPromedio.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.favoritos[i].Cat= this.favoritos[i].Cat.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.favoritos[i].MejoraMensualidad= this.favoritos[i].MejoraMensualidad.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.favoritos[i].AhorroTotal= this.favoritos[i].AhorroTotal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.favoritos[i].MensualidadInicial= this.favoritos[i].MensualidadInicial.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.favoritos[i].IngresoMinimo= this.favoritos[i].IngresoMinimo.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.favoritos[i].PagoTotal= this.favoritos[i].PagoTotal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoAdeudos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
#ecommerce-wishlist-demo {
    .item-view-primary-action-btn {
        color: #2c2c2c !important;
        background-color: #f6f6f6;
        min-width: 50%;
    }

    .item-view-secondary-action-btn {
        min-width: 50%;
    }
}
</style>
