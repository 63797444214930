import { render, staticRenderFns } from "./DocumentosDigitales.vue?vue&type=template&id=189c6442&scoped=true&"
import script from "./DocumentosDigitales.vue?vue&type=script&lang=js&"
export * from "./DocumentosDigitales.vue?vue&type=script&lang=js&"
import style0 from "./DocumentosDigitales.vue?vue&type=style&index=0&id=189c6442&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "189c6442",
  null
  
)

export default component.exports