export const municipios = [
    [
      //AGUASCALIENTES
      {Id:'1', Nombre: "AGUASCALIENTES"},
      {Id:'2', Nombre: "ASIENTOS"},
      {Id:'3', Nombre: "CALVILLO"},
      {Id:'4', Nombre: "COSIO"},
      {Id:'10', Nombre: "EL LLANO"},
      {Id:'5', Nombre: "JESUS MARIA"},
      {Id:'6', Nombre: "PABELLON DE ARTEAGA"},
      {Id:'7', Nombre: "RINCON DE ROMOS"},
      {Id:'11', Nombre: "SAN FRANCISCO DE LOS ROMO"},
      {Id:'8', Nombre: "SAN JOSE DE GRACIA"},
      {Id:'9', Nombre: "TEPEZALA"},
    ],
    [
      {Id:'1', Nombre: "ENSENADA"},
      {Id:'2', Nombre: "MEXICALI"},
      {Id:'5', Nombre: "PLAYAS DE ROSARITO"},
      {Id:'3', Nombre: "TECATE"},
      {Id:'4', Nombre: "TIJUANA"},

    ],
    [
      //BAJA CALIFORNIA SUR
          {Id:'1', Nombre: "COMONDU"},
          {Id:'3', Nombre: "LA PAZ"},
          {Id:'9', Nombre: "LORETO"},
          {Id:'8', Nombre: "LOS CABOS"},
          {Id:'2', Nombre: "MULEGE"},
    ],
    [
          //CAMPECHE
          {Id:'10', Nombre: "CALAKMUL"},
          {Id:'1', Nombre: "CALKINI"},
          {Id:'2', Nombre: "CAMPECHE"},
          {Id:'11', Nombre: "CANDELARIA"},
          {Id:'3', Nombre: "CARMEN"},
          {Id:'4', Nombre: "CHAMPOTON"},
          {Id:'9', Nombre: "ESCARCEGA"},
          {Id:'5', Nombre: "HECELCHAKAN"},
          {Id:'6', Nombre: "HOPELCHEN"},
          {Id:'7', Nombre: "PALIZADA"},
          {Id:'8', Nombre: "TENABO"},
    ],
    [
          //CHIAPAS
        {Id:'1', Nombre: "ACACOYAGUA"},
        {Id:'2', Nombre: "ACALA"},
        {Id:'3', Nombre: "ACAPETAHUA"},
        {Id:'113', Nombre: "ALDAMA"},
        {Id:'4', Nombre: "ALTAMIRANO"},
        {Id:'5', Nombre: "AMATAN"},
        {Id:'6', Nombre: "AMATENANGO DE LA FRONTERA"},
        {Id:'7', Nombre: "AMATENANGO DEL VALLE"},
        {Id:'8', Nombre: "ANGEL ALBINO CORZO"},
        {Id:'9', Nombre: "ARRIAGA"},
        {Id:'10', Nombre: "BEJUCAL DE OCAMPO"},
        {Id:'11', Nombre: "BELLA VISTA"},
        {Id:'114', Nombre: "BENEMERITO DE LAS AMERICAS"},
        {Id:'12', Nombre: "BERRIOZABAL"},
        {Id:'13', Nombre: "BOCHIL"},
        {Id:'15', Nombre: "CACAHOATAN"},
        {Id:'16', Nombre: "CATAZAJA"},
        {Id:'22', Nombre: "CHALCHIHUITAN"},
        {Id:'23', Nombre: "CHAMULA"},
        {Id:'24', Nombre: "CHANAL"},
        {Id:'25', Nombre: "CHAPULTENANGO"},
        {Id:'26', Nombre: "CHENALHO"},
        {Id:'27', Nombre: "CHIAPA DE CORZO"},
        {Id:'28', Nombre: "CHIAPILLA"},
        {Id:'29', Nombre: "CHICOASEN"},
        {Id:'30', Nombre: "CHICOMUSELO"},
        {Id:'31', Nombre: "CHILON"},
        {Id:'17', Nombre: "CINTALAPA"},
        {Id:'18', Nombre: "COAPILLA"},
        {Id:'19', Nombre: "COMITAN DE DOMINGUEZ"},
        {Id:'21', Nombre: "COPAINALA"},
        {Id:'14', Nombre: "EL BOSQUE"},
        {Id:'70', Nombre: "EL PORVENIR"},
        {Id:'32', Nombre: "ESCUINTLA"},
        {Id:'33', Nombre: "FRANCISCO LEON"},
        {Id:'34', Nombre: "FRONTERA COMALAPA"},
        {Id:'35', Nombre: "FRONTERA HIDALGO"},
        {Id:'37', Nombre: "HUEHUETAN"},
        {Id:'39', Nombre: "HUITIUPAN"},
        {Id:'38', Nombre: "HUIXTAN"},
        {Id:'40', Nombre: "HUIXTLA"},
        {Id:'42', Nombre: "IXHUATAN"},
        {Id:'43', Nombre: "IXTACOMITAN"},
        {Id:'44', Nombre: "IXTAPA"},
        {Id:'45', Nombre: "IXTAPANGAJOYA"},
        {Id:'46', Nombre: "JIQUIPILAS"},
        {Id:'47', Nombre: "JITOTOL"},
        {Id:'48', Nombre: "JUAREZ"},
        {Id:'20', Nombre: "LA CONCORDIA"},
        {Id:'36', Nombre: "LA GRANDEZA"},
        {Id:'41', Nombre: "LA INDEPENDENCIA"},
        {Id:'50', Nombre: "LA LIBERTAD"},
        {Id:'99', Nombre: "LA TRINITARIA"},
        {Id:'49', Nombre: "LARRAINZAR"},
        {Id:'52', Nombre: "LAS MARGARITAS"},
        {Id:'75', Nombre: "LAS ROSAS"},
        {Id:'51', Nombre: "MAPASTEPEC"},
        {Id:'115', Nombre: "MARAVILLA TENEJAPA"},
        {Id:'116', Nombre: "MARQUES DE COMILLAS"},
        {Id:'53', Nombre: "MAZAPA DE MADERO"},
        {Id:'54', Nombre: "MAZATAN"},
        {Id:'55', Nombre: "METAPA"},
        {Id:'56', Nombre: "MITONTIC"},
        {Id:'117', Nombre: "MONTECRISTO DE GUERRERO"},
        {Id:'57', Nombre: "MOTOZINTLA"},
        {Id:'58', Nombre: "NICOLAS RUIZ"},
        {Id:'59', Nombre: "OCOSINGO"},
        {Id:'60', Nombre: "OCOTEPEC"},
        {Id:'61', Nombre: "OCOZOCOAUTLA DE ESPINOSA"},
        {Id:'62', Nombre: "OSTUACAN"},
        {Id:'63', Nombre: "OSUMACINTA"},
        {Id:'64', Nombre: "OXCHUC"},
        {Id:'65', Nombre: "PALENQUE"},
        {Id:'66', Nombre: "PANTELHO"},
        {Id:'67', Nombre: "PANTEPEC"},
        {Id:'68', Nombre: "PICHUCALCO"},
        {Id:'69', Nombre: "PIJIJIAPAN"},
        {Id:'72', Nombre: "PUEBLO NUEVO SOLISTAHUACAN"},
        {Id:'73', Nombre: "RAYON"},
        {Id:'74', Nombre: "REFORMA"},
        {Id:'76', Nombre: "SABANILLA"},
        {Id:'77', Nombre: "SALTO DE AGUA"},
        {Id:'118', Nombre: "SAN ANDRES DURAZNAL"},
        {Id:'78', Nombre: "SAN CRISTOBAL DE LAS CASAS"},
        {Id:'79', Nombre: "SAN FERNANDO"},
        {Id:'112', Nombre: "SAN JUAN CANCUC"},
        {Id:'110', Nombre: "SAN LUCAS"},
        {Id:'119', Nombre: "SANTIAGO EL PINAR"},
        {Id:'80', Nombre: "SILTEPEC"},
        {Id:'81', Nombre: "SIMOJOVEL"},
        {Id:'82', Nombre: "SITALA"},
        {Id:'83', Nombre: "SOCOLTENANGO"},
        {Id:'84', Nombre: "SOLOSUCHIAPA"},
        {Id:'85', Nombre: "SOYALO"},
        {Id:'86', Nombre: "SUCHIAPA"},
        {Id:'87', Nombre: "SUCHIATE"},
        {Id:'88', Nombre: "SUNUAPA"},
        {Id:'89', Nombre: "TAPACHULA"},
        {Id:'90', Nombre: "TAPALAPA"},
        {Id:'91', Nombre: "TAPILULA"},
        {Id:'92', Nombre: "TECPATAN"},
        {Id:'93', Nombre: "TENEJAPA"},
        {Id:'94', Nombre: "TEOPISCA"},
        {Id:'96', Nombre: "TILA"},
        {Id:'97', Nombre: "TONALA"},
        {Id:'98', Nombre: "TOTOLAPA"},
        {Id:'100', Nombre: "TUMBALA"},
        {Id:'102', Nombre: "TUXTLA CHICO"},
        {Id:'101', Nombre: "TUXTLA GUTIERREZ"},
        {Id:'103', Nombre: "TUZANTAN"},
        {Id:'104', Nombre: "TZIMOL"},
        {Id:'105', Nombre: "UNION JUAREZ"},
        {Id:'106', Nombre: "VENUSTIANO CARRANZA"},
        {Id:'71', Nombre: "VILLA COMALTITLAN"},
        {Id:'107', Nombre: "VILLA CORZO"},
        {Id:'108', Nombre: "VILLAFLORES"},
        {Id:'109', Nombre: "YAJALON"},
        {Id:'111', Nombre: "ZINACANTAN"},
    ],
    [
      //CHIHUAHUA
      {Id:'1', Nombre: "AHUMADA"},
      {Id:'2', Nombre: "ALDAMA"},
      {Id:'3', Nombre: "ALLENDE"},
      {Id:'4', Nombre: "AQUILES SERDAN"},
      {Id:'5', Nombre: "ASCENSION"},
      {Id:'6', Nombre: "BACHINIVA"},
      {Id:'7', Nombre: "BALLEZA"},
      {Id:'8', Nombre: "BATOPILAS"},
      {Id:'9', Nombre: "BOCOYNA"},
      {Id:'10', Nombre: "BUENAVENTURA"},
      {Id:'11', Nombre: "CAMARGO"},
      {Id:'12', Nombre: "CARICHI"},
      {Id:'13', Nombre: "CASAS GRANDES"},
      {Id:'19', Nombre: "CHIHUAHUA"},
      {Id:'20', Nombre: "CHINIPAS"},
      {Id:'14', Nombre: "CORONADO"},
      {Id:'15', Nombre: "COYAME DEL SOTOL"},
      {Id:'17', Nombre: "CUAUHTEMOC"},
      {Id:'18', Nombre: "CUSIHUIRIACHI"},
      {Id:'21', Nombre: "DELICIAS"},
      {Id:'22', Nombre: "DR. BELISARIO DOMINGUEZ"},
      {Id:'64', Nombre: "EL TULE"},
      {Id:'23', Nombre: "GALEANA"},
      {Id:'25', Nombre: "GOMEZ FARIAS"},
      {Id:'26', Nombre: "GRAN MORELOS"},
      {Id:'27', Nombre: "GUACHOCHI"},
      {Id:'28', Nombre: "GUADALUPE"},
      {Id:'29', Nombre: "GUADALUPE Y CALVO"},
      {Id:'30', Nombre: "GUAZAPARES"},
      {Id:'31', Nombre: "GUERRERO"},
      {Id:'32', Nombre: "HIDALGO DEL PARRAL"},
      {Id:'33', Nombre: "HUEJOTITAN"},
      {Id:'34', Nombre: "IGNACIO ZARAGOZA"},
      {Id:'35', Nombre: "JANOS"},
      {Id:'36', Nombre: "JIMENEZ"},
      {Id:'37', Nombre: "JUAREZ"},
      {Id:'38', Nombre: "JULIMES"},
      {Id:'16', Nombre: "LA CRUZ"},
      {Id:'39', Nombre: "LOPEZ"},
      {Id:'40', Nombre: "MADERA"},
      {Id:'41', Nombre: "MAGUARICHI"},
      {Id:'42', Nombre: "MANUEL BENAVIDES"},
      {Id:'43', Nombre: "MATACHI"},
      {Id:'44', Nombre: "MATAMOROS"},
      {Id:'45', Nombre: "MEOQUI"},
      {Id:'46', Nombre: "MORELOS"},
      {Id:'47', Nombre: "MORIS"},
      {Id:'48', Nombre: "NAMIQUIPA"},
      {Id:'49', Nombre: "NONOAVA"},
      {Id:'50', Nombre: "NUEVO CASAS GRANDES"},
      {Id:'51', Nombre: "OCAMPO"},
      {Id:'52', Nombre: "OJINAGA"},
      {Id:'53', Nombre: "PRAXEDIS G. GUERRERO"},
      {Id:'54', Nombre: "RIVA PALACIO"},
      {Id:'55', Nombre: "ROSALES"},
      {Id:'56', Nombre: "ROSARIO"},
      {Id:'57', Nombre: "SAN FRANCISCO DE BORJA"},
      {Id:'58', Nombre: "SAN FRANCISCO DE CONCHOS"},
      {Id:'59', Nombre: "SAN FRANCISCO DEL ORO"},
      {Id:'60', Nombre: "SANTA BARBARA"},
      {Id:'24', Nombre: "SANTA ISABEL"},
      {Id:'61', Nombre: "SATEVO"},
      {Id:'62', Nombre: "SAUCILLO"},
      {Id:'63', Nombre: "TEMOSACHIC"},
      {Id:'65', Nombre: "URIQUE"},
      {Id:'66', Nombre: "URUACHI"},
      {Id:'67', Nombre: "VALLE DE ZARAGOZA"},
    ],
    [
      //COAHUILA DE ZARAGOZA
      {Id:'1', Nombre: "ABASOLO"},
      {Id:'2', Nombre: "ACUNA"},
      {Id:'3', Nombre: "ALLENDE"},
      {Id:'4', Nombre: "ARTEAGA"},
      {Id:'5', Nombre: "CANDELA"},
      {Id:'6', Nombre: "CASTANOS"},
      {Id:'7', Nombre: "CUATRO CIENEGAS"},
      {Id:'8', Nombre: "ESCOBEDO"},
      {Id:'9', Nombre: "FRANCISCO I. MADERO"},
      {Id:'10', Nombre: "FRONTERA"},
      {Id:'11', Nombre: "GENERAL CEPEDA"},
      {Id:'12', Nombre: "GUERRERO"},
      {Id:'13', Nombre: "HIDALGO"},
      {Id:'14', Nombre: "JIMENEZ"},
      {Id:'15', Nombre: "JUAREZ"},
      {Id:'16', Nombre: "LAMADRID"},
      {Id:'17', Nombre: "MATAMOROS"},
      {Id:'18', Nombre: "MONCLOVA"},
      {Id:'19', Nombre: "MORELOS"},
      {Id:'20', Nombre: "MUZQUIZ"},
      {Id:'21', Nombre: "NADADORES"},
      {Id:'22', Nombre: "NAVA"},
      {Id:'23', Nombre: "OCAMPO"},
      {Id:'24', Nombre: "PARRAS"},
      {Id:'25', Nombre: "PIEDRAS NEGRAS"},
      {Id:'26', Nombre: "PROGRESO"},
      {Id:'27', Nombre: "RAMOS ARIZPE"},
      {Id:'28', Nombre: "SABINAS"},
      {Id:'29', Nombre: "SACRAMENTO"},
      {Id:'30', Nombre: "SALTILLO"},
      {Id:'31', Nombre: "SAN BUENAVENTURA"},
      {Id:'32', Nombre: "SAN JUAN DE SABINAS"},
      {Id:'33', Nombre: "SAN PEDRO"},
      {Id:'34', Nombre: "SIERRA MOJADA"},
      {Id:'35', Nombre: "TORREON"},
      {Id:'36', Nombre: "VIESCA"},
      {Id:'37', Nombre: "VILLA UNION"},
      {Id:'38', Nombre: "ZARAGOZA"},
    ],
    [
      //COLIMA
      {Id:'1', Nombre: "ARMERIA"},
      {Id:'2', Nombre: "COLIMA"},
      {Id:'3', Nombre: "COMALA"},
      {Id:'4', Nombre: "COQUIMATLAN"},
      {Id:'5', Nombre: "CUAUHTEMOC"},
      {Id:'6', Nombre: "IXTLAHUACAN"},
      {Id:'7', Nombre: "MANZANILLO"},
      {Id:'8', Nombre: "MINATITLAN"},
      {Id:'9', Nombre: "TECOMAN"},
      {Id:'10', Nombre: "VILLA DE ALVAREZ"},
    ],
    [
      //DISTRITO FEDERAL
      {Id:'10', Nombre: "ALVARO OBREGON"},
      {Id:'2', Nombre: "AZCAPOTZALCO"},
      {Id:'14', Nombre: "BENITO JUAREZ"},
      {Id:'3', Nombre: "COYOACAN"},
      {Id:'4', Nombre: "CUAJIMALPA DE MORELOS"},
      {Id:'15', Nombre: "CUAUHTEMOC"},
      {Id:'5', Nombre: "GUSTAVO A. MADERO"},
      {Id:'6', Nombre: "IZTACALCO"},
      {Id:'7', Nombre: "IZTAPALAPA"},
      {Id:'8', Nombre: "LA MAGDALENA CONTRERAS"},
      {Id:'16', Nombre: "MIGUEL HIDALGO"},
      {Id:'9', Nombre: "MILPA ALTA"},
      {Id:'11', Nombre: "TLAHUAC"},
      {Id:'12', Nombre: "TLALPAN"},
      {Id:'17', Nombre: "VENUSTIANO CARRANZA"},
      {Id:'13', Nombre: "XOCHIMILCO"},
    ],
    [
      //DURANGO
      {Id:'1', Nombre: "CANATLAN"},
      {Id:'2', Nombre: "CANELAS"},
      {Id:'3', Nombre: "CONETO DE COMONFORT"},
      {Id:'4', Nombre: "CUENCAME"},
      {Id:'5', Nombre: "DURANGO"},
      {Id:'18', Nombre: "EL ORO"},
      {Id:'6', Nombre: "GENERAL SIMON BOLIVAR"},
      {Id:'7', Nombre: "GOMEZ PALACIO"},
      {Id:'8', Nombre: "GUADALUPE VICTORIA"},
      {Id:'9', Nombre: "GUANACEVI"},
      {Id:'10', Nombre: "HIDALGO"},
      {Id:'11', Nombre: "INDE"},
      {Id:'12', Nombre: "LERDO"},
      {Id:'13', Nombre: "MAPIMI"},
      {Id:'14', Nombre: "MEZQUITAL"},
      {Id:'15', Nombre: "NAZAS"},
      {Id:'16', Nombre: "NOMBRE DE DIOS"},
      {Id:'39', Nombre: "NUEVO IDEAL"},
      {Id:'17', Nombre: "OCAMPO"},
      {Id:'19', Nombre: "OTAEZ"},
      {Id:'20', Nombre: "PANUCO DE CORONADO"},
      {Id:'21', Nombre: "PENON BLANCO"},
      {Id:'22', Nombre: "POANAS"},
      {Id:'23', Nombre: "PUEBLO NUEVO"},
      {Id:'24', Nombre: "RODEO"},
      {Id:'25', Nombre: "SAN BERNARDO"},
      {Id:'26', Nombre: "SAN DIMAS"},
      {Id:'27', Nombre: "SAN JUAN DE GUADALUPE"},
      {Id:'28', Nombre: "SAN JUAN DEL RIO"},
      {Id:'29', Nombre: "SAN LUIS DEL CORDERO"},
      {Id:'30', Nombre: "SAN PEDRO DEL GALLO"},
      {Id:'31', Nombre: "SANTA CLARA"},
      {Id:'32', Nombre: "SANTIAGO PAPASQUIARO"},
      {Id:'33', Nombre: "SUCHIL"},
      {Id:'34', Nombre: "TAMAZULA"},
      {Id:'35', Nombre: "TEPEHUANES"},
      {Id:'36', Nombre: "TLAHUALILO"},
      {Id:'37', Nombre: "TOPIA"},
      {Id:'38', Nombre: "VICENTE GUERRERO"},
    ],
    [
      //MEXICO
      {Id:'1', Nombre: "ACAMBAY DE RUIZ CASTANEDA"},
      {Id:'2', Nombre: "ACOLMAN"},
      {Id:'3', Nombre: "ACULCO"},
      {Id:'4', Nombre: "ALMOLOYA DE ALQUISIRAS"},
      {Id:'5', Nombre: "ALMOLOYA DE JUAREZ"},
      {Id:'6', Nombre: "ALMOLOYA DEL RIO"},
      {Id:'7', Nombre: "AMANALCO"},
      {Id:'8', Nombre: "AMATEPEC"},
      {Id:'9', Nombre: "AMECAMECA"},
      {Id:'10', Nombre: "APAXCO"},
      {Id:'11', Nombre: "ATENCO"},
      {Id:'12', Nombre: "ATIZAPAN"},
      {Id:'13', Nombre: "ATIZAPAN DE ZARAGOZA"},
      {Id:'14', Nombre: "ATLACOMULCO"},
      {Id:'15', Nombre: "ATLAUTLA"},
      {Id:'16', Nombre: "AXAPUSCO"},
      {Id:'17', Nombre: "AYAPANGO"},
      {Id:'18', Nombre: "CALIMAYA"},
      {Id:'19', Nombre: "CAPULHUAC"},
      {Id:'25', Nombre: "CHALCO"},
      {Id:'26', Nombre: "CHAPA DE MOTA"},
      {Id:'27', Nombre: "CHAPULTEPEC"},
      {Id:'28', Nombre: "CHIAUTLA"},
      {Id:'29', Nombre: "CHICOLOAPAN"},
      {Id:'30', Nombre: "CHICONCUAC"},
      {Id:'31', Nombre: "CHIMALHUACAN"},
      {Id:'20', Nombre: "COACALCO DE BERRIOZABAL"},
      {Id:'21', Nombre: "COATEPEC HARINAS"},
      {Id:'22', Nombre: "COCOTITLAN"},
      {Id:'23', Nombre: "COYOTEPEC"},
      {Id:'24', Nombre: "CUAUTITLAN"},
      {Id:'121', Nombre: "CUAUTITLAN IZCALLI"},
      {Id:'32', Nombre: "DONATO GUERRA"},
      {Id:'33', Nombre: "ECATEPEC DE MORELOS"},
      {Id:'34', Nombre: "ECATZINGO"},
      {Id:'64', Nombre: "EL ORO"},
      {Id:'35', Nombre: "HUEHUETOCA"},
      {Id:'36', Nombre: "HUEYPOXTLA"},
      {Id:'37', Nombre: "HUIXQUILUCAN"},
      {Id:'38', Nombre: "ISIDRO FABELA"},
      {Id:'39', Nombre: "IXTAPALUCA"},
      {Id:'40', Nombre: "IXTAPAN DE LA SAL"},
      {Id:'41', Nombre: "IXTAPAN DEL ORO"},
      {Id:'42', Nombre: "IXTLAHUACA"},
      {Id:'44', Nombre: "JALTENCO"},
      {Id:'45', Nombre: "JILOTEPEC"},
      {Id:'46', Nombre: "JILOTZINGO"},
      {Id:'47', Nombre: "JIQUIPILCO"},
      {Id:'48', Nombre: "JOCOTITLAN"},
      {Id:'49', Nombre: "JOQUICINGO"},
      {Id:'50', Nombre: "JUCHITEPEC"},
      {Id:'70', Nombre: "LA PAZ"},
      {Id:'51', Nombre: "LERMA"},
      {Id:'123', Nombre: "LUVIANOS"},
      {Id:'52', Nombre: "MALINALCO"},
      {Id:'53', Nombre: "MELCHOR OCAMPO"},
      {Id:'54', Nombre: "METEPEC"},
      {Id:'55', Nombre: "MEXICALTZINGO"},
      {Id:'56', Nombre: "MORELOS"},
      {Id:'57', Nombre: "NAUCALPAN DE JUAREZ"},
      {Id:'59', Nombre: "NEXTLALPAN"},
      {Id:'58', Nombre: "NEZAHUALCOYOTL"},
      {Id:'60', Nombre: "NICOLAS ROMERO"},
      {Id:'61', Nombre: "NOPALTEPEC"},
      {Id:'62', Nombre: "OCOYOACAC"},
      {Id:'63', Nombre: "OCUILAN"},
      {Id:'65', Nombre: "OTUMBA"},
      {Id:'66', Nombre: "OTZOLOAPAN"},
      {Id:'67', Nombre: "OTZOLOTEPEC"},
      {Id:'68', Nombre: "OZUMBA"},
      {Id:'69', Nombre: "PAPALOTLA"},
      {Id:'71', Nombre: "POLOTITLAN"},
      {Id:'72', Nombre: "RAYON"},
      {Id:'73', Nombre: "SAN ANTONIO LA ISLA"},
      {Id:'74', Nombre: "SAN FELIPE DEL PROGRESO"},
      {Id:'124', Nombre: "SAN JOSE DEL RINCON"},
      {Id:'75', Nombre: "SAN MARTIN DE LAS PIRAMIDES"},
      {Id:'76', Nombre: "SAN MATEO ATENCO"},
      {Id:'77', Nombre: "SAN SIMON DE GUERRERO"},
      {Id:'78', Nombre: "SANTO TOMAS"},
      {Id:'79', Nombre: "SOYANIQUILPAN DE JUAREZ"},
      {Id:'80', Nombre: "SULTEPEC"},
      {Id:'81', Nombre: "TECAMAC"},
      {Id:'82', Nombre: "TEJUPILCO"},
      {Id:'83', Nombre: "TEMAMATLA"},
      {Id:'84', Nombre: "TEMASCALAPA"},
      {Id:'85', Nombre: "TEMASCALCINGO"},
      {Id:'86', Nombre: "TEMASCALTEPEC"},
      {Id:'87', Nombre: "TEMOAYA"},
      {Id:'88', Nombre: "TENANCINGO"},
      {Id:'89', Nombre: "TENANGO DEL AIRE"},
      {Id:'90', Nombre: "TENANGO DEL VALLE"},
      {Id:'91', Nombre: "TEOLOYUCAN"},
      {Id:'92', Nombre: "TEOTIHUACAN"},
      {Id:'93', Nombre: "TEPETLAOXTOC"},
      {Id:'94', Nombre: "TEPETLIXPA"},
      {Id:'95', Nombre: "TEPOTZOTLAN"},
      {Id:'96', Nombre: "TEQUIXQUIAC"},
      {Id:'97', Nombre: "TEXCALTITLAN"},
      {Id:'98', Nombre: "TEXCALYACAC"},
      {Id:'99', Nombre: "TEXCOCO"},
      {Id:'100', Nombre: "TEZOYUCA"},
      {Id:'101', Nombre: "TIANGUISTENCO"},
      {Id:'102', Nombre: "TIMILPAN"},
      {Id:'103', Nombre: "TLALMANALCO"},
      {Id:'104', Nombre: "TLALNEPANTLA DE BAZ"},
      {Id:'105', Nombre: "TLATLAYA"},
      {Id:'106', Nombre: "TOLUCA"},
      {Id:'125', Nombre: "TONANITLA"},
      {Id:'107', Nombre: "TONATICO"},
      {Id:'108', Nombre: "TULTEPEC"},
      {Id:'109', Nombre: "TULTITLAN"},
      {Id:'110', Nombre: "VALLE DE BRAVO"},
      {Id:'122', Nombre: "VALLE DE CHALCO SOLIDARIDAD"},
      {Id:'111', Nombre: "VILLA DE ALLENDE"},
      {Id:'112', Nombre: "VILLA DEL CARBON"},
      {Id:'113', Nombre: "VILLA GUERRERO"},
      {Id:'114', Nombre: "VILLA VICTORIA"},
      {Id:'43', Nombre: "XALATLACO"},
      {Id:'115', Nombre: "XONACATLAN"},
      {Id:'116', Nombre: "ZACAZONAPAN"},
      {Id:'117', Nombre: "ZACUALPAN"},
      {Id:'118', Nombre: "ZINACANTEPEC"},
      {Id:'119', Nombre: "ZUMPAHUACAN"},
      {Id:'120', Nombre: "ZUMPANGO"},
    ],
    [
      //GUANAJUATO
      {Id:'1', Nombre: "ABASOLO"},
      {Id:'2', Nombre: "ACAMBARO"},
      {Id:'4', Nombre: "APASEO EL ALTO"},
      {Id:'5', Nombre: "APASEO EL GRANDE"},
      {Id:'6', Nombre: "ATARJEA"},
      {Id:'7', Nombre: "CELAYA"},
      {Id:'9', Nombre: "COMONFORT"},
      {Id:'10', Nombre: "CORONEO"},
      {Id:'11', Nombre: "CORTAZAR"},
      {Id:'12', Nombre: "CUERAMARO"},
      {Id:'13', Nombre: "DOCTOR MORA"},
      {Id:'14', Nombre: "DOLORES HIDALGO CUNA DE LA INDEPENDENCIA NACIONAL"},
      {Id:'15', Nombre: "GUANAJUATO"},
      {Id:'16', Nombre: "HUANIMARO"},
      {Id:'17', Nombre: "IRAPUATO"},
      {Id:'18', Nombre: "JARAL DEL PROGRESO"},
      {Id:'19', Nombre: "JERECUARO"},
      {Id:'20', Nombre: "LEON"},
    {Id:'8', Nombre: "MANUEL DOBLADO"},
      {Id:'21', Nombre: "MOROLEON"},
      {Id:'22', Nombre: "OCAMPO"},
      {Id:'23', Nombre: "PENJAMO"},
      {Id:'24', Nombre: "PUEBLO NUEVO"},
      {Id:'25', Nombre: "PURISIMA DEL RINCON"},
      {Id:'26', Nombre: "ROMITA"},
      {Id:'27', Nombre: "SALAMANCA"},
      {Id:'28', Nombre: "SALVATIERRA"},
      {Id:'29', Nombre: "SAN DIEGO DE LA UNION"},
      {Id:'30', Nombre: "SAN FELIPE"},
      {Id:'31', Nombre: "SAN FRANCISCO DEL RINCON"},
      {Id:'32', Nombre: "SAN JOSE ITURBIDE"},
      {Id:'33', Nombre: "SAN LUIS DE LA PAZ"},
    {Id:'3', Nombre: "SAN MIGUEL DE ALLENDE"},
      {Id:'34', Nombre: "SANTA CATARINA"},
      {Id:'35', Nombre: "SANTA CRUZ DE JUVENTINO ROSAS"},
      {Id:'36', Nombre: "SANTIAGO MARAVATIO"},
      {Id:'37', Nombre: "SILAO DE LA VICTORIA"},
      {Id:'38', Nombre: "TARANDACUAO"},
      {Id:'39', Nombre: "TARIMORO"},
      {Id:'40', Nombre: "TIERRA BLANCA"},
      {Id:'41', Nombre: "URIANGATO"},
      {Id:'42', Nombre: "VALLE DE SANTIAGO"},
      {Id:'43', Nombre: "VICTORIA"},
      {Id:'44', Nombre: "VILLAGRAN"},
      {Id:'45', Nombre: "XICHU"},
      {Id:'46', Nombre: "YURIRIA"},
    ],
    [
      //GUERRERO
      {Id:'1', Nombre: "ACAPULCO DE JUAREZ"},
      {Id:'76', Nombre: "ACATEPEC"},
      {Id:'2', Nombre: "AHUACUOTZINGO"},
      {Id:'3', Nombre: "AJUCHITLAN DEL PROGRESO"},
      {Id:'4', Nombre: "ALCOZAUCA DE GUERRERO"},
      {Id:'5', Nombre: "ALPOYECA"},
      {Id:'6', Nombre: "APAXTLA"},
      {Id:'7', Nombre: "ARCELIA"},
      {Id:'8', Nombre: "ATENANGO DEL RIO"},
      {Id:'9', Nombre: "ATLAMAJALCINGO DEL MONTE"},
      {Id:'10', Nombre: "ATLIXTAC"},
      {Id:'11', Nombre: "ATOYAC DE ALVAREZ"},
      {Id:'12', Nombre: "AYUTLA DE LOS LIBRES"},
      {Id:'13', Nombre: "AZOYU"},
      {Id:'14', Nombre: "BENITO JUAREZ"},
      {Id:'15', Nombre: "BUENAVISTA DE CUELLAR"},
      {Id:'28', Nombre: "CHILAPA DE ALVAREZ"},
      {Id:'29', Nombre: "CHILPANCINGO DE LOS BRAVO"},
      {Id:'16', Nombre: "COAHUAYUTLA DE JOSE MARIA IZAZAGA"},
      {Id:'78', Nombre: "COCHOAPA EL GRANDE"},
      {Id:'17', Nombre: "COCULA"},
      {Id:'18', Nombre: "COPALA"},
      {Id:'19', Nombre: "COPALILLO"},
      {Id:'20', Nombre: "COPANATOYAC"},
      {Id:'21', Nombre: "COYUCA DE BENITEZ"},
      {Id:'22', Nombre: "COYUCA DE CATALAN"},
      {Id:'23', Nombre: "CUAJINICUILAPA"},
      {Id:'24', Nombre: "CUALAC"},
      {Id:'25', Nombre: "CUAUTEPEC"},
      {Id:'26', Nombre: "CUETZALA DEL PROGRESO"},
      {Id:'27', Nombre: "CUTZAMALA DE PINZON"},
      {Id:'75', Nombre: "EDUARDO NERI"},
      {Id:'30', Nombre: "FLORENCIO VILLARREAL"},
      {Id:'31', Nombre: "GENERAL CANUTO A. NERI"},
      {Id:'32', Nombre: "GENERAL HELIODORO CASTILLO"},
      {Id:'33', Nombre: "HUAMUXTITLAN"},
      {Id:'34', Nombre: "HUITZUCO DE LOS FIGUEROA"},
      {Id:'35', Nombre: "IGUALA DE LA INDEPENDENCIA"},
      {Id:'36', Nombre: "IGUALAPA"},
      {Id:'81', Nombre: "ILIATENCO"},
      {Id:'37', Nombre: "IXCATEOPAN DE CUAUHTEMOC"},
      {Id:'79', Nombre: "JOSE JOAQUIN DE HERRERA"},
      {Id:'39', Nombre: "JUAN R. ESCUDERO"},
      {Id:'80', Nombre: "JUCHITAN"},
      {Id:'68', Nombre: "LA UNION DE ISIDORO MONTES DE OCA"},
      {Id:'40', Nombre: "LEONARDO BRAVO"},
      {Id:'41', Nombre: "MALINALTEPEC"},
      {Id:'77', Nombre: "MARQUELIA"},
      {Id:'42', Nombre: "MARTIR DE CUILAPAN"},
      {Id:'43', Nombre: "METLATONOC"},
      {Id:'44', Nombre: "MOCHITLAN"},
      {Id:'45', Nombre: "OLINALA"},
      {Id:'46', Nombre: "OMETEPEC"},
      {Id:'47', Nombre: "PEDRO ASCENCIO ALQUISIRAS"},
      {Id:'48', Nombre: "PETATLAN"},
      {Id:'49', Nombre: "PILCAYA"},
      {Id:'50', Nombre: "PUNGARABATO"},
      {Id:'51', Nombre: "QUECHULTENANGO"},
      {Id:'52', Nombre: "SAN LUIS ACATLAN"},
      {Id:'53', Nombre: "SAN MARCOS"},
      {Id:'54', Nombre: "SAN MIGUEL TOTOLAPAN"},
      {Id:'55', Nombre: "TAXCO DE ALARCON"},
      {Id:'56', Nombre: "TECOANAPA"},
      {Id:'57', Nombre: "TECPAN DE GALEANA"},
      {Id:'58', Nombre: "TELOLOAPAN"},
      {Id:'59', Nombre: "TEPECOACUILCO DE TRUJANO"},
      {Id:'60', Nombre: "TETIPAC"},
      {Id:'61', Nombre: "TIXTLA DE GUERRERO"},
      {Id:'62', Nombre: "TLACOACHISTLAHUACA"},
      {Id:'63', Nombre: "TLACOAPA"},
      {Id:'64', Nombre: "TLALCHAPA"},
      {Id:'65', Nombre: "TLALIXTAQUILLA DE MALDONADO"},
      {Id:'66', Nombre: "TLAPA DE COMONFORT"},
      {Id:'67', Nombre: "TLAPEHUALA"},
      {Id:'69', Nombre: "XALPATLAHUAC"},
      {Id:'70', Nombre: "XOCHIHUEHUETLAN"},
      {Id:'71', Nombre: "XOCHISTLAHUACA"},
      {Id:'72', Nombre: "ZAPOTITLAN TABLAS"},
      {Id:'38', Nombre: "ZIHUATANEJO DE AZUETA"},
      {Id:'73', Nombre: "ZIRANDARO"},
      {Id:'74', Nombre: "ZITLALA"},
    ],
    [
      //HIDALGO
      {Id:'1', Nombre: "ACATLAN"},
      {Id:'2', Nombre: "ACAXOCHITLAN"},
      {Id:'3', Nombre: "ACTOPAN"},
      {Id:'4', Nombre: "AGUA BLANCA DE ITURBIDE"},
      {Id:'5', Nombre: "AJACUBA"},
      {Id:'6', Nombre: "ALFAJAYUCAN"},
      {Id:'7', Nombre: "ALMOLOYA"},
      {Id:'8', Nombre: "APAN"},
      {Id:'10', Nombre: "ATITALAQUIA"},
      {Id:'11', Nombre: "ATLAPEXCO"},
      {Id:'13', Nombre: "ATOTONILCO DE TULA"},
      {Id:'12', Nombre: "ATOTONILCO EL GRANDE"},
      {Id:'14', Nombre: "CALNALI"},
      {Id:'15', Nombre: "CARDONAL"},
      {Id:'17', Nombre: "CHAPANTONGO"},
      {Id:'18', Nombre: "CHAPULHUACAN"},
      {Id:'19', Nombre: "CHILCUAUTLA"},
      {Id:'16', Nombre: "CUAUTEPEC DE HINOJOSA"},
      {Id:'9', Nombre: "EL ARENAL"},
      {Id:'20', Nombre: "ELOXOCHITLAN"},
      {Id:'21', Nombre: "EMILIANO ZAPATA"},
      {Id:'22', Nombre: "EPAZOYUCAN"},
      {Id:'23', Nombre: "FRANCISCO I. MADERO"},
      {Id:'24', Nombre: "HUASCA DE OCAMPO"},
      {Id:'25', Nombre: "HUAUTLA"},
      {Id:'26', Nombre: "HUAZALINGO"},
      {Id:'27', Nombre: "HUEHUETLA"},
      {Id:'28', Nombre: "HUEJUTLA DE REYES"},
      {Id:'29', Nombre: "HUICHAPAN"},
      {Id:'30', Nombre: "IXMIQUILPAN"},
      {Id:'31', Nombre: "JACALA DE LEDEZMA"},
      {Id:'32', Nombre: "JALTOCAN"},
      {Id:'33', Nombre: "JUAREZ HIDALGO"},
      {Id:'40', Nombre: "LA MISION"},
      {Id:'34', Nombre: "LOLOTLA"},
      {Id:'35', Nombre: "METEPEC"},
      {Id:'37', Nombre: "METZTITLAN"},
      {Id:'51', Nombre: "MINERAL DE LA REFORMA"},
      {Id:'38', Nombre: "MINERAL DEL CHICO"},
      {Id:'39', Nombre: "MINERAL DEL MONTE"},
      {Id:'41', Nombre: "MIXQUIAHUALA DE JUAREZ"},
      {Id:'42', Nombre: "MOLANGO DE ESCAMILLA"},
      {Id:'43', Nombre: "NICOLAS FLORES"},
      {Id:'44', Nombre: "NOPALA DE VILLAGRAN"},
      {Id:'45', Nombre: "OMITLAN DE JUAREZ"},
      {Id:'48', Nombre: "PACHUCA DE SOTO"},
      {Id:'47', Nombre: "PACULA"},
      {Id:'49', Nombre: "PISAFLORES"},
      {Id:'50', Nombre: "PROGRESO DE OBREGON"},
      {Id:'36', Nombre: "SAN AGUSTIN METZQUITITLAN"},
      {Id:'52', Nombre: "SAN AGUSTIN TLAXIACA"},
      {Id:'53', Nombre: "SAN BARTOLO TUTOTEPEC"},
      {Id:'46', Nombre: "SAN FELIPE ORIZATLAN"},
      {Id:'54', Nombre: "SAN SALVADOR"},
      {Id:'55', Nombre: "SANTIAGO DE ANAYA"},
      {Id:'56', Nombre: "SANTIAGO TULANTEPEC DE LUGO GUERRERO"},
      {Id:'57', Nombre: "SINGUILUCAN"},
      {Id:'58', Nombre: "TASQUILLO"},
      {Id:'59', Nombre: "TECOZAUTLA"},
      {Id:'60', Nombre: "TENANGO DE DORIA"},
      {Id:'61', Nombre: "TEPEAPULCO"},
      {Id:'62', Nombre: "TEPEHUACAN DE GUERRERO"},
      {Id:'63', Nombre: "TEPEJI DEL RIO DE OCAMPO"},
      {Id:'64', Nombre: "TEPETITLAN"},
      {Id:'65', Nombre: "TETEPANGO"},
      {Id:'67', Nombre: "TEZONTEPEC DE ALDAMA"},
      {Id:'68', Nombre: "TIANGUISTENGO"},
      {Id:'69', Nombre: "TIZAYUCA"},
      {Id:'70', Nombre: "TLAHUELILPAN"},
      {Id:'71', Nombre: "TLAHUILTEPA"},
      {Id:'72', Nombre: "TLANALAPA"},
      {Id:'73', Nombre: "TLANCHINOL"},
      {Id:'74', Nombre: "TLAXCOAPAN"},
      {Id:'75', Nombre: "TOLCAYUCA"},
      {Id:'76', Nombre: "TULA DE ALLENDE"},
      {Id:'77', Nombre: "TULANCINGO DE BRAVO"},
      {Id:'66', Nombre: "VILLA DE TEZONTEPEC"},
      {Id:'78', Nombre: "XOCHIATIPAN"},
      {Id:'79', Nombre: "XOCHICOATLAN"},
      {Id:'80', Nombre: "YAHUALICA"},
      {Id:'81', Nombre: "ZACUALTIPAN DE ANGELES"},
      {Id:'82', Nombre: "ZAPOTLAN DE JUAREZ"},
      {Id:'83', Nombre: "ZEMPOALA"},
      {Id:'84', Nombre: "ZIMAPAN"},
    ],
    [
      //JALISCO
      {Id:'1', Nombre: "ACATIC"},
      {Id:'2', Nombre: "ACATLAN DE JUAREZ"},
      {Id:'3', Nombre: "AHUALULCO DE MERCADO"},
      {Id:'4', Nombre: "AMACUECA"},
      {Id:'5', Nombre: "AMATITAN"},
      {Id:'6', Nombre: "AMECA"},
      {Id:'8', Nombre: "ARANDAS"},
      {Id:'10', Nombre: "ATEMAJAC DE BRIZUELA"},
      {Id:'11', Nombre: "ATENGO"},
      {Id:'12', Nombre: "ATENGUILLO"},
      {Id:'13', Nombre: "ATOTONILCO EL ALTO"},
      {Id:'14', Nombre: "ATOYAC"},
      {Id:'15', Nombre: "AUTLAN DE NAVARRO"},
      {Id:'16', Nombre: "AYOTLAN"},
      {Id:'17', Nombre: "AYUTLA"},
      {Id:'19', Nombre: "BOLANOS"},
      {Id:'20', Nombre: "CABO CORRIENTES"},
      {Id:'117', Nombre: "CANADAS DE OBREGON"},
      {Id:'21', Nombre: "CASIMIRO CASTILLO"},
      {Id:'30', Nombre: "CHAPALA"},
      {Id:'31', Nombre: "CHIMALTITAN"},
      {Id:'32', Nombre: "CHIQUILISTLAN"},
      {Id:'22', Nombre: "CIHUATLAN"},
      {Id:'24', Nombre: "COCULA"},
      {Id:'25', Nombre: "COLOTLAN"},
      {Id:'26', Nombre: "CONCEPCION DE BUENOS AIRES"},
      {Id:'27', Nombre: "CUAUTITLAN DE GARCIA BARRAGAN"},
      {Id:'28', Nombre: "CUAUTLA"},
      {Id:'29', Nombre: "CUQUIO"},
      {Id:'33', Nombre: "DEGOLLADO"},
      {Id:'34', Nombre: "EJUTLA"},
      {Id:'9', Nombre: "EL ARENAL"},
      {Id:'37', Nombre: "EL GRULLO"},
      {Id:'54', Nombre: "EL LIMON"},
      {Id:'70', Nombre: "EL SALTO"},
      {Id:'35', Nombre: "ENCARNACION DE DIAZ"},
      {Id:'36', Nombre: "ETZATLAN"},
      {Id:'79', Nombre: "GOMEZ FARIAS"},
      {Id:'38', Nombre: "GUACHINANGO"},
      {Id:'39', Nombre: "GUADALAJARA"},
      {Id:'40', Nombre: "HOSTOTIPAQUILLO"},
      {Id:'41', Nombre: "HUEJUCAR"},
      {Id:'42', Nombre: "HUEJUQUILLA EL ALTO"},
      {Id:'44', Nombre: "IXTLAHUACAN DE LOS MEMBRILLOS"},
      {Id:'45', Nombre: "IXTLAHUACAN DEL RIO"},
      {Id:'46', Nombre: "JALOSTOTITLAN"},
      {Id:'47', Nombre: "JAMAY"},
      {Id:'48', Nombre: "JESUS MARIA"},
      {Id:'49', Nombre: "JILOTLAN DE LOS DOLORES"},
      {Id:'50', Nombre: "JOCOTEPEC"},
      {Id:'51', Nombre: "JUANACATLAN"},
      {Id:'52', Nombre: "JUCHITLAN"},
      {Id:'18', Nombre: "LA BARCA"},
      {Id:'43', Nombre: "LA HUERTA"},
      {Id:'57', Nombre: "LA MANZANILLA DE LA PAZ"},
      {Id:'53', Nombre: "LAGOS DE MORENO"},
      {Id:'55', Nombre: "MAGDALENA"},
      {Id:'58', Nombre: "MASCOTA"},
      {Id:'59', Nombre: "MAZAMITLA"},
      {Id:'60', Nombre: "MEXTICACAN"},
      {Id:'61', Nombre: "MEZQUITIC"},
      {Id:'62', Nombre: "MIXTLAN"},
      {Id:'63', Nombre: "OCOTLAN"},
      {Id:'64', Nombre: "OJUELOS DE JALISCO"},
      {Id:'65', Nombre: "PIHUAMO"},
      {Id:'66', Nombre: "PONCITLAN"},
      {Id:'67', Nombre: "PUERTO VALLARTA"},
      {Id:'69', Nombre: "QUITUPAN"},
      {Id:'71', Nombre: "SAN CRISTOBAL DE LA BARRANCA"},
      {Id:'72', Nombre: "SAN DIEGO DE ALEJANDRIA"},
      {Id:'113', Nombre: "SAN GABRIEL"},
      {Id:'125', Nombre: "SAN IGNACIO CERRO GORDO"},
      {Id:'73', Nombre: "SAN JUAN DE LOS LAGOS"},
      {Id:'7', Nombre: "SAN JUANITO DE ESCOBEDO"},
      {Id:'74', Nombre: "SAN JULIAN"},
      {Id:'75', Nombre: "SAN MARCOS"},
      {Id:'76', Nombre: "SAN MARTIN DE BOLANOS"},
      {Id:'77', Nombre: "SAN MARTIN HIDALGO"},
      {Id:'78', Nombre: "SAN MIGUEL EL ALTO"},
      {Id:'98', Nombre: "SAN PEDRO TLAQUEPAQUE"},
      {Id:'80', Nombre: "SAN SEBASTIAN DEL OESTE"},
      {Id:'81', Nombre: "SANTA MARIA DE LOS ANGELES"},
      {Id:'56', Nombre: "SANTA MARIA DEL ORO"},
      {Id:'82', Nombre: "SAYULA"},
      {Id:'83', Nombre: "TALA"},
      {Id:'84', Nombre: "TALPA DE ALLENDE"},
      {Id:'85', Nombre: "TAMAZULA DE GORDIANO"},
      {Id:'86', Nombre: "TAPALPA"},
      {Id:'87', Nombre: "TECALITLAN"},
      {Id:'89', Nombre: "TECHALUTA DE MONTENEGRO"},
      {Id:'88', Nombre: "TECOLOTLAN"},
      {Id:'90', Nombre: "TENAMAXTLAN"},
      {Id:'91', Nombre: "TEOCALTICHE"},
      {Id:'92', Nombre: "TEOCUITATLAN DE CORONA"},
      {Id:'93', Nombre: "TEPATITLAN DE MORELOS"},
      {Id:'94', Nombre: "TEQUILA"},
      {Id:'95', Nombre: "TEUCHITLAN"},
      {Id:'96', Nombre: "TIZAPAN EL ALTO"},
      {Id:'97', Nombre: "TLAJOMULCO DE ZUNIGA"},
      {Id:'99', Nombre: "TOLIMAN"},
      {Id:'100', Nombre: "TOMATLAN"},
      {Id:'101', Nombre: "TONALA"},
      {Id:'102', Nombre: "TONAYA"},
      {Id:'103', Nombre: "TONILA"},
      {Id:'104', Nombre: "TOTATICHE"},
      {Id:'105', Nombre: "TOTOTLAN"},
      {Id:'106', Nombre: "TUXCACUESCO"},
      {Id:'107', Nombre: "TUXCUECA"},
      {Id:'108', Nombre: "TUXPAN"},
      {Id:'109', Nombre: "UNION DE SAN ANTONIO"},
      {Id:'110', Nombre: "UNION DE TULA"},
      {Id:'111', Nombre: "VALLE DE GUADALUPE"},
      {Id:'112', Nombre: "VALLE DE JUAREZ"},
      {Id:'114', Nombre: "VILLA CORONA"},
      {Id:'115', Nombre: "VILLA GUERRERO"},
      {Id:'116', Nombre: "VILLA HIDALGO"},
      {Id:'68', Nombre: "VILLA PURIFICACION"},
      {Id:'118', Nombre: "YAHUALICA DE GONZALEZ GALLO"},
      {Id:'119', Nombre: "ZACOALCO DE TORRES"},
      {Id:'120', Nombre: "ZAPOPAN"},
      {Id:'121', Nombre: "ZAPOTILTIC"},
      {Id:'122', Nombre: "ZAPOTITLAN DE VADILLO"},
      {Id:'123', Nombre: "ZAPOTLAN DEL REY"},
      {Id:'23', Nombre: "ZAPOTLAN EL GRANDE"},
      {Id:'124', Nombre: "ZAPOTLANEJO"},
    ],
    [
      //MICHOACAN DE OCAMPO
     {Id:'1', Nombre: "ACUITZIO"},
     {Id:'2', Nombre: "AGUILILLA"},
     {Id:'3', Nombre: "ALVARO OBREGON"},
     {Id:'4', Nombre: "ANGAMACUTIRO"},
     {Id:'5', Nombre: "ANGANGUEO"},
     {Id:'6', Nombre: "APATZINGAN"},
     {Id:'7', Nombre: "APORO"},
     {Id:'8', Nombre: "AQUILA"},
     {Id:'9', Nombre: "ARIO"},
      {Id:'10', Nombre: "ARTEAGA"},
      {Id:'11', Nombre: "BRISENAS"},
      {Id:'12', Nombre: "BUENAVISTA"},
      {Id:'13', Nombre: "CARACUARO"},
      {Id:'21', Nombre: "CHARAPAN"},
      {Id:'22', Nombre: "CHARO"},
      {Id:'23', Nombre: "CHAVINDA"},
      {Id:'24', Nombre: "CHERAN"},
      {Id:'25', Nombre: "CHILCHOTA"},
      {Id:'26', Nombre: "CHINICUILA"},
      {Id:'27', Nombre: "CHUCANDIRO"},
      {Id:'28', Nombre: "CHURINTZIO"},
      {Id:'29', Nombre: "CHURUMUCO"},
      {Id:'14', Nombre: "COAHUAYANA"},
      {Id:'15', Nombre: "COALCOMAN DE VAZQUEZ PALLARES"},
      {Id:'16', Nombre: "COENEO"},
      {Id:'74', Nombre: "COJUMATLAN DE REGULES"},
      {Id:'17', Nombre: "CONTEPEC"},
      {Id:'18', Nombre: "COPANDARO"},
      {Id:'19', Nombre: "COTIJA"},
      {Id:'20', Nombre: "CUITZEO"},
      {Id:'30', Nombre: "ECUANDUREO"},
      {Id:'31', Nombre: "EPITACIO HUERTA"},
      {Id:'32', Nombre: "ERONGARICUARO"},
      {Id:'33', Nombre: "GABRIEL ZAMORA"},
      {Id:'34', Nombre: "HIDALGO"},
      {Id:'36', Nombre: "HUANDACAREO"},
      {Id:'37', Nombre: "HUANIQUEO"},
      {Id:'38', Nombre: "HUETAMO"},
      {Id:'39', Nombre: "HUIRAMBA"},
      {Id:'40', Nombre: "INDAPARAPEO"},
      {Id:'41', Nombre: "IRIMBO"},
      {Id:'42', Nombre: "IXTLAN"},
      {Id:'43', Nombre: "JACONA"},
      {Id:'44', Nombre: "JIMENEZ"},
      {Id:'45', Nombre: "JIQUILPAN"},
      {Id:'113', Nombre: "JOSE SIXTO VERDUZCO"},
      {Id:'46', Nombre: "JUAREZ"},
      {Id:'47', Nombre: "JUNGAPEO"},
      {Id:'35', Nombre: "LA HUACANA"},
      {Id:'69', Nombre: "LA PIEDAD"},
      {Id:'48', Nombre: "LAGUNILLAS"},
      {Id:'52', Nombre: "LAZARO CARDENAS"},
      {Id:'75', Nombre: "LOS REYES"},
      {Id:'49', Nombre: "MADERO"},
      {Id:'50', Nombre: "MARAVATIO"},
      {Id:'51', Nombre: "MARCOS CASTELLANOS"},
      {Id:'53', Nombre: "MORELIA"},
      {Id:'54', Nombre: "MORELOS"},
      {Id:'55', Nombre: "MUGICA"},
      {Id:'56', Nombre: "NAHUATZEN"},
      {Id:'57', Nombre: "NOCUPETARO"},
      {Id:'58', Nombre: "NUEVO PARANGARICUTIRO"},
      {Id:'59', Nombre: "NUEVO URECHO"},
      {Id:'60', Nombre: "NUMARAN"},
      {Id:'61', Nombre: "OCAMPO"},
      {Id:'62', Nombre: "PAJACUARAN"},
      {Id:'63', Nombre: "PANINDICUARO"},
      {Id:'65', Nombre: "PARACHO"},
      {Id:'64', Nombre: "PARACUARO"},
      {Id:'66', Nombre: "PATZCUARO"},
      {Id:'67', Nombre: "PENJAMILLO"},
      {Id:'68', Nombre: "PERIBAN"},
      {Id:'70', Nombre: "PUREPERO"},
      {Id:'71', Nombre: "PURUANDIRO"},
      {Id:'72', Nombre: "QUERENDARO"},
      {Id:'73', Nombre: "QUIROGA"},
      {Id:'76', Nombre: "SAHUAYO"},
      {Id:'79', Nombre: "SALVADOR ESCALANTE"},
      {Id:'77', Nombre: "SAN LUCAS"},
      {Id:'78', Nombre: "SANTA ANA MAYA"},
      {Id:'80', Nombre: "SENGUIO"},
      {Id:'81', Nombre: "SUSUPUATO"},
      {Id:'82', Nombre: "TACAMBARO"},
      {Id:'83', Nombre: "TANCITARO"},
      {Id:'84', Nombre: "TANGAMANDAPIO"},
      {Id:'85', Nombre: "TANGANCICUARO"},
      {Id:'86', Nombre: "TANHUATO"},
      {Id:'87', Nombre: "TARETAN"},
      {Id:'88', Nombre: "TARIMBARO"},
      {Id:'89', Nombre: "TEPALCATEPEC"},
      {Id:'90', Nombre: "TINGAMBATO"},
      {Id:'91', Nombre: "TING�INDIN"},
      {Id:'92', Nombre: "TIQUICHEO DE NICOLAS ROMERO"},
      {Id:'93', Nombre: "TLALPUJAHUA"},
      {Id:'94', Nombre: "TLAZAZALCA"},
      {Id:'95', Nombre: "TOCUMBO"},
      {Id:'96', Nombre: "TUMBISCATIO"},
      {Id:'97', Nombre: "TURICATO"},
      {Id:'98', Nombre: "TUXPAN"},
      {Id:'99', Nombre: "TUZANTLA"},
      {Id:'100', Nombre: "TZINTZUNTZAN"},
      {Id:'101', Nombre: "TZITZIO"},
      {Id:'102', Nombre: "URUAPAN"},
      {Id:'103', Nombre: "VENUSTIANO CARRANZA"},
      {Id:'104', Nombre: "VILLAMAR"},
      {Id:'105', Nombre: "VISTA HERMOSA"},
      {Id:'106', Nombre: "YURECUARO"},
      {Id:'107', Nombre: "ZACAPU"},
      {Id:'108', Nombre: "ZAMORA"},
      {Id:'109', Nombre: "ZINAPARO"},
      {Id:'110', Nombre: "ZINAPECUARO"},
      {Id:'111', Nombre: "ZIRACUARETIRO"},
      {Id:'112', Nombre: "ZITACUARO"},
    ],
    [
      //MORELOS
      {Id:'1', Nombre: "AMACUZAC"},
      {Id:'2', Nombre: "ATLATLAHUCAN"},
      {Id:'3', Nombre: "AXOCHIAPAN"},
      {Id:'4', Nombre: "AYALA"},
      {Id:'5', Nombre: "COATLAN DEL RIO"},
      {Id:'6', Nombre: "CUAUTLA"},
      {Id:'7', Nombre: "CUERNAVACA"},
      {Id:'8', Nombre: "EMILIANO ZAPATA"},
      {Id:'9', Nombre: "HUITZILAC"},
      {Id:'10', Nombre: "JANTETELCO"},
      {Id:'11', Nombre: "JIUTEPEC"},
      {Id:'12', Nombre: "JOJUTLA"},
      {Id:'13', Nombre: "JONACATEPEC"},
      {Id:'14', Nombre: "MAZATEPEC"},
      {Id:'15', Nombre: "MIACATLAN"},
      {Id:'16', Nombre: "OCUITUCO"},
      {Id:'17', Nombre: "PUENTE DE IXTLA"},
      {Id:'18', Nombre: "TEMIXCO"},
      {Id:'33', Nombre: "TEMOAC"},
      {Id:'19', Nombre: "TEPALCINGO"},
      {Id:'20', Nombre: "TEPOZTLAN"},
      {Id:'21', Nombre: "TETECALA"},
      {Id:'22', Nombre: "TETELA DEL VOLCAN"},
      {Id:'23', Nombre: "TLALNEPANTLA"},
      {Id:'24', Nombre: "TLALTIZAPAN DE ZAPATA"},
      {Id:'25', Nombre: "TLAQUILTENANGO"},
      {Id:'26', Nombre: "TLAYACAPAN"},
      {Id:'27', Nombre: "TOTOLAPAN"},
      {Id:'28', Nombre: "XOCHITEPEC"},
      {Id:'29', Nombre: "YAUTEPEC"},
      {Id:'30', Nombre: "YECAPIXTLA"},
      {Id:'31', Nombre: "ZACATEPEC"},
      {Id:'32', Nombre: "ZACUALPAN DE AMILPAS"},
    ],
    [
      //NAYARIT
      {Id:'1', Nombre: "ACAPONETA"},
      {Id:'2', Nombre: "AHUACATLAN"},
      {Id:'3', Nombre: "AMATLAN DE CANAS"},
      {Id:'20', Nombre: "BAHIA DE BANDERAS"},
      {Id:'4', Nombre: "COMPOSTELA"},
      {Id:'9', Nombre: "DEL NAYAR"},
      {Id:'5', Nombre: "HUAJICORI"},
      {Id:'6', Nombre: "IXTLAN DEL RIO"},
      {Id:'7', Nombre: "JALA"},
      {Id:'19', Nombre: "LA YESCA"},
      {Id:'10', Nombre: "ROSAMORADA"},
      {Id:'11', Nombre: "RUIZ"},
      {Id:'12', Nombre: "SAN BLAS"},
      {Id:'13', Nombre: "SAN PEDRO LAGUNILLAS"},
      {Id:'14', Nombre: "SANTA MARIA DEL ORO"},
      {Id:'15', Nombre: "SANTIAGO IXCUINTLA"},
      {Id:'16', Nombre: "TECUALA"},
      {Id:'17', Nombre: "TEPIC"},
      {Id:'18', Nombre: "TUXPAN"},
      {Id:'8', Nombre: "XALISCO"},
    ],
    [
      //NUEVO LEON
      {Id:'1', Nombre: "ABASOLO"},
      {Id:'2', Nombre: "AGUALEGUAS"},
      {Id:'4', Nombre: "ALLENDE"},
      {Id:'5', Nombre: "ANAHUAC"},
      {Id:'6', Nombre: "APODACA"},
      {Id:'7', Nombre: "ARAMBERRI"},
      {Id:'8', Nombre: "BUSTAMANTE"},
      {Id:'9', Nombre: "CADEREYTA JIMENEZ"},
      {Id:'11', Nombre: "CERRALVO"},
      {Id:'13', Nombre: "CHINA"},
      {Id:'12', Nombre: "CIENEGA DE FLORES"},
      {Id:'14', Nombre: "DOCTOR ARROYO"},
      {Id:'15', Nombre: "DOCTOR COSS"},
      {Id:'16', Nombre: "DOCTOR GONZALEZ"},
      {Id:'10', Nombre: "EL CARMEN"},
      {Id:'17', Nombre: "GALEANA"},
      {Id:'18', Nombre: "GARCIA"},
      {Id:'20', Nombre: "GENERAL BRAVO"},
      {Id:'21', Nombre: "GENERAL ESCOBEDO"},
      {Id:'22', Nombre: "GENERAL TERAN"},
      {Id:'23', Nombre: "GENERAL TREVINO"},
      {Id:'24', Nombre: "GENERAL ZARAGOZA"},
      {Id:'25', Nombre: "GENERAL ZUAZUA"},
      {Id:'26', Nombre: "GUADALUPE"},
      {Id:'47', Nombre: "HIDALGO"},
      {Id:'28', Nombre: "HIGUERAS"},
      {Id:'29', Nombre: "HUALAHUISES"},
      {Id:'30', Nombre: "ITURBIDE"},
      {Id:'31', Nombre: "JUAREZ"},
      {Id:'32', Nombre: "LAMPAZOS DE NARANJO"},
      {Id:'33', Nombre: "LINARES"},
      {Id:'3', Nombre: "LOS ALDAMAS"},
      {Id:'27', Nombre: "LOS HERRERAS"},
      {Id:'42', Nombre: "LOS RAMONES"},
      {Id:'34', Nombre: "MARIN"},
      {Id:'35', Nombre: "MELCHOR OCAMPO"},
      {Id:'36', Nombre: "MIER Y NORIEGA"},
      {Id:'37', Nombre: "MINA"},
      {Id:'38', Nombre: "MONTEMORELOS"},
      {Id:'39', Nombre: "MONTERREY"},
      {Id:'40', Nombre: "PARAS"},
      {Id:'41', Nombre: "PESQUERIA"},
      {Id:'43', Nombre: "RAYONES"},
      {Id:'44', Nombre: "SABINAS HIDALGO"},
      {Id:'45', Nombre: "SALINAS VICTORIA"},
      {Id:'46', Nombre: "SAN NICOLAS DE LOS GARZA"},
      {Id:'19', Nombre: "SAN PEDRO GARZA GARCIA"},
      {Id:'48', Nombre: "SANTA CATARINA"},
      {Id:'49', Nombre: "SANTIAGO"},
      {Id:'50', Nombre: "VALLECILLO"},
      {Id:'51', Nombre: "VILLALDAMA"},
    ],
    [
      //OAXACA
      {Id:'1', Nombre: "ABEJONES"},
      {Id:'2', Nombre: "ACATLAN DE PEREZ FIGUEROA"},
      {Id:'174', Nombre: "ANIMAS TRUJANO"},
      {Id:'3', Nombre: "ASUNCION CACALOTEPEC"},
      {Id:'4', Nombre: "ASUNCION CUYOTEPEJI"},
      {Id:'5', Nombre: "ASUNCION IXTALTEPEC"},
      {Id:'6', Nombre: "ASUNCION NOCHIXTLAN"},
      {Id:'7', Nombre: "ASUNCION OCOTLAN"},
      {Id:'8', Nombre: "ASUNCION TLACOLULITA"},
      {Id:'398', Nombre: "AYOQUEZCO DE ALDAMA"},
      {Id:'9', Nombre: "AYOTZINTEPEC"},
      {Id:'11', Nombre: "CALIHUALA"},
      {Id:'12', Nombre: "CANDELARIA LOXICHA"},
      {Id:'247', Nombre: "CAPULALPAM DE MENDEZ"},
      {Id:'25', Nombre: "CHAHUITES"},
      {Id:'26', Nombre: "CHALCATONGO DE HIDALGO"},
      {Id:'27', Nombre: "CHIQUIHUITLAN DE BENITO JUAREZ"},
      {Id:'13', Nombre: "CIENEGA DE ZIMATLAN"},
      {Id:'14', Nombre: "CIUDAD IXTEPEC"},
      {Id:'15', Nombre: "COATECAS ALTAS"},
      {Id:'16', Nombre: "COICOYAN DE LAS FLORES"},
      {Id:'18', Nombre: "CONCEPCION BUENAVISTA"},
      {Id:'19', Nombre: "CONCEPCION PAPALO"},
      {Id:'20', Nombre: "CONSTANCIA DEL ROSARIO"},
      {Id:'21', Nombre: "COSOLAPA"},
      {Id:'22', Nombre: "COSOLTEPEC"},
      {Id:'23', Nombre: "CUILAPAM DE GUERRERO"},
      {Id:'24', Nombre: "CUYAMECALCO VILLA DE ZARAGOZA"},
      {Id:'10', Nombre: "EL BARRIO DE LA SOLEDAD"},
      {Id:'30', Nombre: "EL ESPINAL"},
      {Id:'29', Nombre: "ELOXOCHITLAN DE FLORES MAGON"},
      {Id:'32', Nombre: "FRESNILLO DE TRUJANO"},
      {Id:'34', Nombre: "GUADALUPE DE RAMIREZ"},
      {Id:'33', Nombre: "GUADALUPE ETLA"},
      {Id:'35', Nombre: "GUELATAO DE JUAREZ"},
      {Id:'36', Nombre: "GUEVEA DE HUMBOLDT"},
      {Id:'28', Nombre: "HEROICA CIUDAD DE EJUTLA DE CRESPO"},
      {Id:'39', Nombre: "HEROICA CIUDAD DE HUAJUAPAN DE LEON"},
      {Id:'43', Nombre: "HEROICA CIUDAD DE JUCHITAN DE ZARAGOZA"},
      {Id:'397', Nombre: "HEROICA CIUDAD DE TLAXIACO"},
      {Id:'40', Nombre: "HUAUTEPEC"},
      {Id:'41', Nombre: "HUAUTLA DE JIMENEZ"},
      {Id:'65', Nombre: "IXPANTEPEC NIEVES"},
      {Id:'42', Nombre: "IXTLAN DE JUAREZ"},
      {Id:'17', Nombre: "LA COMPANIA"},
      {Id:'69', Nombre: "LA PE"},
      {Id:'76', Nombre: "LA REFORMA"},
      {Id:'556', Nombre: "LA TRINIDAD VISTA HERMOSA"},
      {Id:'44', Nombre: "LOMA BONITA"},
      {Id:'45', Nombre: "MAGDALENA APASCO"},
      {Id:'46', Nombre: "MAGDALENA JALTEPEC"},
      {Id:'48', Nombre: "MAGDALENA MIXTEPEC"},
      {Id:'49', Nombre: "MAGDALENA OCOTLAN"},
      {Id:'50', Nombre: "MAGDALENA PENASCO"},
      {Id:'51', Nombre: "MAGDALENA TEITIPAC"},
      {Id:'52', Nombre: "MAGDALENA TEQUISISTLAN"},
      {Id:'53', Nombre: "MAGDALENA TLACOTEPEC"},
      {Id:'562', Nombre: "MAGDALENA YODOCONO DE PORFIRIO DIAZ"},
      {Id:'54', Nombre: "MAGDALENA ZAHUATLAN"},
      {Id:'55', Nombre: "MARISCALA DE JUAREZ"},
      {Id:'56', Nombre: "MARTIRES DE TACUBAYA"},
      {Id:'57', Nombre: "MATIAS ROMERO AVENDANO"},
      {Id:'58', Nombre: "MAZATLAN VILLA DE FLORES"},
      {Id:'37', Nombre: "MESONES HIDALGO"},
      {Id:'59', Nombre: "MIAHUATLAN DE PORFIRIO DIAZ"},
      {Id:'60', Nombre: "MIXISTLAN DE LA REFORMA"},
      {Id:'61', Nombre: "MONJAS"},
      {Id:'62', Nombre: "NATIVIDAD"},
      {Id:'63', Nombre: "NAZARENO ETLA"},
      {Id:'64', Nombre: "NEJAPA DE MADERO"},
      {Id:'504', Nombre: "NUEVO ZOQUIAPAM"},
      {Id:'67', Nombre: "OAXACA DE JUAREZ"},
      {Id:'68', Nombre: "OCOTLAN DE MORELOS"},
      {Id:'70', Nombre: "PINOTEPA DE DON LUIS"},
      {Id:'71', Nombre: "PLUMA HIDALGO"},
      {Id:'73', Nombre: "PUTLA VILLA DE GUERRERO"},
      {Id:'75', Nombre: "REFORMA DE PINEDA"},
      {Id:'77', Nombre: "REYES ETLA"},
      {Id:'78', Nombre: "ROJAS DE CUAUHTEMOC"},
      {Id:'79', Nombre: "SALINA CRUZ"},
      {Id:'80', Nombre: "SAN AGUSTIN AMATENGO"},
      {Id:'81', Nombre: "SAN AGUSTIN ATENANGO"},
      {Id:'82', Nombre: "SAN AGUSTIN CHAYUCO"},
      {Id:'83', Nombre: "SAN AGUSTIN DE LAS JUNTAS"},
      {Id:'84', Nombre: "SAN AGUSTIN ETLA"},
      {Id:'85', Nombre: "SAN AGUSTIN LOXICHA"},
      {Id:'86', Nombre: "SAN AGUSTIN TLACOTEPEC"},
      {Id:'87', Nombre: "SAN AGUSTIN YATARENI"},
      {Id:'88', Nombre: "SAN ANDRES CABECERA NUEVA"},
      {Id:'89', Nombre: "SAN ANDRES DINICUITI"},
      {Id:'90', Nombre: "SAN ANDRES HUAXPALTEPEC"},
      {Id:'91', Nombre: "SAN ANDRES HUAYAPAM"},
      {Id:'92', Nombre: "SAN ANDRES IXTLAHUACA"},
      {Id:'93', Nombre: "SAN ANDRES LAGUNAS"},
      {Id:'94', Nombre: "SAN ANDRES NUXINO"},
      {Id:'95', Nombre: "SAN ANDRES PAXTLAN"},
      {Id:'96', Nombre: "SAN ANDRES SINAXTLA"},
      {Id:'97', Nombre: "SAN ANDRES SOLAGA"},
      {Id:'98', Nombre: "SAN ANDRES TEOTILALPAM"},
      {Id:'99', Nombre: "SAN ANDRES TEPETLAPA"},
      {Id:'100', Nombre: "SAN ANDRES YAA"},
      {Id:'101', Nombre: "SAN ANDRES ZABACHE"},
      {Id:'102', Nombre: "SAN ANDRES ZAUTLA"},
      {Id:'103', Nombre: "SAN ANTONINO CASTILLO VELASCO"},
      {Id:'104', Nombre: "SAN ANTONINO EL ALTO"},
      {Id:'105', Nombre: "SAN ANTONINO MONTE VERDE"},
      {Id:'106', Nombre: "SAN ANTONIO ACUTLA"},
      {Id:'107', Nombre: "SAN ANTONIO DE LA CAL"},
      {Id:'108', Nombre: "SAN ANTONIO HUITEPEC"},
      {Id:'109', Nombre: "SAN ANTONIO NANAHUATIPAM"},
      {Id:'110', Nombre: "SAN ANTONIO SINICAHUA"},
      {Id:'111', Nombre: "SAN ANTONIO TEPETLAPA"},
      {Id:'112', Nombre: "SAN BALTAZAR CHICHICAPAM"},
      {Id:'113', Nombre: "SAN BALTAZAR LOXICHA"},
      {Id:'114', Nombre: "SAN BALTAZAR YATZACHI EL BAJO"},
      {Id:'115', Nombre: "SAN BARTOLO COYOTEPEC"},
      {Id:'121', Nombre: "SAN BARTOLO SOYALTEPEC"},
      {Id:'122', Nombre: "SAN BARTOLO YAUTEPEC"},
      {Id:'116', Nombre: "SAN BARTOLOME AYAUTLA"},
      {Id:'117', Nombre: "SAN BARTOLOME LOXICHA"},
      {Id:'118', Nombre: "SAN BARTOLOME QUIALANA"},
      {Id:'119', Nombre: "SAN BARTOLOME YUCUANE"},
      {Id:'120', Nombre: "SAN BARTOLOME ZOOGOCHO"},
      {Id:'123', Nombre: "SAN BERNARDO MIXTEPEC"},
      {Id:'124', Nombre: "SAN BLAS ATEMPA"},
      {Id:'125', Nombre: "SAN CARLOS YAUTEPEC"},
      {Id:'126', Nombre: "SAN CRISTOBAL AMATLAN"},
      {Id:'127', Nombre: "SAN CRISTOBAL AMOLTEPEC"},
      {Id:'128', Nombre: "SAN CRISTOBAL LACHIRIOAG"},
      {Id:'129', Nombre: "SAN CRISTOBAL SUCHIXTLAHUACA"},
      {Id:'130', Nombre: "SAN DIONISIO DEL MAR"},
      {Id:'131', Nombre: "SAN DIONISIO OCOTEPEC"},
      {Id:'132', Nombre: "SAN DIONISIO OCOTLAN"},
      {Id:'133', Nombre: "SAN ESTEBAN ATATLAHUCA"},
      {Id:'134', Nombre: "SAN FELIPE JALAPA DE DIAZ"},
      {Id:'135', Nombre: "SAN FELIPE TEJALAPAM"},
      {Id:'136', Nombre: "SAN FELIPE USILA"},
      {Id:'137', Nombre: "SAN FRANCISCO CAHUACUA"},
      {Id:'138', Nombre: "SAN FRANCISCO CAJONOS"},
      {Id:'139', Nombre: "SAN FRANCISCO CHAPULAPA"},
      {Id:'140', Nombre: "SAN FRANCISCO CHINDUA"},
      {Id:'141', Nombre: "SAN FRANCISCO DEL MAR"},
      {Id:'142', Nombre: "SAN FRANCISCO HUEHUETLAN"},
      {Id:'143', Nombre: "SAN FRANCISCO IXHUATAN"},
      {Id:'144', Nombre: "SAN FRANCISCO JALTEPETONGO"},
      {Id:'145', Nombre: "SAN FRANCISCO LACHIGOLO"},
      {Id:'146', Nombre: "SAN FRANCISCO LOGUECHE"},
      {Id:'147', Nombre: "SAN FRANCISCO NUXANO"},
      {Id:'148', Nombre: "SAN FRANCISCO OZOLOTEPEC"},
      {Id:'149', Nombre: "SAN FRANCISCO SOLA"},
      {Id:'150', Nombre: "SAN FRANCISCO TELIXTLAHUACA"},
      {Id:'151', Nombre: "SAN FRANCISCO TEOPAN"},
      {Id:'152', Nombre: "SAN FRANCISCO TLAPANCINGO"},
      {Id:'153', Nombre: "SAN GABRIEL MIXTEPEC"},
      {Id:'154', Nombre: "SAN ILDEFONSO AMATLAN"},
      {Id:'155', Nombre: "SAN ILDEFONSO SOLA"},
      {Id:'156', Nombre: "SAN ILDEFONSO VILLA ALTA"},
      {Id:'157', Nombre: "SAN JACINTO AMILPAS"},
      {Id:'158', Nombre: "SAN JACINTO TLACOTEPEC"},
      {Id:'159', Nombre: "SAN JERONIMO COATLAN"},
      {Id:'160', Nombre: "SAN JERONIMO SILACAYOAPILLA"},
      {Id:'161', Nombre: "SAN JERONIMO SOSOLA"},
      {Id:'162', Nombre: "SAN JERONIMO TAVICHE"},
      {Id:'163', Nombre: "SAN JERONIMO TECOATL"},
      {Id:'550', Nombre: "SAN JERONIMO TLACOCHAHUAYA"},
      {Id:'164', Nombre: "SAN JORGE NUCHITA"},
      {Id:'165', Nombre: "SAN JOSE AYUQUILA"},
      {Id:'166', Nombre: "SAN JOSE CHILTEPEC"},
      {Id:'167', Nombre: "SAN JOSE DEL PENASCO"},
      {Id:'72', Nombre: "SAN JOSE DEL PROGRESO"},
      {Id:'168', Nombre: "SAN JOSE ESTANCIA GRANDE"},
      {Id:'169', Nombre: "SAN JOSE INDEPENDENCIA"},
      {Id:'170', Nombre: "SAN JOSE LACHIGUIRI"},
      {Id:'171', Nombre: "SAN JOSE TENANGO"},
      {Id:'172', Nombre: "SAN JUAN ACHIUTLA"},
      {Id:'173', Nombre: "SAN JUAN ATEPEC"},
      {Id:'175', Nombre: "SAN JUAN BAUTISTA ATATLAHUCA"},
      {Id:'176', Nombre: "SAN JUAN BAUTISTA COIXTLAHUACA"},
      {Id:'177', Nombre: "SAN JUAN BAUTISTA CUICATLAN"},
      {Id:'178', Nombre: "SAN JUAN BAUTISTA GUELACHE"},
      {Id:'179', Nombre: "SAN JUAN BAUTISTA JAYACATLAN"},
      {Id:'180', Nombre: "SAN JUAN BAUTISTA LO DE SOTO"},
      {Id:'181', Nombre: "SAN JUAN BAUTISTA SUCHITEPEC"},
      {Id:'183', Nombre: "SAN JUAN BAUTISTA TLACHICHILCO"},
      {Id:'182', Nombre: "SAN JUAN BAUTISTA TLACOATZINTEPEC"},
      {Id:'184', Nombre: "SAN JUAN BAUTISTA TUXTEPEC"},
      {Id:'559', Nombre: "SAN JUAN BAUTISTA VALLE NACIONAL"},
      {Id:'185', Nombre: "SAN JUAN CACAHUATEPEC"},
      {Id:'191', Nombre: "SAN JUAN CHICOMEZUCHIL"},
      {Id:'192', Nombre: "SAN JUAN CHILATECA"},
      {Id:'186', Nombre: "SAN JUAN CIENEGUILLA"},
      {Id:'187', Nombre: "SAN JUAN COATZOSPAM"},
      {Id:'188', Nombre: "SAN JUAN COLORADO"},
      {Id:'189', Nombre: "SAN JUAN COMALTEPEC"},
      {Id:'190', Nombre: "SAN JUAN COTZOCON"},
      {Id:'206', Nombre: "SAN JUAN DE LOS CUES"},
      {Id:'193', Nombre: "SAN JUAN DEL ESTADO"},
      {Id:'194', Nombre: "SAN JUAN DEL RIO"},
      {Id:'195', Nombre: "SAN JUAN DIUXI"},
      {Id:'196', Nombre: "SAN JUAN EVANGELISTA ANALCO"},
      {Id:'197', Nombre: "SAN JUAN GUELAVIA"},
      {Id:'198', Nombre: "SAN JUAN GUICHICOVI"},
      {Id:'199', Nombre: "SAN JUAN IHUALTEPEC"},
      {Id:'200', Nombre: "SAN JUAN JUQUILA MIXES"},
      {Id:'201', Nombre: "SAN JUAN JUQUILA VIJANOS"},
      {Id:'202', Nombre: "SAN JUAN LACHAO"},
      {Id:'203', Nombre: "SAN JUAN LACHIGALLA"},
      {Id:'204', Nombre: "SAN JUAN LAJARCIA"},
      {Id:'205', Nombre: "SAN JUAN LALANA"},
      {Id:'207', Nombre: "SAN JUAN MAZATLAN"},
      {Id:'208', Nombre: "SAN JUAN MIXTEPEC -DTO. 08 -"},
      {Id:'209', Nombre: "SAN JUAN MIXTEPEC -DTO. 26 -"},
      {Id:'210', Nombre: "SAN JUAN NUMI"},
      {Id:'211', Nombre: "SAN JUAN OZOLOTEPEC"},
      {Id:'212', Nombre: "SAN JUAN PETLAPA"},
      {Id:'213', Nombre: "SAN JUAN QUIAHIJE"},
      {Id:'214', Nombre: "SAN JUAN QUIOTEPEC"},
      {Id:'215', Nombre: "SAN JUAN SAYULTEPEC"},
      {Id:'216', Nombre: "SAN JUAN TABAA"},
      {Id:'217', Nombre: "SAN JUAN TAMAZOLA"},
      {Id:'218', Nombre: "SAN JUAN TEITA"},
      {Id:'219', Nombre: "SAN JUAN TEITIPAC"},
      {Id:'220', Nombre: "SAN JUAN TEPEUXILA"},
      {Id:'221', Nombre: "SAN JUAN TEPOSCOLULA"},
      {Id:'222', Nombre: "SAN JUAN YAEE"},
      {Id:'223', Nombre: "SAN JUAN YATZONA"},
      {Id:'224', Nombre: "SAN JUAN YUCUITA"},
      {Id:'225', Nombre: "SAN LORENZO"},
      {Id:'226', Nombre: "SAN LORENZO ALBARRADAS"},
      {Id:'227', Nombre: "SAN LORENZO CACAOTEPEC"},
      {Id:'228', Nombre: "SAN LORENZO CUAUNECUILTITLA"},
      {Id:'229', Nombre: "SAN LORENZO TEXMELUCAN"},
      {Id:'230', Nombre: "SAN LORENZO VICTORIA"},
      {Id:'231', Nombre: "SAN LUCAS CAMOTLAN"},
      {Id:'232', Nombre: "SAN LUCAS OJITLAN"},
      {Id:'233', Nombre: "SAN LUCAS QUIAVINI"},
      {Id:'234', Nombre: "SAN LUCAS ZOQUIAPAM"},
      {Id:'235', Nombre: "SAN LUIS AMATLAN"},
      {Id:'236', Nombre: "SAN MARCIAL OZOLOTEPEC"},
      {Id:'237', Nombre: "SAN MARCOS ARTEAGA"},
      {Id:'238', Nombre: "SAN MARTIN DE LOS CANSECOS"},
      {Id:'239', Nombre: "SAN MARTIN HUAMELULPAM"},
      {Id:'240', Nombre: "SAN MARTIN ITUNYOSO"},
      {Id:'241', Nombre: "SAN MARTIN LACHILA"},
      {Id:'242', Nombre: "SAN MARTIN PERAS"},
      {Id:'243', Nombre: "SAN MARTIN TILCAJETE"},
      {Id:'244', Nombre: "SAN MARTIN TOXPALAN"},
      {Id:'245', Nombre: "SAN MARTIN ZACATEPEC"},
      {Id:'246', Nombre: "SAN MATEO CAJONOS"},
      {Id:'248', Nombre: "SAN MATEO DEL MAR"},
      {Id:'250', Nombre: "SAN MATEO ETLATONGO"},
      {Id:'251', Nombre: "SAN MATEO NEJAPAM"},
      {Id:'252', Nombre: "SAN MATEO PENASCO"},
      {Id:'253', Nombre: "SAN MATEO PINAS"},
      {Id:'254', Nombre: "SAN MATEO RIO HONDO"},
      {Id:'255', Nombre: "SAN MATEO SINDIHUI"},
      {Id:'256', Nombre: "SAN MATEO TLAPILTEPEC"},
      {Id:'249', Nombre: "SAN MATEO YOLOXOCHITLAN"},
      {Id:'566', Nombre: "SAN MATEO YUCUTINDOO"},
      {Id:'257', Nombre: "SAN MELCHOR BETAZA"},
      {Id:'258', Nombre: "SAN MIGUEL ACHIUTLA"},
      {Id:'259', Nombre: "SAN MIGUEL AHUEHUETITLAN"},
      {Id:'260', Nombre: "SAN MIGUEL ALOAPAM"},
      {Id:'261', Nombre: "SAN MIGUEL AMATITLAN"},
      {Id:'262', Nombre: "SAN MIGUEL AMATLAN"},
      {Id:'264', Nombre: "SAN MIGUEL CHICAHUA"},
      {Id:'265', Nombre: "SAN MIGUEL CHIMALAPA"},
      {Id:'263', Nombre: "SAN MIGUEL COATLAN"},
      {Id:'266', Nombre: "SAN MIGUEL DEL PUERTO"},
      {Id:'267', Nombre: "SAN MIGUEL DEL RIO"},
      {Id:'268', Nombre: "SAN MIGUEL EJUTLA"},
      {Id:'269', Nombre: "SAN MIGUEL EL GRANDE"},
      {Id:'270', Nombre: "SAN MIGUEL HUAUTLA"},
      {Id:'271', Nombre: "SAN MIGUEL MIXTEPEC"},
      {Id:'272', Nombre: "SAN MIGUEL PANIXTLAHUACA"},
      {Id:'273', Nombre: "SAN MIGUEL PERAS"},
      {Id:'274', Nombre: "SAN MIGUEL PIEDRAS"},
      {Id:'275', Nombre: "SAN MIGUEL QUETZALTEPEC"},
      {Id:'276', Nombre: "SAN MIGUEL SANTA FLOR"},
      {Id:'278', Nombre: "SAN MIGUEL SOYALTEPEC"},
      {Id:'279', Nombre: "SAN MIGUEL SUCHIXTEPEC"},
      {Id:'281', Nombre: "SAN MIGUEL TECOMATLAN"},
      {Id:'282', Nombre: "SAN MIGUEL TENANGO"},
      {Id:'283', Nombre: "SAN MIGUEL TEQUIXTEPEC"},
      {Id:'284', Nombre: "SAN MIGUEL TILQUIAPAM"},
      {Id:'285', Nombre: "SAN MIGUEL TLACAMAMA"},
      {Id:'286', Nombre: "SAN MIGUEL TLACOTEPEC"},
      {Id:'287', Nombre: "SAN MIGUEL TULANCINGO"},
      {Id:'288', Nombre: "SAN MIGUEL YOTAO"},
      {Id:'289', Nombre: "SAN NICOLAS"},
      {Id:'290', Nombre: "SAN NICOLAS HIDALGO"},
      {Id:'291', Nombre: "SAN PABLO COATLAN"},
      {Id:'292', Nombre: "SAN PABLO CUATRO VENADOS"},
      {Id:'293', Nombre: "SAN PABLO ETLA"},
      {Id:'294', Nombre: "SAN PABLO HUITZO"},
      {Id:'295', Nombre: "SAN PABLO HUIXTEPEC"},
      {Id:'296', Nombre: "SAN PABLO MACUILTIANGUIS"},
      {Id:'297', Nombre: "SAN PABLO TIJALTEPEC"},
      {Id:'298', Nombre: "SAN PABLO VILLA DE MITLA"},
      {Id:'299', Nombre: "SAN PABLO YAGANIZA"},
      {Id:'300', Nombre: "SAN PEDRO AMUZGOS"},
      {Id:'301', Nombre: "SAN PEDRO APOSTOL"},
      {Id:'302', Nombre: "SAN PEDRO ATOYAC"},
      {Id:'303', Nombre: "SAN PEDRO CAJONOS"},
      {Id:'305', Nombre: "SAN PEDRO COMITANCILLO"},
      {Id:'304', Nombre: "SAN PEDRO COXCALTEPEC CANTAROS"},
      {Id:'306', Nombre: "SAN PEDRO EL ALTO"},
      {Id:'307', Nombre: "SAN PEDRO HUAMELULA"},
      {Id:'308', Nombre: "SAN PEDRO HUILOTEPEC"},
      {Id:'309', Nombre: "SAN PEDRO IXCATLAN"},
      {Id:'310', Nombre: "SAN PEDRO IXTLAHUACA"},
      {Id:'311', Nombre: "SAN PEDRO JALTEPETONGO"},
      {Id:'312', Nombre: "SAN PEDRO JICAYAN"},
      {Id:'313', Nombre: "SAN PEDRO JOCOTIPAC"},
      {Id:'314', Nombre: "SAN PEDRO JUCHATENGO"},
      {Id:'315', Nombre: "SAN PEDRO MARTIR"},
      {Id:'316', Nombre: "SAN PEDRO MARTIR QUIECHAPA"},
      {Id:'317', Nombre: "SAN PEDRO MARTIR YUCUXACO"},
      {Id:'318', Nombre: "SAN PEDRO MIXTEPEC -DTO. 22 -"},
      {Id:'319', Nombre: "SAN PEDRO MIXTEPEC -DTO. 26 -"},
      {Id:'320', Nombre: "SAN PEDRO MOLINOS"},
      {Id:'321', Nombre: "SAN PEDRO NOPALA"},
      {Id:'322', Nombre: "SAN PEDRO OCOPETATILLO"},
      {Id:'323', Nombre: "SAN PEDRO OCOTEPEC"},
      {Id:'324', Nombre: "SAN PEDRO POCHUTLA"},
      {Id:'325', Nombre: "SAN PEDRO QUIATONI"},
      {Id:'326', Nombre: "SAN PEDRO SOCHIAPAM"},
      {Id:'327', Nombre: "SAN PEDRO TAPANATEPEC"},
      {Id:'328', Nombre: "SAN PEDRO TAVICHE"},
      {Id:'329', Nombre: "SAN PEDRO TEOZACOALCO"},
      {Id:'330', Nombre: "SAN PEDRO TEUTILA"},
      {Id:'331', Nombre: "SAN PEDRO TIDAA"},
      {Id:'332', Nombre: "SAN PEDRO TOPILTEPEC"},
      {Id:'333', Nombre: "SAN PEDRO TOTOLAPAM"},
      {Id:'337', Nombre: "SAN PEDRO Y SAN PABLO AYUTLA"},
      {Id:'339', Nombre: "SAN PEDRO Y SAN PABLO TEPOSCOLULA"},
      {Id:'340', Nombre: "SAN PEDRO Y SAN PABLO TEQUIXTEPEC"},
      {Id:'335', Nombre: "SAN PEDRO YANERI"},
      {Id:'336', Nombre: "SAN PEDRO YOLOX"},
      {Id:'341', Nombre: "SAN PEDRO YUCUNAMA"},
      {Id:'342', Nombre: "SAN RAYMUNDO JALPAN"},
      {Id:'343', Nombre: "SAN SEBASTIAN ABASOLO"},
      {Id:'344', Nombre: "SAN SEBASTIAN COATLAN"},
      {Id:'345', Nombre: "SAN SEBASTIAN IXCAPA"},
      {Id:'346', Nombre: "SAN SEBASTIAN NICANANDUTA"},
      {Id:'347', Nombre: "SAN SEBASTIAN RIO HONDO"},
      {Id:'348', Nombre: "SAN SEBASTIAN TECOMAXTLAHUACA"},
      {Id:'349', Nombre: "SAN SEBASTIAN TEITIPAC"},
      {Id:'350', Nombre: "SAN SEBASTIAN TUTLA"},
      {Id:'351', Nombre: "SAN SIMON ALMOLONGAS"},
      {Id:'352', Nombre: "SAN SIMON ZAHUATLAN"},
      {Id:'534', Nombre: "SAN VICENTE COATLAN"},
      {Id:'535', Nombre: "SAN VICENTE LACHIXIO"},
      {Id:'536', Nombre: "SAN VICENTE NUNU"},
      {Id:'353', Nombre: "SANTA ANA"},
      {Id:'354', Nombre: "SANTA ANA ATEIXTLAHUACA"},
      {Id:'355', Nombre: "SANTA ANA CUAUHTEMOC"},
      {Id:'356', Nombre: "SANTA ANA DEL VALLE"},
      {Id:'357', Nombre: "SANTA ANA TAVELA"},
      {Id:'358', Nombre: "SANTA ANA TLAPACOYAN"},
      {Id:'359', Nombre: "SANTA ANA YARENI"},
      {Id:'360', Nombre: "SANTA ANA ZEGACHE"},
      {Id:'361', Nombre: "SANTA CATALINA QUIERI"},
      {Id:'362', Nombre: "SANTA CATARINA CUIXTLA"},
      {Id:'363', Nombre: "SANTA CATARINA IXTEPEJI"},
      {Id:'364', Nombre: "SANTA CATARINA JUQUILA"},
      {Id:'365', Nombre: "SANTA CATARINA LACHATAO"},
      {Id:'366', Nombre: "SANTA CATARINA LOXICHA"},
      {Id:'367', Nombre: "SANTA CATARINA MECHOACAN"},
      {Id:'368', Nombre: "SANTA CATARINA MINAS"},
      {Id:'369', Nombre: "SANTA CATARINA QUIANE"},
      {Id:'74', Nombre: "SANTA CATARINA QUIOQUITANI"},
      {Id:'370', Nombre: "SANTA CATARINA TAYATA"},
      {Id:'371', Nombre: "SANTA CATARINA TICUA"},
      {Id:'372', Nombre: "SANTA CATARINA YOSONOTU"},
      {Id:'373', Nombre: "SANTA CATARINA ZAPOQUILA"},
      {Id:'374', Nombre: "SANTA CRUZ ACATEPEC"},
      {Id:'375', Nombre: "SANTA CRUZ AMILPAS"},
      {Id:'376', Nombre: "SANTA CRUZ DE BRAVO"},
      {Id:'377', Nombre: "SANTA CRUZ ITUNDUJIA"},
      {Id:'378', Nombre: "SANTA CRUZ MIXTEPEC"},
      {Id:'379', Nombre: "SANTA CRUZ NUNDACO"},
      {Id:'380', Nombre: "SANTA CRUZ PAPALUTLA"},
      {Id:'381', Nombre: "SANTA CRUZ TACACHE DE MINA"},
      {Id:'382', Nombre: "SANTA CRUZ TACAHUA"},
      {Id:'383', Nombre: "SANTA CRUZ TAYATA"},
      {Id:'384', Nombre: "SANTA CRUZ XITLA"},
      {Id:'385', Nombre: "SANTA CRUZ XOXOCOTLAN"},
      {Id:'386', Nombre: "SANTA CRUZ ZENZONTEPEC"},
      {Id:'387', Nombre: "SANTA GERTRUDIS"},
      {Id:'569', Nombre: "SANTA INES DE ZARAGOZA"},
      {Id:'388', Nombre: "SANTA INES DEL MONTE"},
      {Id:'389', Nombre: "SANTA INES YATZECHE"},
      {Id:'390', Nombre: "SANTA LUCIA DEL CAMINO"},
      {Id:'391', Nombre: "SANTA LUCIA MIAHUATLAN"},
      {Id:'392', Nombre: "SANTA LUCIA MONTEVERDE"},
      {Id:'393', Nombre: "SANTA LUCIA OCOTLAN"},
      {Id:'47', Nombre: "SANTA MAGDALENA JICOTLAN"},
      {Id:'394', Nombre: "SANTA MARIA ALOTEPEC"},
      {Id:'395', Nombre: "SANTA MARIA APAZCO"},
      {Id:'399', Nombre: "SANTA MARIA ATZOMPA"},
      {Id:'400', Nombre: "SANTA MARIA CAMOTLAN"},
      {Id:'404', Nombre: "SANTA MARIA CHACHOAPAM"},
      {Id:'406', Nombre: "SANTA MARIA CHILCHOTLA"},
      {Id:'407', Nombre: "SANTA MARIA CHIMALAPA"},
      {Id:'401', Nombre: "SANTA MARIA COLOTEPEC"},
      {Id:'402', Nombre: "SANTA MARIA CORTIJO"},
      {Id:'403', Nombre: "SANTA MARIA COYOTEPEC"},
      {Id:'408', Nombre: "SANTA MARIA DEL ROSARIO"},
      {Id:'409', Nombre: "SANTA MARIA DEL TULE"},
      {Id:'410', Nombre: "SANTA MARIA ECATEPEC"},
      {Id:'411', Nombre: "SANTA MARIA GUELACE"},
      {Id:'412', Nombre: "SANTA MARIA GUIENAGATI"},
      {Id:'413', Nombre: "SANTA MARIA HUATULCO"},
      {Id:'414', Nombre: "SANTA MARIA HUAZOLOTITLAN"},
      {Id:'415', Nombre: "SANTA MARIA IPALAPA"},
      {Id:'416', Nombre: "SANTA MARIA IXCATLAN"},
      {Id:'417', Nombre: "SANTA MARIA JACATEPEC"},
      {Id:'418', Nombre: "SANTA MARIA JALAPA DEL MARQUES"},
      {Id:'419', Nombre: "SANTA MARIA JALTIANGUIS"},
      {Id:'396', Nombre: "SANTA MARIA LA ASUNCION"},
      {Id:'420', Nombre: "SANTA MARIA LACHIXIO"},
      {Id:'421', Nombre: "SANTA MARIA MIXTEQUILLA"},
      {Id:'422', Nombre: "SANTA MARIA NATIVITAS"},
      {Id:'423', Nombre: "SANTA MARIA NDUAYACO"},
      {Id:'424', Nombre: "SANTA MARIA OZOLOTEPEC"},
      {Id:'425', Nombre: "SANTA MARIA PAPALO"},
      {Id:'426', Nombre: "SANTA MARIA PENOLES"},
      {Id:'427', Nombre: "SANTA MARIA PETAPA"},
      {Id:'428', Nombre: "SANTA MARIA QUIEGOLANI"},
      {Id:'429', Nombre: "SANTA MARIA SOLA"},
      {Id:'430', Nombre: "SANTA MARIA TATALTEPEC"},
      {Id:'431', Nombre: "SANTA MARIA TECOMAVACA"},
      {Id:'432', Nombre: "SANTA MARIA TEMAXCALAPA"},
      {Id:'433', Nombre: "SANTA MARIA TEMAXCALTEPEC"},
      {Id:'434', Nombre: "SANTA MARIA TEOPOXCO"},
      {Id:'435', Nombre: "SANTA MARIA TEPANTLALI"},
      {Id:'436', Nombre: "SANTA MARIA TEXCATITLAN"},
      {Id:'437', Nombre: "SANTA MARIA TLAHUITOLTEPEC"},
      {Id:'438', Nombre: "SANTA MARIA TLALIXTAC"},
      {Id:'439', Nombre: "SANTA MARIA TONAMECA"},
      {Id:'440', Nombre: "SANTA MARIA TOTOLAPILLA"},
      {Id:'441', Nombre: "SANTA MARIA XADANI"},
      {Id:'442', Nombre: "SANTA MARIA YALINA"},
      {Id:'443', Nombre: "SANTA MARIA YAVESIA"},
      {Id:'444', Nombre: "SANTA MARIA YOLOTEPEC"},
      {Id:'445', Nombre: "SANTA MARIA YOSOYUA"},
      {Id:'446', Nombre: "SANTA MARIA YUCUHITI"},
      {Id:'447', Nombre: "SANTA MARIA ZACATEPEC"},
      {Id:'448', Nombre: "SANTA MARIA ZANIZA"},
      {Id:'449', Nombre: "SANTA MARIA ZOQUITLAN"},
      {Id:'450', Nombre: "SANTIAGO AMOLTEPEC"},
      {Id:'451', Nombre: "SANTIAGO APOALA"},
      {Id:'452', Nombre: "SANTIAGO APOSTOL"},
      {Id:'453', Nombre: "SANTIAGO ASTATA"},
      {Id:'454', Nombre: "SANTIAGO ATITLAN"},
      {Id:'455', Nombre: "SANTIAGO AYUQUILILLA"},
      {Id:'456', Nombre: "SANTIAGO CACALOXTEPEC"},
      {Id:'457', Nombre: "SANTIAGO CAMOTLAN"},
      {Id:'459', Nombre: "SANTIAGO CHAZUMBA"},
      {Id:'460', Nombre: "SANTIAGO CHOAPAM"},
      {Id:'458', Nombre: "SANTIAGO COMALTEPEC"},
      {Id:'461', Nombre: "SANTIAGO DEL RIO"},
      {Id:'462', Nombre: "SANTIAGO HUAJOLOTITLAN"},
      {Id:'463', Nombre: "SANTIAGO HUAUCLILLA"},
      {Id:'464', Nombre: "SANTIAGO IHUITLAN PLUMAS"},
      {Id:'465', Nombre: "SANTIAGO IXCUINTEPEC"},
      {Id:'466', Nombre: "SANTIAGO IXTAYUTLA"},
      {Id:'467', Nombre: "SANTIAGO JAMILTEPEC"},
      {Id:'468', Nombre: "SANTIAGO JOCOTEPEC"},
      {Id:'469', Nombre: "SANTIAGO JUXTLAHUACA"},
      {Id:'470', Nombre: "SANTIAGO LACHIGUIRI"},
      {Id:'471', Nombre: "SANTIAGO LALOPA"},
      {Id:'472', Nombre: "SANTIAGO LAOLLAGA"},
      {Id:'473', Nombre: "SANTIAGO LAXOPA"},
      {Id:'474', Nombre: "SANTIAGO LLANO GRANDE"},
      {Id:'475', Nombre: "SANTIAGO MATATLAN"},
      {Id:'476', Nombre: "SANTIAGO MILTEPEC"},
      {Id:'477', Nombre: "SANTIAGO MINAS"},
      {Id:'478', Nombre: "SANTIAGO NACALTEPEC"},
      {Id:'479', Nombre: "SANTIAGO NEJAPILLA"},
      {Id:'66', Nombre: "SANTIAGO NILTEPEC"},
      {Id:'480', Nombre: "SANTIAGO NUNDICHE"},
      {Id:'481', Nombre: "SANTIAGO NUYOO"},
      {Id:'482', Nombre: "SANTIAGO PINOTEPA NACIONAL"},
      {Id:'483', Nombre: "SANTIAGO SUCHILQUITONGO"},
      {Id:'484', Nombre: "SANTIAGO TAMAZOLA"},
      {Id:'485', Nombre: "SANTIAGO TAPEXTLA"},
      {Id:'487', Nombre: "SANTIAGO TENANGO"},
      {Id:'488', Nombre: "SANTIAGO TEPETLAPA"},
      {Id:'489', Nombre: "SANTIAGO TETEPEC"},
      {Id:'490', Nombre: "SANTIAGO TEXCALCINGO"},
      {Id:'491', Nombre: "SANTIAGO TEXTITLAN"},
      {Id:'492', Nombre: "SANTIAGO TILANTONGO"},
      {Id:'493', Nombre: "SANTIAGO TILLO"},
      {Id:'494', Nombre: "SANTIAGO TLAZOYALTEPEC"},
      {Id:'495', Nombre: "SANTIAGO XANICA"},
      {Id:'496', Nombre: "SANTIAGO XIACUI"},
      {Id:'497', Nombre: "SANTIAGO YAITEPEC"},
      {Id:'498', Nombre: "SANTIAGO YAVEO"},
      {Id:'499', Nombre: "SANTIAGO YOLOMECATL"},
      {Id:'500', Nombre: "SANTIAGO YOSONDUA"},
      {Id:'501', Nombre: "SANTIAGO YUCUYACHI"},
      {Id:'502', Nombre: "SANTIAGO ZACATEPEC"},
      {Id:'503', Nombre: "SANTIAGO ZOOCHILA"},
      {Id:'506', Nombre: "SANTO DOMINGO ALBARRADAS"},
      {Id:'507', Nombre: "SANTO DOMINGO ARMENTA"},
      {Id:'508', Nombre: "SANTO DOMINGO CHIHUITAN"},
      {Id:'509', Nombre: "SANTO DOMINGO DE MORELOS"},
      {Id:'505', Nombre: "SANTO DOMINGO INGENIO"},
      {Id:'510', Nombre: "SANTO DOMINGO IXCATLAN"},
      {Id:'511', Nombre: "SANTO DOMINGO NUXAA"},
      {Id:'512', Nombre: "SANTO DOMINGO OZOLOTEPEC"},
      {Id:'513', Nombre: "SANTO DOMINGO PETAPA"},
      {Id:'514', Nombre: "SANTO DOMINGO ROAYAGA"},
      {Id:'515', Nombre: "SANTO DOMINGO TEHUANTEPEC"},
      {Id:'516', Nombre: "SANTO DOMINGO TEOJOMULCO"},
      {Id:'517', Nombre: "SANTO DOMINGO TEPUXTEPEC"},
      {Id:'518', Nombre: "SANTO DOMINGO TLATAYAPAM"},
      {Id:'519', Nombre: "SANTO DOMINGO TOMALTEPEC"},
      {Id:'520', Nombre: "SANTO DOMINGO TONALA"},
      {Id:'521', Nombre: "SANTO DOMINGO TONALTEPEC"},
      {Id:'522', Nombre: "SANTO DOMINGO XAGACIA"},
      {Id:'523', Nombre: "SANTO DOMINGO YANHUITLAN"},
      {Id:'524', Nombre: "SANTO DOMINGO YODOHINO"},
      {Id:'525', Nombre: "SANTO DOMINGO ZANATEPEC"},
      {Id:'530', Nombre: "SANTO TOMAS JALIEZA"},
      {Id:'531', Nombre: "SANTO TOMAS MAZALTEPEC"},
      {Id:'532', Nombre: "SANTO TOMAS OCOTEPEC"},
      {Id:'533', Nombre: "SANTO TOMAS TAMAZULAPAN"},
      {Id:'526', Nombre: "SANTOS REYES NOPALA"},
      {Id:'527', Nombre: "SANTOS REYES PAPALO"},
      {Id:'528', Nombre: "SANTOS REYES TEPEJILLO"},
      {Id:'529', Nombre: "SANTOS REYES YUCUNA"},
      {Id:'537', Nombre: "SILACAYOAPAM"},
      {Id:'538', Nombre: "SITIO DE XITLAPEHUA"},
      {Id:'539', Nombre: "SOLEDAD ETLA"},
      {Id:'31', Nombre: "TAMAZULAPAM DEL ESPIRITU SANTO"},
      {Id:'541', Nombre: "TANETZE DE ZARAGOZA"},
      {Id:'542', Nombre: "TANICHE"},
      {Id:'543', Nombre: "TATALTEPEC DE VALDES"},
      {Id:'544', Nombre: "TEOCOCUILCO DE MARCOS PEREZ"},
      {Id:'545', Nombre: "TEOTITLAN DE FLORES MAGON"},
      {Id:'546', Nombre: "TEOTITLAN DEL VALLE"},
      {Id:'547', Nombre: "TEOTONGO"},
      {Id:'548', Nombre: "TEPELMEME VILLA DE MORELOS"},
      {Id:'549', Nombre: "TEZOATLAN DE SEGURA Y LUNA"},
      {Id:'551', Nombre: "TLACOLULA DE MATAMOROS"},
      {Id:'552', Nombre: "TLACOTEPEC PLUMAS"},
      {Id:'553', Nombre: "TLALIXTAC DE CABRERA"},
      {Id:'554', Nombre: "TOTONTEPEC VILLA DE MORELOS"},
      {Id:'555', Nombre: "TRINIDAD ZAACHILA"},
      {Id:'557', Nombre: "UNION HIDALGO"},
      {Id:'558', Nombre: "VALERIO TRUJANO"},
      {Id:'405', Nombre: "VILLA DE CHILAPA DE DIAZ"},
      {Id:'338', Nombre: "VILLA DE ETLA"},
      {Id:'540', Nombre: "VILLA DE TAMAZULAPAM DEL PROGRESO"},
      {Id:'334', Nombre: "VILLA DE TUTUTEPEC DE MELCHOR OCAMPO"},
      {Id:'565', Nombre: "VILLA DE ZAACHILA"},
      {Id:'560', Nombre: "VILLA DIAZ ORDAZ"},
      {Id:'38', Nombre: "VILLA HIDALGO"},
      {Id:'277', Nombre: "VILLA SOLA DE VEGA"},
      {Id:'280', Nombre: "VILLA TALEA DE CASTRO"},
      {Id:'486', Nombre: "VILLA TEJUPAM DE LA UNION"},
      {Id:'561', Nombre: "YAXE"},
      {Id:'563', Nombre: "YOGANA"},
      {Id:'564', Nombre: "YUTANDUCHI DE GUERRERO"},
      {Id:'567', Nombre: "ZAPOTITLAN LAGUNAS"},
      {Id:'568', Nombre: "ZAPOTITLAN PALMAS"},
      {Id:'570', Nombre: "ZIMATLAN DE ALVAREZ"},
    ],
    [
      //PUEBLA
      {Id:'1', Nombre: "ACAJETE"},
      {Id:'2', Nombre: "ACATENO"},
      {Id:'3', Nombre: "ACATLAN"},
      {Id:'4', Nombre: "ACATZINGO"},
      {Id:'5', Nombre: "ACTEOPAN"},
      {Id:'6', Nombre: "AHUACATLAN"},
      {Id:'7', Nombre: "AHUATLAN"},
      {Id:'8', Nombre: "AHUAZOTEPEC"},
      {Id:'9', Nombre: "AHUEHUETITLA"},
      {Id:'10', Nombre: "AJALPAN"},
      {Id:'11', Nombre: "ALBINO ZERTUCHE"},
      {Id:'12', Nombre: "ALJOJUCA"},
      {Id:'13', Nombre: "ALTEPEXI"},
      {Id:'14', Nombre: "AMIXTLAN"},
      {Id:'15', Nombre: "AMOZOC"},
      {Id:'16', Nombre: "AQUIXTLA"},
      {Id:'17', Nombre: "ATEMPAN"},
      {Id:'18', Nombre: "ATEXCAL"},
      {Id:'80', Nombre: "ATLEQUIZAYAN"},
      {Id:'19', Nombre: "ATLIXCO"},
      {Id:'20', Nombre: "ATOYATEMPAN"},
      {Id:'21', Nombre: "ATZALA"},
      {Id:'22', Nombre: "ATZITZIHUACAN"},
      {Id:'23', Nombre: "ATZITZINTLA"},
      {Id:'24', Nombre: "AXUTLA"},
      {Id:'25', Nombre: "AYOTOXCO DE GUERRERO"},
      {Id:'26', Nombre: "CALPAN"},
      {Id:'27', Nombre: "CALTEPEC"},
      {Id:'28', Nombre: "CAMOCUAUTLA"},
      {Id:'99', Nombre: "CANADA MORELOS"},
      {Id:'29', Nombre: "CAXHUACAN"},
      {Id:'45', Nombre: "CHALCHICOMULA DE SESMA"},
      {Id:'46', Nombre: "CHAPULCO"},
      {Id:'47', Nombre: "CHIAUTLA"},
      {Id:'48', Nombre: "CHIAUTZINGO"},
      {Id:'50', Nombre: "CHICHIQUILA"},
      {Id:'49', Nombre: "CHICONCUAUTLA"},
      {Id:'51', Nombre: "CHIETLA"},
      {Id:'52', Nombre: "CHIGMECATITLAN"},
      {Id:'53', Nombre: "CHIGNAHUAPAN"},
      {Id:'54', Nombre: "CHIGNAUTLA"},
      {Id:'55', Nombre: "CHILA"},
      {Id:'56', Nombre: "CHILA DE LA SAL"},
      {Id:'58', Nombre: "CHILCHOTLA"},
      {Id:'59', Nombre: "CHINANTLA"},
      {Id:'30', Nombre: "COATEPEC"},
      {Id:'31', Nombre: "COATZINGO"},
      {Id:'32', Nombre: "COHETZALA"},
      {Id:'33', Nombre: "COHUECAN"},
      {Id:'34', Nombre: "CORONANGO"},
      {Id:'35', Nombre: "COXCATLAN"},
      {Id:'36', Nombre: "COYOMEAPAN"},
      {Id:'37', Nombre: "COYOTEPEC"},
      {Id:'38', Nombre: "CUAPIAXTLA DE MADERO"},
      {Id:'39', Nombre: "CUAUTEMPAN"},
      {Id:'40', Nombre: "CUAUTINCHAN"},
      {Id:'41', Nombre: "CUAUTLANCINGO"},
      {Id:'42', Nombre: "CUAYUCA DE ANDRADE"},
      {Id:'43', Nombre: "CUETZALAN DEL PROGRESO"},
      {Id:'44', Nombre: "CUYOACO"},
      {Id:'60', Nombre: "DOMINGO ARENAS"},
      {Id:'61', Nombre: "ELOXOCHITLAN"},
      {Id:'62', Nombre: "EPATLAN"},
      {Id:'63', Nombre: "ESPERANZA"},
      {Id:'64', Nombre: "FRANCISCO Z. MENA"},
      {Id:'65', Nombre: "GENERAL FELIPE ANGELES"},
      {Id:'66', Nombre: "GUADALUPE"},
      {Id:'67', Nombre: "GUADALUPE VICTORIA"},
      {Id:'68', Nombre: "HERMENEGILDO GALEANA"},
      {Id:'57', Nombre: "HONEY"},
      {Id:'69', Nombre: "HUAQUECHULA"},
      {Id:'70', Nombre: "HUATLATLAUCA"},
      {Id:'71', Nombre: "HUAUCHINANGO"},
      {Id:'72', Nombre: "HUEHUETLA"},
      {Id:'73', Nombre: "HUEHUETLAN EL CHICO"},
      {Id:'150', Nombre: "HUEHUETLAN EL GRANDE"},
      {Id:'74', Nombre: "HUEJOTZINGO"},
      {Id:'75', Nombre: "HUEYAPAN"},
      {Id:'76', Nombre: "HUEYTAMALCO"},
      {Id:'77', Nombre: "HUEYTLALPAN"},
      {Id:'78', Nombre: "HUITZILAN DE SERDAN"},
      {Id:'79', Nombre: "HUITZILTEPEC"},
      {Id:'81', Nombre: "IXCAMILPA DE GUERRERO"},
      {Id:'82', Nombre: "IXCAQUIXTLA"},
      {Id:'83', Nombre: "IXTACAMAXTITLAN"},
      {Id:'84', Nombre: "IXTEPEC"},
      {Id:'85', Nombre: "IZUCAR DE MATAMOROS"},
      {Id:'86', Nombre: "JALPAN"},
      {Id:'87', Nombre: "JOLALPAN"},
      {Id:'88', Nombre: "JONOTLA"},
      {Id:'89', Nombre: "JOPALA"},
      {Id:'90', Nombre: "JUAN C. BONILLA"},
      {Id:'91', Nombre: "JUAN GALINDO"},
      {Id:'92', Nombre: "JUAN N. MENDEZ"},
      {Id:'95', Nombre: "LA MAGDALENA TLATLAUQUITEPEC"},
      {Id:'93', Nombre: "LAFRAGUA"},
      {Id:'94', Nombre: "LIBRES"},
      {Id:'118', Nombre: "LOS REYES DE JUAREZ"},
      {Id:'96', Nombre: "MAZAPILTEPEC DE JUAREZ"},
      {Id:'97', Nombre: "MIXTLA"},
      {Id:'98', Nombre: "MOLCAXAC"},
      {Id:'100', Nombre: "NAUPAN"},
      {Id:'101', Nombre: "NAUZONTLA"},
      {Id:'102', Nombre: "NEALTICAN"},
      {Id:'103', Nombre: "NICOLAS BRAVO"},
      {Id:'104', Nombre: "NOPALUCAN"},
      {Id:'105', Nombre: "OCOTEPEC"},
      {Id:'106', Nombre: "OCOYUCAN"},
      {Id:'107', Nombre: "OLINTLA"},
      {Id:'108', Nombre: "ORIENTAL"},
      {Id:'109', Nombre: "PAHUATLAN"},
      {Id:'110', Nombre: "PALMAR DE BRAVO"},
      {Id:'111', Nombre: "PANTEPEC"},
      {Id:'112', Nombre: "PETLALCINGO"},
      {Id:'113', Nombre: "PIAXTLA"},
      {Id:'114', Nombre: "PUEBLA"},
      {Id:'115', Nombre: "QUECHOLAC"},
      {Id:'116', Nombre: "QUIMIXTLAN"},
      {Id:'117', Nombre: "RAFAEL LARA GRAJALES"},
      {Id:'119', Nombre: "SAN ANDRES CHOLULA"},
      {Id:'120', Nombre: "SAN ANTONIO CANADA"},
      {Id:'121', Nombre: "SAN DIEGO LA MESA TOCHIMILTZINGO"},
      {Id:'122', Nombre: "SAN FELIPE TEOTLALCINGO"},
      {Id:'123', Nombre: "SAN FELIPE TEPATLAN"},
      {Id:'124', Nombre: "SAN GABRIEL CHILAC"},
      {Id:'125', Nombre: "SAN GREGORIO ATZOMPA"},
      {Id:'126', Nombre: "SAN JERONIMO TECUANIPAN"},
      {Id:'127', Nombre: "SAN JERONIMO XAYACATLAN"},
      {Id:'128', Nombre: "SAN JOSE CHIAPA"},
      {Id:'129', Nombre: "SAN JOSE MIAHUATLAN"},
      {Id:'130', Nombre: "SAN JUAN ATENCO"},
      {Id:'131', Nombre: "SAN JUAN ATZOMPA"},
      {Id:'132', Nombre: "SAN MARTIN TEXMELUCAN"},
      {Id:'133', Nombre: "SAN MARTIN TOTOLTEPEC"},
      {Id:'134', Nombre: "SAN MATIAS TLALANCALECA"},
      {Id:'135', Nombre: "SAN MIGUEL IXITLAN"},
      {Id:'136', Nombre: "SAN MIGUEL XOXTLA"},
      {Id:'137', Nombre: "SAN NICOLAS BUENOS AIRES"},
      {Id:'138', Nombre: "SAN NICOLAS DE LOS RANCHOS"},
      {Id:'139', Nombre: "SAN PABLO ANICANO"},
      {Id:'140', Nombre: "SAN PEDRO CHOLULA"},
      {Id:'141', Nombre: "SAN PEDRO YELOIXTLAHUACA"},
      {Id:'142', Nombre: "SAN SALVADOR EL SECO"},
      {Id:'143', Nombre: "SAN SALVADOR EL VERDE"},
      {Id:'144', Nombre: "SAN SALVADOR HUIXCOLOTLA"},
      {Id:'145', Nombre: "SAN SEBASTIAN TLACOTEPEC"},
      {Id:'146', Nombre: "SANTA CATARINA TLALTEMPAN"},
      {Id:'147', Nombre: "SANTA INES AHUATEMPAN"},
      {Id:'148', Nombre: "SANTA ISABEL CHOLULA"},
      {Id:'149', Nombre: "SANTIAGO MIAHUATLAN"},
      {Id:'151', Nombre: "SANTO TOMAS HUEYOTLIPAN"},
      {Id:'152', Nombre: "SOLTEPEC"},
      {Id:'153', Nombre: "TECALI DE HERRERA"},
      {Id:'154', Nombre: "TECAMACHALCO"},
      {Id:'155', Nombre: "TECOMATLAN"},
      {Id:'156', Nombre: "TEHUACAN"},
      {Id:'157', Nombre: "TEHUITZINGO"},
      {Id:'158', Nombre: "TENAMPULCO"},
      {Id:'159', Nombre: "TEOPANTLAN"},
      {Id:'160', Nombre: "TEOTLALCO"},
      {Id:'161', Nombre: "TEPANCO DE LOPEZ"},
      {Id:'162', Nombre: "TEPANGO DE RODRIGUEZ"},
      {Id:'163', Nombre: "TEPATLAXCO DE HIDALGO"},
      {Id:'164', Nombre: "TEPEACA"},
      {Id:'165', Nombre: "TEPEMAXALCO"},
      {Id:'166', Nombre: "TEPEOJUMA"},
      {Id:'167', Nombre: "TEPETZINTLA"},
      {Id:'168', Nombre: "TEPEXCO"},
      {Id:'169', Nombre: "TEPEXI DE RODRIGUEZ"},
      {Id:'170', Nombre: "TEPEYAHUALCO"},
      {Id:'171', Nombre: "TEPEYAHUALCO DE CUAUHTEMOC"},
      {Id:'172', Nombre: "TETELA DE OCAMPO"},
      {Id:'173', Nombre: "TETELES DE AVILA CASTILLO"},
      {Id:'174', Nombre: "TEZIUTLAN"},
      {Id:'175', Nombre: "TIANGUISMANALCO"},
      {Id:'176', Nombre: "TILAPA"},
      {Id:'179', Nombre: "TLACHICHUCA"},
      {Id:'177', Nombre: "TLACOTEPEC DE BENITO JUAREZ"},
      {Id:'178', Nombre: "TLACUILOTEPEC"},
      {Id:'180', Nombre: "TLAHUAPAN"},
      {Id:'181', Nombre: "TLALTENANGO"},
      {Id:'182', Nombre: "TLANEPANTLA"},
      {Id:'183', Nombre: "TLAOLA"},
      {Id:'184', Nombre: "TLAPACOYA"},
      {Id:'185', Nombre: "TLAPANALA"},
      {Id:'186', Nombre: "TLATLAUQUITEPEC"},
      {Id:'187', Nombre: "TLAXCO"},
      {Id:'188', Nombre: "TOCHIMILCO"},
      {Id:'189', Nombre: "TOCHTEPEC"},
      {Id:'190', Nombre: "TOTOLTEPEC DE GUERRERO"},
      {Id:'191', Nombre: "TULCINGO"},
      {Id:'192', Nombre: "TUZAMAPAN DE GALEANA"},
      {Id:'193', Nombre: "TZICATLACOYAN"},
      {Id:'194', Nombre: "VENUSTIANO CARRANZA"},
      {Id:'195', Nombre: "VICENTE GUERRERO"},
      {Id:'196', Nombre: "XAYACATLAN DE BRAVO"},
      {Id:'197', Nombre: "XICOTEPEC"},
      {Id:'198', Nombre: "XICOTLAN"},
      {Id:'199', Nombre: "XIUTETELCO"},
      {Id:'200', Nombre: "XOCHIAPULCO"},
      {Id:'201', Nombre: "XOCHILTEPEC"},
      {Id:'202', Nombre: "XOCHITLAN DE VICENTE SUAREZ"},
      {Id:'203', Nombre: "XOCHITLAN TODOS SANTOS"},
      {Id:'204', Nombre: "YAONAHUAC"},
      {Id:'205', Nombre: "YEHUALTEPEC"},
      {Id:'206', Nombre: "ZACAPALA"},
      {Id:'207', Nombre: "ZACAPOAXTLA"},
      {Id:'208', Nombre: "ZACATLAN"},
      {Id:'209', Nombre: "ZAPOTITLAN"},
      {Id:'210', Nombre: "ZAPOTITLAN DE MENDEZ"},
      {Id:'211', Nombre: "ZARAGOZA"},
      {Id:'212', Nombre: "ZAUTLA"},
      {Id:'213', Nombre: "ZIHUATEUTLA"},
      {Id:'214', Nombre: "ZINACATEPEC"},
      {Id:'215', Nombre: "ZONGOZOTLA"},
      {Id:'216', Nombre: "ZOQUIAPAN"},
      {Id:'217', Nombre: "ZOQUITLAN"},
    ],
    [
      //QUERETARO
      {Id:'1', Nombre: "AMEALCO DE BONFIL"},
      {Id:'3', Nombre: "ARROYO SECO"},
      {Id:'4', Nombre: "CADEREYTA DE MONTES"},
      {Id:'5', Nombre: "COLON"},
      {Id:'6', Nombre: "CORREGIDORA"},
      {Id:'11', Nombre: "EL MARQUES"},
      {Id:'7', Nombre: "EZEQUIEL MONTES"},
      {Id:'8', Nombre: "HUIMILPAN"},
      {Id:'9', Nombre: "JALPAN DE SERRA"},
      {Id:'10', Nombre: "LANDA DE MATAMOROS"},
      {Id:'12', Nombre: "PEDRO ESCOBEDO"},
      {Id:'13', Nombre: "PENAMILLER"},
      {Id:'2', Nombre: "PINAL DE AMOLES"},
      {Id:'14', Nombre: "QUERETARO"},
      {Id:'15', Nombre: "SAN JOAQUIN"},
      {Id:'16', Nombre: "SAN JUAN DEL RIO"},
      {Id:'17', Nombre: "TEQUISQUIAPAN"},
      {Id:'18', Nombre: "TOLIMAN"},
    ],
    [
      //QUINTANA ROO
      {Id:'10', Nombre: "BACALAR"},
      {Id:'5', Nombre: "BENITO JUAREZ"},
      {Id:'1', Nombre: "COZUMEL"},
      {Id:'2', Nombre: "FELIPE CARRILLO PUERTO"},
      {Id:'3', Nombre: "ISLA MUJERES"},
      {Id:'6', Nombre: "JOSE MARIA MORELOS"},
      {Id:'7', Nombre: "LAZARO CARDENAS"},
      {Id:'4', Nombre: "OTHON P. BLANCO"},
      {Id:'11', Nombre: "PUERTO MORELOS"},
      {Id:'8', Nombre: "SOLIDARIDAD"},
      {Id:'9', Nombre: "TULUM"},
    ],
    [
      //SAN LUIS POTOSI
      {Id:'1', Nombre: "AHUALULCO"},
      {Id:'2', Nombre: "ALAQUINES"},
      {Id:'3', Nombre: "AQUISMON"},
      {Id:'4', Nombre: "ARMADILLO DE LOS INFANTE"},
      {Id:'53', Nombre: "AXTLA DE TERRAZAS"},
      {Id:'5', Nombre: "CARDENAS"},
      {Id:'6', Nombre: "CATORCE"},
      {Id:'7', Nombre: "CEDRAL"},
      {Id:'8', Nombre: "CERRITOS"},
      {Id:'9', Nombre: "CERRO DE SAN PEDRO"},
      {Id:'15', Nombre: "CHARCAS"},
      {Id:'10', Nombre: "CIUDAD DEL MAIZ"},
      {Id:'11', Nombre: "CIUDAD FERNANDEZ"},
      {Id:'13', Nombre: "CIUDAD VALLES"},
      {Id:'14', Nombre: "COXCATLAN"},
      {Id:'16', Nombre: "EBANO"},
      {Id:'58', Nombre: "EL NARANJO"},
      {Id:'17', Nombre: "GUADALCAZAR"},
      {Id:'18', Nombre: "HUEHUETLAN"},
      {Id:'19', Nombre: "LAGUNILLAS"},
      {Id:'20', Nombre: "MATEHUALA"},
      {Id:'57', Nombre: "MATLAPA"},
      {Id:'21', Nombre: "MEXQUITIC DE CARMONA"},
      {Id:'22', Nombre: "MOCTEZUMA"},
      {Id:'23', Nombre: "RAYON"},
      {Id:'24', Nombre: "RIOVERDE"},
      {Id:'25', Nombre: "SALINAS"},
      {Id:'26', Nombre: "SAN ANTONIO"},
      {Id:'27', Nombre: "SAN CIRO DE ACOSTA"},
      {Id:'28', Nombre: "SAN LUIS POTOSI"},
      {Id:'29', Nombre: "SAN MARTIN CHALCHICUAUTLA"},
      {Id:'30', Nombre: "SAN NICOLAS TOLENTINO"},
      {Id:'34', Nombre: "SAN VICENTE TANCUAYALAB"},
      {Id:'31', Nombre: "SANTA CATARINA"},
      {Id:'32', Nombre: "SANTA MARIA DEL RIO"},
      {Id:'33', Nombre: "SANTO DOMINGO"},
      {Id:'35', Nombre: "SOLEDAD DE GRACIANO SANCHEZ"},
      {Id:'36', Nombre: "TAMASOPO"},
      {Id:'37', Nombre: "TAMAZUNCHALE"},
      {Id:'38', Nombre: "TAMPACAN"},
      {Id:'39', Nombre: "TAMPAMOLON CORONA"},
      {Id:'40', Nombre: "TAMUIN"},
      {Id:'12', Nombre: "TANCANHUITZ"},
      {Id:'41', Nombre: "TANLAJAS"},
      {Id:'42', Nombre: "TANQUIAN DE ESCOBEDO"},
      {Id:'43', Nombre: "TIERRA NUEVA"},
      {Id:'44', Nombre: "VANEGAS"},
      {Id:'45', Nombre: "VENADO"},
      {Id:'56', Nombre: "VILLA DE ARISTA"},
      {Id:'46', Nombre: "VILLA DE ARRIAGA"},
      {Id:'47', Nombre: "VILLA DE GUADALUPE"},
      {Id:'48', Nombre: "VILLA DE LA PAZ"},
      {Id:'49', Nombre: "VILLA DE RAMOS"},
      {Id:'50', Nombre: "VILLA DE REYES"},
      {Id:'51', Nombre: "VILLA HIDALGO"},
      {Id:'52', Nombre: "VILLA JUAREZ"},
      {Id:'54', Nombre: "XILITLA"},
      {Id:'55', Nombre: "ZARAGOZA"},
    ],
    [
      //SINALOA
      {Id:'1', Nombre: "AHOME"},
      {Id:'2', Nombre: "ANGOSTURA"},
      {Id:'3', Nombre: "BADIRAGUATO"},
      {Id:'7', Nombre: "CHOIX"},
      {Id:'4', Nombre: "CONCORDIA"},
      {Id:'5', Nombre: "COSALA"},
      {Id:'6', Nombre: "CULIACAN"},
      {Id:'10', Nombre: "EL FUERTE"},
      {Id:'8', Nombre: "ELOTA"},
      {Id:'9', Nombre: "ESCUINAPA"},
      {Id:'11', Nombre: "GUASAVE"},
      {Id:'12', Nombre: "MAZATLAN"},
      {Id:'13', Nombre: "MOCORITO"},
      {Id:'18', Nombre: "NAVOLATO"},
      {Id:'14', Nombre: "ROSARIO"},
      {Id:'15', Nombre: "SALVADOR ALVARADO"},
      {Id:'16', Nombre: "SAN IGNACIO"},
      {Id:'17', Nombre: "SINALOA"},
    ],
    [
      //SONORA
      {Id:'1', Nombre: "ACONCHI"},
      {Id:'2', Nombre: "AGUA PRIETA"},
      {Id:'3', Nombre: "ALAMOS"},
      {Id:'4', Nombre: "ALTAR"},
      {Id:'5', Nombre: "ARIVECHI"},
      {Id:'6', Nombre: "ARIZPE"},
      {Id:'7', Nombre: "ATIL"},
      {Id:'8', Nombre: "BACADEHUACHI"},
      {Id:'9', Nombre: "BACANORA"},
      {Id:'10', Nombre: "BACERAC"},
      {Id:'11', Nombre: "BACOACHI"},
      {Id:'12', Nombre: "BACUM"},
      {Id:'13', Nombre: "BANAMICHI"},
      {Id:'14', Nombre: "BAVIACORA"},
      {Id:'15', Nombre: "BAVISPE"},
      {Id:'71', Nombre: "BENITO JUAREZ"},
      {Id:'16', Nombre: "BENJAMIN HILL"},
      {Id:'17', Nombre: "CABORCA"},
      {Id:'18', Nombre: "CAJEME"},
      {Id:'19', Nombre: "CANANEA"},
      {Id:'20', Nombre: "CARBO"},
      {Id:'22', Nombre: "CUCURPE"},
      {Id:'23', Nombre: "CUMPAS"},
      {Id:'24', Nombre: "DIVISADEROS"},
      {Id:'25', Nombre: "EMPALME"},
      {Id:'26', Nombre: "ETCHOJOA"},
      {Id:'27', Nombre: "FRONTERAS"},
      {Id:'70', Nombre: "GENERAL PLUTARCO ELIAS CALLES"},
      {Id:'28', Nombre: "GRANADOS"},
      {Id:'29', Nombre: "GUAYMAS"},
      {Id:'30', Nombre: "HERMOSILLO"},
      {Id:'31', Nombre: "HUACHINERA"},
      {Id:'32', Nombre: "HUASABAS"},
      {Id:'33', Nombre: "HUATABAMPO"},
      {Id:'34', Nombre: "HUEPAC"},
      {Id:'35', Nombre: "IMURIS"},
      {Id:'21', Nombre: "LA COLORADA"},
      {Id:'36', Nombre: "MAGDALENA"},
      {Id:'37', Nombre: "MAZATAN"},
      {Id:'38', Nombre: "MOCTEZUMA"},
      {Id:'39', Nombre: "NACO"},
      {Id:'40', Nombre: "NACORI CHICO"},
      {Id:'41', Nombre: "NACOZARI DE GARCIA"},
      {Id:'42', Nombre: "NAVOJOA"},
      {Id:'43', Nombre: "NOGALES"},
      {Id:'44', Nombre: "ONAVAS"},
      {Id:'45', Nombre: "OPODEPE"},
      {Id:'46', Nombre: "OQUITOA"},
      {Id:'47', Nombre: "PITIQUITO"},
      {Id:'48', Nombre: "PUERTO PENASCO"},
      {Id:'49', Nombre: "QUIRIEGO"},
      {Id:'50', Nombre: "RAYON"},
      {Id:'51', Nombre: "ROSARIO"},
      {Id:'52', Nombre: "SAHUARIPA"},
      {Id:'53', Nombre: "SAN FELIPE DE JESUS"},
      {Id:'72', Nombre: "SAN IGNACIO RIO MUERTO"},
      {Id:'54', Nombre: "SAN JAVIER"},
      {Id:'55', Nombre: "SAN LUIS RIO COLORADO"},
      {Id:'56', Nombre: "SAN MIGUEL DE HORCASITAS"},
      {Id:'57', Nombre: "SAN PEDRO DE LA CUEVA"},
      {Id:'58', Nombre: "SANTA ANA"},
      {Id:'59', Nombre: "SANTA CRUZ"},
      {Id:'60', Nombre: "SARIC"},
      {Id:'61', Nombre: "SOYOPA"},
      {Id:'62', Nombre: "SUAQUI GRANDE"},
      {Id:'63', Nombre: "TEPACHE"},
      {Id:'64', Nombre: "TRINCHERAS"},
      {Id:'65', Nombre: "TUBUTAMA"},
      {Id:'66', Nombre: "URES"},
      {Id:'67', Nombre: "VILLA HIDALGO"},
      {Id:'68', Nombre: "VILLA PESQUEIRA"},
      {Id:'69', Nombre: "YECORA"},
    ],
    [
      //TABASCO
      {Id:'1', Nombre: "BALANCAN"},
      {Id:'2', Nombre: "CARDENAS"},
      {Id:'3', Nombre: "CENTLA"},
      {Id:'4', Nombre: "CENTRO"},
      {Id:'5', Nombre: "COMALCALCO"},
      {Id:'6', Nombre: "CUNDUACAN"},
      {Id:'7', Nombre: "EMILIANO ZAPATA"},
      {Id:'8', Nombre: "HUIMANGUILLO"},
      {Id:'9', Nombre: "JALAPA"},
      {Id:'10', Nombre: "JALPA DE MENDEZ"},
      {Id:'11', Nombre: "JONUTA"},
      {Id:'12', Nombre: "MACUSPANA"},
      {Id:'13', Nombre: "NACAJUCA"},
      {Id:'14', Nombre: "PARAISO"},
      {Id:'15', Nombre: "TACOTALPA"},
      {Id:'16', Nombre: "TEAPA"},
      {Id:'17', Nombre: "TENOSIQUE"},
    ],
    [
      //TAMAULIPAS
      {Id:'1', Nombre: "ABASOLO"},
      {Id:'2', Nombre: "ALDAMA"},
      {Id:'3', Nombre: "ALTAMIRA"},
      {Id:'4', Nombre: "ANTIGUO MORELOS"},
      {Id:'5', Nombre: "BURGOS"},
      {Id:'6', Nombre: "BUSTAMANTE"},
      {Id:'7', Nombre: "CAMARGO"},
      {Id:'8', Nombre: "CASAS"},
      {Id:'9', Nombre: "CIUDAD MADERO"},
      {Id:'10', Nombre: "CRUILLAS"},
      {Id:'21', Nombre: "EL MANTE"},
      {Id:'11', Nombre: "GOMEZ FARIAS"},
      {Id:'12', Nombre: "GONZALEZ"},
      {Id:'14', Nombre: "GUERRERO"},
      {Id:'15', Nombre: "GUSTAVO DIAZ ORDAZ"},
      {Id:'13', Nombre: "G�EMEZ"},
      {Id:'16', Nombre: "HIDALGO"},
      {Id:'17', Nombre: "JAUMAVE"},
      {Id:'18', Nombre: "JIMENEZ"},
      {Id:'19', Nombre: "LLERA"},
      {Id:'20', Nombre: "MAINERO"},
      {Id:'22', Nombre: "MATAMOROS"},
      {Id:'23', Nombre: "MENDEZ"},
      {Id:'24', Nombre: "MIER"},
      {Id:'25', Nombre: "MIGUEL ALEMAN"},
      {Id:'26', Nombre: "MIQUIHUANA"},
      {Id:'27', Nombre: "NUEVO LAREDO"},
      {Id:'28', Nombre: "NUEVO MORELOS"},
      {Id:'29', Nombre: "OCAMPO"},
      {Id:'30', Nombre: "PADILLA"},
      {Id:'31', Nombre: "PALMILLAS"},
      {Id:'32', Nombre: "REYNOSA"},
      {Id:'33', Nombre: "RIO BRAVO"},
      {Id:'34', Nombre: "SAN CARLOS"},
      {Id:'35', Nombre: "SAN FERNANDO"},
      {Id:'36', Nombre: "SAN NICOLAS"},
      {Id:'37', Nombre: "SOTO LA MARINA"},
      {Id:'38', Nombre: "TAMPICO"},
      {Id:'39', Nombre: "TULA"},
      {Id:'40', Nombre: "VALLE HERMOSO"},
      {Id:'41', Nombre: "VICTORIA"},
      {Id:'42', Nombre: "VILLAGRAN"},
      {Id:'43', Nombre: "XICOTENCATL"},
    ],
    [
      //TLAXCALA
      {Id:'22', Nombre: "ACUAMANALA DE MIGUEL HIDALGO"},
      {Id:'1', Nombre: "AMAXAC DE GUERRERO"},
      {Id:'2', Nombre: "APETATITLAN DE ANTONIO CARVAJAL"},
      {Id:'5', Nombre: "APIZACO"},
      {Id:'3', Nombre: "ATLANGATEPEC"},
      {Id:'4', Nombre: "ATLTZAYANCA"},
      {Id:'45', Nombre: "BENITO JUAREZ"},
      {Id:'6', Nombre: "CALPULALPAN"},
      {Id:'10', Nombre: "CHIAUTEMPAN"},
      {Id:'18', Nombre: "CONTLA DE JUAN CUAMATZI"},
      {Id:'8', Nombre: "CUAPIAXTLA"},
      {Id:'9', Nombre: "CUAXOMULCO"},
      {Id:'7', Nombre: "EL CARMEN TEQUEXQUITLA"},
      {Id:'46', Nombre: "EMILIANO ZAPATA"},
      {Id:'12', Nombre: "ESPANITA"},
      {Id:'13', Nombre: "HUAMANTLA"},
      {Id:'14', Nombre: "HUEYOTLIPAN"},
      {Id:'15', Nombre: "IXTACUIXTLA DE MARIANO MATAMOROS"},
      {Id:'16', Nombre: "IXTENCO"},
      {Id:'48', Nombre: "LA MAGDALENA TLALTELULCO"},
      {Id:'47', Nombre: "LAZARO CARDENAS"},
      {Id:'17', Nombre: "MAZATECOCHCO DE JOSE MARIA MORELOS"},
      {Id:'11', Nombre: "MUNOZ DE DOMINGO ARENAS"},
      {Id:'21', Nombre: "NANACAMILPA DE MARIANO ARISTA"},
      {Id:'23', Nombre: "NATIVITAS"},
      {Id:'24', Nombre: "PANOTLA"},
      {Id:'41', Nombre: "PAPALOTLA DE XICOHTENCATL"},
      {Id:'49', Nombre: "SAN DAMIAN TEXOLOC"},
      {Id:'50', Nombre: "SAN FRANCISCO TETLANOHCAN"},
      {Id:'51', Nombre: "SAN JERONIMO ZACUALPAN"},
      {Id:'52', Nombre: "SAN JOSE TEACALCO"},
      {Id:'53', Nombre: "SAN JUAN HUACTZINCO"},
      {Id:'54', Nombre: "SAN LORENZO AXOCOMANITLA"},
      {Id:'55', Nombre: "SAN LUCAS TECOPILCO"},
      {Id:'25', Nombre: "SAN PABLO DEL MONTE"},
      {Id:'20', Nombre: "SANCTORUM DE LAZARO CARDENAS"},
      {Id:'56', Nombre: "SANTA ANA NOPALUCAN"},
      {Id:'57', Nombre: "SANTA APOLONIA TEACALCO"},
      {Id:'58', Nombre: "SANTA CATARINA AYOMETLA"},
      {Id:'59', Nombre: "SANTA CRUZ QUILEHTLA"},
      {Id:'26', Nombre: "SANTA CRUZ TLAXCALA"},
      {Id:'60', Nombre: "SANTA ISABEL XILOXOXTLA"},
      {Id:'27', Nombre: "TENANCINGO"},
      {Id:'28', Nombre: "TEOLOCHOLCO"},
      {Id:'19', Nombre: "TEPETITLA DE LARDIZABAL"},
      {Id:'29', Nombre: "TEPEYANCO"},
      {Id:'30', Nombre: "TERRENATE"},
      {Id:'31', Nombre: "TETLA DE LA SOLIDARIDAD"},
      {Id:'32', Nombre: "TETLATLAHUCA"},
      {Id:'33', Nombre: "TLAXCALA"},
      {Id:'34', Nombre: "TLAXCO"},
      {Id:'35', Nombre: "TOCATLAN"},
      {Id:'36', Nombre: "TOTOLAC"},
      {Id:'38', Nombre: "TZOMPANTEPEC"},
      {Id:'39', Nombre: "XALOZTOC"},
      {Id:'40', Nombre: "XALTOCAN"},
      {Id:'42', Nombre: "XICOHTZINCO"},
      {Id:'43', Nombre: "YAUHQUEMEHCAN"},
      {Id:'44', Nombre: "ZACATELCO"},
      {Id:'37', Nombre: "ZILTLALTEPEC DE TRINIDAD SANCHEZ SANTOS"},
    ],
    [
      //VERACRUZ
      {Id:'1', Nombre: "ACAJETE"},
      {Id:'2', Nombre: "ACATLAN"},
      {Id:'3', Nombre: "ACAYUCAN"},
      {Id:'4', Nombre: "ACTOPAN"},
      {Id:'5', Nombre: "ACULA"},
      {Id:'6', Nombre: "ACULTZINGO"},
      {Id:'204', Nombre: "AGUA DULCE"},
      {Id:'160', Nombre: "ALAMO TEMAPACHE"},
      {Id:'8', Nombre: "ALPATLAHUAC"},
      {Id:'9', Nombre: "ALTO LUCERO DE GUTIERREZ BARRIOS"},
      {Id:'10', Nombre: "ALTOTONGA"},
      {Id:'11', Nombre: "ALVARADO"},
      {Id:'12', Nombre: "AMATITLAN"},
      {Id:'14', Nombre: "AMATLAN DE LOS REYES"},
      {Id:'15', Nombre: "ANGEL R. CABADA"},
      {Id:'17', Nombre: "APAZAPAN"},
      {Id:'18', Nombre: "AQUILA"},
      {Id:'19', Nombre: "ASTACINGA"},
      {Id:'20', Nombre: "ATLAHUILCO"},
      {Id:'21', Nombre: "ATOYAC"},
      {Id:'22', Nombre: "ATZACAN"},
      {Id:'23', Nombre: "ATZALAN"},
      {Id:'25', Nombre: "AYAHUALULCO"},
      {Id:'26', Nombre: "BANDERILLA"},
      {Id:'27', Nombre: "BENITO JUAREZ"},
      {Id:'28', Nombre: "BOCA DEL RIO"},
      {Id:'29', Nombre: "CALCAHUALCO"},
      {Id:'7', Nombre: "CAMARON DE TEJEDA"},
      {Id:'30', Nombre: "CAMERINO Z. MENDOZA"},
      {Id:'208', Nombre: "CARLOS A. CARRILLO"},
      {Id:'31', Nombre: "CARRILLO PUERTO"},
      {Id:'157', Nombre: "CASTILLO DE TEAYO"},
      {Id:'32', Nombre: "CATEMACO"},
      {Id:'33', Nombre: "CAZONES DE HERRERA"},
      {Id:'34', Nombre: "CERRO AZUL"},
      {Id:'54', Nombre: "CHACALTIANGUIS"},
      {Id:'55', Nombre: "CHALMA"},
      {Id:'56', Nombre: "CHICONAMEL"},
      {Id:'57', Nombre: "CHICONQUIACO"},
      {Id:'58', Nombre: "CHICONTEPEC"},
      {Id:'59', Nombre: "CHINAMECA"},
      {Id:'60', Nombre: "CHINAMPA DE GOROSTIZA"},
      {Id:'62', Nombre: "CHOCAMAN"},
      {Id:'63', Nombre: "CHONTLA"},
      {Id:'64', Nombre: "CHUMATLAN"},
      {Id:'35', Nombre: "CITLALTEPETL"},
      {Id:'36', Nombre: "COACOATZINTLA"},
      {Id:'37', Nombre: "COAHUITLAN"},
      {Id:'38', Nombre: "COATEPEC"},
      {Id:'39', Nombre: "COATZACOALCOS"},
      {Id:'40', Nombre: "COATZINTLA"},
      {Id:'41', Nombre: "COETZALA"},
      {Id:'42', Nombre: "COLIPA"},
      {Id:'43', Nombre: "COMAPA"},
      {Id:'44', Nombre: "CORDOBA"},
      {Id:'45', Nombre: "COSAMALOAPAN DE CARPIO"},
      {Id:'46', Nombre: "COSAUTLAN DE CARVAJAL"},
      {Id:'47', Nombre: "COSCOMATEPEC"},
      {Id:'48', Nombre: "COSOLEACAQUE"},
      {Id:'49', Nombre: "COTAXTLA"},
      {Id:'50', Nombre: "COXQUIHUI"},
      {Id:'51', Nombre: "COYUTLA"},
      {Id:'52', Nombre: "CUICHAPA"},
      {Id:'53', Nombre: "CUITLAHUAC"},
      {Id:'205', Nombre: "EL HIGO"},
      {Id:'65', Nombre: "EMILIANO ZAPATA"},
      {Id:'66', Nombre: "ESPINAL"},
      {Id:'67', Nombre: "FILOMENO MATA"},
      {Id:'68', Nombre: "FORTIN"},
      {Id:'69', Nombre: "GUTIERREZ ZAMORA"},
      {Id:'70', Nombre: "HIDALGOTITLAN"},
      {Id:'71', Nombre: "HUATUSCO"},
      {Id:'72', Nombre: "HUAYACOCOTLA"},
      {Id:'73', Nombre: "HUEYAPAN DE OCAMPO"},
      {Id:'74', Nombre: "HUILOAPAN DE CUAUHTEMOC"},
      {Id:'75', Nombre: "IGNACIO DE LA LLAVE"},
      {Id:'76', Nombre: "ILAMATLAN"},
      {Id:'77', Nombre: "ISLA"},
      {Id:'78', Nombre: "IXCATEPEC"},
      {Id:'79', Nombre: "IXHUACAN DE LOS REYES"},
      {Id:'83', Nombre: "IXHUATLAN DE MADERO"},
      {Id:'80', Nombre: "IXHUATLAN DEL CAFE"},
      {Id:'82', Nombre: "IXHUATLAN DEL SURESTE"},
      {Id:'81', Nombre: "IXHUATLANCILLO"},
      {Id:'84', Nombre: "IXMATLAHUACAN"},
      {Id:'85', Nombre: "IXTACZOQUITLAN"},
      {Id:'86', Nombre: "JALACINGO"},
      {Id:'88', Nombre: "JALCOMULCO"},
      {Id:'89', Nombre: "JALTIPAN"},
      {Id:'90', Nombre: "JAMAPA"},
      {Id:'91', Nombre: "JESUS CARRANZA"},
      {Id:'93', Nombre: "JILOTEPEC"},
      {Id:'169', Nombre: "JOSE AZUETA"},
      {Id:'94', Nombre: "JUAN RODRIGUEZ CLARA"},
      {Id:'95', Nombre: "JUCHIQUE DE FERRER"},
      {Id:'16', Nombre: "LA ANTIGUA"},
      {Id:'127', Nombre: "LA PERLA"},
      {Id:'96', Nombre: "LANDERO Y COSS"},
      {Id:'61', Nombre: "LAS CHOAPAS"},
      {Id:'107', Nombre: "LAS MINAS"},
      {Id:'132', Nombre: "LAS VIGAS DE RAMIREZ"},
      {Id:'97', Nombre: "LERDO DE TEJADA"},
      {Id:'137', Nombre: "LOS REYES"},
      {Id:'98', Nombre: "MAGDALENA"},
      {Id:'99', Nombre: "MALTRATA"},
      {Id:'100', Nombre: "MANLIO FABIO ALTAMIRANO"},
      {Id:'101', Nombre: "MARIANO ESCOBEDO"},
      {Id:'102', Nombre: "MARTINEZ DE LA TORRE"},
      {Id:'103', Nombre: "MECATLAN"},
      {Id:'104', Nombre: "MECAYAPAN"},
      {Id:'105', Nombre: "MEDELLIN DE BRAVO"},
      {Id:'106', Nombre: "MIAHUATLAN"},
      {Id:'108', Nombre: "MINATITLAN"},
      {Id:'109', Nombre: "MISANTLA"},
      {Id:'110', Nombre: "MIXTLA DE ALTAMIRANO"},
      {Id:'111', Nombre: "MOLOACAN"},
      {Id:'206', Nombre: "NANCHITAL DE LAZARO CARDENAS DEL RIO"},
      {Id:'112', Nombre: "NAOLINCO"},
      {Id:'113', Nombre: "NARANJAL"},
      {Id:'13', Nombre: "NARANJOS AMATLAN"},
      {Id:'114', Nombre: "NAUTLA"},
      {Id:'115', Nombre: "NOGALES"},
      {Id:'116', Nombre: "OLUTA"},
      {Id:'117', Nombre: "OMEALCA"},
      {Id:'118', Nombre: "ORIZABA"},
      {Id:'119', Nombre: "OTATITLAN"},
      {Id:'120', Nombre: "OTEAPAN"},
      {Id:'121', Nombre: "OZULUAMA DE MASCARENAS"},
      {Id:'122', Nombre: "PAJAPAN"},
      {Id:'123', Nombre: "PANUCO"},
      {Id:'124', Nombre: "PAPANTLA"},
      {Id:'126', Nombre: "PASO DE OVEJAS"},
      {Id:'125', Nombre: "PASO DEL MACHO"},
      {Id:'128', Nombre: "PEROTE"},
      {Id:'129', Nombre: "PLATON SANCHEZ"},
      {Id:'130', Nombre: "PLAYA VICENTE"},
      {Id:'131', Nombre: "POZA RICA DE HIDALGO"},
      {Id:'133', Nombre: "PUEBLO VIEJO"},
      {Id:'134', Nombre: "PUENTE NACIONAL"},
      {Id:'135', Nombre: "RAFAEL DELGADO"},
      {Id:'136', Nombre: "RAFAEL LUCIO"},
      {Id:'138', Nombre: "RIO BLANCO"},
      {Id:'139', Nombre: "SALTABARRANCA"},
      {Id:'140', Nombre: "SAN ANDRES TENEJAPAN"},
      {Id:'141', Nombre: "SAN ANDRES TUXTLA"},
      {Id:'142', Nombre: "SAN JUAN EVANGELISTA"},
      {Id:'211', Nombre: "SAN RAFAEL"},
      {Id:'212', Nombre: "SANTIAGO SOCHIAPAN"},
      {Id:'143', Nombre: "SANTIAGO TUXTLA"},
      {Id:'144', Nombre: "SAYULA DE ALEMAN"},
      {Id:'146', Nombre: "SOCHIAPA"},
      {Id:'145', Nombre: "SOCONUSCO"},
      {Id:'147', Nombre: "SOLEDAD ATZOMPA"},
      {Id:'148', Nombre: "SOLEDAD DE DOBLADO"},
      {Id:'149', Nombre: "SOTEAPAN"},
      {Id:'150', Nombre: "TAMALIN"},
      {Id:'151', Nombre: "TAMIAHUA"},
      {Id:'152', Nombre: "TAMPICO ALTO"},
      {Id:'153', Nombre: "TANCOCO"},
      {Id:'154', Nombre: "TANTIMA"},
      {Id:'155', Nombre: "TANTOYUCA"},
      {Id:'209', Nombre: "TATAHUICAPAN DE JUAREZ"},
      {Id:'156', Nombre: "TATATILA"},
      {Id:'158', Nombre: "TECOLUTLA"},
      {Id:'159', Nombre: "TEHUIPANGO"},
      {Id:'161', Nombre: "TEMPOAL"},
      {Id:'162', Nombre: "TENAMPA"},
      {Id:'163', Nombre: "TENOCHTITLAN"},
      {Id:'164', Nombre: "TEOCELO"},
      {Id:'165', Nombre: "TEPATLAXCO"},
      {Id:'166', Nombre: "TEPETLAN"},
      {Id:'167', Nombre: "TEPETZINTLA"},
      {Id:'168', Nombre: "TEQUILA"},
      {Id:'170', Nombre: "TEXCATEPEC"},
      {Id:'171', Nombre: "TEXHUACAN"},
      {Id:'172', Nombre: "TEXISTEPEC"},
      {Id:'173', Nombre: "TEZONAPA"},
      {Id:'174', Nombre: "TIERRA BLANCA"},
      {Id:'175', Nombre: "TIHUATLAN"},
      {Id:'180', Nombre: "TLACHICHILCO"},
      {Id:'176', Nombre: "TLACOJALPAN"},
      {Id:'177', Nombre: "TLACOLULAN"},
      {Id:'178', Nombre: "TLACOTALPAN"},
      {Id:'179', Nombre: "TLACOTEPEC DE MEJIA"},
      {Id:'181', Nombre: "TLALIXCOYAN"},
      {Id:'182', Nombre: "TLALNELHUAYOCAN"},
      {Id:'24', Nombre: "TLALTETELA"},
      {Id:'183', Nombre: "TLAPACOYAN"},
      {Id:'184', Nombre: "TLAQUILPA"},
      {Id:'185', Nombre: "TLILAPAN"},
      {Id:'186', Nombre: "TOMATLAN"},
      {Id:'187', Nombre: "TONAYAN"},
      {Id:'188', Nombre: "TOTUTLA"},
      {Id:'207', Nombre: "TRES VALLES"},
      {Id:'189', Nombre: "TUXPAN"},
      {Id:'190', Nombre: "TUXTILLA"},
      {Id:'191', Nombre: "URSULO GALVAN"},
      {Id:'210', Nombre: "UXPANAPA"},
      {Id:'192', Nombre: "VEGA DE ALATORRE"},
      {Id:'193', Nombre: "VERACRUZ"},
      {Id:'194', Nombre: "VILLA ALDAMA"},
      {Id:'87', Nombre: "XALAPA"},
      {Id:'92', Nombre: "XICO"},
      {Id:'195', Nombre: "XOXOCOTLA"},
      {Id:'196', Nombre: "YANGA"},
      {Id:'197', Nombre: "YECUATLA"},
      {Id:'198', Nombre: "ZACUALPAN"},
      {Id:'199', Nombre: "ZARAGOZA"},
      {Id:'200', Nombre: "ZENTLA"},
      {Id:'201', Nombre: "ZONGOLICA"},
      {Id:'202', Nombre: "ZONTECOMATLAN DE LOPEZ Y FUENTES"},
      {Id:'203', Nombre: "ZOZOCOLCO DE HIDALGO"},
    ],
    [
      //YUCATAN
      {Id:'1', Nombre: "ABALA"},
      {Id:'2', Nombre: "ACANCEH"},
      {Id:'3', Nombre: "AKIL"},
      {Id:'4', Nombre: "BACA"},
      {Id:'5', Nombre: "BOKOBA"},
      {Id:'6', Nombre: "BUCTZOTZ"},
      {Id:'7', Nombre: "CACALCHEN"},
      {Id:'8', Nombre: "CALOTMUL"},
      {Id:'9', Nombre: "CANSAHCAB"},
      {Id:'10', Nombre: "CANTAMAYEC"},
      {Id:'11', Nombre: "CELESTUN"},
      {Id:'12', Nombre: "CENOTILLO"},
      {Id:'16', Nombre: "CHACSINKIN"},
      {Id:'17', Nombre: "CHANKOM"},
      {Id:'18', Nombre: "CHAPAB"},
      {Id:'19', Nombre: "CHEMAX"},
      {Id:'21', Nombre: "CHICHIMILA"},
      {Id:'20', Nombre: "CHICXULUB PUEBLO"},
      {Id:'22', Nombre: "CHIKINDZONOT"},
      {Id:'23', Nombre: "CHOCHOLA"},
      {Id:'24', Nombre: "CHUMAYEL"},
      {Id:'13', Nombre: "CONKAL"},
      {Id:'14', Nombre: "CUNCUNUL"},
      {Id:'15', Nombre: "CUZAMA"},
      {Id:'25', Nombre: "DZAN"},
      {Id:'26', Nombre: "DZEMUL"},
      {Id:'27', Nombre: "DZIDZANTUN"},
      {Id:'28', Nombre: "DZILAM DE BRAVO"},
      {Id:'29', Nombre: "DZILAM GONZALEZ"},
      {Id:'30', Nombre: "DZITAS"},
      {Id:'31', Nombre: "DZONCAUICH"},
      {Id:'32', Nombre: "ESPITA"},
      {Id:'33', Nombre: "HALACHO"},
      {Id:'34', Nombre: "HOCABA"},
      {Id:'35', Nombre: "HOCTUN"},
      {Id:'36', Nombre: "HOMUN"},
      {Id:'37', Nombre: "HUHI"},
      {Id:'38', Nombre: "HUNUCMA"},
      {Id:'39', Nombre: "IXIL"},
      {Id:'40', Nombre: "IZAMAL"},
      {Id:'41', Nombre: "KANASIN"},
      {Id:'42', Nombre: "KANTUNIL"},
      {Id:'43', Nombre: "KAUA"},
      {Id:'44', Nombre: "KINCHIL"},
      {Id:'45', Nombre: "KOPOMA"},
      {Id:'46', Nombre: "MAMA"},
      {Id:'47', Nombre: "MANI"},
      {Id:'48', Nombre: "MAXCANU"},
      {Id:'49', Nombre: "MAYAPAN"},
      {Id:'50', Nombre: "MERIDA"},
      {Id:'51', Nombre: "MOCOCHA"},
      {Id:'52', Nombre: "MOTUL"},
      {Id:'53', Nombre: "MUNA"},
      {Id:'54', Nombre: "MUXUPIP"},
      {Id:'55', Nombre: "OPICHEN"},
      {Id:'56', Nombre: "OXKUTZCAB"},
      {Id:'57', Nombre: "PANABA"},
      {Id:'58', Nombre: "PETO"},
      {Id:'59', Nombre: "PROGRESO"},
      {Id:'60', Nombre: "QUINTANA ROO"},
      {Id:'61', Nombre: "RIO LAGARTOS"},
      {Id:'62', Nombre: "SACALUM"},
      {Id:'63', Nombre: "SAMAHIL"},
      {Id:'65', Nombre: "SAN FELIPE"},
      {Id:'64', Nombre: "SANAHCAT"},
      {Id:'66', Nombre: "SANTA ELENA"},
      {Id:'67', Nombre: "SEYE"},
      {Id:'68', Nombre: "SINANCHE"},
      {Id:'69', Nombre: "SOTUTA"},
      {Id:'70', Nombre: "SUCILA"},
      {Id:'71', Nombre: "SUDZAL"},
      {Id:'72', Nombre: "SUMA"},
      {Id:'73', Nombre: "TAHDZIU"},
      {Id:'74', Nombre: "TAHMEK"},
      {Id:'75', Nombre: "TEABO"},
      {Id:'76', Nombre: "TECOH"},
      {Id:'77', Nombre: "TEKAL DE VENEGAS"},
      {Id:'78', Nombre: "TEKANTO"},
      {Id:'79', Nombre: "TEKAX"},
      {Id:'80', Nombre: "TEKIT"},
      {Id:'81', Nombre: "TEKOM"},
      {Id:'82', Nombre: "TELCHAC PUEBLO"},
      {Id:'83', Nombre: "TELCHAC PUERTO"},
      {Id:'84', Nombre: "TEMAX"},
      {Id:'85', Nombre: "TEMOZON"},
      {Id:'86', Nombre: "TEPAKAN"},
      {Id:'87', Nombre: "TETIZ"},
      {Id:'88', Nombre: "TEYA"},
      {Id:'89', Nombre: "TICUL"},
      {Id:'90', Nombre: "TIMUCUY"},
      {Id:'91', Nombre: "TINUM"},
      {Id:'92', Nombre: "TIXCACALCUPUL"},
      {Id:'93', Nombre: "TIXKOKOB"},
      {Id:'94', Nombre: "TIXMEHUAC"},
      {Id:'95', Nombre: "TIXPEHUAL"},
      {Id:'96', Nombre: "TIZIMIN"},
      {Id:'97', Nombre: "TUNKAS"},
      {Id:'98', Nombre: "TZUCACAB"},
      {Id:'99', Nombre: "UAYMA"},
      {Id:'100', Nombre: "UCU"},
      {Id:'101', Nombre: "UMAN"},
      {Id:'102', Nombre: "VALLADOLID"},
      {Id:'103', Nombre: "XOCCHEL"},
      {Id:'104', Nombre: "YAXCABA"},
      {Id:'105', Nombre: "YAXKUKUL"},
      {Id:'106', Nombre: "YOBAIN"},
    ],
    [
      //ZACATECAS
      {Id:'1', Nombre: "APOZOL"},
      {Id:'2', Nombre: "APULCO"},
      {Id:'3', Nombre: "ATOLINGA"},
      {Id:'4', Nombre: "BENITO JUAREZ"},
      {Id:'5', Nombre: "CALERA"},
      {Id:'6', Nombre: "CANITAS DE FELIPE PESCADOR"},
      {Id:'9', Nombre: "CHALCHIHUITES"},
      {Id:'7', Nombre: "CONCEPCION DEL ORO"},
      {Id:'8', Nombre: "CUAUHTEMOC"},
      {Id:'15', Nombre: "EL PLATEADO DE JOAQUIN AMARO"},
      {Id:'41', Nombre: "EL SALVADOR"},
      {Id:'10', Nombre: "FRESNILLO"},
      {Id:'12', Nombre: "GENARO CODINA"},
      {Id:'13', Nombre: "GENERAL ENRIQUE ESTRADA"},
      {Id:'14', Nombre: "GENERAL FRANCISCO R. MURGUIA"},
      {Id:'16', Nombre: "GENERAL PANFILO NATERA"},
      {Id:'17', Nombre: "GUADALUPE"},
      {Id:'18', Nombre: "HUANUSCO"},
      {Id:'19', Nombre: "JALPA"},
      {Id:'20', Nombre: "JEREZ"},
      {Id:'21', Nombre: "JIMENEZ DEL TEUL"},
      {Id:'22', Nombre: "JUAN ALDAMA"},
      {Id:'23', Nombre: "JUCHIPILA"},
      {Id:'24', Nombre: "LORETO"},
      {Id:'25', Nombre: "LUIS MOYA"},
      {Id:'26', Nombre: "MAZAPIL"},
      {Id:'27', Nombre: "MELCHOR OCAMPO"},
      {Id:'28', Nombre: "MEZQUITAL DEL ORO"},
      {Id:'29', Nombre: "MIGUEL AUZA"},
      {Id:'30', Nombre: "MOMAX"},
      {Id:'31', Nombre: "MONTE ESCOBEDO"},
      {Id:'32', Nombre: "MORELOS"},
      {Id:'33', Nombre: "MOYAHUA DE ESTRADA"},
      {Id:'34', Nombre: "NOCHISTLAN DE MEJIA"},
      {Id:'35', Nombre: "NORIA DE ANGELES"},
      {Id:'36', Nombre: "OJOCALIENTE"},
      {Id:'37', Nombre: "PANUCO"},
      {Id:'38', Nombre: "PINOS"},
      {Id:'39', Nombre: "RIO GRANDE"},
      {Id:'40', Nombre: "SAIN ALTO"},
      {Id:'58', Nombre: "SANTA MARIA DE LA PAZ"},
      {Id:'42', Nombre: "SOMBRERETE"},
      {Id:'43', Nombre: "SUSTICACAN"},
      {Id:'44', Nombre: "TABASCO"},
      {Id:'45', Nombre: "TEPECHITLAN"},
      {Id:'46', Nombre: "TEPETONGO"},
      {Id:'47', Nombre: "TEUL DE GONZALEZ ORTEGA"},
      {Id:'48', Nombre: "TLALTENANGO DE SANCHEZ ROMAN"},
      {Id:'57', Nombre: "TRANCOSO"},
      {Id:'11', Nombre: "TRINIDAD GARCIA DE LA CADENA"},
      {Id:'49', Nombre: "VALPARAISO"},
      {Id:'50', Nombre: "VETAGRANDE"},
      {Id:'51', Nombre: "VILLA DE COS"},
      {Id:'52', Nombre: "VILLA GARCIA"},
      {Id:'53', Nombre: "VILLA GONZALEZ ORTEGA"},
      {Id:'54', Nombre: "VILLA HIDALGO"},
      {Id:'55', Nombre: "VILLANUEVA"},
      {Id:'56', Nombre: "ZACATECAS"},
    ]
]
