<template>
    <div>
        <div class="calculadora-skandia">

            <div class="calculadora-skandia-titulo">
                <span>Calculadora de deducciones Skandia</span>
            </div>

            <div class="calculadora-skandia-campos row p-5">
                
                <div class="col-md-4">
                    <span class="calculadora-skandia-campos-titulo">Ingreso mensual</span>
                    <div 
                        class="inputwrapper"
                        data-required="MXM">
                        <input
                            v-model="ingresoMensual"
                            v-on:keypress="isNumber($event)"
                            v-on:keyup="revisaValor(listaCampos.INGRESO_MENSUAL, $event)"
                            @change="ajustaFormato(listaCampos.INGRESO_MENSUAL, $event)"
                            class="inputDataText" 
                            type="text" 
                            >
                    </div>
                </div>

                <div class="col-md-4">
                    <span class="calculadora-skandia-campos-titulo">Aguinaldo</span>
                    <div 
                        class="inputwrapper"
                        data-required="MXM">
                        <input
                            v-model="aguinaldo"
                            v-on:keypress="isNumber($event)"
                            v-on:keyup="revisaValor(listaCampos.AGUINALDO, $event)"
                            @change="ajustaFormato(listaCampos.AGUINALDO, $event)"
                            class="inputDataText" 
                            type="text" 
                            >
                    </div>
                </div>

                <div class="col-md-4">
                    <span class="calculadora-skandia-campos-titulo">Bonos</span>
                    <div 
                        class="inputwrapper"
                        data-required="MXM">
                        <input
                            v-model="bonos"
                            v-on:keypress="isNumber($event)"
                            v-on:keyup="revisaValor(listaCampos.BONOS, $event)"
                            @change="ajustaFormato(listaCampos.BONOS, $event)"
                            class="inputDataText" 
                            type="text" 
                            >
                    </div>
                </div>

            </div>

            <div class="calculadora-skandia-campos row p-5">
                
                <div class="col-md-4">
                    <span class="calculadora-skandia-campos-titulo">PPR anual (Plan Personal de Retiro)</span>
                    <div 
                        class="inputwrapper"
                        data-required="MXM">
                        <input
                            v-model="ppr"
                            v-on:keypress="isNumber($event)"
                            v-on:keyup="revisaValor(listaCampos.PPR, $event)"
                            @change="ajustaFormato(listaCampos.PPR, $event)"
                            class="inputDataText" 
                            type="text" 
                            >
                    </div>
                </div>

                <div class="col-md-4">
                    <span class="calculadora-skandia-campos-titulo">Gastos personales</span>
                    <div 
                        class="inputwrapper"
                        data-required="MXM">
                        <input
                            v-model="gastosPersonales"
                            v-on:keypress="isNumber($event)"
                            v-on:keyup="revisaValor(listaCampos.GASTOS_PERSONALES, $event)"
                            @change="ajustaFormato(listaCampos.GASTOS_PERSONALES, $event)"
                            class="inputDataText" 
                            type="text" 
                            >
                    </div>
                </div>

                <div class="col-md-4">
                    <span class="calculadora-skandia-campos-titulo">Colegiaturas</span>
                    <div 
                        class="inputwrapper"
                        data-required="MXM">
                        <input
                            v-model="colegiaturas"
                            v-on:keypress="isNumber($event)"
                            v-on:keyup="revisaValor(listaCampos.COLEGIATURAS, $event)"
                            @change="ajustaFormato(listaCampos.COLEGIATURAS, $event)"
                            class="inputDataText" 
                            type="text" 
                            >
                    </div>
                </div>

            </div>

            <div class="calculadora-skandia-campos row p-5">
                
                <div class="col-md-4">
                    <span class="calculadora-skandia-campos-titulo">Donativos</span>
                    <div 
                        class="inputwrapper"
                        data-required="MXM">
                        <input
                            v-model="donativos"
                            v-on:keypress="isNumber($event)"
                            v-on:keyup="revisaValor(listaCampos.DONATIVOS, $event)"
                            @change="ajustaFormato(listaCampos.DONATIVOS, $event)"
                            class="inputDataText" 
                            type="text" 
                            >
                    </div>
                </div>

                <div class="col-md-4">
                    <button 
                        class="btn btn-skandia-cotizar" 
                        @click="cotizarDeduccion()" 
                        :disabled="!camposRequeridos">Cotizar</button>
                </div>

                <div class="col-md-4">
                    <button 
                        v-if="mostrarResultados"
                        class="btn btn-skandia-descargar" 
                        @click="generaDeduccionPDF()">Descargar</button>
                </div>

            </div>
            
        </div>
        
        <!-- resultados -->
        <div
            v-if="mostrarResultados" 
            class="p-1 mt-5">
            <ul class="nav nav-tabs" id="calculadoraResultado" role="tablist">
                <li 
                    class="nav-item" 
                    role="presentation">

                    <button
                        class="nav-link active"
                        id="sindeduccion-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#sindeduccion" 
                        type="button"
                        role="tab" 
                        aria-controls="sindeduccion" 
                        aria-selected="true">
                        Resultado sin deducción
                    </button>

                </li>
                <li 
                    class="nav-item" 
                    role="presentation">

                    <button
                        class="nav-link"
                        id="condeduccion-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#condeduccion" 
                        type="button"
                        role="tab" 
                        aria-controls="condeduccion" 
                        aria-selected="true">
                        Resultado con deducción
                    </button>

                </li>
            </ul>

            <div 
                class="tab-content calculadora-resultado" 
                id="calculadoraResultadoContenedor">

                <div 
                    class="tab-pane fade show active" 
                    id="sindeduccion" 
                    role="tabpanel" 
                    aria-labelledby="sindeduccion-tab">

                    <div class="tab-body">
                        <div class="calculadora-resultado-titulo">
                            <span>Resultados de la deducción</span>
                        </div>

                        <div class="calculadora-skandia-campos row p-5">
                            
                            <div class="col-md-4">
                                <span class="calculadora-skandia-campos-titulo">Ingreso anual bruto</span>
                                <div 
                                    class="inputwrapper"
                                    data-required="MXM">
                                    <input
                                        v-model="resultadoSinDeduccion.IngresoAnualBruto"
                                        class="inputDataText" 
                                        type="text" 
                                        readonly>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <span class="calculadora-skandia-campos-titulo">Ingreso Grabable</span>
                                <div 
                                    class="inputwrapper"
                                    data-required="MXM">
                                    <input
                                        v-model="resultadoSinDeduccion.IngresoGrabable"
                                        class="inputDataText" 
                                        type="text" 
                                        readonly>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <span class="calculadora-skandia-campos-titulo">Cuota Fija Anual</span>
                                <div 
                                    class="inputwrapper"
                                    data-required="MXM">
                                    <input
                                        v-model="resultadoSinDeduccion.CuotaFijaAnual"
                                        class="inputDataText" 
                                        type="text" 
                                        readonly>
                                </div>
                            </div>

                        </div>

                        <div class="calculadora-skandia-campos row p-5">
                            
                            <div class="col-md-4">
                                <span class="calculadora-skandia-campos-titulo">% Sobre el excedente</span>
                                <div 
                                    class="inputwrapper"
                                    data-required="MXM">
                                    <input
                                        v-model="resultadoSinDeduccion.SobreExcedente"
                                        class="inputDataText" 
                                        type="text" 
                                        readonly>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <span class="calculadora-skandia-campos-titulo">Impuestos totales</span>
                                <div 
                                    class="inputwrapper"
                                    data-required="MXM">
                                    <input
                                        v-model="resultadoSinDeduccion.ImpuestoTotal"
                                        class="inputDataText" 
                                        type="text" 
                                        readonly>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                </div>

                <div 
                    class="tab-pane fade show" 
                    id="condeduccion" 
                    role="tabpanel" 
                    aria-labelledby="condeduccion-tab">

                    <div class="tab-body">
                        <div class="calculadora-resultado-titulo">
                            <span>Resultados de la deducción</span>
                        </div>

                        <div class="calculadora-skandia-campos row p-5">
                            
                            <div class="col-md-4">
                                <span class="calculadora-skandia-campos-titulo">Ingreso anual bruto</span>
                                <div 
                                    class="inputwrapper"
                                    data-required="MXM">
                                    <input
                                        v-model="resultadoConDeduccion.IngresoAnualBruto"
                                        class="inputDataText" 
                                        type="text" 
                                        readonly>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <span class="calculadora-skandia-campos-titulo">Ingreso Grabable</span>
                                <div 
                                    class="inputwrapper"
                                    data-required="MXM">
                                    <input
                                        v-model="resultadoConDeduccion.IngresoGrabable"
                                        class="inputDataText" 
                                        type="text" 
                                        readonly>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <span class="calculadora-skandia-campos-titulo">Cuota Fija Anual</span>
                                <div 
                                    class="inputwrapper"
                                    data-required="MXM">
                                    <input
                                        v-model="resultadoConDeduccion.CuotaFijaAnual"
                                        class="inputDataText" 
                                        type="text" 
                                        readonly>
                                </div>
                            </div>

                        </div>

                        <div class="calculadora-skandia-campos row p-5">
                            
                            <div class="col-md-4">
                                <span class="calculadora-skandia-campos-titulo">% Sobre el excedente</span>
                                <div 
                                    class="inputwrapper"
                                    data-required="MXM">
                                    <input
                                        v-model="resultadoConDeduccion.SobreExcedente"
                                        class="inputDataText" 
                                        type="text" 
                                        readonly>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <span class="calculadora-skandia-campos-titulo">Impuestos totales</span>
                                <div 
                                    class="inputwrapper"
                                    data-required="MXM">
                                    <input
                                        v-model="resultadoConDeduccion.ImpuestoTotal"
                                        class="inputDataText" 
                                        type="text" 
                                        readonly>
                                </div>
                            </div>

                        </div>

                        <div class="calculadora-cinta-contenedor">

                            <div id="calculadoraCinta" class="calculadora-skandia-campos row py-5 px-2">
                                
                                <div class="col-md-4">
                                    <span class="calculadora-skandia-campos-titulo">Ahorro mensual PPR</span>
                                    <div 
                                        class="inputwrapper"
                                        data-required="MXM">
                                        <input
                                            v-model="resultadoConDeduccion.AhorroMensual"
                                            class="inputDataText" 
                                            type="text" 
                                            readonly>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <span class="calculadora-skandia-campos-titulo">Impuestos a favor</span>
                                    <div 
                                        class="inputwrapper"
                                        data-required="MXM">
                                        <input
                                            v-model="resultadoConDeduccion.ImpuestoFavor"
                                            class="inputDataText" 
                                            type="text" 
                                            readonly>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <span class="calculadora-skandia-campos-titulo">Ahorro mensual en impuestos</span>
                                    <div 
                                        class="inputwrapper"
                                        data-required="MXM">
                                        <input
                                            v-model="resultadoConDeduccion.AhorroImpuesto"
                                            class="inputDataText" 
                                            type="text" 
                                            readonly>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    
                </div>

            </div>

        </div>

    </div>

</template>

<script>

export default {
	components: {},
	data() {
		return  {
            idCaso: '',
            apiKey: '',
			listaCampos: {
				INGRESO_MENSUAL: 1,
				AGUINALDO: 2,
				BONOS: 3,
				PPR: 4,
				GASTOS_PERSONALES: 5,
				COLEGIATURAS: 6,
				DONATIVOS: 7,
			},
            ingresoMensual: '',
            aguinaldo: '',
            bonos: '',
            ppr: '',
            gastosPersonales: '',
            colegiaturas: '',
            donativos: '',
            mostrarResultados: false,
            resultadoSinDeduccion: {
                IngresoAnualBruto: '0.00',
                IngresoGrabable: '0.00',
                CuotaFijaAnual: '0.00',
                SobreExcedente: '0.00',
                ImpuestoTotal: '0.00',
            },
            resultadoConDeduccion: {
                IngresoAnualBruto: '0.00',
                IngresoGrabable: '0.00',
                CuotaFijaAnual: '0.00',
                SobreExcedente: '0.00',
                ImpuestoTotal: '0.00',
                AhorroMensual: '0.00',
                ImpuestoFavor: '0.00',
                AhorroImpuesto: '0.00',
            },
		}
	},
	watch: {
		
	},
	computed: {
        camposRequeridos() { // al menos un campo de estos 3 tiene que tener valor
            return  this.validaNumero(this.ingresoMensual) ||
                    this.validaNumero(this.aguinaldo) ||
                    this.validaNumero(this.bonos);
        }
	},
	methods: {
        isNumber: function (evt) {
            evt = evt ? evt : window.event
            var charCode = evt.which ? evt.which : evt.keyCode
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
		},
        revisaValor(campo, evt) {
            let campoValor = evt.target.value.replace(/,/g,"");
            const regex = /^(\d{1,8})(\.\d{0,2})?$/;
            const matches = regex.test(campoValor);
            
            if (matches === true){
                campoValor = campoValor;
            } else{
                campoValor = campoValor.replace(/.$/, '');
            }

            this.actualizaCampo(campo, campoValor)
        },
        ajustaFormato(campo, evt) {            
            let campoValor = evt.target.value;

			if (campoValor == '') { 
                campoValor = 0; 
            }
			campoValor = parseFloat(campoValor).toFixed(2);
			const exp = /(\d)(?=(\d{3})+(?!\d))/g;
			const rep = '$1,'
			campoValor = campoValor.toString().replace(exp, rep);

            this.actualizaCampo(campo, campoValor);
        },
        actualizaCampo(campo, valor) {
            switch (campo) {
                case 1:
                    this.ingresoMensual = valor;
                    break;
                case 2:
                    this.aguinaldo = valor;
                    break;
                case 3:
                    this.bonos = valor;
                    break;
                case 4:
                    this.ppr = valor;
                    break;
                case 5:
                    this.gastosPersonales = valor;
                    break;
                case 6:
                    this.colegiaturas = valor;
                    break;
                case 7:
                    this.donativos = valor;
                    break;
                default:
                    break;
            }
        },
        validaNumero(numero) {

            // console.log( 'validaNumero', numero );

            let numeroValido = false;
            numero = parseFloat(numero.replaceAll(',', '')); // retira comas
            // validaciones
            if (numero != '' && numero > 0) {
                numeroValido = true;
            }
            return numeroValido;
        },
        cotizarDeduccion(registrar = true) { // api skandia
            let self = this;
            // valida o asignar por defecto para cotizar y descargar 
            this.ingresoMensual = this.ingresoMensual ? this.ingresoMensual : '0.00';
            this.aguinaldo = this.aguinaldo ? this.aguinaldo : '0.00';
            this.bonos = this.bonos ? this.bonos : '0.00';
            this.ppr = this.ppr ? this.ppr : '0.00';
            this.gastosPersonales = this.gastosPersonales ? this.gastosPersonales : '0.00';
            this.colegiaturas = this.colegiaturas ? this.colegiaturas : '0.00';
            this.donativos = this.donativos ? this.donativos : '0.00';

            let objReqSkandia = {
                "strApiKey": this.apiKey,
                "strMetodo": "IngresoSkandia",
                "objSkandia": {
                    "IngresoMensual": this.ingresoMensual,
                    "Aguinaldo": this.aguinaldo,
                    "Bonos": this.bonos,
                    "PPR": this.ppr,
                    "GastosPersonales": this.gastosPersonales,
                    "Colegiaturas": this.colegiaturas,
                    "Donativos": this.donativos,
                }
            }
            
            this.$axios.post('/', objReqSkandia, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
                .then((response) => {
					if (response.data.intEstatus == 0) {
                        
                        let cotizacion = response.data.objContenido;

                        // sin deduccion
                        this.resultadoSinDeduccion.IngresoAnualBruto = cotizacion.IngresoAnualBruto;
                        this.resultadoSinDeduccion.IngresoGrabable = cotizacion.IngresoGravableSinDeduccion;
                        this.resultadoSinDeduccion.CuotaFijaAnual = cotizacion.CuotaFijaAnualSinDeduccion;
                        this.resultadoSinDeduccion.SobreExcedente = cotizacion.PorcentajeSobreExcedenteSinDeduccion;
                        this.resultadoSinDeduccion.ImpuestoTotal = cotizacion.ImpuestosTotalesSinDeduccion;

                        // con deduccion
                        this.resultadoConDeduccion.IngresoAnualBruto = cotizacion.IngresoAnualBruto;
                        this.resultadoConDeduccion.IngresoGrabable = cotizacion.IngresoGravableConDeduccion;
                        this.resultadoConDeduccion.CuotaFijaAnual = cotizacion.CuotaFijaAnualConDeduccion;
                        this.resultadoConDeduccion.SobreExcedente = cotizacion.PorcentajeSobreExcedenteConDeduccion;
                        this.resultadoConDeduccion.ImpuestoTotal = cotizacion.ImpuestosTotalesConDeduccion;
                        this.resultadoConDeduccion.AhorroMensual = cotizacion.AhorroMensualPPR;
                        this.resultadoConDeduccion.ImpuestoFavor = cotizacion.ImpuestosFavor;
                        this.resultadoConDeduccion.AhorroImpuesto = cotizacion.AhorroMensualImpuestos;

                        // guardar resultados y envio a api si se da clic al boton cotizar
                        if (registrar) {
                            this.guardarDeduccion(objReqSkandia.objSkandia, this.idCaso);
                        }

                        // cuando se complete la primera cotizacion y se obtenga la respuesta del api
                        this.mostrarResultados = true;
                        
                    } else {
                        this.$vs.notify({
                            title: 'Ocurrio un error en IngresoSkandia',
                            text: response.data.strError,
                            color: 'danger',
                            position: 'top-right',
                        });
                    }
                }).catch(function (error) {
                    self.$vs.notify({
                        title: 'Ocurrio un error de sistema',
                        text: error,
                        color: 'danger',
                        position: 'top-right',
                    });
                });
        },
        guardarDeduccion(objSkandia, idCaso) {
            let self = this;
            let objReqDeduccion = {
                "strApiKey": this.apiKey,
                "strMetodo": "RegistraDeduccion",
                "objSkandia": objSkandia,
                "intCaso": idCaso,
            }

            this.$axios.post('/', objReqDeduccion, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
                .then((response) => {
					if (response.data.intEstatus == 0) {

                        let resp = response.data.objContenido;
                        // console.log( resp );

                    } else {
                        this.$vs.notify({
                            title: 'Ocurrio un error en RegistraDeduccion',
                            text: response.data.strError,
                            color: 'danger',
                            position: 'top-right',
                        });
                    }
                }).catch(function (error) {
                    self.$vs.notify({
                        title: 'Ocurrio un error de sistema',
                        text: error,
                        color: 'danger',
                        position: 'top-right',
                    });
                });
        },
        obtenerDeduccion() {
            let self = this;
            let objReqDeduccion = {
                "strApiKey": this.apiKey,
                "strMetodo": "obtenerDeduccion",
                "intCaso": this.idCaso,
            }

            this.$axios.post('/', objReqDeduccion, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
                .then((response) => {
					if (response.data.intEstatus == 0) {
                        
                        let cotizacion = response.data.objContenido;
                        // valida si tiene una cotizacion asociada
                        if (cotizacion.TieneDeduccion) {
                            // parametros iniciales
                            this.ingresoMensual = cotizacion.IngresoMensual;
                            this.aguinaldo = cotizacion.Aguinaldo;
                            this.bonos = cotizacion.Bonos;
                            this.ppr = cotizacion.Ppr;
                            this.gastosPersonales = cotizacion.GastosPersonales;
                            this.colegiaturas = cotizacion.Colegiaturas;
                            this.donativos = cotizacion.Donativos;

                            this.mostrarResultados = true; // muestro el panel de resultados

                            // realizo una cotizacion con valores iniciales
                            this.cotizarDeduccion(false);
                        }

                        // console.log( cotizacion );

                    } else {
                        this.$vs.notify({
                            title: 'Ocurrio un error en obtenerDeduccion',
                            text: response.data.strError,
                            color: 'danger',
                            position: 'top-right',
                        });
                    }
                }).catch(function (error) {
                    self.$vs.notify({
                        title: 'Ocurrio un error de sistema',
                        text: error,
                        color: 'danger',
                        position: 'top-right',
                    });
                });
        },
        generaDeduccionPDF() {
            const $objParametros = {
                'IngresoMensual': this.ingresoMensual,
                'Aguinaldo': this.aguinaldo,
                'Bonos': this.bonos,
                'PPR': this.ppr,
                'GastosPersonales': this.gastosPersonales,
                'Colegiaturas': this.colegiaturas,
                'Donativos': this.donativos,
            }
            const $objRestulados = {
                'SinDeduccion': this.resultadoSinDeduccion,
                'ConDeduccion': this.resultadoConDeduccion
            }

            const url = `https://cotizador.socasesores.com/admin/formatos/SolicitudesAdicionales/DeduccionSkandia.php?s=${this.idCaso}&p=${JSON.stringify($objParametros)}&r=${JSON.stringify($objRestulados)}`;
            window.location.href = url;
        },
        obtenerCaso() {
            if (this.$store.state.AppActiveUser.ApiKey) { // caso
                this.idCaso = this.$route.params.id;
            } else {
                this.$router.push('/login').catch(() => {})
            }
        },
        obtenerApiKey() {
			if (!JSON.parse(localStorage.getItem('userInfo')).ApiKey) {
				this.$router.push('/login')
			} else {
				this.apiKey = JSON.parse(localStorage.getItem('userInfo')).ApiKey
			}
        }
	},
	mounted() {
        this.obtenerCaso();
        this.obtenerApiKey();
        this.obtenerDeduccion();
	},
}
</script>

<style scoped>
.calculadora-skandia {
    background-color: #FFFFFF;
    border: 1px solid #DEE2E6;
    border-radius: 10px;
    margin: 5px;
}
.calculadora-skandia-titulo {
    padding: 10px;
    text-align: center;
    background-color: #F1F4FF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid #DEE2E6;
}
.calculadora-skandia-titulo span {
    font-weight: 600;
    font-size: 15px;
    color: gray;
}
.calculadora-skandia-campos-titulo {
    font-weight: 600;
    font-size: 14px;
}
.btn-skandia-cotizar {
    color: #FFFFFF;
    background-color: deepskyblue;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    margin-top: 30px;
}
.btn-skandia-descargar {
    color: #FFFFFF;
    background-color: limegreen;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    margin-top: 30px;
}

.nav-item {
    background-color: #F1F4FF;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.tab-body {
    padding: 0;
}
.calculadora-resultado-titulo {
    padding: 10px;
    text-align: center;
    background-color: #F1F4FF;
}
.calculadora-resultado-titulo span {
    font-weight: 600;
    font-size: 15px;
    color: gray;
}
.calculadora-cinta-contenedor {
    margin-left: auto;
    margin-right: auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}
#calculadoraCinta {
    background-color: #C1FFED;
    border-end-start-radius: 10px;
    border-end-end-radius: 10px;
}
</style>