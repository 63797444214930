<template lang="html"> 
  <div class="">
    <div class="">
      <div class="">
        <vx-card  class="">
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Calle</span>
              <input class="vs-input inputMayus" v-validate="'required'" v-model="domicilio.calle" name="calle" maxlength="50"  v-on:keypress="alfanumericos($event)" @change="updateValores()"/>
              <span class="text-danger span-placeholder" v-show="errors.has('calle')">Campo requerido, no se permiten caracteres especiales</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Numero exterior</span>
              <input class="vs-input" v-validate="'required'" v-model="domicilio.numeroExterior" name="numeroExterior" maxlength="20" v-on:keypress="alfanumericos($event)" @change="updateValores()" >
              <span class="text-danger span-placeholder" v-show="errors.has('numeroExterior')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Número interior</span>
              <input class="vs-input"  v-model="domicilio.numeroInterior" name="numeroInterior" maxlength="20" v-on:keypress="alfanumericos($event)" @change="updateValores()"/>
            </div>
          </div>
        <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Código postal</span>
              <input class="vs-input" type="text"  v-validate="'required'" v-model="domicilio.codigoPostal" name="codigoPostal" v-on:keypress="isNumber($event)" maxlength="5" @change="updateValores()"/>
              <span class="text-danger span-placeholder" v-show="errors.has('codigoPostal')">Campo requerido</span>
            </div>  
            <div class="col-md-4">
              <span class="span-placeholder" for="nacionalidad">Colonia</span>
             <input class="vs-input"  v-model="domicilio.colonia" name="colonia"  maxlength="30" v-on:keypress="soloLetras($event)" @change="updateValores()"/>
            </div>
            <div class="col-md-4" >
              <span class="span-placeholder" for="Municipio">Delegación/Municipio</span>
             <input class="vs-input" label="municipio" v-model="domicilio.municipio" name="municipio" maxlength="30" v-on:keypress="soloLetras($event)" @change="updateValores()"/>
            </div>
        </div>
        <div class="row w-full">
            <div class="col-md-4">
              <span class=" span-placeholder ">Tiempo de Residencia</span>
                <br>
              <select name="yearsResidencia" style="width:25%" id="yearsResidencia"  v-model="domicilio.yearsResidencia" @change="updateValores()" class="vs-input">
                <option :value='tipo.Id' v-for="tipo in years"  :key="">{{tipo.label}}</option>
              </select>
              <span class=" span-placeholder ">Año(s)</span>
               <select name="monthResidencia" style="width:25%" id="monthResidencia"  v-model="domicilio.monthResidencia" @change="updateValores()" class="vs-input ">
                <option :value='tipo.Id' v-for="tipo in month"  :key="">{{tipo.label}}</option>
              </select>
              <span class=" span-placeholder ">Mes(es)</span>
              <!--<span class="text-danger span-placeholder" v-show="errors.has('fechaNacimiento')">Campo requerido</span>-->
 
            </div>
            <div class="col-md-4">
              <span class="span-placeholder" for="telefonoFijo" >Teléfono fijo</span>
             <input class="vs-input"  v-model="domicilio.telefonoFijo" name="telefonoFijo" v-validate="'required|min:10|max:10'" maxlength="10" v-on:keypress="isNumber($event)" @change="updateValores()"/>
             <span class="text-danger span-placeholder" v-show="errors.has('telefonoFijo')">Campo requerido a 10 dígitos</span>
            </div>
            <div class="col-md-4" >
              <span class="span-placeholder" for="tipoCasa">Vive en casa</span>
              <select name="tipoCasa" id="tipoCasa"  v-model="domicilio.tipoCasa" @change="updateValores()" class="vs-input">
                <option :value='tipo.Id' v-for="tipo in tipoCasas"  :key="">{{tipo.Nombre}}</option>
              </select>
              <!--<input class="vs-input" label="tipoCasa" v-model="domicilio.tipoCasa" name="username" @change="updateValores()"/>-->
            </div>
        </div>    
        </vx-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data(){
		return{
            domicilio:{
                calle:'',
                numeroExterior:'',
                numeroInterior:'',
                codigoPostal:'',
                colonia:'',
                municipio:'',
                yearsResidencia:0,
                monthResidencia:0,
                telefonoFijo:'',
                tipoCasa:'',
                validaDomicilio:false
            },
            years:[
                {label:0,Id:0},
                {label:1,Id:1},
                {label:2,Id:2},
                {label:3,Id:3},
                {label:4,Id:4},
                {label:5,Id:5},
                {label:6,Id:6},
                {label:7,Id:7},
                {label:8,Id:8},
                {label:9,Id:9},
                {label:10,Id:10},
                {label:11,Id:11},
                {label:12,Id:12},
                {label:13,Id:13},
                {label:14,Id:14},
                {label:15,Id:15},
                {label:16,Id:16},
                {label:17,Id:17},
                {label:18,Id:18},
                {label:19,Id:19},
                {label:20,Id:20},
                {label:21,Id:21},
                {label:22,Id:22},
                {label:23,Id:23},
                {label:24,Id:24},
                {label:25,Id:25},
                {label:26,Id:26},
                {label:27,Id:27},
                {label:28,Id:28},
                {label:29,Id:29},
                {label:30,Id:30},
                {label:31,Id:31},
                {label:32,Id:32},
                {label:33,Id:33},
                {label:34,Id:34},
                {label:35,Id:35},
                {label:36,Id:36},
                {label:37,Id:37},
                {label:38,Id:38},
                {label:39,Id:39},
                {label:40,Id:40},
                {label:41,Id:41},
                {label:42,Id:42},
                {label:43,Id:43},
                {label:44,Id:44},
                {label:45,Id:45},
                {label:46,Id:46},
                {label:47,Id:47},
                {label:48,Id:48},
                {label:49,Id:49},
                {label:50,Id:50},
            ],
            month:[
                {label:0,Id:0},
                {label:1,Id:1},
                {label:2,Id:2},
                {label:3,Id:3},
                {label:4,Id:4},
                {label:5,Id:5},
                {label:6,Id:6},
                {label:7,Id:7},
                {label:8,Id:8},
                {label:9,Id:9},
                {label:10,Id:10},
                {label:11,Id:11},
            ],
            tipoCasas:[],
            validaDomicilio:false,
            email:''
        }
    }, 
    mounted(){
        this.id=this.$route.params.id
        this.getApikey()
        this.getTipoCasa()
        this.dameCaso()
        //this.dameSolicitante()
        //this.updateValores()
    },        
    methods:{
        tipoCasaComparador(tipoCasas){
        //1 rentado 51
            //2 propio sin hipoteca 53
            //3 propio hipotecado 52
            //4 de familiares 54
            //5 prestado
            //6 otro
            switch(tipoCasas) {
                case 1:
                return 51
                case 2:
                return 52
                case 3:
                return 53
                case 4:
                return 54  
                default:
                return 0
            }
        },
        dameCaso(){
            let self= this
            var objRequestDameCaso = {
                strApiKey: this.key,
                strMetodo: 'DameCaso',
                objCaso: {
                Id: this.id
                }
            }
            this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(
                response => {
                if(response.data.intEstatus == 0){
                    this.email= response.data.objContenido.Solicitante
                    //let coacreditado=response.data.objContenido.Coacreditado
                    /*if (coacreditado.length) {
                    if (coacreditado[0].EsConyuge && coacreditado[0].ParticipaCredito) {
                        this.conyuge.esConyugue=coacreditado[0].EsConyuge
                        this.conyuge.email=coacreditado[0].EMail
                        this.conyuge.seraCoPropietario=coacreditado[0].SeraCoPropietario
                        this.conyuge.consolidaIngresos=coacreditado[0].ConsolidaIngresos
                        this.conyuge.parentesco=coacreditado[0].Parentesco
                        this.conyuge.participaCredito=coacreditado[0].ParticipaCredito
                    }else {
                        this.coacreditado.esConyugue=coacreditado[0].EsConyuge
                        this.coacreditado.email2=coacreditado[0].EMail
                        this.coacreditado.email=coacreditado[0].EMail
                        this.coacreditado.seraCoPropietario=coacreditado[0].SeraCoPropietario
                        this.coacreditado.consolidaIngresos=coacreditado[0].ConsolidaIngresos
                        this.coacreditado.parentesco=coacreditado[0].Parentesco
                        this.coacreditado.participaCredito=coacreditado[0].ParticipaCredito
                    }
                    }*/
                    this.dameSolicitante()
                    this.dameDatosPersonales()
                }else {
                    this.$vs.notify({
                    title:'Ocurrio un error en DameCaso',
                    text:response.data.strError,
                    color:'danger',
                    position:'top-right'
                    })
                }
                }
            ).catch(function (error) {
                self.$vs.notify({
                title:'Ocurrio un error de sistema',
                text:error,
                color:'danger',
                position:'top-right'
                })
            })
            },
        dameDatosPersonales(){
            let self= this
            var objRequestDameSolicitante = {
                strApiKey: this.key,
                strMetodo: "DamePrecalificacionBancos",
                objPrecalificacionBanco:
                {
                    IdPrecalificacion: this.id
                }
            }
            this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(
                response => {
                if(response.data.intEstatus == 0){
                    let solicitante =response.data.objContenido
                    this.domicilio.yearsResidencia=solicitante[0].TiempoResidencia
                    this.domicilio.monthResidencia=solicitante[0].TiempoResidenciaMeses
                    this.updateValores()
                }
                }
            ).catch(function (error) {
                self.$vs.notify({
                title:'Ocurrio un error de sistema',
                text:error,
                color:'danger',
                position:'top-right'
                })
            })
            },
        dameSolicitante(){
        let self= this
        var objRequestDameSolicitante = {
            strApiKey: this.key,
            strMetodo: 'DameSolicitante',
            objSolicitante: {
            EMail: this.email
            }
        }
        this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
            response => {
            if(response.data.intEstatus == 0){
                let solicitante =response.data.objContenido
                
                this.domicilio.calle = solicitante.Calle
                this.domicilio.numeroExterior = solicitante.NumeroExterior
                this.domicilio.numeroInterior = solicitante.NumeroInterior
                this.domicilio.colonia = solicitante.Colonia

                this.domicilio.municipio = solicitante.Municipio
                this.domicilio.curp = solicitante.Curp
                this.domicilio.codigoPostal = solicitante.CodigoPostal
                this.domicilio.tipoCasa=this.tipoCasaComparador(solicitante.TipoOcupacion)
                this.domicilio.telefonoFijo=solicitante.TelefonoCasa
                this.updateValores()
            }else {
                this.$vs.notify({
                title:'Ocurrio un error en DameSolicitante',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
                })
            }
            }
        ).catch(function (error) {
            self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
            })
        })
        },
        updateValores(){
            if(this.domicilio.calle != '' && this.domicilio.calle!=null && this.domicilio.numeroExterior !='' && this.domicilio.numeroExterior!==null && this.domicilio.codigoPostal!='' && this.domicilio.codigoPostal!==null && this.domicilio.colonia !='' && this.domicilio.colonia!=null && this.domicilio.municipio !='' && this.domicilio.municipio!==null  && this.domicilio.telefonoFijo != '' && this.domicilio.telefonoFijo!=null && this.domicilio.telefonoFijo.length==10 && this.domicilio.tipoCasa!='' && (this.domicilio.monthResidencia >0 || this.domicilio.yearsResidencia>0)){
                this.domicilio.validaDomicilio=true
            }else{
                this.domicilio.validaDomicilio=false
            }
            this.$emit('updateValores',this.domicilio)
        },
        getApikey(){
            if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
                this.$router.push('/login');
            }else {
                this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
            }
        },
        getTipoCasa(){
            let self=this
            var objRequestListaTipoCasas = {
                strApiKey: this.key,
                strMetodo: 'CatalogosGeneralesBancos',
                objCatalogo:
                {
                    TipoCatalogo: "8"
                }
            }
            this.$axios.post('/',objRequestListaTipoCasas,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(
                response => {
                if(response.data.intEstatus == 0){ 
                    this.tipoCasas=response.data.objContenido 
                }else {
                    this.$vs.notify({
                    title:'Ocurrio un error en ListaSectoresLaborales',
                    text:response.data.strError,
                    color:'danger',
                    position:'top-right'
                    })
                }
                }
            ).catch(function (error) {
                self.$vs.notify({
                title:'Ocurrio un error de sistema',
                text:error,
                color:'danger',
                position:'top-right'
                })
      })
    },
        soloLetras: function(event) {
            var regex = new RegExp("^[áéíóúüña-zÑÁÉÍÓÚA-Z ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
            event.preventDefault();
            return false;
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        alfanumericos: function(event) {
            var regex = new RegExp("^[a-zA-Z0-9 ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    }
}
</script>
<style scoped>
.inputMayus{
    text-transform: uppercase;
}
</style>