export const comprobantes = [
  {Id: 1, Nombre: "RECIBO DE NOMINA"},
  {Id: 10, Nombre: "ESTADO DE CUENTA DE CREDITO"},
  {Id: 2, Nombre: "DECLARACION(ES) QUE COMPRENDEN 12 MESES"},
  {Id: 3, Nombre: "DECLARACION(ES) QUE COMPRENDEN 9 MESES"},
  {Id: 4, Nombre: "DECLARACION(ES) QUE COMPRENDEN 6 MESES"},
  {Id: 5, Nombre: "FORMATO CASA DE BOLSA (TIPO DE INVERSION)"},
  {Id: 6, Nombre: "CARTA SALDOS PROMEDIO INVERSION ULT. 6 MESES"},
  {Id: 7, Nombre: "CARTA SALDOS PROMEDIO INVERSION ULT. 4 MESES"},
  {Id: 8, Nombre: "ESTADOS DE CUENTA SCOTIABANK INVERLAT (4 ULT.)"},
  {Id: 9, Nombre: "ESTADOS DE CUENTA OTROS BANCOS (4 ULT.)"},
]
