<template>
  <div class="" id='sisec'>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Información del cliente</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Información del Crédito</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Documentos</button>
  </li>

</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

    <div class="">

      <vx-card class="">

        <div class="flex justify-end items-center mb-10">
          
          <button 
            class="btn btn-second" 
            @click="toggleSave()" 
            v-bind:hidden="!isPersonalEdit">Editar</button>
          <button 
            v-bind:hidden="isPersonalEdit" 
            class="btn btn-second" @click="RegistraSolicitanteSisec(); toggleSave()" 
            :disabled="!completeInfov3">Guardar información</button>
            <!--  :disabled="!validate" -->
        </div>
        
        <div class="row w-full">
          <div class="col-md-3">
            <span class="span-placeholder">Nombre</span>
            <input 
              id="sisecNombres"
              v-bind:disabled="isPersonalEdit" 
              v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
              v-validate="'required|alpha_spaces'" 
              v-model="sisec.Nombres" v-on:keypress="soloLetras($event)" name="nombres" />
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Segundo nombre</span>
            <input 
              v-bind:disabled="isPersonalEdit" 
              v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
              maxlength="20" 
              v-model="sisec.SegundoNombre" v-on:keypress="soloLetras($event)" name="segNombres" />
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Apellido Paterno</span>
            <input 
              id="sisecApellidoPaterno"
              v-bind:disabled="isPersonalEdit" 
              v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
              v-validate="'required|alpha_spaces'" v-model="sisec.ApellidoPaterno" v-on:keypress="soloLetras($event)" name="apellidoPaterno" />
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Apellido Materno</span>
            <input 
              v-bind:disabled="isPersonalEdit" 
              v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
              v-validate="'required|alpha_spaces'" 
              v-model="sisec.ApellidoMaterno" v-on:keypress="soloLetras($event)" name="apellidoMaterno" />            
          </div>
        </div>

        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder">CURP</span>
            <input 
              id="sisecCURP"
              v-bind:disabled="isPersonalEdit" 
              v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
              v-model="sisec.CURP" 
              name="curp" v-validate="'min:18|max:18'" v-mask="'AAAA######AAAAAANN'" value="" onkeyup="javascript:this.value=this.value.toUpperCase();" />            
            <span class="text-danger span-placeholder" v-show="errors.has('curp')">El campo debe tener 18 caracteres</span>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">RFC</span>
            <input 
              id="sisecRFC"
              v-bind:disabled="isPersonalEdit" 
              v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
              v-model="sisec.RFC" 
              name="rfc" 
              v-validate="'min:13|max:13'" 
              v-mask="'AAAA######NNN'" onkeyup="javascript:this.value=this.value.toUpperCase();" />
            <span class="text-danger span-placeholder" v-show="errors.has('rfc')">El campo debe tener 13 caracteres</span>
          </div>
          <div class="col-md-4">
            <label class="span-placeholder">Correo electrónico</label>
            <input
              id="sisecEMail"
              v-bind:disabled="isPersonalEdit" 
              v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
              class="vs-input" v-validate="'required|email'" v-model="sisec.EMail" name="email" disabled/>                   
          </div>
        </div>
        
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder">Teléfono celular (10 dígitos)</span>
            <div class="input-group" style="flex-wrap: no-wrap">              
              <input 
                id="sisecTelefonoCelular"
                v-bind:disabled="isPersonalEdit" 
                type="text" 
                v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="sisec.TelefonoCelular" 
                minlength="10" maxlength="10" placeholder="Número a 10 dígitos" aria-label="Username" aria-describedby="basic-addon1" v-on:keypress="SoloNumero($event)" />	                             
            </div>					
          </div>
          <div class="col-md-4">
            <span class="span-placeholder" for="sisecEstadoCivil">Estado Civil</span>
            <select 
              v-bind:disabled="isPersonalEdit" 
              name="cars" 
              id="sisecEstadoCivil" 
              v-model="sisec.EstadoCivil" 
              v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
            >
              <option :value="''">Seleccione...</option>
              <option :value="tipo.value" v-for="tipo in Catalogo_estadoCivil">{{ tipo.label }}</option>
            </select>
          </div>
         <div class="col-md-4">
						<span class="span-placeholder" for="sisecTipoEmpleo">Tipo de empleo *</span>
						<select name="cars" id="sisecTipoEmpleo" v-model="sisec.TipoEmpleo" 
            v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
            v-bind:disabled="isPersonalEdit" class="vs-input">
							<option :value="''">Seleccione...</option>
							<option :value="empleo.value" v-for="empleo in Catalogo_tipoEmpleo">{{ empleo.label }}</option>
						</select>
					</div>
        </div>
        <div class="row w-full">
          <div class="col-md-3">
            <span class="span-placeholder" for="sisecNacionalidad">Nacionalidad</span>
            <select 
              v-bind:disabled="isPersonalEdit" 
              name="cars" id="sisecNacionalidad" 
              v-model="sisec.Nacionalidad" 
              v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
            >
              <option :value="''">Seleccione...</option>
              <option :value="'Mexicano'">Mexicano</option>   
              <option :value="'Extranjero'">Extranjero </option>              
            </select>
            <!-- <input class="vs-input" label="Nacionalidad" v-model="nacionalidad" name="username" />-->
          </div>
          <div class="col-md-2">
            <span class="span-placeholder" for="sexo">Coacreditado</span>
                <vs-checkbox v-bind:disabled="isPersonalEdit" v-model="sisec.Coacreditado" vs-name="sisec.coacreditado" class="add-margin"></vs-checkbox>
          </div>
          <div class="col-md-3">
            <span class="span-placeholder" for="sexo">Dependientes economicos</span>
            <div id="sisecDependientesEconomicos" class="col-md-4 alinear">
              <div class="col-md-10 ml-0">                
                <vs-radio v-bind:disabled="isPersonalEdit" v-model="sisec.DependientesEconomicos" vs-name="dependientes" vs-value="No"  class="add-margin"></vs-radio>
                <span class="span-placeholder">No</span>
              </div>
              <div class="col-md-8 ml-6">                
                <vs-radio v-bind:disabled="isPersonalEdit" v-model="sisec.DependientesEconomicos" vs-name="dependientes" vs-value="Si"  class="add-margin"></vs-radio>
                <span class="span-placeholder">Si</span>  
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder" for="sexo">Genero</span>
            <div id="sisecGenero" class="col-md-4 alinear">
              <div class="col-md-12 ml-0">                
                <vs-radio v-bind:disabled="isPersonalEdit" v-model="sisec.Genero" vs-name="sexo" vs-value="Masculino"  class="add-margin"></vs-radio>
                <span class="span-placeholder">Hombre</span>
              </div>
              <div class="col-md-12 ml-12">                
                <vs-radio v-bind:disabled="isPersonalEdit" v-model="sisec.Genero" vs-name="sexo" vs-value="Femenino"  class="add-margin"></vs-radio>
                <span class="span-placeholder">Mujer</span>  
              </div>
            </div>
          </div>
        </div>

        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder">Hijos</span>             
              <select 
                id="sisecNumeroHijos"
                v-bind:disabled="isPersonalEdit" 
                name="hijos" 
                v-model="sisec.NumeroHijos" 
                v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                @change = "valueNumeroHijos()">
                <option :value="''">Seleccione...</option>
                <option :value="hijos.value" v-for="hijos in catalogo_hijos_autos">{{ hijos.label }}</option>
              </select>

          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Edades de los hijos</span>
              <select 
                id="sisecEdadHijos"
                v-bind:disabled="isPersonalEdit" 
                name="hijos" 
                v-model="sisec.EdadHijos" 
                v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']">
                <option :value="''">Seleccione...</option>
                <option :value="hijosEdad.value" v-for="hijosEdad in catalogo_edad_hijos">{{ hijosEdad.label }}</option>
              </select>

          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Codigo postal</span>
            <input 
              id="sisecCodigoPostal"
              v-bind:disabled="isPersonalEdit" 
              v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
              label="País de residencia"  maxlength="5"
              v-model="sisec.CodigoPostal" />
          </div>
        </div>

        <div class="row w-full">
          <div class="col-md-4">
						<span class="span-placeholder" for="sisecNumeroAutos">Automoviles</span>
						<select id="sisecNumeroAutos" name="cars" v-model="sisec.NumeroAutos" 
            v-bind:disabled="isPersonalEdit" 
            v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"class="vs-input">
							<option :value="''">Seleccione...</option>
              <option :value="autos.value" v-for="autos in catalogo_hijos_autos">{{ autos.label }}</option>
						</select>
					</div>
          <div class="col-md-4">
						<span class="span-placeholder" for="sisecIngresos">Ingresos</span>
						<select name="cars" id="sisecIngresos" v-model="sisec.Ingresos" 
            v-bind:disabled="isPersonalEdit" 
            v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"class="vs-input">
							<option :value="''">Seleccione...</option>
							<option :value="ingresos.value" v-for="ingresos in catalogo_ingresos">{{ ingresos.label }}</option>
						</select>
					</div>
          <div class="col-md-4">
						<span class="span-placeholder" for="sisecGradoEstudios">Grado de estudio</span>
						<select name="cars" id="sisecGradoEstudios" v-model="sisec.GradoEstudios" 
            v-bind:disabled="isPersonalEdit" 
            v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"class="vs-input">
							<option :value="''">Seleccione...</option>
							<option :value="gradoEstudio.value" v-for="gradoEstudio in catalogo_gradoEstudio">{{ gradoEstudio.label }}</option>
						</select>
					</div>
        </div>
      
          </vx-card>
      </div>




        <!--<div title="" class="tab-body">
          <div class="row w-full">
            <tr>
              <td class="font-semibold">Nombre*</td>
              <td>{{ sisec.Nombres + ' ' + sisec.SegundoNombre }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Apellidos*</td>
              <td>{{ sisec.ApellidoPaterno }} {{ sisec.ApellidoMaterno }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Sexo*</td>
              <td>{{ sisec.Genero }}</td>
            </tr>
            <tr>
              <td class="font-semibold">EDAD*</td>
              <td>{{ sisec.Edad }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Sexo*</td>
              <td>{{ solicitante.Sexo }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Sexo*</td>
              <td>{{ solicitante.Sexo }}</td>
            </tr>
            <tr>
              <td class="font-semibold">CURP*</td>
              <td>{{ sisec.CURP }}</td>
            </tr>
            <tr>
              <td class="font-semibold">RFC*</td>
              <td>{{ sisec.RFC }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Correo electrónico*</td>
              <td>{{ sisec.EMail }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Telefono*</td>
              <td>{{ sisec.TelefonoCelular }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Estado civil*</td>
              <td>{{ sisec.EstadoCivil }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Tipo de empleo*</td>
              <td>{{ sisec.TipoEmpleo }}</td>
            </tr>
            
            <tr>
              <td class="font-semibold">Nacionalidad*</td>
              <td>{{sisec.Nacionalidad}}</td>
            </tr>
           <tr>
              <td class="font-semibold">Coacreditado</td>
              <td><vs-checkbox v-model="sisec.Coacreditado" disabled></vs-checkbox></td>
            </tr>
            <tr>
              <td class="font-semibold">Dependientes económicos</td>
              <td>{{sisec.DependientesEconomicos}}</td>
            </tr>
            <tr>
              <td class="font-semibold">Hijos</td>
              <td>{{sisec.NumeroHijos}}</td>
            </tr>
            <tr>
              <td class="font-semibold">Edades de los hijos</td>
              <td>{{sisec.EdadHijos}}</td>
            </tr>
            <tr>
              <td class="font-semibold">Grado de estudio</td>
              <td>{{sisec.GradoEstudios}}</td>
            </tr>
            <tr>
              <td class="font-semibold">Código postal</td>
              <td>{{sisec.CodigoPostal}}</td>
            </tr>
            <tr>
              <td class="font-semibold">Automóviles</td>
              <td>{{sisec.NumeroAutos}}</td>
            </tr>
            <tr>
              <td class="font-semibold">Ingresos</td>
              <td>{{sisec.Ingresos}}</td>
            </tr>
          </div>
        </div>-->   
  </div>

  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    <div  class="tab-body">
       <div class="row w-full">  

            <!-- TODO: campos ppr -->
            <div class="col-md-4 cartera">
              <span class="span-placeholder">Tipo de pedido*</span>
                <select 
                  name="tipoPedido" 
                  v-model="tipoPedido"
                  @change="changeTipoPedido()" 
                  class="vs-input">
                  <option 
                    :value='tipo.Id' 
                    v-for="tipo in tipoPedidoList" 
                    :key="tipo.Id">{{ tipo.Name }}</option>
                </select>
            </div>

            <div
              v-if="pprActivo" 
              class="col-md-4 cartera">
              <span class="span-placeholder">Tipo de cartera*</span>
                <select 
                  name="pprTipoCartera" 
                  v-model="pprTipoCartera"
                  class="vs-input">
                  <option 
                    :value='cartera.Id' 
                    v-for="cartera in pprTipoCarteraList" 
                    :key="cartera.Id">{{ cartera.Name }}</option>
                </select>
            </div>

            <!-- Se oculta campo medios porque no se maneja en sisec -->
            <!-- <div
              v-if="pprActivo" 
              class="col-md-4 cartera">
              <span class="span-placeholder">Medios*</span>
                <select 
                  name="pprMedios" 
                  v-model="pprMedios"
                  class="vs-input">
                  <option></option>
                </select>
            </div> -->

            <div
              v-if="pprActivo" 
              class="col-md-4 cartera">
              <span class="span-placeholder">Financiera*</span>
                <select 
                  @change="getTipoCredito()"
                  name="pprFinanciera" 
                  v-model="pprFinanciera"
                  class="vs-input">
                  <option 
                    :value='financiera.Id' 
                    v-for="financiera in pprFinancieraList" 
                    :key="financiera.Id">{{ financiera.Name }}</option>
                </select>
            </div>

            <div
              v-if="pprActivo" 
              class="col-md-4 cartera">
              <span class="span-placeholder">Tipo de crédito*</span>
                <select 
                  @change="getTipoProducto()"
                  name="pprTipoCredito" 
                  v-model="pprTipoCredito"
                  class="vs-input">
                  <option 
                    :value='credito.Id' 
                    v-for="credito in pprTipoCreditoList" 
                    :key="credito.Id">{{ credito.Name }}</option>
                </select>
            </div>

            <div
              v-if="pprActivo" 
              class="col-md-4 cartera">
              <span class="span-placeholder">Tipo de producto*</span>
                <select 
                  name="pprTipoProducto" 
                  v-model="pprTipoProducto"
                  class="vs-input">
                  <option 
                    :value='producto.Id' 
                    v-for="producto in pprTipoProductoList" 
                    :key="producto.Id">{{ producto.Name }}</option>
                </select>
            </div>

            <div
              v-if="pprActivo" 
              class="col-md-4 cartera">
              <span class="span-placeholder">Plazo en meses*</span>
                <select 
                  name="pprPlazoMeses" 
                  v-model="pprPlazoMeses"
                  class="vs-input">
                  <option 
                    :value='plazoMes.Id' 
                    v-for="(plazoMes, index) in pprPlazoMesesList" 
                    :key="index">{{ plazoMes.Name }}</option>
                </select>
            </div>

            <div
              v-if="pprActivo" 
              class="col-md-4 cartera">
              <span class="span-placeholder">Personalidad Jurídica*</span>
                <select 
                  name="pprPersonalidadJuridica"
                  v-model="pprPersonalidadJuridica" 
                  class="vs-input">
                  <option
                    :value='personalidad.Id' 
                    v-for="personalidad in pprPersonalidadJuridicaList" 
                    :key="personalidad.Id">{{ personalidad.Name }}</option>
                </select>
            </div>
            <!-- campos ppr -->

            <div
              v-if="!pprActivo" 
              class="col-md-4 cartera">
              <span class="span-placeholder">Tipo de cartera*</span>
                <select name="cars" id="Programas" v-model="BrokerTypeReferred" class="vs-input" @change="getReferreds()">
                  <option :value='tipo' v-for="tipo in BrokersTypeReferred"  :key="">{{tipo.Name}}</option>
                </select>
            </div>

            <div 
              v-if="!pprActivo" 
              class="col-md-4 cartera" id="Comercializador de vivienda" style="display: none;">
              <span class="span-placholder">Comercializador de vivienda*</span>
                <select name="cars" id="Programas" v-model="Referred" class="vs-input"  @change="getReferredsExecutives()">
                  <option :value='tipo.Id' v-for="tipo in Referreds"  :key="">{{tipo.Name}}</option>
                </select>
            </div>
            <div 
              v-if="!pprActivo" 
              class="col-md-4 cartera" id="Ejecutivo inmobiliario" style="display: none;">
              <span class="span-placeholder">Ejecutivo inmobiliario*</span>
                <select name="cars" id="Programas" v-model="ReferredExecutive" class="vs-input">
                  <option :value='tipo.Id' v-for="tipo in ReferredExecutives"  :key="">{{tipo.Name}}</option>
                </select>
            </div>
            <!-------------------    CAMPO NUEVO - MEDIOS    Jorge --------------------------------->
            <div
              v-if="!pprActivo" 
              class="col-md-4 cartera" id="Medios" style="display: none;">
              <span class="span-placholder">Medios*</span>
                <select name="cars" id="Programas" v-model="medios" class="vs-input">
                  <option :value='tipo.Id' v-for="tipo in Medios"  :key="">{{tipo.Name}}</option>
                </select>
            </div>
            
            <div
              v-if="!pprActivo" 
              class="col-md-4 cartera" id="MedioCaptacion" style="display: none;">
              <span class="span-placholder">Medio de captación*</span>
              <input class="vs-input"  maxlength="50" v-model="medioCaptacion"  name="medioCaptacion" />
            </div>
        
            <div
              v-if="!pprActivo" 
              class="col-md-4 cartera">
              <span class="span-placeholder">Bancos*</span>
                <select name="cars" id="Programas" v-model="banco" class="vs-input"  @change="getCreditTypes()">
                  <option :value='tipo' v-for="tipo in bancos"  :key="">{{tipo.Name}}</option>
                </select>
            </div>
            <div
              v-if="!pprActivo" 
              class="col-md-4 cartera">
              <span class="span-placeholder">Tipo de crédito*</span>
                <select name="cars" id="Programas" v-model="creditType" class="vs-input"  @change="getProductTypes()">
                  <option :value='tipo' v-for="tipo in creditTypes"  :key="">{{tipo.Name}}</option>
                </select>
            </div>
            <div
              v-if="!pprActivo" 
              class="col-md-4 cartera">
              <span class="span-placeholder">Tipo de producto*</span>
                <select name="cars" id="Programas" v-model="BrokerProduct" class="vs-input">
                  <option :value='tipo' v-for="tipo in BrokerProducts"  :key="">{{tipo.Name}}</option>
                </select>
            </div>
     
            <div
              v-if="!pprActivo" 
              class="col-md-4 cartera">
              <span class="span-placeholder">Estado*</span>
                <select name="cars" id="Programas" v-model="estado" class="vs-input" @change="GetMunicipalitiesByState()" >
                  <option :value='0'> </option>
                  <option :value='tipo.Id' v-for="tipo in estados"  :key="">{{tipo.Name}}</option>
                </select>
            </div>
            <div
              v-if="!pprActivo" 
              class="col-md-4 cartera">
              <span class="span-placeholder">Municipio o alcaldia*</span>
                <select name="cars" id="Programas" v-model="municipio" class="vs-input" >
                  <option :value='tipo.Id' v-for="tipo in municipios"  :key="">{{tipo.Name}}</option>
                </select>
            </div>
            <div
              v-if="!pprActivo" 
              class="col-md-4 cartera">
              <span class="span-placeholder">Tipo de vivienda*</span>
                <select name="tipoInmueble" id="tipoInmueble" v-model="tipoInmueble" class="vs-input" >
                  <option :value='tipo.Id' v-for="tipo in tipoVivienda"  :key="">{{tipo.Name}}</option>
                </select>
            </div>
         
            <div
              v-if="!pprActivo" 
              class="col-md-4 cartera">
              <span class="span-placeholder">Plazo de crédito*</span>
                <select name="plazo" id="plazo" v-model="plazo" class="vs-input" >
                  <option :value='tipo.value' v-for="tipo in plazos"  :key="">{{tipo.label}}</option>
                </select>
            </div>
            <div
              v-if="!pprActivo" 
              class="col-md-4 cartera">
              <span class="span-placeholder">Esquema de crédito*</span>
                <select name="esquema" id="esquema" v-model="esquema" class="vs-input" >
                  <option :value='tipo.Id' v-for="tipo in esquemas"  :key="">{{tipo.Name}}</option>
                </select>
            </div>
        </div>
        <div class="row w-full">
          <vs-alert color="danger" title="Completa la Información" :active="muestraAlerta">
            Para enviar a SISEC V3 es necesario completar todos los datos marcados con *
          </vs-alert>
          <div class="col-md-3">
            <button id="enviaSisec" class="btn btn-primary" @click="registroSisec()" :disabled="!completeInfo" >Enviar a SISEC V3</button>
          </div>
        </div>  
    </div>   
  </div>

  <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
        <div v-if="!pprActivo" class="tab-body">
          <h6 class="span-placeholder">INE/IFE o pasaporte</h6>
          <file-base64 :done="getIdentificacion"></file-base64>
          <div class="text-center">
            <img src="" alt="" :src="identificacion.base64" style="width: 20%;">
          </div>
          <div  v-if="formatoPermitido1==false">
								<label class="text-danger">*El documento debe tener formato pdf y Caracteres válidos: letras sin acentos, números y guiones.</label>
					</div>
          <br>
          <h6 class="span-placeholder" >Comprobante de domicilio</h6>
          <file-base64 :done="getComprobante" ></file-base64>
          <div class="text-center">
            <img src="" alt="" :src="img.base64" v-for="img in comprobante">
          </div>
           <div  v-if="formatoPermitido2==false">
								<label class="text-danger">*El documento debe tener formato pdf y Caracteres válidos: letras sin acentos, números y guiones.</label>
					</div>
          <br>
          <h6 class="span-placeholder">Otro</h6>
          <file-base64 :done="getOtroDoc"></file-base64>
          <div class="text-center">
            <img src="" alt="" :src="img.base64" v-for="img in otroDoc">
          </div>
           <div  v-if="formatoPermitido3==false">
								<label class="text-danger">*El documento debe tener formato pdf y Caracteres válidos: letras sin acentos, números y guiones.</label>
					</div>
        </div>
        
    <!-- campos cuando ppr este activo -->
    <div v-if="pprActivo" class="tab-body px-5">
      <div class="row">
        <div class="col-md-6 p-5">
          <h6 class="span-placeholder flex items-center">
            <span class="ppr-doc-titulo">Solicitud Skandia</span>
            <button 
              class="btn border-none ppr-btn-titulo"
              data-toggle="tooltip" 
              data-placement="top" 
              title=
              "- La solicitud debe estar completamente llena.
- No es necesario que cuente con la firma del acreditado.">
              <svg
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24" 
                fill="#008000" 
                class="w-6 h-6">
                <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
              </svg>
            </button>
          </h6>
          <file-base64 
            v-bind:multiple="false"
            v-bind:done="loadFileSkandia"></file-base64>
          <span v-if="pprArchivoValido1 === false" class="block text-danger text-sm font-normal mt-2">* Solo se permiten archivos PDF, JPG, PNG, JPEG con un tamaño de 10MB máximo</span>
        </div>
        <div class="col-md-6 p-5">
          <h6 class="span-placeholder flex items-center">
            <span class="ppr-doc-titulo">Constancia de Situación Fiscal</span>
            <button
              class="btn border-none ppr-btn-titulo"
              data-toggle="tooltip" 
              data-placement="top" 
              title="- No mayor a 2 meses de antigüedad">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24" 
                fill="#008000" 
                class="w-6 h-6">
                <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
              </svg>
            </button>
          </h6>
          <file-base64 
            v-bind:multiple="false"
            v-bind:done="loadFileConstancia"></file-base64>
            <span v-if="pprArchivoValido2 === false" class="block text-danger text-sm font-normal mt-2">* Solo se permiten archivos PDF, JPG, PNG, JPEG con un tamaño de 10MB máximo</span>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 p-5">
          <h6 class="span-placeholder flex items-center">
            <span class="ppr-doc-titulo">Identificación Oficial</span>
            <button
              class="btn border-none ppr-btn-titulo"
              data-toggle="tooltip" 
              data-placement="top" 
              title=
"- Acreditado Mexicano: INE, Pasaporte vigente emitido por la Secretaría de Relaciones Exteriores (SER), Cédula Profesional.
- Extranjero: Es necesario adjuntar una copia de su pasaporte vigente y copia de su credencial de residente permanente o temporal">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24" 
                fill="#008000" 
                class="w-6 h-6">
                <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
              </svg>
            </button>
          </h6>
          <file-base64 
            v-bind:multiple="false"
            v-bind:done="loadFileIdentificacion"></file-base64>
            <span v-if="pprArchivoValido3 === false" class="block text-danger text-sm font-normal mt-2">* Solo se permiten archivos PDF, JPG, PNG, JPEG con un tamaño de 10MB máximo</span>
        </div>
        <div class="col-md-6 p-5">
          <h6 class="span-placeholder flex items-center">
            <span class="ppr-doc-titulo">Comprobante de Domicilio</span>
            <button
              class="btn border-none ppr-btn-titulo"
              data-toggle="tooltip" 
              data-placement="top" 
              title="- Es necesario en caso de que la identificación oficial, no cuente con el domicilio actual del solicitante.
- Son validos los siguientes documentos: Luz, agua, servicio de telefonía y predio.
- No mayor a 2 meses de antigüedad">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24" 
                fill="#008000" 
                class="w-6 h-6">
                <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
              </svg>
            </button>
          </h6>
          <file-base64 
            v-bind:multiple="false"
            v-bind:done="loadFileDomicilio"></file-base64>
          <span v-if="pprArchivoValido4 === false" class="block text-danger text-sm font-normal mt-2">* Solo se permiten archivos PDF, JPG, PNG, JPEG con un tamaño de 10MB máximo</span>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 p-5">
          <h6 class="span-placeholder flex items-center">
            <span class="ppr-doc-titulo">Estado de cuenta para domiciliación</span>
            <button
              class="btn border-none ppr-btn-titulo"
              data-toggle="tooltip" 
              data-placement="top" 
              title="- Se usará para pagos domiciliados. 
- No mayor a 2 meses de antigüedad.
- Es necesario que en este documento se pueda corroborar la cuenta CLABE y nombre del solicitante. Los movimiento se pueden ocultar.
- Se aceptará estado de cuenta de cheques, tarjeta de debito y tarjeta de crédito
- Tiene que estar a nombre del solicitante.">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24" 
                fill="#008000" 
                class="w-6 h-6">
                <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
              </svg>
            </button>
          </h6>
          <file-base64 
            v-bind:multiple="false"
            v-bind:done="loadFileEstado"></file-base64>
          <span v-if="pprArchivoValido5 === false" class="block text-danger text-sm font-normal mt-2">* Solo se permiten archivos PDF, JPG, PNG, JPEG con un tamaño de 10MB máximo</span>
        </div>
      </div>

    </div>
    <!-- campos cuando ppr este activo -->
  </div>

</div>

<div 
  v-if="peticionesHipotecario.length > 0 || peticionesPPR.length > 0" 
  class="p-1 mt-5">
  <ul class="nav nav-tabs" id="historialPedidos" role="tablist">
    <li 
      class="nav-item" 
      role="presentation">
      <button
        class="nav-link active"
        id="hipotecario-sisec-tab"
        data-bs-toggle="tab"
        data-bs-target="#hipotecario-sisec" 
        type="button"
        role="tab" 
        aria-controls="hipotecario-sisec" 
        aria-selected="true">
          Hipotecario
      </button>
    </li>

    <li 
      class="nav-item" 
      role="presentation">
      <button
        class="nav-link"
        id="ppr-sisec-tab"
        data-bs-toggle="tab"
        data-bs-target="#ppr-sisec" 
        type="button"
        role="tab" 
        aria-controls="ppr-sisec" 
        aria-selected="true">
          PPR
      </button>
    </li>
  </ul>

  <div class="tab-content">
    <div  
      id="hipotecario-sisec" 
      role="tabpanel" 
      aria-labelledby="hipotecario-sisec-tab"
      class="tab-pane fade show active vx-col lg:w-2/2">

      <div class="tab-body">
        <vs-table :data="peticionesHipotecario">
          <template slot="thead">
            <vs-th>Fecha creación</vs-th>
            <vs-th>Número de pedido</vs-th>
            <vs-th>Banco</vs-th>
            <vs-th>Tipo de Producto</vs-th>
            <vs-th>Tipo de Cartera</vs-th>
            <vs-th>Tipo de Credito</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td :data="data[indextr].Fecha">
                {{ data[indextr].Fecha }}
              </vs-td>

              <vs-td :data="data[indextr].NumeroPedido">
                {{ data[indextr].NumeroPedido }}
              </vs-td>

              <vs-td :data="data[indextr].Banco">
                {{ data[indextr].Banco }}
              </vs-td>

              <vs-td :data="data[indextr].TipoProducto">
                {{ data[indextr].TipoProducto }}
              </vs-td>

              <vs-td :data="data[indextr].TipoCartera">
                {{ data[indextr].TipoCartera }}
              </vs-td>

              <vs-td :data="data[indextr].TipoCredito">
                {{ data[indextr].TipoCredito }}
              </vs-td>

            </vs-tr>
          </template>

        </vs-table>
      </div>
    </div>

    <div  
      id="ppr-sisec" 
      role="tabpanel" 
      aria-labelledby="ppr-sisec-tab"
      class="tab-pane fade show vx-col lg:w-2/2">

      <div class="tab-body">
        <vs-table :data="peticionesPPR">
          <template slot="thead">
            <vs-th>Fecha creación</vs-th>
            <vs-th>Número de pedido</vs-th>
            <vs-th>Tipo de cartera</vs-th>
            <vs-th>Financiera</vs-th>
            <vs-th>Tipo de producto</vs-th>
            <vs-th>Tipo de crédito</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].Fecha">
                {{ data[indextr].Fecha }}
              </vs-td>
              <vs-td :data="data[indextr].NumeroPedido">
                {{ data[indextr].NumeroPedido }}
              </vs-td>
              <vs-td :data="data[indextr].TipoCartera">
                {{ data[indextr].TipoCartera }}
              </vs-td>
              <vs-td :data="data[indextr].Banco">
                {{ data[indextr].Banco }}
              </vs-td>
              <vs-td :data="data[indextr].TipoProducto">
                {{ data[indextr].TipoProducto }}
              </vs-td>
              <vs-td :data="data[indextr].TipoCredito">
                {{ data[indextr].TipoCredito }}
              </vs-td>
            </vs-tr>
          </template>

        </vs-table>
      </div>
    </div>

  </div>
</div>

    <!-- <div class="vx-col lg:w-2/2 w-full">
      <vs-table :data="peticiones" v-if="peticiones">
        <template slot="thead">
          <vs-th>Número de pedido</vs-th>
          <vs-th>Banco</vs-th>
          <vs-th>Tipo de Producto</vs-th>
          <vs-th>Tipo de Cartera</vs-th>
          <vs-th>Tipo de Credito</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].NumeroPedido">
              {{ data[indextr].NumeroPedido }}
            </vs-td>

            <vs-td :data="data[indextr].Banco">
              {{ data[indextr].Banco }}
            </vs-td>

            <vs-td :data="data[indextr].TipoProducto">
              {{ data[indextr].TipoProducto }}
            </vs-td>

            <vs-td :data="data[indextr].TipoCartera">
              {{ data[indextr].TipoCartera }}
            </vs-td>

            <vs-td :data="data[indextr].TipoCredito">
              {{ data[indextr].TipoCredito }}
            </vs-td>

          </vs-tr>
        </template>

      </vs-table>
    </div> -->

  </div>
</template>

<script>
  import fileBase64 from '../vue-file-base64.vue';
  export default {
    data(){
      return{
        tipoPedidoList: [
          { Id: 1, Name: 'Hipotecario'},
          { Id: 2, Name: 'PPR'}
        ],
        pprFinancieraList: [],
        pprTipoCreditoList: [],
        pprTipoProductoList: [],
        pprTipoCarteraList: [],
        pprPlazoMesesList: [],
        pprPersonalidadJuridicaList: [
          { Id: 'Asalariado', Name: 'Asalariado' },
          { Id: 'Persona Física con actividad empresarial', Name: 'Persona Física con actividad empresarial' },
        ],
        pprActivo: false,
        tipoPedido : 1,
        pprTipoCartera: '',
        pprMedios: '',
        pprFinanciera: '',
        pprTipoCredito: '',
        pprTipoProducto: '',
        pprPlazoMeses: '',
        pprPersonalidadJuridica: '',
        pprArchivoValido1: true,
        pprArchivoValido2: true,
        pprArchivoValido3: true,
        pprArchivoValido4: true,
        pprArchivoValido5: true,
        pprArchivos: [null, null, null, null, null],
        pprPropsName: [],

        //popupAnexoBx:false,
        formatoPermitido1:true,
        formatoPermitido2:true,
        formatoPermitido3:true,
        email:'',
        versionOrigen: '',
        curp:'',
        id:'',
        identificacion: [],
        comprobante: [],
        otroDoc:[],
        solicitante:{},
        SocGuid: '',
        BrokerId:'',
        IdLiga: '',
        socData:'',
        banco:'',
        bancos:[],
        creditTypes:[],
        creditType:'',
        BrokerTypeReferred:'',
        BrokersTypeReferred:[],
        ReferredExecutives:[],
        ReferredExecutive:'',
        Referred:'',
        Referreds:[],
        BrokerProduct:'',
        BrokerProducts:[],
        peticionesHipotecario:[],
        peticionesPPR:[],
        NumeroPedido:'',
        solicitanteAdicionales:[],
        muestraAlerta:false,
        // header: {
        // "authorization": "Bearer 16JXvQ9CD1eEoVESY3-4d-bElrAAgAfcB0UgYwAB-XyuNugkcBvgntr6n6VjEAFWenS9Exi_jlLv_RBfmf004TXytHP64dNEuSyhlXOBDtO56hW07txWd4evNWfukK5CO1ETRIOk8GWyjjS7daQ8eLHUz3XefoKEHKE0yIy76o8rZZYTGskAn1Zo3oKcPFzfs_fmVlO15cD_Kxx27-1qqDBsfAphhY7N599ly5ppo5L4XHaL8JAv6mTxXVYxrTZPQDmnLqQ9Nwre7Bd4URM5rR3rVMrAVkPzJ8ohQ9-pgvigB-Fra_SMi31c5T-QrSTB"
        // },
        header : {},
        estado:'',
        estados: [],
        tipoInmueble:'',
        tipoVivienda: [],
        plazo:'',
        plazos: [
          {label: '1', value: '1'},
          {label: '2', value: '2'},
          {label: '3', value: '3'},
          {label: '4', value: '4'},
          {label: '5', value: '5'},
          {label: '6', value: '6'},
          {label: '7', value: '7'},
          {label: '8', value: '8'},
          {label: '9', value: '9'},
          {label: '10', value: '10'},
          {label: '11', value: '11'},
          {label: '12', value: '12'},
          {label: '13', value: '13'},
          {label: '14', value: '14'},
          {label: '15', value: '15'},
          {label: '16', value: '16'},
          {label: '17', value: '17'},
          {label: '18', value: '18'},
          {label: '19', value: '19'},
          {label: '20', value: '20'},
        ],
        esquema:'',
        esquemas: [],
      municipio:'',
      municipios:[],
      objMunicipio: [],
      medios :'',
      Medios : [],
      medioCaptacion: '',
      FolioSEAExistente:'',
      FolioExternoSEA:'',
      SEAFecha:'',

      
        municipio:'',
        municipios:[],
        objMunicipio: [],
        medios :'',
        Medios : [],
        isPersonalEdit: true,
        sisec: [],
        Catalogo_estadoCivil: [
          {label: 'Soltero',      value: 'Soltero'},
          {label: 'Casado',       value: 'Casado'},
          {label: 'Unión libre',  value: 'Unión libre'},
          {label: 'Divorciado',   value: 'Divorciado'},
          {label: 'Viudo',        value: 'Viudo'},
          ],
        Catalogo_tipoEmpleo : [
              {label: 'Accionista',                                 value: 'Accionista'},
              {label: 'Agropecuario',                               value: 'Agropecuario'},
              {label: 'Ama de casa',                                value: 'Ama de casa'},
              {label: 'Artista/Deportista',                         value: 'Artista/Deportista'},
              {label: 'Asalariado',                                 value: 'Asalariado'},
              {label: 'Asalariado extranjero',                      value: 'Asalariado extranjero'},
              {label: 'Cambio de divisas extranjera',               value: 'Cambio de divisas extranjera'},
              {label: 'Chofer',                                     value: 'Chofer'},
              {label: 'Comercialización de armas de fuego',         value: 'Comercialización de armas de fuego'},
              {label: 'Comerciante fijo',                           value: 'Comerciante fijo'},
              {label: 'Comerciante no fijo o ambulante',            value: 'Comerciante no fijo o ambulante'},
              {label: 'Comisionista',                               value: 'Comisionista'},
              {label: 'Desempleado',                                value: 'Desempleado'},
              {label: 'Director',                                   value: 'Director'},
              {label: 'Director-subdirector',                       value: 'Director-subdirector'},
              {label: 'Ejecutivo',                                  value: 'Ejecutivo'},
              {label: 'Empleado informal familiar',                 value: 'Empleado informal familiar'},
              {label: 'Empleado informal no familiar',              value: 'Empleado informal no familiar'},
              {label: 'Estudiante',                                 value: 'Estudiante'},
              {label: 'Eventual',                                   value: 'Eventual'},
              {label: 'Funcionario público',                        value: 'Funcionario público'},
              {label: 'Ganadero',                                   value: 'Ganadero'},
              {label: 'Gasolinero',                                 value: 'Gasolinero'},
              {label: 'Guardia uniformado',                         value: 'Guardia uniformado'},
              {label: 'Honorarios',                                 value: 'Honorarios'},
              {label: 'Independiente',                              value: 'Independiente'},
              {label: 'Industrial',                                 value: 'Industrial'},
              {label: 'Informales',                                 value: 'Informales'},
              {label: 'Ingreso conyugal',                           value: 'Ingreso conyugal'},
              {label: 'Ingresos por flujos (Estados de cuenta)',    value: 'Ingresos por flujos (Estados de cuenta)'},
              {label: 'Inversionista',                              value: 'Inversionista'},
              {label: 'Jefe de sección',                            value: 'Jefe de sección'},
              {label: 'Jubilado',                                   value: 'Jubilado'},
              {label: 'Juegos de azar',                             value: 'Juegos de azar'},
              {label: 'Maestro',                                    value: 'Maestro'},
              {label: 'Mandos y medios superiores',                 value: 'Mandos y medios superiores'},
              {label: 'Manufactura',                                value: 'Manufactura'},
              {label: 'Medico',                                     value: 'Medico'},
              {label: 'Ministro de culto',                          value: 'Ministro de culto'},
              {label: 'Negocio Propio',                             value: 'Negocio Propio'},
              {label: 'Otro',                                       value: 'Otro'},
              {label: 'Pensionado',                                 value: 'Pensionado'},
              {label: 'Pensionado extranjero',                      value: 'Pensionado extranjero'},
              {label: 'Persona física',                             value: 'Persona física'},
              {label: 'Persona física con actividad empresarial',   value: 'Persona física con actividad empresarial'},
              {label: 'Por rentas',                                 value: 'Por rentas'},
              {label: 'Por ventas',                                 value: 'Por ventas'},
              {label: 'Prestador de servicios fijo',                value: 'Prestador de servicios fijo'},
              {label: 'Prestador de servicios no fijo',             value: 'Prestador de servicios no fijo'},
              {label: 'Repartidor/instalador',                      value: 'Repartidor/instalador'},
              {label: 'RIF (Régimen de incorporación fiscal)',      value: 'RIF (Régimen de incorporación fiscal)'},
              {label: 'Secretaria',                                 value: 'Secretaria'},
              {label: 'Servicios profesionales',                    value: 'Servicios profesionales'},
              {label: 'Supervisor',                                 value: 'Supervisor'},
              {label: 'Técnico especializado',                      value: 'Técnico especializado'},
              {label: 'Técnico independiente u oficios',            value: 'Técnico independiente u oficios'},
        ],
        catalogo_hijos_autos: [
          { label: 'Ninguno',   value: 'Ninguno'},
          { label: '1',         value: '1'},
          { label: '2',         value: '2'},
          { label: 'Más de 2',  value: 'Más de 2'},
        ],
        catalogo_edad_hijos: [
          { label: 'No Aplica',  value: 'No Aplica'},
          { label: 'Menor de edad',  value: 'Menor de edad'},
          { label: 'Mayor de edad',  value: 'Mayor de edad'},          
        ],
        catalogo_ingresos: [
          { label: '$0 – $10,000.00',             value: '$0 – $10,000.00'},
          { label: '$10,001.00 - $20,000.00',     value: '$10,001.00 - $20,000.00'},
          { label: '$20,001.00 - $30,000.00',     value: '$20,001.00 - $30,000.00'},
          { label: '$30,001.00 - $40,000.00',     value: '$30,001.00 - $40,000.00'},
          { label: '$40,001.00 - $50,000.00',     value: '$40,001.00 - $50,000.00'},
          { label: '$50,001.00 - $75,000.00',     value: '$50,001.00 - $75,000.00'},
          { label: '$75,001.00 - $100,000.00',    value: '$75,001.00 - $100,000.00'},
          { label: '$100,001.00 - $150,000.00',   value: '$100,001.00 - $150,000.00'},
          { label: '$150,001.00 - $200,000.00',   value: '$150,001.00 - $200,000.00'},
          { label: '$200,001.00 - $300,000.00',   value: '$200,001.00 - $300,000.00'},
          { label: '$300,001.00 - $400,000.00',   value: '$300,001.00 - $400,000.00'},
        ],
      catalogo_gradoEstudio: [
        { label: 'Bachillerato',            value: 'Bachillerato'},
        { label: 'Licenciatura',            value: 'Licenciatura'},
        { label: 'Postgrado',               value: 'Postgrado'},
        { label: 'Menor a bachillerato',    value: 'Menor a bachillerato'},
      ]      
      }
    },
    components: { fileBase64 },
    async mounted(){
      this.id=this.$route.params.id
      this.socData=JSON.parse(localStorage.getItem('socData'));
      this.getApikey()
      await this.getTokenSisec();
      this.dameCaso()
      this.getBancos()
      this.getReferredTypes()
      this.getPeticion()
      this.DameCredenciales()
      
    },
    computed: {
     completeInfo: function () {

        let validaCurpNacionalidad = false;
        if (this.sisec.Nacionalidad == "Extranjero") {
          validaCurpNacionalidad = true;
        } else if (this.sisec.Nacionalidad == "Mexicano" && this.sisec.CURP) {
          validaCurpNacionalidad = true;
        } else {
          validaCurpNacionalidad = false;
        }

        if (this.tipoPedido == 1) { // hipotecario
          if (this.BrokerTypeReferred.Name == "Medios masivos") {
            return this.banco && this.creditType && this.BrokerProduct && this.medios && this.estado 
                && this.municipio && this.tipoInmueble && this.plazo && this.esquema && this.sisec.Nombres && this.sisec.ApellidoPaterno 
                && validaCurpNacionalidad && this.sisec.RFC && this.sisec.EMail && this.sisec.TelefonoCelular  && this.sisec.EstadoCivil && this.sisec.TipoEmpleo 
                && this.sisec.Nacionalidad && this.sisec.DependientesEconomicos && this.sisec.Genero && this.sisec.NumeroHijos
                && this.sisec.EdadHijos && this.sisec.GradoEstudios && this.sisec.CodigoPostal && this.sisec.NumeroAutos && this.sisec.Ingresos
          }
          else if (this.BrokerTypeReferred.Name == "Otros") {
            return this.banco && this.creditType && this.BrokerProduct && this.medioCaptacion && this.estado 
                && this.municipio && this.tipoInmueble && this.plazo && this.esquema && this.sisec.Nombres && this.sisec.ApellidoPaterno 
                && validaCurpNacionalidad && this.sisec.RFC && this.sisec.EMail && this.sisec.TelefonoCelular  && this.sisec.EstadoCivil && this.sisec.TipoEmpleo 
                && this.sisec.Nacionalidad && this.sisec.DependientesEconomicos && this.sisec.Genero && this.sisec.NumeroHijos
                && this.sisec.EdadHijos && this.sisec.GradoEstudios && this.sisec.CodigoPostal && this.sisec.NumeroAutos && this.sisec.Ingresos
          }
          else if (this.BrokerTypeReferred.Name == "Promotor de vivienda") {
            return this.banco && this.creditType && this.BrokerTypeReferred && this.Referred &&
              this.ReferredExecutive && this.BrokerProduct && this.estado && this.municipio && this.tipoInmueble &&
              this.plazo && this.esquema && this.sisec.Nombres && this.sisec.ApellidoPaterno 
              && validaCurpNacionalidad && this.sisec.RFC && this.sisec.EMail && this.sisec.TelefonoCelular  && this.sisec.EstadoCivil && this.sisec.TipoEmpleo 
              && this.sisec.Nacionalidad && this.sisec.DependientesEconomicos && this.sisec.Genero && this.sisec.NumeroHijos
              && this.sisec.EdadHijos && this.sisec.GradoEstudios && this.sisec.CodigoPostal && this.sisec.NumeroAutos && this.sisec.Ingresos                    
          }
          else{   
              return this.banco && this.creditType && this.BrokerProduct && this.estado && this.municipio && this.tipoInmueble &&
              this.plazo && this.esquema && this.sisec.Nombres && this.sisec.ApellidoPaterno && validaCurpNacionalidad && this.sisec.RFC && 
              this.sisec.EMail && this.sisec.TelefonoCelular  && this.sisec.EstadoCivil && this.sisec.TipoEmpleo 
              && this.sisec.Nacionalidad && this.sisec.DependientesEconomicos && this.sisec.Genero && this.sisec.NumeroHijos
              && this.sisec.EdadHijos && this.sisec.GradoEstudios && this.sisec.CodigoPostal && this.sisec.NumeroAutos && this.sisec.Ingresos
          } 
        } else if (this.tipoPedido == 2) { // ppr
                 // ppr informacion credito  
          return this.pprTipoCartera && this.pprFinanciera && this.pprTipoCredito && this.pprTipoProducto && 
                 this.pprPlazoMeses && this.pprPersonalidadJuridica &&
                 // informacion cliente 
                 this.sisec.Nombres && this.sisec.ApellidoPaterno && validaCurpNacionalidad && 
                 this.sisec.RFC && this.sisec.EMail && this.sisec.TelefonoCelular  && this.sisec.EstadoCivil && 
                 this.sisec.TipoEmpleo && this.sisec.Nacionalidad && this.sisec.DependientesEconomicos && this.sisec.Genero && 
                 this.sisec.NumeroHijos && this.sisec.EdadHijos && this.sisec.GradoEstudios && this.sisec.CodigoPostal && 
                 this.sisec.NumeroAutos && this.sisec.Ingresos;
        }
      },
      // para boton guardar > Pestaña SISEC V3 > Informacion del cliente
      completeInfov3: function () {
            // console.log( 'Nacionalidad', this.sisec.Nacionalidad );
            // console.log( 'Curp', this.sisec.CURP );

            let validaCurpNacionalidad = false;
            if (this.sisec.Nacionalidad == "Extranjero") {
              validaCurpNacionalidad = true;
            } else if (this.sisec.Nacionalidad == "Mexicano" && this.sisec.CURP) {
              validaCurpNacionalidad = true;
            } else {
              validaCurpNacionalidad = false;
            }

            return  this.sisec.Nombres && this.sisec.ApellidoPaterno 
                    && validaCurpNacionalidad && this.sisec.RFC && this.sisec.EMail && this.sisec.TelefonoCelular  && this.sisec.EstadoCivil && this.sisec.TipoEmpleo 
                    && this.sisec.Nacionalidad && this.sisec.DependientesEconomicos && this.sisec.Genero && this.sisec.NumeroHijos
                    && this.sisec.EdadHijos && this.sisec.GradoEstudios && this.sisec.CodigoPostal && this.sisec.NumeroAutos && this.sisec.Ingresos  

            // return  this.sisec.Nombres && this.sisec.ApellidoPaterno 
            //         && this.sisec.CURP && this.sisec.RFC && this.sisec.EMail && this.sisec.TelefonoCelular  && this.sisec.EstadoCivil && this.sisec.TipoEmpleo 
            //         && this.sisec.Nacionalidad && this.sisec.DependientesEconomicos && this.sisec.Genero && this.sisec.NumeroHijos
            //         && this.sisec.EdadHijos && this.sisec.GradoEstudios && this.sisec.CodigoPostal && this.sisec.NumeroAutos && this.sisec.Ingresos                  
      }
    },
    watch: {
      // Formato mayusculas sin acento
      'sisec.Nombres': function(newValue, oldValue) {
        this.setInputAlert('sisecNombres', newValue);
        this.sisec.Nombres = this.textFormat(newValue);
      },
      'sisec.SegundoNombre': function(newValue, oldValue) {
        this.sisec.SegundoNombre = this.textFormat(newValue);
      },
      'sisec.ApellidoPaterno': function(newValue, oldValue) {
        this.setInputAlert('sisecApellidoPaterno', newValue);
        this.sisec.ApellidoPaterno = this.textFormat(newValue);
      },
      'sisec.ApellidoMaterno': function(newValue, oldValue) {
        this.sisec.ApellidoMaterno = this.textFormat(newValue);
      },

      // Alerta campos obligatorios
      'sisec.RFC': function(newValue, oldValue) {
        this.setInputAlert('sisecRFC', newValue);
      },
      'sisec.EMail': function(newValue, oldValue) {
        this.setInputAlert('sisecEMail', newValue);
      },
      'sisec.TelefonoCelular': function(newValue, oldValue) {
        this.setInputAlert('sisecTelefonoCelular', newValue);
      },
      'sisec.EstadoCivil': function(newValue, oldValue) {
        this.setInputAlert('sisecEstadoCivil', newValue);
      },
      'sisec.TipoEmpleo': function(newValue, oldValue) {
        this.setInputAlert('sisecTipoEmpleo', newValue);
      },
      'sisec.Nacionalidad': function(newValue, oldValue) {
        this.setInputAlert('sisecNacionalidad', newValue);

        // Validar si la curp se tiene que marcar
        if (newValue == "Extranjero") {
          this.setInputAlert('sisecCURP', 'curpValida');
        } else if (newValue == "Mexicano" && this.sisec.CURP) {
          this.setInputAlert('sisecCURP', 'curpValida');
        } else {
          this.setInputAlert('sisecCURP', '');
        }
      },
      'sisec.DependientesEconomicos': function(newValue, oldValue) {
        this.setRadioAlert('sisecDependientesEconomicos', newValue); // radio
      },
      'sisec.Genero': function(newValue, oldValue) {
        this.setRadioAlert('sisecGenero', newValue);  // radio
      },
      'sisec.NumeroHijos': function(newValue, oldValue) {
        this.setInputAlert('sisecNumeroHijos', newValue);
      },
      'sisec.EdadHijos': function(newValue, oldValue) {
        this.setInputAlert('sisecEdadHijos', newValue);
      },
      'sisec.GradoEstudios': function(newValue, oldValue) {
        this.setInputAlert('sisecGradoEstudios', newValue);
      },
      'sisec.CodigoPostal': function(newValue, oldValue) {
        this.setInputAlert('sisecCodigoPostal', newValue);
      },
      'sisec.NumeroAutos': function(newValue, oldValue) {
        this.setInputAlert('sisecNumeroAutos', newValue);
      },
      'sisec.Ingresos': function(newValue, oldValue) {
        this.setInputAlert('sisecIngresos', newValue);
      },
    },
    methods: {
      // Alerta
      setInputAlert(strId, strValue) {
        if (strValue == null || strValue == '') {
          document.getElementById(`${strId}`).style.border = "2px solid #FF7150";
        } else {
          document.getElementById(`${strId}`).style.border = "";
        }
      },
      setRadioAlert(strId, strValue) {
        if (strValue == null || strValue == '') {
          let radio = document.getElementById(`${strId}`);
          let radioCollection = radio.getElementsByClassName('vs-radio');
          radioCollection[0].style.border = "2px solid #FF7150";
          radioCollection[1].style.border = "2px solid #FF7150";
        } else {
          let radio = document.getElementById(`${strId}`);
          let radioCollection = radio.getElementsByClassName('vs-radio');
          radioCollection[0].style.border = "";
          radioCollection[1].style.border = "";
        }
      },
      // Conversion
      textFormat(strValue) {
        return strValue.normalize('NFD')
                .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
                .normalize()
                .toUpperCase(); // Eliminar acentos menos Ññ
      },
      getPropsNamePPR( objProps ) {
        let propValueList = ['', '', '', ''];
        propValueList[0] = this.pprTipoProductoList.find((element) => element.Id == objProps.tipoProducto);
        propValueList[1] = this.pprTipoCarteraList.find((element) => element.Id == objProps.tipoCartera);
        propValueList[2] = this.pprTipoCreditoList.find((element) => element.Id == objProps.tipoCredito);
        propValueList[3] = this.pprFinancieraList.find((element) => element.Id == objProps.banco);
        return propValueList;
      },
      loadFileSkandia( file ) {
        if (this.validaArchivoPPR(file.file)) {
          this.pprArchivoValido1 = true;
          this.pprArchivos[0] = file;
        } else {
          this.pprArchivoValido1 = false;
          this.pprArchivos[0] = null;
        }
      },
      loadFileConstancia( file ) {
        if (this.validaArchivoPPR(file.file)) {
          this.pprArchivoValido2 = true;
          this.pprArchivos[1] = file;
        } else {
          this.pprArchivoValido2 = false;
          this.pprArchivos[1] = null;
        }
      },
      loadFileIdentificacion( file ) {
        if (this.validaArchivoPPR(file.file)) {
          this.pprArchivoValido3 = true;
          this.pprArchivos[2] = file;
        } else {
          this.pprArchivoValido3 = false;
          this.pprArchivos[2] = null;
        }
      },
      loadFileDomicilio( file ) {
        if (this.validaArchivoPPR(file.file)) {
          this.pprArchivoValido4 = true;
          this.pprArchivos[3] = file;
        } else {
          this.pprArchivoValido4 = false;
          this.pprArchivos[3] = null;
        }
      },
      loadFileEstado( file ) {
        if (this.validaArchivoPPR(file.file)) {
          this.pprArchivoValido5 = true;
          this.pprArchivos[4] = file;
        } else {
          this.pprArchivoValido5 = false;
          this.pprArchivos[4] = null;
        }
      },
      validaArchivoPPR(file) {
        let esValido = true;
        let type = ['application/pdf', 'image/png', 'image/jpeg'];
        let maxSize = 10000000; // 10 mb
        if (file && file.size > 0 && file.size <= maxSize && type.includes(file.type)) {
          esValido = true;
        } else {
          esValido = false;
        }
        return esValido;
      },
      changeTipoPedido() {
        this.pprActivo = !this.pprActivo; // switch

        this.pprArchivos = [null, null, null, null, null]; // reiniciar los documentos cargados

        if (this.pprActivo) { // cargar catalogos
          this.getTipoCartera();
          this.getCatFinancieras();
          this.calcPlazoMeses();
        }
      },
      calcPlazoMeses() {
        let intEdad = this.solicitante.Edad; // edad acreditado
        let limiteSuperior = 65 - intEdad; 
        this.pprPlazoMeses = limiteSuperior;
        for (let i = 0; i < limiteSuperior; i++) {
          let anio = i + 1;
          let anioPlural = anio > 1 ? ' Años - ' : ' Año - '; 
          let anioMes = anio + anioPlural + (anio  * 12) + ' Meses';
          this.pprPlazoMesesList.push({
            Id: (i + 1),
            Name: anioMes,
          });
        }
      },
      async getCatFinancieras() {
        await this.getTokenSisec(); // token temporal
        let self=this
        
        this.$axios.get(
          'https://apivalidacionriesgos.sisec.mx/api/ppr/getbanks', 
          { headers : this.header }
        ).then(
          response => {
            this.pprFinancieraList = response.data.Banks;
          }
        ).catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema en SISEC',
            text: error,
            color: 'danger',
            position: 'top-right'
          })
        })
      },
      async getTipoCredito() {
        await this.getTokenSisec(); // token temporal
        let self=this
        
        this.$axios.get(
          'https://apivalidacionriesgos.sisec.mx/api/ppr/getcredittypes?BankId=' + this.pprFinanciera, 
          { headers : this.header }
        ).then(
          response => {
            this.pprTipoCreditoList = response.data.CreditTypes;
          }
        ).catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema en SISEC',
            text: error,
            color: 'danger',
            position: 'top-right'
          })
        })
      },
      async getTipoProducto() {
        await this.getTokenSisec(); // token temporal
        let self=this
        
        this.$axios.get(
          'https://apivalidacionriesgos.sisec.mx/api/ppr/getproducttypes?BankId=' + this.pprFinanciera + '&CreditTypeId=' + this.pprTipoCredito, 
          { headers : this.header }
        ).then(
          response => {
            this.pprTipoProductoList = response.data.Products;
          }
        ).catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema en SISEC',
            text: error,
            color: 'danger',
            position: 'top-right'
          })
        })
      },
      async getTipoCartera() {
        await this.getTokenSisec(); // token temporal
        let self=this
        
        this.$axios.get(
          'https://apivalidacionriesgos.sisec.mx/api/ppr/getreferredtypes', 
          { headers : this.header }
        ).then(
          response => {
            this.pprTipoCarteraList = response.data.ReferredTypes;
          }
        ).catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema en SISEC',
            text: error,
            color: 'danger',
            position: 'top-right'
          })
        })
      },
      async getTokenSisec() {
        let objRequestToken = {
          strApiKey: this.key,
          strMetodo: 'DameAuthToken',
        }
        await this.$axios.post('/', objRequestToken,
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
          .then( response => {
            // console.log('getTokenSisec', response.data.access_token );
            if (!response.data.error) {
              this.header = { "Authorization" : "Bearer "+ response.data.access_token};
            } else {
              this.$vs.notify({
                title: 'Ocurrio un error al obtener token',
                text: response.data.error_description,
                color: 'danger',
                position: 'top-right',
              })
            }
          }).catch(function (error) {
            self.$vs.notify({
              title: 'Ocurrio un error de sistema',
              text: error,
              color: 'danger',
              position: 'top-right',
            })
          });
      },
      toggleSave() {
			  this.isPersonalEdit = !this.isPersonalEdit;
      },
      soloLetras: function (event) {
        var regex = new RegExp('^[áéíóúüña-zÑÁÉÍÓÚA-Z ]+$')
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
        if (!regex.test(key)) {
          event.preventDefault()
          return false
        }
      },
      calculateAge: function(birthday) {
      
          var birthday_arr = birthday.split("/");
          var birthday_date = new Date(birthday_arr[2], birthday_arr[1] - 1, birthday_arr[0]);
          var ageDifMs = Date.now() - birthday_date.getTime();
          var ageDate = new Date(ageDifMs);
          return Math.abs(ageDate.getUTCFullYear() - 1970);
      },
      ArrayFecha: function(mensaje) {
        var ano = mensaje.split('-');

        var fecha = ano[2]+'/'+ano[1]+'/'+ano[0];
        return fecha;

        
      },

      valueNumeroHijos: function(){        
        if (this.sisec.NumeroHijos == 'Ninguno') {
              this.sisec.EdadHijos = 'No Aplica';
          }
          
      },
      generaInformacionParaV3(){
        let self=this
        var objRequestDameInfo = {
            strFolioCaso: this.id,
            strApiKey: this.key,
            strMetodo: 'generaInformacionParaV3',
            objSolicitante:
            {
                EMail: this.email
            },
            objCaso: {
              Id: this.id,
            }
          }
          this.$axios.post('/',objRequestDameInfo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
          .then(
            response => {
              if(response.data.intEstatus == 0){                
                this.sisec = response.data.objContenido
                
                if(this.sisec.Nacionalidad=='MEXICANO (A)' || this.sisec.Nacionalidad == 'Mexicano'){
                  this.sisec.Nacionalidad='Mexicano'
                }else if(this.sisec.Nacionalidad == null || this.sisec.Nacionalidad == ''){
                  this.sisec.Nacionalidad=''
                }else
                  this.sisec.Nacionalidad='Extranjero'

                if (this.sisec.DependientesEconomicos == null) {
                  this.sisec.DependientesEconomicos = 'No';                  
                }

                if (this.sisec.NumeroHijos == null || this.sisec.NumeroHijos == '') {
                  this.sisec.NumeroHijos = 'Ninguno';
                  this.sisec.EdadHijos = 'No Aplica';
                }

                if (this.sisec.TipoEmpleo == '--Seleccione--') {
                  this.sisec.TipoEmpleo = '';
                }

                if (this.sisec.Ingresos == null) {
                  this.sisec.Ingresos = '';
                }

                if (this.sisec.NumeroAutos == null || this.sisec.NumeroAutos == '') {
                  this.sisec.NumeroAutos = 'Ninguno';
                }else if (this.sisec.NumeroAutos > 2) {
                  this.sisec.NumeroAutos = 'Más de 2';
                }
                
                if (this.sisec.GradoEstudios == null) {
                  this.sisec.GradoEstudios = '';
                }

              }else {
                this.$vs.notify({
                  title:'Ocurrio un error en generaInformacionParaV3',
                  text:response.data.strError,
                  color:'danger',
                  position:'top-right'
                })
              }
            }
          ).catch(function (error) {
            self.$vs.notify({
              title:'Ocurrio un error de sistema',
              text:error,
              color:'danger',
              position:'top-right'
            })
          })
      },
      getPeticion(contador = null){
        let self=this
        var objRequestDameCaso = {
          strApiKey: this.key,
          strMetodo: 'DameCasoPeticiones',
          objCaso: {
           Id: this.id,
          }
        }
        this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              this.banco = '' 
              this.creditType = ''
              this.BrokerTypeReferred = ''
              this.BrokerProduct = ''
              // this.peticiones=response.data.objContenido.Peticiones
              // cambiar el formato de las fechas
              let peticionesTemp = response.data.objContenido.Peticiones;
              this.peticionesHipotecario = []; // se reinicia
              this.peticionesPPR = []; // se reinicia

              if (peticionesTemp) {
                peticionesTemp.forEach(peticion => {
                  peticion.Fecha = new Date(peticion.Fecha.date).toLocaleDateString('es-MX', {
                    day: '2-digit', month: '2-digit', year: 'numeric'
                  }).split(' ').join('/');
  
                  // clasifica peticiones
                  if (peticion.TipoPedido == 1) {
                    this.peticionesHipotecario.push(peticion);                    
                  } else if (peticion.TipoPedido == 2) {
                    this.peticionesPPR.push(peticion);
                  }
                });
              }
              if(contador){                                 
                document.getElementById('enviaSisec').disabled = false;                
              }
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en DameCasoPeticiones',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      },
      SocRegistraPedido(){

        // tipoPedido
        let bancoPedido = '';
        if (this.tipoPedido == 1) { // hipotecario
          bancoPedido = this.banco.Name;
        } else if (this.tipoPedido == 2) { // ppr
          bancoPedido = this.pprPropsName[3].Name;
        }

        let self=this
        var objRequestDameCaso = {
          strFolioCaso: this.id,
          strApiKey: this.key,
          strMetodo: 'SocRegistraPedido',
          objParametros: {
            Banco: bancoPedido,
            Precalificacion: this.id,
            EMailSolicitante: this.solicitante.EMail,
            NumeroPedidoSisec: this.NumeroPedido
          }
        }
        this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              this.getPeticion('desdeSocPedido')
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en SocRegistraPedido',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      },     
      actualizaCurp(curp){
        let self=this
       //alert(curp)
        var objSolicitante = {
          strFolioCaso: this.id,
          strApiKey: this.key,
          strMetodo: 'ActualizaCurpExtranjero',
          objSolicitante: {
            EMailSolicitante: this.solicitante.EMail,
            Curp: curp
          }
        }
        this.$axios.post('/',objSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              //no necesita mandar msj
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en actualizaCurp',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      },
      registraCasoPeticion(){
        this.pprPropsName = [];
        if (this.tipoPedido == 2) { // si es ppr
          // obtener los valores para ppr
          this.pprPropsName = this.getPropsNamePPR({
            tipoProducto: this.pprTipoProducto,
            tipoCartera: this.pprTipoCartera,
            tipoCredito: this.pprTipoCredito,
            banco: this.pprFinanciera,
          });
        }

        let self=this
       // alert(this.bancoSel.name)
        var objRequestDameCaso = {
          strApiKey: this.key,
          strMetodo: 'RegistraCasoPeticion',
          objCaso: {
            TipoProducto: this.tipoPedido === 1 ? this.BrokerProduct.Name : this.pprPropsName[0].Name,
            TipoCartera: this.tipoPedido === 1 ? this.BrokerTypeReferred.Name : this.pprPropsName[1].Name,
            TipoCredito: this.tipoPedido === 1 ? this.creditType.Name : this.pprPropsName[2].Name,
            Banco: this.tipoPedido === 1 ? this.banco.Name : this.pprPropsName[3].Name,
            Id: this.id,
            EMailSolicitante: this.solicitante.EMail,
            NumeroPedido: this.NumeroPedido,
            TipoPedido: this.tipoPedido // hipotecario o ppr
          }
        }
        this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              this.SocRegistraPedido();
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en RegistraCasoPeticion',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      },
      dameCaso(){
        let self=this
        var objRequestDameCaso = {
          strApiKey: this.key,
          strMetodo: 'DameCaso',
          objCaso: {
            Id: this.id
          }
        }
        this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              this.email= response.data.objContenido.Solicitante   
              this.versionOrigen = response.data.objContenido.VersionOrigen // 2023-06-15 mejora

              if(response.data.objContenido.SEAFolioActual){

                
                this.FolioSEAExistente=true
                
                this.FolioExternoSEA=response.data.objContenido.SEAFolioActual
                this.SEAFecha= response.data.objContenido.SEAAno+'-'+response.data.objContenido.SEAMes+'-'+response.data.objContenido.SEADia+' 00:00:00'

                
                
           
              }
              
              this.getSisecv3(); // tabla sisec_v3                        
              
              this.getStates();
              this.GetHousingTypes()
              this.GetCreditSchemes()
              this.dameLogCotiza();
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en DameCaso',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      },
      dameSolicitante(){
        let self=this
        var objRequestDameSolicitante = {
          strFolioCaso: this.id,
          strApiKey: this.key,
          strMetodo: 'DameSolicitante',
          objSolicitante: {
            EMail: this.email
          }
        }
        this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              this.solicitante=response.data.objContenido
              this.curp= this.solicitante.Curp              

              /* if(this.solicitante.EstadoCivil == 1)
                this.sisec.EstadoCivil='Casado'
              else if(this.solicitante.EstadoCivil == 2)
                this.sisec.EstadoCivil='Divorciado'
              else if(this.solicitante.EstadoCivil == 3)
                this.sisec.EstadoCivil='Soltero'
              else if(this.solicitante.EstadoCivil == 4)
                this.sisec.EstadoCivil='Unión libre'
              else if(this.solicitante.EstadoCivil == 5)
                this.sisec.EstadoCivil='Viudo'
              else if(this.solicitante.EstadoCivil == 6)
                this.sisec.EstadoCivil='Unión libre' */
              
              if(this.solicitante.Ingresos[0]){                
                this.solicitante.TipoEmpleo= this.solicitante.Ingresos[0].TipoEmpleo
                if(this.solicitante.Ingresos[0].TipoEmpleoId)
                this.solicitante.TipoEmpleoId= this.solicitante.Ingresos[0].TipoEmpleoId    
              
                if(this.solicitante.TipoEmpleoId==38 || this.solicitante.TipoEmpleoId==20 || this.solicitante.TipoEmpleoId==48 || this.solicitante.TipoEmpleoId==59 || this.solicitante.TipoEmpleoId==3 || this.solicitante.TipoEmpleoId==4 ||
                    this.solicitante.TipoEmpleoId==50 || this.solicitante.TipoEmpleoId==5 || this.solicitante.TipoEmpleoId==6 || this.solicitante.TipoEmpleoId== 47 || this.solicitante.TipoEmpleoId==15 || this.solicitante.TipoEmpleoId== 19||
                    this.solicitante.TipoEmpleoId== 44|| this.solicitante.TipoEmpleoId==14|| this.solicitante.TipoEmpleoId==37 || this.solicitante.TipoEmpleoId==23 || this.solicitante.TipoEmpleoId==22 || this.solicitante.TipoEmpleoId==7 ||
                    this.solicitante.TipoEmpleoId== 49 || this.solicitante.TipoEmpleoId==8 || this.solicitante.TipoEmpleoId==13 || this.solicitante.TipoEmpleoId==45 || this.solicitante.TipoEmpleoId==42 || this.solicitante.TipoEmpleoId==16 || 
                    this.solicitante.TipoEmpleoId==9 || this.solicitante.TipoEmpleoId==11 || this.solicitante.TipoEmpleoId==30 || this.solicitante.TipoEmpleoId==12 || this.solicitante.TipoEmpleoId==61){

                      this.solicitante.TipoActividad='Independiente'
                    }
                else if(this.solicitante.TipoEmpleoId==1 || this.solicitante.TipoEmpleoId==40 || this.solicitante.TipoEmpleoId==53 || this.solicitante.TipoEmpleoId==52 || this.solicitante.TipoEmpleoId==2 || this.solicitante.TipoEmpleoId==57 ||
                    this.solicitante.TipoEmpleoId==69 || this.solicitante.TipoEmpleoId==55 || this.solicitante.TipoEmpleoId==18 || this.solicitante.TipoEmpleoId== 60 || this.solicitante.TipoEmpleoId==62 || this.solicitante.TipoEmpleoId== 63||
                    this.solicitante.TipoEmpleoId== 51|| this.solicitante.TipoEmpleoId==36|| this.solicitante.TipoEmpleoId==65 || this.solicitante.TipoEmpleoId==41 || this.solicitante.TipoEmpleoId==10 || this.solicitante.TipoEmpleoId==66 ||
                    this.solicitante.TipoEmpleoId== 54 || this.solicitante.TipoEmpleoId==67 || this.solicitante.TipoEmpleoId==68){

                      this.solicitante.TipoActividad='Empleado privado'
                    }
                else if(this.solicitante.TipoEmpleoId==43 || this.solicitante.TipoEmpleoId==46 || this.solicitante.TipoEmpleoId==56 || this.solicitante.TipoEmpleoId==64 || this.solicitante.TipoEmpleoId==39){

                      this.solicitante.TipoActividad='Empleado público'
                }
              }

              

            if(this.solicitante.Sexo==1)
              this.solicitante.Sexo='Masculino'
            else if(this.solicitante.Sexo==2)
              this.solicitante.Sexo='Femenino'
              var arr = this.ArrayFecha(this.solicitante.FechaNacimiento)
              this.solicitante.Edad = this.calculateAge(arr)
              //alert(this.solicitante.Edad)
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en DameSolicitante',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      },
      dameToken(){
        let objRequestDameTokenPv3 = {
        strApiKey: this.key,
        strMetodo: 'DameTokenPv3'
        }
        this.$axios.post('/', objRequestDameTokenPv3, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
          .then((response) => {
            if (response) {
              this.header = "Bearer "+ response.data.access_token;
              this.getStates();
              this.GetHousingTypes()
              this.GetCreditSchemes()
            }
            else{
              this.$vs.notify({
                title: 'Ocurrio un error en Obtener token',
                text: response.data.strError,
                color: 'danger',
                position: 'top-right',
              })
            }
          })
          .catch(function (error) {
            self.$vs.notify({
              title: 'Ocurrio un error de sistema',
              text: error,
              color: 'danger',
              position: 'top-right',
            })
          })
      },
      dameLogCotiza(){
        let self = this
        var objRequestDameLogCotiza = {
          strApiKey: this.key,
          strMetodo: 'DameLogCotiza',
          objCotiza: {
              IdCaso: this.id
            }
        }
        this.$axios
          .post('/', objRequestDameLogCotiza, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
          .then((response) => {
            if (response.data.intEstatus == 0) {
              //this.inmuebleNuevo = response.data.objContenido[0].Nuevo
              if (response.data.objContenido[0]) {
                  let estadoComparador = response.data.objContenido[0].Estado
                  let municipioComparador = response.data.objContenido[0].Plaza   
                  
                  if (response.data.objContenido[0].Nuevo == true) {
                    this.tipoInmueble = "2a7d69bd-650c-4577-8c71-f9228636bf8b" //nueva
                  }
                  else{
                    this.tipoInmueble = "1da196dd-1ac4-4d75-9620-6f5d6b91a901"  //USADA
                  }

                  /***********************************CATALOGOS DE PROD SISEC *********************************************************************************************************/
                  //ESTADOS
                  switch(estadoComparador){
                    case 1:
                      this.estado = 1;
                    break;
                    case 2:
                      this.estado = 2;
                    break;
                    case 3:
                      this.estado = 3;
                    break;
                    case 4:
                      this.estado = 4;
                    break;
                    case 5:
                      this.estado = 5;
                    break;
                    case 6:
                      this.estado = 6;
                    break;  
                    case 7:
                      this.estado = 8;
                    break;
                    case 8:
                      this.estado = 9;
                    break;
                    case 9:
                      this.estado = 7;
                    break;
                    case 10:
                      this.estado = 10;
                    break;
                    case 11:
                      this.estado = 15;
                    break;
                    case 12:
                      this.estado = 11;
                    break;
                    case 13:
                      this.estado = 12;
                    break;
                    case 14:
                      this.estado = 13;
                    break;
                    case 15:
                      this.estado = 14;
                    break;
                    case 16:
                      this.estado = 16;
                    break;
                    case 17:
                      this.estado = 17;
                    break;
                    case 18:
                      this.estado = 18;
                    break;
                    case 19:
                      this.estado = 19;
                    break;
                    case 20:
                      this.estado = 20;
                    break;
                    case 21:
                      this.estado = 21;
                    break;
                    case 22:
                      this.estado = 22;
                    break;
                    case 23:
                      this.estado = 23;
                    break;
                    case 24:
                      this.estado = 24;
                    break;
                    case 25:
                      this.estado = 25;
                    break;
                    case 26:
                      this.estado = 26;
                    break;
                    case 27:
                      this.estado = 27;
                    break;
                    case 28:
                      this.estado = 28;
                    break;
                    case 29:
                      this.estado = 29;
                    break;
                    case 30:
                      this.estado = 30;
                    break;
                    case 31:
                      this.estado = 31;
                    break;
                    case 32:
                      this.estado = 32;
                    break;
                    case 33: //VIVO EN OTRO PAIS
                      this.estado = 0; 
                    break;
                    case 34: //USA
                      this.estado = 0; 
                    break;
                  }

                //MUNICIPIOS
                  //AGUASCALIENTES
                    if (municipioComparador == 17) {
                      this.municipio = 18;
                      this.GetMunicipalitiesByState()
                    }
                  //BAJA CALIFORNIA
                    if (municipioComparador == 66) {
                      this.municipio = 85;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 48) {
                      this.municipio = 83;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 49) {
                      this.municipio = 84;
                      this.GetMunicipalitiesByState()
                    }
                  //BAJA CALIFORNIA SUR
                    if (municipioComparador == 51) {
                      this.municipio = 87;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 50) {
                      this.municipio = 86;
                      this.GetMunicipalitiesByState()
                    }
                  //CAMPECHE
                    if (municipioComparador == 32) {
                      this.municipio = 31;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 33) {
                      this.municipio = 32;
                      this.GetMunicipalitiesByState()
                    }
                  //CHIAPAS
                    if (municipioComparador == 34) {
                      this.municipio = 33;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 35) {
                      this.municipio = 34;
                      this.GetMunicipalitiesByState()
                    }
                  //CHIHUAHUA
                    if (municipioComparador == 1) {
                      this.municipio = 2;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 2) {
                      this.municipio = 3;
                      this.GetMunicipalitiesByState()
                    }
                  //CMDX
                    if (municipioComparador == 67) {
                      this.municipio = 65;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 68) {
                      this.municipio = 66;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 69) {
                      this.municipio = 67;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 30) {
                      this.municipio = 29;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 70) {
                      this.municipio = 68;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 71) {
                      this.municipio = 69;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 72) {
                      this.municipio = 70;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 73) {
                      this.municipio = 71;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 74) {
                      this.municipio = 72;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 75) {
                      this.municipio = 73;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 76) {
                      this.municipio = 74;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 77) {
                      this.municipio = 75;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 78) {
                      this.municipio = 76;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 79) {
                      this.municipio = 77;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 80) {
                      this.municipio = 78;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 81) {
                      this.municipio = 79;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 82) {
                      this.municipio = 80;
                      this.GetMunicipalitiesByState()
                    }
                  //COHAHUILA
                    if (municipioComparador == 3) {
                      this.municipio = 4;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 4) {
                      this.municipio = 5;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 5) {
                      this.municipio = 6;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 6) {
                      this.municipio = 7;
                      this.GetMunicipalitiesByState()
                    }
                  //COLIMA
                    if (municipioComparador == 52) {
                      this.municipio = 51;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 53) {
                      this.municipio = 89;
                      this.GetMunicipalitiesByState()
                    }
                  //DURANGO
                    if (municipioComparador == 7) {
                      this.municipio = 8;
                      this.GetMunicipalitiesByState()
                    }
                  //ESTADO DE MEXICO
                    if (municipioComparador == 31) {
                      this.municipio = 30;
                      this.GetMunicipalitiesByState()
                    }
                  //GUANAJUATO
                    if (municipioComparador == 20) {
                      this.municipio = 19;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 21) {
                      this.municipio = 20;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 22) {
                      this.municipio = 21;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 23) {
                      this.municipio = 22;
                      this.GetMunicipalitiesByState()
                    }
                  //GUERRERO
                    if (municipioComparador == 36) { //ACAPULCO
                      this.municipio = 432; //ACAPULCP DE JUAREZ
                      this.GetMunicipalitiesByState()
                    }
                  //HIDALGO
                    if (municipioComparador == 24) { //PACHUCA
                      this.municipio = 559; //PACHUCA DE SOTO
                      this.GetMunicipalitiesByState()
                    }
                  //JALISCO
                    if (municipioComparador == 54) {
                    this.municipio = 91;
                    this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 55) {
                      this.municipio = 92;
                      this.GetMunicipalitiesByState()
                    }
                  //MICHOACAN
                    if (municipioComparador == 25) {
                      this.municipio = 24;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 26) {
                      this.municipio = 25;
                      this.GetMunicipalitiesByState()
                    }
                  //MORELOS
                    if (municipioComparador == 63) {
                      this.municipio = 93;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 37) {
                      this.municipio = 36;
                      this.GetMunicipalitiesByState()
                    }
                  //NAYARIT
                    if (municipioComparador == 56) {
                      this.municipio = 94;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 58) {
                      this.municipio = 95;
                      this.GetMunicipalitiesByState()
                    }
                  //NVO LEON
                    if (municipioComparador == 8) {
                      this.municipio = 9;
                      this.GetMunicipalitiesByState()
                    }
                  //OAXACA
                  if (municipioComparador == 38) {
                      this.municipio = 96;
                      this.GetMunicipalitiesByState()
                    }              
                  //PUEBLA
                  if (municipioComparador == 39) {
                      this.municipio = 38;
                      this.GetMunicipalitiesByState()
                    } 
                  //QUERETARO
                  if (municipioComparador == 27) {
                      this.municipio = 26;
                      this.GetMunicipalitiesByState()
                    } 
                  //QUINTANA ROO
                  if (municipioComparador == 40) {
                      this.municipio = 98;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 41) {
                      this.municipio = 99;
                      this.GetMunicipalitiesByState()
                    }
                  //SAN LUIS POTOSI
                  if (municipioComparador == 28) { 
                      this.municipio = 27;
                      this.GetMunicipalitiesByState()
                    } 
                  //SINALOA
                  if (municipioComparador == 59) {
                      this.municipio = 100;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 60) {
                      this.municipio = 101;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 61) {
                      this.municipio = 102;
                      this.GetMunicipalitiesByState()
                    }
                  //SONORA
                  if (municipioComparador == 9) {
                      this.municipio = 10;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 62) {
                      this.municipio = 60;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 10) {
                      this.municipio = 11;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 11) {
                      this.municipio = 12;
                      this.GetMunicipalitiesByState()
                    }
                  //TABASCO
                  if (municipioComparador == 42) {
                      this.municipio = 104;
                      this.GetMunicipalitiesByState()
                    }
                  //TAMAILIPAS
                  if (municipioComparador == 12) {
                      this.municipio = 13;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 13) {
                      this.municipio = 14;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 14) {
                      this.municipio = 15;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 15) {
                      this.municipio = 16;
                      this.GetMunicipalitiesByState()
                    }                
                    if (municipioComparador == 16) {
                      this.municipio = 17;
                      this.GetMunicipalitiesByState()
                    }
                    //TLAXCALA    
                    if (municipioComparador == 43) {
                      this.municipio = 42;
                      this.GetMunicipalitiesByState()
                    }   
                    //VERACRUZ
                    if (municipioComparador == 44) {
                      this.municipio = 106;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 65) {
                      this.municipio = 110;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 45) {
                      this.municipio = 107;
                      this.GetMunicipalitiesByState()
                    }
                    if (municipioComparador == 64) {
                      this.municipio = 109;
                      this.GetMunicipalitiesByState()
                    }                
                    if (municipioComparador == 46) {
                      this.municipio = 108;
                      this.GetMunicipalitiesByState()
                    }      
                    //YUCATAN
                    if (municipioComparador == 47) {
                      this.municipio = 111;
                      this.GetMunicipalitiesByState()
                    }
                    //ZACATECAS
                    if (municipioComparador == 29) {
                      this.municipio = 28;
                      this.GetMunicipalitiesByState()
                    }
                    
              }
             

              
   
            } else {
              this.$vs.notify({
                title: 'Ocurrio un error en DameLogCotiza',
                text: response.data.strError,
                color: 'danger',
                position: 'top-right',
              })
            }
          })
          .catch(function (error) {
            self.$vs.notify({
              title: 'Ocurrio un error de sistema',
              text: error,
              color: 'danger',
              position: 'top-right',
            })
          })
      },
      getApikey(){
        if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
          this.$router.push('/login');
        }else {
          this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
        }
      },
      getIdentificacion(files){

       
       this.validaArchivo(files.name)

        if(this.formatoPermitido1=true){
          this.identificacion = files
        }
           
      },
      getComprobante(files){

        this.validaArchivo2(files.name)

       if(this.formatoPermitido1=true)
        this.comprobante = files
      },
      getOtroDoc(files){

        this.validaArchivo3(files.name)

       if(this.formatoPermitido1=true)
        this.otroDoc = files
      },
      validaArchivo(files){

        var nombreFile= files

       
        let objRequestVariante = {
          strApiKey: this.key,
          strMetodo: 'validaFormatoV3',
          files: nombreFile
         
        }
        this.$axios
          .post('/', objRequestVariante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
          .then((response) => {
            if (response.data.intEstatus == 0) {
   
              if(response.data.objContenido=='Formato incorrecto'){
                this.formatoPermitido1=false
              }else{
                this.formatoPermitido1=true
               
              }

            }
     
    })
    .catch(function (error) {
     self.$vs.notify({
      title: 'Ocurrio un error de sistema',
      text: error,
      color: 'danger',
      position: 'top-right',
     })
    })       


      },

    validaArchivo2(files){

        var nombreFile= files

       
        let objRequestVariante = {
          strApiKey: this.key,
          strMetodo: 'validaFormatoV3',
          files: nombreFile
         
        }
        this.$axios
          .post('/', objRequestVariante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
          .then((response) => {
            if (response.data.intEstatus == 0) {
   
              if(response.data.objContenido=='Formato incorrecto'){
                this.formatoPermitido2=false
              }else{
                this.formatoPermitido2=true
              
              }

            }
     
    })
    .catch(function (error) {
     self.$vs.notify({
      title: 'Ocurrio un error de sistema',
      text: error,
      color: 'danger',
      position: 'top-right',
     })
    })       


      },

   validaArchivo3(files){

        var nombreFile= files

       
        let objRequestVariante = {
          strApiKey: this.key,
          strMetodo: 'validaFormatoV3',
          files: nombreFile
         
        }
        this.$axios
          .post('/', objRequestVariante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
          .then((response) => {
            if (response.data.intEstatus == 0) {
   
              if(response.data.objContenido=='Formato incorrecto'){
                this.formatoPermitido3=false
              }else{
                this.formatoPermitido3=true
            
              }

            }
     
    })
    .catch(function (error) {
     self.$vs.notify({
      title: 'Ocurrio un error de sistema',
      text: error,
      color: 'danger',
      position: 'top-right',
     })
    })       


      },
      async registroSisec(){
        // se genera otro auth token por si tarda mas de 20 minutos en este componente activo sin enviar
        await this.getTokenSisec();

        document.getElementById('enviaSisec').disabled = true;        

        if (this.tipoPedido == 1) {
        // hipotecario
        if(this.banco!='' && this.creditType!='' && this.BrokerTypeReferred!='' &&
          this.BrokerProduct!='' && this.sisec.Nombres!='' &&
          this.sisec.ApellidoPaterno!='' && this.sisec.EMail!='' &&
          this.sisec.Nacionalidad!='' && this.sisec.RFC!=''){
            this.muestraAlerta=false
            this.$vs.loading({
              container: '#sisec',
              scale: 0.6
            })
            let self=this
            let Documents= []
            if (this.otroDoc.base64) {
              Documents.push({
                FileName: this.otroDoc.name,
                DocumentType: 3,
                File: this.otroDoc.base64.split(',')[1]
              })
            }
            if (this.identificacion.base64) {
              Documents.push({
                FileName: this.identificacion.name,
                DocumentType: 1,
                File: this.identificacion.base64.split(',')[1]
              })
            }
            if (this.comprobante.base64) {
              Documents.push({
                FileName:this.comprobante.name,
                DocumentType: 2,
                File: this.comprobante.base64.split(',')[1]
              })
            }


            let FolioSEA=""
           if(this.FolioSEAExistente==true && this.banco.Id=='2b771c0c-8e49-4eb7-b90a-7bb12534f27b'){
              FolioSEA = {
                Id: null,
                IdPedido: null,
                FolioBanco: this.FolioExternoSEA,
                CorrioBuro: false,
                ExpedienteOriginal: false,
                FechaRegistro: this.SEAFecha,
                EtapaSEA: 'Captura Sistemas',
                FechaEtapaSEA: null,
                Observaciones: 'Se capturó desde Precalificación Bancaria',
                FolioOperacionCredito: null

              }
            }

            // Cotizador SOC (Venta digital) | Cotizador SOC
            let strOrigin=""

            if (this.versionOrigen == "Negocio digital") {
              strOrigin="Cotizador SOC (Venta digital)"
            } else {
              strOrigin="Cotizador SOC"
            }

            let nacionalidadCorrecta=''

            if(this.sisec.Nacionalidad=='Mexicano' || this.sisec.Nacionalidad=='Extranjero'){
              nacionalidadCorrecta=this.sisec.Nacionalidad
              
            }else if(this.sisec.Nacionalidad=='MEXICANO (A)'){
              nacionalidadCorrecta='Mexicano'
            }else{
              nacionalidadCorrecta='Extranjero'
            }

            // si no hay ingresos, se agrega una actividad por defecto
            if (!this.solicitante.TipoActividad) {
              this.solicitante.TipoActividad = "Empleado privado";
            }

            // si no existe curp, es extranjero y se envia vacio
            if (!this.sisec.CURP) {
              this.sisec.CURP = "";
            }
            
            let objRequestCotizaCaso =
            {
            Accredited: {
              Name: this.sisec.Nombres,
              SName: this.sisec.SegundoNombre,
              LastName: this.sisec.ApellidoPaterno,
              SecondLastName: this.sisec.ApellidoMaterno,
              CURP: this.sisec.CURP,
              RFC: this.sisec.RFC,
              PersonalMail: this.sisec.EMail,
              IsContacted: true,
              Nationality: nacionalidadCorrecta, //// this.sisec.Nacionalidad,
              Phone: this.sisec.TelefonoCelular,
              CivilStatus: this.sisec.EstadoCivil,
              Gender: this.sisec.Genero,
              Age: this.sisec.Edad,
              Activity: this.solicitante.TipoActividad
              
            },
            AccreditedComparatorModel: {
              CoAccredited: this.sisec.Coacreditado,
              EconomicDependents: this.sisec.DependientesEconomicos,
              NumberOfChildren: this.sisec.NumeroHijos,
              StudyGrade: this.sisec.GradoEstudios,
              NumberOfCars: this.sisec.NumeroAutos,
              CP: this.sisec.CodigoPostal,
              ChildrensAge: this.sisec.EdadHijos,
              MonetaryIncome: this.sisec.Ingresos
            },
            BrokerEmployee: {
              Id: this.socData.EmployeeId,
              BrokerId: this.socData.BrokerId
            },
            AccreditedOrder: {
              BankId: this.banco.Id,
              TypeCreditId: this.creditType.Id,
              BrokersTypeReferredId: this.BrokerTypeReferred.Id,
              BrokersReferredId:this.Referred,
              ReferredExecutiveId:this.ReferredExecutive,
              DigitalCampaignId: this.medios, // identificador de medios
              TypeOperationId: 1,
              BrokersProductId: this.BrokerProduct.Id,
              Origin: strOrigin,
              Documents: Documents,
              FolioSEA: FolioSEA,
              StateId:this.estado,//Identificador del estado.
              PlazaId: this.municipio,// Identificador del municipio.
              HousingTypeId:this.tipoInmueble, //Identificador del tipo de vivienda.
              CreditTerm: this.plazo, // Plazo del crédito este debe ser un valor entre el 1 al 20.
              CreditSchemeId: this.esquema, //Identificador del tipo de esquema del crédito.
              CollectionMedium : this.medioCaptacion
            
            }
            }
            this.$axios.post('https://apivalidacionriesgos.sisec.mx/api/External/CreateCreditOrder',objRequestCotizaCaso,{headers : this.header})
            .then(
              response => {
                this.$vs.loading.close('#sisec > .con-vs-loading')
                this.NumeroPedido=response.data.OrderId
                this.$vs.notify({
                  time:4000,
                  title:'Se registro correctamente en SISEC',
                  text:'Folio: '+response.data.OrderId, 
                  color:'primary',
                  position:'top-right'
                })

                if(nacionalidadCorrecta=='Extranjero'){
                  this.actualizaCurp(response.data.CurpExtranjera)
                  // Y no tiene curp
                  if (!this.sisec.CURP) {
                    this.sisec.CURP = response.data.CurpExtranjera; // asigno curp para extranjeros de la respuesta de sisec
                  }
                }

                this.registraLogSisec(objRequestCotizaCaso, response.data, 1, 0); // log sisec

                this.registraCasoPeticion() // 1: hipotecario
                this.GeneraLiga()
                this.RegistraSolicitanteSisec(); // guarda informacion y pasa directo a enviar pedido

              }
            ).catch(function (error) {
              self.registraLogSisec(objRequestCotizaCaso, error.response.data, 1, 1);
              self.$vs.loading.close('#sisec > .con-vs-loading')

              self.$vs.notify({
                title:'Ocurrio un error en SISEC',
                text:error,
                color:'danger',
                position:'top-right'
              })
            })
          }else{
            this.muestraAlerta=true;
          }

        } else if (this.tipoPedido == 2) { // ppr

          if (this.pprTipoCartera != '' && this.pprFinanciera != '' && this.pprTipoCredito != '' && 
              this.pprTipoProducto != '' && this.pprPlazoMeses != '' && this.pprPersonalidadJuridica != '' &&
              this.sisec.Nombres != '' && this.sisec.ApellidoPaterno != '' && this.sisec.EMail != '' &&
              this.sisec.Nacionalidad != '' && this.sisec.RFC != '') {

                this.muestraAlerta = false;
                this.$vs.loading({
                  container: '#sisec',
                  scale: 0.6
                });
                let self = this;

                let Documents = [];
                if (this.pprArchivos[0]) { // skandia
                  Documents.push({
                    FileName: this.pprArchivos[0].name,
                    DocumentType: 1,
                    File: this.pprArchivos[0].base64.split(',')[1],
                  });
                }
                if (this.pprArchivos[2]) { // identificacion
                  Documents.push({
                    FileName: this.pprArchivos[2].name,
                    DocumentType: 2,
                    File: this.pprArchivos[2].base64.split(',')[1],
                  });
                }
                if (this.pprArchivos[3]) { // comprobante
                  Documents.push({
                    FileName: this.pprArchivos[3].name,
                    DocumentType: 3,
                    File: this.pprArchivos[3].base64.split(',')[1],
                  });
                }
                if (this.pprArchivos[4]) { // estado
                  Documents.push({
                    FileName: this.pprArchivos[4].name,
                    DocumentType: 4,
                    File: this.pprArchivos[4].base64.split(',')[1],
                  });
                }
                if (this.pprArchivos[1]) { // constancia
                  Documents.push({
                    FileName: this.pprArchivos[1].name,
                    DocumentType: 5,
                    File: this.pprArchivos[1].base64.split(',')[1],
                  });
                }
                
                // Cotizador SOC (Venta digital) | Cotizador SOC
                let strOrigin = "";
                if (this.versionOrigen == "Negocio digital") {
                  strOrigin = "Cotizador SOC (Venta digital)";
                } else {
                  strOrigin = "Cotizador SOC";
                }
                // Revisión de Nacionalidad
                let nacionalidadCorrecta = '';
                if (this.sisec.Nacionalidad == 'Mexicano' || this.sisec.Nacionalidad == 'Extranjero') {
                  nacionalidadCorrecta = this.sisec.Nacionalidad;
                } else if (this.sisec.Nacionalidad == 'MEXICANO (A)') {
                  nacionalidadCorrecta = 'Mexicano';
                } else {
                  nacionalidadCorrecta = 'Extranjero';
                }
                // Si no hay ingresos, se agrega una actividad por defecto
                if (!this.solicitante.TipoActividad) {
                  this.solicitante.TipoActividad = "Empleado privado";
                }
                // Si no existe curp, es extranjero y se envia vacio
                if (!this.sisec.CURP) {
                  this.sisec.CURP = "";
                }

                // Obj para envio de pedido PPR
                let objRequestPRR = {
                  Accredited: {
                    Name: this.sisec.Nombres,
                    SName: this.sisec.SegundoNombre,
                    LastName: this.sisec.ApellidoPaterno,
                    SecondLastName: this.sisec.ApellidoMaterno,
                    CURP: this.sisec.CURP,
                    RFC: this.sisec.RFC,
                    PersonalMail: this.sisec.EMail,
                    IsContacted: true,
                    Nationality: nacionalidadCorrecta,
                    Phone: this.sisec.TelefonoCelular,
                    CivilStatus: this.sisec.EstadoCivil,
                    Gender: this.sisec.Genero,
                    Age: this.sisec.Edad,
                    Activity: this.solicitante.TipoActividad
                  },
                  BrokerEmployee: {
                    Id: this.socData.EmployeeId,
                    BrokerId: this.socData.BrokerId
                  },
                  Order: {
                    BankId: this.pprFinanciera,
                    TypeCreditId: this.pprTipoCredito,
                    ProductId: this.pprTipoProducto,
                    Term: this.pprPlazoMeses, // ver si es necesario realizar la conversion a string
                    TypePerson: this.pprPersonalidadJuridica,
                    TypeReferredId: this.pprTipoCartera,
                    Origin: strOrigin,
                    Documents: Documents,
                  }
                }
                
                this.$axios.post('https://apivalidacionriesgos.sisec.mx/api/ppr/addorder', objRequestPRR, { headers: this.header })
                  .then(response => {
                    this.$vs.loading.close('#sisec > .con-vs-loading');

                    if (response.status == 200) { // OK
                      // console.log( 'addorder', response.data );
                      this.NumeroPedido = response.data.OrderNumber;
                      this.$vs.notify({
                        time: 4000,
                        title:'Se registro correctamente en SISEC',
                        text:'Folio: '+ response.data.OrderNumber, 
                        color: 'primary',
                        position:'top-right'
                      });

                      if( nacionalidadCorrecta == 'Extranjero'){
                        this.actualizaCurp(response.data.CurpExtranjera)
                        // Y no tiene curp
                        if (!this.sisec.CURP) {
                          this.sisec.CURP = response.data.CurpExtranjera; // asigno curp para extranjeros de la respuesta de sisec
                        }
                      }
                      this.registraLogSisec(objRequestPRR, response.data, 2, 0); // Log sisec
                      this.registraCasoPeticion(); // 2: ppr
                      this.GeneraLiga(); 
                      this.RegistraSolicitanteSisec(); // guarda informacion y pasa directo a enviar pedido
                    } 
                  })
                  .catch(function (error) {
                    self.registraLogSisec(objRequestPRR, error.response.data, 2, 1); // BadRequest, Unauthorized, InternalServer
                    self.$vs.loading.close('#sisec > .con-vs-loading')
                    self.$vs.notify({
                      title: 'Ocurrio un error en SISEC',
                      text: error.response.data.Message,
                      color: 'danger',
                      position: 'top-right'
                    })
                  });
          } else {
            this.muestraAlerta = true;
          }
        }
      },
      registraLogSisec(send, resp, tipo, state) {
        let self = this;
        let objRequestLog = {
          strApiKey: this.key,
          strMetodo: 'RegistraSisecEnvioLog',
          objEnvioV3: {
            precalificacion: this.id,
            envio: JSON.stringify(send),
            respuesta: JSON.stringify(resp),
            tipoPedido: tipo,
            conError: state
          },
        }
        
        this.$axios
          .post('/', objRequestLog, { 
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } 
          })
          .then(response => {
            if (response.data.intEstatus != 0) {
              this.$vs.notify({
                title: 'Ocurrio un error al registrar el log del envío',
                text: response.data.strError,
                color: 'danger',
                position: 'top-right',
              })
            }
          })
          .catch(function (error) {
            self.$vs.notify({
              title: 'Ocurrio un error de sistema',
              text: error,
              color: 'danger',
              position: 'top-right',
            })
          })
      },
      getBancos(){
        let self=this
        this.$axios.get('https://apivalidacionriesgos.sisec.mx/api/External/GetHipotecaryBanks?id='+this.socData.EmployeeId,{headers : this.header})
        .then(
          response => {
            let allBancos =response.data.banks
            for(var i=0; i<allBancos.length;i++)
             if( !allBancos[i].Name.includes('AUTO')){
               this.bancos.push({Name:allBancos[i].Name, Id: allBancos[i].Id})
             }
           //  this.banco='29285bab-7b3a-49e0-83d6-e94e8b1bffb0'
            this.bancos=response.data.banks
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema en SISEC',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      },
      async getCreditTypes(){
        await this.getTokenSisec();

        this.BrokerProducts=[]
        this.creditTypes=[]
        let self=this
        this.$axios.get('https://apivalidacionriesgos.sisec.mx/api/External/GetCreditTypes?BankId='+this.banco.Id,{headers : this.header})
        .then(
          response => {
            this.creditTypes=response.data.CreditTypes
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema en SISEC',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      },
      getReferredTypes(){
        let self=this
        this.$axios.get('https://apivalidacionriesgos.sisec.mx/api/External/GetReferredTypes',{headers : this.header})
        .then(
          response => {
            this.BrokersTypeReferred=response.data.ReferredTypes
            
            //NO INDEXAR ESTA PARTE.

// PROD
this.BrokersTypeReferred.splice(0,1); // - REFERIDO DE CLIENTE ATENDIDO
this.BrokersTypeReferred.splice(0,0,{"Id":"0797ae6a-d4a4-45ab-b2de-5512fd718590","Name":"Referido del cliente atendido"}); // + Referido del cliente atendido
this.BrokersTypeReferred.splice(2,1); //- VENTA CRUZADA
this.BrokersTypeReferred.splice(3,0,{"Id":"832b0763-6879-4d5d-b042-79361f03c9be","Name":"Venta cruzada"});  // + VENTA CRUZADA
this.BrokersTypeReferred.splice(4,1); //- Otro
this.BrokersTypeReferred.splice(4,0,{"Id":"f9e44faa-9986-49b9-a36c-f567051b3876","Name":"Otros"}); // + Otros



          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema en SISEC',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      },
      async getReferreds(){
        await this.getTokenSisec();

        let self=this
        if (this.BrokerTypeReferred.Name == "Medios masivos") {
          //this.medios = null
          this.Referred = null;
          this.ReferredExecutive = null;
          document.getElementById('Comercializador de vivienda').style.display = 'none';
          document.getElementById('Ejecutivo inmobiliario').style.display = 'none';
          document.getElementById('Medios').style.display = 'block';
          document.getElementById('MedioCaptacion').style.display = 'none';

          this.$axios.get('https://apivalidacionriesgos.sisec.mx/api/External/GetMassiveMedia?Id='+this.BrokerTypeReferred.Id+'&Name='+this.BrokerTypeReferred.Name,{headers : this.header})
          .then(
            response => {
              this.Medios=response.data.MassiveMedia
              //this.Referred = "N/A";
              //this.ReferredExecutive = "N/A";
            }
          ).catch(function (error) {
          })
        }

        else if (this.BrokerTypeReferred.Name == "Promotor de vivienda") {
          this.medios = null;
         // this.Referred = null;
          //this.ReferredExecutive = null;
          document.getElementById('Comercializador de vivienda').style.display = 'block';
          document.getElementById('Ejecutivo inmobiliario').style.display = 'block';
          document.getElementById('Medios').style.display = 'none';
          document.getElementById('MedioCaptacion').style.display = 'none';

        this.$axios.get('https://apivalidacionriesgos.sisec.mx/api/External/GetReferreds?BrokerId='+this.socData.BrokerId+'&ReferredType='+this.BrokerTypeReferred.Id,{headers : this.header})
          .then(
            response => {
              this.Referreds=response.data.Referreds
              //this.medios = "N/A";
            }
          ).catch(function (error) {
          })
        }
        
        else if (this.BrokerTypeReferred.Name == "Otros") {         
          this.Referred = null;
          this.ReferredExecutive = null;
          document.getElementById('Comercializador de vivienda').style.display = 'none';
          document.getElementById('Ejecutivo inmobiliario').style.display = 'none';
          document.getElementById('Medios').style.display = 'none';
          document.getElementById('MedioCaptacion').style.display = 'block';

        }
        else{ // * Referido del cliente atendido, * Venta Cruzada * otros
          this.Referred = null;
          this.ReferredExecutive = null;
          this.medios = null;
          document.getElementById('Comercializador de vivienda').style.display = 'none';
          document.getElementById('Ejecutivo inmobiliario').style.display = 'none';
          document.getElementById('Medios').style.display = 'none';
          document.getElementById('MedioCaptacion').style.display = 'none';
        }


      },
      async getReferredsExecutives(){
        await this.getTokenSisec();

        let self=this
        this.$axios.get('https://apivalidacionriesgos.sisec.mx/api/External/GetReferredsExecutives?ReferredId='+this.Referred,{headers : this.header})
        .then(
          response => {
            this.ReferredExecutives=response.data.ReferredsExecutives
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema en SISEC',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      },
      async getProductTypes(){
        await this.getTokenSisec();

        let self=this
        this.$axios.get('https://apivalidacionriesgos.sisec.mx/api/External/GetProductTypes?BankId='+this.banco.Id+'&CreditTypeId='+this.creditType.Id,{headers : this.header})
        .then(
          response => {
            this.BrokerProducts=response.data.Products
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema en SISEC',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      },
      getStates(){
        let self=this
        this.$axios.get('https://apivalidacionriesgos.sisec.mx/api/External/GetStates',{headers : this.header})
        .then(
          response => {
            this.estados=response.data.ListStates
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema en SISEC',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      },
      async GetMunicipalitiesByState(){
        await this.getTokenSisec();

        // si el campo genera un change innecesario
        if (!this.estado) {
          this.estado = 0;
        }

        let self=this        
        this.$axios.get('https://apivalidacionriesgos.sisec.mx/api/External/GetMunicipalitiesByState?StateId='+this.estado,{headers : this.header})
        .then(
          response => {
            if (this.estado != 15) {
              let aux =response.data.ListMunicipalities  
              aux.sort((x, y) => x.Id - y.Id);
             // console.log(aux);
              //console.log(Object.values(this.municipios)) 
              this.municipios = []    
              for (const property in aux) {
                if (this.estado == 1) { //AGUSCALIENTES
                  if (aux[property].Name == 'AGUASCALIENTES') {
                    this.municipios.push(aux[property])
                  }
                  
                }
                if (this.estado == 2) { //BAJA CALIFORNIA
                  if (aux[property].Name == 'ENSENADA' || aux[property].Name == 'MEXICALI' || aux[property].Name == 'TIJUANA') {
                    this.municipios.push(aux[property])
                  }
                  
                }
                if (this.estado == 3) { //BAJA CALIFORNIA SUR
                  if (aux[property].Name == 'CABO SAN LUCAS' || aux[property].Name == 'LA PAZ') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 4) { //CAMPECHE
                  if (aux[property].Name == 'CAMPECHE' || aux[property].Name == 'CD CARMEN') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 5) { //CHIAPAS
                  if (aux[property].Name == 'TAPACHULA' || aux[property].Name == 'TUXTLA GUTIERREZ') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 6) { //CHIHUAHUA
                  if (aux[property].Name == 'CD JUAREZ' || aux[property].Name == 'CHIHUAHUA') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 7) { //CMDX
                  if (aux[property].Name == 'ÁLVARO OBREGÓN' || aux[property].Name == 'AZCAPOTZALCO' || aux[property].Name == 'BENITO JUÁREZ' || aux[property].Name == 'CDMX'
                  || aux[property].Name == 'COYOACÁN' || aux[property].Name == 'CUAJIMALPA DE MORELOS' || aux[property].Name == 'CUAUHTÉMOC' || aux[property].Name == 'GUSTAVO A. MADERO'
                  || aux[property].Name == 'IZTACALCO' || aux[property].Name == 'IZTAPALAPA' || aux[property].Name == 'LA MAGDALENA CONTRERAS' || aux[property].Name == 'MIGUEL HIDALGO'
                  || aux[property].Name == 'MILPA ALTA' || aux[property].Name == 'TLÁHUAC' || aux[property].Name == 'TLALPAN' || aux[property].Name == 'VENUSTIANO CARRANZA'
                  || aux[property].Name == 'XOCHIMILCO') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 8) { //COHAHUILA
                  if (aux[property].Name == 'MONCLOVA' || aux[property].Name == 'PIEDRAS NEGRAS' || aux[property].Name == 'SALTILLO' || aux[property].Name == 'TORREON') {
                    this.municipios.push(aux[property])

                    let hash = {};
                    this.municipios = this.municipios.filter(o => hash[o.Name] ? false : hash[o.Name] = true);
                    //console.log(JSON.stringify(this.municipios));
                  }
                }
                if (this.estado == 9) { //COLIMA
                  if (aux[property].Name == 'COLIMA' || aux[property].Name == 'MANZANILLO') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 10) { //durango
                  if (aux[property].Name == 'DURANGO') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 11) { //GUANAJUATO
                  if (aux[property].Name == 'CELAYA' || aux[property].Name == 'GUANAJUATO' || aux[property].Name == 'IRAPUATO' || aux[property].Name == 'LEON') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 12) { //GUERRERO
                  if (aux[property].Name == 'ACAPULCO DE JUÁREZ') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 13) { //HIDALGO
                  if (aux[property].Name == 'PACHUCA DE SOTO') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 14) { //JALISCO
                  if (aux[property].Name == 'GUADALAJARA' || aux[property].Name == 'VALLARTA') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 16) { //MICHOACAN
                  if (aux[property].Name == 'MORELIA' || aux[property].Name == 'URUAPAN') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 17) { //MORELOS
                  if (aux[property].Name == 'CUAUTLA ' || aux[property].Name == 'CUERNAVACA') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 18) { //NAYARIT
                  if (aux[property].Name == 'NAYARIT ' || aux[property].Name == 'TEPIC ') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 19) { //NUEVO LEON
                  if (aux[property].Name == 'MONTERREY') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 20) { //OAXACA
                  if (aux[property].Name == 'OAXACA ') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 21) { //PUEBLA
                  if (aux[property].Name == 'PUEBLA') {
                    this.municipios.push(aux[property])

                    let hash = {};
                    this.municipios = this.municipios.filter(o => hash[o.Name] ? false : hash[o.Name] = true);
                  }
                }
                if (this.estado == 22) { //QUERETARO
                  if (aux[property].Name == 'QUERETARO') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 23) { //QUINTANA ROO
                  if (aux[property].Name == 'CANCUN ' || aux[property].Name == 'PLAYA DEL CARMEN') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 24) { //SAN LUIS POTOSI
                  if (aux[property].Name == 'SAN LUIS POTOSI') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 25) { //SINALOA
                  if (aux[property].Name == 'CULIACAN' || aux[property].Name == 'LOS MOCHIS' || aux[property].Name == 'MAZATLAN') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 26) { //SONORA
                  if (aux[property].Name == 'HERMOSILLO' || aux[property].Name == 'NAVOJOA' || aux[property].Name == 'NOGALES'  || aux[property].Name == 'OBREGON') {
                    this.municipios.push(aux[property])

                    let hash = {};
                    this.municipios = this.municipios.filter(o => hash[o.Name] ? false : hash[o.Name] = true);
                  }
                }
                if (this.estado == 27) { //TABASCO
                  if (aux[property].Name == 'VILLAHERMOSA') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 28) { //TAMAULIPAS
                  if (aux[property].Name == 'CD VICTORIA' || aux[property].Name == 'MATAMOROS' || aux[property].Name == 'NUEVO LAREDO'  || aux[property].Name == 'REYNOSA' || aux[property].Name == 'TAMPICO') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 29) { //TLAXCALA
                  if (aux[property].Name == 'TLAXCALA') {  //se realiza el filtro con la plaza definida es este if
                    this.municipios.push(aux[property])             

                    //se quita los duplicados
                    let hash = {};
                    this.municipios = this.municipios.filter(o => hash[o.Name] ? false : hash[o.Name] = true);
                    console.log(JSON.stringify(this.municipios));
                  }
                }
                if (this.estado == 30) { //VERACRUZ
                  if (aux[property].Name == 'COATZACOALCOS' || aux[property].Name == 'CORDOBA' || aux[property].Name == 'JALAPA'  || aux[property].Name == 'ORIZABA' || aux[property].Name == 'PTO DE VERACRUZ') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 31) { //YUCATAN
                  if (aux[property].Name == 'MERIDA') {
                    this.municipios.push(aux[property])
                  }
                }
                if (this.estado == 32) { //ZACATECAS
                  if (aux[property].Name == 'ZACATECAS') {
                    this.municipios.push(aux[property])
                  }
                }
                
              }
            }
            else{
              this.municipios = response.data.ListMunicipalities
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema en SISEC',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      },
      GetHousingTypes(){
        let self=this
        this.$axios.get('https://apivalidacionriesgos.sisec.mx/api/External/GetHousingTypes',{headers : this.header})
        .then(
          response => {
            this.tipoVivienda =response.data.ListHousingTypes  
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema en SISEC',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      },
      GetCreditSchemes(){
        let self=this
        this.$axios.get('https://apivalidacionriesgos.sisec.mx/api/External/GetCreditSchemes',{headers : this.header})
        .then(
          response => {
            this.esquemas =response.data.ListCreditSchemes         
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema en SISEC',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      },
      GeneraLiga(){

        // Cotizador SOC (Venta digital) | Cotizador SOC
        let strOrigin=""

        if (this.versionOrigen == "Negocio digital") {
          strOrigin="Cotizador SOC (Venta digital)"
        } else {
          strOrigin="Cotizador SOC"
        }

        let self=this
      
        var mostrar = ""

          let nacionalidadCorrecta=''

            if(this.sisec.Nacionalidad=='Mexicano' || this.sisec.Nacionalidad=='Extranjero'){
              nacionalidadCorrecta=this.sisec.Nacionalidad
              
            }else if(this.sisec.Nacionalidad=='MEXICANO (A)'){
              nacionalidadCorrecta='Mexicano'
            }else{
              nacionalidadCorrecta='Extranjero'
            }

        let objRequestCotizaCaso =
          {
          AccreditedModel: {
            Name: this.sisec.Nombres,
            SName: this.sisec.SegundoNombre,
            LastName: this.sisec.ApellidoPaterno,
            SecondLastName: this.sisec.ApellidoMaterno,
            PersonalMail: this.sisec.EMail,
            CellPhone: this.sisec.TelefonoCelular,
            CURP: this.sisec.CURP,
            RFC: this.sisec.RFC,  
            CivilStatus: this.sisec.EstadoCivil,
            Nationality: nacionalidadCorrecta,
            Gender: this.sisec.Genero,
            Age: this.sisec.Edad,
            StudyGrade: this.sisec.GradoEstudios,
            EconomicDependents: this.sisec.DependientesEconomicos,
            NumberOfChildren: this.sisec.NumeroHijos,
            ChildrensAge: this.sisec.EdadHijos,
            CP: this.sisec.CodigoPostal,
            MonetaryIncome: this.sisec.Ingresos,
            NumberOfCars: this.sisec.NumeroAutos,
            Activity: this.solicitante.TipoActividad,
            PhoneHome: this.sisec.TelefonoCelular,
            DateBirtday: null,
            Cocredited: null,
            Weight: null,
            Height: null,
            NSS: null,
            TypeHousing: null,
            ResidenceTimeHome: null,
            ResidenceTimeTown: null,
            CompanyName: null,
            CompanyAddress: null,
            Position: null,
            TypeCompany: null,
            DateBeginWork: null,
          },
          ReferencesModel: {
            Name: null,
            SName: null,
            LastName: null,
            SecondLastName: null,
            Phone: null,
            Relationship: null,
            TimeRelationship: null
          },
          CreditRequestModel: {
            TypeCredit: this.tipoPedido == 1 ? this.creditType.Id : this.pprPropsName[2].Id, // 1: hipotecario, 2: ppr
            HomeValue: null,
            CreditAmount: null,
            Plazo: null
          },
         BrokerEmployeeModel: {
            Id: this.SocGuid,
            BrokerId: this.BrokerId,
            //Id: this.socData.EmployeeId,
            //BrokerId: this.socData.BrokerId,
            EmployeeName: null,
            EmployeeEmail: null,
            // Origin: "Cotizador soc"
            Origin: strOrigin
            
          },
          IsQuickQuote:false,
          }   
        this.$axios.post('https://apivalidacionriesgos.sisec.mx/api/External/CreateAccredited',objRequestCotizaCaso,{headers : this.header})
              .then(
                response => {
                  this.IdLiga = response.data.AccreditedId           
                  this.EnviaCorreo();
                  this.RegistraAccreditedIdSolicitante();
                  
                }
              ).catch(function (error) {

                if(error.response.data.Message == "The CellPhone already exists"){
                  mostrar = "El telefono ya se encuentra registrado intente con otro"
                }else if(error.response.data.Message == "The email already exists"){
                  mostrar = "El correo elecronico ya se encuentra registrado intente con otro"
                }else{
                  mostrar = error
                }
                
                self.$vs.notify({
                  title:'Ocurrio un error en SISEC',
                  text:mostrar,
                  color:'danger',
                  position:'top-right'
                })
              })
      },
      DameCredenciales(){
      let self=this
      let objRequestDameCredenciales = {
        strApiKey: this.key,
        strMetodo: 'DameCredenciales',
        objBroker: {
        ApiKey: this.key
        }
      }
      this.$axios.post('/',objRequestDameCredenciales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
        this.SocGuid = response.data.objContenido.SocGuid
        this.BrokerId = response.data.objContenido.BrokerId
        
        //Solo pruebas
        //this.SocGuid = '61711b5c-948c-4928-94f0-158b96b58da0';//solo pruebas
				//this.BrokerId = 'ca1b7cbd-f2a5-4507-9f03-4c167dcb2663';
        }
      ).catch(function (error) {
        self.$vs.notify({
        title:'Ocurrio un error de sistema',
        text:error,
        color:'danger',
        position:'top-right'
        })
      })
      },
      EnviaCorreo(){
        let self=this
        let objRequestEnviaLigaCorreo = {
          strApiKey: this.key,
          strMetodo: 'EnviaLigaCorreo',
          objDatos: {
          Nombre: this.solicitante.Nombres,
          Id: this.IdLiga,
          Correo: this.solicitante.EMail,
          }
        }
        this.$axios.post('/',objRequestEnviaLigaCorreo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {

            //document.getElementById('ModalLiga').style.display='none'
            this.$vs.loading.close('#cotizacion > .con-vs-loading')
          }
        ).catch(function (error) {
          self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
          })
        })
      },
      RegistraAccreditedIdSolicitante(){
        let self = this
        let objRequestRegistraSolicitante = {
          strFolioCaso: this.id,
          strApiKey: this.key,
          strMetodo: 'RegistraAccreditedIdSolicitante',
          objSolicitante: {
            EMail: this.email,
            AccreditedIdV3: this.IdLiga
          },
        }

        this.$axios
          .post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
          .then((response) => {
            if (response.data.intEstatus == 0) {
             
            } else {
              this.$vs.notify({
                title: 'Ocurrio un error en Registra AccreditedId Solicitante',
                text: response.data.strError,
                color: 'danger',
                position: 'top-right',
              })
            }
          })
          .catch(function (error) {
            self.$vs.notify({
              title: 'Ocurrio un error de sistema',
              text: error,
              color: 'danger',
              position: 'top-right',
            })
          })
      },
      getSisecv3(){
        let self=this
        var objRequestGetSisecv3 = {
          strFolioCaso: this.id,
          strApiKey: this.key,
          strMetodo: 'GetSisecv3',
          objSolicitante: {
            EMail: this.email,
            Precalificacion: this.id
          }
        }
        this.$axios.post('/',objRequestGetSisecv3,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              this.sisec=response.data.objContenido                  

              if(this.sisec.Nacionalidad=='MEXICANO (A)' || this.sisec.Nacionalidad == 'Mexicano'){
                  this.sisec.Nacionalidad='Mexicano'
                }else if(this.sisec.Nacionalidad == null || this.sisec.Nacionalidad == ''){
                  this.sisec.Nacionalidad=''
                }else
                  this.sisec.Nacionalidad='Extranjero'

                if (this.sisec.DependientesEconomicos == null) {
                  this.sisec.DependientesEconomicos = 'No';                  
                }

                if (this.sisec.NumeroHijos == null || this.sisec.NumeroHijos == '') {
                  this.sisec.NumeroHijos = 'Ninguno';
                  this.sisec.EdadHijos = 'No Aplica';
                }

                if (this.sisec.TipoEmpleo == '--Seleccione--') {
                  this.sisec.TipoEmpleo = '';
                }

                if (this.sisec.Ingresos == null) {
                  this.sisec.Ingresos = '';
                }

                if (this.sisec.NumeroAutos == null || this.sisec.NumeroAutos == '') {
                  this.sisec.NumeroAutos = 'Ninguno';
                }else if (this.sisec.NumeroAutos > 2) {
                  this.sisec.NumeroAutos = 'Más de 2';
                }
                
                if (this.sisec.GradoEstudios == null) {
                  this.sisec.GradoEstudios = '';
                }            
                this.dameSolicitante()    

            }else if(response.data.intEstatus == 1){
             
                this.dameSolicitante()              
                this.generaInformacionParaV3() //Nuevos datos sisec     
              

            }else {
              this.$vs.notify({
                title:'Ocurrio un error en consultar datos del solicitante en la tabla sisec_v3',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      },
      RegistraSolicitanteSisec(){
        let self=this
        var objRequestRegistraSolicitanteSisec = {
          strFolioCaso: this.id,
          strApiKey: this.key,
          strMetodo: 'RegistraSolicitanteSisec',
          objSolicitante: {
            Precalificacion: this.id,
            //Solicitante: this.sisec.EMail,
            EMail: this.email,
            Nombres: this.sisec.Nombres,
            SegundoNombre: this.sisec.SegundoNombre,
            ApellidoPaterno: this.sisec.ApellidoPaterno,
            ApellidoMaterno: this.sisec.ApellidoMaterno,
            Curp: this.sisec.CURP,
            Rfc: this.sisec.RFC,
            TelefonoCelular: this.sisec.TelefonoCelular,
            EstadoCivil: this.sisec.EstadoCivil,
            TipoEmpleo: this.sisec.TipoEmpleo,
            Nacionalidad: this.sisec.Nacionalidad,
            Coacreditado: this.sisec.Coacreditado,
            DependientesEconomicos: this.sisec.DependientesEconomicos,
            Sexo: this.sisec.Genero,
            NumeroHijos: this.sisec.NumeroHijos,
            EdadHijos: this.sisec.EdadHijos,
            CodigoPostal: this.sisec.CodigoPostal,
            Automoviles: this.sisec.NumeroAutos,
            Ingresos: this.sisec.Ingresos,
            Escolaridad: this.sisec.GradoEstudios                       
          }
        }
        this.$axios.post('/',objRequestRegistraSolicitanteSisec,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              this.sisec=response.data.objContenido     
              
              this.$vs.notify({
                title: `Datos guardados exitosamente`,
                position: 'top-right',
              })

            }else {
              this.$vs.notify({
                title:'Ocurrio un error en consultar datos del solicitante en la tabla sisec_v3',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      }
    }
  };


  
</script>
<style media="screen">
  a{
    cursor: pointer;
  }
  .float-left{
    text-align: left;
  }
  .cartera{
    margin-top: 7px;
  }
  .add-margin{
    margin-top: 15px;
  }

/* tabs */
#hipotecario-sisec .tab-body {
  padding: 0;
  border-radius: 0;
}
#hipotecario-sisec .tab-body .vs-con-table {
  margin-right: 0;
}
#hipotecario-sisec .tab-body .vs-con-table .vs-con-tbody {
  margin-top: 0 !important;
}
#ppr-sisec .tab-body {
  padding: 0;
  border-radius: 0;
}
#ppr-sisec .tab-body .vs-con-table {
  margin-right: 0;
}
#ppr-sisec .tab-body .vs-con-table .vs-con-tbody {
  margin-top: 0 !important;
}
.ppr-btn-titulo:active,
.ppr-btn-titulo:focus,
.ppr-btn-titulo:hover {
  border: none;
  box-shadow: none;
}
</style>