export const nacionalidades = [
    {Id:'201', Nombre: "AFGANO(A)"},
    {Id:'101', Nombre: "ALBANES"},
    {Id:'102', Nombre: "ALEMAN (A)"},
    {Id:'401', Nombre: "ANGOLEÑO"},
    {Id:'130', Nombre: "ANTIGUA"},
    {Id:'31', Nombre: "ANTIGUA"},
    {Id:'133', Nombre: "ANTILLAS"},
    {Id:'202', Nombre: "ARABE"},
    {Id:'402', Nombre: "ARGELIA"},
    {Id:'3', Nombre: "ARGENTINO (A)"},
    {Id:'235', Nombre: "ARMENIO"},
    {Id:'131', Nombre: "ARUBEÑO"},
    {Id:'301', Nombre: "AUSTRALIANO (A)"},
    {Id:'103', Nombre: "AUSTRIACO (A)"},
    {Id:'236', Nombre: "AZERBAYANO"},
    {Id:'36', Nombre: "BAHAMAS"},
    {Id:'203', Nombre: "BANGLADESH"},
    {Id:'32', Nombre: "BARBADENSE"},
    {Id:'104', Nombre: "BELGA"},
    {Id:'5', Nombre: "BELICENO(A)"},
    {Id:'132', Nombre: "BERMUDA"},
    {Id:'129', Nombre: "BERMUDEÑO"},
    {Id:'7', Nombre: "BOLIVIANO (A)"},
    {Id:'144', Nombre: "BOSNIOHERZEGOVINO"},
    {Id:'9', Nombre: "BRASILEîO (A)"},
    {Id:'105', Nombre: "BULGARO (A)"},
    {Id:'403', Nombre: "BURUNDES"},
    {Id:'34', Nombre: "CAIMANES"},
    {Id:'237', Nombre: "CAMBOYA"},
    {Id:'405', Nombre: "CAMERUNENSE"},
    {Id:'11', Nombre: "CANADIENSE"},
    {Id:'425', Nombre: "CENTROAFRICANO"},
    {Id:'135', Nombre: "CHECOSLOVACA"},
    {Id:'19', Nombre: "CHILENO (A)"},
    {Id:'208', Nombre: "CHINO (A)"},
    {Id:'209', Nombre: "CHIPRIOTA"},
    {Id:'13', Nombre: "COLOMBIANO (A)"},
    {Id:'15', Nombre: "COSTARRICENSE"},
    {Id:'134', Nombre: "CROATA"},
    {Id:'17', Nombre: "CUBANO (A)"},
    {Id:'107', Nombre: "DANES(A)"},
    {Id:'35', Nombre: "DOMINICA"},
    {Id:'26', Nombre: "DOMINICANO(A)"},
    {Id:'21', Nombre: "ECUATORIANO (A)"},
    {Id:'409', Nombre: "EGIPCIO (A)"},
    {Id:'441', Nombre: "EMIRATOS ARABES"},
    {Id:'139', Nombre: "ESLOVAQUIA"},
    {Id:'140', Nombre: "ESLOVENIO(A)"},
    {Id:'108', Nombre: "ESPAÑOL (A)"},
    {Id:'136', Nombre: "ESTONIO"},
    {Id:'410', Nombre: "ETIOPE"},
    {Id:'239', Nombre: "FILIPINAS"},
    {Id:'110', Nombre: "FINLANDES (A)"},
    {Id:'111', Nombre: "FRANCES (A)"},
    {Id:'10', Nombre: "FRANCOGUAYANES"},
    {Id:'411', Nombre: "GABONES"},
    {Id:'412', Nombre: "GHANES"},
    {Id:'37', Nombre: "GRANADA"},
    {Id:'112', Nombre: "GRIEGO(A)"},
    {Id:'4', Nombre: "GROELANDES(A)"},
    {Id:'463', Nombre: "GUAMEÑO (A)"},
    {Id:'6', Nombre: "GUATEMALTECO (A)"},
    {Id:'8', Nombre: "GUAYANES"},
    {Id:'12', Nombre: "HAITIANO (A)"},
    {Id:'428', Nombre: "HISPANOSAHARIANO"},
    {Id:'114', Nombre: "HOLANDES (A)"},
    {Id:'14', Nombre: "HONDUREîO (A)"},
    {Id:'210', Nombre: "HONGKONES"},
    {Id:'115', Nombre: "HUNGARO(A)"},
    {Id:'213', Nombre: "INDONESICO(A)"},
    {Id:'211', Nombre: "INDU"},
    {Id:'113', Nombre: "INGLES (A)"},
    {Id:'214', Nombre: "IRAKI"},
    {Id:'215', Nombre: "IRANI"},
    {Id:'116', Nombre: "IRLANDES (A)"},
    {Id:'117', Nombre: "ISLANDES(A)"},
    {Id:'38', Nombre: "ISLAS COOK"},
    {Id:'216', Nombre: "ISRAELI"},
    {Id:'118', Nombre: "ITALIANO (A)"},
    {Id:'16', Nombre: "JAMAIQUINO (A)"},
    {Id:'217', Nombre: "JAPONES (A)"},
    {Id:'218', Nombre: "JORDANO(A)"},
    {Id:'413', Nombre: "KENIANO (A)"},
    {Id:'219', Nombre: "KUEIT"},
    {Id:'220', Nombre: "LAOSIANO"},
    {Id:'414', Nombre: "LESOTENSE"},
    {Id:'221', Nombre: "LIBANES"},
    {Id:'415', Nombre: "LIBERIA"},
    {Id:'416', Nombre: "LIBICO(A)"},
    {Id:'137', Nombre: "LITUANO"},
    {Id:'119', Nombre: "LUXEMBURGUES"},
    {Id:'145', Nombre: "MACEDONIO"},
    {Id:'222', Nombre: "MALASIO"},
    {Id:'417', Nombre: "MALAWI"},
    {Id:'418', Nombre: "MALIENSE"},
    {Id:'138', Nombre: "MALTES"},
    {Id:'406', Nombre: "MARFILEÑO(A)"},
    {Id:'419', Nombre: "MARROQUI"},
    {Id:'420', Nombre: "MAURITANO"},
    {Id:'1', Nombre: "MEXICANO (A)"},
    {Id:'223', Nombre: "MONGOLES"},
    {Id:'421', Nombre: "MOZAMBIQUEÑO"},
    {Id:'142', Nombre: "MYANMAR"},
    {Id:'143', Nombre: "NAURU"},
    {Id:'303', Nombre: "NEOZELANDES(A)"},
    {Id:'224', Nombre: "NEPALI"},
    {Id:'18', Nombre: "NICARAGUENSE"},
    {Id:'422', Nombre: "NIGERINO"},
    {Id:'423', Nombre: "NIGIERIANO(A)"},
    {Id:'238', Nombre: "NORCOREANO"},
    {Id:'207', Nombre: "NORCOREANO(A)"},
    {Id:'2', Nombre: "NORTEAMERICANO (A)"},
    {Id:'120', Nombre: "NORUEGO (A)"},
    {Id:'225', Nombre: "OMANI"},
    {Id:'999', Nombre: "OTRO"},
    {Id:'442', Nombre: "PALESTINA"},
    {Id:'20', Nombre: "PANAMEîO (A)"},
    {Id:'302', Nombre: "PAPU NEOGUINEANO"},
    {Id:'226', Nombre: "PAQUISTANI"},
    {Id:'22', Nombre: "PARAGUAYO (A)"},
    {Id:'24', Nombre: "PERUANO (A)"},
    {Id:'121', Nombre: "POLACO (A)"},
    {Id:'122', Nombre: "PORTUGES(A)"},
    {Id:'25', Nombre: "PUERTORRIQUEÑO (A)"},
    {Id:'426', Nombre: "RHODESIANO"},
    {Id:'427', Nombre: "RUANDES"},
    {Id:'123', Nombre: "RUMANO (A)"},
    {Id:'124', Nombre: "RUSO (A)"},
    {Id:'23', Nombre: "SALVADOREÑO (A)"},
    {Id:'40', Nombre: "SAN VICENTE"},
    {Id:'429', Nombre: "SENEGAL"},
    {Id:'146', Nombre: "SERBIO"},
    {Id:'233', Nombre: "SINGAPUR"},
    {Id:'228', Nombre: "SIRIO(A)"},
    {Id:'431', Nombre: "SOSOMMALI"},
    {Id:'206', Nombre: "SRILANQUES"},
    {Id:'432', Nombre: "SUDANES (A)"},
    {Id:'125', Nombre: "SUECO (A)"},
    {Id:'126', Nombre: "SUIZO (A)"},
    {Id:'433', Nombre: "SURAFRICANO (A)"},
    {Id:'550', Nombre: "SURCOREANO(A)"},
    {Id:'27', Nombre: "SURINAMES"},
    {Id:'229', Nombre: "TAHILANDES"},
    {Id:'234', Nombre: "TAIWANES"},
    {Id:'434', Nombre: "TANZANO"},
    {Id:'435', Nombre: "TOGOLES"},
    {Id:'28', Nombre: "TRINITENSE"},
    {Id:'436', Nombre: "TUNECINO"},
    {Id:'230', Nombre: "TURCO(A)"},
    {Id:'127', Nombre: "UCRANIANO(A)"},
    {Id:'437', Nombre: "UGANDES"},
    {Id:'141', Nombre: "UNION MONETARIA EUROPEA"},
    {Id:'29', Nombre: "URUAGUAYO (A)"},
    {Id:'30', Nombre: "VENEZOLANO (A)"},
    {Id:'231', Nombre: "VIETNAMITA"},
    {Id:'33', Nombre: "VIRGENENSE"},
    {Id:'232', Nombre: "YEMENI"},
    {Id:'128', Nombre: "YUGOSLAVO (A)"},
    {Id:'438', Nombre: "ZAIREÑO"},
    {Id:'439', Nombre: "ZAMBIANO"},
    {Id:'440', Nombre: "ZIMBABUENSE"},
]
