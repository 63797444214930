export const paisesNacimiento = [
    {Id: 1, Nombre: 'MÉXICO'},
    {Id: 2, Nombre: 'ESTADOS UNIDOS'},
    {Id: 3, Nombre: 'CANADÁ'},
    {Id: 4, Nombre: 'ALBANIA'},
    {Id: 5, Nombre: 'ALEMANIA'},
    {Id: 6, Nombre: 'ANDORRA'},
    {Id: 7, Nombre: 'ANGOLA'},
    {Id: 8, Nombre: 'ANTIGUA Y BARBUDA'},
    {Id: 9, Nombre: 'ARABIA SAUDITA'},
    {Id: 10, Nombre: 'ARGELIA'},
    {Id: 11, Nombre: 'ARGENTINA'},
    {Id: 12, Nombre: 'ARMENIA'},
    {Id: 13, Nombre: 'AUSTRALIA'},
    {Id: 14, Nombre: 'AUSTRIA'},
    {Id: 15, Nombre: 'AZERBAIYÁN'},
    {Id: 16, Nombre: 'BAHAMAS'},
    {Id: 17, Nombre: 'BANGLADÉS'},
    {Id: 18, Nombre: 'BARBADOS'},
    {Id: 19, Nombre: 'BARÉIN'},
    {Id: 20, Nombre: 'BÉLGICA'},
    {Id: 21, Nombre: 'BELICE'},
    {Id: 22, Nombre: 'BENÍN'},
    {Id: 23, Nombre: 'BIELORRUSIA'},
    {Id: 24, Nombre: 'BIRMANIA/MYANMAR'},
    {Id: 25, Nombre: 'BOLIVIA'},
    {Id: 26, Nombre: 'BOSNIA Y HERZEGOVINA'},
    {Id: 27, Nombre: 'BOTSUANA'},
    {Id: 28, Nombre: 'BRASIL'},
    {Id: 29, Nombre: 'BRUNÉI'},
    {Id: 30, Nombre: 'BULGARIA'},
    {Id: 31, Nombre: 'BURKINA FASO'},
    {Id: 32, Nombre: 'BURUNDI'},
    {Id: 33, Nombre: 'BUTÁN'},
    {Id: 34, Nombre: 'CABO VERDE'},
    {Id: 35, Nombre: 'CAMBOYA'},
    {Id: 36, Nombre: 'CAMERÚN'},
    {Id: 37, Nombre: 'CATAR'},
    {Id: 38, Nombre: 'CHAD'},
    {Id: 39, Nombre: 'CHILE'},
    {Id: 40, Nombre: 'CHINA'},
    {Id: 41, Nombre: 'CHIPRE'},
    {Id: 42, Nombre: 'CIUDAD DEL VATICANO'},
    {Id: 43, Nombre: 'COLOMBIA'},
    {Id: 44, Nombre: 'COMORAS'},
    {Id: 45, Nombre: 'COREA DEL NORTE'},
    {Id: 46, Nombre: 'COREA DEL SUR'},
    {Id: 47, Nombre: 'COSTA DE MARFIL'},
    {Id: 48, Nombre: 'COSTA RICA'},
    {Id: 49, Nombre: 'CROACIA'},
    {Id: 50, Nombre: 'CUBA'},
    {Id: 51, Nombre: 'DINAMARCA'},
    {Id: 52, Nombre: 'DOMINICA'},
    {Id: 53, Nombre: 'ECUADOR'},
    {Id: 54, Nombre: 'EGIPTO'},
    {Id: 55, Nombre: 'EL SALVADOR'},
    {Id: 56, Nombre: 'EMIRATOS ÁRABES UNIDOS'},
    {Id: 57, Nombre: 'ERITREA'},
    {Id: 58, Nombre: 'ESLOVAQUIA'},
    {Id: 59, Nombre: 'ESLOVENIA'},
    {Id: 60, Nombre: 'ESPAÑA'},
    {Id: 61, Nombre: 'ESTONIA'},
    {Id: 62, Nombre: 'ETIOPÍA'},
    {Id: 63, Nombre: 'FILIPINAS'},
    {Id: 64, Nombre: 'FINLANDIA'},
    {Id: 65, Nombre: 'FIYI'},
    {Id: 66, Nombre: 'FRANCIA'},
    {Id: 67, Nombre: 'GABÓN'},
    {Id: 68, Nombre: 'GAMBIA'},
    {Id: 69, Nombre: 'GEORGIA'},
    {Id: 70, Nombre: 'GHANA'},
    {Id: 71, Nombre: 'GRANADA'},
    {Id: 72, Nombre: 'GRECIA'},
    {Id: 73, Nombre: 'GUATEMALA'},
    {Id: 74, Nombre: 'GUYANA'},
    {Id: 75, Nombre: 'GUINEA'},
    {Id: 76, Nombre: 'GUINEA-BISÁU'},
    {Id: 77, Nombre: 'GUINEA ECUATORIAL'},
    {Id: 78, Nombre: 'HAITÍ'},
    {Id: 79, Nombre: 'HONDURAS'},
    {Id: 80, Nombre: 'HUNGRÍA'},
    {Id: 81, Nombre: 'INDIA'},
    {Id: 82, Nombre: 'INDONESIA'},
    {Id: 83, Nombre: 'IRAK'},
    {Id: 84, Nombre: 'IRÁN'},
    {Id: 85, Nombre: 'IRLANDA'},
    {Id: 86, Nombre: 'ISLANDIA'},
    {Id: 87, Nombre: 'ISLAS MARSHALL'},
    {Id: 88, Nombre: 'ISLAS SALOMÓN'},
    {Id: 89, Nombre: 'ISRAEL'},
    {Id: 90, Nombre: 'ITALIA'},
    {Id: 91, Nombre: 'JAMAICA'},
    {Id: 92, Nombre: 'JAPÓN'},
    {Id: 93, Nombre: 'JORDANIA'},
    {Id: 94, Nombre: 'KAZAJISTÁN'},
    {Id: 95, Nombre: 'KENIA'},
    {Id: 96, Nombre: 'KIRGUISTÁN'},
    {Id: 97, Nombre: 'KIRIBATI'},
    {Id: 98, Nombre: 'KUWAIT'},
    {Id: 99, Nombre: 'LAOS'},
    {Id: 100, Nombre: 'LESOTO'},
    {Id: 101, Nombre: 'LETONIA'},
    {Id: 102, Nombre: 'LÍBANO'},
    {Id: 103, Nombre: 'LIBERIA'},
    {Id: 104, Nombre: 'LIBIA'},
    {Id: 105, Nombre: 'LIECHTENSTEIN'},
    {Id: 106, Nombre: 'LITUANIA'},
    {Id: 107, Nombre: 'LUXEMBURGO'},
    {Id: 108, Nombre: 'MACEDONIA DEL NORTE'},
    {Id: 109, Nombre: 'MADAGASCAR'},
    {Id: 110, Nombre: 'MALASIA'},
    {Id: 111, Nombre: 'MALAUI'},
    {Id: 112, Nombre: 'MALDIVAS'},
    {Id: 113, Nombre: 'MALÍ'},
    {Id: 114, Nombre: 'MALTA'},
    {Id: 115, Nombre: 'MARRUECOS'},
    {Id: 116, Nombre: 'MAURICIO'},
    {Id: 117, Nombre: 'MAURITANIA'},
    {Id: 118, Nombre: 'MICRONESIA'},
    {Id: 119, Nombre: 'MOLDAVIA'},
    {Id: 120, Nombre: 'MÓNACO'},
    {Id: 121, Nombre: 'MONGOLIA'},
    {Id: 122, Nombre: 'MONTENEGRO'},
    {Id: 123, Nombre: 'MOZAMBIQUE'},
    {Id: 124, Nombre: 'NAMIBIA'},
    {Id: 125, Nombre: 'NAURU'},
    {Id: 126, Nombre: 'NEPAL'},
    {Id: 127, Nombre: 'NICARAGUA'},
    {Id: 128, Nombre: 'NÍGER'},
    {Id: 129, Nombre: 'NIGERIA'},
    {Id: 130, Nombre: 'NORUEGA'},
    {Id: 131, Nombre: 'NUEVA ZELANDA'},
    {Id: 132, Nombre: 'OMÁN'},
    {Id: 133, Nombre: 'PAÍSES BAJOS'},
    {Id: 134, Nombre: 'PAKISTÁN'},
    {Id: 135, Nombre: 'PALAOS'},
    {Id: 136, Nombre: 'PANAMÁ'},
    {Id: 137, Nombre: 'PAPÚA NUEVA GUINEA'},
    {Id: 138, Nombre: 'PARAGUAY'},
    {Id: 139, Nombre: 'PERÚ'},
    {Id: 140, Nombre: 'POLONIA'},
    {Id: 141, Nombre: 'PORTUGAL'},
    {Id: 142, Nombre: 'REINO UNIDO'},
    {Id: 143, Nombre: 'REPÚBLICA CENTROAFRICANA'},
    {Id: 144, Nombre: 'REPÚBLICA CHECA'},
    {Id: 145, Nombre: 'REPÚBLICA DEL CONGO'},
    {Id: 146, Nombre: 'REPÚBLICA DEMOCRÁTICA DEL CONGO'},
    {Id: 147, Nombre: 'REPÚBLICA DOMINICANA'},
    {Id: 148, Nombre: 'REPÚBLICA SUDAFRICANA'},
    {Id: 149, Nombre: 'RUANDA'},
    {Id: 150, Nombre: 'RUMANÍA'},
    {Id: 151, Nombre: 'RUSIA'},
    {Id: 152, Nombre: 'SAMOA'},
    {Id: 153, Nombre: 'SAN CRISTÓBAL Y NIEVES'},
    {Id: 154, Nombre: 'SAN MARINO'},
    {Id: 155, Nombre: 'SAN VICENTE Y LAS GRANADINAS'},
    {Id: 156, Nombre: 'SANTA LUCÍA'},
    {Id: 157, Nombre: 'SANTO TOMÉ Y PRÍNCIPE'},
    {Id: 158, Nombre: 'SENEGAL'},
    {Id: 159, Nombre: 'SERBIA'},
    {Id: 160, Nombre: 'SEYCHELLES'},
    {Id: 161, Nombre: 'SIERRA LEONA'},
    {Id: 162, Nombre: 'SINGAPUR'},
    {Id: 163, Nombre: 'SIRIA'},
    {Id: 164, Nombre: 'SOMALIA'},
    {Id: 165, Nombre: 'SRI LANKA'},
    {Id: 166, Nombre: 'SUAZILANDIA'},
    {Id: 167, Nombre: 'SUDÁN'},
    {Id: 168, Nombre: 'SUDÁN DEL SUR'},
    {Id: 169, Nombre: 'SUECIA'},
    {Id: 170, Nombre: 'SUIZA'},
    {Id: 171, Nombre: 'SURINAM'},
    {Id: 172, Nombre: 'TAILANDIA'},
    {Id: 173, Nombre: 'TANZANIA'},
    {Id: 174, Nombre: 'TAYIKISTÁN'},
    {Id: 175, Nombre: 'TIMOR ORIENTAL'},
    {Id: 176, Nombre: 'TOGO'},
    {Id: 177, Nombre: 'TONGA'},
    {Id: 178, Nombre: 'TRINIDAD Y TOBAGO'},
    {Id: 179, Nombre: 'TÚNEZ'},
    {Id: 180, Nombre: 'TURKMENISTÁN'},
    {Id: 181, Nombre: 'TURQUÍA'},
    {Id: 182, Nombre: 'TUVALU'},
    {Id: 183, Nombre: 'UCRANIA'},
    {Id: 184, Nombre: 'UGANDA'},
    {Id: 185, Nombre: 'URUGUAY'},
    {Id: 186, Nombre: 'UZBEKISTÁN'},
    {Id: 187, Nombre: 'VANUATU'},
    {Id: 188, Nombre: 'VENEZUELA'},
    {Id: 189, Nombre: 'VIETNAM'},
    {Id: 190, Nombre: 'YEMEN'},
    {Id: 191, Nombre: 'YIBUTI'},
    {Id: 192, Nombre: 'ZAMBIA'},
    {Id: 193, Nombre: 'ZIMBABUE'},
]