export const PaisesHSBC = [
    {Id: 1, Nombre: 'AFGANISTAN'},
    {Id: 2, Nombre: 'ALBANIA'},
    {Id: 3, Nombre: 'ALEMANIA'},
    {Id: 4, Nombre: 'AMERICAN SAMOA'},
    {Id: 5, Nombre: 'ANDORRA'},
    {Id: 6, Nombre: 'ANGOLA'},
    {Id: 7, Nombre: 'ANGUILA'},
    {Id: 8, Nombre: 'ANTIGUA Y BARBUDA'},
    {Id: 9, Nombre: 'ANTILLAS HOLANDESAS'},
    {Id: 10, Nombre: 'ARABIA SAUDITA'},
    {Id: 11, Nombre: 'ARGELIA'},
    {Id: 12, Nombre: 'ARGENTINA'},
    {Id: 13, Nombre: 'ARMENIA'},
    {Id: 14, Nombre: 'ARUBA'},
    {Id: 15, Nombre: 'AUSTRALIA'},
    {Id: 16, Nombre: 'AUSTRIA'},
    {Id: 17, Nombre: 'AZERBAIYÁN'},
    {Id: 18, Nombre: 'BAHAMAS'},
    {Id: 19, Nombre: 'BAHRÉIN'},
    {Id: 20, Nombre: 'BANGLADÉSH'},
    {Id: 21, Nombre: 'BARBADOS'},
    {Id: 22, Nombre: 'BÉLGICA'},
    {Id: 23, Nombre: 'BELICE'},
    {Id: 24, Nombre: 'BENÍN'},
    {Id: 25, Nombre: 'BERMUDA'},
    {Id: 26, Nombre: 'BHUTAN'},
    {Id: 27, Nombre: 'BIELORRUSIA'},
    {Id: 28, Nombre: 'BOLIVIA'},
    {Id: 29, Nombre: 'BOSNIA Y HERZEGOVINA'},
    {Id: 30, Nombre: 'BOTSUANA'},
    {Id: 31, Nombre: 'BRASIL'},
    {Id: 32, Nombre: 'BRUNÉI'},
    {Id: 33, Nombre: 'BULGARIA'},
    {Id: 34, Nombre: 'BURKINA FASO'},
    {Id: 35, Nombre: 'BURUNDI'},
    {Id: 36, Nombre: 'CABO VERDE'},
    {Id: 37, Nombre: 'CAMBOYA'},
    {Id: 38, Nombre: 'CAMERUN'},
    {Id: 39, Nombre: 'CANADÁ'},
    {Id: 40, Nombre: 'CHAD'},
    {Id: 41, Nombre: 'CHILE'},
    {Id: 42, Nombre: 'CHINA'},
    {Id: 43, Nombre: 'CHIPRE'},
    {Id: 44, Nombre: 'CIUDAD DEL VATICANO'},
    {Id: 45, Nombre: 'COCOS (KEELING) ES.'},
    {Id: 46, Nombre: 'COLOMBIA'},
    {Id: 47, Nombre: 'COMORAS'},
    {Id: 48, Nombre: 'CONGO DEM. REP.'},
    {Id: 49, Nombre: 'CONGO REP.'},
    {Id: 50, Nombre: 'COREA DEL NORTE'},
    {Id: 51, Nombre: 'COREA DEL SUR'},
    {Id: 52, Nombre: 'COSTA RICA'},
    {Id: 53, Nombre: 'CROACIA'},
    {Id: 54, Nombre: 'CUBA'},
    {Id: 55, Nombre: 'DESCONOCIDO'},
    {Id: 56, Nombre: 'DINAMARCA'},
    {Id: 57, Nombre: 'DOMINICA'},
    {Id: 58, Nombre: 'ECUADOR'},
    {Id: 59, Nombre: 'EE.UU.'},
    {Id: 60, Nombre: 'EGIPTO'},
    {Id: 61, Nombre: 'EL SALVADOR'},
    {Id: 62, Nombre: 'EMERITOS ÁRABES UNIDOS'},
    {Id: 63, Nombre: 'ERITREA'},
    {Id: 64, Nombre: 'ESLOVAQUIA'},
    {Id: 65, Nombre: 'ESTONIA'},
    {Id: 66, Nombre: 'ETIOPIA'},
    {Id: 67, Nombre: 'FEDERACION DE RUSIA'},
    {Id: 68, Nombre: 'FILIPINAS'},
    {Id: 69, Nombre: 'FINLANDIA'},
    {Id: 70, Nombre: 'FIYI'},
    {Id: 71, Nombre: 'FRANCIA'},
    {Id: 72, Nombre: 'GABÓN'},
    {Id: 73, Nombre: 'GAMBIA'},
    {Id: 74, Nombre: 'GEORGIA'},
    {Id: 75, Nombre: 'GHANA'},
    {Id: 76, Nombre: 'GIBRALTAR'},
    {Id: 77, Nombre: 'GRANADA'},
    {Id: 78, Nombre: 'GRECIA'},
    {Id: 79, Nombre: 'GROENLANDIA'},
    {Id: 80, Nombre: 'GRUPO GUAM'},
    {Id: 81, Nombre: 'GUADALUPE'},
    {Id: 82, Nombre: 'GUATEMALA'},
    {Id: 83, Nombre: 'GUAYANA FRANCESA'},
    {Id: 84, Nombre: 'GUERSNEY'},
    {Id: 85, Nombre: 'GUINEA'},
    {Id: 86, Nombre: 'GUINEA ECUATORIAL'},
    {Id: 87, Nombre: 'GUINEA-BISSÁU'},
    {Id: 88, Nombre: 'GUYANA'},
    {Id: 89, Nombre: 'HAITÍ'},
    {Id: 90, Nombre: 'HONDURAS'},
    {Id: 91, Nombre: 'HONG KONG'},
    {Id: 92, Nombre: 'HUNGRÍA'},
    {Id: 93, Nombre: 'INDIA'},
    {Id: 94, Nombre: 'INDONESIA'},
    {Id: 95, Nombre: 'IRÁN'},
    {Id: 96, Nombre: 'IRAQ'},
    {Id: 97, Nombre: 'IRLANDA'},
    {Id: 98, Nombre: 'ISLA DE LA ASCENSION'},
    {Id: 99, Nombre: 'ISLA DE MAN'},
    {Id: 100, Nombre: 'ISLA DE NAVIDAD'},
    {Id: 101, Nombre: 'ISLA NORFOLK'},
    {Id: 102, Nombre: 'ISLANDIA'},
    {Id: 103, Nombre: 'ISLAS ALAND'},
    {Id: 104, Nombre: 'ISLAS CAIMAN'},
    {Id: 105, Nombre: 'ISLAS COOK'},
    {Id: 106, Nombre: 'ISLAS FEROE'},
    {Id: 107, Nombre: 'ISLAS MALVINAS'},
    {Id: 108, Nombre: 'ISLAS MARSHALL'},
    {Id: 109, Nombre: 'ISLAS TURCAS Y CAICOS.'},
    {Id: 110, Nombre: 'ISLAS VIRGENES (BRITANICAS)'},
    {Id: 111, Nombre: 'ISLAS VIRGENES (EE.UU.)'},
    {Id: 112, Nombre: 'ISRAEL'},
    {Id: 113, Nombre: 'ITALIA'},
    {Id: 114, Nombre: 'JAMAICA'},
    {Id: 115, Nombre: 'JAPÓN'},
    {Id: 116, Nombre: 'JERSEY'},
    {Id: 117, Nombre: 'JORDANIA'},
    {Id: 118, Nombre: 'KAZAJSTÁN'},
    {Id: 119, Nombre: 'KENIA'},
    {Id: 120, Nombre: 'KIRIBATI'},
    {Id: 121, Nombre: 'KUWAIT'},
    {Id: 122, Nombre: 'KYGYZSTAN'},
    {Id: 123, Nombre: 'LAOS'},
    {Id: 124, Nombre: 'LESOTHO'},
    {Id: 125, Nombre: 'LETONIA'},
    {Id: 126, Nombre: 'LÍBANO'},
    {Id: 127, Nombre: 'LIBERIA'},
    {Id: 128, Nombre: 'LIBIA'},
    {Id: 129, Nombre: 'LIECHTENSTEIN'},
    {Id: 130, Nombre: 'LITUANIA'},
    {Id: 131, Nombre: 'LUXEMBURGO'},
    {Id: 132, Nombre: 'MACAO'},
    {Id: 133, Nombre: 'MACEDONIA'},
    {Id: 134, Nombre: 'MADAGASCAR'},
    {Id: 135, Nombre: 'MALASIA'},
    {Id: 136, Nombre: 'MALAWI'},
    {Id: 137, Nombre: 'MALDIVAS'},
    {Id: 138, Nombre: 'MALÍ'},
    {Id: 139, Nombre: 'MALTA'},
    {Id: 140, Nombre: 'MARIANA N. ES.'},
    {Id: 141, Nombre: 'MARRUECOS'},
    {Id: 142, Nombre: 'MARTINICA'},
    {Id: 143, Nombre: 'MAURICIO'},
    {Id: 144, Nombre: 'MAURITANIA'},
    {Id: 145, Nombre: 'MAYOTTE'},
    {Id: 146, Nombre: 'MÉXICO'},
    {Id: 147, Nombre: 'MICRONESIA'},
    {Id: 148, Nombre: 'MOLDAVIA'},
    {Id: 149, Nombre: 'MONACO'},
    {Id: 150, Nombre: 'MONGOLIA'},
    {Id: 151, Nombre: 'MONTENEGRO'},
    {Id: 152, Nombre: 'MONTSERRAT'},
    {Id: 153, Nombre: 'MOZAMBIQUE'},
    {Id: 154, Nombre: 'MYANMAR'},
    {Id: 155, Nombre: 'NAMIBIA'},
    {Id: 156, Nombre: 'NAURU'},
    {Id: 157, Nombre: 'NEPAL'},
    {Id: 158, Nombre: 'NICARAGUA'},
    {Id: 159, Nombre: 'NÍGER'},
    {Id: 160, Nombre: 'NIGERIA'},
    {Id: 161, Nombre: 'NIUE'},
    {Id: 162, Nombre: 'NORUEGA'},
    {Id: 163, Nombre: 'NUEVA CALEDONIA'},
    {Id: 164, Nombre: 'NUEVA ZELANDA'},
    {Id: 165, Nombre: 'OMÁN'},
    {Id: 166, Nombre: 'PAISES'},
    {Id: 167, Nombre: 'PAÍSES BAJOS'},
    {Id: 168, Nombre: 'PAKISTÁN'},
    {Id: 169, Nombre: 'PALAU'},
    {Id: 170, Nombre: 'PALESTINA'},
    {Id: 171, Nombre: 'PANAMÁ'},
    {Id: 172, Nombre: 'PAPÚA NUEVA GUINEA'},
    {Id: 173, Nombre: 'PARAGUAY'},
    {Id: 174, Nombre: 'PERÚ'},
    {Id: 175, Nombre: 'PITCAIRN'},
    {Id: 176, Nombre: 'POLINESIA FRANCESA'},
    {Id: 177, Nombre: 'POLONIA'},
    {Id: 178, Nombre: 'PORTUGAL'},
    {Id: 179, Nombre: 'PUERTO RICO'},
    {Id: 180, Nombre: 'QATAR'},
    {Id: 181, Nombre: 'REINO UNIDO'},
    {Id: 182, Nombre: 'REPÚBLICA CENTROAFRICANA'},
    {Id: 183, Nombre: 'REPÚBLICA CHECA'},
    {Id: 184, Nombre: 'REPÚBLICA DOMINICANA'},
    {Id: 185, Nombre: 'REUNION'},
    {Id: 186, Nombre: 'RUANDA'},
    {Id: 187, Nombre: 'RUMANÍA'},
    {Id: 188, Nombre: 'SAHARA OCCIDENTAL'},
    {Id: 189, Nombre: 'SAINT KITTS Y NEVIS'},
    {Id: 190, Nombre: 'SAMOA'},
    {Id: 191, Nombre: 'SAN MARINO'},
    {Id: 192, Nombre: 'SAN VICENTE Y LAS GRANADINAS'},
    {Id: 193, Nombre: 'SANTA LUCÍA'},
    {Id: 194, Nombre: 'SANTO TOMÉ Y PRÍNCIPE'},
    {Id: 195, Nombre: 'SENEGAL'},
    {Id: 196, Nombre: 'SERBIA'},
    {Id: 197, Nombre: 'SERBIA Y MONTENEGRO'},
    {Id: 198, Nombre: 'SEYCHELLES'},
    {Id: 199, Nombre: 'SIERRA LEONA'},
    {Id: 200, Nombre: 'SINGAPUR'},
    {Id: 201, Nombre: 'SIRIA'},
    {Id: 202, Nombre: 'SOMALIA'},
    {Id: 203, Nombre: 'SOMALILANDIA'},
    {Id: 204, Nombre: 'ST. PIERRE & MIQUELON'},
    {Id: 205, Nombre: 'SUDAFRICA'},
    {Id: 206, Nombre: 'SUDÁN'},
    {Id: 207, Nombre: 'SUECIA'},
    {Id: 208, Nombre: 'SUIZA'},
    {Id: 209, Nombre: 'SURINAM'},
    {Id: 210, Nombre: 'SVALBARD'},
    {Id: 211, Nombre: 'SWAZILANDIA'},
    {Id: 212, Nombre: 'TAILANDIA'},
    {Id: 213, Nombre: 'TAIWAN'},
    {Id: 214, Nombre: 'TANZANIA'},
    {Id: 215, Nombre: 'TAYIKISTAN'},
    {Id: 216, Nombre: 'TIMOR ORIENTAL'},
    {Id: 217, Nombre: 'TOGO'},
    {Id: 218, Nombre: 'TOKELAU'},
    {Id: 219, Nombre: 'TONGA'},
    {Id: 220, Nombre: 'TRINIDAD Y TOBAGO'},
    {Id: 221, Nombre: 'TÚNEZ'},
    {Id: 222, Nombre: 'TURKMENISTÁN'},
    {Id: 223, Nombre: 'TURQUÍA'},
    {Id: 224, Nombre: 'TUVALU'},
    {Id: 225, Nombre: 'UCRANIA'},
    {Id: 226, Nombre: 'UGANDA'},
    {Id: 227, Nombre: 'URUGUAY'},
    {Id: 228, Nombre: 'UZBEKISTÁN'},
    {Id: 229, Nombre: 'VANUATU'},
    {Id: 230, Nombre: 'VENEZUELA'},
    {Id: 231, Nombre: 'VIET NAM'},
    {Id: 232, Nombre: 'WALLIS Y FUNTUNA ES.'},
    {Id: 233, Nombre: 'YEMEN'},
    {Id: 234, Nombre: 'YIBUTI'},
    {Id: 235, Nombre: 'ZAMBIA '},
    {Id: 236, Nombre: 'ZIMBABWE'},    
]

