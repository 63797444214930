<template lang="html">
  <div id="container">
    <div class="">
      <div class="">
        <!-- <vx-card title="Obligados solidarios" class=""> -->
        <vx-card class="">

          <div class="flex justify-between items-center mb-10">
            <h4>Obligados solidarios</h4>
            <button 
              class="btn btn-second" 
              @click="toggleSave()" 
              v-bind:hidden="!isObligadoEdit">Editar</button>
            <button 
              v-bind:hidden="isObligadoEdit" 
              class="btn btn-second" @click="registraSolicitante(); toggleSave()" 
              :disabled="!validateForm">Guardar Obligado solidario</button>
          </div>

          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Correo electrónico*</span>
              <input 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="correo" name="name" type="email" :disabled="!existeObligado && !isObligadoEdit ? false : true"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Nombre(s) *</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="nombre" 
                name="name"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Apellido Paterno*</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="apellidoPaterno" 
                name="name"/>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Apellido Materno</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="apellidoMaterno" 
                name="name"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Teléfono celular* (10 dígitos)</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="telefono" 
                name="date" 
                v-on:keypress="isNumber($event)"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Fecha de nacimiento*</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                type="date" 
                v-model="fechaNacimiento" 
                name="fechaNacimiento" />
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">CURP</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="curp" 
                name="name"/>
            </div>
            <div class="col-md-4">
                <span for="sexo">Sexo</span>
                <div class="col-md-4 alinear">
                <div class="col-md-6 mr-4">
                  <span class="span-placeholder">Hombre</span>
                  <vs-radio v-bind:disabled="isObligadoEdit" v-model="sexo" vs-name="sexocon" vs-value="1"></vs-radio>
                </div> 
                <div class="col-md-6 ml-4"> 
                  <span class="span-placeholder">Mujer</span>
                  <vs-radio v-bind:disabled="isObligadoEdit" v-model="sexo" vs-name="sexocon" vs-value="2"></vs-radio>
                </div>
                </div>  
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">País de nacimiento</span>
              <select 
                name="name" 
                v-bind:disabled="isObligadoEdit"
                id="paisNacimiento" 
                v-model="paisNacimiento" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
              >
                <option :value='pais.Nombre' v-for="pais in paisesNac"  :key="">{{pais.Nombre}}</option>
              </select>

              <!-- <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="paisNacimiento" 
                name="name"/> -->
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">RFC</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                label="RFC" 
                v-model="rfc" 
                name="name"/>
            </div>
          </div>
          <div class="row w-full">

          <div class="col-md-4">
            <span class="span-placeholder" for="nacionalidad">Nacionalidad</span>
            <select 
              v-bind:disabled="isObligadoEdit" 
              name="cars" id="nacionalidad" 
              v-model="nacionalidad" 
              v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
            >
              <option :value="''">Seleccione...</option>
              <option :value="tipo.Nombre" v-for="tipo in nacionalidades">{{ tipo.Nombre }}</option>
            </select>
          </div>

            <!-- <div class="col-md-4">
              <span class="span-placeholder">Nacionalidad</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="nacionalidad" 
                name="name"/>
            </div> -->


            <div class="col-md-4">
              <span class="span-placeholder" for="formasEnganche">Escolaridad</span>
              <select 
                v-bind:disabled="isObligadoEdit" 
                name="cars" 
                id="formasEnganche" 
                v-model="escolaridad" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
              >
                <option :value="''">Seleccione...</option>
                <option :value='tipo.Id' v-for="tipo in escolaridades"  :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Lugar de nacimiento</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="lugarNacimiento" 
                maxlength="30" 
                name="name"/>
            </div>
          </div>
          <div class="row w-full">  
            <div class="col-md-4">
              <span class="span-placeholder" for="formasEnganche">Parentesco</span>
              <select 
                v-bind:disabled="isObligadoEdit" 
                name="cars" 
                id="formasEnganche" 
                v-model="parentesco" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
              >
                <option :value="''">Seleccione...</option>
                <option :value='tipo.Id' v-for="tipo in parentescos" >{{tipo.Nombre}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Teléfono fijo</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="telefonoFijo"  
                maxlength="10" 
                minlength="10"  
                name="name" 
                v-on:keypress="isNumber($event)"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder" for="formasEnganche">Tipo de identificación</span>
              <select 
                v-bind:disabled="isObligadoEdit" 
                name="cars" 
                id="identificacion" 
                v-model="identificacion" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
              >
                <option :value="''">Seleccione...</option>
                <option :value='tipo.Id' v-for="tipo in identificaciones" >{{tipo.Nombre}}</option>
              </select>
            </div>
          </div> 
          <div class="row w-full"> 
            <div class="col-md-4">
              <span class="span-placeholder">No. de identificación</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="numeroIdentificacion" 
                maxlength="13"  
                name="name" 
                v-on:keypress="isNumber($event)"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder" for="formasEnganche">Estado civil</span>
              <select 
                v-bind:disabled="isObligadoEdit" 
                name="cars" 
                id="formasEnganche" 
                v-model="estadoCivil" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                @change="dameConyuge()">
                <option :value="''">Seleccione...</option>
                <option :value='tipo.Id' v-for="tipo in estadosCiviles" >{{tipo.Nombre}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder" for="formasEnganche">Régimen matrimonial</span>
              <select 
                v-bind:disabled="isObligadoEdit" 
                name="cars" 
                id="formasEnganche" 
                v-model="regimenMatrimonial" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
              >
                <option :value="''">Seleccione...</option>
                <option :value='tipo.Id' v-for="tipo in regimenes" >{{tipo.Nombre}}</option>
              </select>
            </div>
          </div>
          <div class="row w-full" v-if="estadoCivil == 1">
             <div class="col-md-4">
               <span class="span-placeholder">Nombres(s) cónyuge*</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="nombreConyuge" 
                name="name"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder"> Apellido Paterno cónyuge*</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"  
                v-model="apellidoPaternoConyuge" 
                name="name"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Apellido Materno cónyuge*</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="apellidoMaternoConyuge" 
                name="name"/>
            </div>
          </div>
          <div class="row w-full" v-if="estadoCivil == 1 && conyugeIsCoacred=='FALSE'">
            <div class="col-md-4" >
              <span class="span-placeholder">CURP cónyuge</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="curpConyuge" 
                name="name"/>
            </div>
            <div class="col-md-4" >
               <span class="span-placeholder">RFC cónyuge</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="rfcConyuge" 
                name="name"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Fecha de nacimiento*</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                type="date" 
                v-model="fechaNacimientoConyuge" 
                name="fechaNacimiento" />
            </div>
          </div>
           <div class="row w-full" v-if="estadoCivil == 1 && conyugeIsCoacred=='FALSE'">
            
            <div class="col-md-4">
              <span class="span-placeholder">Edad cónyuge</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="edadConyuge" 
                name="name"/>
            </div>

            <div class="col-md-4">
              <span class="span-placeholder" for="nacionalidadConyuge">Nacionalidad</span>
              <select 
                v-bind:disabled="isObligadoEdit" 
                name="name" id="nacionalidadConyuge" 
                v-model="nacionalidadConyuge" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
              >
                <option :value="''">Seleccione...</option>
                <option :value="tipo.Nombre" v-for="tipo in nacionalidades">{{ tipo.Nombre }}</option>
              </select>
              <!-- <input class="vs-input" label="Nacionalidad" v-model="nacionalidad" name="username" />-->
            </div>

            <!-- <div class="col-md-4">
              <span class="span-placeholder">Nacionalidad</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="nacionalidadConyuge" 
                name="name"/>
            </div> -->


            <div class="col-md-4">
                <span for="sexo">Sexo</span>
                <div class="col-md-4 alinear">
                <div class="col-md-6 mr-4">
                  <span class="span-placeholder">Hombre</span>
                  <vs-radio v-bind:disabled="isObligadoEdit" v-model="sexoConyuge" vs-name="sexo" vs-value="Hombre"></vs-radio>
                </div> 
                <div class="col-md-6 ml-4"> 
                  <span class="span-placeholder">Mujer</span>
                  <vs-radio v-bind:disabled="isObligadoEdit" v-model="sexoConyuge" vs-name="sexo" vs-value="Mujer"></vs-radio>
                </div>
                </div>  
            </div>
          </div>
          <div class="row w-full" v-if="estadoCivil == 1 && conyugeIsCoacred=='FALSE'">
            <div class="col-md-4">
              <span class="span-placeholder">Domicilio (Calle y número) cónyuge</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="domicilioConyuge" 
                name="name"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Colonia cónyuge</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="coloniaConyuge" 
                name="name"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Municipio y entidad federativa cónyuge</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="municipioConyuge" 
                name="name"/>
            </div>
          </div>
          <div class="row w-full" v-if="estadoCivil == 1 && conyugeIsCoacred=='FALSE'">
            <div class="col-md-4">
              <span class="span-placeholder">Código postal</span>
              <input 
                v-bind:disabled="isObligadoEdit" 
                v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                v-model="codigoPostalConyuge" 
                name="name" />
            </div>
            <!--<div class="vx-col lg:w-5/6 sm:w-full">
              <input class="vs-input" label="Ocupación conyuge" v-model="ocupacionConyuge" name="name"/>
            </div>-->
          </div>
          <div class="row w-full">
            <div class="accordion" id="accordionExampleOS">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOneOS">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneOS" aria-expanded="true" aria-controls="collapseOneOS">
                    Domicilio
                  </button>
                </h2>
                <div id="collapseOneOS" class="accordion-collapse collapse" aria-labelledby="headingOneOS" data-bs-parent="#accordionExampleOS">
                  <div class="accordion-body">
                    <div class="row w-full">
                      <div class="col-md-3">
                        <span class="span-placeholder">Calle</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          v-model="calle" name="name"/>
                      </div>
                      <div class="col-md-3">
                        <span class="span-placeholder">Número exterior</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          v-model="numeroExterior" name="name"/>
                      </div>
                      <div class="col-md-3">
                        <span class="span-placeholder">Número interior</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          v-model="numeroInterior" name="name"/>
                      </div>
                      <div class="col-md-3">
                        <span class="span-placeholder">Código postal</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          v-on:blur="getColoniaMunicipio(1)"
                          v-model="codigoPostal" 
                          name="name" 
                          v-on:keypress="isNumber($event)"/>
                      </div>
                    </div>
                    <div class="row w-full">
                      <div class="col-md-4">
                        <span class="span-placeholder">Alcaldia/Municipio</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          v-model="municipio" disabled
                          name="name"/>
                      </div>
                      <div class="col-md-4">
                        <span class="span-placeholder" v-if="LabelColoniaObligado">Colonia *</span>
                        <span class="span-placeholder" v-if="LabelColoniaNoObligado">Colonia </span>
                          <select 
                            v-bind:disabled="isObligadoEdit" 
                            v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                            v-model="colonia" name="colonia" class="vs-input">
                            <option value="" selected>Selecciona una colonia</option>
                            <option :value='colonia' v-for="colonia in ColoniasCP" :key="">{{colonia}}</option>
                          </select>
                          <span class="text-danger span-placeholder" v-if="LabelColoniaObligadoText">Campo requerido</span>
                      </div>
                      <div class="col-md-4">
                        <span class="span-placeholder">Ciudad</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          label="Ciudad" 
                          v-model="ciudad" name="name"/>
                      </div>
                    </div>        
                    <div class="row w-full">
                      <div class="col-md-4">
                        <span class="span-placeholder" for="formasEnganche">Estado</span>
                        <select 
                          v-bind:disabled="isObligadoEdit" 
                          name="cars" 
                          id="formasEnganche" 
                          v-model="estado" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                        >
                          <option :value="''">Seleccione...</option>
                          <option :value='tipo.Id' v-for="tipo in estados"  >{{tipo.Nombre}}</option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <span class="span-placeholder" for="formasEnganche">Años en domicilio</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          v-model="tiempoOcupandoDomicilioActual" 
                          name="name" 
                          v-on:keypress="isNumber($event)" />
                      </div>
                      <div class="col-md-4">
                        <span class="span-placeholder">Meses en domicilio</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          v-model="mesesOcupandoDomicilioActual" 
                          name="name" 
                          v-on:keypress="isNumber($event)"/>
                      </div>
                    </div>                  
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwoOS">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoOS" aria-expanded="false" aria-controls="collapseTwoOS">
                    Ingresos
                  </button>
                </h2>
                <div id="collapseTwoOS" class="accordion-collapse collapse" aria-labelledby="headingTwoOS" data-bs-parent="#accordionExampleOS">
                  <div class="accordion-body">
                    <div class="row w-full">
                      <div class="col-md-4">
                        <span class="span-placeholder">Nombre de la empresa</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          label="Nombre de la empresa" 
                          v-model="ingreso.empresa" 
                          maxlength="30"  
                          name="name" />
                      </div>
                      <div class="col-md-4">
                        <span class="span-placeholder" for="formasEnganche">Giro</span>
                        <select 
                          v-bind:disabled="isObligadoEdit" 
                          name="cars" id="formasEnganche" 
                          v-model="ingreso.giro" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                        >
                          <option :value="''">Seleccione...</option>
                          <option :value='tipo.Id' v-for="tipo in giros" >{{tipo.Nombre}}</option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <span class="span-placeholder" for="formasEnganche">Sector laboral</span>
                        <select 
                          v-bind:disabled="isObligadoEdit" 
                          name="cars" 
                          id="formasEnganche" 
                          v-model="ingreso.sector" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                        >
                          <option :value="''">Seleccione...</option>
                          <option :value='tipo.Id' v-for="tipo in sectores" >{{tipo.Nombre}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row w-full">
                    <div class="col-md-4">
                        <span class="span-placeholder" for="formasEnganche">Ocupación</span>
                        <select 
                          v-bind:disabled="isObligadoEdit" 
                          name="cars" 
                          id="tiempoEmpleo" 
                          v-model="ingreso.tipoEmpleo" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                        >
                          <option :value="''">Seleccione...</option>
                          <option :value='tipo.Id' v-for="tipo in tiposDeEmpleo"  :key="">{{tipo.Nombre}}</option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <span class="span-placeholder" for="formasEnganche">Puesto</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          label="Puesto" 
                          v-model="ingreso.puesto" maxlength="30"  name="name" />
                      </div>
                      <div class="col-md-4">
                        <span class="span-placeholder" for="formasEnganche">Cargo</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          label="Cargo" 
                          v-model="ingreso.cargo" maxlength="30"  name="name" />
                      </div>
                    </div>
                    <div class="row w-full">
                      <div class="col-md-3">
                        <span class="span-placeholder" for="formasEnganche">Antigüedad años</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          label="Antigüedad años" 
                          v-model="ingreso.antiguedadYears" 
                          name="name" v-on:keypress="isNumber($event)"/>
                      </div>
                      <div class="col-md-3">
                        <span class="span-placeholder" for="formasEnganche">Antigüedad meses</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          label="Antigüedad meses" 
                          v-model="ingreso.antiguedadMeses" 
                          name="name" v-on:keypress="isNumber($event)"/>
                      </div>
                      <div class="col-md-3">
                        <span class="span-placeholder" for="formasEnganche">Domicilio calle / avenida</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          label="Domicilio calle / avenida" 
                          v-model="ingreso.domicilio" maxlength="30"  name="name" />
                      </div>
                      <div class="col-md-3">
                        <span class="span-placeholder" for="formasEnganche">No Exterior</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          label="No Exterior" 
                          v-model="ingreso.numeroExterior" name="name" v-on:keypress="isNumber($event)"/>
                      </div>
                    </div>
                    <div class="row w-full">
                      <div class="col-md-2">
                        <span class="span-placeholder" for="formasEnganche">No Interior</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          label="No Interior" 
                          v-model="ingreso.numeroInterior" name="name" v-on:keypress="isNumber($event)"/>
                      </div>
                      <div class="col-md-2">
                        <span class="span-placeholder" for="formasEnganche">C.P.</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          label="C.P." 
                          v-model="ingreso.codigoPostal" 
                          name="name" v-on:keypress="isNumber($event)" @blur="getColoniaMunicipioIngresos(1)"/>
                      </div>
                      <div class="col-md-4">
                        <span class="span-placeholder" for="formasEnganche">Municipio</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          label="Municipio" 
                          v-model="ingreso.municipio" name="name" disabled/>
                      </div>
                      <div class="col-md-4">
                        <span class="span-placeholder" for="formasEnganche">Colonia*</span>
                          <select 
                            v-bind:disabled="isObligadoEdit" 
                            v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                            label="Colonia" 
                            v-model="ingreso.colonia" name="colonia" class="vs-input">
                            <option value="" selected>Selecciona una colonia</option>
                            <option :value='colonia' v-for="colonia in ColoniasCPIngresos" :key="">{{colonia}}</option>
                          </select>
                      </div>
                    </div>  
                    <div class="row w-full">
                      <div class="col-md-4">
                        <span class="span-placeholder" for="formasEnganche">Estado</span>
                        <select 
                          v-bind:disabled="isObligadoEdit" 
                          name="cars" 
                          id="formasEnganche" 
                          v-model="ingreso.estado" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                        >
                          <option :value="''">Seleccione...</option>
                          <option :value='tipo.Id' v-for="tipo in estados"  :key="">{{tipo.Nombre}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThreeOS">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreeOS" aria-expanded="false" aria-controls="collapseThreeOS">
                    Generales
                  </button>
                </h2>
                <div id="collapseThreeOS" class="accordion-collapse collapse" aria-labelledby="headingThreeOS" data-bs-parent="#accordionExampleOS">
                  <div class="accordion-body">
                    <div class="row w-full">
                      <div class="col-md-4">
                        <span class="span-placeholder">Cantidad de dependientes económicos</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          v-model="dependientesEconomicos" 
                          name="name" 
                          type="number"/>
                      </div>
                      <div class="col-md-4">
                        <span class="span-placeholder">Número de inmuebles propios</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          v-model="inmueblesPropios" 
                          name="name" 
                          type="number"/>
                      </div>
                      <div class="col-md-4">
                        <span class="span-placeholder">Valor total bienes inmuebles propios</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          v-model="valorInmueblesPropios" 
                          name="name" 
                          v-on:keypress="isNumber($event)"/>
                      </div>
                    </div>
                    <div class="row w-full">
                      <div class="col-md-4">
                        <span class="span-placeholder">Valor total gravado de bienes inmuebles</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          v-model="valorGravado" 
                          name="name" v-on:keypress="isNumber($event)"/>
                      </div>
                      <div class="col-md-4">
                        <ul class="centerx" style="margin-top:10%">
                          <li>
                            <vs-checkbox v-bind:disabled="isObligadoEdit" v-model="autoPropio">Cuenta con auto propio</vs-checkbox>
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-4" v-if="autoPropio">
                        <span class="span-placeholder">Número de autos propios</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          v-model="numeroAutospropios" 
                          name="name"/>
                      </div>
                      <div class="col-md-4" v-if="autoPropio">
                        <span class="span-placeholder">Valor total de los autos propios</span>
                        <input 
                          v-bind:disabled="isObligadoEdit" 
                          v-bind:class="[ isObligadoEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                          v-model="valorAutosPropios" 
                          name="name" v-on:keypress="isNumber($event)"/>
                      </div>
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>          
          <div class="row w-full">
            <div class="col-md-4">
              <!-- <button class="btn btn-second" @click="registraSolicitante()" :disabled="!validateForm">Guardar Obligado solidario</button> -->
            </div>
          </div>
        </vx-card>
        <vx-card title="" class="">
          <vs-table :data="obligados" max-items="5"
            pagination
            noDataText="No hay datos para mostrar">
            <template slot="thead">
              <vs-th>
                Correo electrónico
              </vs-th>
              <vs-th>
                Nombre(s)
              </vs-th>
              <vs-th>
                Apellido Paterno
              </vs-th>
              <vs-th>
                Apellido Materno
              </vs-th>
              <vs-th>
                Fecha de nacimiento
              </vs-th>
              <vs-th>
                Teléfono celular
              </vs-th>
              <vs-th>
                Acciones
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].EMail" >
                  {{data[indextr].EMail}}
                </vs-td>

                <vs-td :data="data[indextr].Nombre">
                  {{data[indextr].Nombre}}
                </vs-td>

                <vs-td :data="data[indextr].ApellidoPaterno">
                  {{data[indextr].ApellidoPaterno}}
                </vs-td>

                <vs-td :data="data[indextr].ApellidoMaterno">
                  {{data[indextr].ApellidoMaterno}}
                </vs-td>
                <vs-td :data="data[indextr].FechaNacimiento">
                  {{data[indextr].FechaNacimiento}}
                </vs-td>
                <vs-td :data="data[indextr].Telefono">
                  {{data[indextr].Telefono}}
                </vs-td>
                <vs-td>
                  <vs-row vs-w="12" vs-justify="space-between">
                    <!-- <vx-tooltip text="Editar Obligado Solidario" position="top">
                      <vs-button color="primary" @click="editar(data[indextr])"><feather-icon icon="EditIcon" svgClasses="w-4 h-4"/></vs-button>
                    </vx-tooltip> -->
                  <vx-tooltip text="Borrar ingreso" position="top">
                      <button v-bind:disabled="isObligadoEdit" class="favoritos" @click="eliminar(data[indextr])">
                          <svg class="trash">
                                <g id="trash">
                                  <path id="Vector" d="M18,.5C14.67.17,11.32,0,7.98,0A59.068,59.068,0,0,0,2.04.3L0,.5" transform="translate(3 5.48)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  <path id="Vector-2" data-name="Vector" d="M0,2.97.22,1.66C.38.71.5,0,2.19,0H4.81C6.5,0,6.63.75,6.78,1.67L7,2.97" transform="translate(8.5 2)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                                  <path id="Vector-3" data-name="Vector" d="M13.7,0l-.65,10.07c-.11,1.57-.2,2.79-2.99,2.79H3.64C.85,12.86.76,11.64.65,10.07L0,0" transform="translate(5.15 9.14)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  <path id="Vector-4" data-name="Vector" d="M0,0H3.33" transform="translate(10.33 16.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                                  <path id="Vector-5" data-name="Vector" d="M0,0H5" transform="translate(9.5 12.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                                  <path id="Vector-6" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0"/>
                                </g>
                              </svg>
                      </button>
                    </vx-tooltip>

                  </vs-row>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { nacionalidades } from '@/assets/jsonFiles/nacionalidades.js'
import { paisesNacimiento } from '@/assets/jsonFiles/paisesNacimiento.js'
export default {
  components: {
    'v-select': vSelect,
  },
  data(){
		return{
      isObligadoEdit: true,

      correo:'',
      nombre:'',
      apellidoPaterno:'',
      apellidoMaterno:'',
      rfc:'',
      curp:'',
      sexo:'',
      fechaNacimiento:'',
      telefono:'',
      escolaridad:'',
      escolaridades:[],
      dependientesEconomicos:'',
      inmueblesPropios:'',
      valorInmueblesPropios:'',
      valorGravado:'',
      calle:'',
      numeroExterior:'',
      numeroInterior:'',
      colonia:'',
      ciudad:'',
      ColoniasCP: [],
      ColoniasCPIngresos: [],
			MunicipiosCP: [],
      estado:'',
      estados:[],
      codigoPostal:'',
      paisNacimiento:'',
      nacionalidad:'',
      nacionalidades: [],
      paisesNac: [],
      estadoCivil:'',
      estadosCiviles:[],
      regimenMatrimonial:'',
      regimenes:[],
      autoPropio:false,
      valorAutosPropios:'',
      numeroAutospropios:'',
      obligados:[],
      id:'',
      key:'',
      nombreConyuge:'',
      apellidoPaternoConyuge:'',
      apellidoMaternoConyuge:'',
      rfcConyuge:'',
      curpConyuge:'',
      fechaNacimientoConyuge:'',
      edadConyuge:'',
      nacionalidadConyuge:'',
      lugarNacimientoConyuge:'',
      sexoConyuge:'',
      domicilioConyuge:'',
      coloniaConyuge:'',
      municipioConyuge:'',
      codigoPostalConyuge:'',
      solicitante:'',
      conyugeIsCoacred:'TRUE',
      parentescos:[],
      entenado:{Familiar: false, Id: '16',Nombre:'ENTENADO'},
      identificaciones:[],
      lugarNacimiento:'',//municipioNacimiento en la api
      parentesco:'',//ParentescoSolicitante
      telefonoFijo:'',//telefonoCasa
      identificacion:'',//identificacion
      numeroIdentificacion:'',//NumeroIdentificacion
      licencia:{Id:7,Nombre:'LICENCIA'},
      municipio:'',//municipio
      tiempoOcupandoDomicilioActual:'',//AniosDomicilio
      mesesOcupandoDomicilioActual:'',//MesesDomicilio
      giros:[],
      anterior:[],
      sectores:[],
      tiposDeEmpleo:[],
      obligado:'',
      ingresos:[],
      existeObligado:false,
      ingreso:{
        ingresoId:'',
        tipoEmpleo:'',//tipoEmpleo
        empresa:'',//empresa
        puesto:'',//puesto
        giro:'',//giro

        domicilio:'',//domicilio
        numeroExterior:'',//numeroExterior
        numeroInterior:'',//numeroInterior
        colonia:'',//colonia
        municipio:'',//municipio
        codigoPostal:'',//codigoPostal
        sector:'',//sectorRedHipotecaria
        estado:'',//estado
        cargo:'',//Cargo
        antiguedadYears:'',//AniosAntiguedad
        antiguedadMeses:'',//MesesAntiguedad
        LabelColoniaObligado: false,
        LabelColoniaNoObligado: true,
        LabelColoniaObligadoText: true
      }
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getNacionalidades();
    this.getPaisesNacimiento();
    this.getApikey()
    this.getEstados()
    this.getListaRegimenes()
    this.getEscolaridades()
    this.getEstadosCiviles()
    this.getListaParentescos()
    this.getIdentificaciones()
    this.getGiros()
    this.getSectoresRed()
    this.getListaTipoEmpleos()
    this.dameCaso()
  },
  watch: {
		// Formato mayusculas sin acento
		nombre: function(newValue, oldValue) {
			this.nombre = this.textFormat(newValue);
		},
		apellidoPaterno: function(newValue, oldValue) {
			this.apellidoPaterno = this.textFormat(newValue);
		},
		apellidoMaterno: function(newValue, oldValue) {
			this.apellidoMaterno = this.textFormat(newValue);
		},
		'nombreConyuge': function(newValue, oldValue) {
			this.nombreConyuge = this.textFormat(newValue);
		},
		'apellidoPaternoConyuge': function(newValue, oldValue) {
			this.apellidoPaternoConyuge = this.textFormat(newValue);
		},
		'apellidoMaternoConyuge': function(newValue, oldValue) {
			this.apellidoMaternoConyuge = this.textFormat(newValue);
		},
    valorAutosPropios: function(newValue) {
      this.valorAutosPropios= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    valorInmueblesPropios: function(newValue) {
      this.valorInmueblesPropios= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    valorGravado: function(newValue) {
      this.valorGravado= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  },
  computed:{
    validateForm(){
      
      if (this.codigoPostal == '') {
        this.LabelColoniaObligado = false
        this.LabelColoniaNoObligado = true
        this.LabelColoniaObligadoText = false
        return this.correo != '' && this.nombre != '' && this.apellidoPaterno != '' && this.telefono != '' && this.fechaNacimiento != ''  
      }else{
        this.LabelColoniaObligado = true
        this.LabelColoniaNoObligado = false
        if (this.colonia) {
          this.LabelColoniaObligadoText = false
        }
        else
          this.LabelColoniaObligadoText = true

        return this.correo != '' && this.nombre != '' && this.apellidoPaterno != '' && this.telefono != '' && this.fechaNacimiento != '' && this.colonia != ''
      }
        
    }
  },
  methods:{
		// Conversion
		textFormat(strValue) {
			return strValue.normalize('NFD')
							.replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
							.normalize()
							.toUpperCase(); // Eliminar acentos menos Ññ
		},
    getPaisesNacimiento() {
      this.paisesNac = paisesNacimiento;
    },
		getNacionalidades() {
			let nacionalidadesList = [];
			let index = 3;
			for (const nacionalidad of nacionalidades) {
				if (nacionalidad.Nombre === "MEXICANO (A)") {
					nacionalidadesList[0] = nacionalidad;
					continue;
				}
				if (nacionalidad.Nombre === "NORTEAMERICANO (A)") {
					nacionalidadesList[1] = nacionalidad;
					continue;
				}
				if (nacionalidad.Nombre === "CANADIENSE") {
					nacionalidadesList[2] = nacionalidad;
					continue;
				}
				nacionalidadesList[index] = nacionalidad;
				index ++;
			}
			this.nacionalidades = nacionalidadesList;
		},
    toggleSave() {
			this.isObligadoEdit = !this.isObligadoEdit;
		},

    eliminar(item){
      var eliminarCuentas = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'BorrarObligadoSolidario',
        objCaso:{
            Id:this.id,
            EMail:item.EMail,
          }
      }
      this.$axios.post('/',eliminarCuentas,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
             this.clearForm()
            this.existeObligado=false
            this.$vs.notify({
              title:`Se eliminó exitosamente`,
              position:'top-right'
            })
            this.dameCaso()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en BorrarObligadoSolidario',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraObligado(){
      let self= this
      var objRequestRegistraObligadoSolidario = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'RegistraObligadoSolidario',
        objCaso: {
          Id : this.id,
          EMail: this.correo
        }
      }
      this.$axios.post('/',objRequestRegistraObligadoSolidario,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.clearForm()
            this.dameCaso()
            this.existeObligado=true
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.$vs.loading.close('#container > .con-vs-loading')
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraObligadoSolidario',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }

      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraSolicitante(){
      this.$vs.loading({
        container: '#container',
        scale: 0.6
      })
      let self= this
      let objRequestRegistraSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'RegistraSolicitante',
        objSolicitante: {
          //generales
          EMail: this.correo,
          Nombres: this.nombre,
          ApellidoPaterno: this.apellidoPaterno,
          ApellidoMaterno: this.apellidoMaterno,
          TelefonoCelular: this.telefono,
          TelefonoCasa: this.telefonoFijo,//telefonoCasa
          FechaNacimiento: this.fechaNacimiento,
          Rfc: this.rfc,
          Curp: this.curp,
          Sexo:this.sexo,
          //faltan por validar nombres
          DependientesEconomicosNum:parseInt(this.dependientesEconomicos),
          Escolaridad:this.escolaridad,
          InmueblesPropiosGravado:parseFloat(this.valorGravado.replace(/,/g,"")),
          InmueblesPropiosNumero: this.inmueblesPropios,
          InmueblesPropiosValor:parseFloat(this.valorInmueblesPropios.replace(/,/g,"")),
          //domicilio
          Calle:this.calle,
          NumeroExterior:this.numeroExterior,
          NumeroInterior:this.numeroInterior,
          Colonia:this.colonia,
          Ciudad:this.ciudad,//si
          Municipio: this.municipio,//municipio
          Estado:this.estado,
          CodigoPostal:this.codigoPostal,
          AniosDomicilio: this.tiempoOcupandoDomicilioActual,//AniosDomicilio
          MesesDomicilio: this.mesesOcupandoDomicilioActual,//MesesDomicilio
          //generales
          // Nacionalidad:this.nacionalidad,
          Nacionalidad: {
							name: this.nacionalidad,
							noSanitiza: true,
					},
          // PaisNacimiento:this.paisNacimiento,
          PaisNacimiento: {
							name: this.paisNacimiento,
							noSanitiza: true,
					},

          MunicipioNacimiento: this.lugarNacimiento,//municipioNacimiento en la api
          EstadoCivil: this.estadoCivil,
          RegimenMatrimonial: this.regimenMatrimonial,
          Identificacion: this.identificacion,//identificacion
          NumeroIdentificacion: this.numeroIdentificacion,//NumeroIdentificacion
          AutosPropios:this.autoPropio,
          AutosPropiosNumero: this.numeroAutospropios,
          AutosPropiosValor:parseFloat(this.valorAutosPropios.replace(/,/g,"")),
          ParentescoSolicitante:this.parentesco,//ParentescoSolicitante
          //obligado conyuge
          NombreConyugeObligado:this.nombreConyuge,
          ApellidoPaternoConyugeObligado:this.apellidoPaternoConyuge,
          ApellidoMaternoConyugeObligado:this.apellidoMaternoConyuge,
          RFCConyugeObligado:this.rfcConyuge,
          CURPConyugeObligado:this.curpConyuge,
          FechaNacimientoConyugeObligado:this.fechaNacimientoConyuge,
          EdadConyugeObligado:this.edadConyuge,
          // NacionalidadConyugeObligado:this.nacionalidadConyuge,
          NacionalidadConyugeObligado: {
							name: this.nacionalidadConyuge,
							noSanitiza: true,
					},
          LugarNacimientoConyugeObligado:this.lugarNacimientoConyuge,
          SexoConyugeObligado:this.sexoConyuge,
          DomicilioConyugeObligado:this.domicilioConyuge,
          ColoniaConyugeObligado:this.coloniaConyuge,
          MunicipioConyugeObligado:this.municipioConyuge,
          CodigoPostalConyugeObligado:this.codigoPostalConyuge,
          Ingresos:[
              {
                  TipoEmpleo: this.ingreso.tipoEmpleo,//tipoEmpleo
                  Empresa: this.ingreso.empresa,//empresa
                  Puesto: this.ingreso.puesto,//puesto
                  Giro: this.ingreso.giro,//giro
                  Domicilio: this.ingreso.domicilio,//domicilio
                  NumeroExterior: this.ingreso.numeroExterior,//numeroExterior
                  NumeroInterior: this.ingreso.numeroInterior,//numeroInterior
                  Colonia: this.ingreso.colonia,//colonia
                  Municipio: this.ingreso.municipio,//municipio
                  CodigoPostal: this.ingreso.codigoPostal,//codigoPostal
                  Sector: this.ingreso.sector,//sectorRedHipotecaria
                  Estado: this.ingreso.estado,//estado
                  Cargo: this.ingreso.cargo,//Cargo
                  AniosAntiguedad: this.ingreso.antiguedadYears,//AniosAntiguedad
                  MesesAntiguedad: this.ingreso.antiguedadMeses,//MesesAntiguedad
              }
            ],

        }
      }
      this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.registraObligado()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }

      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.obligados=response.data.objContenido.ObligadosSolidarios
            //this.solicitante=response.data.objContenido.Solicitante
             if(this.obligados.length >0){
              this.obligado=this.obligados[0].EMail
              this.dameSolicitante()
              this.existeObligado=true
            }
            this.solicitanteCaso=response.data.objContenido.Solicitante
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self=this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.obligado
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            
            this.solicitante=response.data.objContenido
            this.correo=this.solicitante.EMail
            this.nombre=this.solicitante.Nombres
            this.apellidoPaterno= this.solicitante.ApellidoPaterno
            this.apellidoMaterno=this.solicitante.ApellidoMaterno
            this.telefono=this.solicitante.TelefonoCelular
            this.fechaNacimiento=this.solicitante.FechaNacimiento
            this.curp=this.solicitante.Curp
            this.sexo=this.solicitante.Sexo
            this.paisNacimiento=this.solicitante.PaisNacimiento
            this.rfc=this.solicitante.Rfc
            this.nacionalidad=this.solicitante.Nacionalidad
            this.escolaridad=this.solicitante.Escolaridad
            this.telefonoFijo=this.solicitante.TelefonoCasa
            this.identificacion=this.solicitante.Identificacion
            this.numeroIdentificacion=this.solicitante.NumeroIdentificacion
           this.estadoCivil=this.solicitante.EstadoCivil
           this.regimenMatrimonial=this.solicitante.RegimenMatrimonial
           this.nombreConyuge=this.solicitante.NombreConyugeObligado
           this.apellidoPaternoConyuge=this.solicitante.ApellidoPaternoConyugeObligado
           this.apellidoMaternoConyuge=this.solicitante.ApellidoMaternoConyugeObligado
           this.curpConyuge=this.solicitante.CURPConyugeObligado
           this.rfcConyuge=this.solicitante.RFCConyugeObligado
           this.fechaNacimientoConyuge=this.solicitante.FechaNacimientoConyugeObligado
           this.edadConyuge=this.solicitante.EdadConyugeObligado
           this.nacionalidadConyuge=this.solicitante.NacionalidadConyugeObligado
           this.sexoConyuge=this.solicitante.SexoConyugeObligado
           this.domicilioConyuge=this.solicitante.DomicilioConyugeObligado
           this.coloniaConyuge=this.solicitante.ColoniaConyugeObligado
           this.municipioConyuge=this.solicitante.MunicipioConyugeObligado
           this.codigoPostalConyuge=this.solicitante.CodigoPostalConyugeObligado
           this.calle=this.solicitante.Calle
           this.numeroExterior=this.solicitante.NumeroExterior
           this.numeroInterior=this.solicitante.NumeroInterior
           this.colonia=this.solicitante.Colonia
           this.ciudad=this.solicitante.Ciudad
           this.estado=this.solicitante.Estado
           this.codigoPostal=this.solicitante.CodigoPostal
           this.municipio=this.solicitante.Municipio
            this.tiempoOcupandoDomicilioActual=this.solicitante.AniosDomicilio
            this.mesesOcupandoDomicilioActual=this.solicitante.MesesDomicilio
            this.parentesco=this.solicitante.ParentescoSolicitante
            this.lugarNacimiento=this.solicitante.MunicipioNacimiento
            this.ingresos=response.data.objContenido.Ingresos
            this.ingreso.empresa=this.ingresos[0].Empresa
            this.ingreso.giro=this.ingresos[0].GiroId
            this.ingreso.sector=this.ingresos[0].SectorRedHipotecariaId
            this.ingreso.tipoEmpleo=this.ingresos[0].TipoEmpleoId
            this.ingreso.puesto=this.ingresos[0].Puesto
            this.ingreso.cargo=this.ingresos[0].Cargo
            this.ingreso.antiguedadYears=this.ingresos[0].AniosAntiguedad
            this.ingreso.antiguedadMeses=this.ingresos[0].MesesAntiguedad
            this.ingreso.domicilio=this.ingresos[0].Domicilio
            this.ingreso.colonia=this.ingresos[0].Colonia
            this.ingreso.numeroExterior=this.ingresos[0].NumeroExterior
            this.ingreso.numeroInterior=this.ingresos[0].NumeroInterior
            this.ingreso.municipio=this.ingresos[0].Municipio
            this.ingreso.estado=this.ingresos[0].EstadoId
            this.ingreso.codigoPostal=this.ingresos[0].CodigoPostal
            this.dependientesEconomicos=this.solicitante.DependientesEconomicosNum
            this.inmueblesPropios=this.solicitante.InmueblesPropiosNumero
            if(this.solicitante.InmueblesPropiosValor){
              this.valorInmueblesPropios=(this.solicitante.InmueblesPropiosValor).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if(this.solicitante.InmueblesPropiosGravado){
             this.valorGravado=(this.solicitante.InmueblesPropiosGravado).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            this.autoPropio=this.solicitante.AutosPropios
            this.numeroAutospropios=this.solicitante.AutosPropiosNumero

            if(this.solicitante.AutosPropiosValor){
              this.valorAutosPropios=(this.solicitante.AutosPropiosValor).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }

            if (this.solicitante.CodigoPostal != '') {
              this.getColoniaMunicipio(0)
            }
            if (this.ingresos[0].CodigoPostal) {
              this.getColoniaMunicipioIngresos(0)
            }

            /*
            this.ingresos=response.data.objContenido.Ingresos
            this.anterior.funciones=this.solicitante.DescripcionFuncionesLaborales
            this.anterior.empresaAnterior=this.solicitante.NombreEmpleoAnterior
            this.anterior.inicioAnterior=this.solicitante.FechaIngresoEmpleoAnterior
            this.anterior.salidaAnterior=this.solicitante.FechaSalidaEmpleoAnterior
            this.anterior.separacion=this.solicitante.CausaSeparacionEmpleoAnterior
            this.anterior.jefe=this.solicitante.JefeEmpleoAnterior
            this.anterior.telefono=this.solicitante.TelefonoEmpleoAnterior
            this.anterior.contrato=this.solicitante.TipoContratoEmpleoAnterior
            this.anterior.tipo=this.solicitante.TipoEmpleoAnterior
            this.anterior.sector=this.solicitante.SectorRedHipotecariaEmpleoAnterior*/
            this.dameConyuge()
            //console.log(response.data.objContenido)
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getEstados(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaEstados',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estados=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstados',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaRegimenes(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaRegimenesMatrimoniales',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.regimenes=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaRegimenesMatrimoniales',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getEscolaridades(){
      let self= this
      var objRequestListaEscolaridades = {
        strApiKey: this.key,
        strMetodo: 'ListaEscolaridades',
      }
      this.$axios.post('/',objRequestListaEscolaridades,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.escolaridades=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEscolaridades',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getEstadosCiviles(){
      let self= this
      var objRequestListaEstadosCiviles = {
        strApiKey: this.key,
        strMetodo: 'ListaEstadosCiviles',
      }
      this.$axios.post('/',objRequestListaEstadosCiviles,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estadosCiviles=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstadosCiviles',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    clearForm(){
      this.correo=''
      this.nombre=''
            this.apellidoPaterno= ''
            this.apellidoMaterno=''
            this.telefono=''
            this.fechaNacimiento=''
            this.curp=''
            this.sexo=''
            this.paisNacimiento=''
            this.rfc=''
            this.nacionalidad=''
            this.escolaridad=''
            this.telefonoFijo=''
            this.identificacion=''
            this.numeroIdentificacion=''
           this.estadoCivil=''
           this.regimenMatrimonial=''
           this.nombreConyuge=''
           this.apellidoPaternoConyuge=''
           this.apellidoMaternoConyuge=''
           this.curpConyuge=''
           this.rfcConyuge=''
           this.fechaNacimientoConyuge=''
           this.edadConyuge=''
           this.nacionalidadConyuge=''
           this.sexoConyuge=''
           this.domicilioConyuge=''
           this.coloniaConyuge=''
           this.municipioConyuge=''
           this.codigoPostalConyuge=''
           this.calle=''
           this.numeroExterior=''
           this.numeroInterior=''
           this.colonia=''
           this.ciudad=''
           this.estado=''
           this.codigoPostal=''
           this.municipio=''
            this.tiempoOcupandoDomicilioActual=''
            this.mesesOcupandoDomicilioActual=''
            this.parentesco=''
            this.lugarNacimiento=''
            this.ingresos=[]
            this.ingreso.empresa=''
            this.ingreso.giro=''
            this.ingreso.sector=''
            this.ingreso.tipoEmpleo=''
            this.ingreso.puesto=''
            this.ingreso.cargo=''
            this.ingreso.antiguedadYears=''
            this.ingreso.antiguedadMeses=''
            this.ingreso.domicilio=''
            this.ingreso.colonia=''
            this.ingreso.numeroExterior=''
            this.ingreso.numeroInterior=''
            this.ingreso.municipio=''
            this.ingreso.estado=''
            this.ingreso.codigoPostal=''
            this.dependientesEconomicos=''
            this.inmueblesPropios=''
            this.valorInmueblesPropios=''
            this.valorGravado=''
            this.autoPropio=''
            this.numeroAutospropios=''
            this.valorAutosPropios=''
      this.conyugeIsCoacred='FALSE'
    },
     getListaParentescos(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaParentescos',
        ObjParentesco:{
          User:'Obligado'
        }
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            
            //this.parentescos=[{Familiar: false, Id: '16',Nombre:'Entenado'}]
            this.parentescos=response.data.objContenido
            //this.parentescos.push(this.entenado)
            //console.log(this.parentescos)
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaParentescos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getSectoresRed(){
      let self=this
      var objRequestListaSectorRedHipotecaria = {
        strApiKey: this.key,
        strMetodo: 'ListaSectorRedHipotecaria',
      }
      this.$axios.post('/',objRequestListaSectorRedHipotecaria,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            //this.sectores=response.data.objContenido
            var auxSectores =[1,2,3,4]
            var auxSectorExiste = response.data.objContenido
            for(var j=0;j<auxSectorExiste.length;j++){
              if(auxSectores.includes(auxSectorExiste[j]['Id'])){
                //console.log(auxIdentiExiste)
                this.sectores.push(auxSectorExiste[j])
              }
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaSectorRedHipotecaria',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaTipoEmpleos(){
      let self=this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaTipoEmpleos',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.tiposDeEmpleo=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoEmpleos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getIdentificaciones(){
      let self=this
      var objRequestListaTipoIdentificaciones = {
        strApiKey: this.key,
        strMetodo: 'ListaTipoIdentificaciones',
        ObjParentesco:{
          User:'Obligado'
        }

      }
      this.$axios.post('/',objRequestListaTipoIdentificaciones,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            // 2023-05-04 Mejora en indentificacion
            let identificacionList = response.data.objContenido;
            identificacionList = identificacionList.filter(identi => identi.Id != 7);
            this.identificaciones = identificacionList;

            // console.log( 'INDEN', identificacionList );

            // var auxIdentificaciones =[1,3,5,7]
            // var auxIdentiExiste = response.data.objContenido

            // console.log( 'IDENTIFICADORES', auxIdentiExiste );

            // for(var i=0;i<auxIdentiExiste.length;i++){
            //   if(auxIdentificaciones.includes(auxIdentiExiste[i]['Id'])){
            //     //console.log(auxIdentiExiste)
            //     this.identificaciones.push(auxIdentiExiste[i])
            //   }
            // }
            //this.identificaciones.push(this.licencia)
            //this.identificaciones=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoIdentificaciones',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameConyuge(){
       let self= this
      var objRequestDameConyuge = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameConyuge',
        objSolicitante:
        { EMailSolicitante:this.solicitanteCaso,  
          EMail:this.correo
        }
      }
      this.$axios.post('/',objRequestDameConyuge,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.conyugeIsCoacred=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameConyuge',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getGiros(){
      let self=this
      var objRequestListaGirosIngreso = {
        strApiKey: this.key,
        strMetodo: 'ListaGirosIngreso',
      }
      this.$axios.post('/',objRequestListaGirosIngreso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.giros=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaGirosIngreso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    async getColoniaMunicipio(value){
      var origen = 0;

      if (value == 1) { // si viene de perder el foco -  codigo postal
				this.colonia = '';	
        origen = 1;
			}

        const getToken = await this.getTokenSisec();
        var token = getToken.objRespuesta.token
        const dataInfoCP = await this.getInformationCP(token)

        if ( !dataInfoCP ) {
          this.colonia = ''
          this.municipio = ''
          return
        }
        
        const colonias = dataInfoCP.map(x => x.colonia)
        this.ColoniasCP = colonias
        this.municipio = dataInfoCP[0].municipio
      
        if (origen == 0) {
				if(colonias.indexOf(this.colonia) == -1){  // no se encontro
					this.colonia = '';
				}
			}

		
		},
		async getTokenSisec() {
			const URL_Method = 'https://cotizador.socasesores.com/consultaSisec/getTokenSisec.php'

			const { data, status } = await this.$axios
						.post(URL_Method,{headers : {'Content-Type':'application/json; charset=UTF-8'}})

			if ( status !== 200 ) {

				this.$vs.notify({
				title: 'Ocurrio un error en getTokenSisec',
				text: data.strError,
				color: 'danger',
				position: 'top-right',
				})

				return false

			}

			if ( data.intRespuesta ) {

				this.$vs.notify({
				title: 'Ocurrio un error en Metodo getTokenSisec',
				text: data.strError,
				color: 'danger',
				position: 'top-right',
				})

				return false

			}

			if ( !data.objRespuesta.status ) {

				this.$vs.notify({
				title: 'Ocurrio un error en API getTokenSisec',
				text: data.strError,
				color: 'danger',
				position: 'top-right',
				})

				return false

			}

			//console.log(data)
			return data;

		},
		async getInformationCP(token) {

				const jsonCP = {
									token,
									CodigoPostal: this.codigoPostal
								}

				const URL_Method = 'https://cotizador.socasesores.com/consultaSisec/getInfoCP.php'

				const { status, data } = await this.$axios
						.post(URL_Method,jsonCP,{headers : {'Content-Type':'application/json; charset=UTF-8'}})

				if ( status !== 200 ) {

					this.$vs.notify({
						title: 'Ocurrio un error en getInformationCP',
						text: data.strError,
						color: 'danger',
						position: 'top-right',
					})

					return false

				}

				if ( data.intRespuesta ) {

					this.$vs.notify({
						title: 'Ocurrio un error en Metodo getInformationCP',
						text: data.strError,
						color: 'danger',
						position: 'top-right',
					})

					return false

				}

				if ( data.objRespuesta.message ) {

          this.$vs.notify({
          title: data.objRespuesta.message,
          text: data.strError,
          color: 'danger',
          position: 'top-right',
          })

          return false

        }

        if ( !data.objRespuesta.length ) {
          return false
        }

				return data.objRespuesta

		},
    async getColoniaMunicipioIngresos(value){
			if (value == 1) {
				this.ingreso.colonia = '';	
			}
			
			const getToken = await this.getTokenSisec();
			var token = getToken.objRespuesta.token
			const dataInfoCPIngresos = await this.getInformationCPIngresos(token)

			if ( !dataInfoCPIngresos ) {
				this.ingreso.colonia = ''
				this.ingreso.municipio = ''
				return
			}
			
			const coloniasIngresos = dataInfoCPIngresos.map(x => x.colonia)
			this.ColoniasCPIngresos = coloniasIngresos
      this.ingreso.municipio = dataInfoCPIngresos[0].municipio

		
		},
    async getInformationCPIngresos(token) {

      const jsonCP = {
                token,
                CodigoPostal: this.ingreso.codigoPostal
              }

      const URL_Method = 'https://cotizador.socasesores.com/consultaSisec/getInfoCP.php'

      const { status, data } = await this.$axios
          .post(URL_Method,jsonCP,{headers : {'Content-Type':'application/json; charset=UTF-8'}})

      if ( status !== 200 ) {

        this.$vs.notify({
          title: 'Ocurrio un error en getInformationCP',
          text: data.strError,
          color: 'danger',
          position: 'top-right',
        })

        return false

      }

      if ( data.intRespuesta ) {

        this.$vs.notify({
          title: 'Ocurrio un error en Metodo getInformationCP',
          text: data.strError,
          color: 'danger',
          position: 'top-right',
        })

        return false

      }

      if ( data.objRespuesta.message ) {

        this.$vs.notify({
        title: data.objRespuesta.message,
        text: data.strError,
        color: 'danger',
        position: 'top-right',
        })

        return false

      }

      if ( !data.objRespuesta.length ) {
        return false
      }

      return data.objRespuesta

    },
  }
}
</script>

<style lang="css" scoped>
.v-select{
    width: 100%;
}
.label-select{
  margin-top: 5%;
  margin-bottom: 1%;
}
</style>
