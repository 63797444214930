<template>
	<vx-card class="grid-view-item overflow-hidden" v-on="$listeners">
		<template slot="no-body">
			<div class="solicitados">
				<slot name="action-favs" />
				<div class="header-pro">
					<div class="img-pro">
						<img :src="'https://cotizador.socasesores.com/uploads/' + solicitados.BancoLogo" :alt="solicitados.Banco" class="" width="180" />
					</div>
					<p class="font-bold">{{ solicitados.Variante }}</p>
				</div>
				<div class="">
          <div v-if="precalificacionTipo == 3" class="row">
						<div class="col-md-6 col-xs-12 td-modal">Plazo:</div>
						<div class="col-md-6 col-xs-12">{{ solicitados.Plazo * 12  }} meses</div>
					</div>
					<div v-else class="row">
						<div class="col-md-6 col-xs-12 td-modal">Pago:</div>
						<div class="col-md-6 col-xs-12">{{ solicitados.Esquema == 2 ? 'Fijo' : 'Creciente' }} durante {{ solicitados.Plazo }} años </div>
					</div>
					<div class="row">
						<div class="col-md-6 td-modal">Mensualidad:</div>
						<div class="col-md-6 col-sm-12">${{ solicitados.MensualidadPromedio }}</div>
					</div>
					<div class="row">
						<div class="col-md-6 td-modal">Tasa:</div>
						<div class="col-md-6 col-sm-12">{{ solicitados.Tasa }}%</div>
					</div>
					<div class="row">
						<div class="col-md-6 td-modal">Cat:</div>
						<div class="col-md-6 col-sm-12">{{ solicitados.Cat }}%</div>
					</div>
          <div class="row" v-if="precalificacionTipo == 3">
						<div class="">
							<slot name="action-buttons-2" />
							<vx-tooltip text="Solicitud de crédito">
								<button class="btn-pro" @click="generaSolicitudEditableAutos(solicitados, 'titular')">Generar solicitud</button>
							</vx-tooltip>
						</div>
					</div>
          <div v-else>
            <div class="row" v-if="solicitados.IdBanco == 2 || solicitados.IdBanco == 11 || solicitados.IdBanco == 4 || solicitados.IdBanco == 6 || solicitados.IdBanco == 18 || solicitados.IdBanco == 12 || solicitados.IdBanco == 22 || solicitados.IdBanco == 28 || solicitados.IdBanco == 29 || solicitados.IdBanco == 16 || solicitados.IdBanco == 14 || solicitados.IdBanco == 37 || solicitados.IdBanco == 10">
              <div class="">
                <slot name="action-buttons-2" />
                <vx-tooltip text="Solicitud de crédito">
                  <button class="btn-pro" @click="generaSolicitudEditable(solicitados)">Generar solicitud</button>
                </vx-tooltip>
              </div>
            </div>
            <div class="row" v-if="solicitados.IdBanco != 4 && solicitados.IdBanco != 11 && solicitados.IdBanco != 2 && solicitados.IdBanco != 6 && solicitados.IdBanco != 18 && solicitados.IdBanco != 12 && solicitados.IdBanco != 22 && solicitados.IdBanco != 28 && solicitados.IdBanco != 29 && solicitados.IdBanco != 16 && solicitados.IdBanco != 14 && solicitados.IdBanco != 37 && solicitados.IdBanco != 10">
              <div class="">
                <slot name="action-buttons-2" />
                <vx-tooltip text="Solicitud de crédito">
                  <button class="btn-pro" @click="generaSolicitud(solicitados)">Generar solicitud</button>
                </vx-tooltip>
              </div>
            </div>
          </div>
					<div class="row" v-if="solicitados.IdBanco == 12">
						<vx-tooltip text="Anexos a descargar">
              <div v-if="precalificacionTipo == 3">                
                <!-- <div class="anexos-pro cursor"><a @click="generaSolicitudEditableAutos(solicitados,'titular')" style="color:#079DEF;">Anexo B</a></div> -->
              </div>              
              <div v-else>
                <div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/Afirme_AnexoA.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Anexo A</a></div>
							  <div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/Afirme_AnexoBuroPF.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Autorización de Buro de crédito y Consulta de Historial Crediticio (PF)</a></div>
				        <div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/Afirme_AnexoBuroPM.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Autorización de Buro de crédito y Consulta de Historial Crediticio (PM)</a></div>
                <!--<div class=" anexos-pro cursor">Anexos a descargar</div>-->
              </div>							
						</vx-tooltip>
					</div>
					<div class="row" v-if="solicitados.IdBanco == 11 || solicitados.IdBanco == 28">  <!--   scotiabank y scotia desarrolladores -->
						<vx-tooltip text="Anexos a descargar">
              <div v-if="precalificacionTipo == 3">
                <div class="anexos-pro cursor"><a @click="generaAnexoEditableAutos('scotiabank_anexo_automotriz_COS.php',solicitados.IdCaso, 'titular')" style="color:#079DEF;">Solicitud con Obligado Solidario</a></div>
              </div>

              <div v-else>
                <!--<div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/Scotiabank_AnexoB.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Anexo B</a></div>-->
                <div class="anexos-pro cursor"><a @click="generaAnexoEditable('Scotiabank_anexo_B.php', solicitados.IdCaso, solicitados.Resultado)" style="color:#079DEF;">Anexo B</a></div>
                <div class="anexos-pro cursor"><a @click="generaAnexoEditable('Scotiabank_anexo_carta_de_autorizacion_hipotecaria.php', solicitados.IdCaso, solicitados.Resultado)" style="color:#079DEF;">Carta de Autorización Hipotecaria</a></div>
              </div>
						</vx-tooltip>
					</div>
					<div class="row" v-if="solicitados.IdBanco == 10">
						<vx-tooltip text="Anexos a descargar">
							<div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/Santander_AnexoB_2022_06.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Anexo B</a></div>
              <div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/Santander_AnexoB_Fisicas_2023_06.pdf'" style="color:#079DEF;">Carta Autorizacion Personas Fisicas</a></div>
              <!--<div class="anexos-pro cursor"><a @click="generaAnexoEditable('Santander_anexo_B.php', solicitados.IdCaso, solicitados.Resultado)" style="color:#079DEF;">Anexo B</a></div>-->
							<div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/Carta_de_Autorización_para_investigación_para_persona_Moral_RIES.pdf'" style="color:#079DEF;">Carta Autorizacion Persona Moral</a></div>
							<div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/SUSTITUCION_CARTA_PASIVOS_BROKER.pdf'" style="color:#079DEF;">Sustitución carta pasivos broker</a></div>
							<div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/Cuestionario_créditos_partir_de_5_MDP.pdf'" style="color:#079DEF;">Cuestionario para créditos a partir de 5MDP</a></div>
              <div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/SantanderAnexoCartaAutorizacion17-06-2024.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Anexo Carta de Autorización para Investigación de Información Crediticia</a></div>
						</vx-tooltip>
					</div>
					<div class="row" v-if="solicitados.IdBanco == 14">
						<vx-tooltip text="Anexos a descargar">
              <div v-if="precalificacionTipo == 3">      
                <div class="anexos-pro cursor"><a @click="generaAnexoEditableAutos('banregio_anexo_automotriz_PM.php',solicitados.IdCaso, 'titular')" style="color:#079DEF;">Solicitud Persona Moral</a></div>
              </div>
              <div v-else>
                  <div class="anexos-pro cursor"><a target="_blank" :href="'https://cotizador.socasesores.com/admin/formatos/SolicitudesEditables/anexos/Banregio_Carta_autorizacion.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Carta Autorizacion</a></div>
                  <div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/Banregio_AvisoPrivacidad.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Aviso de Privacidad</a></div>
                  <!--<div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/SolicitudesEditables/anexos/Hey_Banco_anexo_consulta_buro.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Autorización Buró</a></div>-->
                  <div class="anexos-pro cursor"><a @click="generaAnexoEditable('Hey_Banco_anexo_consulta_buro.php', solicitados.IdCaso, solicitados.Resultado)" style="color:#079DEF;">Autorización Buró</a></div>
                  <div class="anexos-pro cursor">
                    <a target="_blank" :href="'https://cotizador.socasesores.com/admin/formatos/SolicitudesEditables/anexos/Banregio_Autorizacion_Buro_PM.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Autorización Buro - PM</a>
                  </div>
                  <div class="anexos-pro cursor">
                    <a target="_blank" :href="'https://cotizador.socasesores.com/admin/formatos/SolicitudesEditables/anexos/Banregio_Solicitud_de_Avaluo.php?s=' + solicitados.IdCaso + '&o=' + solicitados.Resultado" style="color:#079DEF;">Solicitud de Avalúo</a>
                  </div>
              </div>
						</vx-tooltip>
					</div>
					<div class="row" v-if="solicitados.IdBanco == 4">
						<vx-tooltip text="Anexos a descargar">
							<div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/Banorte_AnexoF.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Anexo F</a></div>
							<div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/Banorte_AutorizacionBuro.php?s='+solicitados.IdCaso" style="color:#079DEF;">Autorización Buro Solicitante</a></div>
							<div class=" anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/Banorte_AutorizacionBuro_Coacreditado.php?s='+solicitados.IdCaso" style="color:#079DEF;">Autorización Buro Coacreditado</a></div>
						</vx-tooltip>
					</div>
					<div class="row" v-if="solicitados.IdBanco == 6 || solicitados.IdBanco == 18"> <!-- HSBC y HSBC Desarrolladores-->
						<vx-tooltip text="Anexos a descargar">
              <div v-if="precalificacionTipo == 3">      
                <div class="anexos-pro cursor"><a @click="generaAnexoEditableAutos('HSBC_anexo_automotriz_coacreditado.php',solicitados.IdCaso, 'coacreditado')" style="color:#079DEF;">Solicitud coacreditado</a></div>
              </div>
              <div v-else>
                <!-- <div class="anexos-pro cursor"><a target="_blank" :href="'https://cotizador.socasesores.com/admin/formatos/anexos/HSBC_Carta_Seleccion_de_Productos.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Carta de selección de productos hipotecarios</a></div> -->
                <div class="anexos-pro cursor"><a @click="generaAnexoEditable('HSBC_Carta_de_Seleccion_de_Producto_Hipotecario.php', solicitados.IdCaso, null, solicitados.VarianteId)" style="color:#079DEF;">Carta de selección de productos hipotecarios</a></div>
                <div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/HSBC_Carta_de_consentimiento.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Carta de consentimiento</a></div>
                <div class="anexos-pro cursor" v-if="solicitados.CoAcreditado == 'true'"><a @click="generaAnexoEditable('HSBC_Seguro_Coacreditado.php', solicitados.IdCaso, null, solicitados.VarianteId)" style="color:#079DEF;"> Anexo de Seguro Coacreditado</a></div>
                <div class="anexos-pro cursor"><a @click="generaAnexoEditable('HSBC_Autorizacion_Cliente.php', solicitados.IdCaso, null, null)" style="color:#079DEF;">Formato de Autorización del cliente</a></div>
              </div>
						</vx-tooltip>
					</div>
					<!-- <div class="row" v-if="solicitados.IdBanco == 16">
						<vx-tooltip text="Anexos a descargar">
							<div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/BX+_AnexoB.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Anexo b</a></div>
						</vx-tooltip>
					</div> -->

					<!-- Anexo editable BX+ -->
					<div class="row" v-if="solicitados.IdBanco == 16">
						<vx-tooltip text="Anexos a descargar">
							<div class="anexos-pro cursor"><a @click="generaAnexoEditable('bx+_anexo_2022_11.php', solicitados.IdCaso, null)" style="color:#079DEF;">Anexo b</a></div>
						</vx-tooltip>
					</div>

					<div class="row" v-if="solicitados.IdBanco == 2">
						<vx-tooltip text="Anexos a descargar">
							<div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/Carta_Seguros_Citi_03082022.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Carta de Seguros</a></div>
              <div class="anexos-pro cursor" v-if="nacionalidadSol !=='MEXICANO (A)' && nacionalidadSol !=='' ">
                <a @click="generaAnexoEditable('Cuestionario_Extranjero_citiBanamex_24_06.php', solicitados.IdCaso, null)" style="color:#079DEF;">Cuestionario para solicitantes de crédito Hipotecario de nacionalidad extranjera</a>
              </div>
            </vx-tooltip>
					</div>

					<div class="row" v-if="solicitados.IdBanco == 22">
						<vx-tooltip text="Anexos a descargar">
							<div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/mifel_carta_autorizacion_2022_10_27.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Carta Autorizacion</a></div>
							
              <!-- <div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/mifel_cuestionario_enfermedades_respiratorias.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Cuestionario Enfermedades Respiratorias</a></div>
							<div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/mifel_solicitud_vida_individual.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Solicitud Unica Vida Individual</a></div> -->

              <div class="anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/mifel_solicitud_cuestionario_seguro_vida.php?s=' + solicitados.IdCaso" style="color:#079DEF;">Solicitud y Cuestionario de Seguro de Vida</a></div>                            
              <div class="anexos-pro cursor"><a @click="generaAnexoEditable('mifel_solicitud_de_avaluo.php', solicitados.IdCaso, solicitados.Resultado)" style="color:#079DEF;">Solicitud de Avalúo</a></div>
						</vx-tooltip>
					</div>


				</div>
				<div v-if="popupSolicitud">
					<vs-popup fullscreen :title="isAnexo ? 'Anexo' : 'Solicitud de crédito'" :active.sync="popupSolicitud">
						<embed :src="'' + urlSolicitud" type="application/pdf" width="100%" height="1000px" />
					</vs-popup>
				</div>
			</div>
		</template>
	</vx-card>
</template>

<script>
import Productos from '@/views/Caso/Productos.vue'

export default {
  data(){
    return{
      urlSolicitud:'',
      popupSolicitud:false,
	  isAnexo:false,
    }

  },
  components:{
    Productos
  },
  props: {
    solicitados: {
      type: Object,
      required: true
    },
    nacionalidadSol:{
      type:String,
      required:false
    },
		isActiveCoacreditado: {
			type: Boolean,
			required: false
		},
    precalificacionTipo: {
			type: Number,
			required: false
		}
  },
  mounted(){
    console.log(this.precalificacionTipo)
    this.getApikey()
  },
  methods:{
    generaSolicitud(item){
      let self=this
      var objRequestSolicitaCaso = {
        strApiKey: this.key,
        strMetodo: "GeneraSolicitud",
        objSolicitud:{
          Id:item.IdCaso,
          Banco:item.IdBanco,
          Variante:item.Resultado
        }
      }
      this.$axios.post('/',objRequestSolicitaCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
			this.isAnexo = false;
            this.urlSolicitud=response.data.objContenido.Solicitud
            this.popupSolicitud=true
			this.conteoSolicitudes(item)
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en GeneraSolicitud',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
	generaSolicitudEditable(item){    
      let self=this
      var objRequestSolicitaCaso = {
        strApiKey: this.key,
        strMetodo: "GeneraSolicitudEditable",
        objSolicitud:{
          Id:item.IdCaso,
          Banco:item.IdBanco,
          Variante:item.Resultado          
        }
      }      
      this.$axios.post('/',objRequestSolicitaCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
			      this.isAnexo = false;
            this.urlSolicitud=response.data.objContenido.Solicitud          
            this.popupSolicitud=true			

			this.conteoSolicitudes(item)
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en GeneraSolicitud',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
  },
  generaSolicitudEditableAutos(item, entidad = null, file = null ){    
      let self=this
      var objRequestSolicitaCaso = {
        strApiKey: this.key,
        strMetodo: "GeneraSolicitudEditableAutos",
        objSolicitud:{
          Id:item.IdCaso,
          Banco:item.IdBanco,
          Variante:item.Resultado,
          Entidad: entidad,
          File: file
        }
      }      
      this.$axios.post('/',objRequestSolicitaCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
			      this.isAnexo = false;
            this.urlSolicitud=response.data.objContenido.Solicitud
            this.popupSolicitud=true			

			this.conteoSolicitudes(item)
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en GeneraSolicitud',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
  },
  generaAnexoEditableAutos(file, idCaso, entidad = "titular"){      
		let $url = `https://cotizador.socasesores.com/admin/formatos/SolicitudesEditables/automoviles/anexos/${ file }?s=${ idCaso }&o=${entidad}`;
		this.isAnexo = true;
		this.urlSolicitud = $url;
    this.popupSolicitud=true;
  },
	generaAnexoEditable(file, idCaso, idResultado, idProducto = null) {
    // Para carta de selección de producto
    let productoId = '';
    idProducto ? productoId = `&p=${ idProducto }` : productoId = '';

		let $url = `https://cotizador.socasesores.com/admin/formatos/SolicitudesEditables/anexos/${ file }?s=${ idCaso }&o=${idResultado}${ productoId }`;
		this.isAnexo = true;
		this.urlSolicitud = $url;
    this.popupSolicitud=true;
	},
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }      
    },
	 conteoSolicitudes(item){
      let self=this
      var objPrecalificacionSol = {
        strApiKey: this.key,
        strMetodo: 'SumaSolicitudes',
		objPrecalificacion: {
         Id: item.IdCaso,
        }
      }
      this.$axios.post('/',objPrecalificacionSol,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.suma=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en Suma de solicitudes',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
#ecommerce-wishlist-demo {
    .item-view-primary-action-btn {
        color: #2c2c2c !important;
        background-color: #f6f6f6;
        min-width: 50%;
    }

    .item-view-secondary-action-btn {
        min-width: 50%;
    }
}

</style>
