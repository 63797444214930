<template lang="html">
  <div id="container">
    <div class="row w-full" v-if="email == ''">
      <div class="vx-col w-full md:w-3/3 sm:w-2/2 ">
        <vx-card class="text-center cursor-pointer">
          <img src="@/assets/images/pages/graphic-2.png" alt="graphic" width="180" class="mx-auto mb-4">
          <h4 class="mb-2">Añade un coacreditado</h4>
          <small>Regresa al apartado solicitante para agregar un coacreditado</small>
        </vx-card>
      </div>
    </div>
    <div class="row w-full" v-else>

      <div class="vx-col w-full">
        <vx-card title="Datos requeridos" class="">
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder" for="formasEnganche">Parentesco *</span>
              <select name="cars" id="formasEnganche" v-model="parentesco" class="vs-input">
                <option :value="''">seleccione...</option>
                <option :value='tipo.Id' v-for="tipo in parentescos"  :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Nombre(s)</span>
              <input class="vs-input" label="Nombre(s) *" v-model="nombre" name="name"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Apellido Paterno</span>
              <input class="vs-input" label="Apellido Paterno *" v-model="apellidoPaterno" name="name"/>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Apellido Materno</span>
              <input class="vs-input" label="Apellido Materno *" v-model="apellidoMaterno" name="name"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Año conociéndolo</span>
              <input class="vs-input" label="Años conociendolo *" v-model="años" name="name" type="number"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Teléfono celular (10 dígitos)*</span>
              <!-- <input class="vs-input" label="Teléfono celular (10 dígitos) *" v-model="telCelular" name="name" type="number"/> -->
              
              <input class="vs-input" v-model="telCelular" v-validate="'min:10|max:10'" v-mask="'##########'" name="telCelular" type="number"/>
              <!-- <input class="vs-input" v-model="curp" name="curp" v-validate="'min:18|max:18'" v-mask="'AAAA######AAAAAA##'" value=""  onkeyup="javascript:this.value=this.value.toUpperCase();"/> -->
              <span class="text-danger span-placeholder" v-show="errors.has('telCelular')">El campo debe tener 10 dígitos</span>
            </div>
          </div>
          <div class="row w-full">
          <div class="accordion" id="accordionExampleADI">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneADI">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneADI" aria-expanded="true" aria-controls="collapseOneADI">
                  Adicionales requeridos para llenado de solicitudes
                </button>
              </h2>
              <div id="collapseOneADI" class="accordion-collapse collapse" aria-labelledby="headingOneADI" data-bs-parent="#accordionExampleADI">
                <div class="accordion-body">
                  <div class="row w-full">
                  <div class="col-md-6">
                    <span class="span-placeholder">Domicilio</span>
                    <input class="vs-input" label="Domicilio" v-model="domicilio" name="name"/>
                  </div>
                  <div class="col-md-6">
                    <span class="span-placeholder">Teléfono casa (10 dígitos)</span>
                    <input class="vs-input" label="Teléfono casa (10 dígitos)" v-model="telCasa" name="name" type="number"/>
                  </div>
                  </div>
                  <div class="row w-full">
                    <div class="col-md-6">
                      <span class="span-placeholder">Teléfono oficina (10 dígitos)</span>
                      <input class="vs-input" label="Teléfono oficina (10 dígitos)" v-model="telOficina" name="name" type="number"/>
                    </div>
                    <div class="col-md-6">
                      <span class="span-placeholder">Correo electrónico</span>
                      <input class="vs-input" label="Correo electrónico" v-model="correo" name="name"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
          <button class="btn btn-second" @click="registraReferencia()" :disabled="!validateForm">Guardar referencia</button>
            </div>
          </div>
        </vx-card>
        <vx-card title="" class="">
          <vs-table :data="referencias" max-items="5"
            pagination
            noDataText="No hay datos para mostrar">
            <template slot="thead">
              <vs-th>
                Nombre(s)
              </vs-th>
              <vs-th>
                Apellido Paterno
              </vs-th>
              <vs-th>
                Apellido Materno
              </vs-th>
              <vs-th>
                Parentesco
              </vs-th>
              <vs-th>
                Teléfono celular
              </vs-th>
              <vs-th>
                Años de conocerlo
              </vs-th>
              <vs-th>
                Acciones
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].Nombre" >
                  {{data[indextr].Nombre}}
                </vs-td>
                <vs-td :data="data[indextr].ApellidoPaterno">
                  {{data[indextr].ApellidoPaterno}}
                </vs-td>
                <vs-td :data="data[indextr].ApellidoMaterno">
                  {{data[indextr].ApellidoMaterno}}
                </vs-td>
                <vs-td :data="data[indextr].Parentesco">
                  {{data[indextr].Parentesco}}
                </vs-td>
                <vs-td :data="data[indextr].Telefono">
                  {{data[indextr].Telefono}}
                </vs-td>
                <vs-td :data="data[indextr].AnosConocerlo">
                  {{data[indextr].AnosConocerlo}} {{ data[indextr].AnosConocerlo ? "Años" : "" }}
                </vs-td>
                <vs-td>
                  <vs-row vs-w="12" >
                    <vx-tooltip text="Editar referencia" position="top">
                      <button class="favoritos" @click="editar(data[indextr])">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <g id="vuesax_twotone_edit" data-name="vuesax/twotone/edit" transform="translate(-620 -252)">
                            <g id="edit">
                              <path id="Vector" d="M9,0H7C2,0,0,2,0,7v6c0,5,2,7,7,7h6c5,0,7-2,7-7V11" transform="translate(622 254)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.4"/>
                              <path id="Vector-2" data-name="Vector" d="M8.991,1.271l-7.88,7.88a2.712,2.712,0,0,0-.66,1.32l-.43,3.01a1.424,1.424,0,0,0,1.7,1.7l3.01-.43a2.8,2.8,0,0,0,1.32-.66l7.88-7.88c1.36-1.36,2-2.94,0-4.94S10.351-.089,8.991,1.271Z" transform="translate(627.049 253.749)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                              <path id="Vector-3" data-name="Vector" d="M0,0A7.144,7.144,0,0,0,4.94,4.94" transform="translate(634.91 256.15)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.4"/>
                              <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(620 252)" fill="none" opacity="0"/>
                            </g>
                          </g>
                        </svg>
                      </button>
                    </vx-tooltip>
                    <vx-tooltip text="Borrar referencia" position="top">
                      <button class="favoritos" @click="eliminar(data[indextr].Id)">
                           <svg class="trash">
                                <g id="trash">
                                  <path id="Vector" d="M18,.5C14.67.17,11.32,0,7.98,0A59.068,59.068,0,0,0,2.04.3L0,.5" transform="translate(3 5.48)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  <path id="Vector-2" data-name="Vector" d="M0,2.97.22,1.66C.38.71.5,0,2.19,0H4.81C6.5,0,6.63.75,6.78,1.67L7,2.97" transform="translate(8.5 2)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                                  <path id="Vector-3" data-name="Vector" d="M13.7,0l-.65,10.07c-.11,1.57-.2,2.79-2.99,2.79H3.64C.85,12.86.76,11.64.65,10.07L0,0" transform="translate(5.15 9.14)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  <path id="Vector-4" data-name="Vector" d="M0,0H3.33" transform="translate(10.33 16.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                                  <path id="Vector-5" data-name="Vector" d="M0,0H5" transform="translate(9.5 12.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                                  <path id="Vector-6" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0"/>
                                </g>
                              </svg>
                      </button>
                    </vx-tooltip>
                  </vs-row>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
    <vs-popup fullscreen title="Editar referencia" :active.sync="popupActive">
      <vx-card title="Datos requeridos" class="">
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder" for="formasEnganche">Parentesco *</span>
            <select name="cars" id="formasEnganche" v-model="parentesco" class="vs-input">
              <option :value='tipo.Id' v-for="tipo in parentescos"  :key="">{{tipo.Nombre}}</option>
            </select>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Nombre(s)</span>
            <input class="vs-input" label="Nombre(s) *" v-model="nombre" name="name"/>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Apellido Paterno*</span>
            <input class="vs-input" label="Apellido Paterno *" v-model="apellidoPaterno" name="name"/>
          </div>
        </div>
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder">Apellido Materno*</span>
            <input class="vs-input" label="Apellido Materno *" v-model="apellidoMaterno" name="name"/>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Años conociéndolo</span>
            <input class="vs-input" label="Años conociendolo *" v-model="años" name="name" type="number"/>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Teléfono celular (10 dígitos)</span>
            <input class="vs-input" label="Teléfono celular (10 dígitos) *" v-model="telCelular" name="name" type="number"/>
          </div>
        </div>
        <br>
        <h4>Adicionales</h4>
        <div class="row w-full">
          <div class="col-md-6">
            <span class="span-placeholder">Domicilio</span>
            <input class="vs-input" label="Domicilio" v-model="domicilio" name="name"/>
          </div>
          <div class="col-md-6">
            <span class="span-placeholder">Institución acreedora</span>
            <input class="vs-input" label="Teléfono casa (10 dígitos)" v-model="telCasa" name="name" type="number"/>
          </div>

        </div>
        <div class="row w-full">
          <div class="col-md-6">
            <span class="span-placeholder">Teléfono oficina (10 dígitos)</span>
            <input class="vs-input" label="Teléfono oficina (10 dígitos)" v-model="telOficina" name="name" type="number"/>
          </div>
          <div class="col-md-6">
            <span class="span-placeholder">Correo electrónico</span>
            <input class="vs-input" label="Correo electrónico" v-model="correo" name="name"/>
          </div>
        </div>
        <br>
        <vs-button color="primary" @click="editarDependiente()" :disabled="!validateForm">Guardar referencia</vs-button>
      </vx-card>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    'v-select': vSelect,
  },
  data(){
		return{
      popupActive: false,
      nombre:'',
      apellidoPaterno:'',
      apellidoMaterno:'',
      correo:'',
      años:'',
      telCasa:'',
      telOficina:'',
      telCelular:'',
      domicilio:'',
      parentesco:'',
      referenciaId:'',
      referencias:[],
      parentescos:[],
      email:'',
    }
  },
  computed:{
    validateForm(){
      return this.nombre != '' && this.apellidoPaterno != '' && this.apellidoMaterno != '' && this.telCelular != '' && this.parentesco != ''
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    this.getListaParentescos()
    this.dameCaso()
  },
  watch: {
		// Formato mayusculas sin acento
		nombre: function(newValue, oldValue) {
			this.nombre = this.textFormat(newValue);
		},
		apellidoPaterno: function(newValue, oldValue) {
			this.apellidoPaterno = this.textFormat(newValue);
		},
		apellidoMaterno: function(newValue, oldValue) {
			this.apellidoMaterno = this.textFormat(newValue);
		},
    años: function(newValue) {
      if (this.años >100) {
        this.años = 100
      }
    },
    popupActive:function(){
      if (this.popupActive == false) {
        this.limpiaForm()
      }
    },
  },
  methods:{
		// Conversion
		textFormat(strValue) {
			return strValue.normalize('NFD')
							.replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
							.normalize()
							.toUpperCase(); // Eliminar acentos menos Ññ
		},
    editarDependiente(){
      let self= this
      var objRequestRegistraCuentasBancarias = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'EditarReferenciasPersonales',
        objSolicitante:{
            EMail:this.email,
            ReferenciasPersonales:[
              {
                Id:this.referenciaId,
                Nombre:this.nombre,
                ApellidoPaterno:this.apellidoPaterno,
                ApellidoMaterno:this.apellidoMaterno,
                Telefono:this.telCelular,
                Domicilio : this.domicilio,
                AnosConocerlo:this.años,
                Parentesco:this.parentesco,
                TelefonoCelular:this.telCelular,
                TelefonoCasa:this.telCasa,
                TelefonoOficina:this.telOficina,
                EMail : this.correo
              }
            ]
        }
      }
      this.$axios.post('/',objRequestRegistraCuentasBancarias,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos editados exitosamente`,
              position:'top-right'
            })
            this.popupActive=false
            this.limpiaForm()
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en EditarReferenciasPersonales',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    editar(referencia){
      this.nombre=referencia.Nombre
      this.apellidoPaterno=referencia.ApellidoPaterno
      this.apellidoMaterno=referencia.ApellidoMaterno
      this.correo=referencia.EMail
      this.años=referencia.AnosConocerlo
      this.telCasa=referencia.TelefonoCasa
      this.telOficina=referencia.TelefonoOficina
      this.telCelular=referencia.Telefono
      this.domicilio=referencia.Domicilio
      this.parentesco=referencia.ParentescoId
      this.referenciaId=referencia.Id
      this.popupActive=true
    },
    eliminar(id){
      var eliminarCuentas = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'BorrarReferenciasPersonales',
        objSolicitante:{
            EMail:this.email,
            ReferenciasPersonales:[
              {
                Id:id
              }
            ]
          }
      }
      this.$axios.post('/',eliminarCuentas,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Se eliminó exitosamente`,
              position:'top-right'
            })
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en BorrarReferenciasPersonales',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraReferencia(){
      this.$vs.loading({
        container: '#container',
        scale: 0.6
      })
      let self= this
      var objRequestRegistraReferencias = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'RegistraReferenciasPersonales',
        objSolicitante:{
            EMail:this.email,
            ReferenciasPersonales:[
              {
                Nombre:this.nombre,
                ApellidoPaterno:this.apellidoPaterno,
                ApellidoMaterno:this.apellidoMaterno,
                Telefono:this.telCelular,
                Domicilio : this.domicilio,
                AnosConocerlo:this.años,
                Parentesco:this.parentesco,
                // Los siguientes son completamente opcionales
                TelefonoCelular:this.telCelular,
                TelefonoCasa:this.telCasa,
                TelefonoOficina:this.telOficina,
                EMail : this.correo
              }
            ]
        }
      }
      this.$axios.post('/',objRequestRegistraReferencias,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.limpiaForm()
            this.dameSolicitante()
            this.$vs.loading.close('#container > .con-vs-loading')
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            if (response.data.objContenido.Coacreditado[0]) {
              this.email= response.data.objContenido.Coacreditado[0].EMail
            }
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.referencias=response.data.objContenido.ReferenciasPersonales
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaParentescos(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaParentescos',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.parentescos=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaParentescos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    limpiaForm(){
      this.nombre=''
      this.apellidoPaterno=''
      this.apellidoMaterno=''
      this.correo=''
      this.años=''
      this.telCasa=''
      this.telOficina=''
      this.telCelular=''
      this.domicilio=''
      this.parentesco=''
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
  }
}
</script>

<style lang="css" scoped>
.v-select{
    width: 100%;
}
.label-select{
  margin-top: 3%;
}
</style>
