<template>
    <div>

        <!-- tabs title -->
		<ul class="nav nav-tabs" id="myTab" role="tablist">
			<li class="nav-item" role="presentation">
				<button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Acceso de precalificador</button>
			</li>
		</ul>

        <!-- tabs content -->
		<div class="tab-content" id="myTabContent">

			<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

				<!-- Nota para el Front: Aqui va todo el HTML dentro de este div -->
				<div class="tab-body">

					<div class="flex flex-col space-y-4">
						<!-- mostrar solo cuando son dos o mas asesores -->
						<div
							v-if="asesoresList.length > 1" 
							class="w-full">
							Mostrar
							<select
								@change="mostrarAsesores($event)" 
								class="py-1 pr-0" 
								name="" 
								:id="`filtro-out-asesor`">
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
							registros
						</div>

						<!-- header para asesores -->
						<div
							v-if="asesoresList.length > 0"  
							class="flex justify-between text-center">
							<span class="w-3/12 font-semibold pl-20">Asesor</span>
							<span class="w-3/12 font-semibold pl-20">Correo</span>
							<span class="w-3/12 font-semibold pl-20">Acceso</span>
							<span class="w-2/12 font-semibold pr-5">Acciones</span>
						</div>

						<!-- mostrar solo cuando son dos o mas asesores -->
						<div
							v-if="asesoresList.length > 1" 
							class="flex justify-evenly gap-4">
							<input
								:id="`input-asesor-out`"
								@keyup="searchOutAsesor($event)" 
								class="w-3/12 px-2 input-preca-caso" 
								type="text" 
								placeholder="Asesor">
							<input 
								:id="`input-correo-out`"
								@keyup="searchOutAsesor($event)" 
								class="w-3/12 px-2 input-preca-caso" 
								type="text" 
								placeholder="Correo">
							<input 
								:id="`input-acceso-out`"
								@keyup="searchOutAsesor($event)" 
								class="w-3/12 px-2 input-preca-caso" 
								type="text" 
								placeholder="Acceso">
							<span class="w-1/12"></span>
						</div>
					</div>

					<!-- estructura para asesores -->
					<div
						v-if="asesores" 
						class="accordion mt-8" 
						id="accordionAsesores">

						<!-- Asesor 1 -->
						<div 
							v-for="(asesor, index) in asesores" :key="asesor.Id" 
							class="accordion-item asesor-externo">

							<h2 class="accordion-header" :id="`headingUp${ index }`">
								<button class="accordion-button accordion-button-externo bg-preca-acord-asesor collapsed flex" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseUp${ index }`" aria-expanded="false" :aria-controls="`collapseUp${ index }`">

										<span class="text-center dato-acord-asesor ancho-preca-dato">{{ asesor.NombreEntidad }}</span>
										<span class="text-center dato-acord-asesor ancho-preca-mail">{{ asesor.Email }}</span>
										<span class="text-left dato-acord-asesor ancho-preca-dato">
											<u class="urlAsesorInmo">
												<a 
													:href="asesor.AccesoUrl" 
													target="_blank"
													class="text-white precalificador-url-broker"
												>{{ asesor.AccesoUrl }}</a>
											</u>
										</span>
										<span class="text-center ancho-preca-asesor-icono">
											<svg 
												@click="copyUrl( asesor.AccesoUrl )"
												data-bs-toggle="tooltip" 
												title="Copiar"
												xmlns="http://www.w3.org/2000/svg" 
												fill="none" 
												viewBox="0 0 24 24" 
												stroke-width="1.5" 
												stroke="currentColor" 
												class="w-6 h-6 btnCopyUrlAsesor dato-acord-asesor">
												<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
											</svg>
										</span>

								</button>
							</h2>

							<div :id="`collapseUp${ index }`" class="accordion-collapse collapse" :aria-labelledby="`headingUp${ index }`" data-bs-parent="#accordionAsesores">
								<div class="accordion-body accordion-scrolling mh-4">
									
									<!-- mostrar dentro de los asesores -->
									<div class="flex flex-col space-y-4">

										<div class="w-full">
											Mostrar
											<select 
												@change="mostrarPromotoras($event, asesor.Id)"
												class="py-1 pr-0" 
												name="" 
												:id="`filtro-int-${index}`">
												<option value="10">10</option>
												<option value="25">25</option>
												<option value="50">50</option>
												<option value="100">100</option>
											</select>
											registros
										</div>

										<div class="flex justify-evenly text-center label-preca-caso">
											<span class="ancho-interno">Inmobiliaria</span>
											<span class="ancho-interno">Vendedor <br>Inmobiliario</span>
											<span class="ancho-interno">Correo</span>
											<span class="ancho-interno">Acceso</span>
											<span class="ancho-interno">Acciones</span>
										</div>

										<div class="flex justify-evenly gap-4">
											<input 
												:id="`input-inmo-${index}`"
												@keyup="searchInAsesor($event, asesor.Id, index)"
												class="flex-1 px-2 input-preca-caso-interno" 
												type="text" 
												placeholder="Inmobiliaria">
											<input 
												:id="`input-vend-${index}`"
												@keyup="searchInAsesor($event, asesor.Id, index)"
												class="flex-1 px-2 input-preca-caso-interno" 
												type="text" 
												placeholder="Vendedor Inmobiliario">
											<input 
												:id="`input-correo-${index}`"
												@keyup="searchInAsesor($event, asesor.Id, index)"
												class="flex-1 px-2 input-preca-caso-interno" 
												type="text" 
												placeholder="Correo">
											<input 
												:id="`input-acceso-${index}`"
												@keyup="searchInAsesor($event, asesor.Id, index)"
												class="flex-1 px-2 input-preca-caso-interno" 
												type="text" 
												placeholder="Acceso">
											<span class="flex-1"></span>
										</div>

									</div>

									<!-- paginador inicio -->
									<div
										:id="`page-list-int-${ asesor.Id }`" 
										data-current-page="1" 
										aria-live="polite">

										<!-- inmobiliarias -->
										<div
											v-if="asesor.Promotoras" 
											class="accordion mt-8" 
											id="accordionInmo">

											<!-- inmmo 1 -->
											<div 
												v-for="(promotora, index) in asesor.Promotoras" :key="promotora.Id"
												class="accordion-item inmo-interno">

												<h2 class="accordion-header" :id="`headingSub${ index }-${ asesor.Id }`">
													<button 
														class="accordion-button accordion-button-interno collapsed flex gap-4" 
														type="button" 
														data-bs-toggle="collapse" 
														:data-bs-target="`#collapseSub${ index }-${ asesor.Id }`" 
														aria-expanded="false" 
														:aria-controls="`collapseSub${ index }-${ asesor.Id }`">
															<span class="text-center ancho-interno">{{ promotora.NombreEntidad }}</span>
															<span class="text-center ancho-interno">{{ promotora.NoEjecutivos }}</span>
															<span class="text-center ancho-interno">{{ promotora.Email }}</span>
															<span class="text-center ancho-interno">
																<u class="urlAsesorInmo text-link-inmo-interno">
																	<a 
																		:href="promotora.AccesoUrl" 
																		target="_blank"
																		class="text-link-inmo-interno precalificador-url-broker"
																	>{{ promotora.AccesoUrl }}</a>
																</u>
															</span>
															<span class="text-center ancho-interno">
																<svg
																	@click="copyUrl( promotora.AccesoUrl )" 
																	data-bs-toggle="tooltip" 
																	title="Copiar"
																	xmlns="http://www.w3.org/2000/svg" 
																	fill="none" 
																	viewBox="0 0 24 24" 
																	stroke-width="1.5" 
																	stroke="#079DEF" 
																	class="w-6 h-6 btnCopyUrlAsesor">
																	<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
																</svg>
															</span>

													</button>
												</h2>

												<div :id="`collapseSub${ index }-${ asesor.Id }`" class="accordion-collapse collapse" :aria-labelledby="`headingSub${ index }-${ asesor.Id }`" data-bs-parent="#accordionInmo">
													<div class="accordion-body accordion-scrolling mh-4">
														
														<!-- mostrar dentro de inmo -->
														<div class="flex flex-col space-y-4">

															<div
																v-for="ejecutivo in promotora.Ejecutivos" :key="ejecutivo.Id" 
																class="flex justify-evenly text-center">
																<span class="ancho-interno"></span>
																<span class="ancho-interno">{{ ejecutivo.NombreEntidad }}</span>
																<span class="ancho-interno">{{ ejecutivo.Email }}</span>
																<span class="ancho-interno">
																	<u>
																		<a :href="ejecutivo.AccesoUrl" 
																			target="_blank"
																			class="text-black precalificador-url-broker">{{ ejecutivo.AccesoUrl }}
																		</a>
																	</u>
																</span>
																<span class="ancho-interno-acor-icono">
																	<svg
																		@click="copyUrl( ejecutivo.AccesoUrl )" 
																		data-bs-toggle="tooltip" 
																		title="Copiar"
																		xmlns="http://www.w3.org/2000/svg" 
																		fill="none" 
																		viewBox="0 0 24 24" 
																		stroke-width="1.5" 
																		stroke="currentColor" class="w-6 h-6 cursor-pointer">
																		<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
																	</svg>
																</span>
															</div>

														</div>

													</div>
												</div>

											</div>

										</div>


										<!-- paginador inmobiliarias interno y registros -->
										<div class="flex mt-4">

											<div
												:id="`count-register-int-${ asesor.Id }`" 
												class="flex-1 text-left">
											</div>

											<div class="flex-1 flex justify-end space-x-1 ">
												<button
													:id="`prev-btn-int-${ asesor.Id }`" 
													class="cursor-pointer p-1 pag-preca-selectt">
													<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
														<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
													</svg>
													<span>Anterior</span>
												</button>
												
												<!-- aqui se calculan el numero de paginas -->
												<div 
													:id="`page-items-int-${ asesor.Id }`"
													class="flex justify-evenly gap-2"></div>

												<button
													:id="`next-btn-int-${ asesor.Id }`" 
													class="cursor-pointer p-1 pag-preca-selectt">
													<span>Siguiente</span>
													<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
														<path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
													</svg>
												</button>

											</div>

										</div>

									</div>
									<!-- paginador fin -->

								</div>
							</div>

						</div>

					</div>

					<!-- cuando no exista asesores -->
					<div
						v-if="showPageMsgEmpty"
						class="text-center h-64"
						>
						<h4 class="text-grey">
							Por el momento no dispones de Asesores con acceso al precalificador
						</h4>
					</div>

					<!-- paginador asesores externo y registros -->
					<div
						v-if="asesoresList.length > 1" 
						class="flex mt-4">

						<div
							:id="`count-register-out`" 
							class="flex-1 text-left ">
						</div>

						<div class="flex-1 flex justify-end space-x-1">
							<button
								id="prev-btn-out" 
								class="cursor-pointer p-1 pag-preca-selectt">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
								</svg>
								<span>Anterior</span>
							</button>
							
							<!-- aqui se calculan el numero de paginas -->
							<div 
								id="page-items-out"
								class="flex justify-evenly gap-2" >
							</div>

							<button
								id="next-btn-out" 
								class="cursor-pointer p-1 pag-preca-selectt">
								<span>Siguiente</span>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
								</svg>
							</button>

						</div>

					</div>

				</div>
				<!-- paginador externo fin -->
				<!-- Nota para el Front: Aqui termina todo lo que se modifica del HTML -->
				<input class="temp-input-preca" type="text" name="" id="tempInputPreca">

			</div>

		</div>


    </div>
</template>

<script>
export default {
	data() {
		return  {
			apiKey: '',
			TipoPerfilEjecutivo: null,
			asesores: [], // procesado
			asesoresList: [], // inicial
			asesoresInmo: [], // procesado
			asesoresInmoList: [], // inicial
			
			// debounceInput: '',
			timeout: null,
			timeoutAsesor: null,
			showPageMsgEmpty: false, 
		}
	},
	watch: {
		
	},
	computed: {

	},
	methods: {
		// Para copiar enlace en portapapeles
		copyUrl( url ) {
			try {
				// valida http / https
				if (window.isSecureContext && navigator.clipboard) {
					navigator.clipboard.writeText( url );
				} else {
					document.getElementById('tempInputPreca').value = url;
					document.getElementById('tempInputPreca').select();
					document.execCommand('copy'); // en desuso, se incluyo como soporte unico para pruebas
				}
				// console.log( 'enlace copiado: ', url );
			} catch ( e ) {
				throw new Error( e );
			}
		},
		// Paginador interno
		paginateInmo( perPage, idAsesor ) {

			// variables
			let pageItems = document.getElementById(`page-items-int-${ idAsesor }`);
			let pageList = document.getElementById(`page-list-int-${ idAsesor }`);
			let listItems = pageList.querySelectorAll('div.accordion-item.inmo-interno');
			let prevBtn = document.getElementById(`prev-btn-int-${ idAsesor }`);
			let nextBtn = document.getElementById(`next-btn-int-${ idAsesor }`);
			// elementos por pagina
			let itemsPerPage = perPage; // valor inicial despues de renderizar y hacer peticion al api
			let numberOfPage = Math.ceil(listItems.length / itemsPerPage);
			let currentPage = 1;


			let disableButton = (button) => {
				button.classList.add("disabled");
				button.setAttribute("disabled", true);
			};
			let enableButton = (button) => {
				button.classList.remove("disabled");
				button.removeAttribute("disabled");
			};
			// cambia el estado de los botones de navegacion
			let changeNavBtnState = () => {
				currentPage === 1 ? disableButton(prevBtn) : enableButton(prevBtn);
				numberOfPage === currentPage ? disableButton(nextBtn) : enableButton(nextBtn)
			};
		
			// crear numeros de pagina dinamicos
			let addPageNumber = (index) => {
				// crea nueva numeracion
				let numberPage = document.createElement("button");
				numberPage.className = `page-item-int-${ idAsesor } px-3 w-10 pag-preca-selectt`;
				numberPage.innerHTML = index;
				numberPage.setAttribute(`page-index-int-${ idAsesor }`, index);
				numberPage.setAttribute(`aria-label-int-${ idAsesor }`, "Page " + index);
				pageItems.appendChild(numberPage);
			};

			// pagina activa
			const setActivePage = () => {
				document.querySelectorAll(`.page-item-int-${ idAsesor }`).forEach((button) => {
					button.classList.remove("active-inmo");
					let pageIndex = Number(button.getAttribute(`page-index-int-${ idAsesor }`));

					if (pageIndex == currentPage) {
						button.classList.add("active-inmo");
					}
				});
			};

			// genera y limpia numeracion
			let generatePageNumbers = () => {
				// limpiar numeracion anterior
				pageItems.innerHTML = "";

				for (let i = 1; i <= numberOfPage; i++) {
					addPageNumber(i);
				}
			};

			// para registros
			let prevIdx = 0;
			let nextIdx = 0;

			// establece pagina actual y calcula registros
			let setCurrentPage = (pageNum) => {
				currentPage = pageNum;

				setActivePage();
				changeNavBtnState();
				
				let prevRange = (pageNum - 1) * itemsPerPage;
				let currRange = pageNum * itemsPerPage;


				// asignar idx
				prevIdx = prevRange + 1;
				if (currRange >= listItems.length ) {
					nextIdx = listItems.length;
				} else {
					nextIdx = currRange;
				}
				// calcula registros
				this.calculaRegistros( listItems.length, prevIdx, nextIdx, idAsesor );
				// console.log( 'calculo de registros terminado!' );


				listItems.forEach((item, index) => {
					item.classList.add("hidden");
					if (index >= prevRange && index < currRange) {
						item.classList.remove("hidden");
					}
				});
			};

			prevBtn.addEventListener("click", () => {
				setCurrentPage(currentPage - 1);
				// console.log( 'atras', currentPage );
			});

			nextBtn.addEventListener("click", () => {
				setCurrentPage(currentPage + 1);
				// console.log( 'adelante', currentPage );
			});

			generatePageNumbers();
			setCurrentPage(1);

			document.querySelectorAll(`.page-item-int-${ idAsesor }`).forEach( (button) => {
				const pageIndex = Number(button.getAttribute(`page-index-int-${ idAsesor }`));

				if (pageIndex) {
					button.addEventListener("click", () => {
						setCurrentPage(pageIndex);
					});
				}
			});

			// valida si hay resultados de promotoras, de lo contrario corrige la paginacion
			if ( !listItems.length ) {
				// disabled page nav buttons
				prevBtn.setAttribute("disabled", true);
				nextBtn.setAttribute("disabled", true);

				// console.log( 'No hay items para paginar' );
			}

			// console.log( 'paginacion terminada!' );
			
		},
		calculaRegistros( countItems, prevIdx, nextIdx, idAsesor ) {

			let countRegister = document.getElementById(`count-register-int-${ idAsesor }`);

			if ( countItems > 0) {
				countRegister.innerHTML = `
					Mostrando registros del ${ prevIdx } al ${ nextIdx } de un total de ${ countItems } registros
				`;
			} else {
				countRegister.innerHTML = `
					Mostrando 0 registros
				`;
			}
			
		},
		searchInAsesor(event, idAsesor, idInput) {

			/**
			 * Busqueda en Asesor por campo y combinado
			 * 
			 * - Inmobiliaria
			 * - Vendedor inmobiliario
			 * - Correo
			 * - Acceso
			 * 
			 * Buscador con debounce. funciona dentro de asesores
			 */

			// let val = event.target.value;
			let objSearch = {
				inmo: document.getElementById(`input-inmo-${ idInput }`).value,
				vend: document.getElementById(`input-vend-${ idInput }`).value,
				correo: document.getElementById(`input-correo-${ idInput }`).value,
				acceso: document.getElementById(`input-acceso-${ idInput }`).value,
			};		
			
			// console.log( 'event: ', val );
			// console.log( 'idAsesor: ', idAsesor );
			// console.log( 'idInput: ', idInput );

			// reiniciar temporizador si sigue escribiendo
			if(this.timeout) clearTimeout( this.timeout );
			// controla el numero de procesos
			this.timeout = setTimeout(() => {
				// realizar operacion de busqueda
				
				let asesoresArray = [...this.asesoresInmoList]; // reinicio para aplicar nueva busqueda
				// ubicacion del asesor en el array de asesores
				let idxAsesor = asesoresArray.findIndex( (item) => item.Id == idAsesor);
				// buscar el asesor con la seccion abierta
				asesoresArray = asesoresArray.filter( (item) => item.Id == idAsesor );
				// obtiene las promotoras de este asesor en especifico ( funciona para todos los perfiles )
				let promotorasArray = asesoresArray[0].Promotoras;
				// realiza busqueda a partir del objeto
				
				// busqueda 1
				promotorasArray = promotorasArray.filter( (item) => item.NombreEntidad.toLowerCase().includes( objSearch.inmo.toLowerCase() ));
				// busqueda 2 (subnivel)
				promotorasArray = promotorasArray.filter( 
					( item ) =>  {
						// si es vacio retorno todas las promotoras
						if ( objSearch.vend.trim() == "" ) {
							return true;
						}
						return item.Ejecutivos.some( (ejecutivo) => ejecutivo.NombreEntidad.toLowerCase().includes( objSearch.vend.toLowerCase() ));
					}
				);
				// busqueda 3
				promotorasArray = promotorasArray.filter( (item) => item.Email.toLowerCase().includes( objSearch.correo.toLowerCase() ));
				// busqueda 4
				promotorasArray = promotorasArray.filter( (item) => item.AccesoUrl.toLowerCase().includes( objSearch.acceso.toLowerCase() ));

				// nueva ubicacion del asesor en el array de asesores antes de la inyeccion si cambia el tamaño de busqueda
				if (this.asesores.length != this.asesoresInmoList.length) {
					idxAsesor = this.asesores.findIndex( (item) => item.Id == idAsesor);
				}
				// inyectar las promotoras resultantes
				this.asesores[idxAsesor].Promotoras = promotorasArray;

				// console.log( 'objSearch: ', objSearch );
				// console.log( 'promotoraSearch: ', promotorasArray );
				// console.log( 'resultados:', promotorasArray );


				setTimeout(() => {
					// paginar nuevamente, obtener el value del filtro
					let perPage = document.getElementById(`filtro-int-${ idInput }`).value;
					this.paginateInmo( Number(perPage), idAsesor );
					// console.log( 'pageSearch', Number(perPage) );

					// mapear las url y los iconos de copiar despues de cada busqueda 
					this.setBtnCopyUrlAsesorInmoProp();
					this.setUrlAsesorInmoProp();
					// reiniciar tooltip
					this.initTooltip();
				}, 100);

			}, 1000); // cambiar si es necesario

		},
		mostrarPromotoras( event, idAsesor ) {

			// Filtro por asesor
			let itemsPerPage = event.target.value;
			this.paginateInmo( itemsPerPage, idAsesor );

			// let asesoresArray = [...this.asesoresList];
			// // ubicacion del asesor en el array de asesores
			// let idxAsesor = asesoresArray.findIndex( (item) => item.Id == idAsesor);
			// // buscar el asesor con la seccion abierta
			// asesoresArray = asesoresArray.filter( (item) => item.Id == idAsesor );
			// // obtiene las promotoras de este asesor en especifico
			// let promotorasArray = asesoresArray[0].Promotoras;
			// // filtro la cantidad de promotoras
			// promotorasArray = promotorasArray.slice(0, event.target.value);
			// // inyecto las promotoras filtradas al asesor en especifico
			// this.asesores[idxAsesor].Promotoras = promotorasArray;
			// console.log( 'asesores', this.asesores );
			// console.log( 'original', this.asesoresList );
		},
		mostrarAsesores( event ) {
			let asesorPerPage = Number(event.target.value);
			this.paginateAsesor( asesorPerPage );
		},
		searchOutAsesor( event ) {

			/**
			 * Busqueda para Cuenta Maestra y Gerente por campo y combinada
			 * 
			 * - Asesor
			 * - Correo
			 * - Acceso
			 * 
			 * Buscador con debounce. funciona dentro de asesores
			 */

			let objSearchAsesor = {
				asesor: document.getElementById(`input-asesor-out`).value,
				correo: document.getElementById(`input-correo-out`).value,
				acceso: document.getElementById(`input-acceso-out`).value,
			};

			// reiniciar temporizador si sigue escribiendo
			if(this.timeoutAsesor) clearTimeout( this.timeoutAsesor );
			// controla proceso
			this.timeoutAsesor = setTimeout(() => {
				// realizar busqueda
				let asesoresArray = [...this.asesoresList]; // reinicio para aplicar nueva busqueda

				// console.log( 'busqueda externa', asesoresArray );

				// busqueda 1
				asesoresArray = asesoresArray.filter( (item) => item.NombreEntidad.toLowerCase().includes( objSearchAsesor.asesor.toLowerCase() ));
				// busqueda 2
				asesoresArray = asesoresArray.filter( (item) => item.Email.toLowerCase().includes( objSearchAsesor.correo.toLowerCase() ));
				// busqueda 3
				asesoresArray = asesoresArray.filter( (item) => item.AccesoUrl.toLowerCase().includes( objSearchAsesor.acceso.toLowerCase() ));

				// inyectar la lista de asesores resultante
				this.asesores = asesoresArray;

				// console.log( 'objSearchAsesor: ', objSearchAsesor );
				// console.log( 'AsesorSearch: ', asesoresArray );
				// console.log( 'resultados:', asesoresArray );

				setTimeout(() => {	
					// paginar nuevamente, obtener el value del filtro externo
					let perPage = document.getElementById(`filtro-out-asesor`).value;
					this.paginateAsesor( Number(perPage) );
					// console.log( 'pageSearchAsesor', Number(perPage) );

					// paginar nuevamente para cada asesor con los valores por default.
					if (this.asesores.length) {
						for (let asesor of this.asesores) {
							this.paginateInmo( 10, asesor.Id );
						}
					}
					// console.log( 'CANTIDAD DESUES DE BUSCAR', this.asesores.length );

					// mapear nuevamente las url y los iconos de copy
					this.setBtnCopyUrlAsesorInmoProp();
					this.setUrlAsesorInmoProp();
					// reiniciar tooltip
					this.initTooltip();
				}, 100);

			}, 1000); // si es necesario cambiar el tiempo de busqueda

		},
		paginateAsesor( perPage ) {

			// variables
			let pageItems = document.getElementById(`page-items-out`);
			let pageList = document.getElementById(`accordionAsesores`);
			let listItems = pageList.querySelectorAll('div.accordion-item.asesor-externo');
			let prevBtn = document.getElementById(`prev-btn-out`);
			let nextBtn = document.getElementById(`next-btn-out`);
			// elementos por pagina
			let itemsPerPage = perPage; // valor inicial despues de renderizar y hacer peticion al api
			let numberOfPage = Math.ceil(listItems.length / itemsPerPage);
			let currentPage = 1;


			let disableButton = (button) => {
				button.classList.add("disabled");
				button.setAttribute("disabled", true);
			};
			let enableButton = (button) => {
				button.classList.remove("disabled");
				button.removeAttribute("disabled");
			};
			// cambia el estado de los botones de navegacion
			let changeNavBtnState = () => {
				currentPage === 1 ? disableButton(prevBtn) : enableButton(prevBtn);
				numberOfPage === currentPage ? disableButton(nextBtn) : enableButton(nextBtn)
			};


			// crear numeros de pagina dinamicos
			let addPageNumber = (index) => {
				// crea nueva numeracion
				let numberPage = document.createElement("button");
				numberPage.className = `page-item-out px-4`;
				numberPage.innerHTML = index;
				numberPage.setAttribute(`page-index-out`, index);
				numberPage.setAttribute(`aria-label-out`, "Page " + index);
				pageItems.appendChild(numberPage);
			};
			// pagina activa
			const setActivePage = () => {
				document.querySelectorAll(`.page-item-out`).forEach((button) => {
					button.classList.remove("active-inmo");
					let pageIndex = Number(button.getAttribute(`page-index-out`));

					if (pageIndex == currentPage) {
						button.classList.add("active-inmo");
					}
				});
			};
			// genera y limpia numeracion
			let generatePageNumbers = () => {
				// limpiar numeracion anterior
				pageItems.innerHTML = "";

				for (let i = 1; i <= numberOfPage; i++) {
					addPageNumber(i);
				}
			};

			// para registros
			let prevIdx = 0;
			let nextIdx = 0;
			// establece pagina actual y calcula registros
			let setCurrentPage = (pageNum) => {
				currentPage = pageNum;

				setActivePage();
				changeNavBtnState();
				
				let prevRange = (pageNum - 1) * itemsPerPage;
				let currRange = pageNum * itemsPerPage;


				// asignar idx
				prevIdx = prevRange + 1;
				if (currRange >= listItems.length ) {
					nextIdx = listItems.length;
				} else {
					nextIdx = currRange;
				}
				// calcula registros
				this.calculaRegistroAsesor( listItems.length, prevIdx, nextIdx );
				// console.log( 'calculo de registros asesor terminado!' );


				listItems.forEach((item, index) => {
					item.classList.add("hidden");
					if (index >= prevRange && index < currRange) {
						item.classList.remove("hidden");
					}
				});
			};

			prevBtn.addEventListener("click", () => {
				setCurrentPage(currentPage - 1);
				// console.log( 'atras', currentPage );
			});
			nextBtn.addEventListener("click", () => {
				setCurrentPage(currentPage + 1);
				// console.log( 'adelante', currentPage );
			});

			generatePageNumbers();
			setCurrentPage(1);

			document.querySelectorAll(`.page-item-out`).forEach( (button) => {
				const pageIndex = Number(button.getAttribute(`page-index-out`));

				if (pageIndex) {
					button.addEventListener("click", () => {
						setCurrentPage(pageIndex);
					});
				}
			});

			// valida si hay resultados de promotoras, de lo contrario corrige la paginacion
			if ( !listItems.length ) {
				// disabled page nav buttons
				prevBtn.setAttribute("disabled", true);
				nextBtn.setAttribute("disabled", true);

				// console.log( 'No hay asesores para paginar' );
			}

			// console.log( 'paginacion asesores terminada!' );

		},
		calculaRegistroAsesor( countItems, prevIdx, nextIdx ) {
			
			let countRegisterAsesor = document.getElementById(`count-register-out`);

			if ( countItems > 0) {
				countRegisterAsesor.innerHTML = `
					Mostrando registros del ${ prevIdx } al ${ nextIdx } de un total de ${ countItems } registros
				`;
			} else {
				countRegisterAsesor.innerHTML = `Mostrando 0 registros`;
			}
		},
		getApiKey() {
			if( !JSON.parse(localStorage.getItem('userInfo')).ApiKey ) {
				this.$router.push('/login');
			} else {
				// Informacion para consultas
				this.apiKey = JSON.parse(localStorage.getItem('userInfo')).ApiKey;
				this.TipoPerfilEjecutivo = JSON.parse(localStorage.getItem('userInfo')).TipoPerfilEjecutivo;
			}
		},
		getAsesores() {
			let self = this;

			/**
			 * Asesor | id: 5 | Listo
			 * Operador | id: 3 | Listo
			 * Operador administrador | id: 4 | Listo
			 * Cuenta maestra | id: 1 | Listo
			 * Gerente | id: 2 | Listo
			 */

			let objRequestGetAsesores = {
				strApiKey: this.apiKey, // descomentar
				// strApiKey: '392d766f265ebd5a3aa82a5271cab9bd',
				// strApiKey: '8a6f4fa6d6b706296cba5d80f507df90',
				strMetodo: 'ObtenerBrokersPrecalificador',
				objBroker: {
					Perfil: this.TipoPerfilEjecutivo, // define metodo en api descomentar
					// Perfil: 5, // para pruebas
					// Perfil: 3, // para pruebas
				},
            }

			// consulta api
			this.$axios.post('/', objRequestGetAsesores, 
					{ headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }}
			).then( resp => {

				if (resp.data.intEstatus == 0) {

					let objAsesorArray = resp.data.objContenido;

					if ( typeof(resp.data.objContenido) == 'object' && resp.data.objContenido.length > 0) {
						// shallow copy maneja a 1 nivel, los demas niveles son referenciados
						// this.asesores = [...objAsesorArray]; // funciona a 1 nivel
						// this.asesoresList = [...objAsesorArray]; // funciona a 1 nivel
						
						// clonacion multidimensional 
						this.asesores =  JSON.parse(JSON.stringify( objAsesorArray ));
						this.asesoresList = JSON.parse(JSON.stringify( objAsesorArray ));

						// necesario para busquedas escalonadas
						this.asesoresInmo = JSON.parse(JSON.stringify( objAsesorArray ));
						this.asesoresInmoList = JSON.parse(JSON.stringify( objAsesorArray ));
						
						// console.log( 'getAsesores', objAsesorArray );
					} else {
						return {
							msg: 'sin datos',
							perPage: 0,
						}
					}

					return {
						msg: 'con datos ok,',
						perPage: 10, // valor inicial por defecto
					};
					
				} else {
                    this.$vs.notify({
                        title: 'Ocurrio un error al obtener los asesores',
                        text: resp.data.strError,
                        color: 'danger',
                        position: 'top-right'
                    });
				}

			}).then(
				renderizado => {
					if ( renderizado && renderizado.perPage ) {
						// console.log( renderizado.msg, 'paginando...' );
						// crear la paginacion para cada asesor con los valores por default.
						for (let asesor of this.asesores) {
							this.paginateInmo( renderizado.perPage, asesor.Id );
						}

						// para capa externa si hay mas de un asesor, despues de renderizar asesores
						if (this.asesores.length > 1) {
							this.paginateAsesor( renderizado.perPage ); // por default
						}

						// prop botones y enlaces eviten la propagacion de collapse del accordion
						this.setBtnCopyUrlAsesorInmoProp();
						this.setUrlAsesorInmoProp();
						this.initTooltip();

					} else {
						this.showPageMsgEmpty = true;
						// console.log( renderizado.msg, 'muestra mensaje' );
					}
				}
			)
			.catch(function (error) {
                    self.$vs.notify({
                    title: 'Ocurrio un error de sistema',
                    text: error,
                    color: 'danger',
                    position: 'top-right'
                });
            });
		
		},
		setBtnCopyUrlAsesorInmoProp() {

			const btnCopyUrlAsesor = document.querySelectorAll(`.btnCopyUrlAsesor`);

			btnCopyUrlAsesor.forEach( btn => {
				// quita collapse accordion
				btn.addEventListener('mouseenter', (e) => {
					let accordionSection = btn.parentElement.parentElement;
					accordionSection.setAttribute('data-bs-toggle', '');
				});
				// agrega collapse accordion
				btn.addEventListener('mouseleave', (e) => {
					let accordionSection = btn.parentElement.parentElement;
					accordionSection.setAttribute('data-bs-toggle', 'collapse')
				});
			});
		},
		setUrlAsesorInmoProp() {
			const urlAserorInmo = document.querySelectorAll(`.urlAsesorInmo`);

			urlAserorInmo.forEach( url => {
				// quita collpase accordion
				url.addEventListener('mouseenter', (e) => {
					let accordionSection = url.parentElement.parentElement;
					accordionSection.setAttribute('data-bs-toggle', '');
				});
				// agrega collapse accordion
				url.addEventListener('mouseleave', (e) => {
					let accordionSection = url.parentElement.parentElement;
					accordionSection.setAttribute('data-bs-toggle', 'collapse')
				});
			});
		},
		initTooltip() {
			let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
			let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
				return new bootstrap.Tooltip(tooltipTriggerEl)
			});
		}
	},
	created() {
		this.getApiKey();
		this.getAsesores();
	},
}
</script>

<style>

.temp-input-preca {
	opacity: 0;
}

.hidden {
  display: none;
}
.input-preca-caso{
	width: 25%;
	padding: 7px;
    border-radius: 5px;
    border: 1px solid #00000033;
    box-shadow: 0 0 0 0 rgb(0 0 0 / 15%);
    --webkit-transition: all .3s ease;
}
.label-preca-caso{
	font-weight:600;
}
.bg-preca-acord-asesor{
	background-color: #006d4e;
}
.dato-acord-asesor{
	color:#ffffff;
}
.accordion-button-externo:not(.collapsed){
	background-color: #006d4e !important;
}
.accordion-button-interno:not(.collapsed){
	background-color: #f8f9fa !important;
}
.ancho-preca-dato{
	width: 25%!important;
}
.ancho-preca-icono{
	width: 10% !important;
}
.ancho-preca-mail{
	width: 40% !important;
}
.ancho-preca-ejecutivo{
	width: 20%;
}
.text-link-inmo-interno{
	color: #079DEF;
	text-decoration: underline;
}
.accordion-button-interno {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #000;
    text-align: left;
    background-color: #f8f9fa;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
	font-family: inherit;
	border: solid 1px #000;
}
.active-inmo{
	border: none !important;
	background-color: #079DEF !important;
	color: #ffffff !important;
}
.pag-preca-selectt{
	border: solid 2px #079DEF;
	background-color: #fff;
	color: #079DEF;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #4ED176;
  border-radius: 0.25rem;
  opacity: 1;
}
.tooltip.show {
  opacity: 1;
}

.input-preca-caso-interno{
	width: 20%;
	padding: 7px;
    border-radius: 5px;
    border: 1px solid #00000033;
    box-shadow: 0 0 0 0 rgb(0 0 0 / 15%);
    --webkit-transition: all .3s ease;
}
.ancho-interno-acor{
	width: 22%;
	word-break: break-all;
}
.ancho-interno{
	width: 20%;
	word-break: break-all;
}
.ancho-interno-acor-icono{
	width: 23%;
	word-break: break-all;
}
.collapse{
	margin-left: 0 !important;
}
.precalificador-url-broker {
	word-break: break-all;
}
</style>