<template>
<div class="">
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="credi" role="tabpanel" aria-labelledby="credi-tab">
    <div class="tab-body">
     <div class="accordion" id="accordionExample">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button"  type="button" data-bs-toggle="collapse" ref="collapseCredito" data-bs-target="#collapseCredito" aria-expanded="true" id="apartadoCredito" v-bind:style="[creditoCompleto ? { backgroundColor: '#54be16 !important'}:{ backgroundColor: '#FEE94E   !important'}]" aria-controls="collapseCredito">
                Crédito
            </button>
            </h2>
            <div id="collapseCredito" class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body ">
                <Credito v-on:updateValores="update($event)"/>
            </div>
            </div>
        </div>    
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"  aria-expanded="false" aria-controls="collapseOne" v-bind:style="[personalesCompleto ? { backgroundColor: '#54be16 !important'}:{ backgroundColor: '#FEE94E !important'}]">
                Personales
            </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body">
                <Personales v-on:updateValores="update($event)"/>
            </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" v-bind:style="[domicilioCompleto ? { backgroundColor: '#54be16 !important'}:{ backgroundColor: '#FEE94E !important'}]">
                Domicilio
            </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div class="accordion-body">
               <Domicilio v-on:updateValores="update($event)"/>
            </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" v-bind:style="[ingresosCompleto ? { backgroundColor: '#54be16 !important'}:{ backgroundColor: '#FEE94E !important'}]">
                Ingresos
            </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div class="accordion-body">
               <Ingresos v-on:updateValores="update($event)"/>
            </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" v-bind:style="[reporteCompleto ? { backgroundColor: '#54be16 !important'}:{ backgroundColor: '#FEE94E !important'}]">
                Reportes de crédito
            </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <ReporteCredito v-on:updateValores="update($event)"/>
            </div>
            </div>
        </div>
        
        <div class="row w-full"> 
          <vs-button color="primary" type="border" style="margin-left: auto;
  margin-right: auto;"  class="col-md-2" @click="regstraDatos()"  v-if="muestraBoton" >Guardar</vs-button>
        </div>
        </div>
    </div>  
  </div>
</div>

</div>
</template>

<script>
import Personales from '@/views/Caso/Santander/Personales.vue'
import Ingresos from '@/views/Caso/Santander/Ingresos.vue'
import Domicilio from '@/views/Caso/Santander/Domicilio.vue'
import Credito from '@/views/Caso/Santander/Credito.vue'
import ReporteCredito from '@/views/Caso/Santander/ReporteCrediticio.vue'
import { mapState, mapMutations } from 'vuex'

export default {
  components: {
    Personales,
    Ingresos,
    Domicilio,
    Credito,
    ReporteCredito
  },
  data () {
    return {
      tab:1,
      validaCreditoIndividual:false,
      credito:[],
      reporte:[],
      domicilio:[],
      ingresos:[],
      personales:[],
      Ine:[],
      id:'',
      autorizacionBuro:[],
      email:'',
      muestraBoton:false,
      solicitudes:[],
      creditoCompleto:false,
      personalesCompleto:false,
      domicilioCompleto:false,
      ingresosCompleto:false,
      reporteCompleto:false,
      validaSelect:false
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    this.dameCaso()
    //this.update()
  },  
  computed: {
    ...mapState(['existeProductoSantander']),
    isSmallerScreen () {
      return window.screen.width < 768
    },
  },
  methods:{
    ...mapMutations(['updateExisteProductoSantander']),
    quitarAcentos(s){
      if(s == null || s.trim().length() == 0) {
          return "";
      }
      return Normalizer.normalize(s, Normalizer.Form.NFD).replaceAll("[\u0300-\u0301]", "");
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    regstraDatos(){
       let self=this
        var objeRegistraDatosSantander={
          strApiKey: this.key,
          strMetodo: "RegistraPrecaBancoSantander",
          objPrecalificacionBanco:
          {
              IdPrecalificacion: this.id,
              FechaNacimiento:this.personales.fechaNacimiento,
              EstadoCivil:this.personales.estadoCivil,
              RegimenMatrimonial:this.personales.regimenMatrimonial,
              DependientesEconomicos:this.personales.dependientes,
              AgregarParticipante:this.personales.participa,
              ParticipaIngresos:this.personales.participaIngresos,
              FechaIngresoAsalariado:this.ingresos.fechaInicioAsalariado,
              FechaInicioNoAsalariado:this.ingresos.fechaInicio,
              FechaInicioEmpleoAnterior:this.ingresos.inicioAnterior,
              FechaFinEmpleoAnterior:this.ingresos.salidaAnterior,
              DescripcionActividades:this.ingresos.funciones,
              SueldoHonorarios:this.ingresos.sueldoHonorarios,
              BonosVales:this.ingresos.bonoVales,
              Comisiones:this.ingresos.comisiones,
              PromedioMensual:this.ingresos.promedioMensual,
              HonorariosProfesionales:this.ingresos.honorariosProfesionales,
              RetiroAnticipoUtilidades:this.ingresos.retiroUtilidades,
              VentasAnuales:this.ingresos.ventas,
              UtilidadOperacion:this.ingresos.utilidadOperacion,
              TiempoResidencia:this.domicilio.yearsResidencia,
              MontoCreditoINFONAVIT:this.credito.montoCreditoInfonavit,
              SaldoSubcuenta:this.credito.saldoSubcuenta,
              DescuentoMensual:this.credito.descuentoMensual,
              MontoCreditoConyuge:this.credito.montoCreditoConyuge,
              SaldoSubcuentaConyuge:this.credito.saldoSubcuentaConyuge,
              DescuentoMensualConyuge:this.credito.descuentoMensualConyuge,
              SaldoActual:this.credito.saldoActual,
              TarjetaCredito:this.reporte.tarjeta,
              UltimosDigitosTarjeta:this.reporte.digitos,
              CreditoHipotecarioVigente:this.reporte.creditoHipotecario,
              CreditoAutomotrizVigente:this.reporte.creditoAuto,
              SantanderExCompaniaTelefonica:this.personales.companiaMovil,
              SantanderExFormaMigratoria:this.personales.formaMigratoria,
              SantanderExParentesco:this.personales.parentesco,
              SantanderExTipoIngresos:this.ingresos.tipoEmpleo,
              SantanderExEscolaridad:this.ingresos.gradoEstudios,
              SantanderExOcupacion:this.ingresos.ocupacion,
              SantanderExSectorLaboral:this.ingresos.sector,
              //SantanderExViveCasa:this.domicilio.tipoCasa,
              SantanderExProducto:this.credito.producto,
              SantanderExTipoLiquidez:this.credito.tipoLiquidez,
              SantanderExTipoCofinanciamiento:this.credito.cofinanciamiento,
              SantanderExDestinoInmueble:this.credito.destino,
              SantanderExTipoConsulta:this.reporte.tipoConsulta,
              TiempoResidenciaMeses:this.domicilio.monthResidencia,
              EMail:this.personales.email,
               AutorizacionConsultaBuroCredito:this.autorizacionBuro,
              IdentificacionOficial:this.Ine,
          }
        }
         this.$axios.post('/',objeRegistraDatosSantander,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            //console.log(response.data.objContenido)
            this.registraSolicitante()
            /*let conyuge =response.data.objContenido
            this.conyuge.nombre= conyuge.Nombres
            this.conyuge.email= conyuge.EMail
            this.conyuge.apellidoPaterno= conyuge.ApellidoPaterno
            this.conyuge.apellidoMaterno= conyuge.ApellidoMaterno
            this.conyuge.rfc=conyuge.Rfc
            this.conyuge.curp = conyuge.Curp*/
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraPrecaBancoSantander',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
         self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    changeTab(id){
      this.tab=id
    },
    tipoCasaComparador(tipoCasas){
      //1 rentado 51
      //2 propio sin hipoteca 52
      //3 propio hipotecado 53
      //4 de familiares 54
      //5 prestado
      //6 otro
      switch(tipoCasas) {
        case 51:
          return 1
        case 53:
          return 3
        case 52:
          return 2
        case 54:
          return 4
        default:
          return 0
      }
    },
    update(datos){ 
      /*var campos, valido ,selects,textAreas
      campos =document.querySelectorAll('input')
      selects=document.querySelectorAll('select')
      textAreas=document.querySelectorAll('textarea')
      
      this.validaSelect=true //vacio 
      for(var j=0;j<selects.length;j++){
        if(selects[j].value=='' ||selects[j].value==0){
          this.validaSelect=true //vacio
          
        }else{
          this.validaSelect=false // por lo menos un campo lleno
          break;
        }
      }
      this.validaInputs=true //vacio 
      for(var j=0;j<campos.length;j++){
        if(campos[j].value=='' ||campos[j].value=='false'||campos[j].value==0.00){
          this.validaInputs=true //vacio
          
        }else{
          this.validaInputs=false // por lo menos un campo lleno
          break;
        }
      }
      this.validatextArea=true //vacio 
      for(var j=0;j<textAreas.length;j++){
        if(textAreas[j].value=='' ){
          this.validatextArea=true //vacio
          
        }else{
          this.validatextArea=false // por lo menos un campo lleno
          break;
        }
      }*/
      /*if(this.validatextArea && this.validaInputs && this.validaSelect){
         localStorage.setItem("validaCamposVacios", true)
        //this.$emit('validaCamposVacios', true)
      }else{
        localStorage.setItem("validaCamposVacios", false)
        //this.$emit('validaCamposVacios', false)
      }*/
      if(datos.validaCredito){
        this.credito.producto=datos.producto//no
        this.credito.tipoLiquidez=datos.tipoLiquidez//no
        this.credito.cofinanciamiento=datos.cofinanciamiento//no
        this.credito.destino=datos.destino//no
        this.credito.montoCreditoInfonavit=datos.montoCreditoInfonavit
        this.credito.saldoSubcuenta=datos.saldoSubcuenta
        this.credito.descuentoMensual=datos.descuentoMensual
        this.credito.montoCreditoConyuge=datos.conyuge.montoCredito
        this.credito.saldoSubcuentaConyuge=datos.conyuge.saldoSubcuenta
        this.credito.descuentoMensualConyuge=datos.conyuge.descuentoMensual
        this.credito.saldoActual=datos.saldoActual
        this.credito.validaCredito=datos.validaCredito
        this.creditoCompleto=datos.validaCredito
       // document.getElementsByName("#collapseCredito").style.backgroundColor='#f00 !important'
       //document.querySelectorAll('[data-bs-target="#collapseCredito"]').style.backgroundColor='#f00 !important'
       // elemento.style.background-color = "#0000FF";
        //document.getElementsByClassName('button.accordion-button').style.backgroundColor('rgb(84, 190, 22) !important')
      }else if(datos.validaCredito===false){
        this.creditoCompleto=false
      }
      if(datos.validaDomicilio){
        this.domicilio.calle=datos.calle.trim().toUpperCase()//si 12
        this.domicilio.numeroExterior=datos.numeroExterior//si 13
        this.domicilio.numeroInterior=datos.numeroInterior//si 14
        this.domicilio.codigoPostal=datos.codigoPostal//si 15
        this.domicilio.colonia=datos.colonia//si 16
        this.domicilio.municipio=datos.municipio//si 17
        this.domicilio.yearsResidencia=datos.yearsResidencia
        this.domicilio.monthResidencia=datos.monthResidencia
        this.domicilio.telefonoFijo=datos.telefonoFijo//si 18
        this.domicilio.tipoCasa=this.tipoCasaComparador(datos.tipoCasa)//si 19
        this.domicilio.validaDomicilio=datos.validaDomicilio
        this.domicilioCompleto=datos.validaDomicilio
      }else if( datos.validaDomicilio===false){
        this.domicilioCompleto=false
      }
      if(datos.validaIngresos){
        this.ingresos.tipoEmpleo=datos.tipoEmpleo//no
        this.ingresos.gradoEstudios=datos.gradoEstudios//no
        this.ingresos.ocupacion=datos.ocupacion//no
        this.ingresos.fechaInicioAsalariado=datos.fechaInicioAsalariado
        this.ingresos.fechaInicio=datos.fechaInicio
        this.ingresos.inicioAnterior=datos.inicioAnterior
        this.ingresos.salidaAnterior=datos.salidaAnterior
        this.ingresos.sector=datos.sector//no
        this.ingresos.funciones=datos.funciones
        if(datos.sueldoHonorarios.length>0){
         this.ingresos.sueldoHonorarios=datos.sueldoHonorarios.replace(/,/g,"")
        }
        if(datos.bonoVales.length>0){
          this.ingresos.bonoVales=datos.bonoVales.replace(/,/g,"")
        }
        if(datos.comisiones.length>0){
          this.ingresos.comisiones=datos.comisiones.replace(/,/g,"")
        }
        if(datos.promedioMensual.length>0){
          this.ingresos.promedioMensual=datos.promedioMensual.replace(/,/g,"")
        }
        if(datos.honorariosProfesionales.length>0){
          this.ingresos.honorariosProfesionales=datos.honorariosProfesionales.replace(/,/g,"")
        }
        if(datos.retiroUtilidades.length>0){
         this.ingresos.retiroUtilidades=datos.retiroUtilidades.replace(/,/g,"")
        }
        if(datos.ventas.length>0){
          this.ingresos.ventas=datos.ventas.replace(/,/g,"")
        }
        if(datos.utilidadOperacion.length>0){
          this.ingresos.utilidadOperacion=datos.utilidadOperacion.replace(/,/g,"")
        }
        this.ingresos.validaIngresos=datos.validaIngresos
        this.ingresosCompleto=datos.validaIngresos
      }else if(datos.validaIngresos===false){
        this.ingresosCompleto=datos.validaIngresos
      }
      if(datos.validaFormMexicano){
        this.personales.parentesco=datos.parentesco//no
        this.personales.participa=datos.participa//si
        this.personales.participaIngresos=datos.participaIngresos//si
        this.personales.dependientes=datos.dependientes//si
        this.personales.formaMigratoria=datos.formaMigratoria//no
        this.personales.companiaMovil=datos.companiaMovil//no
        this.personales.email=datos.email//no
        this.personales.nombre=datos.nombre.trim().toUpperCase()//si 1
        this.personales.apellidoMaterno=datos.apellidoMaterno.trim().toUpperCase()//si 3
        this.personales.apellidoPaterno=datos.apellidoPaterno.trim().toUpperCase()//si 2
        this.personales.telefono=datos.telefono//si 10
        this.personales.fechaNacimiento=datos.fechaNacimiento//si
        this.personales.nacionalidad=datos.nacionalidad//si 4,5
        this.personales.estadoNacimiento=datos.estadoNacimiento//si 6
        this.personales.sexo=datos.sexo//si 7
        this.personales.estadoCivil=datos.estadoCivil//si
        this.personales.regimenMatrimonial=datos.regimenMatrimonial//si
        this.personales.rfc=datos.rfc//rfc 8
        this.personales.curp=datos.curp//curp 9
        this.personales.validaFormMexicano=datos.validaFormMexicano
        this.personalesCompleto=datos.validaFormMexicano
      }else if(datos.validaFormMexicano===false){
        this.personalesCompleto=datos.validaFormMexicano
      }
      if(datos.validaReporteCrediticio){
        this.reporte.tarjeta=datos.tarjeta
        this.reporte.digitos=datos.digitos
        this.reporte.creditoHipotecario=datos.creditoHipotecario
        this.reporte.creditoAuto=datos.creditoAuto
        this.reporte.tipoConsulta=datos.tipoConsulta//no
        this.reporte.autorizacionCredito=datos.autorizacionCredito
        this.reporte.identificacion=datos.identificacion
        this.reporte.validaReporteCrediticio=datos.validaReporteCrediticio
        if(datos.identificacion){
          this.Ine.push({
              FileName:datos.identificacion.name,
              DocumentType: 2,
              File: datos.identificacion.base64
            })
        } 
        if(datos.autorizacionCredito){
          this.autorizacionBuro.push({
            FileName:datos.identificacion.name,
              DocumentType: 2,
              File: datos.identificacion.base64
          }) 
        } 
        this.reporteCompleto=datos.validaReporteCrediticio
      }else if(datos.validaReporteCrediticio==false){
        this.reporteCompleto=datos.validaReporteCrediticio
      }

      if(this.credito.validaCredito && this.personales.validaFormMexicano && this.domicilio.validaDomicilio && this.ingresos.validaIngresos && this.reporte.validaReporteCrediticio){
        this.muestraBoton=true
      }else{
        this.muestraBoton=false
      }
    },
    registraSolicitante(){
      let self=this
      /*this.$vs.loading({
        container: '#main',
        scale: 0.6
      })*/
      let sexo
      if(this.personales.sexo== 'H'){
        sexo=1
      }else{
        sexo=2
      }
      let objRequestRegistraSolicitante = {
        strApiKey: this.key,
        strMetodo: 'RegistraSolicitante',
        objSolicitante: {
          EMail: this.email,
          Nombres: this.personales.nombre,//si
          ApellidoPaterno: this.personales.apellidoPaterno,//si
          ApellidoMaterno: this.personales.apellidoMaterno,//si
          TelefonoCelular: this.personales.telefono,//si
          MunicipioNacimiento: this.personales.lugarNacimiento,//si
          Rfc: this.personales.rfc,//si
          Curp: this.personales.curp,//si
      		Sexo:sexo,//si
      		Calle:this.domicilio.calle,//si
      		NumeroExterior:this.domicilio.numeroExterior,//si
          NumeroInterior:this.domicilio.numeroInterior,//si
      		Colonia:this.domicilio.colonia,//si
      		Municipio:this.domicilio.municipio,//si
      		CodigoPostal:this.domicilio.codigoPostal,//si
          Nacionalidad:this.personales.nacionalidad,//si
          TelefonoCasa: this.domicilio.telefonoFijo,//si
        }
      }
      this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.registraAdicionales()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }

      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })

    },
    registraAdicionales(){
      let self=this
      var objRequestAdicionales = {
        strApiKey: this.key,
        strMetodo: 'RegistraAdicionalesSolicitud',
        objCaso: {
         Id: this.id,
          Nacionalidad:{
            EstadoNacimiento:this.personales.estadoNacimiento
          },
          DomicilioActual:{
            TitularidadDomicilio:this.domicilio.tipoCasa,
          },
        }
     }
      this.$axios.post('/',objRequestAdicionales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Guardardo exitosamente`,
              position:'top-right'
               })
            //this.dameCaso()
            //this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraAdicionalesSolicitud',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let cotizacion=response.data.objContenido;
            this.email= cotizacion.Solicitante
            this.solicitudes=cotizacion.Solicitudes
            for (var i = 0; i < this.solicitudes.length; i++) {
              this.solicitudes[i].Resultado=this.solicitudes[i].Resultado
              this.solicitudes[i].IdCaso=this.solicitudes[i].Caso
              this.solicitudes[i].IdBanco=this.solicitudes[i].IdBanco
              this.solicitudes[i].MensualidadPromedio= this.solicitudes[i].MensualidadPromedio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.solicitudes[i].Cat= this.solicitudes[i].Cat.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              if( this.solicitudes[i].IdBanco==10){
              this.$store.commit('UPDATE_EXISTE_PRODUCTO_SANTANSER',true)
              //this.existenSolicitados=true
              }else{
                this.$store.commit('UPDATE_EXISTE_PRODUCTO_SANTANSER',false)
              }
            }
            
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
.formularioCompleto{
    background-color: rgb(84, 190, 22) !important;
    color: white !important;
        width: 100%;
        text-align: left;
        border: 0;
    border-radius: 0;
    overflow-anchor: none;
    position: relative;
    display: flex;
    align-items: center;
}
</style>
<style scoped>
 
</style>
