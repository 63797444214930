import { render, staticRenderFns } from "./Dependientes.vue?vue&type=template&id=67962ecd&scoped=true&lang=html&"
import script from "./Dependientes.vue?vue&type=script&lang=js&"
export * from "./Dependientes.vue?vue&type=script&lang=js&"
import style0 from "./Dependientes.vue?vue&type=style&index=0&id=67962ecd&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67962ecd",
  null
  
)

export default component.exports