<template>
	<div class="router-view">
		<div class="router-content">
			<!--<div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">-->
			<!--<div
                  class="content-area__heading"
                  :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>-->

			<!-- BREADCRUMB -->
			<!--<vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl" />-->

			<!-- DROPDOWN -->
			<!--<vs-dropdown vs-trigger-click class="ml-auto md:block hidden cursor-pointer">
                  <vs-button radius icon="icon-settings" icon-pack="feather" />

                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item>
                      <div @click="$router.push('/pages/profile').catch(() => {})" class="flex items-center">
                        <feather-icon icon="UserIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Profile</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/todo').catch(() => {})" class="flex items-center">
                        <feather-icon icon="CheckSquareIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Tasks</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/email').catch(() => {})" class="flex items-center">
                        <feather-icon icon="MailIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Inbox</span>
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>

                </vs-dropdown>-->

			<!--</div>-->
			<div class="title-bar">
				<h4 class="" style="color: #015694; font-weight: 600">Precalificación Bancaria</h4>
			</div>
			<ul class="nav nav-tabs" id="myTab" role="tablist">
				<li class="nav-item" role="presentation">
					<button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Nueva Precalificación</button>
				</li>
				<li class="nav-item" role="presentation">
					<button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Historial Precalificaciónes</button>
				</li>
			</ul>

			<div class="tab-content" id="myTabContent">
				<div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab">
					<div title="" class="tab-body">
						<div class="row w-full">
							<div>
								<Personales v-on:bandera="update($event)" />
							</div>
						</div>
					</div>
				</div>
				<div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab">
					<div title="" class="tab-body">
						<div class="row w-full">
							<div>
								<Historiales v-on:bandera="update($event)" :ActualizaTable="banderaTabla" />
							</div>
						</div>
					</div>
				</div>
				<!--<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
          <div title="" class="tab-body">
            <div class="row w-full">
              <div>
                  <ReporteCredito :emailN="this.mailCasoNuevo" :IdN="this.IdCasoNuevo"  v-on:bandera="update($event)"/>
              </div>
            </div>
          </div>
        </div>-->
			</div>
		</div>
	</div>
</template>



<script>
import TheNavbarVerticalPerfil from '@/layouts/components/navbar/TheNavbarVerticalPerfil.vue'
import Personales from '@/views/Caso/PrecalificacionesBancarias/PersonalesPrecalificacionScotia.vue'
import Historiales from '@/views/Caso/PrecalificacionesBancarias/HistorialPrecalificaciones.vue'

export default {
	components: {
		TheNavbarVerticalPerfil,
		Personales,
		Historiales,
	},
	data() {
		return {
			id: '',
			key: '',
			consulta: false,
			tab: 1,
			userInfo: '',
			solicidadoSantander: false,
			solicitudes: [],
			camposVaciosSantanderExpress: false,
			banderaStatus: '',
			mailCasoNuevo: '',
			telCasoNuevo: '',
			IdCasoNuevo: '',
			banderaTabla: '',
		}
	},
	mounted() {
		if (!this.$route.params.id) {
		} else {
			if (this.$store.state.AppActiveUser.ApiKey) {
				this.id = this.$route.params.id
				this.getApikey()
				this.consultarCaso()
				if (this.$store.state.AppActiveUser.WidgetColorPrimario) {
					this.$vs.theme({
						primary: this.$store.state.AppActiveUser.WidgetColorPrimario,
					})
				}
			} else {
				this.$router.push('/login').catch(() => {})
			}
			this.dameCaso()
		}
	},
	computed: {
		socData() {
			//return this.$store.state.socData
			return JSON.parse(localStorage.getItem('socData'))

			/* if(JSON.parse(localStorage.getItem('socData')) && JSON.parse(localStorage.getItem('userInfo')).VentaCruzadaHipotecario == 1){
          return JSON.parse(localStorage.getItem('socData'))
      }*/
		},
		/*solicidadoSantander(){
      return this.$store.state.existeProductoSantander
      //return JSON.parse(localStorage.getItem('socData'))
    }*/
	},
	watch: {
		solicidadoSantander: function () {
			this.solicidadoSantander = this.$store.state.existeProductoSantander
		},
	},
	methods: {
		update(val) {
			this.banderaStatus = val

			if (this.banderaStatus == 'Personales') {
				let elemento = document.getElementById('home-tab')
				elemento.classList.remove('active')
				let elementoTabCont = document.getElementById('home-tab-pane')
				elementoTabCont.classList.remove('show')
				elementoTabCont.classList.remove('active')
				elemento.setAttribute('aria-selected', 'false')

				let elemento2 = document.getElementById('profile-tab')
				elemento2.setAttribute('aria-selected', 'true')
				elemento2.classList.add('active')
				let elementoTabCont2 = document.getElementById('profile-tab-pane')
				elementoTabCont2.classList.add('show')
				elementoTabCont2.classList.add('active')

				this.banderaTabla = 'actualiza'
			}

			// alert('prueba1'+this.banderaStatus)
		},
		consultarCaso() {
			let self = this
			var objRequestAutentica = {
				strApiKey: this.key,
				strMetodo: 'ConsultarCaso',
				objCaso: {
					EMail: this.$store.state.AppActiveUser.EMail,
					Id: this.id,
				},
			}
			this.$axios
				.post('/', objRequestAutentica, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						if (!response.data.Consultar) {
							this.$router.push('/')
						} else {
							this.cambiarStatus()
							this.consulta = true
						}
					} else {
						this.$router.push('/')
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		cambiarStatus() {
			let self = this
			var objRequestCambiarStatus = {
				strApiKey: this.key,
				strMetodo: 'ActualizaStatusCaso',
				objCaso: {
					Id: this.id,
					StatusCaso: 'Abierto',
				},
			}
			this.$axios
				.post('/', objRequestCambiarStatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getApikey() {
			if (!JSON.parse(localStorage.getItem('userInfo')).ApiKey) {
				this.$router.push('/login')
			} else {
				this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey
			}
		},
		changeTab(id) {
			this.tab = id
		},
		dameCaso() {
			let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'DameCaso',
				objCaso: {
					Id: this.id,
				},
			}
			this.$axios
				.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						let cotizacion = response.data.objContenido
						this.solicitudes = cotizacion.Solicitudes
						for (var i = 0; i < this.solicitudes.length; i++) {
							this.solicitudes[i].Resultado = this.solicitudes[i].Resultado
							this.solicitudes[i].IdCaso = this.solicitudes[i].Caso
							this.solicitudes[i].IdBanco = this.solicitudes[i].IdBanco
							this.solicitudes[i].MensualidadPromedio = this.solicitudes[i].MensualidadPromedio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
							this.solicitudes[i].Cat = this.solicitudes[i].Cat.toFixed(2)
								.toString()
								.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
							if (this.solicitudes[i].IdBanco == 10) {
								this.$store.commit('UPDATE_EXISTE_PRODUCTO_SANTANSER', true)
								this.solicidadoSantander = true
							}
						}
						if (this.solicitudes.length == 0) {
							this.$store.commit('UPDATE_EXISTE_PRODUCTO_SANTANSER', false)
							this.solicidadoSantander = false
						}
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameCaso 1',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		existeSolicitadoSantander(value) {
			this.solicidadoSantander = value
		},
		/*validaCamposVacios(value){
      this.camposVaciosSantanderExpress=value
    }*/
	},
}
</script>

<style lang="css" scoped>
.vs-tabs-position-left .vs-tabs--ul {
	background: #015694 !important;
	box-shadow: none !important;
	border: none !important;
}
.wrap-all-the-things {
	padding-top: 100px;
}
.nav-tabs .nav-link.active {
	text-decoration: underline;
}

@media screen and (max-width: 896px) {
	.wrap-all-the-things {
		padding-top: 100px;
	}
}
@media screen and (max-width: 768px) {
	.wrap-all-the-things {
		padding-top: 150px;
	}
}
</style>
