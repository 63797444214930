export const actividades = [
  {Id:'AMAC', Nombre: "AMA DE CASA"},
  {Id:'DESE', Nombre: "DESEMPLEADO CON INGRESOS"},
  {Id:'ESTU', Nombre: "ESTUDIANTE"},
  {Id:'INVE', Nombre: "INVERSIONISTA / ARRENDATARIO"},
  {Id:'JUBI', Nombre: "JUBILADO"},
  {Id:'MEDI', Nombre: "MEDICO"},
  {Id:'OTRO', Nombre: "OTRO"},
  {Id:'PROF', Nombre: "PROFESIONISTA / INDEPENDIENTE"},
  {Id:'TAXI', Nombre: "TAXISTA"},
  {Id:'VTAS', Nombre: "COMISIONISTA / VENTAS"},
]
