<template lang="html">
    <div class="">
      <div class="">
        <vx-card title="Datos del asegurado" class="">
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Peso en kilogramos</span>
              <input class="vs-input" label="Peso en kilogramos" v-model="peso" name="name" type="number"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Estatura en centímetros</span>
              <input class="vs-input" label="Estatura en centímetros" v-model="estatura" name="name"/>
            </div>
          </div>
          <br>
          <div class="row w-full">
            <div class="col-md-6" v-for="(padecimiento, index) in padecimientosOpciones" :key="index" vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-sm="12" vs-xs="12">
              <vs-checkbox v-model="padecimientos" :vs-value="padecimiento.Id" >{{ padecimiento.Nombre }}</vs-checkbox>
            </div>
          </div>

          <div class="row w-full" v-if="isActiveContentCiti">
            <span class="mb-4">REFERENCIA APLICADA ÚNICAMENTE PARA EL BANCO CITIBANAMEX.</span>
            <div class="col-md-8 col-lg-6 text-center mb-4">
              <span class="span-placeholder" for="tipoSeguro">Seguro de vida</span>
              <div class="row">
                <div class="col-md-6">
                  <vs-radio v-model="tipoSeguro" vs-name="tipoSeguro" vs-value="Insoluto"></vs-radio>
                  <span class="span-placeholder" for="Insoluto">Saldo Insoluto</span>
                </div>
                <div class="col-md-6">
                  <vs-radio v-model="tipoSeguro" vs-name="tipoSeguro" vs-value="Original"></vs-radio>
                  <span class="span-placeholder" for="Original">Monto Original</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder" for="">Incluir seguro por daños</span>
              <vs-switch v-model="seguroDanos" />
            </div>
          </div>

          <hr>

          <button class="btn btn-second" @click="registraAseguradora()">Guardar Selección</button>
        </vx-card>
      </div>
    </div>
  
</template>

<script>
export default {
  data(){
		return{
      key:'',
      id:'',
      peso:'',
      estatura:'',
      padecimientos:[],
      padecimientosOpciones: [],
      tipoSeguro: '',
      seguroDanos: false,
      isActiveContentCiti: false
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    this.listaEnfermedades()
    this.dameCaso()
  },
  methods:{
    registraAseguradora(){
      let self= this
      let enfermedades=[]
      for (var i = 0; i < this.padecimientos.length; i++) {
        enfermedades.push({"Concepto":this.padecimientos[i]})
      }
      var objRequestRegistraCuentasBancarias = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'RegistraAseguradora',
        objSolicitante:{
          EMail:this.email,
          Peso:this.peso,
          Estatura:this.estatura,
          DatosAseguradora:enfermedades,
          TipoSeguro: this.tipoSeguro,
          SeguroDanos: this.seguroDanos,
          IdCaso: this.id
        }
      }

      this.$axios.post('/',objRequestRegistraCuentasBancarias,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraAseguradora',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    listaEnfermedades(){
      let self= this
      var objRequestListaEnfermedades = {
        strApiKey: this.key,
        strMetodo: 'listaConceptosAseguradora',
      }
      this.$axios.post('/',objRequestListaEnfermedades,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.padecimientosOpciones=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en listaConceptosAseguradora',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
          Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.email= response.data.objContenido.Solicitante

            const dataCaso = response.data.objContenido
            const bancos = dataCaso.ComparaBancos

            // console.log(dataCaso)

            // CITIBANAMEX
            const isCitibanamex = bancos.includes('2')
            if ( isCitibanamex ) {

              this.tipoSeguro = dataCaso.TipoSeguro
              this.seguroDanos = dataCaso.SeguroDanos
              this.isActiveContentCiti = true

            }

            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
          EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.peso=response.data.objContenido.Peso
            this.estatura=response.data.objContenido.Estatura
            for (var i = 0; i < response.data.objContenido.DatosAseguradora.length; i++) {
              this.padecimientos.push(response.data.objContenido.DatosAseguradora[i].Id)
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
  }
}
</script>
<style scoped>
.con-vs-checkbox {
    position: relative;
    /* display: block; */
    /* display: -webkit-box; */
    display: -ms-flexbox;
    display: -webkit-inline-box;
    /* -webkit-box-align: center; */
    -ms-flex-align: center;
    align-items: center;
    /* -webkit-box-pack: center; */
    -ms-flex-pack: center;
    /* justify-content: center; */
    margin-left: 5px;
    margin-right: 5px;
    text-transform: lowercase;
}
.vs-checkbox--input {
    position: absolute;
    width: 20px;
    height: 20px;
    opacity: 0;
    z-index: 200;
    cursor: pointer;
    background: #55d775;
    text-transform: lowercase;
}
span.con-slot-label {
    text-transform: lowercase !important;
    font-size: 14px;
}
</style>
