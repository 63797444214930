<template lang="html">
  <div id="container">
    <div class="vx-row">
      <div class="vx-col w-full">
        <vx-card title="Scotiabank" class="mb-base">
					<div class="row d-flex justify-content-end">
						<div class="col-6 col-xl-3 text-end">
							<button class="btn btn-second btn-large" @click="ActiveForm()" :disabled="disabledFlag">{{ txtButtonProgreso }}</button>
							<p style="color:red" v-show="disabledFlag">*Es importante guardar la información del coacreditado primero</p>
						</div>
						<div class="col-6 col-xl-3">
							<vs-button class="btn btn-blue btn-large" @click="getCaso()" :disabled="activePrecaExterna">Mandar precalificación</vs-button>
						</div>
					</div>

          <div class="row w-full">
            <h5>Personas politicamente expuestas</h5>
            <label class="span-placeholder" for="bienes">¿Es usted o fue una PPE?</label>
            <div class="col-md-4 alinear">
              <div class="col-md-6">
                <vs-radio class="span-placeholder" v-model="ppe" vs-name="ppe" vs-value='N' :disabled="isDisabledForm">No</vs-radio>
              </div>
              <div class="col-md-6">
                <vs-radio v-model="ppe" vs-name="ppe" vs-value='S' :disabled="isDisabledForm">Sí</vs-radio>
              </div>
            </div>
          </div>
          <div class="row w-full">
            <h5>Relacionados personas politicamente expuesta</h5>
            <p>(Considerar hasta 2do grado de consanguineidad o afinidad)</p>
            <label class="span-placeholder" for="bienes">¿Usted tiene un parentesco con una PPE? </label>
            <div class="col-md-4 alinear">
              <div class="col-md-6">
                <vs-radio v-model="parentescoppe" vs-name="Parentesco" vs-value='N' :disabled="isDisabledForm">No</vs-radio>
              </div>
              <div class="col-md-6">
                <vs-radio v-model="parentescoppe" vs-name="Parentesco" vs-value='S' :disabled="isDisabledForm">Sí</vs-radio>
              </div>
            </div>
          </div>
          <br>
          <h4 class="mt-4">Datos personales</h4>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Email</span>
              <vs-input class="" :disabled="isDisabledForm" v-model="email" name="email" readonly />
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Nombre(s)</span>
              <vs-input v-validate="'required|alpha_spaces'" :disabled="isDisabledForm" v-model="nombre" name="nombres" />
              <span class="text-danger text-sm" v-show="errors.has('nombres')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Apellido Paterno</span>
              <vs-input class="" v-validate="'required|alpha_spaces'" :disabled="isDisabledForm" v-model="apellidoPaterno"
                name="apellidoPaterno" />
              <span class="text-danger text-sm" v-show="errors.has('apellidoPaterno')">Campo requerido</span>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Apellido Materno</span>
              <vs-input class="" v-validate="`${requiredByForeign}alpha_spaces`" :disabled="isDisabledForm" v-model="apellidoMaterno" name="apellidoMaterno" />
              <span class="text-danger text-sm" v-show="errors.has('apellidoMaterno')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="sexo">Sexo</label>
              <div class="alinear">
                <div class="col-md-6">
                  <vs-radio :disabled="isDisabledForm" v-model="sexo" vs-name="sexo" name="sexo" vs-value="H">Hombre</vs-radio>
                </div>
                <div class="col-md-6">
                  <vs-radio :disabled="isDisabledForm" v-model="sexo" vs-name="sexo" name="sexo" vs-value="M">Mujer</vs-radio>
                </div>
								<span class="text-danger text-sm" v-show="errors.has('sexo')">Campo requerido</span>
              </div>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Teléfono celular (10 dígitos)</span>
              <vs-input class="" :disabled="isDisabledForm" v-model="telefono" name="telefono" v-validate="'required|min:10|max:10'"
                v-on:keypress="isNumber($event)" />
              <span class="text-danger text-sm" v-show="errors.has('telefono')">El campo debe tener 10 caracteres</span>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Fecha de nacimiento</span>
              <vs-input class="" type="date" :disabled="isDisabledForm" v-model="fechaNacimiento" name="fechaNacimiento" v-validate="'required'" />
              <span class="text-danger text-sm" v-show="errors.has('fechaNacimiento')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">RFC</span>
              <input class="vs-input" :disabled="isDisabledForm" v-model="rfc" name="rfc" v-validate="'min:13|max:13'" v-mask="'AAAA######NNN'" onkeyup="javascript:this.value=this.value.toUpperCase();" />
              <span class="text-danger span-placeholder" v-show="errors.has('rfc')">El campo debe tener 13 caracteres</span>
            </div>

            <!-- <div class="col-md-4">
              <span class="span-placeholder">RFC</span>
              <vs-input class="" :disabled="isDisabledForm" v-model="rfc" name="rfc" v-validate="'min:13|max:13'" v-mask="'AAAA######NNN'"  />
                <span class="text-danger text-sm" v-show="errors.has('rfc')">El campo debe tener 13 caracteres</span>
            </div>-->
            <div class="col-md-4">
              <span class="span-placeholder">CURP</span>
              <input class="vs-input" :disabled="isDisabledForm" v-model="curp" name="curp" v-validate="'min:18|max:18'" v-mask="'AAAA######AAAAAANN'" value="" onkeyup="javascript:this.value=this.value.toUpperCase();" />
              <span class="text-danger span-placeholder" v-show="errors.has('curp')">El campo debe tener 18 caracteres</span>
            </div>

            <!--<div class="col-md-4">
              <span class="span-placeholder">Curp</span>
              <vs-input class="" v-validate="'min:18|max:18'" :disabled="isDisabledForm" v-model="curp" name="curp" />
                <span class="text-danger text-sm" v-show="errors.has('curp')">El campo debe tener 18 caracteres</span>
            </div>-->
          </div>

		  <!-- Cambio por mejora -->
          <div class="row w-full">
            <div class="col-md-4">
              <label class="span-placeholder" for="nacionalidad">Nacionalidad</label>
              <select name="nacionalidad" id="nacionalidad" :disabled="isDisabledForm" v-model="nacionalidad" v-validate="'required'" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo' v-for="tipo in nacionalidades" :key="">{{tipo.Nombre}}</option>
              </select>
							<span class="text-danger text-sm" v-show="errors.has('nacionalidad')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="paisNacimiento">País de nacimiento</label>
              <select name="paisNacimiento" id="paisNacimiento" :disabled="isDisabledForm" v-model="adicionales.paisNacimiento" v-validate="'required'" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in paises" :key="">{{tipo.Nombre}}</option>
              </select>
							<span class="text-danger text-sm" v-show="errors.has('paisNacimiento')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="estadoNacimiento">Estado de nacimiento</label>
              <select name="estadoNacimiento" id="estadoNacimiento" :disabled="isDisabledForm" v-model="adicionales.estadoNacimiento" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Nombre' v-for="tipo in estados" :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <label class="span-placeholder" for="estadoCivil">Estado Civil</label>
              <select name="estadoCivil" id="estadoCivil" :disabled="isDisabledForm" v-model="estadoCivil" v-validate="'required'" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in estadosCiviles" :key="">{{tipo.Nombre}}</option>
              </select>
							<span class="text-danger text-sm" v-show="errors.has('estadoCivil')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="regimen">Régimen matrimonial</label>
              <select name="regimen" id="regimen" :disabled="isDisabledForm" v-model="regimenMatrimonial" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in regimenes" :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12" vs-xs="12" v-if="estadoCivil=='M' || estadoCivil=='U'">
              <div class="alinear">
                <span class="span-placeholder" for="">¿Participa en el crédito?</span>
                <vs-switch :disabled="isDisabledForm" v-model="coacreditado.participaCredito" />
              </div>
            </vs-col>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">No. de dependientes económicos</span>
              <vs-input class="" :disabled="isDisabledForm" v-model="dependientesEconomicos" name="dependientesEconomicos" />
              <!-- <span class="text-danger text-sm" v-show="errors.has('dependientesEconomicos')">Campo requerido</span> -->
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="identificacionPresenta">Identificación que presenta</label>
              <select name="identificacionPresenta" id="identificacionPresenta" :disabled="isDisabledForm" v-model="adicionales.identificacionPresenta" v-validate="'required'" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in identificaciones" :key="">{{tipo.Nombre}}</option>
              </select>
							<span class="text-danger text-sm" v-show="errors.has('identificacionPresenta')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Número de identificación</span>
              <vs-input class="" v-validate="'required'" :disabled="isDisabledForm" v-model="adicionales.numeroIfe" name="numeroIfe" />
              <span class="text-danger text-sm" v-show="errors.has('numeroIfe')">Campo requerido</span>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Vigencia Identificación</span>
              <vs-input class="" v-validate="'required'" type="date" :disabled="isDisabledForm" v-model="adicionales.vigencia" name="vigencia" />
              <span class="text-danger text-sm" v-show="errors.has('vigencia')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="grados">Máximo grado de estudios</label>
              <select name="grados" id="grados" :disabled="isDisabledForm" v-model="adicionales.gradoEstudios" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in gradosEstudio" :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">NSS (solo con cofinavit)</span>
              <vs-input class="" v-validate="'min:11|max:11'" :disabled="isDisabledForm" v-model="adicionales.nss" name="nss"
                v-on:keypress="isNumber($event)" />
              <span class="text-danger text-sm" v-show="errors.has('nss')">El campo debe tener 11 caracteres</span>
            </div>
          </div>

          <br>
          <br>

          <div class="mt-4" v-if="estadoCivil=='M' || estadoCivil=='U'">
            <h4>Datos del cónyuge</h4>
            <div class="row w-full">
              <div class="col-md-4">
                <span class="span-placeholder">Nombres</span>
                <vs-input class="" v-validate="'required'" label="" :disabled="isDisabledForm" v-model="conyuge.nombre" name="name" />
								<span class="text-danger text-sm" v-show="errors.has('nombreConyuge')">Campo requerido</span>
              </div>
              <div class="col-md-4">
                <span class="span-placeholder">Apellido Paterno</span>
                <vs-input class="" v-validate="'required'" :disabled="isDisabledForm" v-model="conyuge.apellidoPaterno" name="apellidoPaternoConyuge" />
								<span class="text-danger text-sm" v-show="errors.has('apellidoPaternoConyuge')">Campo requerido</span>
              </div>
              <div class="col-md-4">
                <span class="span-placeholder">Apellido Materno</span>
                <vs-input class="" v-validate="'required'" :disabled="isDisabledForm" v-model="conyuge.apellidoMaterno" name="apellidoMaternoConyuge" />
								<span class="text-danger text-sm" v-show="errors.has('apellidoMaternoConyuge')">Campo requerido</span>
              </div>
            </div>
            <div class="row w-full">
              <div class="col-md-4">
                <span class="span-placeholder"></span>
              </div>
              <div class="col-md-4">
                <span class="span-placeholder"></span>
              </div>
              <div class="col-md-4">
                <span class="span-placeholder"></span>
              </div>
            </div>
            <div class="row w-full">
              <div class="col-md-4">
                <span class="span-placeholder">CURP</span>
                <input class="vs-input" :disabled="isDisabledForm" v-model="conyuge.curp" name="name" v-validate="'min:18|max:18'" v-mask="'AAAA######AAAAAANN'" value="" onkeyup="javascript:this.value=this.value.toUpperCase();" />
                <span class="text-danger span-placeholder" v-show="errors.has('curp')">El campo debe tener 18 caracteres</span>
              </div>
              <div class="col-md-4">
                <span class="span-placeholder">RFC</span>
                <input class="vs-input" :disabled="isDisabledForm" v-model="conyuge.rfc" name="name" v-validate="'min:13|max:13'" v-mask="'AAAA######NNN'" onkeyup="javascript:this.value=this.value.toUpperCase();" />
                <span class="text-danger span-placeholder" v-show="errors.has('rfc')">El campo debe tener 13 caracteres</span>
              </div>

              <!-- <div class="col-md-4">
                <span class="span-placeholder">RFC</span>
                <vs-input class="" :disabled="isDisabledForm" v-model="conyuge.rfc" name="name" />
              </div>
              <div class="col-md-4">
                <span class="span-placeholder">Curp</span>
                <vs-input class="" :disabled="isDisabledForm" v-model="conyuge.curp" name="name" />
              </div>-->
            </div>
          </div>

          <br>
          <br>

          <h4>Domicilio actual</h4>
          <div class="row w-full">
            <div class="col-md-4">
              <label class="span-placeholder" for="estados">País</label>
              <select name="estados" id="estados" :disabled="isDisabledForm" v-model="pais" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in paises" :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="estados">Estado</label>
              <select name="estados" id="estados" :disabled="isDisabledForm" v-model="estado" class="v-select vs--single vs--searchable vs-input--input" @change='getMunicipios()'>
                <option :value='tipo.Id' v-for="tipo in estados" :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Calle</span>
              <vs-input class="" v-validate="'required'" :disabled="isDisabledForm" v-model="calle" name="calle" />
              <span class="text-danger text-sm" v-show="errors.has('calle')">Campo requerido</span>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Número exterior</span>
              <!-- <vs-input class="" v-validate="'required'" :disabled="isDisabledForm" v-model="numeroExterior" name="numeroExterior" /> -->
							<input class="vs-input" :disabled="isDisabledForm" v-model="numeroExterior" name="numeroExterior" v-validate="'required'" maxlength="5" />
              <span class="text-danger text-sm" v-show="errors.has('numeroExterior')">Campo requerido</span>
            </div>

            <div class="col-md-4">
              <span class="span-placeholder">Número interior</span>
              <!-- <vs-input class="" :disabled="isDisabledForm" v-model="numeroInterior" name="numeroInterior" /> -->
							<input class="vs-input" :disabled="isDisabledForm" v-model="numeroInterior" name="numeroInterior" maxlength="5" />
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Colonia</span>
              <vs-input class="" v-validate="'required'" :disabled="isDisabledForm" v-model="colonia" name="colonia" />
              <span class="text-danger text-sm" v-show="errors.has('colonia')">Campo requerido</span>
            </div>

          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Código postal</span>
              <vs-input class="" :disabled="isDisabledForm" v-model="codigoPostal" name="cp" v-validate="'min:5|max:5'"
                v-on:keypress="isNumber($event)" />
              <span class="text-danger text-sm" v-show="errors.has('cp')">El campo debe tener 5 caracteres</span>
            </div>

            <div class="col-md-4">
              <label class="span-placeholder" for="estados">Municipio</label>
              <select name="estados" id="estados" :disabled="isDisabledForm" v-model="municipio"
                class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in municipios" :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Antigüedad domicilio en años</span>
              <vs-input class="" v-validate="'required'" :disabled="isDisabledForm" v-model="adicionales.antiguedadDomicilioAnios"
                name="antiguedadDomicilioAnios" v-on:keypress="isNumber($event)" />
              <span class="text-danger text-sm" v-show="errors.has('antiguedadDomicilioAnios')">Campo requerido</span>
            </div>

          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Antigüedad domicilio en meses</span>
              <!-- <vs-input class="" v-validate="'required'" :disabled="isDisabledForm" v-model="adicionales.antiguedadDomicilioMeses" name="antiguedadDomicilioMeses" v-on:keypress="isNumber($event)" /> -->
              <input class="vs-input" v-validate="'required'" :disabled="isDisabledForm" v-model="adicionales.antiguedadDomicilioMeses" name="antiguedadDomicilioMeses" v-on:keypress="isNumber($event)" />

              <span class="text-danger text-sm" v-show="errors.has('antiguedadDomicilioMeses')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Antigüedad en población en años</span>
              <vs-input class="" v-validate="'required'" :disabled="isDisabledForm" v-model="adicionales.antiguedadCiudadAnios" name="antiguedadCiudadAnios" v-on:keypress="isNumber($event)" />
              <span class="text-danger text-sm" v-show="errors.has('antiguedadCiudadAnios')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Antigüedad en población en meses</span>
              <vs-input class="" v-validate="'required'" :disabled="isDisabledForm" v-model="adicionales.antiguedadCiudadMeses" name="antiguedadCiudadMeses" v-on:keypress="isNumber($event)" />
              <span class="text-danger text-sm" v-show="errors.has('antiguedadCiudadMeses')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="domicilioOcupaciones">Tipo de vivienda</label>
              <select name="cars" id="domicilioOcupaciones" :disabled="isDisabledForm" v-model="adicionales.tipoVivienda" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in domicilioOcupaciones" :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
          </div>

          <br>
          <br>

          <h4>Ocupación Actual</h4>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Empresa</span>
              <vs-input class="" v-validate="'required'" :disabled="isDisabledForm" v-model="ingreso.empresa" name="empresa" />
              <span class="text-danger text-sm" v-show="errors.has('empresa')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="giro">Giro</label>
              <select name="giro" v-validate="'required'" id="giro" :disabled="isDisabledForm" v-model="ingreso.giro" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in giros" :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger text-sm" v-show="errors.has('giro')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="tipoContrato">Tipo de Contrato</label>
              <select name="tipoContrato" v-validate="'required'" id="tipoContrato" :disabled="isDisabledForm" v-model="ingreso.tipoContrato"
                class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in tiposContratos" :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger text-sm" v-show="errors.has('tipoContrato')">Campo requerido</span>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <label class="span-placeholder" for="sectorlaboral">Sector laboral</label>
              <select name="sectorlaboral" v-validate="'required'" id="sectorlaboral" :disabled="isDisabledForm" v-model="ingreso.sectorlaboral" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in sectores" :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger text-sm" v-show="errors.has('sectorlaboral')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="ocupacion">Ocupación</label>
              <select name="ocupacion" v-validate="'required'" id="ocupacion" :disabled="isDisabledForm" v-model="ingreso.ocupacion" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in ocupaciones" :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger text-sm" v-show="errors.has('ocupacion')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="actividad">Actividad</label>
              <select name="actividad" v-validate="'required'" id="actividad" :disabled="isDisabledForm" v-model="ingreso.actividad" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in actividades" :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger text-sm" v-show="errors.has('actividad')">Campo requerido</span>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <label class="span-placeholder" for="paisIngresos">Pais</label>
              <select name="paisIngresos" v-validate="'required'" id="paisIngresos" :disabled="isDisabledForm" v-model="ingreso.pais" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in paises" :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger text-sm" v-show="errors.has('paisIngresos')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="estadosIngreso">Estado</label>
              <select name="estados" id="estadosIngreso" :disabled="isDisabledForm" v-model="ingreso.estado" class="v-select vs--single vs--searchable vs-input--input" @change='getMunicipiosIngreso()'>
                <option :value='tipo.Id' v-for="tipo in estados" :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="municipioIngresos">Municipio</label>
              <select name="estados" id="municipioIngresos" :disabled="isDisabledForm" v-model="ingreso.municipio" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in municipiosIngreso" :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Calle</span>
              <vs-input class="" v-validate="'required'" :disabled="isDisabledForm" v-model="ingreso.domicilio" name="calleIngreso" />
              <span class="text-danger text-sm" v-show="errors.has('calleIngreso')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Número exterior</span>
							<input class="vs-input" :disabled="isDisabledForm" v-model="ingreso.numeroExterior" name="numeroExteriorIngreso"  v-validate="'required'" maxlength="5" onkeyup="javascript:this.value=this.value.toUpperCase();" />
              <span class="text-danger text-sm" v-show="errors.has('numeroExteriorIngreso')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Número Interior</span>
							<input class="vs-input" :disabled="isDisabledForm" v-model="ingreso.numeroInterior" name="numeroInteriorIngreso" maxlength="5" onkeyup="javascript:this.value=this.value.toUpperCase();" />
              <!-- <vs-input class="" :disabled="isDisabledForm" v-model="ingreso.numeroInterior" name="name" :maxlength="6" /> -->
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Colonia</span>
              <vs-input class="" v-validate="'required'" :disabled="isDisabledForm" v-model="ingreso.colonia" name="coloniaIngreso" />
              <span class="text-danger text-sm" v-show="errors.has('coloniaIngreso')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Código postal</span>
              <vs-input class="" :disabled="isDisabledForm" v-model="ingreso.codigoPostal" name="cpIngreso" v-validate="'required|min:5|max:5'"
                v-on:keypress="isNumber($event)" />
              <span class="text-danger text-sm" v-show="errors.has('cpIngreso')">El campo debe tener 5 caracteres</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Puesto</span>
              <vs-input class="" v-validate="'required'" :disabled="isDisabledForm" v-model="ingreso.puesto" name="puesto" />
              <span class="text-danger text-sm" v-show="errors.has('puesto')">Campo requerido</span>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Teléfono (10 dígitos)</span>
              <vs-input class="" v-validate="'required'" minlength="10" maxlength="10" :disabled="isDisabledForm" v-model="ingreso.telefono" name="telIngreso" v-on:keypress="isNumber($event)" />
              <span class="text-danger text-sm" v-show="errors.has('telIngreso')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Antigüedad Años</span>
              <vs-input class="" v-validate="'required'" :disabled="isDisabledForm" v-model="ingreso.antiguedadAnios" name="antAnios" />
              <span class="text-danger text-sm" v-show="errors.has('antAnios')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Antigüedad Meses</span>
              <vs-input class="" v-validate="'required'" :disabled="isDisabledForm" v-model="ingreso.antiguedadMeses" name="antMeses" />
              <span class="text-danger text-sm" v-show="errors.has('antMeses')">Campo requerido</span>
            </div>
          </div>

          <br>
          <br>

		  <!-- Ingreso principal -->
		<h4>Actividad Financiera</h4>
		<div class="row w-full">

			<div class="col-md-4">
				<label class="span-placeholder" for="actividadFinanciera">Actividad Financiera</label>
				<select 
					name="actividadFinanciera" 
					v-validate="'required'" 
					id="actividadFinanciera" 
					:disabled="isDisabledForm" 
					v-model="ingreso.actividadFinanciera" 
					class="v-select vs--single vs--searchable vs-input--input">
					<option :value='tipo.Id' v-for="tipo in actividadesFinancieras" :key="">{{tipo.Nombre}}</option>
				</select>
				<span class="text-danger text-sm" v-show="errors.has('actividadFinanciera')">Campo requerido</span>
			</div>

			<div class="col-md-4">
				<label class="span-placeholder" for="comprobanteIngresos">Comprueba ingresos con</label>
				<select 
					name="comprobanteIngresos" 
					v-validate="'required'" 
					id="comprobanteIngresos" 
					:disabled="isDisabledForm" 
					v-model="ingreso.comprobanteIngresos" 
					class="v-select vs--single vs--searchable vs-input--input">
					<option :value='tipo.Id' v-for="tipo in comprobantes" :key="">{{tipo.Nombre}}</option>
				</select>
				<span class="text-danger text-sm" v-show="errors.has('comprobanteIngresos')">Campo requerido</span>
			</div>

			<div class="col-md-3">
				<span class="span-placeholder">Ingreso mensual</span>
				<vs-input 
					class="" 
					v-validate="'required|decimal:2'" 
					:disabled="isDisabledForm" 
					v-model="ingreso.ingresoMensual" 
					name="ingreso" 
					v-on:keypress="isNumber($event)" />
				<span class="text-danger text-sm" v-show="errors.has('ingreso')">Campo requerido</span>
			</div>

			<div class="col-md-1"></div>

		</div>

		<!-- Ingresos adicionales: cambio por mejora ingresos adicionales -->
		<div class="row w-full flex items-center" v-for="ingresoAdicional in ingresoAdicionalList">

			<div class="col-md-4">
				<label 
					class="span-placeholder" 
					for="">Actividad Financiera</label>
				<select 
					:name="'actividadFinanciera'+ingresoAdicional.Id" 
					v-validate="'required'" 
					:disabled="isDisabledForm" 
					v-model="ingresoAdicional.actividadFinanciera" 
					class="v-select vs--single vs--searchable vs-input--input">
					<option :value='tipo.Id' v-for="tipo in actividadesFinancieras" :key="">{{ tipo.Nombre }}</option>
				</select>
				<!-- <span class="text-danger text-sm" v-show="errors.has('')">Campo requerido</span> -->
			</div>

			<div class="col-md-4">
				<label class="span-placeholder" for="">Comprueba ingresos con</label>
				<select 
					:name="'comprobanteIngresos'+ingresoAdicional.Id" 
					v-validate="'required'" 
					:disabled="isDisabledForm" 
					v-model="ingresoAdicional.comprobanteIngresos" 
					class="v-select vs--single vs--searchable vs-input--input">
					<option :value='tipo.Id' v-for="tipo in comprobantes" :key="">{{ tipo.Nombre }}</option>
				</select>
				<!-- <span class="text-danger text-sm" v-show="errors.has('')">Campo requerido</span> -->
			</div>

			<div class="col-md-3">
				<span class="span-placeholder">Ingreso mensual</span>
				<vs-input 
					class="" 
					v-validate="'required|decimal:2'" 
					:disabled="isDisabledForm" 
					:name="'ingreso'+ingresoAdicional.Id"
					v-model="ingresoAdicional.IngresosMensuales"  
					v-on:keypress="isNumber($event)" />
				<!-- <span class="text-danger text-sm" v-show="errors.has('')">Campo requerido</span> -->
			</div>

			<div class="col-md-1">
				<button
					:disabled="isDisabledForm" 
					class="border-0 bg-transparent mt-10" 
					v-on:click="eliminarIngresoAdicional(ingresoAdicional)">
					<svg class="trash">
						<g id="trash">
							<path id="Vector" d="M18,.5C14.67.17,11.32,0,7.98,0A59.068,59.068,0,0,0,2.04.3L0,.5" transform="translate(3 5.48)" fill="none" stroke="#626262" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
							<path id="Vector-2" data-name="Vector" d="M0,2.97.22,1.66C.38.71.5,0,2.19,0H4.81C6.5,0,6.63.75,6.78,1.67L7,2.97" transform="translate(8.5 2)" fill="none" stroke="#626262" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34" />
							<path id="Vector-3" data-name="Vector" d="M13.7,0l-.65,10.07c-.11,1.57-.2,2.79-2.99,2.79H3.64C.85,12.86.76,11.64.65,10.07L0,0" transform="translate(5.15 9.14)" fill="none" stroke="#626262" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
							<path id="Vector-4" data-name="Vector" d="M0,0H3.33" transform="translate(10.33 16.5)" fill="none" stroke="#626262" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34" />
							<path id="Vector-5" data-name="Vector" d="M0,0H5" transform="translate(9.5 12.5)" fill="none" stroke="#626262" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34" />
							<path id="Vector-6" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0" />
						</g>
					</svg>
				</button>
			</div>

		</div>

		<br>
		<br>

		<!-- Ingresos adicionales -->
		<h4 class="flex items-center">
			<span class="mr-4">Agregar ingreso</span> 
			<button 
				class="border-0 bg-transparent mt-2"
				v-on:click="agregarIngresoAdicional()"
				:disabled="isDisabledForm">
				<feather-icon icon="PlusCircleIcon" />
			</button>
		</h4>

		<!-- <div class="row w-full">
			<div class="col-md-4">
			<label class="span-placeholder">¿Tiene otro ingreso?</label>
			<div class="alinear">
				<div class="col-md-6">
				<vs-radio :disabled="isDisabledForm" v-model="otroIngreso" vs-name="otroIngreso" vs-value='0'>No</vs-radio>
				</div>
				<div class="col-md-6">
				<vs-radio :disabled="isDisabledForm" v-model="otroIngreso" vs-name="otroIngreso" vs-value='1'>Sí</vs-radio>
				</div>
			</div>
			</div>
		</div> -->

          <!-- </br>
          </br> -->

          <!-- <div v-if="otroIngreso == '1'">
            <h4>Ingreso Extra</h4>
            <div class="row w-full">
              <div class="col-md-4">
                <label class="span-placeholder" for="formasEnganche">Actividad Financiera</label>
                <select name="cars" id="formasEnganche" :disabled="isDisabledForm" v-model="ingreso.actividadFinanciera2" class="v-select vs--single vs--searchable vs-input--input">
                  <option :value='tipo.Id' v-for="tipo in actividadesFinancieras" :key="">{{tipo.Nombre}}</option>
                </select>
              </div>
              <div class="col-md-4">
                <label class="span-placeholder" for="formasEnganche">Comprueba ingresos con</label>
                <select name="cars" id="formasEnganche" :disabled="isDisabledForm" v-model="ingreso.comprobanteIngresos2" class="v-select vs--single vs--searchable vs-input--input">
                  <option :value='tipo.Id' v-for="tipo in comprobantes" :key="">{{tipo.Nombre}}</option>
                </select>
              </div>
              <div class="col-md-4">
                <span class="span-placeholder">Ingreso mensual</span>
                <vs-input class="" :disabled="isDisabledForm" v-model="ingreso.ingresoMensual2" name="ingreso" v-on:keypress="isNumber($event)" />
              </div>
            </div>
          </div> -->

          <br>
          <br>

          <h4>Datos de la garantía</h4>
          <div class="row w-full">
            <div class="col-md-4">
              <label class="span-placeholder" for="garantiaUbicacion">Ubicacion</label>
              <select name="garantiaUbicacion" v-validate="'required'" id="garantiaUbicacion" :disabled="isDisabledForm" v-model="garantia.ubicacion" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in ubicaciones" :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger text-sm" v-show="errors.has('garantiaUbicacion')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="usoVivienda">Uso de la vivienda</label>
              <select name="usoVivienda" v-validate="'required'" id="usoVivienda" :disabled="isDisabledForm" v-model="garantia.uso" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in usos" :key="">{{tipo.Nombre}}</option>
              </select>
							<span class="text-danger text-sm" v-show="errors.has('usoVivienda')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="estadoVivienda">Estado de la vivienda</label>
              <select name="estadoVivienda" v-validate="'required'" id="estadoVivienda" :disabled="isDisabledForm" v-model="garantia.estado" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in condiciones" :key="">{{tipo.Nombre}}</option>
              </select>
							<span class="text-danger text-sm" v-show="errors.has('estadoVivienda')">Campo requerido</span>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <label class="span-placeholder" for="tipoPropiedad">Tipo de propiedad</label>
              <select name="tipoPropiedad" v-validate="'required'" id="tipoPropiedad" :disabled="isDisabledForm" v-model="garantia.tipo" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in tipos" :key="">{{tipo.Nombre}}</option>
              </select>
							<span class="text-danger text-sm" v-show="errors.has('tipoPropiedad')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <label class="span-placeholder" for="bienes">¿Tiene bienes a su nombre?</label>
              <div class="alinear">
                <div class="col-md-6">
                  <vs-radio :disabled="isDisabledForm" v-model="bienesFlag" vs-name="Bienes" vs-value='0'>No</vs-radio>
                </div>
                <div class="col-md-6">
                  <vs-radio :disabled="isDisabledForm" v-model="bienesFlag" vs-name="Bienes" vs-value='1'>Sí</vs-radio>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder"></span>
            </div>
          </div>

          <br>
          <br>

          <vs-row v-if="bienesFlag == '1'">
            <h4>Auto</h4>

            <div class="row w-full">
              <div class="col-md-4">
                <span class="span-placeholder">Marca</span>
                <vs-input :disabled="isDisabledForm" v-model="bienes.marca" />
              </div>
              <div class="col-md-4">
                <span class="span-placeholder">Modelo</span>
                <vs-input class="" :disabled="isDisabledForm" v-model="bienes.modelo" name="modelo" />
              </div>
              <div class="col-md-4">
                <span class="span-placeholder">Valor estimado</span>
                <vs-input class="" :disabled="isDisabledForm" v-model="bienes.valor" name="valor" v-on:keypress="isNumber($event)" />
              </div>
            </div>
            <br>
            <br>

            <div class="row w-full">
              <h4>Inmueble</h4>

              <div class="col-md-4">
                <label class="span-placeholder" for="formasEnganche">Tipo de propiedad</label>
                <select name="cars" id="formasEnganche" :disabled="isDisabledForm" v-model="bienes.tipoInmueble" class="v-select vs--single vs--searchable vs-input--input">
                  <option :value='tipo.Id' v-for="tipo in tiposInmueble" :key="">{{tipo.Nombre}}</option>
                </select>
              </div>
              <div class="col-md-4">
                <span class="span-placeholder">Valor estimado</span>
                <vs-input :disabled="isDisabledForm" v-model="bienes.valorInmueble" name="valor" v-on:keypress="isNumber($event)" />
              </div>
              <div class="col-md-4">
                <span class="span-placeholder"></span>
              </div>
            </div>
            <br>
          </vs-row>
          <div class="row w-full">
            <div class="col-md-12">
              <label for="bienes" class="span-placeholder">Propósito del Crédito Hipotecario</label>
              <div class="alinear">
                <div class="col-md-2">
                  <vs-radio :disabled="isDisabledForm" v-model="proposito" vs-name="proposito" vs-value='P'>Principal</vs-radio>
                </div>
                <div class="col-md-2">
                  <vs-radio :disabled="isDisabledForm" v-model="proposito" vs-name="proposito" vs-value='D'>Descanso</vs-radio>
                </div>
                <div class="col-md-2">
                  <vs-radio :disabled="isDisabledForm" v-model="proposito" vs-name="proposito" vs-value='I'>Inversión</vs-radio>
                </div>
                <div class="col-md-2">
                  <vs-radio :disabled="isDisabledForm" v-model="proposito" vs-name="proposito" vs-value='L'>Liquidez</vs-radio>
                </div>
                <div class="col-md-2">
                  <vs-radio :disabled="isDisabledForm" v-model="proposito" vs-name="proposito" vs-value='O'>Otro</vs-radio>
                </div>
                <div class="col-md-2">
                  <span class="span-placeholder">Especificar</span>
                  <vs-input class="" :disabled="isDisabledForm" v-model="otroproposito" v-if="proposito=='O'" />
                </div>
              </div>
            </div>
          </div>

          <br>
          <br>

          <div class="row w-full">
            <h4>Proveedor de Recursos</h4>
            <h5>Enganche de Crédito</h5>
            <label class="span-placeholder" for="bienes">¿Los recursos utilizados para el pago del enganche provienen de un tercero y/o proveedor de recursos?</label>
            <div class="alinear col-md-4">
              <div class="col-md-6">
                <vs-radio :disabled="isDisabledForm" v-model="recursos" vs-name="recursos" vs-value='N'>No</vs-radio>
              </div>
              <div class="col-md-6">
                <vs-radio :disabled="isDisabledForm" v-model="recursos" vs-name="recursos" vs-value='S'>Sí</vs-radio>
              </div>
            </div>
          </div>

          <br>
          <br>

          <div class="row w-full">
            <h5>Pago Mensualidades de Crédito</h5>
            <label class="span-placeholder" for="bienes"> ¿Los pagos que se realizarán al crédito provienen de un tercero y/o proveedor de recursos?</label>
            <div class="col-md-4 alinear">
              <div class="col-md-4">
                <vs-radio :disabled="isDisabledForm" v-model="pago" vs-name="pago" vs-value='N'>No</vs-radio>
              </div>
              <div class="col-md-4">
                <vs-radio :disabled="isDisabledForm" v-model="pago" vs-name="pago" vs-value='S'>Sí</vs-radio>
              </div>
            </div>
          </div>
          <br>

          <!---  <vs-button color="primary" @click="actualizarInfo()" :disabled="disabledFlag">Guardar Progreso</vs-button>
          <p style="color:red" v-show="disabledFlag">*Es importante guardar la información del coacreditado primero</p>--->
          <br>

          <vs-collapse v-if="coacreditado.participaCredito">
            <vs-collapse-item>
              <div slot="header" class="row">
                <h4>Agregar coacreditado
                  <feather-icon icon="PlusCircleIcon" />
                </h4>
              </div>
              <div class="row w-full">
                <div class="col-md-4">
                  <span class="span-placeholder">Correo electrónico</span>
                  <vs-input class="" :disabled="isDisabledForm" v-model="coacreditado.email" name="username" />
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Parentesco</span>
                  <vs-input :disabled="isDisabledForm" v-model="coacreditado.parentesco" name="username" readonly />
                </div>
                <div class="col-md-4">
                  <label class="span-placeholder" for="">¿Consolida ingresos con el solicitante?</label>
                  <vs-switch :disabled="isDisabledForm" v-model="coacreditado.consolidaIngresos" />
                </div>
              </div>
              <br>
              <button class="btn btn-second" @click="registraCoacreditado()">Añadir Coacreditado</button>
            </vs-collapse-item>
          </vs-collapse>
        </vx-card>
      </div>
    </div>

    <vs-popup class="holamundo" :title="resultado" :active.sync="popupActive">
      <p>{{titulo}}</p>
    </vs-popup>
    <vs-popup class="holamundo" title="Personas politicamente expuestas" :active.sync="popupAlert" color="warning">
      <p>Es necesario capturar el folio en el sitio de Scotiabank</p>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from 'axios'
import { usos } from '@/assets/jsonFiles/usos.js'
import { ubicaciones } from '@/assets/jsonFiles/ubicaciones.js'
import { tipos } from '@/assets/jsonFiles/tipos.js'
import { estadosCiviles } from '@/assets/jsonFiles/estadosCiviles.js'
import { giros } from '@/assets/jsonFiles/giros.js'
import { domicilioOcupaciones } from '@/assets/jsonFiles/domicilioOcupaciones.js'
import { paises } from '@/assets/jsonFiles/paises.js'
import { nacionalidades } from '@/assets/jsonFiles/nacionalidades.js'
import { actividades } from '@/assets/jsonFiles/actividades.js'
import { actividadesFinancieras } from '@/assets/jsonFiles/actividadesFinancieras.js'
import { sectores } from '@/assets/jsonFiles/sectores.js'
import { ocupaciones } from '@/assets/jsonFiles/ocupaciones.js'
import { comprobantes } from '@/assets/jsonFiles/comprobantes.js'
import { identificaciones } from '@/assets/jsonFiles/identificaciones.js'
import { gradosEstudio } from '@/assets/jsonFiles/gradosEstudio.js'
import { municipios } from '@/assets/jsonFiles/municipios.js'
import { condiciones } from '@/assets/jsonFiles/condiciones.js'
import { tiposInmueble } from '@/assets/jsonFiles/tiposInmueble.js'
export default {
	components: {
		'v-select': vSelect,
	},
	props: {
		varianteId: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			ingresoAdicionalList: [],
			ingresoAdicionalEliminado: [],
			isSaveIngresoAdicionalRobot: false, // para campo caso pre, indica que los ingresos de master ya se vieron y no se traen
			requiredByForeign: '',
			disabledFlag: false,
			activePrecaExterna: false,
			id: '',
			key: '',
			email: '',
			nombre: '',
			apellidoPaterno: '',
			apellidoMaterno: '',
			telefono: '',
			fechaNacimiento: '',
			ingresosSinImpuestos: '',
			IngresosConImpuestos: '',
			estadoCivil: '',
			obligado: '',
			regimenMatrimonial: '',
			rfc: '',
			sexo: '',
			calle: '',
			numeroInterior: '',
			numeroExterior: '',
			colonia: '',
			municipio: '',
			estado: '',
			pais: '1',
			curp: '',
			nacionalidad: '1',
			codigoPostal: '',
			dependientesEconomicos: '',
			estados: [],
			municipiosAll: municipios,
			municipios: [],
			municipiosIngreso: [],
			regimenes: [],
			bienes: {
				marca: '',
				modelo: '',
				valor: '',
				tipoInmueble: '',
				valorInmueble: '',
			},
			ingreso: {
				ingresoId: '',
				ingresoNeto: '',
				ingresoMensual: '',
				empresa: '',
				giro: '',
				puesto: '',
				domicilio: '',
				colonia: '',
				municipio: '',
				codigoPostal: '',
				telefono: '',
				tipoContrato: '',
				comprobanteIngresos: '',
				sectorlaboral: '',
				ocupacion: '',
				actividad: '',
				actividadFinanciera: '',
				estado: '',
				pais: '',
				antiguedadAnios: '',
				antiguedadMeses: '',
				numeroExterior: '',
				numeroInterior: '',
				actividadFinanciera2: '',
				comprobanteIngresos2: '',
				ingresoMensual2: '',
				ScotiaPorceConsiderado: '',
				ScotiaIngresosConsiderar: '',
				ScotiaTotalesConsiderar: '',
				ScotiaIngresosCalculados: '',
			},
			otroIngreso: '0',
			bienesFlag: '0',
			solicitante: {},
			tieneIngreso: false,
			adicionales: {
				estadoNacimiento: '',
				titularidadDomicilio: '',
				gradoEstudios: '',
				tiempoOcupandoDomicilioActual: '',
				identificacionPresenta: '',
				numeroIfe: '',
				vigencia: '',
				nss: '',
				antiguedadCiudadAnios: '',
				antiguedadCiudadMeses: '',
				paisNacimiento: '',
				tipoVivienda: '',
				antiguedadDomicilioAnios: '',
				antiguedadDomicilioMeses: '',
			},
			coacreditado: {
				email: '',
				// TODO: Se cambio manualmente
				parentesco: 'Esposo a',
				participaCredito: false,
				consolidaIngresos: false,
			},
			garantia: {
				uso: '',
				ubicacion: '',
				estado: '',
				tipo: '',
			},
			conyuge: {
				nombre: '',
				apellidoPaterno: '',
				apellidoMaterno: '',
				curp: '',
				rfc: '',
			},
			caso: {},
			resultado: '',
			titulo: '',
			popupActive: false,
			ppe: 'N',
			recursos: 'N',
			pago: 'N',
			parentescoppe: 'N',
			popupAlert: false,
			proposito: '',
			otroproposito: '',
			tiposContratos: [],
			usos: usos,
			ubicaciones: ubicaciones,
			tipos: tipos,
			estadosCiviles: estadosCiviles,
			giros: giros,
			domicilioOcupaciones: domicilioOcupaciones,
			gradosEstudio: gradosEstudio,
			identificaciones: identificaciones,
			comprobantes: comprobantes,
			sectores: sectores,
			ocupaciones: ocupaciones,
			actividades: actividades,
			actividadesFinancieras: actividadesFinancieras,
			nacionalidades: [],
			paises: [],
			condiciones: condiciones,
			tiposInmueble: tiposInmueble,
			emailCasoNuevo: { bandera: '' },
			rows: [],
			estadoCivilGeneral: '',
			txtButtonProgreso: 'Editar',
			isDisabledForm: true,
			normaTipo: {
				Numero: 1,
				LetraEspacio: 2,
				LetraNumero: 3,
				LetraNumeroEspacio: 4,
			}
		}
	},
	watch: {
		// Restricciones Solicitante
		nombre: function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ ]*$/;
			if (!regex.test(newValue)) {
				this.nombre = oldValue;
			}
		},
		apellidoPaterno: function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ ]*$/;
			if (!regex.test(newValue)) {
				this.apellidoPaterno = oldValue;
			}
		},
		apellidoMaterno: function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ ]*$/;
			if (!regex.test(newValue)) {
				this.apellidoMaterno = oldValue;
			}
		},
		telefono: function(newValue, oldValue) {
			let regex = /^[0-9]*$/;
			if (!regex.test(newValue)) {
				this.telefono = oldValue;
			}
		},
		// RFC y CURP usan v-mask //
		dependientesEconomicos: function(newValue, oldValue) {
			let regex = /^[0-9]*$/;
			if (!regex.test(newValue)) {
				this.dependientesEconomicos = oldValue;
			}
		},
		'adicionales.numeroIfe': function(newValue, oldValue) {
			let regex = /^[0-9]*$/;
			if (!regex.test(newValue)) {
				this.adicionales.numeroIfe = oldValue;
			}
		},
		'adicionales.nss': function(newValue, oldValue) {
			let regex = /^[0-9]*$/;
			if (!regex.test(newValue)) {
				this.adicionales.nss = oldValue;
			}
		},
		'conyuge.nombre': function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ ]*$/;
			if (!regex.test(newValue)) {
				this.conyuge.nombre = oldValue;
			}
		},
		'conyuge.apellidoPaterno': function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ ]*$/;
			if (!regex.test(newValue)) {
				this.conyuge.apellidoPaterno = oldValue;
			}
		},
		'conyuge.apellidoMaterno': function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ ]*$/;
			if (!regex.test(newValue)) {
				this.conyuge.apellidoMaterno = oldValue;
			}
		},
		// RFC y CURP usan v-mask //
		calle: function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9 ]*$/;
			if (!regex.test(newValue)) {
				this.calle = oldValue;
			}
		},
		numeroExterior: function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9]*$/;
			if (!regex.test(newValue)) {
				this.numeroExterior = oldValue;
			}
		},
		numeroInterior: function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9]*$/;
			if (!regex.test(newValue)) {
				this.numeroInterior = oldValue;
			}
		},
		colonia: function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9 ]*$/;
			if (!regex.test(newValue)) {
				this.colonia = oldValue;
			}
		},
		codigoPostal: function(newValue, oldValue) {
			let regex = /^[0-9]*$/;
			if (!regex.test(newValue)) {
				this.codigoPostal = oldValue;
			}
		},
		'adicionales.antiguedadDomicilioAnios': function(newValue, oldValue) {
			let regex = /^[0-9]*$/;
			if (!regex.test(newValue)) {
				this.adicionales.antiguedadDomicilioAnios = oldValue;
			}
		},
		'adicionales.antiguedadDomicilioMeses': function(newValue, oldValue) {
			let regex = /^[0-9]*$/;
			if (!regex.test(newValue)) {
				this.adicionales.antiguedadDomicilioMeses = oldValue;
			}
		},
		'adicionales.antiguedadCiudadAnios': function(newValue, oldValue) {
			let regex = /^[0-9]*$/;
			if (!regex.test(newValue)) {
				this.adicionales.antiguedadCiudadAnios = oldValue;
			}
		},
		'adicionales.antiguedadCiudadMeses': function(newValue, oldValue) {
			let regex = /^[0-9]*$/;
			if (!regex.test(newValue)) {
				this.adicionales.antiguedadCiudadMeses = oldValue;
			}
		},
		'ingreso.empresa': function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9 ]*$/;
			if (!regex.test(newValue)) {
				this.ingreso.empresa = oldValue;
			}
		},
		'ingreso.domicilio': function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9 ]*$/;
			if (!regex.test(newValue)) {
				this.ingreso.domicilio = oldValue;
			}
		},
		'ingreso.numeroExterior': function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9]*$/;
			if (!regex.test(newValue)) {
				this.ingreso.numeroExterior = oldValue;
			}
		},
		'ingreso.numeroInterior': function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9]*$/;
			if (!regex.test(newValue)) {
				this.ingreso.numeroInterior = oldValue;
			}
		},
		'ingreso.colonia': function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9 ]*$/;
			if (!regex.test(newValue)) {
				this.ingreso.colonia = oldValue;
			}
		},
		'ingreso.codigoPostal': function(newValue, oldValue) {
			let regex = /^[0-9]*$/;
			if (!regex.test(newValue)) {
				this.ingreso.codigoPostal = oldValue;
			}
		},
		'ingreso.puesto': function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9 ]*$/;
			if (!regex.test(newValue)) {
				this.ingreso.puesto = oldValue;
			}
		},
		'ingreso.telefono': function(newValue, oldValue) {
			let regex = /^[0-9]*$/;
			if (!regex.test(newValue)) {
				this.ingreso.telefono = oldValue;
			}
		},
		'bienes.marca': function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9 ]*$/;
			if (!regex.test(newValue)) {
				this.bienes.marca = oldValue;
			}
		},
		'bienes.modelo': function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9 ]*$/;
			if (!regex.test(newValue)) {
				this.bienes.modelo = oldValue;
			}
		},
		// Campo coacreditado.parentesco es readonly // 
		// Restricciones Solicitante //

		// Requerimiento 2023-03-28 Cambio en nacionalidades
		nacionalidad: function(newValue, oldValue) {
			if (newValue.Nombre == "MEXICANO (A)") {
				// console.log( 'ES MEXICANO' );
				this.requiredByForeign = 'required|';
			} else {
				// console.log( 'ES EXTRANJERO' );
				this.requiredByForeign = '';
			}

			// Se adapto esto
			if (newValue.Id == '1') {
				this.pais = '1';
				this.ingreso.pais = '1'
			}
			// console.log( 'N: ', newValue, 'O: ', oldValue );
		},


		parentescoppe: function (newValue) {
			if (newValue == 'S') {
				this.popupAlert = true
			}
		},
		ppe: function (newValue) {
			if (newValue == 'S') {
				this.popupAlert = true
			}
		},
		participa: function (newValue) {
			if (this.coacreditado.email) {
				this.disabledFlag = false
			} else {
				this.disabledFlag = newValue
			}
		},
		estadoCivil: function (newValue) {

			this.regimenMatrimonial = ''

		},
		// ingresoNeto: function(newValue) {
		//   this.ingreso.ingresoNeto= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		// },
		// ingresoMensual: function(newValue) {
		//   this.ingreso.ingresoMensual= newValue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
		//
		//   // this.ingreso.ingresoMensual= newValue.replace(/\D/g, "")
		// },
		// ingresoMensual2: function(newValue) {
		//   this.ingreso.ingresoMensual2= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		// },
		// valor: function(newValue) {
		//   this.bienes.valor= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		// },
		// valorInmueble: function(newValue) {
		//   this.bienes.valorInmueble= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		// },
		sector: function (newValue) {
			if (newValue == 1) {
				this.actividades = [
					{ Id: 'ADVO', Nombre: 'ADMINISTRATIVO' },
					{ Id: 'ALBA', Nombre: 'DIRECTOR/SUBDIRECTOR' },
					{ Id: 'CHOF', Nombre: 'CHOFER' },
					{ Id: 'DIGE', Nombre: 'DIRECTOR GENERAL' },
					{ Id: 'EJEC', Nombre: 'EJECUTIVO' },
					{ Id: 'GTES', Nombre: 'GERENTE' },
					{ Id: 'GUAR', Nombre: 'GUARDIA UNIFORMADO' },
					{ Id: 'JUBI', Nombre: 'JUBILADO' },
					{ Id: 'MEDI', Nombre: 'MEDICO' },
					{ Id: 'MTRO', Nombre: 'PROFESOR' },
					{ Id: 'OTRO', Nombre: 'OTRO' },
					{ Id: 'REIN', Nombre: 'REPARADOR / INSTALADOR' },
					{ Id: 'SECR', Nombre: 'SECRETARIA' },
					{ Id: 'SERV', Nombre: 'OTROS SERV. (MESERO, PELUQUERO,ETC.)' },
				]
			} else if (newValue == 2) {
				this.actividades = [
					{ Id: 'AMAC', Nombre: 'AMA DE CASA' },
					{ Id: 'DESE', Nombre: 'DESEMPLEADO CON INGRESOS' },
					{ Id: 'ESTU', Nombre: 'ESTUDIANTE' },
					{ Id: 'INVE', Nombre: 'INVERSIONISTA / ARRENDATARIO' },
					{ Id: 'JUBI', Nombre: 'JUBILADO' },
					{ Id: 'MEDI', Nombre: 'MEDICO' },
					{ Id: 'OTRO', Nombre: 'OTRO' },
					{ Id: 'PROF', Nombre: 'PROFESIONISTA / INDEPENDIENTE' },
					{ Id: 'TAXI', Nombre: 'TAXISTA' },
					{ Id: 'VTAS', Nombre: 'COMISIONISTA / VENTAS' },
				]
			} else if (newValue == 3) {
				this.actividades = [
					{ Id: 'AGGA', Nombre: 'AGRICULTURA  / GANADERIA' },
					{ Id: 'COME', Nombre: 'COMERCIO' },
					{ Id: 'INTR', Nombre: 'INDUSTRIA / SERVICIOS' },
					{ Id: 'MEDI', Nombre: 'MEDICO' },
					{ Id: 'OTRO', Nombre: 'OTRO' },
				]
			} else if (newValue == 4) {
				this.actividades = [
					{ Id: 'ADVO', Nombre: 'ADMINISTRATIVO' },
					{ Id: 'ALBA', Nombre: 'DIRECTOR/SUBDIRECTOR' },
					{ Id: 'CHOF', Nombre: 'CHOFER' },
					{ Id: 'DIGE', Nombre: 'DIRECTOR GENERAL' },
					{ Id: 'EJEC', Nombre: 'EJECUTIVO' },
					{ Id: 'JUBI', Nombre: 'JUBILADO' },
					{ Id: 'MEDI', Nombre: 'MEDICO' },
					{ Id: 'OTRO', Nombre: 'OTRO' },
					{ Id: 'GTES', Nombre: 'GERENTE' },
					{ Id: 'GUAR', Nombre: 'GUARDIA UNIFORMADO' },
					{ Id: 'MTRO', Nombre: 'PROFESOR' },
					{ Id: 'REIN', Nombre: 'REPARADOR / INSTALADOR' },
					{ Id: 'SECR', Nombre: 'SECRETARIA' },
					{ Id: 'SERV', Nombre: 'OTROS SERV. (MESERO, PELUQUERO,ETC.)' },
				]
			}
		},
	},
	computed: {
		participa() {
			return this.coacreditado.participaCredito
		},
		sector() {
			return this.ingreso.sectorlaboral
		},
		ingresoNeto() {
			return this.ingreso.ingresoNeto
		},
		ingresoMensual() {
			return this.ingreso.ingresoMensual
		},
		ingresoMensual2() {
			return this.ingreso.ingresoMensual2
		},
		valor() {
			return this.bienes.valor
		},
		valorInmueble() {
			return this.bienes.valorInmueble
		},
	},
	mounted() {
		this.id = this.$route.params.id
		this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey

		this.loadPaises()
		this.loadNaciopnalidades()

		//this.getApikey()
		this.dameCaso()
		// this.DameExternaAntiguedad()
		this.getEstados()
		this.getListaRegimenes()
		this.getTiposContratos()
		this.municipios = this.municipiosAll
		this.municipiosIngreso = this.municipiosAll
	},
	methods: {
		// cambio por mejora ingresos adicionales
		eliminarIngresoAdicional( ingresoId ) {
			// console.log( 'Ingreso a eliminar', ingresoId );
			this.ingresoAdicionalList = this.ingresoAdicionalList.filter( ingreso => ingreso != ingresoId)
			// console.log( this.ingresoAdicionalList );
			
			// mientras se obtengan registros del master
			if (!this.isSaveIngresoAdicionalRobot) {
				// verificar si hubo un ingreso excedente, si existe y hay espacio se agrega a la lista
				if (this.ingresoAdicionalList.length < 3 && this.ingresoAdicionalEliminado.length > 0) {
					// console.log( 'Agregando ingreso eliminado' )
					this.ingresoAdicionalList.push( this.ingresoAdicionalEliminado.shift() )
				}
				// console.log( 'Ingresos Extra Master', this.ingresoAdicionalEliminado );
			}
		},
		agregarIngresoAdicional() {
			// Limitar a 3 ingreso adicionales
			if ( this.ingresoAdicionalList.length >= 3) {
				this.$vs.notify({
					title: 'Limite alcanzado',
					text: 'No se pueden agregar mas ingresos',
					color: 'danger',
					position: 'top-right',
				})
				return;
			}

			let tempId = new Date().getTime()
			this.ingresoAdicionalList.push({ 
				Id: tempId, 
				actividadFinanciera: null,
				comprobanteIngresos: null,
				IngresosMensuales: null
			}) // ingreso temporal
			// console.log( 'lista final despues de agregar o combinar', this.ingresoAdicionalList );

		},
		normalizaCadena(value, tipo) {
			/*
			1: Numeros 
			2: Letras y espacios 
			3: Letras y numeros 
			4: Letras, numeros y espacios
			*/

			// const strValue = String(value)
			// console.log(strValue)
			
			const vocalNormalizacion = (str) => {
				let regex = /([aeiou])[\u0301\u0300]/gi;
				return str.normalize('NFD')
						.replace(regex,"$1")
						.normalize();
			}

			let cadena = "";
			if (value !== '') {
				const data = String(value)
				cadena = vocalNormalizacion(data)

				let regex = "";

				switch (tipo) {
					case 1:
					regex = /[^0-9]/g;
					cadena = cadena.replaceAll(regex, '');
					break;
					case 2:
					regex = /[^A-Za-zÑñ ]/g;
					cadena = cadena.replaceAll(regex, '');
					break;
					case 3:
					regex = /[^A-Za-zÑñ0-9]/g;
					cadena = cadena.replaceAll(regex, '');
					break;
					case 4:
					regex = /[^A-Za-zÑñ0-9 ]/g;
					cadena = cadena.replaceAll(regex, '');
					break;
					default:
					break;
				}
			}

			// TODO: Eliminar para prod
			// console.log( 'Original: ', value, 'Normalizando: ', cadena );
			return cadena;
		},
		ActiveForm() {
			!this.isDisabledForm && this.actualizarInfo(false)

			this.isDisabledForm = !this.isDisabledForm
			this.txtButtonProgreso = !this.isDisabledForm ? 'Guardar Progreso' : 'Editar'
		},
		loadPaises() {

			const arrPaises = []
			let count = 2
			for (const pais of paises) {

				const nombre = pais.Nombre
				if ( nombre === 'MÉXICO' ) { 
					arrPaises[0] = pais 
					continue
				}

				if ( nombre === 'ESTADOS UNIDOS' ) { 
					arrPaises[1] = pais 
					continue
				}

				arrPaises[count] = pais

				count++
			}

			this.paises = arrPaises

		},
		loadNaciopnalidades() {

			const arrNacionalidades = []
			let count = 2
			for (const nacionalidad of nacionalidades) {

				const nombre = nacionalidad.Nombre
				if ( nombre === 'MEXICANO (A)' ) { 
					arrNacionalidades[0] = nacionalidad 
					continue
				}

				if ( nombre === 'NORTEAMERICANO (A)' ) { 
					arrNacionalidades[1] = nacionalidad 
					continue
				}

				arrNacionalidades[count] = nacionalidad

				count++
			}

			this.nacionalidades = arrNacionalidades

		},
		getMunicipios() {
			this.municipio = ''
			this.municipios = []
			this.municipios = this.municipiosAll[this.estado - 1]
		},
		getMunicipiosIngreso() {
			this.ingreso.municipio = ''
			this.municipiosIngreso = []
			this.municipiosIngreso = this.municipiosAll[this.ingreso.estado - 1]
		},
		saveScotiaAditionals() {
			
			if (this.bienesFlag == '0') {
				this.bienes.marca = '' 
				this.bienes.modelo = '' 
				this.bienes.valor = '' 
				this.bienes.tipoInmueble = '' 
				this.bienes.valorInmueble =  ''
			}

			// conversion a json de ingresos adicionales
			let ingresoAdicionalFinal = JSON.stringify( this.ingresoAdicionalList )
			// console.log( 'guardado de adicionales scotia', ingresoAdicionalFinal );

			let self = this
			if (this.estadoCivil == 'D') {
				this.estadoCivilGeneral = '2'
			} else if (this.estadoCivil == 'M') {
				this.estadoCivilGeneral = '1'
			} else if (this.estadoCivil == 'P') {
				this.estadoCivilGeneral = '6'
			} else if (this.estadoCivil == 'S') {
				this.estadoCivilGeneral = '3'
			} else if (this.estadoCivil == 'U') {
				this.estadoCivilGeneral = '4'
			} else if (this.estadoCivil == 'W') {
				this.estadoCivilGeneral = '5'
			}

			var objRequestguardaAdicionales = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraDatosConexiones',
				objSolicitante: {
					EMail: this.email,
					IngresoId: this.ingreso.ingresoId,
					IdCaso: this.id,
					Scotiabank: {
						GiroEmpresa: this.ingreso.giro,
						SectorLaboral: this.ingreso.sectorlaboral,
						Ocupacion: this.ingreso.ocupacion,
						Actividad: this.ingreso.actividad,
						ActividadFinanciera: this.ingreso.actividadFinanciera,
						Comprobante: this.ingreso.comprobanteIngresos,
						Escolaridad: this.adicionales.gradoEstudios,
						DependientesEconomicos: this.dependientesEconomicos,
						TipoIdentificacion: this.adicionales.identificacionPresenta,
						EstadoCivil: this.estadoCivil,
						EstadoCivilGeneral: this.estadoCivilGeneral,
						TipoVivienda: this.adicionales.tipoVivienda,
						PaisDomicilioActual: this.pais,
						AntiguedadEnPoblacionActualAnios: this.adicionales.antiguedadCiudadAnios,
						AntiguedadEnPoblacionActualMeses: this.adicionales.antiguedadCiudadMeses,
						AntiguedadEnDomicilioActualAnios: this.adicionales.antiguedadDomicilioAnios,
						AntiguedadEnDomicilioActualMeses: this.adicionales.antiguedadDomicilioMeses,
						AntiguedadOcupacionActualAnios: this.ingreso.antiguedadAnios,
						AntiguedadOcupacionActualMeses: this.ingreso.antiguedadMeses,
						Ubicacion: this.garantia.ubicacion,
						UsoVivienda: this.garantia.uso,
						EstadoVivienda: this.garantia.estado,
						TipoPropiedad: this.garantia.tipo,
						NumeroIdentificacion: this.adicionales.numeroIfe,
						VigenciaIdentificacion: this.adicionales.vigencia,
						PaisNacimiento: this.adicionales.paisNacimiento,
						EstadoNacimiento: this.adicionales.estadoNacimiento,
						Municipio: this.municipio,
						// Nacionalidad: this.nacionalidad,
						Nacionalidad: {
							id: this.nacionalidad.Id,
							name: this.nacionalidad.Nombre,
							updateScotia: true,
						},
						ConyugeNombre: this.conyuge.nombre,
						ConyugeApellidoPaterno: this.conyuge.apellidoPaterno,
						ConyugeApellidoMaterno: this.conyuge.apellidoMaterno,
						ConyugeRfc: this.conyuge.rfc,
						ConyugeCurp: this.conyuge.curp,
						//ingreso Extra
						// 2023-05-24 Ya no se usa
						// Actividad2: this.ingreso.actividadFinanciera2,
						// Comprobante2: this.ingreso.comprobanteIngresos2,
						// Ingreso2: this.ingreso.ingresoMensual2,
						ScotiaIngresoAdicional: ingresoAdicionalFinal,

						//BIENES
						TipoInmueble: this.bienes.tipoInmueble,
						ValorInmueble: this.bienes.valorInmueble,
						MarcaAuto: this.bienes.marca,
						ModeloAuto: this.bienes.modelo,
						ValorAuto: this.bienes.valor,
						Ppe: this.ppe,
						Parentescoppe: this.parentescoppe,
						Proposito: this.proposito,
						PropositoOtro: this.otroproposito,
						Recursos: this.recursos,
						Pago: this.pago,
					},
				},
			}
			this.$axios
				.post('/', objRequestguardaAdicionales, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Datos editados exitosamente`,
							position: 'top-right',
						})
						// si el guardado es existoso se pasa a true
						this.isSaveIngresoAdicionalRobot = true
						// reinicio el listado para que no quede en cache
						this.ingresoAdicionalList = []
						// retiro la lista de eliminados si existe
						this.ingresoAdicionalEliminado = []

						this.dameSolicitante(true)

						this.emailCasoNuevo.bandera = 'CompletoPersonales'
						this.$emit('bandera', this.emailCasoNuevo.bandera)
						this.ActualizaBanderaPersonales(false)
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en Guarda adicionales Scotia',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema saveScotiaAditionals',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		ActualizaBanderaPersonales(tipo) {
			let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'ActualizaBanderaScotiabank',
				objCaso: {
					Id: this.id,
					Bandera: 'Personales',
					tipo: tipo,
				},
			}
			this.$axios
				.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						if (tipo == false) {
							this.emailCasoNuevo.bandera = 'CompletoPersonales'
							this.$emit('bandera', this.emailCasoNuevo.bandera)
						} else {
							this.emailCasoNuevo.bandera = 'IncompletosPersonales'
							this.$emit('bandera', this.emailCasoNuevo.bandera)
						}
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en Actualiza',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema ActualizaBanderaPersonales',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		sendEmail(folio) {
			let self = this
			let to
			if (this.$store.state.AppActiveUser.EMailNotificaciones) {
				to = this.$store.state.AppActiveUser.EMailNotificaciones
			} else {
				to = this.$store.state.AppActiveUser.EMail
			}
			var objEmail = {
				to: to,
				brokername: this.$store.state.AppActiveUser.Nombre,
				bank: 'Scotiabank',
				id: folio,
				clientname: this.nombre + ' ' + this.apellidoPaterno,
			}
			axios
				.post('https://autos.conexionbox.com/api/mail/connections', objEmail, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {
					this.$vs.notify({
						title: 'Se envió correctamente el correo electrónico a',
						text: this.$store.state.AppActiveUser.EMail,
						position: 'top-right',
					})
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		async getCaso() {

			let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'DameCaso',
				objCaso: {
					Id: this.id,
				},
			}
			const { data } = await this.$axios.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
			const { intEstatus, objContenido } = data

			// console.log(data)

			if ( intEstatus ) {

				return this.$vs.notify({
					title: 'Ocurrio un error en DameCaso',
					text: data.strError,
					color: 'danger',
					position: 'top-right',
				})

			}

			this.caso = objContenido

			if (objContenido.ScotiabankPersonales == 'Completo') {
				this.emailCasoNuevo.bandera = 'CompletoPersonales'
				this.$emit('bandera', this.emailCasoNuevo.bandera)
			}

			const actualizarInfo = await this.actualizarInfo(true)

			// return console.log(actualizarInfo)

			if ( !actualizarInfo ) return

			this.precalificacionLinea()
		},
		getDameCredencialesBrokerBanco() {
			let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'DameBrokerBanco',
				objEjecutivo: {
					EMail: this.$store.state.AppActiveUser.EMail,
					Banco: 11,
				},
			}
			this.$axios
				.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						if (response.data.objContenido == 'Si existe') {
							this.$vs.notify({
								title: 'Las credenciales del banco que ingresaste son incorrectas',
								text: response.data.strError,
								color: 'danger',
								position: 'top-right',
							})
						} else if (response.data.objContenido == 'No existe') {
							this.$vs.notify({
								title: 'Las credenciales registradas para Scotiabank son incorrectas o no existen',
								text: response.data.strError,
								color: 'danger',
								position: 'top-right',
							})
						} else {
							this.$vs.notify({
								title: 'Se produjo un error al intentar conectar con el banco, inténtalo más tarde',
								text: response.data.strError,
								color: 'danger',
								position: 'top-right',
							})
						}
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameAcceso',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema getDameCredencialesBrokerBanco',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		precalificacionLinea() {
			this.$vs.loading({
				container: '#container',
				scale: 0.6,
			})

			let self = this
			var objRequestSolicitaCaso = {
				strApiKey: this.key,
				strMetodo: 'SolicitarEnLinea',
				objCaso: {
					Id: this.id,
					Banco: 11, //hsbc 6: santander 10: Scotiabank 11
					EMail: this.$store.state.AppActiveUser.EMail,
					DatosCredito: {
						Plazo: this.caso.Plazos[0],
						Programa: this.caso.Programas[0],
						Esquema: this.caso.Esquemas[0],
						Monto: this.caso.MontoSolicitado,
						ValorPropiedad: this.caso.ValorInmueble,
						Variante: this.varianteId
					},
				},
			}
			this.$axios
				.post('/', objRequestSolicitaCaso, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
					},
				})
				.then((response) => {

					// this.$vs.loading.close('#container > .con-vs-loading')
					const { data } = response

					// console.log({ data })
					// return console.log(data)

					if (response.intEstatus == 0) {
						/*  this.resultado=response.objContenido.strInformacion
              this.titulo=response.objContenido.strTitle
              this.popupActive=true
              if (response.objContenido.intFolio != 0) {
                this.sendEmail(response.objContenido.intFolio)
              }*/

						this.emailCasoNuevo.bandera = 'Personales'
						this.$emit('bandera', this.emailCasoNuevo.bandera)
						this.gettabla()
					} else {
						if (response.data.objContenido == 'Las credenciales registradas para Scotiabank son incorrectas o no existen.' || response.data.objContenido == 'Solo puedes agregar una solicitud por cliente en el banco') {
							if (response.data.objContenido == 'Las credenciales registradas para Scotiabank son incorrectas o no existen.') {
								this.resultado = 'Las credenciales son incorrectas'
								this.titulo = response.data.objContenido
								this.popupActive = true
							}
							if (response.data.objContenido == 'Solo puedes agregar una solicitud por cliente en el banco') {
								this.resultado = 'Solicitud ya registrada'
								this.titulo = response.data.objContenido
								this.popupActive = true
							}
						} else {
							if (response.data.objContenido.strTitle == 'CONSULTA DE APROBACIÓN DE CRÉDITO.') {
								this.emailCasoNuevo.bandera = 'Personales'
								this.$emit('bandera', this.emailCasoNuevo.bandera)
							} else {
								this.resultado = response.data.objContenido.strTitle
								this.titulo = response.data.objContenido.strInformacion + ' ' + response.data.objContenido.strError
								this.popupActive = true
							}
						}
					}
					this.$vs.loading.close('#container > .con-vs-loading')
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Se produjo un error al intentar conectar con el banco, inténtalo más tarde',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		gettabla() {
			let self = this
			var objRequestListaTabla = {
				strApiKey: this.key,
				strMetodo: 'ListaSolicitudesExternas',
				objCaso: {
					IdCaso: this.id,
				},
			}
			this.$axios
				.post('/', objRequestListaTabla, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					response.data.objContenido
					//this.rows = response.data.objContenido
					let registro = response.data.objContenido
					for (var i = 0; i < registro.length; i++) {
						let fecha = registro[i].Fecha.date.substring(0, 10)

						fecha = fecha.split('-', 3)
						fecha = fecha[2] + '/' + fecha[1] + '/' + fecha[0]

						this.rows.push({ folioComparador: registro[i].Precalificacion, destino: registro[i].Destino, banco: registro[i].NombreBanco, montoSolicitado: registro[i].MontoSolicitado, fechaSolicitud: fecha, Activo: registro[i].Estatus, Acciones: registro[i].FolioTexto })
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema4',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		addIngreso() {
			if (this.tieneIngreso) {
				this.editarIngreso()
			} else {
				this.registraIngreso()
			}
		},
		registraIngreso() {
			let self = this

			if (this.ingreso.actividadFinanciera == '1-100') {
				this.ingreso.ScotiaPorceConsiderado = 100
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '2-100') {
				this.ingreso.ScotiaPorceConsiderado = 100
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '4-100') {
				this.ingreso.ScotiaPorceConsiderado = 100
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '5-30') {
				this.ingreso.ScotiaPorceConsiderado = 50
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '6-10') {
				this.ingreso.ScotiaPorceConsiderado = 10
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '7-2.5') {
				this.ingreso.ScotiaPorceConsiderado = 2.5
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '8-2.5') {
				this.ingreso.ScotiaPorceConsiderado = 2.5
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '9-2.5') {
				this.ingreso.ScotiaPorceConsiderado = 2.5
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '10-100') {
				this.ingreso.ScotiaPorceConsiderado = 100
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '11-100') {
				this.ingreso.ScotiaPorceConsiderado = 100
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '12-75') {
				this.ingreso.ScotiaPorceConsiderado = 80
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '13-75') {
				this.ingreso.ScotiaPorceConsiderado = 80
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			}

			var objRequestListaCasos = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraIngresos',
				objSolicitante: {
					EMail: this.email,
					Ingresos: [
						{
							IngresosMensuales: this.ingreso.ingresoMensual,
							IngresosNetos: this.ingreso.ingresoMensual,
							TipoEmpleo: 1,
							Empresa: this.ingreso.empresa,
							Puesto: this.ingreso.puesto,
							Domicilio: this.ingreso.domicilio,
							Colonia: this.ingreso.colonia,
							Municipio: this.ingreso.municipio,
							CodigoPostal: this.ingreso.codigoPostal,
							Telefono: this.ingreso.telefono,
							TipoContrato: this.ingreso.tipoContrato,
							Estado: this.ingreso.estado,
							Pais: this.ingreso.pais,
							NumeroInterior: this.ingreso.numeroInterior,
							NumeroExterior: this.ingreso.numeroExterior,
							ScotiaPorceConsiderado: this.ingreso.ScotiaPorceConsiderado,
							ScotiaIngresosConsiderar: this.ingreso.ScotiaIngresosConsiderar,
							ScotiaTotalesConsiderar: this.ingreso.ScotiaTotalesConsiderar,
							ScotiaIngresosCalculados: this.ingreso.ScotiaIngresosCalculados,
						},
					],
				},
			}
			this.$axios
				.post('/', objRequestListaCasos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.ingreso.ingresoId = response.data.objContenido.Ingresos[0].Id
						this.saveScotiaAditionals()
					}
					this.$vs.loading.close('#container > .con-vs-loading')
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema registraIngreso',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		agregarCoacreditado() {
			let self = this
			var objRequestRegistraCoacreditado = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraCoacreditado',
				objCaso: {
					Id: this.id,
					EMail: this.coacreditado.email,
					DatosCoacreditado: {
						ConsolidaIngresos: this.coacreditado.consolidaIngresos,
						ParticipaCredito: this.coacreditado.participaCredito,
						Parentesco: this.coacreditado.parentesco,
					},
				},
			}
			this.$axios
				.post('/', objRequestRegistraCoacreditado, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.disabledFlag = false
						this.$vs.notify({
							title: `Datos guardados exitosamente`,
							position: 'top-right',
						})
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraCoacreditado',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema agregarCoacreditado',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		registraCoacreditado() {
			let objRequestRegistraSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraSolicitante',
				objSolicitante: {
					EMail: this.coacreditado.email,
					Nombres: this.conyuge.nombre,
					ApellidoPaterno: this.conyuge.apellidoPaterno,
					ApellidoMaterno: this.conyuge.apellidoMaterno,
					TelefonoCelular: '',
					FechaNacimiento: '',
					Rfc: this.conyuge.rfc,
					Curp: this.conyuge.curp,
				},
			}
			this.$axios
				.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.agregarCoacreditado()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraSolicitante',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema registraCoacreditado',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		editarIngreso() {
			let self = this

			if (this.ingreso.actividadFinanciera == '1-100') {
				this.ingreso.ScotiaPorceConsiderado = 100
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '2-100') {
				this.ingreso.ScotiaPorceConsiderado = 100
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '4-100') {
				this.ingreso.ScotiaPorceConsiderado = 100
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '5-30') {
				this.ingreso.ScotiaPorceConsiderado = 50
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '6-10') {
				this.ingreso.ScotiaPorceConsiderado = 10
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '7-2.5') {
				this.ingreso.ScotiaPorceConsiderado = 2.5
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '8-2.5') {
				this.ingreso.ScotiaPorceConsiderado = 2.5
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '9-2.5') {
				this.ingreso.ScotiaPorceConsiderado = 2.5
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '10-100') {
				this.ingreso.ScotiaPorceConsiderado = 100
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '11-100') {
				this.ingreso.ScotiaPorceConsiderado = 100
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '12-75') {
				this.ingreso.ScotiaPorceConsiderado = 80
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			} else if (this.ingreso.actividadFinanciera == '13-75') {
				this.ingreso.ScotiaPorceConsiderado = 80
				this.ingreso.ScotiaIngresosConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaTotalesConsiderar = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
				this.ingreso.ScotiaIngresosCalculados = (this.ingreso.ingresoMensual / 100) * this.ingreso.ScotiaPorceConsiderado
			}

			var objRequestRegistraCuentasBancarias = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'EditarIngresos',
				objSolicitante: {
					EMail: this.email,
					Ingresos: [
						{
							Id: this.ingreso.ingresoId,
							IngresosMensuales: this.ingreso.ingresoMensual,
							IngresosNetos: this.ingreso.ingresoMensual,
							TipoEmpleo: 1,
							TipoContrato: this.ingreso.tipoContrato,
							Empresa: this.ingreso.empresa,
							Puesto: this.ingreso.puesto,
							Domicilio: this.ingreso.domicilio,
							Colonia: this.ingreso.colonia,
							Municipio: this.ingreso.municipio,
							CodigoPostal: this.ingreso.codigoPostal,
							Telefono: this.ingreso.telefono,
							Estado: this.ingreso.estado,
							Pais: this.ingreso.pais,
							NumeroInterior: this.ingreso.numeroInterior,
							NumeroExterior: this.ingreso.numeroExterior,
							ScotiaPorceConsiderado: this.ingreso.ScotiaPorceConsiderado,
							ScotiaIngresosConsiderar: this.ingreso.ScotiaIngresosConsiderar,
							ScotiaTotalesConsiderar: this.ingreso.ScotiaTotalesConsiderar,
							ScotiaIngresosCalculados: this.ingreso.ScotiaIngresosCalculados,
						},
					],
				},
			}
			this.$axios
				.post('/', objRequestRegistraCuentasBancarias, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.saveScotiaAditionals()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en Editar Ingreso',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema editarIngreso',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		updateObligadoSolidario() {
			// Si hay un obligado solidario y un regimen seleccionado
			if (this.obligado.EMail && this.regimenMatrimonial) {
				// console.log( 'updateObligadoSolidario', this.key, this.obligado.EMail, this.regimenMatrimonial );

				let objUpdateObligadoSolidario = {
					strFolioCaso: this.id,
					strApiKey: this.key,
					strMetodo: 'RegistraObligadoSolidario',
					objCaso: {
						EMail: this.obligado.EMail,
						RegimenMatrimonial: this.regimenMatrimonial,
						ScotiaObligado: 1,
					}
				}
				// console.log( 'updateObligadoSolidario', objUpdateObligadoSolidario );

				this.$axios.post('/',objUpdateObligadoSolidario,{ 
					headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
				}).then(response => {

					if(response.data.intEstatus == 0){
						// console.log( 'obligadoSolidario:', response );
					} else {
						this.$vs.notify({
							title:'Ocurrio un error al actualizar el regimen matrimonial',
							text:response.data.strError,
							color:'danger',
							position:'top-right'
						})
					}
				}).catch(function (error) {
					self.$vs.notify({
					title:'Ocurrio un error de sistema updateObligadoSolidario',
					text:error,
					color:'danger',
					position:'top-right'
					})
				})
			}
		},
		async actualizarInfo(isSolicitar) {

			// console.log(this.$validator)

			// return console.log(isSolicitar)

			// verifica edad con antiguedad en domicilio y antiguedad en poblacion

			var arr = this.ArrayFecha(this.fechaNacimiento)
            var edad_solicitante  = this.calculateAge(arr)
			console.log("edad" + edad_solicitante);
			console.log("adicionales.antiguedadDomicilioAnios " + this.adicionales.antiguedadDomicilioAnios);
			console.log("adicionales.antiguedadDomicilioAnios " + this.adicionales.antiguedadCiudadAnios);

			if (this.adicionales.antiguedadDomicilioAnios > edad_solicitante) {
				this.$vs.notify({
					title: 'Ocurrio un error',
					text: 'La antiguedad en domicilio no puede ser mayor a la edad del solicitante.',
					color: 'danger',
					position: 'top-right',
				})
				return
			}
			if (this.adicionales.antiguedadCiudadAnios > edad_solicitante) {
				this.$vs.notify({
					title: 'Ocurrio un error',
					text: 'La Antigüedad en población  no puede ser mayor a la edad del solicitante.',
					color: 'danger',
					position: 'top-right',
				})
				return 
			}

			let self = this
			let sexo
			if (this.sexo == 'H') {
				sexo = 1
			} else {
				sexo = 2
			}
			//  alert('actualiza'+sexo)
			let objRequestRegistraSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraSolicitante',
				objSolicitante: {
					EMail: this.email,
					//Nombres: this.nombre,
					ApellidoPaterno: this.apellidoPaterno,
					ApellidoMaterno: this.apellidoMaterno,
					TelefonoCelular: this.telefono,
					FechaNacimiento: this.fechaNacimiento,
					Rfc: this.rfc,
					Sexo: sexo,
					Calle: this.calle,
					Municipio: this.municipio,
					NumeroExterior: this.numeroExterior,
					NumeroInterior: this.numeroInterior,
					Colonia: this.colonia,
					Estado: this.estado,
					Curp: this.curp,
					CodigoPostal: this.codigoPostal,
					RegimenMatrimonial: this.regimenMatrimonial,
					Nss: this.adicionales.nss
				},
				isScotia: true
			}

			const validator = isSolicitar ? await this.$validator.validateAll() : true
			// const validator = true

			if ( !validator ) {

				this.$vs.notify({
					title: 'Ocurrio un error',
					text: 'Datos incompletos',
					color: 'danger',
					position: 'top-right',
				})

				return false

			}

			const response = await this.$axios.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })

			const { intEstatus, objContenido } = response

			// console.log({ objContenido })

			if ( intEstatus ) {

				this.$vs.notify({
					title: 'Ocurrio un error en RegistraSolicitante',
					text: response.data.strError,
					color: 'danger',
					position: 'top-right',
				})

				return false

			}

			// Actualiza el regimen matrimonial al obligado solidario
			this.updateObligadoSolidario();

			if ( isSolicitar ) {

				return true

			}
			else {

				this.registraAdicionales()
				
				// console.log( 'REGISTRANDO ADICIONALES' );

			}
		},
		calculateAge: function(birthday) {
      
			var birthday_arr = birthday.split("/");
			var birthday_date = new Date(birthday_arr[2], birthday_arr[1] - 1, birthday_arr[0]);
			var ageDifMs = Date.now() - birthday_date.getTime();
			var ageDate = new Date(ageDifMs);
			return Math.abs(ageDate.getUTCFullYear() - 1970);
		},
		ArrayFecha: function(mensaje) {
			var ano = mensaje.split('-');

			var fecha = ano[2]+'/'+ano[1]+'/'+ano[0];
			return fecha;

			
		},
		dameCaso() {
			let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'DameCaso',
				objCaso: {
					Id: this.id,
				},
			}
			this.$axios
				.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.email = response.data.objContenido.Solicitante
						this.adicionales.usoInmueble = response.data.objContenido.UsoInmueble
						let coacreditado = response.data.objContenido.Coacreditado
						if (coacreditado.length) {
							this.disabledFlag = true
							this.coacreditado.email = coacreditado[0].EMail
							this.coacreditado.participaCredito = coacreditado[0].ParticipaCredito
							this.coacreditado.consolidaIngresos = coacreditado[0].ConsolidaIngresos
						}

						// TIPO DE PROPIEDAD
						// 1 	=> CASA HABITACION 				| C => CASA-HABITACION
						// 2 	=> DEPARTAMENTO 					| D => DEPARTAMENTO CONDOMINIO
						// 3 	=> LOCAL COMERCIAL 				| L => LOCAL COMERCIAL
						// 4 	=> ALMACEN 								| O => OTRO
						// 5 	=> EDIFICIO 							| O => OTRO
						// 6 	=> OFICINA 								| F => OFICINA
						// 7 	=> CONSULTORIO MEDICO 		| O => OTRO
						// 8 	=> CONJUNTO HABITACIONAL 	| O => OTRO
						// 9 	=> CASA EN CONDOMINIO 		| O => OTRO
						// 10 => TERRENO 								| T => TERRENO

						const tipoInmueble = response.data.objContenido.TipoInmueble
						if ( tipoInmueble === 1 ) this.bienes.tipoInmueble = 'C'
						if ( tipoInmueble === 2 ) this.bienes.tipoInmueble = 'D'
						if ( tipoInmueble === 3 ) this.bienes.tipoInmueble = 'L'
						if ( tipoInmueble === 4 ) this.bienes.tipoInmueble = 'O'
						if ( tipoInmueble === 5 ) this.bienes.tipoInmueble = 'O'
						if ( tipoInmueble === 6 ) this.bienes.tipoInmueble = 'F'
						if ( tipoInmueble === 7 ) this.bienes.tipoInmueble = 'O'
						if ( tipoInmueble === 8 ) this.bienes.tipoInmueble = 'O'
						if ( tipoInmueble === 9 ) this.bienes.tipoInmueble = 'O'
						if ( tipoInmueble === 10 ) this.bienes.tipoInmueble = 'T'

            const listObligados = response.data.objContenido.ObligadosSolidarios
            this.obligado = listObligados.length > 0 && listObligados[0]

						// 2023-05-24 verifica si ya dio click en guardar por primera vez
						if (response.data.objContenido.ScotiaSaveIngresoAdicional == 1) {
							this.isSaveIngresoAdicionalRobot = true;
						} else {
							this.isSaveIngresoAdicionalRobot = false;
						}

						this.dameSolicitante()
						this.DameExternaAntiguedad()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameCaso',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema dameCaso',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		DameExternaAntiguedad() {
			let self = this
			const objRequestCount = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'PrecaExternaAntiguedad',
				objAntiguedad: {
					EmailSolicitante: this.email,
					IdCaso: this.id,
				},
			}

			this.$axios
				.post('/', objRequestCount, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {

					const status = response.data.intEstatus
					const resp = response.data.objContenido

					if ( status ) {

						this.$vs.notify({
							title: 'Ocurrio un error en DameExternaAntiguedad',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})

					}

					this.activePrecaExterna = resp ? true : false
					// this.activePrecaExterna = false

				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema DameExternaAntiguedad',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})

		},
		dameSolicitante(getData) {
			let self = this
			var objRequestDameSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'DameSolicitante',
				objSolicitante: {
					EMail: this.email,
				},
			}
			this.$axios
				.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						let solicitante = response.data.objContenido
						this.nombre = this.normalizaCadena(solicitante.Nombres + ' ' + solicitante.SegundoNombre, this.normaTipo.LetraEspacio);
						this.apellidoPaterno = this.normalizaCadena(solicitante.ApellidoPaterno, this.normaTipo.LetraEspacio);
						this.apellidoMaterno = this.normalizaCadena(solicitante.ApellidoMaterno, this.normaTipo.LetraEspacio);
						this.telefono = this.normalizaCadena(solicitante.TelefonoCelular, this.normaTipo.Numero);
						// TODO: Puede que haya problemas con este campo por el formato ya que se ocupa el mismo
						this.telefono = this.telefono.replace(/ /g, '').replace(/-/g, '')
						this.fechaNacimiento = solicitante.FechaNacimiento
						this.rfc = this.normalizaCadena(solicitante.Rfc, this.normaTipo.LetraNumero);

						if (solicitante.Sexo == 1) {
							this.sexo = 'H'
						} else if (solicitante.Sexo == 2) {
							this.sexo = 'M'
						}

						this.calle = this.normalizaCadena(solicitante.Calle, this.normaTipo.LetraNumeroEspacio);
						this.numeroExterior = this.normalizaCadena(solicitante.NumeroExterior, this.normaTipo.LetraNumero);
						this.numeroInterior = this.normalizaCadena(solicitante.NumeroInterior, this.normaTipo.LetraNumero);
						//this.colonia = this.normalizaCadena(solicitante.Colonia, this.normaTipo.LetraNumero);
						this.colonia = solicitante.Colonia;
						this.estado = solicitante.Estado
						this.curp = this.normalizaCadena(solicitante.Curp, this.normaTipo.LetraNumero);
						this.codigoPostal = this.normalizaCadena(solicitante.CodigoPostal, this.normaTipo.Numero);

						// this.nacionalidad = solicitante.ScotiaNacionalidad
						// Cambio en nacionalidad por mejora
						if (solicitante.ScotiaNacionalidad && solicitante.Nacionalidad) {
							this.nacionalidad = {
								Id: solicitante.ScotiaNacionalidad,
								Nombre: solicitante.Nacionalidad,
							};
						} else {
							this.nacionalidad = '';
						}
	

            // Estados Civiles
            // 1 => CASADO(A) => M
            // 6 => CONVIVENCIA => P
            // 2 => DIVORCIADO(A) => D
            // 3 => SOLTERO(A) => S
            // 4 => UNIÓN LIBRE => U
            // 5 => VIUDO(A) => W

            if ( solicitante.ScotiaEstadoCivil ) this.estadoCivil = solicitante.ScotiaEstadoCivil
            else {
              if ( solicitante.EstadoCivil == 1 ) this.estadoCivil = 'M'
              if ( solicitante.EstadoCivil == 2 ) this.estadoCivil = 'D'
              if ( solicitante.EstadoCivil == 3 ) this.estadoCivil = 'S'
              if ( solicitante.EstadoCivil == 4 ) this.estadoCivil = 'U'
              if ( solicitante.EstadoCivil == 5 ) this.estadoCivil = 'W'
              if ( solicitante.EstadoCivil == 6 ) this.estadoCivil = 'P'
            }

            if ( this.obligado ) this.getRegimenSolicitante()

						this.adicionales.tiempoOcupandoDomicilioActual = solicitante.TiempoViviendoCiudad
						this.adicionales.paisNacimiento = solicitante.ScotiaPaisNacimiento
						this.adicionales.nss = this.normalizaCadena(solicitante.Nss, this.normaTipo.Numero);

						this.adicionales.numeroIfe = this.normalizaCadena(solicitante.NumeroIdentificacion, this.normaTipo.Numero);
						this.adicionales.vigencia = solicitante.VigenciaIdentificacion

						// GRADO DE ESTUDIOS
						// 1 	=> NINGUNO 							| O => OTRO
						// 2 	=> PRIMARIA 						| O => OTRO
						// 3 	=> SECUNDARIA 					| O => OTRO
						// 4 	=> BACHILLERATO TECNICO | O => OTRO
						// 5 	=> LICENCIATURA 				| O => OTRO
						// 6 	=> POSTGRADO 						| G => POSTGRADO(MEASTRIA O  DOCTORADO)
						// 7 	=> PREPARATORIA 				| H => PREPARATORIA
						// 9 	=> DOCTORADO 						| G => POSTGRADO(MEASTRIA O  DOCTORADO)
						// 8 	=> MAESTRIA 						| G => POSTGRADO(MEASTRIA O  DOCTORADO)
						// 10 => PASANTE 							| S => PASANTE

						if ( solicitante.ScotiaEscolaridad ) this.adicionales.gradoEstudios = solicitante.ScotiaEscolaridad
            else {
							if ( solicitante.Escolaridad == 1 )	this.adicionales.gradoEstudios = 'O'
							if ( solicitante.Escolaridad == 2 )	this.adicionales.gradoEstudios = 'O'
							if ( solicitante.Escolaridad == 3 )	this.adicionales.gradoEstudios = 'O'
							if ( solicitante.Escolaridad == 4 )	this.adicionales.gradoEstudios = 'O'
							if ( solicitante.Escolaridad == 5 )	this.adicionales.gradoEstudios = 'O'
							if ( solicitante.Escolaridad == 6 )	this.adicionales.gradoEstudios = 'G'
							if ( solicitante.Escolaridad == 7 )	this.adicionales.gradoEstudios = 'H'
							if ( solicitante.Escolaridad == 9 )	this.adicionales.gradoEstudios = 'G'
							if ( solicitante.Escolaridad == 8 )	this.adicionales.gradoEstudios = 'G'
							if ( solicitante.Escolaridad == 10 )	this.adicionales.gradoEstudios = 'S'
						}

						this.adicionales.antiguedadCiudadAnios = this.normalizaCadena(solicitante.ScotiaAntiguedadEnPoblacionActualAnios, this.normaTipo.Numero);

						// 2023-05-03 default value 0 if empty
						// this.adicionales.antiguedadCiudadMeses = this.normalizaCadena(solicitante.ScotiaAntiguedadEnPoblacionActualMeses, this.normaTipo.Numero);
						this.adicionales.antiguedadCiudadMeses = solicitante.ScotiaAntiguedadEnPoblacionActualMeses ? this.normalizaCadena(solicitante.ScotiaAntiguedadEnPoblacionActualMeses, this.normaTipo.Numero) : 0;

						this.adicionales.antiguedadDomicilioAnios =  this.normalizaCadena(solicitante.ScotiaAntiguedadEnDomicilioActualAnios, this.normaTipo.Numero);
						
						// 2023-05-03 default value 0 if empty
						// this.adicionales.antiguedadDomicilioMeses = this.normalizaCadena(solicitante.ScotiaAntiguedadEnDomicilioActualMeses, this.normaTipo.Numero);
						this.adicionales.antiguedadDomicilioMeses = solicitante.ScotiaAntiguedadEnDomicilioActualMeses ? this.normalizaCadena(solicitante.ScotiaAntiguedadEnDomicilioActualMeses, this.normaTipo.Numero) : 0;


						this.pais = solicitante.ScotiaPaisDomicilioActual
						this.adicionales.identificacionPresenta = solicitante.ScotiaTipoIdentificacion

						// TIPO DE VIVIENDA
						// 1 => RENTADO 						| R => RENTADA
						// 2 => PROPIO SIN HIPOTECA | P => PROPIA
						// 3 => PROPIO HIPOTECADO 	| H => HIPOTECADA
						// 4 => DE FAMILIARES 			| F => DE FAMILIARES
						// 5 => PRESTADO 						| O => OTRA
						// 6 => OTRO 								| O => OTRA

						if ( solicitante.ScotiaTipoVivienda ) { 
							this.adicionales.tipoVivienda = solicitante.ScotiaTipoVivienda 
						}
						else {
							if ( solicitante.TipoOcupacion === 1 ) this.adicionales.tipoVivienda = 'R'
							if ( solicitante.TipoOcupacion === 2 ) this.adicionales.tipoVivienda = 'P'
							if ( solicitante.TipoOcupacion === 3 ) this.adicionales.tipoVivienda = 'H'
							if ( solicitante.TipoOcupacion === 4 ) this.adicionales.tipoVivienda = 'F'
							if ( solicitante.TipoOcupacion === 5 ) this.adicionales.tipoVivienda = 'O'
							if ( solicitante.TipoOcupacion === 6 ) this.adicionales.tipoVivienda = 'O'
						}

						// this.adicionales.tipoVivienda = solicitante.ScotiaTipoVivienda

						this.garantia.tipo = solicitante.ScotiaTipoPropiedad
						this.garantia.uso = solicitante.ScotiaUsoVivienda
						this.garantia.estado = solicitante.ScotiaEstadoVivienda
						this.garantia.ubicacion = solicitante.ScotiaUbicacion

						if (solicitante.ScotiaParentescoppe != null) {
							this.parentescoppe = solicitante.ScotiaParentescoppe
						}
						if (solicitante.ScotiaPpe != null) {
							this.ppe = solicitante.ScotiaPpe
						}

						this.proposito = solicitante.ScotiaProposito
						this.otroproposito = solicitante.ScotiaPropositoOtro
						this.pago = solicitante.ScotiaPago

						// console.log({ 'pago': this.pago, 'form': solicitante.ScotiaPago })

						this.recursos = solicitante.ScotiaRecursos

						if (!getData) {
							this.municipios = this.municipios[parseInt(solicitante.Estado) - 1]
						}
						this.municipio = solicitante.ScotiaMunicipio

						this.adicionales.estadoNacimiento = solicitante.ScotiaEstadoNacimiento

						// 2023-05-25 Se mantiene
						// if (solicitante.ScotiaActividad2) {
						// 	this.otroIngreso = '1'
						// }

						// 2023-05-24 Ya no se usa
						// this.ingreso.comprobanteIngresos2 = solicitante.ScotiaComprobante2
						// this.ingreso.actividadFinanciera2 = solicitante.ScotiaActividad2
						// this.ingreso.ingresoMensual2 = solicitante.ScotiaIngreso2

						if (solicitante.ScotiaValorInmueble || solicitante.ScotiaValorAuto) {
							this.bienesFlag = '1'
							this.bienes.valorInmueble = solicitante.ScotiaValorInmueble
							this.bienes.valor = solicitante.ScotiaValorAuto
							// this.bienes.tipoInmueble = solicitante.ScotiaTipoInmueble
							this.bienes.marca = this.normalizaCadena(solicitante.ScotiaMarcaAuto, this.normaTipo.LetraNumeroEspacio);
							this.bienes.modelo = this.normalizaCadena(solicitante.ScotiaModeloAuto, this.normaTipo.LetraNumeroEspacio);
						}
						if (solicitante.Ingresos[0]) {
							this.tieneIngreso = true
							let ingreso = solicitante.Ingresos[0]
							this.ingreso.ingresoNeto = ingreso.IngresosNetos
							this.ingreso.ingresoMensual = ingreso.IngresosMensuales
							this.ingreso.empresa = this.normalizaCadena(ingreso.Empresa, this.normaTipo.LetraNumeroEspacio);
							this.ingreso.ingresoId = ingreso.Id
							this.ingreso.puesto = this.normalizaCadena(ingreso.Puesto, this.normaTipo.LetraNumeroEspacio);
							this.ingreso.domicilio = this.normalizaCadena(ingreso.Domicilio, this.normaTipo.LetraNumeroEspacio);
							this.ingreso.colonia = this.normalizaCadena(ingreso.Colonia, this.normaTipo.LetraNumeroEspacio);
							this.ingreso.codigoPostal = this.normalizaCadena(ingreso.CodigoPostal, this.normaTipo.Numero);
							this.ingreso.telefono = this.normalizaCadena(ingreso.Telefono, this.normaTipo.Numero);
							this.ingreso.pais = ingreso.Pais
							this.ingreso.estado = ingreso.EstadoId
							this.ingreso.tipoContrato = ingreso.TipoContratoId
							this.ingreso.giro = ingreso.ScotiaGiroEmpresa
							this.ingreso.sectorlaboral = ingreso.ScotiaSectorLaboral
							this.ingreso.ocupacion = ingreso.ScotiaOcupacion
							this.ingreso.actividad = ingreso.ScotiaActividad
							this.ingreso.actividadFinanciera = ingreso.ScotiaActividadFinanciera
							this.ingreso.comprobanteIngresos = ingreso.ScotiaComprobante
							this.dependientesEconomicos = this.normalizaCadena(ingreso.ScotiaDependientesEconomicos, this.normaTipo.Numero);
							this.ingreso.numeroExterior = this.normalizaCadena(ingreso.NumeroExterior, this.normaTipo.LetraNumero);
							this.ingreso.numeroInterior = this.normalizaCadena(ingreso.NumeroInterior, this.normaTipo.LetraNumero);
							this.ingreso.antiguedadAnios = ingreso.ScotiaAntiguedadOcupacionActualAnios
							this.ingreso.antiguedadMeses = ingreso.ScotiaAntiguedadOcupacionActualMeses
							if (!getData) {
								this.municipiosIngreso = this.municipiosIngreso[parseInt(ingreso.EstadoId) - 1]
							}
							this.ingreso.municipio = ingreso.Municipio

							// console.log( 'damesolicitante function', this.isSaveIngresoAdicionalRobot );
							// TODO: 2023-05-23 Obtener las demas actividades financieras si existen
							if ( !this.isSaveIngresoAdicionalRobot && solicitante.Ingresos.length > 1 ) {
								// si no se ha guardado obtengo los ingresos del master
								let listaIngresoAdicional = [...solicitante.Ingresos] // rompo la referencia
								listaIngresoAdicional.shift() // elimino el ingreso principal (el primero)

								// si son mas de 3 ingresos adicionales se borran los siguientes, quedan solo 3
								if ( listaIngresoAdicional.length > 3 ) {
									let ingresosEliminados = listaIngresoAdicional.splice(3) // modifico el array de ingresos
									
									// si hay ingresos eliminados
									if (ingresosEliminados.length > 0) {
										// formato de ingresos eliminados
										let formatoIngresoEliminado = []
										for (let ieItem of ingresosEliminados) {
											formatoIngresoEliminado.push({
												Id: ieItem.Id, 
												actividadFinanciera: null,
												comprobanteIngresos: null,
												IngresosMensuales: ieItem.IngresosMensuales
											})
										}

										this.ingresoAdicionalEliminado = formatoIngresoEliminado
									}
								}
								
								// formato a los ingresos
								// actualmente solo se obtiene el monto mensual del ingreso, no se hace conversion
								let formatIngresoAdicional = []
								for (let fia of listaIngresoAdicional) {
									formatIngresoAdicional.push({
										Id: fia.Id, 
										actividadFinanciera: null,
										comprobanteIngresos: null,
										IngresosMensuales: fia.IngresosMensuales
									})
								}

								// se igualan los arrays
								this.ingresoAdicionalList = formatIngresoAdicional

								// console.log( 'Obteniendo ingresos desde el master', formatIngresoAdicional );
								// console.log( 'Ingresos eliminados por limite ', this.ingresoAdicionalEliminado)
							}

							// 2023-05-23 cuando se guardo solo se obtien json de bd
							if ( this.isSaveIngresoAdicionalRobot ) {
								// console.log( 'Obteniendo ingresos desde bd previamente guardada' );
								const adicionales = solicitante.ScotiaIngresoAdicional !== 'null' && solicitante.ScotiaIngresoAdicional ? JSON.parse(solicitante.ScotiaIngresoAdicional) : []
								this.ingresoAdicionalList = adicionales
								// TODO: verificacion de estructura de envio a SEA
							}

						}

						// 2023-05-25 Se valida si hay contenido en el array TODO: (Revisar si funciona adecuadamente)
						if (this.ingresoAdicionalList.length > 0) {
							this.otroIngreso = '1'
						}

						// if ( solicitante.Conyuge ) {

						// 	this.getConyuge(solicitante.Conyuge)

						// }

						// Datos del Conyuge
						// this.conyuge.nombre = solicitante.ScotiaConyugeNombre
						// this.conyuge.apellidoPaterno = solicitante.ScotiaConyugeApellidoPaterno
						// this.conyuge.apellidoMaterno = solicitante.ScotiaConyugeApellidoMaterno
						// this.conyuge.rfc = solicitante.ScotiaConyugeRfc
						// this.conyuge.curp = solicitante.ScotiaConyugeCurp

						this.conyuge.nombre = this.normalizaCadena(solicitante.ScotiaConyugeNombre, this.normaTipo.LetraEspacio);
						this.conyuge.apellidoPaterno = this.normalizaCadena(solicitante.ScotiaConyugeApellidoPaterno, this.normaTipo.LetraEspacio);
						this.conyuge.apellidoMaterno = this.normalizaCadena(solicitante.ScotiaConyugeApellidoMaterno, this.normaTipo.LetraEspacio);
						// BUG: v-mask afecta el formato en la ultima parte para rc y curp
						this.conyuge.rfc = this.normalizaCadena(solicitante.ScotiaConyugeRfc, this.normaTipo.LetraNumero);
						this.conyuge.curp = this.normalizaCadena(solicitante.ScotiaConyugeCurp, this.normaTipo.LetraNumero);

						// if (solicitante.Sexo == 1) {
						// 	this.sexo = 'H'
						// } else if (solicitante.Sexo == 2) {
						// 	this.sexo = 'M'
						// }

						/* if(solicitante.Nombres=='' || solicitante.ApellidoPaterno=='' || solicitante.ApellidoMaterno=='' || solicitante.TelefonoCelular=='' ||
            ingreso.ScotiaDependientesEconomicos=='' || solicitante.NumeroIdentificacion=='' || solicitante.VigenciaIdentificacion==''|| 
            solicitante.Calle== '' || solicitante.NumeroExterior=='' || solicitante.Colonia=='' || solicitante.ScotiaAntiguedadEnPoblacionActualAnios=='' ||
            solicitante.ScotiaAntiguedadEnPoblacionActualMeses=='' || solicitante.ScotiaAntiguedadEnDomicilioActualAnios=='' || solicitante.ScotiaAntiguedadEnDomicilioActualMeses=='' ||
            solicitante.Ingresos[0].Domicilio=='' || solicitante.Ingresos[0].NumeroExterior=='' || solicitante.Ingresos[0].Colonia==''
            || solicitante.Ingresos[0].Puesto=='' || solicitante.Ingresos[0].Telefono==''  || solicitante.Ingresos[0].ScotiaAntiguedadOcupacionActualAnios=='' ||
            solicitante.Ingresos[0].ScotiaAntiguedadOcupacionActualMeses==''
            ){
                this.emailCasoNuevo.bandera= 'IncompletosPersonales'
                this.$emit("bandera",  this.emailCasoNuevo.bandera)
                this.ActualizaBanderaPersonales(true)

            }*/

						if (solicitante.Nombres == '' || solicitante.ApellidoPaterno == '' || solicitante.ApellidoMaterno == '' || solicitante.TelefonoCelular == '' || solicitante.Ingresos[0].ScotiaDependientesEconomicos == '' || solicitante.NumeroIdentificacion == '' || solicitante.VigenciaIdentificacion == '' || solicitante.Calle == '' || solicitante.NumeroExterior == '' || solicitante.Colonia == '' || solicitante.ScotiaAntiguedadEnPoblacionActualAnios == '' || solicitante.ScotiaAntiguedadEnPoblacionActualMeses == '' || solicitante.ScotiaAntiguedadEnDomicilioActualAnios == '' || solicitante.ScotiaAntiguedadEnDomicilioActualMeses == '' || solicitante.Ingresos[0].Domicilio == '' || solicitante.Ingresos[0].NumeroExterior == '' || solicitante.Ingresos[0].Colonia == '' || solicitante.Ingresos[0].Puesto == '' || solicitante.Ingresos[0].Telefono == '' || solicitante.Ingresos[0].ScotiaAntiguedadOcupacionActualAnios == '' || solicitante.Ingresos[0].ScotiaAntiguedadOcupacionActualMeses == '') {
							this.emailCasoNuevo.bandera = 'IncompletosPersonales'
							this.$emit('bandera', this.emailCasoNuevo.bandera)
							this.ActualizaBanderaPersonales(true)
						}

						//alert('dameSoliciatnte'+this.sexo)
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameSolicitante dameSolicitante 1',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema dameSolicitante 2',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
    getRegimenSolicitante() {
      let self=this
      var objRequestDameSolicitante = {
		strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
          EMail: this.obligado.EMail
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){

						// console.log( 'REGIMEN', response.data.objContenido );

            const dataObligado = response.data.objContenido 

						// console.log(dataObligado.RegimenMatrimonial)

            this.regimenMatrimonial = dataObligado.RegimenMatrimonial

          }else {
            this.$vs.notify({
              title:'Ocurrio un error en getRegimenSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
		getApikey() {
			if (!this.$cookie.get('key')) {
				this.$router.push('/login')
			} else {
				this.key = this.$cookie.get('key')
			}
		},
		getEstados() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaEstados',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.estados = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaEstados',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getListaRegimenes() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaRegimenesMatrimoniales',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.regimenes = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaRegimenesMatrimoniales anali',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		registraAdicionales() {
			let self = this
			var objRequestAdicionales = {
				strApiKey: this.key,
				strMetodo: 'RegistraAdicionalesSolicitud',
				objCaso: {
					Id: this.id,
					Personales: {
						Nss: this.adicionales.nss,
					},
					DomicilioActual: {
						TiempoOcupandoDomicilioActual: this.adicionales.tiempoOcupandoDomicilioActual,
					},
				},
			}
			this.$axios
				.post('/', objRequestAdicionales, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Guardardo exitosamente`,
							position: 'top-right',
						})
						this.addIngreso()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraAdicionalesSolicitud',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema Adicionales',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getTiposContratos() {
			let self = this
			var objRequestListaTipoContratos = {
				strApiKey: this.key,
				strMetodo: 'ListaTipoContratos',
			}
			this.$axios
				.post('/', objRequestListaTipoContratos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.tiposContratos = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaTipoContratos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema getTiposContratos',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getConyuge(emailConyuge) {

			let self = this
			var objRequestDameSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'DameSolicitante',
				objSolicitante: {
					EMail: emailConyuge,
				},
			}
			this.$axios
				.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {

					const status = response.intEstatus

					if ( status ) {

						this.$vs.notify({
							title: 'Ocurrio un error en DameSolicitante - Conyugue',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})

					}

					const conyuge = response.data.objContenido

					this.conyuge.nombre = this.normalizaCadena(conyuge.Nombres, this.normaTipo.LetraEspacio);
					this.conyuge.apellidoPaterno = this.normalizaCadena(conyuge.ApellidoPaterno, this.normaTipo.LetraEspacio);
					this.conyuge.apellidoMaterno = this.normalizaCadena(conyuge.ApellidoMaterno, this.normaTipo.LetraEspacio);
					// BUG: v-mask afecta el formato en la ultima parte para rc y curp
					this.conyuge.rfc = this.normalizaCadena(conyuge.Rfc, this.normaTipo.LetraNumero);
					this.conyuge.curp = this.normalizaCadena(conyuge.Curp, this.normaTipo.LetraNumero);

				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema getConyuge',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})

		},
		isNumber: function (evt) {
			evt = evt ? evt : window.event
			var charCode = evt.which ? evt.which : evt.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
				evt.preventDefault()
			} else {
				return true
			}
		},
	},
}
</script>

<style lang="css">
.vs-component.vs-con-input-label.vs-input.vs-input-primary {
    border: none;
}
.label-select{
  margin-top: 3%;
}
.input-custom .vs-con-input input{
  padding: .5rem !important;
}
.con-vs-radio{
  margin-right: 5%;
}
label.span-placeholder,
span.vs-radio--label {
    color: #868D96 !important;
}
.accordion label{
  display:flex;
}

.btn-large {
	min-width: 100%;
	height: 100%;
	margin: 0 !important;
}
</style>
