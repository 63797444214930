export const paises = [
  {Id:'201', Nombre: "AFGANISTÁN"},
  {Id:'101', Nombre: "ALBANIA"},
  {Id:'102', Nombre: "ALEMANIA"},
  {Id:'445', Nombre: "ANDORRA"},
  {Id:'401', Nombre: "ANGOLA"},
  {Id:'446', Nombre: "ANGUILA"},
  {Id:'31', Nombre: "ANTIGUA Y BARBUDA"},
  {Id:'133', Nombre: "ANTILLAS HOLANDESAS"},
  {Id:'447', Nombre: "ANTÁRTIDA"},
  {Id:'202', Nombre: "ARABIA SAUDITA"},
  {Id:'402', Nombre: "ARGELIA"},
  {Id:'3', Nombre: "ARGENTINA"},
  {Id:'235', Nombre: "ARMENIA"},
  {Id:'131', Nombre: "ARUBA"},
  {Id:'301', Nombre: "AUSTRALIA"},
  {Id:'103', Nombre: "AUSTRIA"},
  {Id:'236', Nombre: "AZERBAIYÁN"},
  {Id:'36', Nombre: "BAHAMAS"},
  {Id:'448', Nombre: "BAHREIN"},
  {Id:'203', Nombre: "BANGLADESH"},
  {Id:'32', Nombre: "BARBADOS"},
  {Id:'5', Nombre: "BELICE"},
  {Id:'449', Nombre: "BENIN"},
  {Id:'129', Nombre: "BERMUDAS"},
  {Id:'450', Nombre: "BIELORRUSIA"},
  {Id:'7', Nombre: "BOLIVIA"},
  {Id:'451', Nombre: "BONAIRE, SAN EUSTAQUIO Y SABA"},
  {Id:'144', Nombre: "BOSNIA Y HERZEGOVINA (REPÚBLIC"},
  {Id:'404', Nombre: "BOTSUANA"},
  {Id:'9', Nombre: "BRASIL"},
  {Id:'452', Nombre: "BRUNÉI DARUSSALAM"},
  {Id:'105', Nombre: "BULGARIA"},
  {Id:'453', Nombre: "BURKINA FASO"},
  {Id:'204', Nombre: "BURMA (MYANMAR)"},
  {Id:'403', Nombre: "BURUNDI"},
  {Id:'454', Nombre: "BUTÁN"},
  {Id:'104', Nombre: "BÉLGICA"},
  {Id:'455', Nombre: "CABO VERDE"},
  {Id:'237', Nombre: "CAMBOYA"},
  {Id:'405', Nombre: "CAMERÚN"},
  {Id:'11', Nombre: "CANADÁ"},
  {Id:'19', Nombre: "CHILE"},
  {Id:'208', Nombre: "CHINA"},
  {Id:'209', Nombre: "CHIPRE"},
  {Id:'13', Nombre: "COLOMBIA"},
  {Id:'207', Nombre: "COREA DEL NORTE"},
  {Id:'550', Nombre: "COREA DEL SUR"},
  {Id:'406', Nombre: "COSTA DE MARFIL (COTED IVOIRE)"},
  {Id:'15', Nombre: "COSTA RICA"},
  {Id:'134', Nombre: "CROACIA"},
  {Id:'17', Nombre: "CUBA"},
  {Id:'456', Nombre: "CURAÇAO"},
  {Id:'107', Nombre: "DINAMARCA"},
  {Id:'457', Nombre: "DJIBOUTI"},
  {Id:'35', Nombre: "DOMINICA"},
  {Id:'21', Nombre: "ECUADOR"},
  {Id:'409', Nombre: "EGIPTO"},
  {Id:'23', Nombre: "EL SALVADOR"},
  {Id:'441', Nombre: "EMIRATOS ARABES"},
  {Id:'458', Nombre: "ERITREA"},
  {Id:'139', Nombre: "ESLOVAQUIA"},
  {Id:'140', Nombre: "ESLOVENIA"},
  {Id:'108', Nombre: "ESPAÑA"},
  {Id:'2', Nombre: "ESTADOS UNIDOS"},
  {Id:'136', Nombre: "ESTONIA"},
  {Id:'410', Nombre: "ETHIOPIA"},
  {Id:'459', Nombre: "FIJI"},
  {Id:'239', Nombre: "FILIPINAS"},
  {Id:'110', Nombre: "FINLANDIA"},
  {Id:'111', Nombre: "FRANCIA"},
  {Id:'411', Nombre: "GABÓN"},
  {Id:'443', Nombre: "GAMBIA"},
  {Id:'460', Nombre: "GEORGIA DEL SUR Y LAS ISLAS SA"},
  {Id:'412', Nombre: "GHANA"},
  {Id:'461', Nombre: "GIBRALTAR"},
  {Id:'37', Nombre: "GRANADA"},
  {Id:'112', Nombre: "GRECIA"},
  {Id:'4', Nombre: "GROENLANDIA"},
  {Id:'462', Nombre: "GUADALUPE"},
  {Id:'463', Nombre: "GUAM"},
  {Id:'6', Nombre: "GUATEMALA"},
  {Id:'8', Nombre: "GUAYANA"},
  {Id:'10', Nombre: "GUAYANA FRANCESA"},
  {Id:'464', Nombre: "GUERNSEY"},
  {Id:'444', Nombre: "GUINEA"},
  {Id:'465', Nombre: "GUINEA ECUATORIAL"},
  {Id:'466', Nombre: "GUINEA-BISSAU"},
  {Id:'12', Nombre: "HAITÍ"},
  {Id:'14', Nombre: "HONDURAS"},
  {Id:'210', Nombre: "HONG KONG"},
  {Id:'115', Nombre: "HUNGRÍA"},
  {Id:'211', Nombre: "INDIA"},
  {Id:'212', Nombre: "INDOCHINA"},
  {Id:'213', Nombre: "INDONESIA"},
  {Id:'214', Nombre: "IRAK"},
  {Id:'116', Nombre: "IRLANDA"},
  {Id:'215', Nombre: "IRÁN"},
  {Id:'545', Nombre: "ISLA BOUVET"},
  {Id:'546', Nombre: "ISLA DE MAN"},
  {Id:'547', Nombre: "ISLA DE NAVIDAD"},
  {Id:'548', Nombre: "ISLA HEARD E ISLAS MCDONALD"},
  {Id:'117', Nombre: "ISLANDIA"},
  {Id:'467', Nombre: "ISLAS ALAND"},
  {Id:'34', Nombre: "ISLAS CAIMÁN"},
  {Id:'38', Nombre: "ISLAS COOK (LAS)"},
  {Id:'468', Nombre: "ISLAS FALKLAND (MALVINAS)"},
  {Id:'469', Nombre: "ISLAS FAROE"},
  {Id:'470', Nombre: "ISLAS MARIANAS DEL NORTE (LAS)"},
  {Id:'471', Nombre: "ISLAS MARSHALL (LAS)"},
  {Id:'549', Nombre: "ISLAS NORFOLK"},
  {Id:'472', Nombre: "ISLAS PITCAIRN"},
  {Id:'473', Nombre: "ISLAS REUNIÓN"},
  {Id:'474', Nombre: "ISLAS SALOMÓN (LAS)"},
  {Id:'33', Nombre: "ISLAS VÍRGENES (EE.UU.)"},
  {Id:'475', Nombre: "ISLAS VÍRGENES INGLESAS"},
  {Id:'216', Nombre: "ISRAEL"},
  {Id:'118', Nombre: "ITALIA"},
  {Id:'16', Nombre: "JAMAICA"},
  {Id:'217', Nombre: "JAPÓN"},
  {Id:'476', Nombre: "JERSEY"},
  {Id:'218', Nombre: "JORDANIA"},
  {Id:'477', Nombre: "KATAR"},
  {Id:'478', Nombre: "KAZAJISTÁN"},
  {Id:'413', Nombre: "KENYA"},
  {Id:'479', Nombre: "KIRGUISTÁN"},
  {Id:'480', Nombre: "KIRIBATÍ"},
  {Id:'219', Nombre: "KUEIT"},
  {Id:'481', Nombre: "KUWAIT"},
  {Id:'483', Nombre: "LA REPÚBLICA DE COREA DEL SUR"},
  {Id:'485', Nombre: "LA REPÚBLICA DEMOCRÁTICA POPUL"},
  {Id:'486', Nombre: "LA REPÚBLICA DEMOCRÁTICA POPUL"},
  {Id:'482', Nombre: "LA REPÚBLICA ÁRABE DE SIRIA"},
  {Id:'39', Nombre: "LAS GRANADINAS"},
  {Id:'487', Nombre: "LAS ISLAS DE ULTRAMAR MENORES"},
  {Id:'488', Nombre: "LAS ISLAS TURCAS Y CAICOS"},
  {Id:'414', Nombre: "LESOTHO"},
  {Id:'489', Nombre: "LETONIA"},
  {Id:'415', Nombre: "LIBERIA"},
  {Id:'416', Nombre: "LIBIA"},
  {Id:'490', Nombre: "LIECHTENSTEIN"},
  {Id:'137', Nombre: "LITUANIA"},
  {Id:'491', Nombre: "LOS EMIRATOS ÁRABES UNIDOS"},
  {Id:'492', Nombre: "LOS PAÍSES BAJOS (HOLANDA)"},
  {Id:'119', Nombre: "LUXEMBURGO"},
  {Id:'221', Nombre: "LÍBANO"},
  {Id:'493', Nombre: "MACAO"},
  {Id:'494', Nombre: "MACEDONIA (LA ANTIGUA REPÚBLIC"},
  {Id:'495', Nombre: "MADAGASCAR"},
  {Id:'222', Nombre: "MALASIA"},
  {Id:'417', Nombre: "MALAWI"},
  {Id:'496', Nombre: "MALDIVIAS"},
  {Id:'418', Nombre: "MALI"},
  {Id:'138', Nombre: "MALTA"},
  {Id:'419', Nombre: "MARRUECOS"},
  {Id:'497', Nombre: "MARTINICA"},
  {Id:'498', Nombre: "MAURICIO"},
  {Id:'420', Nombre: "MAURITANIA"},
  {Id:'499', Nombre: "MAYOTTE"},
  {Id:'500', Nombre: "MICRONESIA (LOS ESTADOS FEDERA"},
  {Id:'501', Nombre: "MOLDAVIA (LA REPÚBLICA DE)"},
  {Id:'223', Nombre: "MONGOLIA"},
  {Id:'503', Nombre: "MONTENEGRO"},
  {Id:'504', Nombre: "MONTSERRAT"},
  {Id:'421', Nombre: "MOZAMBIQUE"},
  {Id:'142', Nombre: "MYANMAR"},
  {Id:'1', Nombre: "MÉXICO"},
  {Id:'502', Nombre: "MÓNACO"},
  {Id:'505', Nombre: "NAMIBIA"},
  {Id:'143', Nombre: "NAURU"},
  {Id:'224', Nombre: "NEPAL"},
  {Id:'18', Nombre: "NICARAGUA"},
  {Id:'422', Nombre: "NIGER (EL)"},
  {Id:'423', Nombre: "NIGERIA"},
  {Id:'506', Nombre: "NIUE"},
  {Id:'238', Nombre: "NORTH KOREA"},
  {Id:'120', Nombre: "NORUEGA"},
  {Id:'507', Nombre: "NUEVA CALEDONIA"},
  {Id:'303', Nombre: "NUEVA ZELANDIA"},
  {Id:'225', Nombre: "OMÁN"},
  {Id:'999', Nombre: "OTRO"},
  {Id:'226', Nombre: "PAKISTÁN"},
  {Id:'508', Nombre: "PALAOS"},
  {Id:'442', Nombre: "PALESTINA, ESTADO DE"},
  {Id:'20', Nombre: "PANAMÁ"},
  {Id:'509', Nombre: "PAPUA NUEVA GUINEA"},
  {Id:'22', Nombre: "PARAGUAY"},
  {Id:'24', Nombre: "PERÚ"},
  {Id:'510', Nombre: "POLINESIA FRANCESA"},
  {Id:'121', Nombre: "POLONIA"},
  {Id:'122', Nombre: "PORTUGAL"},
  {Id:'25', Nombre: "PUERTO RICO"},
  {Id:'113', Nombre: "REINO UNIDO"},
  {Id:'425', Nombre: "REP. CENTRAL AFRICANA"},
  {Id:'145', Nombre: "REPUBLICA DE MACEDONIA"},
  {Id:'484', Nombre: "REPUBLICA DEMOCRATICA DEL CONG"},
  {Id:'135', Nombre: "REPÚBLICA CHECA (LA)"},
  {Id:'511', Nombre: "REPÚBLICA DE GEORGIA"},
  {Id:'512', Nombre: "REPÚBLICA DE LA UNIÓN DE MYANM"},
  {Id:'513', Nombre: "REPÚBLICA DEL CHAD"},
  {Id:'424', Nombre: "REPÚBLICA DEL CONGO"},
  {Id:'515', Nombre: "REPÚBLICA DEMOCRÁTICA DE SANTO"},
  {Id:'516', Nombre: "REPÚBLICA DEMOCRÁTICA DE TIMOR"},
  {Id:'26', Nombre: "REPÚBLICA DOMINICANA"},
  {Id:'517', Nombre: "REPÚBLICA UNIDA DE TANZANIA"},
  {Id:'427', Nombre: "RUANDA"},
  {Id:'123', Nombre: "RUMANIA"},
  {Id:'124', Nombre: "RUSIA"},
  {Id:'518', Nombre: "SAHARA OCCIDENTAL"},
  {Id:'519', Nombre: "SAMOA AMERICANA"},
  {Id:'520', Nombre: "SAMOA OESTE"},
  {Id:'521', Nombre: "SAN BARTOLOMÉ"},
  {Id:'522', Nombre: "SAN CRISTÓBAL Y NIEVES"},
  {Id:'523', Nombre: "SAN MARINO"},
  {Id:'524', Nombre: "SAN MARTÍN (PARTE FRANCESA)"},
  {Id:'525', Nombre: "SAN PEDRO Y MIQUELÓN"},
  {Id:'40', Nombre: "SAN VICENTE Y LAS GRANADINAS"},
  {Id:'526', Nombre: "SANTA HELENA (SANTA HELENA, AS"},
  {Id:'527', Nombre: "SANTA LUCÍA"},
  {Id:'429', Nombre: "SENEGAL"},
  {Id:'146', Nombre: "SERBIA"},
  {Id:'528', Nombre: "SEYCHELLES"},
  {Id:'430', Nombre: "SIERRA LEONA"},
  {Id:'233', Nombre: "SINGAPUR"},
  {Id:'529', Nombre: "SINT MAARTEN (PARTE HOLANDESA)"},
  {Id:'228', Nombre: "SIRIA"},
  {Id:'431', Nombre: "SOMALIA"},
  {Id:'206', Nombre: "SRI LANKA"},
  {Id:'433', Nombre: "SUDÁFRICA"},
  {Id:'432', Nombre: "SUDÁN"},
  {Id:'514', Nombre: "SUDÁN DEL SUR"},
  {Id:'125', Nombre: "SUECIA"},
  {Id:'126', Nombre: "SUIZA"},
  {Id:'27', Nombre: "SURINAM"},
  {Id:'530', Nombre: "SVALBARD Y JAN MAYEN"},
  {Id:'531', Nombre: "SWAZILANDIA"},
  {Id:'41', Nombre: "TAHITI"},
  {Id:'229', Nombre: "TAILANDIA"},
  {Id:'234', Nombre: "TAIWÁN (PROVINCIA DE CHINA)"},
  {Id:'532', Nombre: "TAYIKISTÁN"},
  {Id:'533', Nombre: "TERRITORIO BRITÁNICO DEL OCÉAN"},
  {Id:'534', Nombre: "TERRITORIO DE LAS ISLAS COCOS"},
  {Id:'535', Nombre: "TERRITORIOS AUSTRALES FRANCESE"},
  {Id:'435', Nombre: "TOGO"},
  {Id:'536', Nombre: "TOKELAU"},
  {Id:'537', Nombre: "TONGA"},
  {Id:'28', Nombre: "TRINIDAD Y TOBAGO"},
  {Id:'538', Nombre: "TURKMENISTÁN"},
  {Id:'230', Nombre: "TURQUÍA"},
  {Id:'539', Nombre: "TUVALU"},
  {Id:'436', Nombre: "TÚNEZ"},
  {Id:'127', Nombre: "UCRANIA"},
  {Id:'437', Nombre: "UGANDA"},
  {Id:'141', Nombre: "UNION MONETARIA EUROPEA"},
  {Id:'540', Nombre: "UNIÓN DE LAS COMORAS (COMORAS"},
  {Id:'29', Nombre: "URUGUAY"},
  {Id:'541', Nombre: "UZBEKISTÁN"},
  {Id:'542', Nombre: "VANUATU"},
  {Id:'543', Nombre: "VATICANO SANTA SEDE (ESTADO DE"},
  {Id:'30', Nombre: "VENEZUELA, REPÚBLICA BOLIVARIA"},
  {Id:'231', Nombre: "VIET NAM"},
  {Id:'544', Nombre: "WALLIS Y FUTUNA"},
  {Id:'232', Nombre: "YEMEN"},
  {Id:'128', Nombre: "YUGOSLAVIA"},
  {Id:'439', Nombre: "ZAMBIA"},
  {Id:'440', Nombre: "ZIMBABUE"},
]
