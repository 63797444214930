import { render, staticRenderFns } from "./ObligadoSolidario.vue?vue&type=template&id=fed0f3e8&scoped=true&lang=html&"
import script from "./ObligadoSolidario.vue?vue&type=script&lang=js&"
export * from "./ObligadoSolidario.vue?vue&type=script&lang=js&"
import style0 from "./ObligadoSolidario.vue?vue&type=style&index=0&id=fed0f3e8&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fed0f3e8",
  null
  
)

export default component.exports