<template>
  <div class="">
    <div class="vx-row" v-if="correo == ''">
      <div class="vx-col w-full md:w-3/3 sm:w-2/2 mb-base">
        <vx-card class="text-center cursor-pointer">
          <img src="@/assets/images/pages/graphic-2.png" alt="graphic" width="180" class="mx-auto mb-4">
          <h4 class="mb-2">Añade un coacreditado</h4>
          <small>Regresa al apartado solicitante para agregar un coacreditado</small>
        </vx-card>
      </div>
    </div>
    <div class="vx-row" v-else>
      <div class="vx-col w-full">
        <vx-card title="Documentos requeridos" class="">
          <vs-row vs-w="12" vs-justify="space-between">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-sm="12" vs-xs="12" vs-offset="1" v-for="documento in documentos"  :key="documento.Id">
              <div class="">
                <vs-row vs-w="12" vs-justify="space-between">
                  <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-lg="12" vs-sm="12" vs-xs="12">
                    <h6>{{documento.Nombre}}</h6>
                    <feather-icon icon="DownloadIcon" @click='download(documento.Disponible)' v-if="documento.Disponible"/>
                  </vs-col>
                </vs-row>
                <br>
                <vs-row vs-w="12" vs-justify="space-between">
                  <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-lg="12" vs-sm="12" vs-xs="12">
                    <input class="w-full mb-base" type="file" accept=".jpg,.jpeg,.png,.gif,.pdf" @change="obtenerImagen($event,documento.Id)"></vs-input>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>
          </vs-row>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      correo:'',
      key:'',
      id:'',
      documentos:[]
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    this.dameCaso()
  },
  methods:{
    download(id){
      window.open('https://cotizador.socasesores.com/apipro//?f='+id+'&k='+this.key);
    },
    openDoc(id){
      // https://cotizador.socasesores.com/uploads/expediente.php?f=97.jpg
      window.open('https://cotizador.socasesores.com/expedientes/'+id, '_blank');
    },
    obtenerImagen(e,number){
      this.registrarSolicitante(e.target.files[0],number)
    },
    registrarSolicitante(file,number){
      let self= this
      let formData = new FormData();
      formData.append(number, file);
      formData.append('MAX_FILE_SIZE','9999999')
      formData.append('request', '{"strApiKey": "'+this.key+'","strMetodo": "RegistraSolicitante","objSolicitante": {"EMail": "'+this.correo+'"}}');
      this.$axios.post('/',formData,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`El documento se subio exitosamente`,
      	      position:'top-right'
            })
            this.dameCaso()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }

      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            if (response.data.objContenido.Coacreditado[0]) {
              this.correo= response.data.objContenido.Coacreditado[0].EMail
            }
            this.documentos=response.data.objContenido.Documentos
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
  }
}
</script>

<style lang="css" scoped>
  span{
    margin-left: 3%;
    cursor: pointer;
  }
</style>
