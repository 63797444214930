<template lang="html">
  <div id="main">
    <div class="vx-row" >
      <div class="vx-col w-full">
        <vx-card title="Reporte crediticio" class="">
          <!--<vs-alert color="danger" title="Completa tu Información" :active="!completeInfo">
            Ve al apartado de información personal y completa los datos generales para poder consultar el buró de crédito.
          </vs-alert>
          <br>-->
           <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder" for="tipoConsulta">Tipo de consulta</span>
              <select name="cars" id="tipoConsulta" v-model="reporteCrediticio.tipoConsulta" class="vs-input" @change="updateValores()">
                <option :value='tipo.Id' v-for="tipo in tiposConsultas"  :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
            <div class="col-md-8 aling-center" v-if="reporteCrediticio.tipoConsulta==66">
              <span class="span-placeholder" for="tipoConsulta">Es necesario realizar el llenado y firmado del siguiente formato:</span>
              <vx-tooltip text="Descargar Autorización de consulta de buró de crédito" >
                <div class=" anexos-pro cursor"><a :href="'https://cotizador.socasesores.com/admin/formatos/anexos/ries-014.doc'">Autorización de consulta de buró de crédito</a></div>
              </vx-tooltip>
              <br>
              <span class="span-placeholder" for="tipoConsulta">Autorización de consulta de buró de crédito:</span>
              <file-base64 accept="application/pdf,image/jpeg,image/jpg,image/tiff,.tif" :done="getAutorizacionCredito"></file-base64>
              <div class="text-center">
                <img src="" alt="" :src="reporteCrediticio.autorizacionCredito.base64" style="width: 20%;" @change="updateValores()">
                <span class="text-danger span-placeholder" v-if="muestraAlertaTipoArchivo">{{textoAlertaAutorizacion}}</span>
              </div>
              <span class="span-placeholder" for="tipoConsulta">{{reporteCrediticio.nombreAutorizacion}}</span>
            </div>
          </div> 
          <div class="row w-full" v-if="reporteCrediticio.tipoConsulta==66">
            <span class="span-placeholder" for="tipoConsulta">Identificación oficial:</span>
            <file-base64 accept="application/pdf,image/jpeg,image/jpg,image/tiff,.tif" :done="getIdentificacion"></file-base64>
            <div class="text-center">
              <img src="" alt="" :src="reporteCrediticio.identificacion.base64" style="width: 20%;" @change="updateValores()">
              <span class="text-danger span-placeholder" v-if="muestraAlertaTipoArchivoIne">Solo se aceptan archivos con formato pdf, jpe, jpeg, tiff y tif</span>
            </div>
            <span class="span-placeholder" for="tipoConsulta">{{reporteCrediticio.nombreIdentificacion}}</span>
          </div> 
          <div class="row w-full" v-if="reporteCrediticio.tipoConsulta==67">
            <div class="col-md-12 alinear">
              <div class="col-md-6">
               
                  <span for="">¿Cuenta con una tarjeta de crédito vigente?</span>
              </div>
              <div class="col-md-1">    
                  <vs-switch v-model="reporteCrediticio.tarjeta" @change="updateValores()"/>
                
              </div>
            </div>
 
            <div class="col-md-4" v-if="reporteCrediticio.tarjeta">
              <span class="span-placeholder">Últimos 4 dígitos</span>
              <input class="vs-input" label="Ùltimos 4 digitos" v-model="reporteCrediticio.digitos" maxlength="4" name="name" @change="updateValores()" v-on:keypress="isNumber($event)"/>
            </div>
          </div>
          <div class="row w-full" v-if="reporteCrediticio.tipoConsulta==67">             
            <div class="col-md-12 alinear">
              <div class="col-md-6">
               
                  <span for="">¿Cuenta con un crédito hipotecario vigente?</span>
              </div>
              <div class="col-md-1">    
                  <vs-switch v-model="reporteCrediticio.creditoHipotecario" @change="updateValores()"/>
                
              </div>
            </div>
          </div>
          <div class="row w-full" v-if="reporteCrediticio.tipoConsulta==67">  
            <div class="col-md-12 alinear">
              <div class="col-md-6">
               
                  <span for="">¿Ha tenido un crédito automotriz en los últimos 24 meses?</span>
              </div>
              <div class="col-md-1">    
                  <vs-switch v-model="reporteCrediticio.creditoAuto" @change="updateValores()"/>
                
              </div>
            </div>
          </div>
          <div >
              <!--<input class="vs-input" v-model="datosPersonales.curp" name="curp" v-validate="'min:18|max:18'" @change="updateValores()"/>-->
          </div>
          <br>
          <!--<vs-button color="primary" @click="actualizarInfo()" :disabled="!completeInfo">Consultar reporte crediticio</vs-button>-->
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import fileBase64 from '../../vue-file-base64.vue';
export default {
  data(){
		return{
      tiposConsultas:[],
      popupActive:false,
      creditos:[],
      id:'',
      key:'',
      reporteCrediticio:{
        email:'',
        tarjeta:'',
        digitos:'',
        creditoHipotecario:'',
        creditoAuto:'',
        tipoConsulta:'',
        autorizacionCredito:[],
        identificacion:[],
        nombreAutorizacion:'',
        nombreIdentificacion:'',
        validaReporteCrediticio:false
      },
      validaTradicional:false,
      validaAutenticada:false,
      muestraAlertaTipoArchivo:false,
      muestraAlertaTipoArchivoIne:false,
      textoAlertaAutorizacion:'',
      textoAlertaIne:''
    }
  },
  components: { fileBase64 },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    this.dameDatosReporteBuro()
    //this.dameCaso()
    this.getTipoConsultas()
  },
  methods:{
    dameDatosReporteBuro(){
            let self= this
            var objRequestDameSolicitante = {
                strApiKey: this.key,
                strMetodo: "DamePrecalificacionBancos",
                objPrecalificacionBanco:
                {
                    IdPrecalificacion: this.id
                }
            }
            this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(
                response => {
                if(response.data.intEstatus == 0){
                    let solicitante =response.data.objContenido
                    this.reporteCrediticio.tipoConsulta=solicitante[0].SantanderExTipoConsulta
                    this.reporteCrediticio.nombreAutorizacion=solicitante[0].AutorizacionConsultaBuroCredito
                    this.reporteCrediticio.nombreIdentificacion=solicitante[0].IdentificacionOficial
                    this.reporteCrediticio.tarjeta=solicitante[0].TarjetaCredito
                    this.reporteCrediticio.digitos=solicitante[0].UltimosDigitosTarjeta
                    this.reporteCrediticio.creditoHipotecario=solicitante[0].CreditoHipotecarioVigente
                    this.reporteCrediticio.creditoAuto=solicitante[0].CreditoAutomotrizVigente  
                    this.updateValores()                 
                }
                }
            ).catch(function (error) {
                self.$vs.notify({
                title:'Ocurrio un error de sistema',
                text:error,
                color:'danger',
                position:'top-right'
                })
            })
    },
    isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
    },
    updateValores(){
       if( this.reporteCrediticio.tipoConsulta==66 && this.reporteCrediticio.nombreIdentificacion!='' && this.reporteCrediticio.nombreAutorizacion!=''){
         this.validaTradicional=true
       }else if(this.reporteCrediticio.tipoConsulta!=66){
         this.validaTradicional=true
       }else{
         this.validaTradicional=false
       }
       if( this.reporteCrediticio.tipoConsulta==67 && this.reporteCrediticio.tarjeta && this.reporteCrediticio.digitos.toString().length>3){
         this.validaAutenticada=true
       }else if(this.reporteCrediticio.tipoConsulta==67 && this.reporteCrediticio.tarjeta==false){
         this.validaAutenticada=true
       }else if(this.reporteCrediticio.tipoConsulta!=67){
         this.validaAutenticada=true
       }else{
         this.validaAutenticada=false
       }
       if(this.reporteCrediticio.tipoConsulta !='' && this.validaTradicional && this.validaAutenticada){
         this.reporteCrediticio.validaReporteCrediticio=true
       }else{
         this.reporteCrediticio.validaReporteCrediticio=false
       }
      this.$emit('updateValores',this.reporteCrediticio)
    },
    getIdentificacion(files){
      this.reporteCrediticio.identificacion = files
      this.muestraAlertaTipoArchivoIne=false
      this.textoAlertaIne=''
      this.reporteCrediticio.identificacion = files
      this.reporteCrediticio.nombreIdentificacion=this.reporteCrediticio.identificacion.name
      let extension = this.reporteCrediticio.identificacion.name.split(".").pop();
      extension=extension.toLowerCase()
      var extensiones =['pdf','jpg','jpeg','tif','tiff']
              //validar extension PDF, JPG, JPEG, TIF y TIFF
        if(!extensiones.includes(extension)){
          this.muestraAlertaTipoArchivoIne = true
          this.textoAlertaIne ='Solo se aceptan archivos con formato pdf, jpe, jpeg, tiff y tif'
        }
         let sizeFile=this.reporteCrediticio.identificacion.size.split(" ")
        if(parseInt(sizeFile[0])>5120){
          this.muestraAlertaTipoArchivoIne = true
          this.textoAlertaIne='El tamaño del archivo no debe exceder los 5MB'
        }
        this.updateValores()
    },
    getAutorizacionCredito(files){
      this.muestraAlertaTipoArchivo=false
      this.textoAlertaAutorizacion=''
      this.reporteCrediticio.autorizacionCredito = files
      this.reporteCrediticio.nombreAutorizacion=this.reporteCrediticio.autorizacionCredito.name
      let extension = this.reporteCrediticio.autorizacionCredito.name.split(".").pop();
      extension=extension.toLowerCase()
      var extensiones =['pdf','jpg','jpeg','tif','tiff']
              //validar extension PDF, JPG, JPEG, TIF y TIFF
        if(!extensiones.includes(extension)){
          this.muestraAlertaTipoArchivo = true
          this.textoAlertaAutorizacion ='Solo se aceptan archivos con formato pdf, jpe, jpeg, tiff y tif'
        }
        let sizeFile=this.reporteCrediticio.autorizacionCredito.size.split(" ")
        if(parseInt(sizeFile[0])>5120){
          this.muestraAlertaTipoArchivo = true
          this.textoAlertaAutorizacion='El tamaño del archivo no debe exceder los 5MB'
        }
            
        this.updateValores()
    },
    actualizarInfo(){
      let self=this
      this.$vs.loading({
        container: '#main',
        scale: 0.6
      })
      let objRequestRegistraSolicitante = {
        strApiKey: this.key,
        strMetodo: 'RegistraSolicitante',
        objSolicitante: {
          EMail: this.email,
          DigitosTarjetaCredito:this.digitos,
          CreditoHipotecarioActual:this.creditoHipotecario,
          CreditoAutomotirzReciente:this.creditoAuto
        }
      }
      this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.consultar()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }

      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })

    },
    consultar(){
      let self=this
      var objRequestConsultaBuro = {
        strApiKey: this.key,
        strMetodo: 'ConsultaBuro',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestConsultaBuro,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.creditos=response.data.objContenido.vctCreditos
            for (var i = 0; i < this.creditos.length; i++) {
              this.creditos[i].LimiteDeCredito = this.creditos[i].LimiteDeCredito.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.creditos[i].SaldoActual = this.creditos[i].SaldoActual.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.creditos[i].MontoAPagar = this.creditos[i].MontoAPagar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            this.$vs.loading.close('#main > .con-vs-loading')
            this.printBuro()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ConsultaBuro',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
            this.$vs.loading.close('#main > .con-vs-loading')
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    printBuro(){
      window.open('https://cotizador.socasesores.com/app/buro/'+this.email, '_blank');
    },
    dameCaso(){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            if (response.data.objContenido.Coacreditado[0]) {
              this.email= response.data.objContenido.Coacreditado[0].EMail
            }
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self=this
      var objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let solicitante =response.data.objContenido
            this.completeInfo = solicitante.Nombres && solicitante.ApellidoPaterno
            && solicitante.ApellidoMaterno &&solicitante.Rfc && solicitante.FechaNacimiento
            && solicitante.Calle && solicitante.NumeroExterior &&
            solicitante.Colonia && solicitante.Ciudad && solicitante.CodigoPostal
            && solicitante.Estado
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    getTipoConsultas(){
            let self=this
            var objRequestListaConsultas = {
                strApiKey: this.key,
                strMetodo: 'CatalogosGeneralesBancos',
                objCatalogo:
                {
                    TipoCatalogo: "13"
                }
            }
            this.$axios.post('/',objRequestListaConsultas,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(
                response => {
                if(response.data.intEstatus == 0){ 
                    this.tiposConsultas=response.data.objContenido 
                }else {
                    this.$vs.notify({
                    title:'Ocurrio un error en ListaTiposConsultas',
                    text:response.data.strError,
                    color:'danger',
                    position:'top-right'
                    })
                }
                }
            ).catch(function (error) {
                self.$vs.notify({
                title:'Ocurrio un error de sistema',
                text:error,
                color:'danger',
                position:'top-right'
                })
      })
    },
  }
}
</script>

<style lang="css" scoped>
.selectExample{
  width: 150%;}
</style>
