export const giros = [
  {Id:1, Nombre: "METALURICA-SIDERURGIA"},
  {Id:2, Nombre: "ENSENANZA-EDUCACION-FORMACION"},
  {Id:3, Nombre: "SERVICIOS DE INFORMATICA"},
  {Id:4, Nombre: "ENERGIA"},
  {Id:5, Nombre: "AGUA"},
  {Id:6, Nombre: "MINERIA"},
  {Id:7, Nombre: "INGENIERIA-MECANICA-AUTOMOTRIZ"},
  {Id:8, Nombre: "QUIMICA"},
  {Id:9, Nombre: "PAPEL-ARTES GRAFICAS-JUGUETERIA"},
  {Id:10, Nombre: "SANIDAD-HOSPITALES-SERVICIOS VETERINARIOS"},
  {Id:11, Nombre: "REPARACION DE VEHICULOS"},
  {Id:12, Nombre: "TRANSPORTE NAVAL"},
  {Id:13, Nombre: "FERROCARRIL"},
  {Id:14, Nombre: "PESCA"},
  {Id:15, Nombre: "CONSULTORIA FINANCIERA"},
  {Id:16, Nombre: "SERVICIOS A EMPRESAS"},
  {Id:17, Nombre: "ELECTRICIDAD-ELECTRONICA"},
  {Id:18, Nombre: "ALIMENTACION-BEBIDA-TABACO"},
  {Id:19, Nombre: "ADMINISTRACION PUBLICA"},
  {Id:20, Nombre: "TRANSPORTE CARRETERA"},
  {Id:21, Nombre: "COMUNICACIONES (CORREOS-TELEFONOS)"},
  {Id:22, Nombre: "CONSTRUCCION-CEMENTO-HULERA"},
  {Id:23, Nombre: "VIDRIO"},
  {Id:24, Nombre: "DIPLOMATICO"},
  {Id:25, Nombre: "SERVICIOS DOMESTICOS-SERVICIOS PERSONALES"},
  {Id:26, Nombre: "TRANSPORTE AEREO"},
  {Id:27, Nombre: "AGRICULTURA-AVICULTURA-GANADERIA"},
  {Id:28, Nombre: "TEXTIL-MADERA-MUEBLES"},
  {Id:29, Nombre: "CUERO-PIEL-CALZADO-VESTIDO"},
  {Id:30, Nombre: "CUERPO SEGURIDAD/MILITAR"},
  {Id:31, Nombre: "BANCA-FINANCIEROS-SEGUROS"},
  {Id:32, Nombre: "COMERCIO-HOTELERIA-RESTAURANTES"},
  {Id:33, Nombre: "AGENCIAS AUTOMOTRICES"},
  {Id:34, Nombre: "ALQUILER DE MUEBLES- INMUEBLES"},
  {Id:35, Nombre: "PRENSA- RADIO - TELEVISION"},
  {Id:36, Nombre: "ESPECTACULOS-DEPORTES"},
  {Id:37, Nombre: "TURISMO-AGENCIAS DE VIAJES"},
  {Id:38, Nombre: "OTROS"},
  {Id:39, Nombre: "SERVICIOS DE MENSAJERIA"},
  {Id:40, Nombre: "MANUFACTURAS"},
]
