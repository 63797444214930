<template>
	<div class="">
		<ul class="nav nav-tabs" id="myTab" role="tablist">
			<li class="nav-item" role="presentation">
				<button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Generales</button>
			</li>
			<li class="nav-item" role="presentation">
				<button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Ingresos</button>
			</li>
			<li class="nav-item" role="presentation">
				<button class="nav-link" id="datos-tab" data-bs-toggle="tab" data-bs-target="#datos" type="button" role="tab" aria-controls="datos" aria-selected="false">Datos adicionales</button>
			</li>
			<li class="nav-item" role="presentation">
				<button class="nav-link" id="datos-tab" data-bs-toggle="tab" data-bs-target="#productos" type="button" role="tab" aria-controls="productos" aria-selected="false"  @click="dameCaso()">Productos</button>
			</li>
		</ul>
		<div class="tab-content" id="myTabContent">
			<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
				<div class="tab-body">
					<div class="accordion" id="accordionExample">
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingOne">
								<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Personales</button>
							</h2>
							<div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
								<div class="accordion-body accordion-scrolling mh-4">
									<PersonalesGeneral
										v-bind:conyugePersonalProps="conyugePersonalProps"
										v-on:personalGeneralProps="personalProps($event)" 
										v-on:actualiza_bandera="updateBandera" 
										v-on:changeCoacreditado="showContentCoacreditado($event)" />
								</div>
							</div>
						</div>
						
						<!-- mostrar bajo condiciones -->
						<div
							v-if="personalConyuge.estadoCivil == 1 || personalConyuge.estadoCivil == 4" 
							class="accordion-item">
							<h2 class="accordion-header" id="headingSix">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">Cónyuge</button>
							</h2>
							<div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
								<div class="accordion-body accordion-scrolling mh-4">
									<Conyuge 
										v-on:conyugePersonal="conyugePersonal($event)"
										v-bind:personalConyuge="personalConyuge"/>
								</div>
							</div>
						</div>

						<div class="accordion-item">
							<h2 class="accordion-header" id="headingTwo">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Reporte de Crédito y Precalificación</button>
							</h2>
							<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
								<div class="accordion-body accordion-scrolling mh-4">
									<ReporteCredito />
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingFive">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Dependientes</button>
							</h2>
							<div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
								<div class="accordion-body accordion-scrolling mh-4">
									<Dependientes />
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingThree">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Referencias</button>
							</h2>
							<div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
								<div class="accordion-body accordion-scrolling mh-4">
									<Referencias />
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingFour">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Obligados solidarios</button>
							</h2>
							<div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
								<div class="accordion-body accordion-scrolling mh-4">
									<ObligadoSolidario />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
				<div class="tab-body">
					<div class="accordion" id="accordionExample2">
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingOne-pro">
								<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne-pro" aria-expanded="true" aria-controls="collapseOne-pro">Ingresos</button>
							</h2>
							<div id="collapseOne-pro" class="accordion-collapse collapse" aria-labelledby="headingOne-pro" data-bs-parent="#accordionExample2">
								<div class="accordion-body accordion-scrolling mh-4">
									<Ingresos v-on:actualiza_bandera="updateBandera" />
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingTwo-pro">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo-pro" aria-expanded="false" aria-controls="collapseTwo-pro">Cuentas bancarias</button>
							</h2>
							<div id="collapseTwo-pro" class="accordion-collapse collapse" aria-labelledby="headingTwo-pro" data-bs-parent="#accordionExample2">
								<div class="accordion-body accordion-scrolling mh-4">
									<CuentasBancarias />
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingThree-pro">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree-pro" aria-expanded="false" aria-controls="collapseThree-pro">Adeudos</button>
							</h2>
							<div id="collapseThree-pro" class="accordion-collapse collapse" aria-labelledby="headingThree-pro" data-bs-parent="#accordionExample2">
								<div class="accordion-body accordion-scrolling mh-4">
									<Adeudos />
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingFour-pro">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour-pro" aria-expanded="false" aria-controls="collapseFour-pro">Beneficiarios</button>
							</h2>
							<div id="collapseFour-pro" class="accordion-collapse collapse" aria-labelledby="headingFour-pro" data-bs-parent="#accordionExample2">
								<div class="accordion-body accordion-scrolling mh-4">
									<Beneficiarios />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		<!--<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
				<div class="tab-body">
					<Aseguradoras />
				</div>
			</div>-->
			<div class="tab-pane fade" id="datos" role="tabpanel" aria-labelledby="datos-tab">
				<div class="tab-body">
					<DatosAdicionales />
				</div>
			</div>
			<div class="tab-pane fade" id="productos" role="tabpanel" aria-labelledby="datos-tab">
				<div class="tab-body">
					<div class="pro-card">
						<div>
							<div class="tab-body-2" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl" v-if="caso.solicitudes.length">
								<div class="col-md-4" v-for="item in caso.solicitudes" :key="item.Id">
									<Solicitados :solicitados="item">
										<template slot="action-favs">
											<div class="trash-pro">
												<button type="filled" class="favoritos" @click="quitar(item)">
													<svg class="trash">
														<g id="trash">
															<path id="Vector" d="M18,.5C14.67.17,11.32,0,7.98,0A59.068,59.068,0,0,0,2.04.3L0,.5" transform="translate(3 5.48)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
															<path id="Vector-2" data-name="Vector" d="M0,2.97.22,1.66C.38.71.5,0,2.19,0H4.81C6.5,0,6.63.75,6.78,1.67L7,2.97" transform="translate(8.5 2)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34" />
															<path id="Vector-3" data-name="Vector" d="M13.7,0l-.65,10.07c-.11,1.57-.2,2.79-2.99,2.79H3.64C.85,12.86.76,11.64.65,10.07L0,0" transform="translate(5.15 9.14)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
															<path id="Vector-4" data-name="Vector" d="M0,0H3.33" transform="translate(10.33 16.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34" />
															<path id="Vector-5" data-name="Vector" d="M0,0H5" transform="translate(9.5 12.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34" />
															<path id="Vector-6" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0" />
														</g>
													</svg>
												</button>
											</div>
										</template>
									</Solicitados>
								</div>
							</div>
							<div v-else>
								<span class="span-placeholder">No hay productos elegidos</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> 
</template>

<script>
import Solicitados from '@/views/Caso/Solicitados.vue'
import PersonalesGeneral from '@/views/Caso/Personales/PersonalesGeneral.vue'
import Conyuge from '@/views/Caso/Personales/Conyuge.vue'
import Adeudos from '@/views/Caso/Personales/Adeudos.vue'
import Beneficiarios from '@/views/Caso/Personales/Beneficiarios.vue'
import CuentasBancarias from '@/views/Caso/Personales/CuentasBancarias.vue'
import Dependientes from '@/views/Caso/Personales/Dependientes.vue'
import Referencias from '@/views/Caso/Personales/Referencias.vue'
import Aseguradoras from '@/views/Caso/Personales/Aseguradoras.vue'
import ObligadoSolidario from '@/views/Caso/Personales/ObligadoSolidario.vue'
import DocumentosDigitales from '@/views/Caso/Personales/DocumentosDigitales.vue'
import Ingresos from '@/views/Caso/Personales/Ingresos.vue'
import DatosAdicionales from '@/views/Caso/Personales/DatosAdicionales.vue'
import ReporteCredito from '@/views/Caso/Personales/ReporteCredito.vue'
//import ProductosPer from '@/views/Caso/Personales/ProductosPer.vue'

export default {
  components: {
    PersonalesGeneral,
	Conyuge,
    Adeudos,
    Beneficiarios,
    CuentasBancarias,
    Dependientes,
    Referencias,
    Aseguradoras,
    ObligadoSolidario,
    DocumentosDigitales,
    Ingresos,
    DatosAdicionales,
    ReporteCredito,
	//ProductosPer,
	Solicitados,
  },
  data () {
    return {
		personalConyuge: {}, // de PersonalesGeneral.vue
		conyugePersonalProps: {}, // de Conyuge.vue
      tab:1,
	  id: '',
	  key: '',
	  caso: {
				valorInmueble: '',
				saldoInsoluto: '',
				plazos: [],
				destino: '',
				esquemas: [],
				pagoMensualActual: '',
				plazoRestante: '',
				ejecutivo: '',
				estado: '',
				estatus: '',
				favoritos: [],
				solicitudes: [],
				montoSolicitado: '',
				programa: '',
				email: '',
				bancos: [],
				inmuebleNuevo: true,
				estado: '',
				plaza: '',
				solicitante: '',
				tasa: '',
				ssv: '',
				creditoInf: '',
				//gastosNotarial:3,
				valorProyecto: 0,
				valorTerreno: 0,
				montoCredito: 0,
				curp: '', 
				nombre: '',
				appPaterno: '',
				appMaterno: '',
				telefono: '',
				Segnombre: '',
			},
		CoAcreditado :'',
		banderaSolicitanteGenerales: [false,false,false],
		mensaje: 'escuchando evento hijo',
		dataSolicitante:[]
    }
  },
  mounted() {
	this.id = this.$route.params.id
	this.getApikey()
	this.dameCaso()
  },
  computed: {
    isSmallerScreen () {
      return window.screen.width < 768
    },
  },
  methods:{
	conyugePersonal( event ) {
		this.conyugePersonalProps = event;
		// console.log( 'Personales.vue obj conyuge', event );
	},
	personalProps( event ) {
		this.personalConyuge = event;
		// console.log( 'Personales.vue, recibiendo data actualizad', event );
	},
		updateBandera: function(origen, status) {
			if (origen == 'add') {
				this.banderaSolicitanteGenerales[1] = status	
			}else if(origen == 'delete'){
				this.banderaSolicitanteGenerales[1] = status
			}
			this.dameCaso()
    },
    changeTab(id){
      this.tab=id
    },
		getApikey() {
				if (!JSON.parse(localStorage.getItem('userInfo')).ApiKey) {
					this.$router.push('/login')
				} else {
					this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey
				}
		},
		dameCaso(flag) {
				//this.destino=1 //restaurar para que tome los valores devueltos
				this.caso.programa = 1 //restaurar para que tome los valores devueltos
				let self = this
				var objRequestDameCaso = {
					strApiKey: this.key,
					strMetodo: 'DameCaso',
					objCaso: {
						Id: this.id,
					},
				}
				this.$axios
					.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
					.then((response) => {
						if (response.data.intEstatus == 0) {
							let cotizacion = response.data.objContenido
				
							if (response.data.objContenido.CoPropietario != null) {
								this.CoAcreditado = true	
							}
							else
							this.CoAcreditado = false
							
							
							this.caso.solicitudes = cotizacion.Solicitudes
							for (var i = 0; i < this.caso.solicitudes.length; i++) {
								this.caso.solicitudes[i].Resultado = this.caso.solicitudes[i].Resultado
								this.caso.solicitudes[i].IdCaso = this.caso.solicitudes[i].Caso
								this.caso.solicitudes[i].IdBanco = this.caso.solicitudes[i].IdBanco
								this.caso.solicitudes[i].MensualidadPromedio = this.caso.solicitudes[i].MensualidadPromedio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
								this.caso.solicitudes[i].Cat = this.caso.solicitudes[i].Cat.toFixed(2)
								this.caso.solicitudes[i].CoAcreditado = this.CoAcreditado
									.toString()
									.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
							}

							this.caso.solicitante = response.data.objContenido.Solicitante
							this.dameSolicitante()					
							
							this.$vs.loading.close('#datosCotizacion > .con-vs-loading')
						} else {
							this.$vs.notify({
								title: 'Ocurrio un error en DameCaso',
								text: response.data.strError,
								color: 'danger',
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						self.$vs.notify({
							title: 'Ocurrio un error de sistema',
							text: error,
							color: 'danger',
							position: 'top-right',
						})
					})
		},
		quitar(item) {
				let self = this
				var objRequestSolicitaCaso = {
					strApiKey: this.key,
					strMetodo: 'BorrarSolicita',
					intVariante: item.VarianteId,
					objCaso: {
						Id: this.id,
					},
				}
				this.$axios
					.post('/', objRequestSolicitaCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
					.then((response) => {
						if (response.data.intEstatus == 0) {
							this.$vs.notify({
								title: 'Eliminado exitosamente',
								position: 'top-right',
							})
							if (item.IdBanco == 10) {
								this.$store.commit('UPDATE_EXISTE_PRODUCTO_SANTANSER', false)
								this.$router.go(0)
							}
							this.dameCaso()
						} else {
							this.$vs.notify({
								title: 'Ocurrio un error en BorrarSolicita',
								text: response.data.strError,
								color: 'danger',
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						self.$vs.notify({
							title: 'Ocurrio un error de sistema',
							text: error,
							color: 'danger',
							position: 'top-right',
						})
					})
		},
		dameSolicitante() {
			//alert("entrando a dameSolicitante...")
				let self = this
				var objRequestDameSolicitante = {
					strFolioCaso: this.id,
					strApiKey: this.key,
					strMetodo: 'DameSolicitante',
					objSolicitante: {
						EMail: this.caso.solicitante,
					},
				}
				this.$axios
					.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
					.then((response) => {

						const status = response.data.intEstatus
						this.dataSolicitante = response.data.objContenido

						// VALIDACION PARA PESTAÑA SOLICITANTE-GENERALES

						if (this.dataSolicitante.Nombres != '' && this.dataSolicitante.ApellidoPaterno != '' && this.dataSolicitante.ApellidoMaterno != '' && (this.dataSolicitante.Sexo != '' && this.dataSolicitante.Sexo != null) && this.dataSolicitante.TelefonoCelular != ''
							&& this.dataSolicitante.FechaNacimiento != '') {
								this.banderaSolicitanteGenerales[0] = true
						}else {
							this.banderaSolicitanteGenerales[0] = false
						}
						
						// VALIDACION PARA PESTAÑA INGRESOS-INGRESOS

						if(this.dataSolicitante.Ingresos.length > 0){
							this.banderaSolicitanteGenerales[1] = true
						}					
							
						// SE OBTIENE LA LISTA DE LOS NAV

						let tabs = document.querySelectorAll(".nav-tabs button");
						
						
						if (this.banderaSolicitanteGenerales[0] == false) {
							tabs[1].classList.remove('parpadea')
							tabs[1].classList.remove('pill-nav')
							tabs[1].classList.add('nav-link')
							tabs[2].classList.remove('parpadea')
							tabs[2].classList.remove('pill-nav')
							tabs[2].classList.add('nav-link')
						}
						else if (this.banderaSolicitanteGenerales[0] == true && this.banderaSolicitanteGenerales[1] != true) {		
							tabs[1].className = 'parpadea pill-nav '
							tabs[2].classList.remove('parpadea')
							tabs[2].classList.remove('pill-nav')
							tabs[2].classList.add('nav-link')
						}
						else if (this.banderaSolicitanteGenerales[0] == true && this.banderaSolicitanteGenerales[1] == true) {
							tabs[1].classList.remove('parpadea')
							tabs[1].classList.remove('pill-nav')
							tabs[1].classList.add('nav-link')
							tabs[2].className = 'pill-nav parpadea'
						}

						setTimeout(function(){
							tabs[1].classList.remove('parpadea')
							tabs[1].classList.remove('pill-nav')
							tabs[1].classList.add('nav-link')
							tabs[2].classList.remove('parpadea')
							tabs[2].classList.remove('pill-nav')
							tabs[2].classList.add('nav-link')
						}, 2000);
							
						
						

						if (status) {
					this.$vs.notify({
					title:'Ocurrio un error en DameSolicitante',
					text:response.data.strError,
					color:'danger',
					position:'top-right'
					})

				}

				}).catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		showContentCoacreditado(changeCoacreditado) {

      // console.log('changeCoacreditado :>> ', changeCoacreditado);
			
				this.$emit('changeCoacreditado', changeCoacreditado)

    },
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
.pill-nav {
  background-color: dodgerblue;
  color: white;
  border: 0;
  text-align: center;
  padding: 8px 15px 8px 15px;
  text-decoration: none;
  border-radius: 5px;
}
.parpadea {
  
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 2;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: 2;
}

@-moz-keyframes parpadeo{  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
   100% { opacity: 1.0; }
}

@keyframes parpadeo {  
  0% { opacity: 1.0; }
   50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}


</style>
