<template lang="html">
  <div id="container">
    <div class="">
      <div class="">
        <vx-card title="Datos requeridos" class="">
          <div class="row w-full">  
            <div class="col-md-4">
              <span class="span-placeholder" for="tiempoEmpleo">Tipo de ingresos</span>
              <select name="tiempoEmpleo" v-validate="'required'" id="tiempoEmpleo" v-model="ingresos.tipoEmpleo" class="vs-input" @change="updateValores(); validaFechaMaxima()">
                <option :value='tipo.Id' v-for="tipo in tipoIngresos"  :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger span-placeholder" v-show="errors.has('tiempoEmpleo')">Campo requerido</span>
            </div>
            <div class="col-md-4">
                  <span class="span-placeholder" for="escolaridad">Escolaridad</span>
                  <select name="escolaridad" id="escolaridad" v-validate="'required'" v-model="ingresos.gradoEstudios" class="vs-input" @change="updateValores()">
                    <option :value='tipo.Id' v-for="tipo in escolaridades"  :key="tipo.id">{{tipo.Nombre}}</option>
                  </select>
                  <span class="text-danger span-placeholder" v-show="errors.has('escolaridad')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder" for="tiempoEmpleo">Ocupacion</span>
              <select name="tiempoEmpleo" id="tiempoEmpleo" v-validate="'required'" v-model="ingresos.ocupacion" class="vs-input" @change="updateValores()">
                <option :value='tipo.Id' v-for="tipo in ocupaciones"  :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger span-placeholder" v-show="errors.has('tiempoEmpleo')">Campo requerido</span>
            </div>
         </div>    
          <div class="row w-full">
            <div class="col-md-4" v-if="ingresos.tipoEmpleo==17">
              <span class="span-placeholder">Fecha ingreso Asalariado </span>
              <input class="vs-input" type="date" v-validate="'required'" v-model="ingresos.fechaInicioAsalariado" name="fechaIngresoAsalariado" id="fechaIngresoAsa" :max="fecha_maxima" @change="updateValores(); validarFecha(); fechaActual();"/>
              <span class="text-danger span-placeholder" v-show="errors.has('fechaIngresoAsalariado')">Campo requerido</span>
              <span class="text-danger span-placeholder" v-if="mostrarMensajeFechaMinima">El valor mínimo a capturar es un mes de antigüedad</span>
            </div>
            <div class="col-md-4" v-if="ingresos.tipoEmpleo !== 17">
              <span class="span-placeholder">Fecha inicio No asalariado </span>
              <input class="vs-input" type="date" v-validate="'required'" v-model="ingresos.fechaInicio" id="fechaInicioNoAsalariado"  name="fechaInicio" :max="fecha_maxima" @change="updateValores(); validacionFechaNoAsalariado();"/>
              <span class="text-danger span-placeholder" v-show="errors.has('fechaInicio')">Campo requerido</span>
              <span class="text-danger span-placeholder" v-if="mostrarMensajeFechaNoAsalariado">Debes cumplir con mínimo 2 años de anigüedad</span>
              <span class="text-danger span-placeholder" v-if="mostrarMensajeFechaMinimaNoAsalariado"> El valor mínimo a capturar es un mes</span>
            </div>
            <div class="col-md-4" v-if="muestraEmpleoAnterior && ingresos.tipoEmpleo==17">
                <span class="span-placeholder">Fecha inicio empleo anterior</span>
                <input class="vs-input" v-validate="'required'" v-model="ingresos.inicioAnterior" name="inicioAnterior" id="inicioAnterior" type="date" :max="fecha_maxima" @change="updateValores();  validarFechaEmpleoAnterior()"/>
                <span class="text-danger span-placeholder" v-show="errors.has('inicioAnterior')">Campo requerido</span>
                
              <span class="text-danger span-placeholder" v-if="mostrarMensajeFechaMinimaAnterior">El valor mínimo a capturar es un mes de antigüedad</span>
            </div>
            <div class="col-md-4" v-if="muestraEmpleoAnterior && ingresos.tipoEmpleo==17">
                <span class="span-placeholder">Fecha fin empleo anterior</span>
                <input class="vs-input" v-validate="'required'" v-model="ingresos.salidaAnterior" name="salidaAnterior" id="salidaAnterior" type="date" @change="updateValores(); validarFechaEmpleoAnteriorSalida()"/>
                <span class="text-danger span-placeholder" v-show="errors.has('salidaAnterior')">Campo requerido</span>
                <span class="text-danger span-placeholder" v-if="mostrarMensajeFechaMinimaAnteriorSalida">El valor mínimo a capturar es un mes de antigüedad</span>
            </div>
          </div>  
          
         <div class="row w-full" >
            <div class="col-md-4" v-if="ingresos.ocupacion==29">
                <span class="span-placeholder" for="formasEnganche">Sector laboral</span>
                <select name="formasEnganche" id="formasEnganche" v-validate="'required'" v-model="ingresos.sector" class="vs-input" @change="updateValores()">
                <option :value='tipo.Id' v-for="tipo in sectores"  :key="">{{tipo.Nombre}}</option>
                </select>
                <span class="text-danger span-placeholder" v-show="errors.has('formasEnganche')">Campo requerido</span>
            </div>
             <div class="col-md-12">
              <span class="span-placeholder">Descripción de Actividades</span>
              <textarea class="vs-input" v-model="ingresos.funciones" v-validate="'required'" name="funciones" @change="updateValores()"/>
              <span class="text-danger span-placeholder" v-show="errors.has('funciones')">Campo requerido</span>
            </div>
          </div> 
          <div class="row w-full" v-if="ingresos.tipoEmpleo==17">
            <div class="col-md-4" v-if="ingresos.tipoEmpleo==17">
              <span class="span-placeholder" >Sueldo y/o Honorarios</span>
              <input class="vs-input" v-model="ingresos.sueldoHonorarios" name="sueldoHonorarios" v-validate="'required'" maxlength="11" @change=" updateValores()"  v-on:keypress="isNumber($event)"/>
              <span class="text-danger span-placeholder" v-show="errors.has('sueldoHonorarios')">Campo requerido</span>
            </div>
            <div class="col-md-4" v-if="ingresos.tipoEmpleo==17">
              <span class="span-placeholder">Bono y/o vales</span>
              <input class="vs-input" v-model="ingresos.bonoVales" v-validate="'required'" name="bonoVales" maxlength="11" @change="; updateValores()"  v-on:keypress="isNumber($event)"/>
              <span class="text-danger span-placeholder" v-show="errors.has('bonoVales')">Campo requerido</span>
            </div>
            <div class="col-md-4" v-if="ingresos.tipoEmpleo==17">
              <span class="span-placeholder">Comisiones</span>
              <input class="vs-input" v-model="ingresos.comisiones" v-validate="'required'" name="comisiones" maxlength="11" @change=" updateValores()"  v-on:keypress="isNumber($event)"/>
              <span class="text-danger span-placeholder" v-show="errors.has('comisiones')">Campo requerido</span>
            </div>
         </div>
          <div class="row w-full" v-if="ingresos.tipoEmpleo==20">
           
            <div class="col-md-4" v-if="ingresos.tipoEmpleo==20">
              <span class="span-placeholder">Promedio Mensual</span>
              <input class="vs-input" v-model="ingresos.promedioMensual" v-validate="'required'" name="promedioMensual" maxlength="11" @change=" updateValores()"  v-on:keypress="isNumber($event)"/>
              <span class="text-danger span-placeholder" v-show="errors.has('promedioMensual')">Campo requerido</span>
            </div>
          </div>  
          <div class="row w-full" v-if="ingresos.tipoEmpleo==18">
            <div class="col-md-4" v-if="ingresos.tipoEmpleo==18">
              <span class="span-placeholder">Honorarios Profesionales</span>
              <input class="vs-input" v-model="ingresos.honorariosProfesionales" v-validate="'required'" name="honorariosProfesionales" maxlength="11" @change=" updateValores()"  v-on:keypress="isNumber($event)"/>
              <span class="text-danger span-placeholder" v-show="errors.has('honorariosProfesionales')">Campo requerido</span>
            </div>
            <div class="col-md-4" v-if="ingresos.tipoEmpleo==18">
              <span class="span-placeholder">Retiro o Anticipo Utilidades</span>
              <input class="vs-input" v-model="ingresos.retiroUtilidades" v-validate="'required'" name="retiroUtilidades" maxlength="11" @change="updateValores()"  v-on:keypress="isNumber($event)"/>
              <span class="text-danger span-placeholder" v-show="errors.has('honorariosProfesionales')">Campo requerido</span>
            </div>
          </div>
          <div class="row w-full" v-if="ingresos.tipoEmpleo==19">
            <div class="col-md-4" v-if="ingresos.tipoEmpleo==19">
              <span class="span-placeholder">Ventas Anuales</span>
              <input class="vs-input" v-model="ingresos.ventas" name="ventas" v-validate="'required'" maxlength="11" @change=" updateValores()"  v-on:keypress="isNumber($event)"/>
              <span class="text-danger span-placeholder" v-show="errors.has('ventas')">Campo requerido</span>
            </div>
            <div class="col-md-4" v-if="ingresos.tipoEmpleo==19">
              <span class="span-placeholder">Utilidad de operación</span>
              <input class="vs-input" v-model="ingresos.utilidadOperacion" name="utilidadOperacion" v-validate="'required'" maxlength="11" @change="updateValores()"  v-on:keypress="isNumber($event)"/>
              <span class="text-danger span-placeholder" v-show="errors.has('utilidadOperacion')">Campo requerido</span>
            </div>
          </div>  
      </vx-card>
    </div>
  </div>  
</div>
</template>

<script>
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/dist/locale'
export default {
  components: {
    'v-select': vSelect,
    Datepicker
  },
  data(){
		return{
      es: es,
      ingresos:{
        tipoEmpleo:'',
        gradoEstudios:'',
        ocupacion:'',
        fechaInicioAsalariado:'',
        fechaInicio:'',
        inicioAnterior:'',
        salidaAnterior:'',
        sector:'',
        funciones:'',
        sueldoHonorarios:'',
        bonoVales:'',
        comisiones:'',
        promedioMensual:'',
        honorariosProfesionales:'',
        retiroUtilidades:'',
        ventas:'',
        utilidadOperacion:'',
        validaIngresos:false
      },
      validaAsalariado:false,
      validaOcupacion:false,
      validaProfesionista:false,
      validaPersonaFisica:false,
      validaCuentaCheques:false,
        tipoIngresos:[],
        escolaridades:[], 
         ocupaciones:[],    
        muestraEmpleoAnterior:true,
      popupActive: false,
      key:'',
      solicitante:{},
      tiposDeEmpleo:[],
      giros:[],
      estados:[],
      comprobantes:[],
      tiposContratos:[],
      sectores:[],
      id:'',
      email:'',
      fechaSinValidar:false,
      dateFrom:new Date(),
      dateTo:'',
      fechaHoy: new Date(),
      mostrarMensajeFechaMinima:false,
      mostrarMensajeFechaNoAsalariado:false,
      mostrarMensajeFechaMinimaAnterior:false,
      mostrarMensajeFechaMinimaNoAsalariado:false,
      mostrarMensajeFechaMinimaAnteriorSalida:false,
      fecha_maxima:''
    }
  },
  watch: {
    sueldoHonorarios: function(newValue) {
      this.ingresos.sueldoHonorarios= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    bonoVales: function(newValue) {
      this.ingresos.bonoVales= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    comisiones: function(newValue) {
      this.ingresos.comisiones= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    promedioMensual: function(newValue) {
      this.ingresos.promedioMensual= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    honorariosProfesionales: function(newValue) {
      this.ingresos.honorariosProfesionales= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    retiroUtilidades: function(newValue) {
      this.ingresos.retiroUtilidades= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    ventas: function(newValue) {
      this.ingresos.ventas= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    utilidadOperacion: function(newValue) {
      this.ingresos.utilidadOperacion= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    popupActive:function(){
      if (this.popupActive == false) {
        this.limpiaForm()
      }
    },
  },
  computed:{
    sueldoHonorarios(){
      return this.ingresos.sueldoHonorarios
    },
    bonoVales(){
      return this.ingresos.bonoVales
    },
    comisiones(){
      return this.ingresos.comisiones
    },
    promedioMensual(){
      return this.ingresos.promedioMensual
    },
    honorariosProfesionales(){
      return this.ingresos.honorariosProfesionales
    },
    retiroUtilidades(){
      return this.ingresos.retiroUtilidades
    },
    ventas(){
      return this.ingresos.ventas
    },
    utilidadOperacion(){
      return this.ingresos.utilidadOperacion
    },
    validateForm(){
      return this.ingreso.tipoEmpleo != '' && this.ingreso.ingresoNeto != '' && this.ingreso.ingresoMensual != '' && this.ingreso.empresa != '' && ((this.ingreso.tipoEmpleo != 48 && this.ingreso.fechaInicio != '')|| this.ingreso.tipoEmpleo == 48)
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    this.validaFechaMaxima()
    //this.fechaActual()
    /*this.getEstados()
    this.getListaTipoEmpleos()
    this.getTipoIdentificaciones()
    this.getGiros()
    this.getSectoresRed()
    this.getTiposContratos()
    this.dameCaso()*/
    this.getSectoresLaborales()
    this.getOcupaciones()
    this.getEscolaridades()
    this.getTipoIngresos()
    this.dameDatosPersonales()
    this.fechaMinima()
    //this.updateValores()
  },
  methods:{
    validaFechaMaxima(){
      var fecha = new Date();
      var anio = fecha.getFullYear();
      var dia = fecha.getDate();
      var _mes = fecha.getMonth(); //viene con valores de 0 al 11
      _mes = _mes + 1; //ahora lo tienes de 1 al 12
      if (_mes < 10) //ahora le agregas un 0 para el formato date
      {
        var mes = "0" + _mes;
      } else {
        var mes = _mes.toString;
      }

        this.fecha_maxima = anio + '-' + mes + '-' + dia; // Nueva variable
       
    },
    validarFechaEmpleoAnteriorSalida(){
       var fechaInicio = new Date(this.ingresos.salidaAnterior);
      var fechaUnMesesAntes = new Date();
      // Quitas los meses
      fechaUnMesesAntes.setMonth(fechaUnMesesAntes.getMonth() - 1);
      this.mostrarMensajeFechaMinimaAnteriorSalida = fechaInicio>fechaUnMesesAntes
    },
    fechaMinima(){
        var fechaInicio = new Date(this.ingresos.FechaInicioNoAsalariado);
      var fechaUnMesesAntes = new Date();
      // Quitas los meses
      fechaUnMesesAntes.setMonth(fechaUnMesesAntes.getMonth() - 1);
      //alert(fechaUnMesesAntes.getDate()+'-'+fechaUnMesesAntes.getMonth()+'-'+fechaUnMesesAntes.getFullYear())
     // document.getElementById('fechaInicioNoAsalariado').setAttribute('min',fechaUnMesesAntes.getDate()+'/'+fechaUnMesesAntes.getMonth()+'/'+fechaUnMesesAntes.getFullYear())
    },
    validacionFechaNoAsalariado(){
      var fechaInicio = new Date(this.ingresos.fechaInicio);
      var fechaDosAniosAntes = new Date();
      // Quitas los Años
      fechaDosAniosAntes.setFullYear(fechaDosAniosAntes.getFullYear() - 2);
      this.mostrarMensajeFechaNoAsalariado = fechaInicio>fechaDosAniosAntes

      var fechaInicio = new Date(this.ingresos.fechaInicio);
      var fechaUnMesesAntes = new Date();
      // Quitas los meses
      fechaUnMesesAntes.setMonth(fechaUnMesesAntes.getMonth() - 1);
      document.getElementById('fechaInicioNoAsalariado').setAttribute('max',fechaUnMesesAntes.getDate()+'/'+fechaUnMesesAntes.getMonth()+'/'+fechaUnMesesAntes.getFullYear())
      this.mostrarMensajeFechaMinimaNoAsalariado = fechaInicio>fechaUnMesesAntes
    },
    fechaActual(){
      var fechaInicio = new Date(this.ingresos.fechaInicioAsalariado);
      var fechaUnMesesAntes = new Date();
      // Quitas los meses
      fechaUnMesesAntes.setMonth(fechaUnMesesAntes.getMonth() - 1);
      this.mostrarMensajeFechaMinima = fechaInicio>fechaUnMesesAntes
    },
    dameDatosPersonales(){
      let self= this
      var objRequestDameSolicitante = {
         strApiKey: this.key,
        strMetodo: "DamePrecalificacionBancos",
        objPrecalificacionBanco:
        {
            IdPrecalificacion: this.id
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let solicitante =response.data.objContenido
            this.ingresos.tipoEmpleo=solicitante[0].SantanderExTipoIngresos
            this.ingresos.gradoEstudios=solicitante[0].SantanderExEscolaridad
            this.ingresos.ocupacion=solicitante[0].SantanderExOcupacion
            this.ingresos.fechaInicioAsalariado=solicitante[0].FechaIngresoAsalariado
            if(this.ingresos.fechaInicioAsalariado!=''){
              this.validarFecha(); this.fechaActual(); //this.validaFechaMaxima()
            }
            this.ingresos.fechaInicio=solicitante[0].FechaInicioNoAsalariado
            this.ingresos.inicioAnterior=solicitante[0].FechaInicioEmpleoAnterior
            this.ingresos.salidaAnterior=solicitante[0].FechaFinEmpleoAnterior
            this.ingresos.sector=solicitante[0].SantanderExSectorLaboral
            this.ingresos.funciones=solicitante[0].DescripcionActividades
            if(solicitante[0].SueldoHonorarios >0){
              this.ingresos.sueldoHonorarios=solicitante[0].SueldoHonorarios.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if(solicitante[0].BonosVales>0){
              this.ingresos.bonoVales=solicitante[0].BonosVales.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if(solicitante[0].Comisiones>0){
              this.ingresos.comisiones=solicitante[0].Comisiones.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if(solicitante[0].PromedioMensual>0){
              this.ingresos.promedioMensual=solicitante[0].PromedioMensual.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if(solicitante[0].HonorariosProfesionales){
              this.ingresos.honorariosProfesionales=solicitante[0].HonorariosProfesionales.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if(solicitante[0].RetiroAnticipoUtilidades){
              this.ingresos.retiroUtilidades=solicitante[0].RetiroAnticipoUtilidades.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if(solicitante[0].VentasAnuales>0){
              this.ingresos.ventas=solicitante[0].VentasAnuales.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if(solicitante[0].UtilidadOperacion){
              this.ingresos.utilidadOperacion=solicitante[0].UtilidadOperacion.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            this.updateValores()
            this.validaFechaMaxima()
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    updateValores(){
      //validar campos cuando es asalariado
      if(this.ingresos.tipoEmpleo==17 && (this.ingresos.sueldoHonorarios != '' && this.ingresos.sueldoHonorarios !==null) && (this.ingresos.bonoVales !='' && this.ingresos.bonoVales !==null ) && (this.ingresos.comisiones != '' && this.ingresos.comisiones !==null) && (this.ingresos.fechaInicioAsalariado !='' && this.ingresos.fechaInicioAsalariado !==null) ){
        if(this.muestraEmpleoAnterior && (this.ingresos.inicioAnterior != '' && this.ingresos.inicioAnterior !==null) && (this.ingresos.salidaAnterior != '' && this.ingresos.salidaAnterior !==null)){
            this.validaAsalariado= true
        }else if(this.muestraEmpleoAnterior == false){
          this.validaAsalariado=true
        }else{
          this.validaAsalariado=false
        }
      }else if(this.ingresos.tipoEmpleo!=17){
        this.validaAsalariado =true
      }else{
        this.validaAsalariado=false
      }
      //validar campos cuando ocupacion es sector publico
      if(this.ingresos.ocupacion == 29 && this.ingresos.sector!='' && this.ingresos.sector!=null){
          this.validaOcupacion = true
      }else if(this.ingresos.ocupacion != 29){
         this.validaOcupacion = true
      }else{
        this.validaOcupacion=false
      }
      //validar campo cuando es profesionista independiente
      if(this.ingresos.tipoEmpleo==18 && (this.ingresos.fechaInicio != '' && this.ingresos.fechaInicio !==null) && (this.ingresos.honorariosProfesionales !='' && this.ingresos.honorariosProfesionales !==null) && (this.ingresos.retiroUtilidades !='' && this.ingresos.retiroUtilidades !==null)){
        this.validaProfesionista = true
      }else if( this.ingresos.tipoEmpleo!=18){
        this.validaProfesionista=true
      }else{
        this.validaProfesionista=false
      }
      //valida campos cuando es persona física con actividad empresarial
      if(this.ingresos.tipoEmpleo==19 && (this.ingresos.fechaInicio != '' && this.ingresos.fechaInicio !==null) && (this.ingresos.ventas !='' && this.ingresos.ventas !==null) && (this.ingresos.utilidadOperacion !='' && this.ingresos.utilidadOperacion !==null)){
        this.validaPersonaFisica = true
      }else if( this.ingresos.tipoEmpleo!=19){
        this.validaPersonaFisica=true
      }else{
        this.validaPersonaFisica=false
      }
      //valida campos cuando es ingresos por cuenta de cheques
      if(this.ingresos.tipoEmpleo==20 && (this.ingresos.fechaInicio != '' && this.ingresos.fechaInicio !==null) && (this.ingresos.promedioMensual !='' && this.ingresos.promedioMensual !==null) ){
        this.validaCuentaCheques = true
      }else if( this.ingresos.tipoEmpleo!=20){
        this.validaCuentaCheques=true
      }else{
        this.validaCuentaCheques=false
      }
      this.ingresos.validaIngresos = this.ingresos.tipoEmpleo != '' && this.ingresos.gradoEstudios != '' && this.ingresos.ocupacion !='' && this.ingresos.funciones != '' && this.validaAsalariado && this.validaOcupacion && this.validaProfesionista && this.validaPersonaFisica && this.validaCuentaCheques
      this.$emit('updateValores',this.ingresos)
    },
    getSectoresLaborales(){
            let self=this
            var objRequestListaSectorLaborales = {
                strApiKey: this.key,
                strMetodo: 'CatalogosGeneralesBancos',
                objCatalogo:
                {
                    TipoCatalogo: "7"
                }
            }
            this.$axios.post('/',objRequestListaSectorLaborales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(
                response => {
                if(response.data.intEstatus == 0){ 
                    this.sectores=response.data.objContenido 
                }else {
                    this.$vs.notify({
                    title:'Ocurrio un error en ListaSectoresLaborales',
                    text:response.data.strError,
                    color:'danger',
                    position:'top-right'
                    })
                }
                }
            ).catch(function (error) {
                self.$vs.notify({
                title:'Ocurrio un error de sistema',
                text:error,
                color:'danger',
                position:'top-right'
                })
      })
    },
    getOcupaciones(){
            let self=this
            var objRequestListaOcupaciones = {
                strApiKey: this.key,
                strMetodo: 'CatalogosGeneralesBancos',
                objCatalogo:
                {
                    TipoCatalogo: "6"
                }
            }
            this.$axios.post('/',objRequestListaOcupaciones,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(
                response => {
                if(response.data.intEstatus == 0){ 
                    this.ocupaciones=response.data.objContenido 
                }else {
                    this.$vs.notify({
                    title:'Ocurrio un error en ListaOcupaciones',
                    text:response.data.strError,
                    color:'danger',
                    position:'top-right'
                    })
                }
                }
            ).catch(function (error) {
                self.$vs.notify({
                title:'Ocurrio un error de sistema',
                text:error,
                color:'danger',
                position:'top-right'
                })
      })
    },
    getEscolaridades(){
            let self=this
            var objRequestListaEscolaridades = {
                strApiKey: this.key,
                strMetodo: 'CatalogosGeneralesBancos',
                objCatalogo:
                {
                    TipoCatalogo: "5"
                }
            }
            this.$axios.post('/',objRequestListaEscolaridades,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(
                response => {
                if(response.data.intEstatus == 0){ 
                    this.escolaridades=response.data.objContenido 
                }else {
                    this.$vs.notify({
                    title:'Ocurrio un error en ListaEscolaridades',
                    text:response.data.strError,
                    color:'danger',
                    position:'top-right'
                    })
                }
                }
            ).catch(function (error) {
                self.$vs.notify({
                title:'Ocurrio un error de sistema',
                text:error,
                color:'danger',
                position:'top-right'
                })
      })
    },
    getTipoIngresos(){
            let self=this
            var objRequestListaTipoIngresos = {
                strApiKey: this.key,
                strMetodo: 'CatalogosGeneralesBancos',
                objCatalogo:
                {
                    TipoCatalogo: "4"
                }
            }
            this.$axios.post('/',objRequestListaTipoIngresos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(
                response => {
                if(response.data.intEstatus == 0){ 
                    this.tipoIngresos=response.data.objContenido 
                }else {
                    this.$vs.notify({
                    title:'Ocurrio un error en ListaTipoIngresos',
                    text:response.data.strError,
                    color:'danger',
                    position:'top-right'
                    })
                }
                }
            ).catch(function (error) {
                self.$vs.notify({
                title:'Ocurrio un error de sistema',
                text:error,
                color:'danger',
                position:'top-right'
                })
      })
    },
    validarFechaEmpleoAnterior(){
       var fechaInicio = new Date(this.ingresos.inicioAnterior);
      var fechaUnMesesAntes = new Date();
      // Quitas los meses
      fechaUnMesesAntes.setMonth(fechaUnMesesAntes.getMonth() - 1);
      this.mostrarMensajeFechaMinimaAnterior = fechaInicio>fechaUnMesesAntes
    },
    validarFecha(){
      var fechaInicio = new Date(this.ingresos.fechaInicioAsalariado);
      var fechaSeisMesesAntes = new Date();
      // Quitas los meses
      fechaSeisMesesAntes.setMonth(fechaSeisMesesAntes.getMonth() - 6);
      this.muestraEmpleoAnterior = fechaInicio>fechaSeisMesesAntes
    },
    validaIngresoNeto() {
      this.ingreso.ingresoNeto=this.ingreso.ingresoNeto.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  
      const matches = regex.test(this.ingreso.ingresoNeto);
      if(matches===true){
      this.ingreso.ingresoNeto= this.ingreso.ingresoNeto
      }else{
        this.ingreso.ingresoNeto=this.ingreso.ingresoNeto.replace(/.$/, '')
      }
    },
    formateaIngresoNeto(){
      if(this.ingreso.ingresoNeto==''){
        this.ingreso.ingresoNeto=0
      }
      this.ingreso.ingresoNeto = parseFloat(this.ingreso.ingresoNeto).toFixed(2)
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.ingreso.ingresoNeto= this.ingreso.ingresoNeto.toString().replace(exp,rep);
    },
     validaIngersoMensual() {
      this.ingreso.ingresoMensual=this.ingreso.ingresoMensual.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  
      const matches = regex.test(this.ingreso.ingresoMensual);
      if(matches===true){
      this.ingreso.ingresoMensual= this.ingreso.ingresoMensual
      }else{
        this.ingreso.ingresoMensual=this.ingreso.ingresoMensual.replace(/.$/, '')
      }
    },
    formateaIngresoMensual(){
      if(this.ingreso.ingresoMensual==''){
        this.ingreso.ingresoMensual=0
      }
      this.ingreso.ingresoMensual = parseFloat(this.ingreso.ingresoMensual).toFixed(2)
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.ingreso.ingresoMensual= this.ingreso.ingresoMensual.toString().replace(exp,rep);
    },
    numberFormat(num){
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    registraEmpleoAnterior(){
      var objEmpleoAnterior= {
        strApiKey:this.key,
        strMetodo:'RegistraSolicitanteEmpleoAnterior',
        objSolicitante: {
          EMail:this.email,
          Empleo:{
            NombreEmpleoAnterior:this.anterior.empresaAnterior,
            JefeEmpleoAnterior:this.anterior.jefe,
            TelefonoEmpleoAnterior:this.anterior.telefono,
            CausaSeparacionEmpleoAnterior:this.anterior.separacion,
            FechaIngresoEmpleoAnterior:this.anterior.inicioAnterior,
            FechaSalidaEmpleoAnterior:this.anterior.salidaAnterior,
            DescripcionFuncionesLaborales:this.anterior.funciones,
            TipoContratoEmpleoAnterior: this.anterior.contrato,
            TipoEmpleoAnterior: this.anterior.tipo,
            SectorRedHipotecariaEmpleoAnterior: this.anterior.sector
          }
        }
      }
      this.$axios.post('/',objEmpleoAnterior,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraSolicitanteEmpleoAnterior',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    editarIngreso(){
      let self= this
      var objRequestRegistraCuentasBancarias = {
        strApiKey:this.key,
        strMetodo:'EditarIngresos',
        objSolicitante:{
            EMail:this.email,
            Ingresos:[
              {
                Id:this.ingreso.ingresoId,
                IngresosMensuales:parseFloat(this.ingreso.ingresoMensual.replace(/,/g,"")),
                IngresosNetos:parseFloat(this.ingreso.ingresoNeto.replace(/,/g,"")),
                TipoEmpleo:this.ingreso.tipoEmpleo,
                Empresa : this.ingreso.empresa,
                Puesto : this.ingreso.puesto,
                Giro : this.ingreso.giro,
                TipoContrato : this.ingreso.tipoContrato,
                RetieneImpuestos : this.ingreso.impuestos,
                FechaInicio : this.ingreso.fechaInicio,
                Domicilio : this.ingreso.domicilio,
                Colonia : this.ingreso.colonia,
                NumeroExterior: this.ingreso.numeroExterior,
                NumeroInterior:this.ingreso.numeroInterior,
                Ciudad : this.ingreso.ciudad,
                Municipio : this.ingreso.municipio,
                CodigoPostal : this.ingreso.codigoPostal,
                Telefono : this.ingreso.telefono,
                AreaDepartamento : this.ingreso.area,
                SectorRedHipotecaria : this.ingreso.sector,
                Profesion : this.ingreso.profesion,
                ComprobanteIngresos : this.ingreso.comprobanteIngresos,
                Estado : this.ingreso.estado,
                Pais : this.ingreso.pais,
                JefeInmediatoNombre : this.ingreso.jefe,
              }
            ],
            Empleo:{
                ActividadesEmpleoActual:this.ingreso.funciones,
          }
        }
      }
      this.$axios.post('/',objRequestRegistraCuentasBancarias,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos editados exitosamente`,
              position:'top-right'
            })
            this.popupActive=false
            this.limpiaForm()
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCuentasBancarias',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    editar(ingreso){
      this.ingreso.ingresoId=ingreso.Id
      this.ingreso.tipoEmpleo=ingreso.TipoEmpleoId
      this.ingreso.ingresoNeto=this.numberFormat(ingreso.IngresosNetos)
      this.ingreso.ingresoMensual=this.numberFormat(ingreso.IngresosMensuales)
      this.ingreso.empresa=ingreso.Empresa
      this.ingreso.puesto=ingreso.Puesto
      this.ingreso.giro=ingreso.GiroId
      this.ingreso.tipoContrato=ingreso.TipoContratoId
      this.ingreso.impuestos=ingreso.RetieneImpuestos
      this.ingreso.fechaInicio= ingreso.FechaInicio
      this.ingreso.domicilio=ingreso.Domicilio
      this.ingreso.colonia=ingreso.Colonia
      this.ingreso.numeroExterior=ingreso.NumeroExterior
      this.ingreso.numeroInterior=ingreso.NumeroInterior
      this.ingreso.ciudad=ingreso.Ciudad
      this.ingreso.municipio=ingreso.Municipio
      this.ingreso.codigoPostal=ingreso.CodigoPostal
      this.ingreso.telefono=ingreso.Telefono
      if (ingreso.AreaDepartamento) {
        this.ingreso.area=ingreso.AreaDepartamento
      }else {
        this.ingreso.area=''
      }
      this.ingreso.sector=ingreso.SectorRedHipotecariaId
      this.ingreso.profesion=ingreso.Profesion
      this.ingreso.comprobanteIngresos=ingreso.ComprobanteIngresosId
      this.ingreso.estado=ingreso.EstadoId
      this.ingreso.pais=ingreso.Pais
      this.ingreso.jefe=ingreso.JefeInmediatoNombre
      this.popupActive=true
    },
    eliminar(id){
      var eliminarCuentas = {
        strApiKey:this.key,
        strMetodo:'BorrarIngresos',
        objSolicitante:{
            EMail:this.email,
            Ingresos:[
              {
                Id:id
              }
            ]
          }
      }
      this.$axios.post('/',eliminarCuentas,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Se eliminó exitosamente`,
              position:'top-right'
            })
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en BorrarIngresos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraIngreso(){
      this.$vs.loading({
        container: '#container',
        scale: 0.6
      })
      let self=this
      var objRequestListaCasos = {
        strApiKey:this.key,
        strMetodo:'RegistraIngresos',
        objSolicitante:{
            EMail:this.email,
            Ingresos:[
              {
                IngresosMensuales:parseFloat(this.ingreso.ingresoMensual.replace(/,/g,"")),
                 IngresosNetos:parseFloat(this.ingreso.ingresoNeto.replace(/,/g,"")),
                 TipoEmpleo:this.ingreso.tipoEmpleo,
                 Empresa : this.ingreso.empresa,
                 Puesto : this.ingreso.puesto,
                 Giro : this.ingreso.giro,
                 TipoContrato : this.ingreso.tipoContrato,
                 RetieneImpuestos : this.ingreso.impuestos,
                 FechaInicio : this.ingreso.fechaInicio,
                 Domicilio : this.ingreso.domicilio,
                 Colonia : this.ingreso.colonia,
                 NumeroExterior: this.ingreso.numeroExterior,
                 NumeroInterior: this.ingreso.numerioInterior,
                 Ciudad : this.ingreso.ciudad,
                 Municipio : this.ingreso.municipio,
                 CodigoPostal : this.ingreso.codigoPostal,
                 Telefono : this.ingreso.telefono,
                 AreaDepartamento : this.ingreso.area,
                 SectorRedHipotecaria : this.ingreso.sector,
                 Profesion : this.ingreso.profesion,
                 ComprobanteIngresos : this.ingreso.comprobanteIngresos,
                 Estado : this.ingreso.estado,
                 Pais : this.ingreso.pais,
                 JefeInmediatoNombre : this.ingreso.jefe,
              }
            ],
            Empleo:{
              ActividadesEmpleoActual:this.ingreso.funciones,
          }
        }
      }
      this.$axios.post('/',objRequestListaCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.limpiaForm()
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraIngresos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
          this.$vs.loading.close('#container > .con-vs-loading')
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.email= response.data.objContenido.Solicitante
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self=this
      var objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.solicitante=response.data.objContenido
            this.ingresos=response.data.objContenido.Ingresos
            this.ingreso.funciones=this.solicitante.DescripcionFuncionesLaborales
            this.anterior.empresaAnterior=this.solicitante.NombreEmpleoAnterior
            this.anterior.inicioAnterior=this.solicitante.FechaIngresoEmpleoAnterior
            this.anterior.salidaAnterior=this.solicitante.FechaSalidaEmpleoAnterior
            this.anterior.separacion=this.solicitante.CausaSeparacionEmpleoAnterior
            this.anterior.jefe=this.solicitante.JefeEmpleoAnterior
            this.anterior.telefono=this.solicitante.TelefonoEmpleoAnterior
            this.anterior.contrato=this.solicitante.TipoContratoEmpleoAnterior
            this.anterior.tipo=this.solicitante.TipoEmpleoAnterior
            this.anterior.sector=this.solicitante.SectorRedHipotecariaEmpleoAnterior
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaTipoEmpleos(){
      let self=this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaTipoEmpleos',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.tiposDeEmpleo=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoEmpleos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getEstados(){
      let self=this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaEstados',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estados=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstados',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getTipoIdentificaciones(){
      let self=this
      var objRequestListaTipoIdentificaciones = {
        strApiKey: this.key,
        strMetodo: 'ListaComprobantesIngreso',
      }
      this.$axios.post('/',objRequestListaTipoIdentificaciones,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.comprobantes=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoIdentificaciones',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getGiros(){
      let self=this
      var objRequestListaGirosIngreso = {
        strApiKey: this.key,
        strMetodo: 'ListaGirosIngreso',
      }
      this.$axios.post('/',objRequestListaGirosIngreso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.giros=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaGirosIngreso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getTiposContratos(){
      let self=this
      var objRequestListaTipoContratos = {
        strApiKey: this.key,
        strMetodo: 'ListaTipoContratos',
      }
      this.$axios.post('/',objRequestListaTipoContratos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.tiposContratos=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoContratos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getSectoresRed(){
      let self=this
      var objRequestListaSectorRedHipotecaria = {
        strApiKey: this.key,
        strMetodo: 'ListaSectorRedHipotecaria',
      }
      this.$axios.post('/',objRequestListaSectorRedHipotecaria,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.sectores=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaSectorRedHipotecaria',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    limpiaForm(){
      this.ingreso.tipoEmpleo=''
      this.ingreso.ingresoNeto=''
      this.ingreso.ingresoMensual=''
      this.ingreso.empresa=''
      this.ingreso.puesto=''
      this.ingreso.giro=''
      this.ingreso.tipoContrato=''
      this.ingreso.impuestos=false
      this.ingreso.fechaInicio=''
      this.ingreso.domicilio=''
      this.ingreso.colonia=''
      this.ingreso.numeroExterior=''
      this.ingreso.numeroInterior=''
      this.ingreso.ciudad=''
      this.ingreso.municipio=''
      this.ingreso.codigoPostal=''
      this.ingreso.telefono=''
      this.ingreso.area=''
      this.ingreso.sector=''
      this.ingreso.profesion=''
      this.ingreso.comprobanteIngresos=''
      this.ingreso.estado=''
      this.ingreso.pais=''
      this.ingreso.jefe=''
      this.ingreso.funciones=''
      // this.ingreso.empresaAnterior=''
      // this.ingreso.duracionAnios=''
      // this.ingreso.duracionMeses=''
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  }
}
</script>

<style lang="css" scoped>
.v-select{
    width: 100%;
}
.label-select{
  margin-top: 3%;
}
.con-vs-checkbox{
  margin-top: 5%;
}
</style>
