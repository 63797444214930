<template lang="html">
      <div class="vista" id="cotizacion">
      	<div class="tab-content" id="myTabContent">
        <div class="row encabezadoPrecalificar ">
            <div class="col-md-4">
              <h6><b>Precalificar</b></h6>
              <vs-button color="primary" @click="openPrecalificacion()" type="border" >Realizar precalificación</vs-button>
            </div>
            <div class="col-md-4">
              <br>
              <h6><b style="text-transform:uppercase;">Diagnóstico de crédito</b></h6>
            </div>
            <div class="col-md-4">
              <h6><b>Resultado de precalificación</b></h6>
              <h5><b style="color: #079DEF; text-transform:uppercase;">{{this.ResultadoPrecalificacion}}</b></h5>
              <h6><b><span>Fecha:</span> <span>{{this.fechaPrecalificacion}}</span> </b></h6>
            </div>
        </div> 
        <div class="tablaPrecalificar">
        <table class="">
          <tr>
            <td><span class="tDia">Ingresos</span><br><b>{{this.Signos}}{{numberFormat(this.StatusIngresos)}}</b></td>
            <td><span class="tDia">Deuda mensual</span><br><b>{{this.Signos}}{{numberFormat(this.StatusDeudaMensual)}}</b></td>
            <td><span class="tDia">Capacidad de pago</span><br><b>{{this.Signos}}{{numberFormat(this.StatusCapacidadPago)}}</b></td>
            <td><span class="tDia">Monto solicitado</span><br><b style="color:#079DEF;">{{this.Signos}}{{numberFormat(this.StatusMontoSolicitado)}}</b></td>
            <td><span class="tDia">Monto máximo</span><br><b style="color:#079DEF;">{{this.Signos}}{{numberFormat(this.StatusMontoMaximo)}}</b></td>
          </tr>
          <tr style="border-top:1px solid ; padding-bottom:20px;">
            <td colspan="5">
              <span class="tDia">Detalles</span>
              <div class="col-md-12">
                <div class="detalles">{{this.CondicionesLeyendas}}<br>
                {{this.CondicionesLeyendas2}} <br>
               
                <div v-if="CondicionesLeyendas3==true">
                El solicitante no cumple con la Capacidad de Pago de ${{numberFormat(this.resultadoCondicionesPago)}} para alcanzar el Monto Solicitado de ${{numberFormat(this.StatusMontoSolicitado)}}
                </div>

                Línea de crédito máxima posible sujeta a aforo y condiciones del crédito

                
                </div>
              </div>            
            </td>
          </tr>
        </table>
        </div>
       
        <vx-card class="alinear-reporte"> 
          <h5 style="margin-bottom:20px;">Reporte crediticio</h5>
         <!-- <vs-alert color="danger" title="Completa tu Información" :active="!completeInfo">
            Ve al apartado de información personal y completa los datos generales para poder consultar el buró de crédito.
          </vs-alert>-->
          
          <button  class="btn btn-second-line"  @click="openConfirm()" >Consultar reporte crediticio</button><!-- se quita validación de campos :disabled="!completeInfo"-->
      


         <div class="ver-reporte"  v-if="this.statusPedidoBuro!=' '">
            <a  @click="AbrirReporte()"  >Ver último reporte</a>
         </div>

        </vx-card>
         
        <div title="" class="" v-if="mostrarBotonSms">
          <div class="modalSMS">
          <div class="confirmacion-phone text-center">
              <p>Escribe el código que recibiste en tu teléfono</p>
              <input id="uno" type="text" v-model="numUno" maxlength="1" minlength="1" class="mb-2" placeholder="" @keyup="cambiarInput()">
              <input id="dos" type="text" v-model="numDos" maxlength="1" minlength="1" class="mb-2" placeholder="" @keyup="cambiarInput()">
              <input id="tres" type="text" v-model="numTres" maxlength="1" minlength="1" class="mb-2" placeholder="" @keyup="cambiarInput()">
              <input id="cuatro" type="text" v-model="numCuatro" maxlength="1" minlength="1" class="mb-2" placeholder="">
              <a @click="generarCodigo()" class="d-block blue text-center mb-4">Reenviar código</a>
              <div class="d-flex justify-content-center justify-content-md-end">
              <div class="row w-full" >
               <div class="col-md-4"><a  class="mr-2 btn-back" @click="regresar()" >Regresar</a></div>
               <div class="col-md-4"></div>
               <div class="col-md-4">  <vs-button  @click="validaCodigo()" :disabled="!infoCompleta" color="primary">Solicitar</vs-button></div>
              </div>
                
              
                  <div class="alert alert-success alert-margin" @click="closeAlert('exito')" role="alert " :class="alertExito? '' : 'ocultarMensaje' ">
                    {{mensajeExito}}
                  </div>
                  <div class="alert alert-danger" @click="closeAlert('error')"  role="alert" :class="alertError? '' : 'ocultarMensaje' ">
                    {{mensajeError}}
                  </div>  
              </div>
              <a @click="cerrarModalSMS()" class="close"><img src="../../../assets/images/icon-close.svg" width="15px" alt="close"></a>
            </div> 
            </div>
        </div><!-- Agregar sms -->
        
       
        </div>
      </div>
      
</template>



<script>
export default {
    props: {
    CambioVistaN: {
      type: String,
      required: false
    
    }
    
   },
  data() {
    return {
      completeInfo: false,
      completeInfoBuro: false,
      id: "",
      key: "",
      email: "",
      tarjeta: "",
      digitos: "",
      creditoHipotecario: "",
      creditoAuto: "",
      popupActive: false,
      creditos: [],
      score: "",
      activeConfirm: false,
      mensajeError: "",
      mensajeExito: "",
      alertExito: false,
      alertError: false,
      mostrarBoton: false,
      mostrarBotonSms: false,
      ladaPais: "52",
      telefonoCelular: "",
      numUno: "",
      numDos: "",
      numTres: "",
      numCuatro: "",
      statusPedidoBuro: "",
      StatusIngresos: 0,
      StatusDeudaMensual: 0,
      StatusCapacidadPago: 0,
      StatusMontoSolicitado: 0,
      StatusMontoMaximo: 0,
      PrecalificacionSoc: "",
      Signos: "",
      ResultadoPrecalificacion: "NO DISPONIBLE",
      fechaPrecalificacion:'',
      PrecaDestino: "",
      ScorePreca: 678,
      HistorialPreca: false,
      CondicionesLeyendas: "",
      CondicionesLeyendas2: "",
      CondicionesLeyendas3: "",
      resultadoCondicionesPago: 0,
      popupActive: false,
      activePrompt: false,
      variablesVacias: []
    };
  },
  watch: {
    CambioVistaN:function(){
     // alert('hola'+this.CambioVistaN )
      if(this.CambioVistaN =='Credito'){
       
        this.dameSolicitante()
        this.completeInfoBuro=false
        this.variablesVacias= []
      }
    }

   },
  computed: {
    infoCompleta() {
      return (
        this.numUno.length > 0 &&
        this.numDos.length > 0 &&
        this.numTres.length > 0 &&
        this.numCuatro.length > 0
      );
    },
  },
  mounted() {
    this.id = this.$route.params.id;

    this.getApikey();
    this.dameCaso();
    //this.DameReporte()
  },
  methods: {
    closeAlert(alerta) {
      switch (alerta) {
        case "exito":
          this.alertExito = false;
          break;
        case "error":
          this.alertError = false;
          break;
        default:
          break;
      }
    },
    numberFormat(number) {
      return number
       // .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    consultarSolicitanteyGenerarSms() {
      this.mostrarBotonSms=true;
    //  alert(this.mostrarBotonSms)
      this.dameSolicitante();
      setTimeout(() => this.generarCodigo(), 2000);
     
    },
    cerrarModalSMS(){
      this.mostrarBotonSms=false;
    },
    regresar() {
      this.mostrarBotonSms = false;
      //this.completeInfo = true;
    },
    cambiarInput() {
      //var focusedElement = document.activeElement;
      //console.log(focusedElement.id);
      if (this.numUno.length > 0) {
        document.getElementById("dos").focus();
      }
      if (this.numUno.length > 0 && this.numDos.length > 0) {
        document.getElementById("tres").focus();
      }
      if (
        this.numUno.length > 0 &&
        this.numDos.length > 0 &&
        this.numTres.length > 0
      ) {
        document.getElementById("cuatro").focus();
      }
    },
      validaCodigo(){
           let self=this
           this.codigoVerificacion=this.numUno+this.numDos+this.numTres+this.numCuatro
           var objGeneraCodigo={
             Codigo: this.codigoVerificacion,
             numero: this.ladaPais+this.telefonoCelular
           } 
        this.$axios.post('https://cotizador.socasesores.com/EnvioSms/ValidaSms.php',objGeneraCodigo,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
        .then(
            response => {
              //if(response.intRespuesta==0){
                 var respuestaHubspot = JSON.parse(response.data.objRespuesta)
                if(respuestaHubspot.Mensaje && respuestaHubspot.Mensaje=='Número telefónico validado con éxito'){
                  this.mensajeExito= respuestaHubspot.Mensaje
                  this.alertExito=true
                  setTimeout(() => this.alertExito = false, 5000);
                  this.mostrarBotonSms=false
                  this.completeInfo=true
                  this.numUno=''
                  this.numDos=''
                  this.numTres=''
                  this.numCuatro=''
                  
                   // this.$router.push('/caso/'+this.id).catch(() => {})
                  //this.consultar()
                  this.actualizarInfo()
                }else if(respuestaHubspot.Mensaje && respuestaHubspot.Mensaje=='Código inválido o expiró'){
                  this.alertError=true
                  this.mensajeError=respuestaHubspot.Mensaje
                   setTimeout(() => this.alertError = false, 5000);
                } else if(respuestaHubspot.Message){
                  this.alertError=true
                  this.mensajeError=respuestaHubspot.Message
                   setTimeout(() => this.alertError = false, 5000);
                }else if(respuestaHubspot.Mensaje && respuestaHubspot.Mensaje == 'Error'){
                    this.alertError=true
                    this.mensajeError=respuestaHubspot.Mensaje
                    setTimeout(() => this.alertError = false, 5000);
               }else if(respuestaHubspot.Mensaje !== '' ){ 
                  this.alertError=true
                  this.mensajeError=respuestaHubspot.Mensaje
                   setTimeout(() => this.alertError = false, 5000);
              }
                
            }
        ).catch(function (error) {
            this.$vs.notify({
              title:'Ocurrio un errros en ValidaSms',
              text:error,
              color:'danger',
              position:'top-right'
            })
        })
    },
   
    generarCodigo() {
    //  this.mostrarBotonSms = true;
      //alert(this.mostrarBotonSms)
      this.mensajeExito =
        "En breve te llegará un código a tu teléfono, Este tiene una validez de 5 minutos.”";
      //this.alertExito=true
      //let self=this
      var objGeneraCodigo = {
        metodo: "Generar",
        numero: this.ladaPais + this.telefonoCelular,
      };
      this.$axios
        .post(
          "https://cotizador.socasesores.com/EnvioSms/GeneraSms.php",
          objGeneraCodigo,
          { headers: { "Content-Type": "application/json; charset=UTF-8" } }
        )
        .then((response) => {
          var respuestaHubspot = JSON.parse(response.data.objRespuesta);
          // console.log(respuestaHubspot)
          if (
            respuestaHubspot.Mensaje &&
            respuestaHubspot.Mensaje == "Teléfono validado."
          ) {
            this.mensajeExito = respuestaHubspot.Mensaje;
            this.alertExito = true;
            setTimeout(() => (this.alertExito = false), 5000);
            this.mostrarBotonSms = false;
            this.completeInfo = true;
            this.numUno = "";
            this.numDos = "";
            this.numTres = "";
            this.numCuatro = "";
            //this.consultar();
          } else if (
            respuestaHubspot.Message ==
            "Authorization has been denied for this request."
          ) {
            this.alertError = true;
            this.mensajeError = respuestaHubspot.Mensaje;
            setTimeout(() => (this.alertError = false), 5000);
          } else if (
            respuestaHubspot.Mensaje &&
            respuestaHubspot.Mensaje == "Mensaje SMS enviado."
          ) {
            this.alertExito = true;
            setTimeout(() => (this.alertExito = false), 5000);
            this.mostrarBotonSms = true; ///aqui era false
          } else if (respuestaHubspot.Mensaje !== "") {
            this.$vs.notify({
              title: "Ocurrio un error en EnvioSms",
              text: respuestaHubspot.Mensaje,
              color: "danger",
              position: "top-right",
            });
            this.alertError = true;
            this.mensajeError = respuestaHubspot.Mensaje;
            setTimeout(() => (this.alertError = false), 5000);
          }
        })
        .catch(function (error) {
          this.$vs.notify({
            title: "Ocurrio un errros en EnvioSms",
            text: error,
            color: "danger",
            position: "top-right",
          });
        });
    },
    AbrirReporte() {
      var WhatsPrint = window.open(
        "https://cotizador.socasesores.com/admin/formatos/FormatoConsultaCirculoCredito.php?s=" +
          this.statusPedidoBuro
      );
    },

    openConfirm() {
      this.$router.push("/ReporteCoacreditado/" + this.email+"/"+ this.id);
      // alert(this.id)

      /* this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: `Consultar Buró de Crédito`,
        text: '¿Estás seguro que deseas realizar la consulta?',
        accept: this.consultarSolicitanteyGenerarSms //agregar sms
      })*/
    },

    openPrecalificacion() {
      //this.$router.push('/PrecaSoc/'+this.id)
      this.$vs.dialog({
        type: "confirm",
        color: "success",
        title: `Aviso`,
        text:'¿Deseas ejecutar la Precalificacion SOC para este Coacreditado?, Recuerda que esto hace uso de las consultas de Circulo de Credito que tenga disponible tu empresa.',
        buttonCancel: "success",
        acceptText:"Aceptar",
        cancelText:"Cancelar",
        accept: this.DatosConsultaBuro, //agregar sms
      });
    },
    DatosConsultaBuro() {
      let self = this;
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: "DameSolicitante",
        objSolicitante: {
          EMail: this.email,
        },
      };
      this.$axios
        .post("/", objRequestDameSolicitante, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then((response) => {
          if (response.data.intEstatus == 0) {
            let solicitante = response.data.objContenido;

            //campos para sms


            this.completeInfoBuro =
              solicitante.Nombres != "" &&
              solicitante.ApellidoPaterno != "" &&
              solicitante.ApellidoMaterno != "" &&
              solicitante.TelefonoCelular != "" &&
              solicitante.EMail != "" &&
              (solicitante.Rfc != "" ||
              solicitante.Rfc != null) &&
              (solicitante.Curp != "" ||
              solicitante.Curp != null) &&
              solicitante.Sexo != "" &&
              solicitante.FechaNacimiento != "" &&
              (solicitante.CodigoPostal != "" ||
              solicitante.CodigoPostal != null) &&
              (solicitante.Municipio != "" ||
              solicitante.Municipio != null) &&
              (solicitante.Colonia != "" ||
              solicitante.Colonia != null) &&
              (solicitante.Calle != "" ||
              solicitante.Calle != null) &&
              (solicitante.NumeroExterior != "" &&
              solicitante.NumeroExterior != null) &&
              (solicitante.Ciudad != "" ||
              solicitante.Ciudad != null) &&
              (solicitante.Estado != "" ||
              solicitante.Estado != null) &&
              solicitante.Ingresos.length >= 1 ;

           
              

            if (this.completeInfoBuro == false) {
               

              if(solicitante.Ingresos.length == 0){
                   this.variablesVacias.push('Ingreso Mensual')
                   this.variablesVacias.push('Ingreso Fecha Inicio')
                   this.variablesVacias.push('Tipo Empleo')
                   this.variablesVacias.push('Comprobante Ingresos')
              }else{
                   
                 // alert(solicitante.Ingresos[0].ComprobanteIngresos )
                  if( solicitante.Ingresos[0].IngresosMensuales == ""){
                       this.variablesVacias.push('Ingreso Mensual')
                  }
                  if( solicitante.Ingresos[0].FechaInicio == ""){
                       this.variablesVacias.push('Ingreso Fecha Inicio')
                  }
                  if( solicitante.Ingresos[0].TipoEmpleo == ""){
                       this.variablesVacias.push('Tipo Empleo')
                  }
                  if( solicitante.Ingresos[0].ComprobanteIngresos == null){
                       this.variablesVacias.push('Comprobante Ingresos')
                  }

              }

              if( solicitante.Nombres  == ""){
                    this.variablesVacias.push('Nombres')
              } 
              if(solicitante.ApellidoPaterno  == ""){
                    this.variablesVacias.push('Apellido Paterno')
              } 
              if(solicitante.ApellidoMaterno == ""){
                    this.variablesVacias.push('Apellido Materno')
              } 
              if( solicitante.TelefonoCelular == ""){
                    this.variablesVacias.push('Telefono Celular')
              }
              if(solicitante.EMail  == ""){
                    this.variablesVacias.push('EMail')
              } 
              if(solicitante.Rfc  == ""){
                    this.variablesVacias.push('Rfc')
              } 
               if(solicitante.Rfc  == null){
                    this.variablesVacias.push('Rfc')
              } 
              if(solicitante.Curp  == ""){
                    this.variablesVacias.push('Curp')
              } 
               if(solicitante.Curp  == null){
                    this.variablesVacias.push('Curp')
              } 
              if(solicitante.Sexo  == ""){
                    this.variablesVacias.push('Sexo')
              } 
              if(solicitante.Sexo  == null){
                    this.variablesVacias.push('Sexo')
              } 
               if(solicitante.FechaNacimiento  == ""){
                    this.variablesVacias.push('Fecha Nacimiento')
              } 
              if(solicitante.CodigoPostal  == ""){
                    this.variablesVacias.push('Codigo Postal')
              } 
               if(solicitante.CodigoPostal  == null){
                    this.variablesVacias.push('Codigo Postal')
              } 
              if(solicitante.Municipio  == ""){
                    this.variablesVacias.push('Municipio')
              } 
              if(solicitante.Municipio  == null){
                    this.variablesVacias.push('Municipio')
              }  
              if(solicitante.Colonia  == ""){
                    this.variablesVacias.push('Colonia')
              } 
              if(solicitante.Colonia  == null){
                    this.variablesVacias.push('Colonia')
              } 
              if(solicitante.Calle  == ""){
                    this.variablesVacias.push('Calle')
              } 
              if(solicitante.Calle  == null){
                    this.variablesVacias.push('Calle')
              } 
              if(solicitante.NumeroExterior  == ""){
                    this.variablesVacias.push('Numero Exterior')
              } 
               if(solicitante.NumeroExterior  == null){
                    this.variablesVacias.push('Numero Exterior')
              } 
              if(solicitante.Ciudad  == ""){
                    this.variablesVacias.push('Ciudad')
              }
               if(solicitante.Ciudad  == null){
                    this.variablesVacias.push('Ciudad')
              } 
              if(solicitante.Estado  == ""){
                    this.variablesVacias.push('Estado')
              }
               if(solicitante.Estado  == null){
                    this.variablesVacias.push('Estado')
              }


                
                let variables1= this.variablesVacias
                

                if(variables1.length>11){
    //alert('1')               
                         this.$vs.dialog({
                        type: "alert",
                        color: "success",
                        title: `Aviso`,
                        text: "Faltan datos para poder realizar la Precalificacion",
                        acceptText:"Aceptar",
                        buttonCancel:"false",
                       });
                  
                }else{
                     let textoVariables=''
                     let acomuladoVar=''
                    for (var i = 0; i <= variables1.length; i++) {

                        if(variables1.length==1)
                          textoVariables= variables1[0]
                        if(variables1.length==2)
                          textoVariables= variables1[0]+', '+variables1[1]
                        if(variables1.length==3)
                          textoVariables= variables1[0]+', '+variables1[1]+', '+variables1[2]
                        if(variables1.length==4)
                          textoVariables= variables1[0]+', '+variables1[1]+', '+variables1[2]+', '+variables1[3]
                        if(variables1.length==5)
                          textoVariables= variables1[0]+', '+variables1[1]+', '+variables1[2]+', '+variables1[3]+', '+variables1[4]
                        if(variables1.length==6)
                          textoVariables= variables1[0]+', '+variables1[1]+', '+variables1[2]+', '+variables1[3]+', '+variables1[4]+', '+variables1[5]
                        if(variables1.length==7)
                          textoVariables= variables1[0]+', '+variables1[1]+', '+variables1[2]+', '+variables1[3]+', '+variables1[4]+', '+variables1[5]+', '+variables1[6]
                        if(variables1.length==8)
                          textoVariables= variables1[0]+', '+variables1[1]+', '+variables1[2]+', '+variables1[3]+', '+variables1[4]+', '+variables1[5]+', '+variables1[6]+', '+variables1[7]
                        if(variables1.length==9)
                          textoVariables= variables1[0]+', '+variables1[1]+', '+variables1[2]+', '+variables1[3]+', '+variables1[4]+', '+variables1[5]+', '+variables1[6]+', '+variables1[7]+', '+variables1[8]
                        if(variables1.length==10)
                          textoVariables= variables1[0]+', '+variables1[1]+', '+variables1[2]+', '+variables1[3]+', '+variables1[4]+', '+variables1[5]+', '+variables1[6]+', '+variables1[7]+', '+variables1[8]+', '+variables1[9]
      //alert('12')
                    this.$vs.dialog({
                        type: "alert",
                        color: "success",
                        title: `Aviso`,
                        text: "Faltan datos para poder realizar la Precalificacion " +textoVariables,
                        acceptText:"Aceptar",
                        buttonCancel:"false",
                    });

                      
                    }

                }

             
            } else {

                
                  if( solicitante.Ingresos[0].IngresosMensuales == ""){
                       this.variablesVacias.push('Ingreso Mensual')
                  }
                  if( solicitante.Ingresos[0].FechaInicio == ""){
                       this.variablesVacias.push('Fecha Inicio')
                  }
                  if( solicitante.Ingresos[0].TipoEmpleo == ""){
                       this.variablesVacias.push('Tipo Empleo')
                  }
                  if( solicitante.Ingresos[0].ComprobanteIngresos == null){
                       this.variablesVacias.push('Comprobante de Ingresos')
                  }

                  let variables1= this.variablesVacias

                   if(variables1.length>0){

                       let textoVariables=''

                        for (var i = 0; i <= variables1.length; i++) {
                            if(variables1.length==1)
                            textoVariables= variables1[0]
                            if(variables1.length==2)
                            textoVariables= variables1[0]+', '+variables1[1]
                            if(variables1.length==3)
                            textoVariables= variables1[0]+', '+variables1[1]+', '+variables1[2]
                            if(variables1.length==4)
                            textoVariables= variables1[0]+', '+variables1[1]+', '+variables1[2]+', '+variables1[3]
                        

                        }
                   
                  // alert('123')
                         this.$vs.dialog({
                            type: "alert",
                            color: "success",
                            title: `Aviso`,
                            text: "Faltan datos para poder realizar la Precalificacion " +textoVariables,
                            acceptText:"Aceptar",
                            buttonCancel:"false",
                        });
                  
                 }else{
                     this.getCountDisponibles();
                 }


              
            }
          } else {
            this.$vs.notify({
              title: "Ocurrio un error en DameSolicitante",
              text: response.data.strError,
              color: "danger",
              position: "top-right",
            });
          }
        })
        .catch(function (error) {
          self.$vs.notify({
            title: "Ocurrio un error de sistema",
            text: error,
            color: "danger",
            position: "top-right",
          });
        });
    },

    getCountDisponibles() {
      let self = this;
      var objRequestCount = {
        strApiKey: this.key,
        strMetodo: "BrokerConsultasBuro",
        objParametros: {
          ApiKey: this.key,
        },
      };

      this.$axios
        .post("/", objRequestCount, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then((response) => {
          if (response.data.intEstatus == 0) {
            this.count = response.data.objContenido;

            if (this.count > 0) {                   
              this.consultarSolicitanteyGenerarSms();
            } else {
               this.$vs.dialog({
                id:"alertAzul",      
                type: "alert",
                color: "dark",
                title: `Aviso`,
                text: "Tu empresa no cuenta con las consultas disponibles o no tienes la facultad para poder realizar consultas de Circulo de Credito",
                acceptText:"Aceptar",
                buttonCancel:"false",
                
              });
            }
          }else{
               this.$vs.dialog({
                id:"alertAzul",      
                type: "alert",
                color: "dark",
                title: `Aviso`,
                text: "Tu empresa no cuenta con las consultas disponibles o no tienes la facultad para poder realizar consultas de Circulo de Credito",
                acceptText:"Aceptar",
                buttonCancel:"false",
                
              });
          }
        })
        .catch(function (error) {
          self.$vs.notify({
            title: "Ocurrio un error de sistema",
            text: error,
            color: "danger",
            position: "top-right",
          });
        });
    },
    actualizarInfo() {
      this.$vs.loading({
				container: '#cotizacion',
				scale: 0.6,
	  })
      let objRequestRegistraSolicitante = {
        strApiKey: this.key,
        strMetodo: "RegistraSolicitante",
        objSolicitante: {
          EMail: this.email,
          PrecalificacionSocCoacreditado: true,

        },
      };
      this.$axios
        .post("/", objRequestRegistraSolicitante, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then((response) => {
          if (response.data.intEstatus == 0) {
            this.consultar();
          } else {
            this.$vs.notify({
              title: "Ocurrio un error en RegistraSolicitante",
              text: response.data.strError,
              color: "danger",
              position: "top-right",
            });
          }
        })
        .catch(function (error) {
          self.$vs.notify({
            title: "Ocurrio un error de sistema",
            text: error,
            color: "danger",
            position: "top-right",
          });
        });
    },
   /* consultar() {
      this.$vs.loading.close("#main > .con-vs-loading");
      window.open(
        "https://cotizador.socasesores.com/app/buro/" + this.email,
        "_blank"
      );
    },*/
    consultar(){

      let self=this
      var objRequestConsultaBuro = {
        strApiKey: this.key,
        strMetodo: 'ConsultaBuroCoacreditado',
        objSolicitante: {
         EMail: this.email,
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestConsultaBuro,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$router.push('/caso/'+this.id).catch(() => {})
            this.$vs.loading.close('#cotizacion > .con-vs-loading')   

            window.location = 'https://cotizador.socasesores.com/app/caso/'+this.id 
            
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ConsultaBuro',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso() {
      let self = this;
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: "DameCaso",
        objCaso: {
          Id: this.id,
        },
      };
      this.$axios
        .post("/", objRequestDameCaso, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then((response) => {
          if (response.data.intEstatus == 0) {
            if (response.data.objContenido.Coacreditado[0]) { //condicion que elimina el error de la alert Email not define
              this.email = response.data.objContenido.Coacreditado[0].EMail;
            }



              this.StatusMontoSolicitado = response.data.objContenido.MontoSolicitado;
             
              this.PrecaDestino = response.data.objContenido.Destino;
       

            this.DameReporte();
            this.dameSolicitante();
          } else {
            this.$vs.notify({
              title: "Ocurrio un error en DameCaso",
              text: response.data.strError,
              color: "danger",
              position: "top-right",
            });
          }
        })
        .catch(function (error) {
          self.$vs.notify({
            title: "Ocurrio un error de sistema",
            text: error,
            color: "danger",
            position: "top-right",
          });
        });
    },
    DameReporte() {
      let self = this;
      var objRequestDameCaso = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: "GetConsultaSolicitanteCredito",
        objCaso: {
          Email: this.email,
        },
      };
      this.$axios
        .post("/", objRequestDameCaso, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then((response) => {
          if (response.data.intEstatus == 0) {
            this.statusPedidoBuro = response.data.objContenido;
          } else {
            this.$vs.notify({
              title: "Ocurrio un error en DameCaso",
              text: response.data.strError,
              color: "danger",
              position: "top-right",
            });
          }
        })
        .catch(function (error) {
          self.$vs.notify({
            title: "Ocurrio un error de sistema",
            text: error,
            color: "danger",
            position: "top-right",
          });
        });
    },
    dameSolicitante() {
      let self = this;
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: "DameSolicitante",
        objSolicitante: {
          EMail: this.email,
        },
      };
      this.$axios
        .post("/", objRequestDameSolicitante, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then((response) => {
          if (response.data.intEstatus == 0) {
            let solicitante = response.data.objContenido;

            if (solicitante.PrecalificacionSocCoacreditado == true ) {
              
              this.Signos = "$";

              this.fechaPrecalificacion= solicitante.FehaPrecalificacionCoacreditado;
              this.StatusIngresos =  solicitante.Ingresos[0].IngresosMensuales;
              this.StatusDeudaMensual = 10004;

              this.StatusCapacidadPago =
                solicitante.Ingresos[0].IngresosMensuales -
                this.StatusDeudaMensual;

              this.StatusMontoMaximo = this.StatusCapacidadPago * 40;

              ////iNICIA VALIDACION DE ESTATUS DE PRECALIFICACION

              //

              if (this.PrecaDestino == 1) {
           

                if (
                  this.ScorePreca >= 551 &&
                  this.HistorialPreca == false &&
                  this.StatusCapacidadPago >= 15000 &&
                  this.StatusMontoSolicitado >= 500000 &&
                  this.StatusMontoSolicitado <= this.StatusMontoMaximo
                ) {
                  this.ResultadoPrecalificacion = "APROBADO";
                  //this.CondicionesLeyendas='Línea de crédito máxima posible sujeta a aforo y condiciones del crédito'
                } else if (
                  this.ScorePreca >= 551 &&
                  this.HistorialPreca == false &&
                  this.StatusCapacidadPago >= 15000 &&
                  this.StatusMontoSolicitado >= 225000 &&
                  this.StatusMontoSolicitado < 500000 &&
                  this.StatusMontoSolicitado <= this.StatusMontoMaximo
                ) {
                  this.ResultadoPrecalificacion = "PROBABILIDAD DE RECHAZO";
                  if (this.StatusCapacidadPago < 15000) {
                    this.CondicionesLeyendas =
                      "El solicitante no cumple con la Capacidad de Pago mínima de $15,000";
                  }

                  if (
                    this.StatusMontoSolicitado >= 225000 &&
                    this.StatusMontoSolicitado < 500000
                  ) {
                    this.CondicionesLeyendas2 =
                      "El solicitante no cumple con el Monto de Crédito mínimo de $500,000";
                  }

                  if (this.StatusMontoSolicitado <= this.StatusMontoMaximo) {
                    this.CondicionesLeyendas3 = "";
                  } else {
                    this.resultadoCondicionesPago =
                      this.StatusMontoSolicitado / 40;
                    this.CondicionesLeyendas3 = true; //'El solicitante no cumple con la Capacidad de Pago de '+this.resultadoCondicionesPago+' para alcanzar el Monto Solicitado de '+this.StatusMontoSolicitado
                  }
                } else {
                  this.ResultadoPrecalificacion = "RECHAZADO";
                  if (this.StatusCapacidadPago < 15000) {
                    this.CondicionesLeyendas =
                      "El solicitante no cumple con la Capacidad de Pago mínima de $15,000";
                  }

                  if (this.StatusMontoSolicitado < 225000) {
                    this.CondicionesLeyendas2 =
                      "El solicitante no cumple con el Monto de Crédito mínimo de $500,000";
                  }

                  if (this.StatusMontoSolicitado > this.StatusMontoMaximo) {
                    this.resultadoCondicionesPago =
                      this.StatusMontoSolicitado / 40;
                    this.CondicionesLeyendas3 = true;
                  }
                }
              } else if (
                this.PrecaDestino == 19 ||
                this.PrecaDestino == 3 ||
                this.PrecaDestino == 5
              ) {
                if (
                  this.ScorePreca >= 551 &&
                  this.HistorialPreca == false &&
                  this.StatusCapacidadPago >= 15000 &&
                  this.StatusMontoSolicitado >= 500000 &&
                  this.StatusMontoSolicitado <= this.StatusMontoMaximo
                ) {
                  this.ResultadoPrecalificacion = "APROBADO";
                  // this.CondicionesLeyendas='Línea de crédito máxima posible sujeta a aforo y condiciones del crédito'
                } else if (
                  this.ScorePreca >= 551 &&
                  this.HistorialPreca == false &&
                  this.StatusCapacidadPago >= 15000 &&
                  this.StatusMontoSolicitado >= 225000 &&
                  this.StatusMontoSolicitado < 500000 &&
                  this.StatusMontoSolicitado <= this.StatusMontoMaximo
                ) {
                  this.ResultadoPrecalificacion = "PROBABILIDAD DE RECHAZO";

                  if (this.StatusCapacidadPago < 15000) {
                    this.CondicionesLeyendas =
                      "El solicitante no cumple con la Capacidad de Pago mínima de $15,000";
                  }

                  if (
                    this.StatusMontoSolicitado >= 225000 &&
                    this.StatusMontoSolicitado < 500000
                  ) {
                    this.CondicionesLeyendas2 =
                      "El solicitante no cumple con el Monto de Crédito mínimo de $500,000";
                  }

                  if (this.StatusMontoSolicitado <= this.StatusMontoMaximo) {
                    this.CondicionesLeyendas3 = "";
                  } else {
                    this.resultadoCondicionesPago =
                      this.StatusMontoSolicitado / 40;
                    this.CondicionesLeyendas3 = true; //'El solicitante no cumple con la Capacidad de Pago de '+this.resultadoCondicionesPago+' para alcanzar el Monto Solicitado de '+this.StatusMontoSolicitado
                  }
                } else {
                  this.ResultadoPrecalificacion = "RECHAZADO";

                  if (this.StatusCapacidadPago < 15000) {
                    this.CondicionesLeyendas =
                      "El solicitante no cumple con la Capacidad de Pago mínima de $15,000";
                  }

                  if (this.StatusMontoSolicitado < 225000) {
                    this.CondicionesLeyendas2 =
                      "El solicitante no cumple con el Monto de Crédito mínimo de $500,000";
                  }

                  if (this.StatusMontoSolicitado > this.StatusMontoMaximo) {
                    this.resultadoCondicionesPago =
                      this.StatusMontoSolicitado / 40;
                    this.CondicionesLeyendas3 = true; //'El solicitante no cumple con la Capacidad de Pago de '+this.resultadoCondicionesPago+' para alcanzar el Monto Solicitado de '+this.StatusMontoSolicitado
                  }
                }
              } else if (this.PrecaDestino == 2 || this.PrecaDestino == 8) {
                if (
                  this.ScorePreca >= 551 &&
                  this.HistorialPreca == false &&
                  this.StatusCapacidadPago >= 15000 &&
                  this.StatusMontoSolicitado >= 500000 &&
                  this.StatusMontoSolicitado <= this.StatusMontoMaximo
                ) {
                  this.ResultadoPrecalificacion = "APROBADO";
                  //this.CondicionesLeyendas='Línea de crédito máxima posible sujeta a aforo y condiciones del crédito'
                } else if (
                  this.ScorePreca >= 551 &&
                  this.HistorialPreca == false &&
                  this.StatusCapacidadPago >= 15000 &&
                  this.StatusMontoSolicitado >= 225000 &&
                  this.StatusMontoSolicitado < 500000 &&
                  this.StatusMontoSolicitado <= this.StatusMontoMaximo
                ) {
                  this.ResultadoPrecalificacion = "PROBABILIDAD DE RECHAZO";

                  if (this.StatusCapacidadPago < 15000) {
                    this.CondicionesLeyendas =
                      "El solicitante no cumple con la Capacidad de Pago mínima de $15,000";
                  }

                  if (
                    this.StatusMontoSolicitado >= 225000 &&
                    this.StatusMontoSolicitado < 500000
                  ) {
                    this.CondicionesLeyendas2 =
                      "El solicitante no cumple con el Monto de Crédito mínimo de $500,000";
                  }

                  if (this.StatusMontoSolicitado <= this.StatusMontoMaximo) {
                    this.CondicionesLeyendas3 = "";
                  } else {
                    this.resultadoCondicionesPago =
                      this.StatusMontoSolicitado / 40;
                    this.CondicionesLeyendas3 = true; //'El solicitante no cumple con la Capacidad de Pago de '+this.resultadoCondicionesPago+' para alcanzar el Monto Solicitado de '+this.StatusMontoSolicitado
                  }
                } else {
                  this.ResultadoPrecalificacion = "RECHAZADO";

                  if (this.StatusCapacidadPago < 15000) {
                    this.CondicionesLeyendas =
                      "El solicitante no cumple con la Capacidad de Pago mínima de $15,000";
                  }

                  if (this.StatusMontoSolicitado < 225000) {
                    this.CondicionesLeyendas2 =
                      "El solicitante no cumple con el Monto de Crédito mínimo de $500,000";
                  }

                  if (this.StatusMontoSolicitado > this.StatusMontoMaximo) {
                    this.resultadoCondicionesPago =
                      this.StatusMontoSolicitado / 40;
                    this.CondicionesLeyendas3 = true; //'El solicitante no cumple con la Capacidad de Pago de '+this.resultadoCondicionesPago+' para alcanzar el Monto Solicitado de '+this.StatusMontoSolicitado
                  }
                }
              }
            } else {
              //alert('error')
              this.StatusIngresos = "--";
              this.StatusCapacidadPago = "--";
              this.StatusMontoMaximo = "--";
              this.StatusMontoSolicitado = "--";
               this.Signos = "";
               this.StatusDeudaMensual= "--";

              

            }

            //campos para sms
            this.telefonoCelular = solicitante.TelefonoCelular;
            //this.ladaPais = solicitante.Lada;
            this.completeInfo =
              solicitante.Nombres != "" &&
              solicitante.ApellidoPaterno != "" &&
              solicitante.ApellidoMaterno != "" &&
              solicitante.Rfc != "" &&
              solicitante.FechaNacimiento != "" &&
              solicitante.Calle != "" &&
              solicitante.NumeroExterior != "" &&
              solicitante.Colonia != "" &&
              solicitante.Ciudad != "" &&
              solicitante.CodigoPostal != "" &&
              solicitante.Estado != "" &&
              solicitante.TelefonoCelular != "";
          } else {
            this.$vs.notify({
              title: "Ocurrio un error en DameSolicitante",
              text: response.data.strError,
              color: "danger",
              position: "top-right",
            });
          }
        })
        .catch(function (error) {
          self.$vs.notify({
            title: "Ocurrio un error de sistema",
            text: error,
            color: "danger",
            position: "top-right",
          });
        });
    },
    getApikey() {
      if (!JSON.parse(localStorage.getItem("userInfo")).ApiKey) {
        this.$router.push("/login");
      } else {
        this.key = JSON.parse(localStorage.getItem("userInfo")).ApiKey;
      }
    },
  },
};
</script>

<style lang="css">
.tablaPrecalificar {
  border: 1px solid #006d4e;
  border-radius: 5px;
  padding: 5px;
  overflow-x: auto;
  display: block;
}
.encabezadoPrecalificar,
.tablaPrecalificar td {
  text-align: center;
  background: #fff;
  padding: 10px !important;
}
.tDia {
  color: #015694;
  font-weight: bold;
}
.detalles {
  border: 1px solid #079def;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  min-height: 70px;
}
.alinear-reporte {
  text-align: center;
  max-width: 300px;
}
.ver-reporte {
  text-decoration: underline;
  padding-top: 20px;
}
.ver-reporte a {
  text-decoration: underline;
  color: #4589ff;
  cursor: pointer;
}
.selectExample {
  width: 150%;
}

.confirmacion-phone {
  background: #f1f2f7 0% 0% no-repeat padding-box;
  border: 1px solid #00000033;
  border-radius: 4px;
  padding: 4rem 1rem 2rem 1rem;
  max-width: 500px;
  top:20%;
  margin-left: auto;
  margin-right: auto;
}
.confirmacion-phone input {
  max-width: 40px;
  margin: 0 auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
}
.confirmacion-phone p {
  color: #212529 !important;
  font-weight: bold !important;
  visibility: visible;
  opacity: 1;
  display: block;
  font-size: 14px !important;
  background-color: unset;
  text-align: center;
}
.confirmacion-phone a {
  color: #829494;
}
.confirmacion-phone a.blue {
  color: #2ab5df;
}
.confirmacion-phone {
  position: relative;
}
.modalSMS {
    position: fixed;
    z-index: 999;
    height: 100%;
    width: 100%;
    top: 0;
    left:0;
    background: rgba(0,0,0,0.4);
    transition: all .25s ease;
}   
.close {
  position: absolute;
  right: 10px;
  top: 5px;
}
.btn-back {
  background-color: transparent !important;
  color: #6c757d;
  font-size: 17px !important;
  width: 275px !important;
  height: 44px;
  border: none;
}
.ocultarMensaje {
  display: none !important;
}
.alert {
  max-width: 300px;
  bottom: 63px;
  right: 3.2rem;
  position: absolute;
  padding: 0.75rem 1.25rem;
  margin-bottom: 10px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
button.vs-component.vs-button.vs-dialog-cancel-button.vs-button-primary.vs-button-success,
.vs-component.vs-button.vs-dialog-cancel-button.vs-button-primary.vs-button-flat,
button.vs-component.vs-button.vs-dialog-accept-button.vs-button-dark.vs-button-filled {
    min-width: 102px;
    min-height: 42px;
    background-color: #079def !important;
    color: #fff !important;
}
.vs-dialog-success .vs-dialog .vs-dialog-header,
.con-vs-dialog .vs-dialog header
{
    background: #4ba84f !important;
    color: #fff !important;
    border-radius: 5px 5px 0px 0px;

}
.vs-dialog-dark .vs-dialog .vs-dialog-header,
.con-vs-dialog .vs-dialog header,
.con-vs-dialog .vs-dialog header .dialog-title
.con-vs-dialog .vs-dialog header .vs-dialog-cancel 
{
    background: #079def !important;
    color: #fff !important;
    border-radius: 5px 5px 0px 0px !important;

}
.con-vs-dialog .vs-dialog header .dialog-title,
.con-vs-dialog .vs-dialog header .vs-dialog-cancel{
  color: #fff !important;
}

button.vs-component.vs-button.vs-dialog-accept-button.vs-button-success.vs-button-filled {
    background: #4ba84f !important;
}

.con-vs-dialog .vs-dialog footer {
    justify-content: center !important;
    background: #fff;}
</style>