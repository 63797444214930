<template lang="html">
  <div id="container">
    <div class="">
      <div class="">
        <vx-card title="Datos requeridos" class="">
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Institución acreedora *</span>
              <input class="vs-input" v-model="adeudo.institucion" name="username" />
            </div>
            <div class="col-md-4">
              <span class="span-placeholder" for="formasEnganche">Tipo de adeudo *</span>
              <select name="cars" id="formasEnganche" v-model="adeudo.tipoAdeudo" class="vs-input">
                <option :value="''">Seleccione...</option>
                <option :value='tipo.Id' v-for="tipo in tiposDeAdeudo"  :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Número de cuenta</span>
              <input class="vs-input" v-model="adeudo.numeroCuenta" name="username" />
            </div>
          </div>
          <br>
          <div class="accordion" id="accordionADE">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneADE">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneADE" aria-expanded="true" aria-controls="collapseOneADE">
                  Adicionales requeridos para llenado de solicitudes requeridos
                </button>
              </h2>
              <div id="collapseOneADE" class="accordion-collapse collapse" aria-labelledby="headingOneADE" data-bs-parent="#accordionADE">
                <div class="accordion-body">
                  <div class="row w-full">
                <div class="col-md-4">
                  <span class="span-placeholder">Pago mensual</span>
                  <input class="vs-input" v-model="adeudo.pagoMensual" name="name" v-on:keypress="isNumber($event)"/>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Fecha de inicio</span>
                  <input class="vs-input" type="date" v-model="adeudo.fechaApertura" name="fechaNacimiento" />
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Límite de crédito</span>
                  <input class="vs-input" v-model="adeudo.limiteCredito" name="name" v-on:keypress="isNumber($event)"/>
                </div>
                  </div>
                  <div class="row w-full">
                <div class="col-md-4">
                  <span class="span-placeholder">Saldo actual</span>
                  <input class="vs-input" v-model="adeudo.saldoActual" name="name" v-on:keypress="isNumber($event)"/>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Número de sucursal</span>
                  <input class="vs-input" v-model="adeudo.sucursalPlaza" name="name" />
                </div>
              </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <button class="btn btn-second" @click="registraAdeudo()" :disabled="!validateForm">Guardar adeudo</button>
             </div> 
          </div>            

          <br>
        </vx-card>
        <vx-card title="" class="">
          <vs-table :data="adeudos" max-items="5"
            pagination
            noDataText="No hay datos para mostrar">
            <template slot="thead">
              <vs-th>
                Institución acreedora
              </vs-th>
              <vs-th>
                Tipo de adeudo
              </vs-th>
              <vs-th>
                Número de Cuenta
              </vs-th>
              <vs-th>
                Acciones
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].Institucion" >
                  {{data[indextr].Institucion}}
                </vs-td>
                <vs-td :data="data[indextr].Tipo">
                  {{data[indextr].Tipo}}
                </vs-td>
                <vs-td :data="data[indextr].Cuenta">
                  {{data[indextr].Cuenta}}
                </vs-td>
                <vs-td>
                  <vs-row vs-w="12">
                    <vx-tooltip text="Editar cuenta" position="top">
                      <button class="favoritos" @click="editar(data[indextr])">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <g id="vuesax_twotone_edit" data-name="vuesax/twotone/edit" transform="translate(-620 -252)">
                            <g id="edit">
                              <path id="Vector" d="M9,0H7C2,0,0,2,0,7v6c0,5,2,7,7,7h6c5,0,7-2,7-7V11" transform="translate(622 254)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.4"/>
                              <path id="Vector-2" data-name="Vector" d="M8.991,1.271l-7.88,7.88a2.712,2.712,0,0,0-.66,1.32l-.43,3.01a1.424,1.424,0,0,0,1.7,1.7l3.01-.43a2.8,2.8,0,0,0,1.32-.66l7.88-7.88c1.36-1.36,2-2.94,0-4.94S10.351-.089,8.991,1.271Z" transform="translate(627.049 253.749)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                              <path id="Vector-3" data-name="Vector" d="M0,0A7.144,7.144,0,0,0,4.94,4.94" transform="translate(634.91 256.15)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.4"/>
                              <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(620 252)" fill="none" opacity="0"/>
                            </g>
                          </g>
                        </svg>
                      </button>
                    </vx-tooltip>
                    <vx-tooltip text="Borrar cuenta" position="top">
                      <button class="favoritos" @click="eliminar(data[indextr].Id)">
                        <svg class="trash">
                                <g id="trash">
                                  <path id="Vector" d="M18,.5C14.67.17,11.32,0,7.98,0A59.068,59.068,0,0,0,2.04.3L0,.5" transform="translate(3 5.48)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  <path id="Vector-2" data-name="Vector" d="M0,2.97.22,1.66C.38.71.5,0,2.19,0H4.81C6.5,0,6.63.75,6.78,1.67L7,2.97" transform="translate(8.5 2)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                                  <path id="Vector-3" data-name="Vector" d="M13.7,0l-.65,10.07c-.11,1.57-.2,2.79-2.99,2.79H3.64C.85,12.86.76,11.64.65,10.07L0,0" transform="translate(5.15 9.14)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  <path id="Vector-4" data-name="Vector" d="M0,0H3.33" transform="translate(10.33 16.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                                  <path id="Vector-5" data-name="Vector" d="M0,0H5" transform="translate(9.5 12.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                                  <path id="Vector-6" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0"/>
                                </g>
                              </svg>
                      </button>
                    </vx-tooltip>
                  </vs-row>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
    <vs-popup fullscreen title="Editar Adeudo" :active.sync="popupActive">
      <vx-card title="Datos requeridos" class="">
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder">Institución acreedora *</span>
            <input class="vs-input" v-model="adeudo.institucion" name="username" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder" for="formasEnganche">Tipo de adeudo *</span>
            <select name="cars" id="formasEnganche" v-model="adeudo.tipoAdeudo" class="vs-input">
              <option :value="''">Seleccione...</option>
              <option :value='tipo.Id' v-for="tipo in tiposDeAdeudo"  :key="">{{tipo.Nombre}}</option>
            </select>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Número de cuenta</span>
            <input class="vs-input" v-model="adeudo.numeroCuenta" name="username" />
          </div>
        </div>
        <br>
        <h4>Adicionales</h4>
        <br>
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder">Pago mensual</span>
            <input class="vs-input" v-model="adeudo.pagoMensual" name="name" v-on:keypress="isNumber($event)"/>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Fecha de inicio</span>
            <input class="vs-input" type="date" v-model="adeudo.fechaApertura" name="fechaNacimiento" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Límite de crédito</span>
            <input class="vs-input" v-model="adeudo.limiteCredito" name="name" v-on:keypress="isNumber($event)"/>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Saldo actual</span>
            <input class="vs-input" v-model="adeudo.saldoActual" name="name" v-on:keypress="isNumber($event)"/>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Número de sucursal</span>
            <input class="vs-input" v-model="adeudo.sucursalPlaza" name="name" />
          </div>
        </div>
        <br>
        <vs-button color="primary" @click="editarAdeudos()" :disabled="!validateForm">Editar cuenta</vs-button>
      </vx-card>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    'v-select': vSelect,
  },
  data(){
		return{
      popupActive: false,
      adeudo:{
        institucion:'',
        tipoAdeudo:'',
        pagoMensual:'',
        fechaApertura:'',
        saldoActual:'',
        numeroCuenta:'',
        limiteCredito:'',
        sucursalPlaza:'',
        adeudoId:'',
      },
      tiposDeAdeudo:[],
      adeudos:[],
      id:'',
      email:''
    }
  },
  watch: {
    pagoMensual: function(newValue) {
      this.adeudo.pagoMensual= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    saldoActual: function(newValue) {
      this.adeudo.saldoActual= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    limiteCredito: function(newValue) {
      this.adeudo.limiteCredito= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    popupActive:function(){
      if (this.popupActive == false) {
        this.limpiaForm()
      }
    },
  },
  computed:{
    pagoMensual(){
      return this.adeudo.pagoMensual
    },
    saldoActual(){
      return this.adeudo.saldoActual
    },
    limiteCredito(){
      return this.adeudo.limiteCredito
    },
    validateForm(){
      return this.adeudo.institucion != '' && this.adeudo.tipoAdeudo != '' && this.adeudo.numeroCuenta != ''
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    this.getTipoAdeudos()
    this.dameCaso()
  },
  methods:{
    editarAdeudos(){
      if (this.adeudo.limiteCredito== null) {
        this.adeudo.limiteCredito=''
      }
      if (this.adeudo.saldoActual== null) {
        this.adeudo.saldoActual=''
      }
      let self= this
      var objRequestRegistraCuentasBancarias = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'EditarAdeudos',
        objSolicitante:{
          EMail:this.email,
          Adeudos:[
            {
              Id:this.adeudo.adeudoId,
              PagoMensual:parseFloat(this.adeudo.pagoMensual.replace(/,/g,"")),
              Institucion:this.adeudo.institucion,
              Tipo:this.adeudo.tipoAdeudo,
              Cuenta :this.adeudo.numeroCuenta,
              LimiteCredito : parseFloat(this.adeudo.limiteCredito.replace(/,/g,"")),
              SaldoActual : parseFloat(this.adeudo.saldoActual.replace(/,/g,"")),
              SucursalPlaza : this.adeudo.sucursalPlaza,
              FechaApertura : this.adeudo.fechaApertura
            }
          ]
        }
      }

      this.$axios.post('/',objRequestRegistraCuentasBancarias,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos editados exitosamente`,
              position:'top-right'
            })
            this.popupActive=false
            this.limpiaForm()
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en EditarAdeudos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    editar(adeudo){
      this.adeudo.institucion=adeudo.Institucion
      this.adeudo.tipoAdeudo=adeudo.TipoId
      this.adeudo.pagoMensual=adeudo.PagoMensual
      this.adeudo.fechaApertura= adeudo.FechaApertura
      this.adeudo.saldoActual=adeudo.SaldoActual
      this.adeudo.numeroCuenta=adeudo.Cuenta
      this.adeudo.limiteCredito=adeudo.LimiteCredito
      this.adeudo.sucursalPlaza=adeudo.SucursalPlaza
      this.adeudo.adeudoId=adeudo.Id
      this.popupActive=true
    },
    eliminar(id){
      var eliminarCuentas = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'BorrarAdeudos',
        objSolicitante:{
            EMail:this.email,
            Adeudos:[
              {
                Id:id
              }
            ]
          }
      }
      this.$axios.post('/',eliminarCuentas,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Se eliminó exitosamente`,
              position:'top-right'
            })
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en BorrarAdeudos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraAdeudo(){
      this.$vs.loading({
        container: '#container',
        scale: 0.6
      })
      let self= this
      var objRequestListaCasos = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'RegistraAdeudos',
        objSolicitante:{
            EMail:this.email,
            Adeudos:[
              {
                PagoMensual:parseFloat(this.adeudo.pagoMensual.replace(/,/g,"")),
                Institucion:this.adeudo.institucion,
                Tipo:this.adeudo.tipoAdeudo,
                Cuenta :this.adeudo.numeroCuenta,
                LimiteCredito : parseFloat(this.adeudo.limiteCredito.replace(/,/g,"")),
                SaldoActual : parseFloat(this.adeudo.saldoActual.replace(/,/g,"")),
                SucursalPlaza : this.adeudo.sucursalPlaza,
                FechaApertura : this.adeudo.fechaApertura
              }
            ]
        }
      }
      this.$axios.post('/',objRequestListaCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.limpiaForm()
            this.dameSolicitante()
            this.$vs.loading.close('#container > .con-vs-loading')
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraAdeudos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.email= response.data.objContenido.Solicitante
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.adeudos=response.data.objContenido.Adeudos
            for (var i = 0; i < this.adeudos.length; i++) {
              this.adeudos[i].PagoMensual=(this.adeudos[i].PagoMensual).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              if (this.adeudos[i].LimiteCredito) {
                this.adeudos[i].LimiteCredito=(this.adeudos[i].LimiteCredito).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              if (this.adeudos[i].SaldoActual) {
                this.adeudos[i].SaldoActual=(this.adeudos[i].SaldoActual).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getTipoAdeudos(){
      let self= this
      var objRequestListaTipoAdeudos = {
        strApiKey: this.key,
        strMetodo: 'ListaTipoAdeudos',
      }
      this.$axios.post('/',objRequestListaTipoAdeudos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.tiposDeAdeudo=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoAdeudos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    limpiaForm(){
      this.adeudo.institucion=''
      this.adeudo.tipoAdeudo=''
      this.adeudo.pagoMensual=''
      this.adeudo.fechaApertura=''
      this.adeudo.saldoActual=''
      this.adeudo.numeroCuenta=''
      this.adeudo.limiteCredito=''
      this.adeudo.sucursalPlaza=''
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  }
}
</script>

<style lang="css" scoped>
.v-select{
    width: 100%;
}
.label-select{
  margin-top: 3%;
}
</style>
