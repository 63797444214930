<template>
    <div>
        
        <div class="flex justify-between items-center mb-10">
            <h5 class="font-bold">Cónyuge</h5>
            <button 
                class="btn btn-second" 
                @click="toggleSave()" 
                v-bind:hidden="!isConyugeEdit">Editar</button>
            <button 
                v-bind:hidden="isConyugeEdit" 
                class="btn btn-second" @click="toggleSave(); guardarConyuge()" 
                :disabled="!validate">Guardar información</button>
        </div>
        
        <div class="row w-full -mb-4"> <!-- # Seccion Datos Personales -->
            <div class="col-md-12">
                <h5 class="font-bold">Datos personales</h5> 
            </div>
		</div>

        <!-- primera fila -->
        <div class="row w-full">
            <!-- grupo email -->
            <div class="col-md-4" v-if="(estadoCivil == 1 || estadoCivil == 4) && regimenMatrimonial != 1">
                <span class="span-placeholder">Correo electrónico cónyuge</span>
                    <!-- v-on:blur="validaCorreoSolicitante('conyuge')" -->
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    v-model="conyuge.email" 
                    name="conyugeEmail" />
                <span class="text-danger span-placeholder" v-if="errorEmail">El Correo electronico no puede ser el mismo que del solicitante</span>
            </div>

            <div class="col-md-4" v-if="estadoCivil == 1 && regimenMatrimonial == 1">
                <span class="span-placeholder">Correo electrónico cónyuge</span>
                    <!-- v-on:blur="validaCorreoSolicitante('conyuge')" -->
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                    v-model="conyuge.email" 
                    name="conyugeEmail" />
                <span class="text-danger span-placeholder" v-if="errorEmail">El Correo electronico no puede ser el mismo que del solicitante</span>
            </div>

            <div class="col-md-4" v-if="estadoCivil == 4 && regimenMatrimonial == 1">
                <span class="span-placeholder">Correo electrónico cónyuge</span>
                    <!-- v-on:blur="validaCorreoSolicitante('conyuge')" -->
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    v-model="conyuge.email" 
                    name="conyugeEmail"/>
                <span class="text-danger span-placeholder" v-if="errorEmail">El Correo electronico no puede ser el mismo que del solicitante</span>
            </div>

            <!-- grupo curp -->
            <div class="col-md-4" v-if="estadoCivil == 1 || estadoCivil == 4">
                <span class="span-placeholder">CURP cónyuge</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    v-model="conyuge.curp" 
                    name="cuprconyuge" 
                    v-validate="'min:18|max:18'" />
                <span class="text-danger span-placeholder" v-show="errors.has('cuprconyuge')">El campo debe tener 18 caracteres</span>
            </div>

            <!-- grupo rfc -->
            <div class="col-md-4" v-if="(estadoCivil == 1 || estadoCivil == 4) && regimenMatrimonial == 3">
                <span class="span-placeholder">RFC cónyuge</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    label="RFC cónyuge" 
                    v-model="conyuge.rfc" 
                    name="rfcconyuge" 
                    v-validate="'min:13|max:13'" />
                <span class="text-danger span-placeholder" v-show="errors.has('rfcconyuge')">El campo debe tener 13 caracteres</span>
            </div>

            <div class="col-md-4" v-if="estadoCivil == 4 && (regimenMatrimonial == 1 || regimenMatrimonial == 2)">
                <span class="span-placeholder">RFC cónyuge</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    label="RFC cónyuge" 
                    v-model="conyuge.rfc" 
                    name="rfcconyuge" 
                    v-validate="'min:13|max:13'" />
                <span class="text-danger span-placeholder" v-show="errors.has('rfcconyuge')">El campo debe tener 13 caracteres</span>
            </div>

            <div class="col-md-4" v-if="estadoCivil == 1 && regimenMatrimonial == 2">
                <span class="span-placeholder">RFC cónyuge</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    label="RFC cónyuge" 
                    v-model="conyuge.rfc" 
                    name="rfcconyuge" 
                    v-validate="'min:13|max:13'" />
                <span class="text-danger span-placeholder" v-show="errors.has('rfcconyuge')">El campo debe tener 13 caracteres</span>
            </div>

            <div class="col-md-4" v-if="estadoCivil == 1 && regimenMatrimonial == 1">
                <span class="span-placeholder">RFC cónyuge</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    label="RFC cónyuge" 
                    v-model="conyuge.rfc" 
                    name="rfcconyuge" 
                    v-validate="'min:13|max:13'" />
                <span class="text-danger span-placeholder" v-show="errors.has('rfcconyuge')">El campo debe tener 13 caracteres</span>
            </div>
        </div>

        <!-- segunda fila -->
        <div class="row w-full">  
            <!-- grupo nombres -->
            <div class="col-md-4" v-if="(estadoCivil == 1 || estadoCivil == 4) && regimenMatrimonial != 1">
                <span class="span-placeholder">Nombres</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                    v-model="conyuge.nombre" 
                    name="conyugeName" />
			</div>

            <div class="col-md-4" v-if="estadoCivil == 1 && regimenMatrimonial == 1">
                <span class="span-placeholder">Nombres</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                    v-model="conyuge.nombre" 
                    name="conyugeName" />
            </div>

            <div class="col-md-4" v-if="estadoCivil == 4 && regimenMatrimonial == 1">
                <span class="span-placeholder">Nombres</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    v-model="conyuge.nombre"
                    name="conyugeName" />
            </div>

            <!-- grupo apellido paterno -->
            <div class="col-md-4" v-if="(estadoCivil == 1 || estadoCivil == 4) && regimenMatrimonial == 3">
                <span class="span-placeholder">Apellido paterno</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                    v-model="conyuge.apellidoPaterno" 
                    name="conyugeApP" />
            </div>

            <div class="col-md-4" v-if="estadoCivil == 4 && (regimenMatrimonial == 1 || regimenMatrimonial == 2)">
                <span class="span-placeholder">Apellido paterno</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    v-model="conyuge.apellidoPaterno" 
                    name="conyugeApP" />
            </div>

            <div class="col-md-4" v-if="estadoCivil == 1 && regimenMatrimonial == 2">
                <span class="span-placeholder">Apellido paterno</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    v-model="conyuge.apellidoPaterno" 
                    name="conyugeApP" />
            </div>

            <div class="col-md-4" v-if="estadoCivil == 1 && regimenMatrimonial == 1">
                <span class="span-placeholder">Apellido paterno</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    v-model="conyuge.apellidoPaterno" 
                    name="conyugeApP" />
            </div>

            <!-- grupo apellido materno -->
            <div class="col-md-4" v-if="(estadoCivil == 1 || estadoCivil == 4) && regimenMatrimonial == 3">
                <span class="span-placeholder">Apellido materno</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    v-model="conyuge.apellidoMaterno" 
                    name="conyugeApM" />
            </div>

            <div class="col-md-4" v-if="estadoCivil == 4 && (regimenMatrimonial == 1 || regimenMatrimonial == 2)">
                <span class="span-placeholder">Apellido materno</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    v-model="conyuge.apellidoMaterno" 
                    name="conyugeApM" />
            </div>

            <div class="col-md-4" v-if="estadoCivil == 1 && regimenMatrimonial == 2">
                <span class="span-placeholder">Apellido materno</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    v-model="conyuge.apellidoMaterno" 
                    name="conyugeApM" />
            </div>

            <div class="col-md-4" v-if="estadoCivil == 1 && regimenMatrimonial == 1">
                <span class="span-placeholder">Apellido materno</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    v-model="conyuge.apellidoMaterno" 
                    name="conyugeApM" />
            </div>
        </div>
        
        <!-- tercera fila -->
        <div class="row w-full">
            <div class="col-md-4" v-if="estadoCivil == 1 || estadoCivil == 4">
                <span class="span-placeholder" for="sexo">Género</span>
                <div class="col-md-4 alinear">
                    <div class="col-md-6 mr-4">
                        <span class="span-placeholder">Hombre</span>
                        <vs-radio v-bind:disabled="isConyugeEdit" v-model="conyuge.sexo" vs-name="sexoconyuge" vs-value="H"></vs-radio>
                    </div>
                    <div class="col-md-6 ml-6">
                        <span class="span-placeholder">Mujer</span>
                        <vs-radio v-bind:disabled="isConyugeEdit" v-model="conyuge.sexo" vs-name="sexoconyuge" vs-value="M"></vs-radio>
                    </div>
                </div>
                <!-- <select 
                    v-bind:disabled="isConyugeEdit" 
                    name="sexoconyuge" id="sexoconyuge" 
                    v-model="conyuge.sexo" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']">
                    <option :value="''">Seleccione...</option>
                    <option :value="'H'">MASCULINO</option>
                    <option :value="'M'">FEMENINO</option>
                </select> -->
            </div>        

            <div class="col-md-4" v-if="estadoCivil == 1 || estadoCivil == 4">
                <span class="span-placeholder">Fecha de nacimiento</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    type="date" 
                    v-model="conyuge.fechaNacimiento" 
                    name="fechaNacimientoconyuge" />
                <span class="text-danger span-placeholder" v-show="errors.has('fechaNacimiento')">Campo requerido</span>
            </div>

            <div class="col-md-4" v-if="estadoCivil == 1 || estadoCivil == 4">
                <span class="span-placeholder" for="nacionalidad">Nacionalidad</span>
                <select 
                    v-bind:disabled="isConyugeEdit" 
                    name="cars" id="nacionalidad" 
                    v-model="conyuge.nacionalidad" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']">
                    <option :value="''">Seleccione...</option>
                    <option :value="tipo.Nombre" v-for="tipo in nacionalidades" :key="tipo.Id">{{ tipo.Nombre }}</option>
                </select>
            </div>

		</div>
        
        <!-- cuarta fila -->
        <div class="row w-full">
            
            <div class="col-md-4" v-if="(estadoCivil == 1 || estadoCivil == 4) && conyuge.nacionalidad == 'MEXICANO (A)'">
                <span class="span-placeholder" for="estado">Entidad federativa nacimiento</span>
                <select 
                    v-bind:disabled="isConyugeEdit" 
                    name="cars" 
                    id="estado" 
                    v-model="conyuge.estadoNacimiento" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']">
                    <option :value="''">Seleccione...</option>
                    <option :value="tipo.Id" v-for="tipo in estados" :key="tipo.Id">{{ tipo.Nombre }}</option>
                </select>
            </div>
            
            <div class="col-md-4" v-if="estadoCivil == 1 || estadoCivil == 4">
                <span class="span-placeholder">País de residencia</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    v-model="conyuge.paisRecidencia" 
                    name="conyugePaisRecidencia" />
            </div>
        </div>

        <div class="row w-full mb-2"> <!-- # Seccion Domicilio -->
            <div class="col-md-12">
                <h5 class="font-bold">Domicilio</h5> 
            </div>
		</div>

        <!-- primera fila -->
        <div class="col-md-4 flex">
            <span class="span-placeholder col-8">Domicilio del solicitante</span>
            <vs-switch
                v-bind:disabled="isConyugeEdit" 
                class="col-4" 
                v-model="conyuge.viveMismoDomicilioTitular" />
        </div>

        <!-- segunda fila -->
        <div 
            v-if="!conyuge.viveMismoDomicilioTitular" 
            class="row w-full">
            <div class="col-md-6">
                <span class="span-placeholder">Calle</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                    v-model="conyuge.calle"
                    name="calle" />
			</div>

            <div class="col-md-3">
                <span class="span-placeholder">Número exterior</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                    v-model="conyuge.numeroExterior"
                    name="numeroExterior" />
			</div>

            <div class="col-md-3">
                <span class="span-placeholder">Número interior</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-model="conyuge.numeroInterior"
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                    name="numeroInterior" />
			</div>
        </div>

        <!-- tercera fila -->
        <div
            v-if="!conyuge.viveMismoDomicilioTitular" 
            class="row w-full">
            <div class="col-md-3">
                <span class="span-placeholder">Ciudad</span>
                <input 
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                    v-model="conyuge.ciudad"
                    name="ciudad" />
			</div>

            <div class="col-md-3">
                <span class="span-placeholder">Estado</span>
                <select 
                    v-model="conyuge.estado"
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                    name="estado">
                    <option :value="''">Seleccione...</option>
                    <option :value="tipo.Id" v-for="tipo in estados" :key="tipo.Id">{{ tipo.Nombre }}</option>
                </select>
			</div>

            <div class="col-md-3">
                <span class="span-placeholder">Código postal</span>
                <input 
                    v-model="conyuge.codigoPostal"
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                    name="codigoPostal" />
			</div>

            <div class="col-md-3">
                <span class="span-placeholder">Municipio</span>
                <input 
                    v-model="conyuge.municipio"
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                    name="municipio" />
			</div>
        </div>

        <!-- cuarta fila -->
        <div
            v-if="!conyuge.viveMismoDomicilioTitular" 
            class="row w-full">
            <div class="col-md-3">
                <span class="span-placeholder">Colonia</span>
                <input 
                    v-model="conyuge.colonia"
                    v-bind:disabled="isConyugeEdit" 
                    v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                    name="colonia" />
			</div>

            <div class="col-md-3"> 
                <span class="span-placeholder">Tiempo en domicilio</span>
                
                <div class="flex">
                    <span class="col-4">
                        <input 
                            v-model="conyuge.tiempoViviendoCiudad"
                            v-bind:disabled="isConyugeEdit"
                            v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                            class="input-antiguedad-am"
                            label="Tiempo en domicilio"
                            name="tiempoEnDomicilio"/>
                    </span>
                    <span class="col-2 input-antiguedad-fecha">
                        <span>A</span>
                    </span>

                    <span class="col-4">
                        <input 
                            v-model="conyuge.tiempoViviendoCiudadMeses"
                            v-bind:disabled="isConyugeEdit"
                            v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                            class="input-antiguedad-am"
                            label="Tiempo en domicilio"
                            name="tiempoEnDomicilioMeses"/>
                    </span>
                    <span 
                        class="col-2 input-antiguedad-fecha">
                        <span>M</span>
                    </span>

                </div>
            </div>

            <div class="col-md-3"> 
                <span class="span-placeholder">Antigüedad en la ciudad</span>
                <div class="flex">	
                    <span class="col-4">
                        <input 
                            v-model="conyuge.antiguedadEnCiudad"
                            v-bind:disabled="isConyugeEdit"
                            v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                            class="input-antiguedad-am"
                            label="Antigüedad en la ciudad"
                            v-on:keypress="isNumber($event)"
                            name="antiguedadCiudad"/>
                    </span>
                    <span 
                        class="col-2 input-antiguedad-fecha">
                        <span>A</span>
                    </span>

                    <span class="col-4">
                        <input 
                            v-model="conyuge.antiguedadEnCiudadMeses"
                            v-bind:disabled="isConyugeEdit"
                            v-bind:class="[ isConyugeEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                            class="input-antiguedad-am"
                            label="Antigüedad en la ciudad"
                            v-on:keypress="isNumber($event)"
                            name="antiguedadCiudadMeses"/>
                    </span>
                    <span 
                        class="col-2 input-antiguedad-fecha">
                        <span>M</span>
                    </span>
                </div>
            </div>
        </div>

        
    </div>
</template>

<script>
import vSelect from 'vue-select'
import { nacionalidades } from '@/assets/jsonFiles/nacionalidades.js'

export default {
    components: {
        'v-select': vSelect,
    },
    props: {
        personalConyuge: {
            type: Object,
            default: {},
        }
    },
    data() {
		return {
            // recibir info de PersonalesGeneral.vue
            estadoCivil: '',
            regimenMatrimonial: '',
            validate: false,
            errorEmail: false,

            nacionalidades: [],
            estados: [],
            key: '',
            isConyugeEdit: true,

			conyuge: {
                email: '', // ok
				nombre: '', // ok
				apellidoPaterno: '',// ok
				apellidoMaterno: '',// ok
				curp: '',// ok
				rfc: '',// ok
				estadoNacimiento: '',// ok
				sexo: '',// ok
				nacionalidad: '',// ok
                
                calle: '',
                numeroExterior: '',
                numeroInterior: '',
                ciudad: '',
                estado: '',
                codigoPostal: '',
                municipio: '',
                colonia: '',
                tiempoViviendoCiudad: '0',
                tiempoViviendoCiudadMeses: '0',
                antiguedadEnCiudad: '0',
                antiguedadEnCiudadMeses: '0',
                
                fechaNacimiento: null, // ok
                paisRecidencia: '',

                identidad: 'conyuge',

                viveMismoDomicilioTitular: false

				// participaCredito: false,
				// esConyugue: true,
				// seraCoPropietario: false,
				// consolidaIngresos: false,
				// parentesco: 1,
			},
            // En la tabla solicitantes estan todos los campos necesarios 
        }
    },
    mounted() {
        this.getApikey();
        this.getNacionalidades();
        this.getEstados();
    },
    watch: {
		// Formato mayusculas sin acento
		'conyuge.nombre': function(newValue, oldValue) {
			this.conyuge.nombre = this.textFormat(newValue);
		},
		'conyuge.apellidoPaterno': function(newValue, oldValue) {
			this.conyuge.apellidoPaterno = this.textFormat(newValue);
		},
		'conyuge.apellidoMaterno': function(newValue, oldValue) {
			this.conyuge.apellidoMaterno = this.textFormat(newValue);
		},
        personalConyuge: {
            deep: true,
            handler: function() {
                // console.log( 'Conyuge.vue, recibiendo data actualizada', this.personalConyuge );

                // obtener validaciones desde PersonalesGeneral.vue
                this.estadoCivil = this.personalConyuge.estadoCivil;
                this.regimenMatrimonial = this.personalConyuge.regimenMatrimonial;
                this.validate =  this.personalConyuge.validate;
                this.errorEmail = this.personalConyuge.errorEmail;

                // data actualizada desde PersonalesGeneral.vue
                this.conyuge.email = this.personalConyuge.conyuge.email;
                this.conyuge.nombre = this.personalConyuge.conyuge.nombre;
                this.conyuge.apellidoPaterno = this.personalConyuge.conyuge.apellidoPaterno;
                this.conyuge.apellidoMaterno = this.personalConyuge.conyuge.apellidoMaterno;
                this.conyuge.curp = this.personalConyuge.conyuge.curp;
                this.conyuge.rfc = this.personalConyuge.conyuge.rfc;
                this.conyuge.estadoNacimiento = this.personalConyuge.conyuge.estadoNacimiento;
                this.conyuge.sexo = this.personalConyuge.conyuge.sexo;
                this.conyuge.nacionalidad = this.personalConyuge.conyuge.nacionalidad;
                this.conyuge.fechaNacimiento = this.personalConyuge.conyuge.fechaNacimiento;
                this.conyuge.paisRecidencia = this.personalConyuge.conyuge.paisRecidencia;

				this.conyuge.calle = this.personalConyuge.conyuge.calle;
				this.conyuge.numeroExterior = this.personalConyuge.conyuge.numeroExterior;
				this.conyuge.numeroInterior = this.personalConyuge.conyuge.numeroInterior;
				this.conyuge.ciudad = this.personalConyuge.conyuge.ciudad;
				this.conyuge.estado = this.personalConyuge.conyuge.estado;
				this.conyuge.codigoPostal = this.personalConyuge.conyuge.codigoPostal;
				this.conyuge.municipio = this.personalConyuge.conyuge.municipio;
				this.conyuge.colonia = this.personalConyuge.conyuge.colonia;
				this.conyuge.tiempoViviendoCiudad = this.personalConyuge.conyuge.tiempoViviendoCiudad;
				this.conyuge.tiempoViviendoCiudadMeses = this.personalConyuge.conyuge.tiempoViviendoCiudadMeses;
				this.conyuge.antiguedadEnCiudad = this.personalConyuge.conyuge.antiguedadEnCiudad;
				this.conyuge.antiguedadEnCiudadMeses = this.personalConyuge.conyuge.antiguedadEnCiudadMeses;

                this.conyuge.viveMismoDomicilioTitular = this.personalConyuge.conyuge.viveMismoDomicilioTitular; // switch
            }
        },
        conyuge: {
            deep: true,
            handler: function() {
                // cuando cambie alguna propiedad se emite el objeto
                this.$emit('conyugePersonal', this.conyuge);
            }
        }

    },
    computed: {

    },
    methods: {
		// Conversion
		textFormat(strValue) {
			return strValue.normalize('NFD')
							.replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
							.normalize()
							.toUpperCase(); // Eliminar acentos menos Ññ
		},
        guardarConyuge() {
            // this.$emit('conyugePersonalGuardar', true);
            // console.log( 'Conyuge.vue guardarConyuge', this );
            this.$root.$refs.PersonalGeneralMethods.agregaConyuge();
            this.$root.$refs.PersonalGeneralMethods.registraAdicionales();
        },
		getApikey() {
			if (!JSON.parse(localStorage.getItem('userInfo')).ApiKey) {
				this.$router.push('/login')
			} else {
				this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey
				this.socData = JSON.parse(localStorage.getItem('socData'))
			}
		},
		getEstados() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaEstados',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.estados = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaEstados',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
        getNacionalidades() {
            let nacionalidadesList = [];
            let index = 3;
            for (const nacionalidad of nacionalidades) {
                if (nacionalidad.Nombre === "MEXICANO (A)") {
                    nacionalidadesList[0] = nacionalidad;
                    continue;
                }
                if (nacionalidad.Nombre === "NORTEAMERICANO (A)") {
                    nacionalidadesList[1] = nacionalidad;
                    continue;
                }
                if (nacionalidad.Nombre === "CANADIENSE") {
                    nacionalidadesList[2] = nacionalidad;
                    continue;
                }
                nacionalidadesList[index] = nacionalidad;
                index ++;
			}
			this.nacionalidades = nacionalidadesList;
		},
		toggleSave() {
			this.isConyugeEdit = !this.isConyugeEdit;
		},
		isNumber: function (evt) {
			evt = evt ? evt : window.event
			var charCode = evt.which ? evt.which : evt.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
				evt.preventDefault()
			} else {
				return true
			}
		},
    }
}
</script>

<style lang="css" scoped>

</style>