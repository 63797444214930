<template lang="html">
      <div class="vista">
        <div class="row encabezadoPrecalificar ">
            <div class="col-md-4">
              <h6><b>Precalificar</b></h6>
              <vs-button color="primary" @click="openPrecalificacion()" type="border" >Realizar precalificación</vs-button>
            </div>
            <div class="col-md-4">
              <br>
              <h6><b style="text-transform:uppercase;">Diagnóstico de crédito</b></h6>
            </div>
            <div class="col-md-4">
              <h6><b>Resultado de precalificación</b></h6>
              <h5><b style="color: #079DEF; text-transform:uppercase;">{{this.ResultadoPrecalificacion}}</b></h5>
              <h6><b><span>Fecha:</span> <span>04/01/2023</span> </b></h6>
            </div>
        </div> 
        <div class="tablaPrecalificar">
        <table class="">
          <tr>
            <td><span class="tDia">Ingresos</span><br><b>{{this.Signos}}{{numberFormat(this.StatusIngresos)}}</b></td>
            <td><span class="tDia">Deuda mensual</span><br><b>{{this.Signos}}{{numberFormat(this.StatusDeudaMensual)}}</b></td>
            <td><span class="tDia">Capacidad de pago</span><br><b>{{this.Signos}}{{numberFormat(this.StatusCapacidadPago)}}</b></td>
            <td><span class="tDia">Monto solicitado</span><br><b style="color:#079DEF;">{{this.Signos}}{{numberFormat(this.StatusMontoSolicitado)}}</b></td>
            <td><span class="tDia">Monto máximo</span><br><b style="color:#079DEF;">{{this.Signos}}{{numberFormat(this.StatusMontoMaximo)}}</b></td>
          </tr>
          <tr style="border-top:1px solid ; padding-bottom:20px;">
            <td colspan="5">
              <span class="tDia">Detalles</span>
              <div class="col-md-12">
                <div class="detalles">{{this.CondicionesLeyendas}}<br>
                {{this.CondicionesLeyendas2}} <br>
               
                <div v-if="CondicionesLeyendas3==true">
                El solicitante no cumple con la Capacidad de Pago de ${{numberFormat(this.resultadoCondicionesPago)}} para alcanzar el Monto Solicitado de ${{numberFormat(this.StatusMontoSolicitado)}}
                </div>

                Línea de crédito máxima posible sujeta a aforo y condiciones del crédito

                
                </div>
              </div>            
            </td>
          </tr>
        </table>
        </div>
       
        <vx-card class="" v-if="mostrarBoton"> <!--Agregar sms v-if="mostrarBoton"-->
          <div class="alinear-reporte">
            <div class="header-reporte-crediticio">
              <h5 style="margin-bottom:20px;">Reporte crediticio</h5>
              <vs-alert color="danger" title="Completa tu Información" :active="!completeInfo">
                Ve al apartado de información personal y completa los datos generales para poder consultar el buró de crédito.
              </vs-alert>
            </div>
            <div class="content-buttons">
              <div class="content content-circulo">
                <button class="btn btn-second-line" :disabled="!IsPermitted" @click="openConfirm()" >Consultar circulo de crédito</button><!-- se quita validación de campos :disabled="!completeInfo"--> <!-- @click="openConfirm()" para sms-->
                <div class="ver-reporte"  v-if="this.statusPedidoBuro!= ''">
                    <a  @click="AbrirReporte()"  >Ver último reporte</a>
                </div>
              </div>
              <!-- <div class="content content-buro-credito">
                <button class="btn btn-second-line" :disabled="!IsPermitted" @click="openBuroCredito()" >Consultar buro de crédito</button>
                </div>
              -->
            </div>
          </div>
        </vx-card>

        <vx-card title="" class="" v-if="!mostrarBoton">
          <div class="confirmacion-phone text-center">
              <p>Escribe el código que recibiste en tu teléfono</p>
              <input id="uno" type="text" v-model="numUno" maxlength="1" minlength="1" class="mb-2" placeholder="" @keyup="cambiarInput()">
              <input id="dos" type="text" v-model="numDos" maxlength="1" minlength="1" class="mb-2" placeholder="" @keyup="cambiarInput()">
              <input id="tres" type="text" v-model="numTres" maxlength="1" minlength="1" class="mb-2" placeholder="" @keyup="cambiarInput()">
              <input id="cuatro" type="text" v-model="numCuatro" maxlength="1" minlength="1" class="mb-2" placeholder="">
              <a @click="generarCodigo()" class="d-block blue text-center mb-4">Reenviar código</a>
              <div class="d-flex justify-content-center justify-content-md-end">
                <a  class="mr-2 btn-back" @click="regresar()" >Regresar</a>
                <vs-button  @click="validaCodigo()" :disabled="!infoCompleta" color="primary">Confirmar número</vs-button>
                  <div class="alert alert-success alert-margin" @click="closeAlert('exito')" role="alert " :class="alertExito? '' : 'ocultarMensaje' ">
                    {{mensajeExito}}
                  </div>
                  <div class="alert alert-danger" @click="closeAlert('error')"  role="alert" :class="alertError? '' : 'ocultarMensaje' ">
                    {{mensajeError}}
                  </div>  
              </div>
              <!--<a  class="close"><img src="../../../assets/images/icon-close.svg" width="15px" alt="close"></a>  -->   
            </div> 
        </vx-card><!-- Agregar sms -->
      </div>
</template>

<script>
export default {
  data(){
		return{
      completeInfo:false,
      id:'',
      key:'',
      email:'',
      tarjeta:'',
      digitos:'',
      creditoHipotecario:'',
      creditoAuto:'',
      popupActive:false,
      creditos:[],
      score:'',
      activeConfirm: false,
      mensajeError:'',
      mensajeExito:'',
      alertExito:false,
      alertError:false,
      mostrarBoton:true,
      ladaPais:'',
      telefonoCelular:'',
      numUno:'',
      numDos:'',
      numTres:'',
      numCuatro:'',
      statusPedidoBuro:'',
      StatusIngresos:0,
      StatusDeudaMensual:0,
      StatusCapacidadPago:0,
      StatusMontoSolicitado:0,
      StatusMontoMaximo:0,
      PrecalificacionSoc:'',
      Signos:'',
      ResultadoPrecalificacion:'NO DISPONIBLE',
      PrecaDestino:'',
      ScorePreca:678,
      HistorialPreca:false,
      CondicionesLeyendas:'',
      CondicionesLeyendas2:'',
      CondicionesLeyendas3:'',
      resultadoCondicionesPago:0,

      IsPermitted: false,
      stdXmlCuentas: []
    }
  },
  computed:{
    infoCompleta(){
      return this.numUno.length > 0 && this.numDos.length > 0 && this.numTres.length > 0 && this.numCuatro.length > 0
    }
  },
  mounted(){
    this.id=this.$route.params.id

    this.getApikey()
    this.dameCaso()
    this.DameReporte()
    
    this.getPermitedConsulta()
  },
  methods:{
    closeAlert(alerta){
      switch (alerta) {
       case 'exito':
         this.alertExito=false
         break;
       case 'error':
         this.alertError=false
         break;
       default:
         break;
     }
    },
    numberFormat(number) {
      if ( isNaN(number) ) return

			return number
				.toFixed(2)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		},

    async getTokenSisec () {
      try {
        const objRequest = {
          username: 'user',
          password: 'user',
          grant_type: 'password'
        }

        const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/token'
        // const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/token'

        let formBody = [];
        for (const property in objRequest) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(objRequest[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        const data = await this.$axios.post(URL_API_SISEC, formBody,{headers : {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}})

        return data

      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })

        return false
      }
    },

    async getPermitedConsulta () {
    
      try {
        const consultasComparador = await this.getConsultasComparador()
        const { intEstatus, objContenido, strError } = consultasComparador
        const consultasSISEC = await this.getConsultasSISEC()
        const { Total, ReasonBlocked, IsPermitted } = consultasSISEC

        if ( intEstatus && Total === 0 ) {

          console.log('intEstatus :>> ', intEstatus);
        /*  this.$vs.notify({
            title: 'Ocurrio un error',
            text: 'El bróker no tiene la facultad de realizar consultas.',
            color:'danger',
            position:'top-right'
          }) */

          return false

        }

        console.log('IsPermitted :>> ', this.IsPermitted)
        this.IsPermitted = true

        console.log('IsPermitted :>> ', this.IsPermitted)
        
        
      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })

        return false
      }

    },

    async getConsultasSISEC () {
      const self = this

      try {
        
        const TOKEN_SISEC = await this.getTokenSisec()
        const { access_token } = TOKEN_SISEC.data

        const userInfoJson = localStorage.getItem('userInfo')
        const { SocGuid } = JSON.parse(userInfoJson)
  
        if ( !SocGuid ) {
          console.log('SocGuid :>> ', SocGuid);
          return { Total: 0, ReasonBlocked: 'El bróker no tiene la facultad de realizar consultas.', IsPermitted: false }

        }

        const objRequest = {
          EmployeeSOCId: SocGuid,
          PersonType: 'Fisico'
        }

        const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/CirculoCredito/Estado'
        // const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/CirculoCredito/Estado' // PROD

        const resp = await this.$axios.post(URL_API_SISEC, objRequest, { 
          headers : {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json; charset=UTF-8'
          }
        })

        const { status, data } = resp

        if ( status !== 200 ) {
          this.$vs.notify({
            title:'Ocurrio un error en Consultas disponibles',
            text:'Ocurrió un problema, inténtelo nuevamente más tarde.',
            color:'danger',
            position:'top-right'
          })

          return []
        }

        return data

      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      }
    },

    async getConsultasComparador () {

      try {
        const objRequestCount = {
          strApiKey: this.key,
          strMetodo: 'BrokerConsultasBuro',
          objParametros: {
            ApiKey: this.key
          }
        }

        const { data } = await this.$axios.post('/',objRequestCount,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        
        if ( data.intEstatus ) {
          return { intEstatus: data.intEstatus, objContenido: 0, strError: data.strError }
        }

        return { intEstatus: data.intEstatus, objContenido: data.objContenido, strError: null }

      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      }

    },

    consultarSolicitanteyGenerarSms(){
      //if(this.ladaPais!=null && this.ladaPais!=''){alert(this.ladaPais)
       this.dameSolicitante()
       setTimeout(() =>this.generarCodigo(),2000)
      /*}else{
        this.$vs.notify({
              title:'Error la lada del pais esta vacía',
              text:'Debes agregar la lada del país de tu número celular',
              color:'danger',
              position:'top-right'
            })
      }*/
    },
    regresar(){
      this.mostrarBoton=true
      this.completeInfo=true
    },
    cambiarInput(){
      //var focusedElement = document.activeElement;
      //console.log(focusedElement.id);
        if(this.numUno.length>0){
          document.getElementById('dos').focus()
        }
         if(this.numUno.length>0 && this.numDos.length>0){
          document.getElementById('tres').focus()
        }
        if(this.numUno.length>0 && this.numDos.length>0 && this.numTres.length >0){
          document.getElementById('cuatro').focus()
        }
    },
    validaCodigo(){
           let self=this
           this.codigoVerificacion=this.numUno+this.numDos+this.numTres+this.numCuatro
           var objGeneraCodigo={
             Codigo: this.codigoVerificacion,
             numero: this.ladaPais+this.telefonoCelular
           } 
        this.$axios.post('https://cotizador.socasesores.com/EnvioSms/ValidaSms.php',objGeneraCodigo,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
        .then(
            response => {
              //if(response.intRespuesta==0){
                 var respuestaHubspot = JSON.parse(response.data.objRespuesta)
                if(respuestaHubspot.Mensaje && respuestaHubspot.Mensaje=='Número telefónico validado con éxito'){
                  this.mensajeExito= respuestaHubspot.Mensaje
                  this.alertExito=true
                  setTimeout(() => this.alertExito = false, 5000);
                  this.mostrarBoton=true
                  this.completeInfo=true
                  this.numUno=''
                  this.numDos=''
                  this.numTres=''
                  this.numCuatro=''
                  this.consultar()
                  //this.$router.push('/contactar-asesor-folio').catch(() => {})
                }else if(respuestaHubspot.Mensaje && respuestaHubspot.Mensaje=='Código inválido o expiró'){
                  this.alertError=true
                  this.mensajeError=respuestaHubspot.Mensaje
                   setTimeout(() => this.alertError = false, 5000);
                } else if(respuestaHubspot.Message){
                  this.alertError=true
                  this.mensajeError=respuestaHubspot.Message
                   setTimeout(() => this.alertError = false, 5000);
                }else if(respuestaHubspot.Mensaje && respuestaHubspot.Mensaje == 'Error'){
                    this.alertError=true
                    this.mensajeError=respuestaHubspot.Mensaje
                    setTimeout(() => this.alertError = false, 5000);
               }else if(respuestaHubspot.Mensaje !== '' ){ 
                  this.alertError=true
                  this.mensajeError=respuestaHubspot.Mensaje
                   setTimeout(() => this.alertError = false, 5000);
              }
                
            }
        ).catch(function (error) {
            this.$vs.notify({
              title:'Ocurrio un errros en ValidaSms',
              text:error,
              color:'danger',
              position:'top-right'
            })
        })
    },
    generarCodigo(){
      this.mostrarBoton=false
         this.mensajeExito= 'En breve te llegará un código a tu teléfono, Este tiene una validez de 5 minutos.”'
        //this.alertExito=true
           //let self=this
           var objGeneraCodigo={
             metodo: 'Generar',
             numero:  this.ladaPais+this.telefonoCelular
           } 
        this.$axios.post('https://cotizador.socasesores.com/EnvioSms/GeneraSms.php',objGeneraCodigo,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
        .then(
            response => {
            var respuestaHubspot = JSON.parse(response.data.objRespuesta)
           // console.log(respuestaHubspot)
            if(respuestaHubspot.Mensaje && respuestaHubspot.Mensaje == 'Teléfono validado.'){
                  this.mensajeExito= respuestaHubspot.Mensaje
                  this.alertExito=true
                  setTimeout(() => this.alertExito = false, 5000);
                  this.mostrarBoton=true
                  this.completeInfo=true
                  this.numUno=''
                  this.numDos=''
                  this.numTres=''
                  this.numCuatro=''
                  this.consultar()
            }else if(respuestaHubspot.Message=='Authorization has been denied for this request.'){
                  this.alertError=true
                  this.mensajeError=respuestaHubspot.Mensaje
                   setTimeout(() => this.alertError = false, 5000);
            }else if (respuestaHubspot.Mensaje && respuestaHubspot.Mensaje == 'Mensaje SMS enviado.'){
                  this.alertExito=true
                  setTimeout(() => this.alertExito = false, 5000);
                  this.mostrarBoton=false
            }else if(respuestaHubspot.Mensaje !== '' ){ 
                   this.$vs.notify({
                    title:'Ocurrio un error en EnvioSms',
                    text:respuestaHubspot.Mensaje,
                    color:'danger',
                    position:'top-right'
                  })
                  this.alertError=true
                  this.mensajeError=respuestaHubspot.Mensaje
                   setTimeout(() => this.alertError = false, 5000);
            }
            }
        ).catch(function (error) {
           this.$vs.notify({
              title:'Ocurrio un errros en EnvioSms',
              text:error,
              color:'danger',
              position:'top-right'
            })
        })
    },
     AbrirReporte(){
      var WhatsPrint =  window.open('https://cotizador.socasesores.com/admin/formatos/FormatoConsultaCirculoCredito.php?s='+this.statusPedidoBuro)
    },

    openConfirm() {

      this.$router.push('/ReporteUser/'+this.id)
     // alert(this.id)

     /* this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: `Consultar Buró de Crédito`,
        text: '¿Estás seguro que deseas realizar la consulta?',
        accept: this.consultarSolicitanteyGenerarSms //agregar sms
      })*/
    },

    openBuroCredito () {
      this.$router.push('/ConsultaBuroCredito/'+this.id)
    },

    openPrecalificacion(){
       this.$router.push('/PrecaSoc/'+this.id)
    },
    actualizarInfo(){
      /*let self=this
      this.$vs.loading({
        container: '#main',
        scale: 0.6
      })*/
      let objRequestRegistraSolicitante = {
        strApiKey: this.key,
        strMetodo: 'RegistraSolicitante',
        objSolicitante: {
          EMail: this.email,
          DigitosTarjetaCredito:this.digitos,
          CreditoHipotecarioActual:this.creditoHipotecario,
          CreditoAutomotirzReciente:this.creditoAuto
        }
      }
      this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.consultar()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }

      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })

    },
    consultar(){
      this.$vs.loading.close('#main > .con-vs-loading')
      window.open('https://cotizador.socasesores.com/app/buro/'+this.email, '_blank');
    },
    dameCaso(){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.email= response.data.objContenido.Solicitante
            this.PrecalificacionSoc= response.data.objContenido.PrecalificacionSoc

            if(this.PrecalificacionSoc=='PrecalificacionSoc'){
              
              
              this.StatusMontoSolicitado= response.data.objContenido.MontoSolicitado
              this.Signos='$'
              this.PrecaDestino= response.data.objContenido.Destino
            }else{
              this.StatusMontoSolicitado= '--'
            }

            

             this.DameReporte()
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    DameReporte(){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'GetConsultaPedidoCredito',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {

          // return console.log('response :>> ', response)

          if(response.data.intEstatus == 0){
            this.statusPedidoBuro= response.data.objContenido.IdConsulta
            this.stdXmlCuentas = response.data.objContenido.circuloCredito.Cuenta
            //alert(this.statusPedidoBuro)
           
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },

    async getDataBuroSolicitante () {
      try {

        

      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error en getDataBuroSolicitante',
          text:response.data.strError,
          color:'danger',
          position:'top-right'
        })

        return false
      }
    },

    async dameSolicitante(){

      try {
        const objRequestDameSolicitante = {
          strFolioCaso: this.id,
          strApiKey: this.key,
          strMetodo: 'DameSolicitante',
          objSolicitante: {
          EMail: this.email
          }
        }

        const { data } = await this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        const { intEstatus, objContenido } = data

        if ( intEstatus ) {
          return this.$vs.notify({
            title:'Ocurrio un error en DameSolicitante',
            text:response.data.strError,
            color:'danger',
            position:'top-right'
          })
        }

        const solicitante = objContenido

        if(this.PrecalificacionSoc=='PrecalificacionSoc'){
          //alert(this.PrecalificacionSoc+solicitante.Ingresos[0].IngresosMensuales)
      
            this.StatusIngresos= solicitante.Ingresos[0].IngresosMensuales

            const arrCuentas = this.stdXmlCuentas.filter(cuenta => cuenta.SaldoActual > 0)
            const sumaMontoPagar = arrCuentas.reduce((suma, cuenta) => suma + parseFloat(cuenta.MontoPagar), 0)
            this.StatusDeudaMensual= sumaMontoPagar
            
            this.StatusCapacidadPago= solicitante.Ingresos[0].IngresosMensuales - this.StatusDeudaMensual

            
            this.StatusMontoMaximo=  this.StatusCapacidadPago * 40
            
            ////iNICIA VALIDACION DE ESTATUS DE PRECALIFICACION

            if(this.PrecaDestino==1){

              // alert(this.StatusMontoSolicitado+' '+this.StatusMontoMaximo)
            
              if(this.ScorePreca>= 551 && this.HistorialPreca==false && this.StatusCapacidadPago>=15000 && this.StatusMontoSolicitado>=500000 &&(this.StatusMontoSolicitado<=this.StatusMontoMaximo) ){
                  this.ResultadoPrecalificacion='APROBADO'
                  //this.CondicionesLeyendas='Línea de crédito máxima posible sujeta a aforo y condiciones del crédito'
              }else if(this.ScorePreca>= 551 && this.HistorialPreca==false && this.StatusCapacidadPago>=15000 && (this.StatusMontoSolicitado>=225000 && this.StatusMontoSolicitado<500000) &&(this.StatusMontoSolicitado<=this.StatusMontoMaximo) ){
                
                  this.ResultadoPrecalificacion='PROBABILIDAD DE RECHAZO'
                  if(this.StatusCapacidadPago < 15000){
                      this.CondicionesLeyendas='El solicitante no cumple con la Capacidad de Pago mínima de $15,000'
                  }

                  if(this.StatusMontoSolicitado>=225000 && this.StatusMontoSolicitado<500000){
                      this.CondicionesLeyendas2='El solicitante no cumple con el Monto de Crédito mínimo de $500,000'
                  }

                  if(this.StatusMontoSolicitado<=this.StatusMontoMaximo){
                      this.CondicionesLeyendas3=''
                  }else{
                      this.resultadoCondicionesPago= this.StatusMontoSolicitado/40
                      this.CondicionesLeyendas3= true //'El solicitante no cumple con la Capacidad de Pago de '+this.resultadoCondicionesPago+' para alcanzar el Monto Solicitado de '+this.StatusMontoSolicitado  
                  }

              }else{
              
                  this.ResultadoPrecalificacion='RECHAZADO'
                    if(this.StatusCapacidadPago < 15000){
                      this.CondicionesLeyendas='El solicitante no cumple con la Capacidad de Pago mínima de $15,000'
                  }

                  if(this.StatusMontoSolicitado < 225000){
                      this.CondicionesLeyendas2='El solicitante no cumple con el Monto de Crédito mínimo de $500,000'
                  }

                  if(this.StatusMontoSolicitado > this.StatusMontoMaximo){
                    this.resultadoCondicionesPago= this.StatusMontoSolicitado/40
                      this.CondicionesLeyendas3= true
                    
                      
                  }
              }
            }else if(this.PrecaDestino==19 || this.PrecaDestino==3 || this.PrecaDestino==5){
                if(this.ScorePreca>= 551 && this.HistorialPreca==false && this.StatusCapacidadPago>=15000 && this.StatusMontoSolicitado>=500000 &&(this.StatusMontoSolicitado<=this.StatusMontoMaximo) ){
                    this.ResultadoPrecalificacion='APROBADO'
                    // this.CondicionesLeyendas='Línea de crédito máxima posible sujeta a aforo y condiciones del crédito'
                }else if(this.ScorePreca>= 551 && this.HistorialPreca==false && this.StatusCapacidadPago>=15000 && (this.StatusMontoSolicitado>=225000 && this.StatusMontoSolicitado<500000) &&(this.StatusMontoSolicitado<=this.StatusMontoMaximo) ){
                    this.ResultadoPrecalificacion='PROBABILIDAD DE RECHAZO'

                  if(this.StatusCapacidadPago < 15000){
                      this.CondicionesLeyendas='El solicitante no cumple con la Capacidad de Pago mínima de $15,000'
                  }

                  if(this.StatusMontoSolicitado>=225000 && this.StatusMontoSolicitado<500000){
                      this.CondicionesLeyendas2='El solicitante no cumple con el Monto de Crédito mínimo de $500,000'
                  }

                  if(this.StatusMontoSolicitado<=this.StatusMontoMaximo){
                      this.CondicionesLeyendas3=''
                  }else{
                      this.resultadoCondicionesPago= this.StatusMontoSolicitado/40
                      this.CondicionesLeyendas3= true //'El solicitante no cumple con la Capacidad de Pago de '+this.resultadoCondicionesPago+' para alcanzar el Monto Solicitado de '+this.StatusMontoSolicitado  

                  }

                }else{
                    this.ResultadoPrecalificacion='RECHAZADO'

                      if(this.StatusCapacidadPago < 15000){
                      this.CondicionesLeyendas='El solicitante no cumple con la Capacidad de Pago mínima de $15,000'
                  }

                  if(this.StatusMontoSolicitado < 225000){
                      this.CondicionesLeyendas2='El solicitante no cumple con el Monto de Crédito mínimo de $500,000'
                  }

                  if(this.StatusMontoSolicitado > this.StatusMontoMaximo){
                      this.resultadoCondicionesPago= this.StatusMontoSolicitado/40
                      this.CondicionesLeyendas3= true //'El solicitante no cumple con la Capacidad de Pago de '+this.resultadoCondicionesPago+' para alcanzar el Monto Solicitado de '+this.StatusMontoSolicitado  

                  }

                }

            }else if(this.PrecaDestino==2 || this.PrecaDestino==8){
                if(this.ScorePreca>= 551 && this.HistorialPreca==false && this.StatusCapacidadPago>=15000 && this.StatusMontoSolicitado>=500000 &&(this.StatusMontoSolicitado<=this.StatusMontoMaximo) ){
                    this.ResultadoPrecalificacion='APROBADO'
                    //this.CondicionesLeyendas='Línea de crédito máxima posible sujeta a aforo y condiciones del crédito'
                }else if(this.ScorePreca>= 551 && this.HistorialPreca==false && this.StatusCapacidadPago>=15000 && (this.StatusMontoSolicitado>=225000 && this.StatusMontoSolicitado<500000) &&(this.StatusMontoSolicitado<=this.StatusMontoMaximo) ){
                    this.ResultadoPrecalificacion='PROBABILIDAD DE RECHAZO'

                  if(this.StatusCapacidadPago < 15000){
                      this.CondicionesLeyendas='El solicitante no cumple con la Capacidad de Pago mínima de $15,000'
                  }

                  if(this.StatusMontoSolicitado>=225000 && this.StatusMontoSolicitado<500000){
                      this.CondicionesLeyendas2='El solicitante no cumple con el Monto de Crédito mínimo de $500,000'
                  }

                  if(this.StatusMontoSolicitado<=this.StatusMontoMaximo){
                      this.CondicionesLeyendas3=''
                  }else{
                      this.resultadoCondicionesPago= this.StatusMontoSolicitado/40
                      this.CondicionesLeyendas3= true //'El solicitante no cumple con la Capacidad de Pago de '+this.resultadoCondicionesPago+' para alcanzar el Monto Solicitado de '+this.StatusMontoSolicitado  

                  }

                }else{
                    this.ResultadoPrecalificacion='RECHAZADO'

                      if(this.StatusCapacidadPago < 15000){
                      this.CondicionesLeyendas='El solicitante no cumple con la Capacidad de Pago mínima de $15,000'
                  }

                  if(this.StatusMontoSolicitado < 225000){
                      this.CondicionesLeyendas2='El solicitante no cumple con el Monto de Crédito mínimo de $500,000'
                        
                  }

                  if(this.StatusMontoSolicitado > this.StatusMontoMaximo){
                      this.resultadoCondicionesPago= this.StatusMontoSolicitado/40
                      this.CondicionesLeyendas3= true //'El solicitante no cumple con la Capacidad de Pago de '+this.resultadoCondicionesPago+' para alcanzar el Monto Solicitado de '+this.StatusMontoSolicitado  

                  }
                }

            }

        }else{

            //alert('error')
            this.StatusIngresos= '--'
            this.StatusCapacidadPago= '--'
            this.StatusMontoMaximo= '--'
        }

        //campos para sms
        this.telefonoCelular=solicitante.TelefonoCelular
        this.ladaPais=solicitante.Lada
        this.completeInfo = solicitante.Nombres!='' && solicitante.ApellidoPaterno!=''
        && solicitante.ApellidoMaterno!='' &&solicitante.Rfc!='' && solicitante.FechaNacimiento!=''
        && solicitante.Calle!='' && solicitante.NumeroExterior!='' &&
        solicitante.Colonia!='' && solicitante.Ciudad!='' && solicitante.CodigoPostal!=''
        && solicitante.Estado!='' && solicitante.TelefonoCelular!=''

      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      }
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    }
  }
}
</script>

<style lang="css" scoped>
.tablaPrecalificar{
  border:1px solid #006D4E;
  border-radius: 5px;
  padding: 5px;
  overflow-x: auto;
  display: block;
}
.encabezadoPrecalificar,
.tablaPrecalificar td {
    text-align: center;
    background: #fff;
    padding: 10px !important;
}
.tDia{
  color: #015694;
  font-weight: bold;
}
.detalles{
  border: 1px solid #079DEF;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  min-height: 70px;
}
.alinear-reporte{
  text-align: center;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.alinear-reporte .content-buttons {
  display: flex;
  justify-content: center;
  gap: 2em;
}

.alinear-reporte .content-buttons .content {
  padding: 1em;
}

.ver-reporte{
  text-decoration: underline;
  padding-top: 20px;
}
.ver-reporte a{
  text-decoration: underline;
  color: #4589ff;
  cursor: pointer;
}
.selectExample{
  width: 150%;}

.confirmacion-phone{
    background: #F1F2F7 0% 0% no-repeat padding-box;
    border: 1px solid #00000033;
    border-radius: 4px;
    padding: 4rem 1rem 2rem 1rem;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}
.confirmacion-phone input{
    max-width: 40px;
    margin: 0 auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E0E0E0;
}
.confirmacion-phone p{
    color: #212529 !important;
    font-weight: bold !important;
    visibility: visible; 
    opacity: 1;
    display: block;
    font-size: 14px !important;
    background-color: unset;
    text-align: center;
}
.confirmacion-phone a{
    color: #829494;
}
.confirmacion-phone a.blue{
    color: #2AB5DF;
}
.confirmacion-phone{
    position: relative;
}
.close{
    position: absolute;
    right: 10px;
    top: 5px;
}
.btn-back{
    background-color: transparent !important;
    color: #6c757d;
    font-size: 17px !important;
    width: 275px !important;
    height: 44px;
    border: none;

}
.ocultarMensaje{
  display: none !important;
}
.alert {
    max-width: 300px;
    bottom: 63px;
    right: 3.2rem;
    position: absolute;
    padding: 0.75rem 1.25rem;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
</style>
