export const actividadesFinancieras = [
  {Id:'1-100', Nombre: "EMPLEADOS"},
  {Id:'2-100', Nombre: "COMISIONISTAS SIN SUELDO FIJO"},
  {Id:'4-100', Nombre: "DUENO O ACCIONISTA DE S.A. o A.C."},
  {Id:'5-30', Nombre: "COMERCIANTE, CONSTRUCTOR, DUENO DE NEGOCIO(NO PERSONA MORAL)"},
  {Id:'6-10', Nombre: "AGROPECUARIO, GANADERO, GASOLINERO"},
  {Id:'7-2.5', Nombre: "INVERSIONISTA CASA DE BOLSA"},
  {Id:'8-2.5', Nombre: "INVERSIONISTA DEL BANCO"},
  {Id:'9-2.5', Nombre: "INVERSIONISTA OTROS BANCO"},
  {Id:'10-100', Nombre: "ABOGADOS NO LITIGANTES, DOCTORES, VETERINARIOS, DENTISTA"},
  {Id:'11-100', Nombre: "ARQUITECTOS E INGENIEROS (NO DEDICADOS A LA CONSTRUCCION)"},
  {Id:'12-75', Nombre: "CONTADORES, CONSULTORES, AGENTES ADUANALES, ASESORES"},
  {Id:'13-75', Nombre: "ARTISTAS, MUSICOS, VALUADORES, ARRENDADORES, PROFESORES"},
]
