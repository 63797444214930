<template lang="html">
  <div id="container">
    <div class="vx-row" v-if="email == ''">
      <div class="vx-col w-full md:w-3/3 sm:w-2/2 ">
        <vx-card class="text-center cursor-pointer">
          <img src="@/assets/images/pages/graphic-2.png" alt="graphic" width="180" class="mx-auto mb-4">
          <h4 class="mb-2">Añade un coacreditado</h4>
          <small>Regresa al apartado solicitante para agregar un coacreditado</small>
        </vx-card>
      </div>
    </div>
    <div class="vx-row" v-else>
      <div class="vx-col w-full">
        <vx-card title="Datos requeridos" class="">
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder" for="formasEnganche">Parentesco *</span>
              <select name="cars" id="formasEnganche" v-model="parentesco" class="vs-input">
                <option :value="''">seleccione...</option>
                <option :value='tipo.Id' v-for="tipo in parentescos"  :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Edad</span>
              <input class="vs-input" label="Edad *" v-model="edad" name="name" type="number"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Ocupación</span>
              <input class="vs-input" label="Ocupación *" v-model="ocupacion" name="name"/>
            </div>
          </div>
          <br>
          <button class="btn btn-second" @click="registraDependiente()" :disabled="!validateForm">Guardar Dependiente</button>
        </vx-card>
        <vx-card title="" class="">
          <vs-table :data="dependientes" max-items="5"
            pagination
            noDataText="No hay datos para mostrar">
            <template slot="thead">
              <vs-th>
                Parentesco
              </vs-th>
              <vs-th>
                Edad
              </vs-th>
              <vs-th>
                Ocupación
              </vs-th>
              <vs-th>
                Acciones
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].Tipo" >
                  {{data[indextr].Tipo}}
                </vs-td>

                <vs-td :data="data[indextr].Edad">
                  {{data[indextr].Edad}} años
                </vs-td>

                <vs-td :data="data[indextr].Ocupacion">
                  {{data[indextr].Ocupacion}}
                </vs-td>
                <vs-td>
                  <vs-row vs-w="12" >
                    <vx-tooltip text="Editar dependiente" position="top">
                      <button class="favoritos" @click="editar(data[indextr])">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <g id="vuesax_twotone_edit" data-name="vuesax/twotone/edit" transform="translate(-620 -252)">
                            <g id="edit">
                              <path id="Vector" d="M9,0H7C2,0,0,2,0,7v6c0,5,2,7,7,7h6c5,0,7-2,7-7V11" transform="translate(622 254)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.4"/>
                              <path id="Vector-2" data-name="Vector" d="M8.991,1.271l-7.88,7.88a2.712,2.712,0,0,0-.66,1.32l-.43,3.01a1.424,1.424,0,0,0,1.7,1.7l3.01-.43a2.8,2.8,0,0,0,1.32-.66l7.88-7.88c1.36-1.36,2-2.94,0-4.94S10.351-.089,8.991,1.271Z" transform="translate(627.049 253.749)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                              <path id="Vector-3" data-name="Vector" d="M0,0A7.144,7.144,0,0,0,4.94,4.94" transform="translate(634.91 256.15)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.4"/>
                              <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(620 252)" fill="none" opacity="0"/>
                            </g>
                          </g>
                        </svg>
                      </button>
                    </vx-tooltip>
                    <vx-tooltip text="Borrar dependiente" position="top">
                      <button class="favoritos" @click="eliminar(data[indextr].Id)">
                           <svg class="trash">
                                <g id="trash">
                                  <path id="Vector" d="M18,.5C14.67.17,11.32,0,7.98,0A59.068,59.068,0,0,0,2.04.3L0,.5" transform="translate(3 5.48)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  <path id="Vector-2" data-name="Vector" d="M0,2.97.22,1.66C.38.71.5,0,2.19,0H4.81C6.5,0,6.63.75,6.78,1.67L7,2.97" transform="translate(8.5 2)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                                  <path id="Vector-3" data-name="Vector" d="M13.7,0l-.65,10.07c-.11,1.57-.2,2.79-2.99,2.79H3.64C.85,12.86.76,11.64.65,10.07L0,0" transform="translate(5.15 9.14)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  <path id="Vector-4" data-name="Vector" d="M0,0H3.33" transform="translate(10.33 16.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                                  <path id="Vector-5" data-name="Vector" d="M0,0H5" transform="translate(9.5 12.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                                  <path id="Vector-6" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0"/>
                                </g>
                              </svg>
                      </button>
                    </vx-tooltip>
                  </vs-row>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
    <vs-popup fullscreen title="Editar Dependiente" :active.sync="popupActive">
      <vx-card title="Datos requeridos" class="">
        <div class="vx-row">
          <div class="col-md-4">
            <span class="span-placeholder" for="formasEnganche">Parentesco *</span>
            <select name="cars" id="formasEnganche" v-model="parentesco" class="vs-input">
              <option :value='tipo.Id' v-for="tipo in parentescos"  :key="">{{tipo.Nombre}}</option>
            </select>
          </div>
          <div class="col-md-4">
          <span class="span-placeholder">Edad*</span>
            <input class="vs-input" label="Edad *" v-model="edad" name="name" type="number"/>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Ocupación</span>
            <input class="vs-input" label="Ocupación *" v-model="ocupacion" name="name"/>
          </div>
        </div>
        <br>
        <vs-button color="primary" @click="editarDependiente()" :disabled="!validateForm">Editar dependiente</vs-button>
      </vx-card>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    'v-select': vSelect,
  },
  data(){
		return{
      popupActive: false,
      dependienteId:'',
      edad:'',
      ocupacion:'',
      parentesco:'',
      dependientes:[],
      parentescos:[],
      email:'',
      key:'',
      id:''
    }
  },
  watch: {
    popupActive:function(){
      if (this.popupActive == false) {
        this.limpiaForm()
      }
    },
  },
  computed:{
    validateForm(){
      return this.edad != '' && (this.ocupacion && this.ocupacion.trim() != '') && this.parentesco != ''
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    this.getListaParentescos()
    this.dameCaso()
  },
  methods:{
    editarDependiente(){
      let self= this
      var objRequestRegistraCuentasBancarias = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'EditarDependientesEconomicos',
        objSolicitante:{
            EMail:this.email,
            DependientesEconomicos:[
              {
                Id:this.dependienteId,
                Tipo:this.parentesco,
                Edad:this.edad,
                Ocupacion:this.ocupacion
              }
            ]
        }
      }
      this.$axios.post('/',objRequestRegistraCuentasBancarias,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos editados exitosamente`,
              position:'top-right'
            })
            this.popupActive=false
            this.limpiaForm()
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en EditarDependientesEconomicos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    editar(dependiente){
      this.edad=dependiente.Edad
      this.ocupacion=dependiente.Ocupacion
      this.parentesco=dependiente.TipoId
      this.dependienteId=dependiente.Id
      this.popupActive=true
    },
    eliminar(id){
      var eliminarCuentas = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'BorrarDependientesEconomicos',
        objSolicitante:{
            EMail:this.email,
            DependientesEconomicos:[
              {
                Id:id
              }
            ]
          }
      }
      this.$axios.post('/',eliminarCuentas,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Se eliminó exitosamente`,
              position:'top-right'
            })
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en BorrarDependiente',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraDependiente(){
      this.$vs.loading({
        container: '#container',
        scale: 0.6
      })
      let self= this
      var objRequestRegistraDependientes = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'RegistraDependientesEconomicos',
        objSolicitante:{
            EMail:this.email,
            DependientesEconomicos:[
              {
                Tipo:this.parentesco,
                Edad:this.edad,
                Ocupacion:this.ocupacion
              }
            ]
        }
      }
      this.$axios.post('/',objRequestRegistraDependientes,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.limpiaForm()
            this.dameSolicitante()
            this.$vs.loading.close('#container > .con-vs-loading')
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            if (response.data.objContenido.Coacreditado[0]) {
              this.email= response.data.objContenido.Coacreditado[0].EMail
            }
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.dependientes=response.data.objContenido.DependientesEconomicos
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaParentescos(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaParentescos',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.parentescos=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaParentescos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    limpiaForm(){
      this.edad=''
      this.ocupacion=''
      this.parentesco=''
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
  }
}
</script>

<style lang="css" scoped>
.v-select{
    width: 100%;
}
.label-select{
  margin-top: 3%;
}
</style>
