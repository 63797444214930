<template lang="html">
  <div id="container">
    <div class="">
      <div class="">
        <vx-card title="Datos requeridos Beneficiario" class="" v-if="this.porcentajeMax > 0">
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Nombre(s)</span>
              <input class="vs-input" label="Nombre(s)" v-model="nombre" name="username" />
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Apellido Paterno</span>
              <input class="vs-input" label="Apellido Paterno" v-model="apellidoPaterno" name="name" />
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Apellido Materno</span>
              <input class="vs-input" label="Apellido Materno" v-model="apellidoMaterno" name="name" />
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Edad</span>
              <input class="vs-input" label="Edad" v-model="edad" name="name" type="number" />
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Porcentaje del beneficiario</span>
              <input class="vs-input" label="Porcentaje del beneficiario" name="porcentaje" v-model="porcentaje" v-validate="'required|min_value:1|max_value:'+porcentajeMax"type="number"/>
              <span class="text-danger span-placeholder" v-show="errors.has('porcentaje')">Requerido y maximo {{ porcentajeMax }}% </span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder" for="formasEnganche">Parentesco</span>
              <select name="cars" id="formasEnganche" v-model="parentesco" class="vs-input">
                <option :value="''">Seleccione...</option>
                <option :value='tipo.Id' v-for="tipo in parentescos"  :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
          </div>
          <div class="row w-full">
          <div class="accordion w-full" id="accordionBEN">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneBEN">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneBEN" aria-expanded="true" aria-controls="collapseOneBEN">
                Adicionales requeridos para llenado de solicitudes Beneficiario
                </button>
              </h2>
              <div id="collapseOneBEN" class="accordion-collapse collapse" aria-labelledby="headingOneBEN" data-bs-parent="#accordionBEN">
                <div class="accordion-body">
                  <div class="row w-full">
                <div class="col-md-4">
                  <span class="span-placeholder">Domicilio</span>
                  <input class="vs-input" label="Domicilio" v-model="domicilio" name="username" />
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Teléfono (10 dígitos)</span>
                  <input class="vs-input" label="Teléfono (10 dígitos)" v-model="telefono" name="username" />
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Fecha de nacimiento</span>
                  <input class="vs-input" label="Fecha de nacimiento" type="date" v-model="fechaNacimiento" name="fechaNacimiento" />
                </div>
              </div>
                </div>
              </div>
            </div>
          </div> 
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <button class="btn btn-second" @click="validaBeneficiarios()" :disabled="!validateForm">Guardar Beneficiario</button>
            </div>  
          </div>           
        </vx-card>
        <vx-card title="" class="">
          <vs-table :data="beneficiarios" max-items="5"
            pagination
            noDataText="No hay datos para mostrar">
            <template slot="thead">
              <vs-th>
                Nombre(s)
              </vs-th>
              <vs-th>
                Apellido Paterno
              </vs-th>
              <vs-th>
                Apellido Materno
              </vs-th>
              <vs-th>
                Edad
              </vs-th>
      				<vs-th>
                Parentesco
              </vs-th>
      				<vs-th>
                Porcentaje
              </vs-th>
              <vs-th>
                Acciones
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].Nombres" >
                  {{data[indextr].Nombres}}
                </vs-td>

                <vs-td :data="data[indextr].ApellidoPaterno">
                  {{data[indextr].ApellidoPaterno}}
                </vs-td>

                <vs-td :data="data[indextr].ApellidoMaterno">
                  {{data[indextr].ApellidoMaterno}}
                </vs-td>

                <vs-td :data="data[indextr].Edad">
                  {{data[indextr].Edad}} años
                </vs-td>
                <vs-td :data="data[indextr].Parentesco">
                  {{data[indextr].Parentesco}}
                </vs-td>
                <vs-td :data="data[indextr].Porcentaje">
                  {{data[indextr].Porcentaje}}%
                </vs-td>
                <vs-td>
                  <div class="row w-full alinear" vs-w="12">
                    <vx-tooltip text="Editar beneficiario" position="top">
                      <button class="favoritos" @click="editar(indextr)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <g id="vuesax_twotone_edit" data-name="vuesax/twotone/edit" transform="translate(-620 -252)">
                            <g id="edit">
                              <path id="Vector" d="M9,0H7C2,0,0,2,0,7v6c0,5,2,7,7,7h6c5,0,7-2,7-7V11" transform="translate(622 254)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.4"/>
                              <path id="Vector-2" data-name="Vector" d="M8.991,1.271l-7.88,7.88a2.712,2.712,0,0,0-.66,1.32l-.43,3.01a1.424,1.424,0,0,0,1.7,1.7l3.01-.43a2.8,2.8,0,0,0,1.32-.66l7.88-7.88c1.36-1.36,2-2.94,0-4.94S10.351-.089,8.991,1.271Z" transform="translate(627.049 253.749)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                              <path id="Vector-3" data-name="Vector" d="M0,0A7.144,7.144,0,0,0,4.94,4.94" transform="translate(634.91 256.15)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.4"/>
                              <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(620 252)" fill="none" opacity="0"/>
                            </g>
                          </g>
                        </svg>
                      </button>
                    </vx-tooltip>
                    <vx-tooltip text="Borrar beneficiario" position="top">
                      <button class="favoritos" @click="eliminar(data[indextr].Id)">
                        <svg class="trash">
                          <g id="trash">
                            <path id="Vector" d="M18,.5C14.67.17,11.32,0,7.98,0A59.068,59.068,0,0,0,2.04.3L0,.5" transform="translate(3 5.48)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            <path id="Vector-2" data-name="Vector" d="M0,2.97.22,1.66C.38.71.5,0,2.19,0H4.81C6.5,0,6.63.75,6.78,1.67L7,2.97" transform="translate(8.5 2)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                            <path id="Vector-3" data-name="Vector" d="M13.7,0l-.65,10.07c-.11,1.57-.2,2.79-2.99,2.79H3.64C.85,12.86.76,11.64.65,10.07L0,0" transform="translate(5.15 9.14)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            <path id="Vector-4" data-name="Vector" d="M0,0H3.33" transform="translate(10.33 16.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                            <path id="Vector-5" data-name="Vector" d="M0,0H5" transform="translate(9.5 12.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                            <path id="Vector-6" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0"/>
                          </g>
                        </svg>
                      </button> 
                    </vx-tooltip>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
    <vs-popup fullscreen title="Editar Beneficiario" :active.sync="popupActive">
      <vx-card title="Datos requeridos" class="">
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder">Nombre(s)</span>
            <input class="vs-input" label="Nombre(s)" v-model="nombre" name="username" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Apellido Paterno</span>
            <input class="vs-input" label="Apellido Paterno" v-model="apellidoPaterno" name="name" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Apellido Materno</span>
            <input class="vs-input" label="Apellido Materno" v-model="apellidoMaterno" name="name" />
          </div>
        </div>
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder">Edad</span>
            <input class="vs-input" label="Edad" v-model="edad" name="name" type="number" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Porcentaje del beneficiario</span>
            <input class="vs-input" label="Porcentaje del beneficiario" name="porcentaje" v-model="porcentaje" v-validate="'required|min_value:1|max_value:'+porcentajeMax"type="number"/>
            <span class="text-danger span-placeholder" v-show="errors.has('porcentaje')" v-if="!editarMode">Requerido y maximo {{ porcentajeMax }}% </span>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder" for="formasEnganche">Parentesco</span>
            <select name="cars" id="formasEnganche" v-model="parentesco" class="vs-input">
              <option :value="''">Seleccione...</option>
              <option :value='tipo.Id' v-for="tipo in parentescos"  :key="">{{tipo.Nombre}}</option>
            </select>
          </div>
        </div>
        <br>
        <h4>Adicionales Beneficiario </h4>
        <div class="row w-full" vs-w="12" vs-justify="space-between">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="12" vs-xs="12">
            <span class="span-placeholder">Domicilio</span>
            <input class="vs-input" label="Domicilio" v-model="domicilio" name="username" />
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12">
            <span class="span-placeholder">Teléfono (10 dígitos)</span>
            <input class="vs-input" label="Teléfono (10 dígitos)" v-model="telefono" name="username" />
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12">
            <div class="vx-col w-full">
              <span class="span-placeholder">Fecha de nacimiento</span>
              <input class="vs-input" label="Fecha de nacimiento" type="date" v-model="fechaNacimiento" name="fechaNacimiento" />
            </div>
          </vs-col>
        </div>
        <br>
        <vs-button color="primary" @click="validaBeneficiariosEdicion()" :disabled="!validateForm">Editar Beneficiarios</vs-button>
      </vx-card>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    'v-select': vSelect,
  },
  data(){
		return{
      nombre:'',
      apellidoPaterno:'',
      apellidoMaterno:'',
      domicilio:'',
      fechaNacimiento:'',
      porcentaje:100,
      edad:'',
      telefono:'',
      parentesco:'',
      beneficiarios:[],
      parentescos:[],
      email:'',
      key:'',
      id:'',
      popupActive: false,
      beneficiario1Id:'',
      beneficiario2Id:'',
      beneficiario3Id:'',
      porcentajeMax:100,
      editarMode:false
    }
  },
  computed:{
    validateForm(){
      return this.porcentaje != '' && this.nombre != '' && this.apellidoPaterno != '' && this.apellidoMaterno != '' && this.edad != '' && this.parentesco != ''
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    this.getListaParentescos()
    this.dameCaso()
  },
  watch: {
		// Formato mayusculas sin acento
		nombre: function(newValue, oldValue) {
			this.nombre = this.textFormat(newValue);
		},
		apellidoPaterno: function(newValue, oldValue) {
			this.apellidoPaterno = this.textFormat(newValue);
		},
		apellidoMaterno: function(newValue, oldValue) {
			this.apellidoMaterno = this.textFormat(newValue);
		},
    // porcentaje: function(newValue) {
    //   this.porcentaje=parseInt(this.porcentaje)
    //   if (this.porcentaje > this.porcentajeMax) {
    //     this.porcentaje = this.porcentajeMax
    //   }
    // },
    edad: function(newValue) {
      if (this.edad >100) {
        this.edad = 100
      }
    },
    popupActive:function(){
      if (this.popupActive == false) {
        this.limpiaForm()
      }
    },
  },
  methods:{
		// Conversion
		textFormat(strValue) {
			return strValue.normalize('NFD')
                    .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
                    .normalize()
                    .toUpperCase(); // Eliminar acentos menos Ññ
		},
    validaBeneficiariosEdicion(){
      let beneficiarios= []
      beneficiarios=[{
        Id:this.beneficiario1Id,
        Nombres:this.nombre,
        ApellidoPaterno:this.apellidoPaterno,
        ApellidoMaterno:this.apellidoMaterno,
        Edad:this.edad,
        Parentesco:this.parentesco,
        Porcentaje:this.porcentaje,
        Telefono:this.telefono,
        Domicilio : this.domicilio,
        FechaNacimiento : this.fechaNacimiento
      }]
      this.editarBeneficiarios(beneficiarios)
    },
    editarBeneficiarios(beneficiarios){
      let self= this
      var objRequestRegistraCuentasBancarias = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'EditarBeneficiarios',
        objSolicitante:{
            EMail:this.email,
            Beneficiarios: beneficiarios
        }
      }
      this.$axios.post('/',objRequestRegistraCuentasBancarias,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos editados exitosamente`,
              position:'top-right'
            })
            this.popupActive=false
            this.dameSolicitante()
            this.limpiaForm()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en EditarBeneficiarios',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    editar(id){
      this.editarMode=true
      this.nombre=this.beneficiarios[id].Nombres
      this.apellidoPaterno=this.beneficiarios[id].ApellidoPaterno
      this.apellidoMaterno=this.beneficiarios[id].ApellidoMaterno
      this.domicilio=this.beneficiarios[id].Domicilio
      this.fechaNacimiento= this.beneficiarios[id].FechaNacimiento
      this.porcentaje=this.beneficiarios[id].Porcentaje
      this.edad=this.beneficiarios[id].Edad
      this.telefono=this.beneficiarios[id].Telefono
      this.parentesco=this.beneficiarios[id].ParentescoId
      this.beneficiario1Id=this.beneficiarios[id].Id
      this.popupActive=true
    },
    eliminar(id){
      var eliminarCuentas = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'BorrarBeneficiarios',
        objSolicitante:{
            EMail:this.email,
            Beneficiarios:[
              {
                Id:id
              }
            ]
          }
      }
      this.$axios.post('/',eliminarCuentas,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Se eliminó exitosamente`,
              position:'top-right'
            })
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en BorrarBeneficiarios',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    validaBeneficiarios(){
      this.$vs.loading({
        container: '#container',
        scale: 0.6
      })
      let beneficiarios= []
      beneficiarios=[{
        Nombres:this.nombre,
        ApellidoPaterno:this.apellidoPaterno,
        ApellidoMaterno:this.apellidoMaterno,
        Edad:this.edad,
        Parentesco:this.parentesco,
        Porcentaje:this.porcentaje,
        Telefono:this.telefono,
        Domicilio : this.domicilio,
        FechaNacimiento : this.fechaNacimiento
      }]
      this.registraBeneficiario(beneficiarios)
    },
    registraBeneficiario(beneficiarios){
      let self= this
      var objRequestRegistraBeneficiarios = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'RegistraBeneficiarios',
        objSolicitante:{
            EMail:this.email,
            Beneficiarios: beneficiarios
        }
      }
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$axios.post('/',objRequestRegistraBeneficiarios,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
          .then(
            response => {
              if(response.data.intEstatus == 0){
                this.$vs.notify({
                  title:`Datos guardados exitosamente`,
                  position:'top-right'
                })
                this.limpiaForm()
                this.dameSolicitante()
                this.$vs.loading.close('#container > .con-vs-loading')
              }
            }
          ).catch(function (error) {
            self.$vs.notify({
              title:'Ocurrio un error de sistema',
              text:error,
              color:'danger',
              position:'top-right'
            })
          })
        } else {
          this.$vs.loading.close('#container > .con-vs-loading')
          this.$vs.notify({
            title:'Ocurrio un error',
            text:'Datos incompletos',
            color:'danger',
            position:'top-right'
          })
        }
      })
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.email= response.data.objContenido.Solicitante
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.porcentajeMax=100
            this.beneficiarios=response.data.objContenido.Beneficiarios
            if (this.beneficiarios) {
              for (var i = 0; i < this.beneficiarios.length; i++) {
                this.porcentajeMax= this.porcentajeMax -this.beneficiarios[i].Porcentaje
              }
              this.porcentaje=this.porcentajeMax
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaParentescos(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaParentescos',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.parentescos=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaParentescos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    limpiaForm(){
      this.nombre=''
      this.apellidoPaterno=''
      this.apellidoMaterno=''
      this.domicilio=''
      this.fechaNacimiento=''
      this.porcentaje=this.porcentajeMax
      this.edad=''
      this.telefono=''
      this.parentesco=''
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
  }
}
</script>

<style lang="css" scoped>
.v-select{
    width: 100%;
}
.label-select{
  margin-top: 3%;
}
</style>
