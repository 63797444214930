export const identificaciones = [
  {Id: '1', Nombre: "CREDENCIAL DE ELECTOR"},
  {Id: '10', Nombre: "ACTA DE NACIMIENTO"},
  {Id: '11', Nombre: "MATRICULA CONSULAR"},
  {Id: '2', Nombre: "CARTILLA"},
  {Id: '31', Nombre: "RFC"},
  {Id: '4', Nombre: "PASAPORTE"},
  {Id: '51', Nombre: "CURP"},
  {Id: '6', Nombre: "CEDULA PROFESIONAL"},
  {Id: '7', Nombre: "FORMA MIGRATORIA 1"},
  {Id: '8', Nombre: "FORMA MIGRATORIA 2"},
  {Id: '9', Nombre: "TARJETA UNICA DE IDENTIFICACION MILITAR"},
]
