<template lang="html">
  <div class="">
    <div class="vx-row" v-if="email == ''">
      <div class="vx-col w-full md:w-3/3 sm:w-2/2 ">
        <vx-card class="text-center cursor-pointer">
          <img src="@/assets/images/pages/graphic-2.png" alt="graphic" width="180" class="mx-auto mb-4">
          <h4 class="mb-2">Añade un coacreditado</h4>
          <small>Regresa al apartado solicitante para agregar un coacreditado</small>
        </vx-card>
      </div>
    </div>
    <div class="vx-row" v-else>
      <div class="vx-col w-full">
        <vx-card title="Datos del asegurado" class="">
          <div class="row w-full">
            <div class="col-md-4">
            <span class="span-placeholder">Peso en kilogramos</span>
              <input class="vs-input" label="Peso en kilogramos" v-model="peso" name="name" type="number"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Estatura en centímetros</span>
              <input class="vs-input" label="Estatura en centímetros" v-model="estatura" name="name"/>
            </div>
          </div>
          <br>
          <div class="row w-full">
            <div class="col-md-6" v-for="(padecimiento, index) in padecimientosOpciones" :key="index" vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-sm="12" vs-xs="12">
              <vs-checkbox v-model="padecimientos" :vs-value="padecimiento.Id" @onclick="vete()">{{ padecimiento.Nombre }}</vs-checkbox>
            </div>
          </div>
          <br>
          <button class="btn btn-second" @click="registraAseguradora()">Guardar Selección</button>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
		return{
      key:'',
      id:'',
      peso:'',
      estatura:'',
      padecimientos:[],
      padecimientosOpciones: [],
      email:'',
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    this.listaEnfermedades()
    this.dameCaso()
  },
  methods:{
    registraAseguradora(){
      let self= this
      let enfermedades=[]
      for (var i = 0; i < this.padecimientos.length; i++) {
        enfermedades.push({"Concepto":this.padecimientos[i]})
      }
      var objRequestRegistraCuentasBancarias = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'RegistraAseguradora',
        objSolicitante:{
            EMail:this.email,
            Peso:this.peso,
            Estatura:this.estatura,
            DatosAseguradora:enfermedades
        }
      }
      this.$axios.post('/',objRequestRegistraCuentasBancarias,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraAseguradora',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    listaEnfermedades(){
      let self= this
      var objRequestListaEnfermedades = {
        strApiKey: this.key,
        strMetodo: 'listaConceptosAseguradora',
      }
      this.$axios.post('/',objRequestListaEnfermedades,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.padecimientosOpciones=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en listaConceptosAseguradora',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            if (response.data.objContenido.Coacreditado[0]) {
              this.email= response.data.objContenido.Coacreditado[0].EMail
            }
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.peso=response.data.objContenido.Peso
            this.estatura=response.data.objContenido.Estatura
            for (var i = 0; i < response.data.objContenido.DatosAseguradora.length; i++) {
              this.padecimientos.push(response.data.objContenido.DatosAseguradora[i].Id)
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    }
  }
}
</script>
