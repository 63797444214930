<template lang="html">
  <div id="container">
    <div class="row w-full" v-if="email == ''">
      <div class="vx-col w-full md:w-3/3 sm:w-2/2 ">
        <vx-card class="text-center cursor-pointer">
          <img src="@/assets/images/pages/graphic-2.png" alt="graphic" width="180" class="mx-auto mb-4">
          <h4 class="mb-2">Añade un coacreditado</h4>
          <small>Regresa al apartado solicitante para agregar un coacreditado</small>
        </vx-card>
      </div>
    </div>
    <div class="row w-full" v-else>
      <div class="vx-col w-full">
        <vx-card title="Datos requeridos" class="">
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Institución acreedora</span>
              <input class="vs-input" label="Institución acreedora *" v-model="adeudo.institucion" name="username" />
            </div>
            <div class="col-md-4">
              <span class="span-placeholder" for="formasEnganche">Tipo de adeudo *</span>
              <select name="cars" id="formasEnganche" v-model="adeudo.tipoAdeudo" class="vs-input">
                <option :value='tipo.Id' v-for="tipo in tiposDeAdeudo"  :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Pago mensual</span>
              <input class="vs-input" label="Pago mensual *" v-model="adeudo.pagoMensual" name="name" v-on:keypress="isNumber($event)"/>
            </div>
          </div>
          <br>
          <vs-collapse>
            <vs-collapse-item>
              <div slot="header">
                <h4>Adicionales requeridos para llenado de solicitudes requeridos
                  <feather-icon icon="PlusCircleIcon"/>
                </h4>
              </div>
              <div class="row w-full">
                <div class="col-md-4">
                  <span class="span-placeholder">Número de cuenta</span>
                  <input class="vs-input" label="Número de cuenta" v-model="adeudo.numeroCuenta" name="username" />
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Fecha de inicio</span>
                  <input class="vs-input" label="Fecha de inicio" type="date" v-model="adeudo.fechaApertura" name="fechaNacimiento" />
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Límite de crédito</span>
                  <input class="vs-input" label="Límite de crédito" v-model="adeudo.limiteCredito" name="name" v-on:keypress="isNumber($event)"/>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Saldo actual</span>
                  <input class="vs-input" label="Saldo actual" v-model="adeudo.saldoActual" name="name" v-on:keypress="isNumber($event)"/>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Número de sucursal</span>
                  <input class="vs-input" label="Número de sucursal" v-model="adeudo.sucursalPlaza" name="name" />
                </div>
              </div>
            </vs-collapse-item>
          </vs-collapse>
          <br>
          <button class="btn btn-second" @click="registraAdeudo()" :disabled="!validateForm">Guardar adeudo</button>
        </vx-card>
        <vx-card title="" class="">
          <vs-table :data="adeudos" max-items="5"
            pagination
            noDataText="No hay datos para mostrar">
            <template slot="thead">
              <vs-th>
                Institución acreedora
              </vs-th>
              <vs-th>
                Tipo de adeudo
              </vs-th>
              <vs-th>
                Pago Mensual
              </vs-th>
              <vs-th>
                Acciones
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].Institucion" >
                  {{data[indextr].Institucion}}
                </vs-td>
                <vs-td :data="data[indextr].Tipo">
                  {{data[indextr].Tipo}}
                </vs-td>
                <vs-td :data="data[indextr].PagoMensual">
                  {{data[indextr].PagoMensual}}
                </vs-td>
                <vs-td>
                  <vs-row vs-w="12" vs-justify="space-between">
                    <vx-tooltip text="Editar cuenta" position="top">
                      <vs-button color="primary" @click="editar(data[indextr])"><feather-icon icon="EditIcon" svgClasses="w-4 h-4"/></vs-button>
                    </vx-tooltip>
                    <vx-tooltip text="Borrar cuenta" position="top">
                      <vs-button color="primary" @click="eliminar(data[indextr].Id)"><feather-icon icon="TrashIcon" svgClasses="w-4 h-4"/></vs-button>
                    </vx-tooltip>
                  </vs-row>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
    <vs-popup fullscreen title="Editar Adeudo" :active.sync="popupActive">
      <vx-card title="Datos requeridos" class="">
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder">Institución acreedora</span>
            <input class="vs-input" label="Institución acreedora *" v-model="adeudo.institucion" name="username" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder" for="formasEnganche">Tipo de adeudo *</span>
            <select name="cars" id="formasEnganche" v-model="adeudo.tipoAdeudo" class="vs-input">
              <option :value='tipo.Id' v-for="tipo in tiposDeAdeudo"  :key="">{{tipo.Nombre}}</option>
            </select>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Pago mensual*</span>
            <input class="vs-input" label="Pago mensual *" v-model="adeudo.pagoMensual" name="name" v-on:keypress="isNumber($event)"/>
          </div>
        </div>
        <br>
        <h4>Adicionales</h4>
        <br>
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder">Número de cuenta</span>
            <input class="vs-input" label="Número de cuenta" v-model="adeudo.numeroCuenta" name="username" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Fecha de inicio</span>
            <input class="vs-input" label="Fecha de inicio" type="date" v-model="adeudo.fechaApertura" name="fechaNacimiento" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Límite de crédito</span>
            <input class="vs-input" label="Límite de crédito" v-model="adeudo.limiteCredito" name="name" v-on:keypress="isNumber($event)"/>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Saldo actual</span>
            <input class="vs-input" label="Saldo actual" v-model="adeudo.saldoActual" name="name" v-on:keypress="isNumber($event)"/>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Número de sucursal</span>
            <input class="vs-input" label="Número de sucursal" v-model="adeudo.sucursalPlaza" name="name" />
          </div>
        </div>
        <br>
        <vs-button color="primary" @click="editarAdeudos()" :disabled="!validateForm">Editar cuenta</vs-button>
      </vx-card>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    'v-select': vSelect,
  },
  data(){
		return{
      popupActive: false,
      adeudo:{
        institucion:'',
        tipoAdeudo:'',
        pagoMensual:'',
        fechaApertura:'',
        saldoActual:'',
        numeroCuenta:'',
        limiteCredito:'',
        sucursalPlaza:'',
        adeudoId:'',
      },
      tiposDeAdeudo:[],
      adeudos:[],
      id:'',
      email:''
    }
  },
  watch: {
    pagoMensual: function(newValue) {
      this.adeudo.pagoMensual= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    saldoActual: function(newValue) {
      this.adeudo.saldoActual= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    limiteCredito: function(newValue) {
      this.adeudo.limiteCredito= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    popupActive:function(){
      if (this.popupActive == false) {
        this.limpiaForm()
      }
    },
  },
  computed:{
    pagoMensual(){
      return this.adeudo.pagoMensual
    },
    saldoActual(){
      return this.adeudo.saldoActual
    },
    limiteCredito(){
      return this.adeudo.limiteCredito
    },
    validateForm(){
      return this.adeudo.institucion != '' && this.adeudo.tipoAdeudo != '' && this.adeudo.pagoMensual != ''
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    this.getTipoAdeudos()
    this.dameCaso()
  },
  methods:{
    editarAdeudos(){
      if (this.adeudo.limiteCredito== null) {
        this.adeudo.limiteCredito=''
      }
      if (this.adeudo.saldoActual== null) {
        this.adeudo.saldoActual=''
      }
      let self= this
      var objRequestRegistraCuentasBancarias = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'EditarAdeudos',
        objSolicitante:{
            EMail:this.email,
            Adeudos:[
              {
                Id:this.adeudo.adeudoId,
                PagoMensual:parseFloat(this.adeudo.pagoMensual.replace(/,/g,"")),
                Institucion:this.adeudo.institucion,
                Tipo:this.adeudo.tipoAdeudo,
                Cuenta :this.adeudo.numeroCuenta,
                LimiteCredito : parseFloat(this.adeudo.limiteCredito.replace(/,/g,"")),
                SaldoActual : parseFloat(this.adeudo.saldoActual.replace(/,/g,"")),
                SucursalPlaza : this.adeudo.sucursalPlaza,
                FechaApertura : this.adeudo.fechaApertura
              }
            ]
        }
      }
      this.$axios.post('/',objRequestRegistraCuentasBancarias,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos editados exitosamente`,
              position:'top-right'
            })
            this.popupActive=false
            this.limpiaForm()
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en EditarAdeudos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    editar(adeudo){
      this.adeudo.institucion=adeudo.Institucion
      this.adeudo.tipoAdeudo=adeudo.TipoId
      this.adeudo.pagoMensual=adeudo.PagoMensual
      this.adeudo.fechaApertura= adeudo.FechaApertura
      this.adeudo.saldoActual=adeudo.SaldoActual
      this.adeudo.numeroCuenta=adeudo.Cuenta
      this.adeudo.limiteCredito=adeudo.LimiteCredito
      this.adeudo.sucursalPlaza=adeudo.SucursalPlaza
      this.adeudo.adeudoId=adeudo.Id
      this.popupActive=true
    },
    eliminar(id){
      var eliminarCuentas = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'BorrarAdeudos',
        objSolicitante:{
            EMail:this.email,
            Adeudos:[
              {
                Id:id
              }
            ]
          }
      }
      this.$axios.post('/',eliminarCuentas,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Se eliminó exitosamente`,
              position:'top-right'
            })
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en BorrarAdeudos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraAdeudo(){
      this.$vs.loading({
        container: '#container',
        scale: 0.6
      })
      let self= this
      var objRequestListaCasos = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'RegistraAdeudos',
        objSolicitante:{
            EMail:this.email,
            Adeudos:[
              {
                PagoMensual:parseFloat(this.adeudo.pagoMensual.replace(/,/g,"")),
                Institucion:this.adeudo.institucion,
                Tipo:this.adeudo.tipoAdeudo,
                Cuenta :this.adeudo.numeroCuenta,
                LimiteCredito : parseFloat(this.adeudo.limiteCredito.replace(/,/g,"")),
                SaldoActual : parseFloat(this.adeudo.saldoActual.replace(/,/g,"")),
                SucursalPlaza : this.adeudo.sucursalPlaza,
                FechaApertura : this.adeudo.fechaApertura
              }
            ]
        }
      }
      this.$axios.post('/',objRequestListaCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.limpiaForm()
            this.dameSolicitante()
            this.$vs.loading.close('#container > .con-vs-loading')
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraAdeudos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            if (response.data.objContenido.Coacreditado[0]) {
              this.email= response.data.objContenido.Coacreditado[0].EMail
            }
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.adeudos=response.data.objContenido.Adeudos
            for (var i = 0; i < this.adeudos.length; i++) {
              this.adeudos[i].PagoMensual=(this.adeudos[i].PagoMensual).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              if (this.adeudos[i].LimiteCredito) {
                this.adeudos[i].LimiteCredito=(this.adeudos[i].LimiteCredito).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              if (this.adeudos[i].SaldoActual) {
                this.adeudos[i].SaldoActual=(this.adeudos[i].SaldoActual).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getTipoAdeudos(){
      let self= this
      var objRequestListaTipoAdeudos = {
        strApiKey: this.key,
        strMetodo: 'ListaTipoAdeudos',
      }
      this.$axios.post('/',objRequestListaTipoAdeudos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.tiposDeAdeudo=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoAdeudos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    limpiaForm(){
      this.adeudo.institucion=''
      this.adeudo.tipoAdeudo=''
      this.adeudo.pagoMensual=''
      this.adeudo.fechaApertura=''
      this.adeudo.saldoActual=''
      this.adeudo.numeroCuenta=''
      this.adeudo.limiteCredito=''
      this.adeudo.sucursalPlaza=''
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  }
}
</script>

<style lang="css" scoped>
.v-select{
    width: 100%;
}
.label-select{
  margin-top: 3%;
}
</style>
