<template lang="html">
	<div class="" id="solGenPer">
		<!-- <vx-card :title="'Solicitante: ' + email" class=""> -->
		<vx-card class="">

			<div class="flex justify-end items-center mb-10">
				<!-- <h4>Solicitante: {{ email }}</h4> -->
				<button 
					class="btn btn-second" 
					@click="toggleSave()" 
					v-bind:hidden="!isPersonalEdit">Editar</button>
				<button 
					v-bind:hidden="isPersonalEdit" 
					class="btn btn-second" @click="agregaConyuge(); toggleSave() ;" 
					:disabled="!validate">Guardar información</button>
			</div>


			<!-- Nueva organizacion 2024-01-24 -->
			<div class="text-center" v-if="!state_data">
				<span class="red">Recuerda actualizar la fecha de nacimiento e información de ingresos del solicitante</span>
			</div>

			<div class="row w-full -mb-4"> <!-- # Seccion Solicitud -->
				<div class="col-md-12">
					<h5 class="font-bold">Solicitud</h5> 
				</div>
			</div>

			<div class="row w-full">
				<div class="col-md-3">
					<span class="span-placeholder">Fecha de la solicitud</span>
					<input
						v-model="adicionales.fechaSolicitud" 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						type="date"
						name="fechaSolicitud" />
				</div>

				<div class="col-md-3">
					<span class="span-placeholder">Lugar de la solicitud</span>
					<input 
						v-model="adicionales.lugarSolicitud" 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						type="text"
						name="lugarSolicitud" />
				</div>

				<div class="col-md-3">
					<span class="span-placeholder" for="usosInmuebles">Uso de inmueble</span>
					<select 
						v-model="adicionales.usoInmueble" 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						type="text"
						name="usosInmuebles">
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in usosInmuebles" :key="tipo.Id">{{ tipo.Nombre }}</option>
					</select>
				</div>

				<div class="col-md-3">
					<span class="span-placeholder">Tipo de inmueble</span>
					<select 
						v-model="adicionales.tipoInmueble" 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						type="text"
						name="tiposInmuebles">
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in tiposInmuebles" :key="tipo.Id">{{ tipo.Nombre }}</option>
					</select>
				</div>
			</div>

			<div class="row w-full -mb-4"> <!-- # Datos personales -->
				<div class="col-md-12">
					<h5 class="font-bold">Datos personales</h5> 
				</div>
			</div>

			<div class="row w-full -mb-4">
				<div class="col-md-4">
					<span class="span-placeholder">Correo cliente *</span>
					<input 
						class="vs-input bg-toggle-disable" 
						v-model="email" 
						disabled /> 
				</div>

				<div class="col-md-4">
					<span class="span-placeholder">CURP *</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-if="!AccreditedidV3" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="curp" 
						name="curp" v-validate="'min:18|max:18'" v-mask="'AAAA######AAAAAANN'" value="" onkeyup="javascript:this.value=this.value.toUpperCase();" />
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-if="AccreditedidV3" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="curp" 
						name="curp" 
						v-validate="'min:18|max:18'" v-mask="'AAAA######AAAAAANN'" value="" onkeyup="javascript:this.value=this.value.toUpperCase();" disabled />
					<span class="text-danger span-placeholder" v-show="errors.has('curp')">El campo debe tener 18 caracteres</span>
				</div>

				<div class="col-md-4">
					<span class="span-placeholder">RFC *</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="rfc" 
						name="rfc" 
						v-validate="'min:13|max:13'" 
						v-mask="'AAAA######NNN'" onkeyup="javascript:this.value=this.value.toUpperCase();" />
					<span class="text-danger span-placeholder" v-show="errors.has('rfc')">El campo debe tener 13 caracteres</span>
				</div>
			</div>
			
			<!-- <div class="text-center" v-if="!state_data">
				<span class="red">Recuerda actualizar la fecha de nacimiento e información de ingresos del solicitante</span>
			</div> -->
			<div class="row w-full">
				<div class="col-md-3">
					<span class="span-placeholder">Nombre</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-validate="'required|alpha_spaces'" 
						v-model="nombre" v-on:keypress="soloLetras($event)" name="nombres" />
					<span class="text-danger span-placeholder" v-show="errors.has('nombres')">Campo requerido</span>
				</div>
				<div class="col-md-3">
					<span class="span-placeholder">Segundo nombre</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						maxlength="20" 
						v-model="segNombre" v-on:keypress="soloLetras($event)" name="segNombres" />
					<span class="text-danger span-placeholder" v-show="errors.has('segNombres')">Campo requerido</span>
				</div>
				<div class="col-md-3">
					<span class="span-placeholder">Apellido Paterno</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-validate="'required|alpha_spaces'" v-model="apellidoPaterno" v-on:keypress="soloLetras($event)" name="apellidoPaterno" />
					<span class="text-danger span-placeholder" v-show="errors.has('apellidoPaterno')">Campo requerido</span>
				</div>
				<div class="col-md-3">
					<span class="span-placeholder">Apellido materno</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-validate="`${requiredByForeign}alpha_spaces`" 
						v-model="apellidoMaterno" v-on:keypress="soloLetras($event)" name="apellidoMaterno" />
					<span  v-if="error_apm" class="text-danger span-placeholder">Campo requerido</span>
				</div>
			</div>

			<div class="row w-full">
				<div class="col-md-3">
					<span class="span-placeholder" for="sexo">Género</span>
					<div class="col-md-4 alinear">
						<div class="col-md-6 mr-4">
							<span class="span-placeholder">Hombre</span>
							<vs-radio v-bind:disabled="isPersonalEdit" v-model="sexo" vs-name="sexo" vs-value="H"></vs-radio>
						</div>
						<div class="col-md-6 ml-6">
							<span class="span-placeholder">Mujer</span>
							<vs-radio v-bind:disabled="isPersonalEdit" v-model="sexo" vs-name="sexo" vs-value="M"></vs-radio>
						</div>
					</div>
				</div>

				<div class="col-md-3">
					<div class="form-group">
						<span class="span-placeholder">Código de país</span>
						<select 
							v-bind:disabled="isPersonalEdit" 
							name="cars" 
							id="codigo" 
							v-model="codigoSeleccionado" 
							v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
							style="border: 1px solid rgba(0, 0, 0, 0.2)" aria-placeholder="Seleccionar una opción" @change="actualizaCodigo()">
							<option :value="tipo.Lada" v-for="tipo in codigoPaises" :key="tipo.Id">{{ tipo.Pais }}</option>
						</select>
					</div>
				</div>

				<div class="col-md-3">
					<span class="span-placeholder">Teléfono</span>
					<div class="input-group" style="flex-wrap: no-wrap">
						<div class="input-group-prepend">
							<span class="input-group-text" id="basic-addon1">{{ codigoSeleccionado }}</span>
						</div>
						<input 
							v-bind:disabled="isPersonalEdit" 
							type="text" 
							v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
							v-model="telefono" 
							minlength="10" maxlength="10" placeholder="Número a 10 dígitos" aria-label="Username" aria-describedby="basic-addon1" v-on:keypress="isNumber($event)" />	
							
						<!-- <button v-bind:disabled="isPersonalEdit"  class="addTel" id="addCelular2" v-on:click="showCelular2()">
							<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
								<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
								<g id="SVGRepo_iconCarrier"> 
									<path d="M7 12L12 12M12 12L17 12M12 12V7M12 12L12 17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> 
								</g>
							</svg>
						</button>	 -->
					</div>					
				</div>

				<!-- <div class="col-md-3" id="campoCelular2" style="display: none;"> -->
				<div class="col-md-3" id="campoCelular2">
					<span class="span-placeholder">Segundo teléfono celular</span>
					<div class="input-group" style="flex-wrap: no-wrap">
						<input 
							v-bind:disabled="isPersonalEdit" 
							type="text" 
							v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
							v-model="telefono2" name="telefono2"
							v-validate="'min:10|max:10'"
							minlength="10" maxlength="10" placeholder="Número a 10 dígitos" aria-label="Username" aria-describedby="basic-addon1" v-on:keypress="SoloNumero($event)" />							
							
						<!-- <button  v-bind:disabled="isPersonalEdit" class="addTel" id="SubCelular2"  v-on:click="subCelular2()">
							<svg fill="#000000" width="24px" height="24px" viewBox="-3 0 19 19" xmlns="http://www.w3.org/2000/svg" class="cf-icon-svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g>
								<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
								<g id="SVGRepo_iconCarrier">
									<path d="M12.711 9.182a1.03 1.03 0 0 1-1.03 1.03H1.319a1.03 1.03 0 1 1 0-2.059h10.364a1.03 1.03 0 0 1 1.029 1.03z"></path>
								</g>
							</svg>
						</button>							 -->
					</div>					
					<!--<span class="text-danger span-placeholder" v-show="errors.has('telefono2')">El campo debe tener 10 caracteres</span>	-->
				</div>
			</div>

			<div class="row w-full">
				<div class="col-md-3">
					<span class="span-placeholder">Fecha de nacimiento</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						type="date" 
						v-model="fechaNacimiento" 
						name="fechaNacimiento" />
					<span class="text-danger span-placeholder" v-show="errors.has('fechaNacimiento')">Campo requerido</span>
				</div>

				<div class="col-md-3">
					<span class="span-placeholder">País de nacimiento</span>
					<select 
						v-bind:disabled="isPersonalEdit"
						name="name" 
						v-model="paisNacimiento" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
					>
						<option :value='pais.Nombre' v-for="pais in paisesNac" :key="">{{ pais.Nombre }}</option>
					</select>
				</div>

				<div class="col-md-3">
					<span class="span-placeholder" for="estados">Estado de nacimiento</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						id="estados" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="estadoNacimiento">
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in estados" :key="tipo.Id">{{ tipo.Nombre }}</option>
					</select>
				</div>

				<div class="col-md-3">
					<span class="span-placeholder">Municipio de nacimiento</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						label="Municipio de nacimiento" 
						v-model="municipioNacimiento"/>
				</div>
			</div>

			<div class="row w-full">
				<div class="col-md-3">
					<span class="span-placeholder" for="nacionalidad">Nacionalidad</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="cars" id="nacionalidad" 
						v-model="nacionalidad" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
					>
						<option :value="''">Seleccione...</option>
						<option :value="tipo" v-for="tipo in nacionalidades">{{ tipo.Nombre }}</option>
					</select>
				</div>
			</div>

			<div class="row w-full -mb-4"> <!-- # Domicilio -->
				<div class="col-md-12">
					<h5 class="font-bold">Domicilio</h5> 
				</div>
			</div>

			<div class="row w-full">
				<div class="col-md-3">
					<span class="span-placeholder">Calle</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="calle" 
						name="name" />
				</div>

				<div class="col-md-3">
					<span class="span-placeholder">Número exterior</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="numeroExterior" 
						name="name" minlength="30" maxlength="30" />
				</div>

				<div class="col-md-3">
					<span class="span-placeholder">Número Interior</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="numeroInterior" 
						name="username" minlength="30" maxlength="30"/>
				</div>

				<div class="col-md-3">
					<span class="span-placeholder">Código postal</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						type="text" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="codigoPostal" 
						v-on:blur="getColoniaMunicipio(1)"
						name="cp" minlength="5" maxlength="5" v-validate="'min:5|max:5'" v-on:keypress="isNumber($event)" />

					<span class="text-danger span-placeholder" v-show="errors.has('cp')">El campo debe tener 5 caracteres</span>
				</div>
			</div>

			<div class="row w-full">
				<div class="col-md-3">
					<span class="span-placeholder" v-if="LabelColoniaObligado">Colonia *</span>
                    <span class="span-placeholder" v-if="LabelColoniaNoObligado">Colonia </span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						v-model="colonia" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
						name="colonia" id="colonia" class="vs-input">
						<option value="" selected>Selecciona una colonia</option>
						<option :value='colonia' v-for="colonia in ColoniasCP" :key="">{{colonia}}</option>
					</select>
					<span class="text-danger span-placeholder" v-if="LabelColoniaObligadoText">Campo requerido</span>
				</div>	

				<div class="col-md-3">
					<span class="span-placeholder" for="estado">Estado</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="cars" 
						id="estado" 
						v-model="estado" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
					>
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in estados">{{ tipo.Nombre }}</option>
					</select>
				</div>

				<div class="col-md-3">
					<span class="span-placeholder">Ciudad</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="ciudad" 
						name="name" />
				</div>

				<div class="col-md-3">
					<span class="span-placeholder">Municipio</span>
					<input 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="municipio" 
						name="name" disabled />
				</div>
			</div>

			<div class="row w-full">
				<div class="col-md-4"> 
					<span class="span-placeholder" for="titularidadDomicilio">Titularidad de domicilio actual</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="titularidadDomicilio" 
						id="titularidadDomicilio" 
						v-model="adicionales.titularidadDomicilio" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']">
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in domicilioOcupaciones" :key="tipo.id">{{ tipo.Nombre }}</option>
					</select>
				</div>

				<div class="col-md-4"> 
					<span class="span-placeholder">Tiempo ocupando el domicilio actual</span>
                	
					<div class="flex">
						<span class="col-4">
							<input 
								v-bind:disabled="isPersonalEdit"
								v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
								class="input-antiguedad-am"
								label="Tiempo ocupando el domicilio actual"
								v-model="adicionales.tiempoOcupandoDomicilioActual"
								name="tiempoOcupandoDomicilioActualMeses"/>
						</span>
						<span class="col-2 input-antiguedad-fecha">
							<span>A</span>
						</span>

						<span class="col-4">
							<input 
								v-bind:disabled="isPersonalEdit"
								v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
								class="input-antiguedad-am"
								label="Tiempo ocupando el domicilio actual"
								v-model="adicionales.tiempoOcupandoDomicilioActualMeses"
								name="tiempoOcupandoDomicilioActualMeses"/>
						</span>
						<span 
							class="col-2 input-antiguedad-fecha">
							<span>M</span>
						</span>

                	</div>
				</div>

				<div class="col-md-4"> 
					<span class="span-placeholder">Antigüedad en la ciudad</span>
					<div class="flex">	
						<span class="col-4">
							<input 
								v-bind:disabled="isPersonalEdit"
								v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
								class="input-antiguedad-am"
								label="Antigüedad en la ciudad"
								v-model="adicionales.antiguedadCiudad"
								v-on:keypress="isNumber($event)"
								name="antiguedadCiudad"/>
						</span>
						<span 
							class="col-2 input-antiguedad-fecha">
							<span>A</span>
						</span>

						<span class="col-4">
							<input 
								v-bind:disabled="isPersonalEdit"
								v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
								class="input-antiguedad-am"
								label="Antigüedad en la ciudad"
								v-model="adicionales.antiguedadCiudadMeses"
								v-on:keypress="isNumber($event)"
								name="antiguedadCiudadMeses"/>
						</span>
						<span 
							class="col-2 input-antiguedad-fecha">
							<span>M</span>
						</span>
					</div>
				</div>
			</div>

			<div class="row w-full">
				<div class="col-md-5"> 
					<span class="span-placeholder">Antigüedad del inmueble a adquirir en años</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"   v-model="adicionales.antiguedadInmuebleAdquirir" 
						name="antiguedadInmuebleAdquirir" 
						v-on:keypress="isNumber($event)" />
				</div>

				<div class="col-md-5"> 
					<span class="span-placeholder">Antigüedad domicilio anterior en años</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="adicionales.antiguedadDomicilioAnterior" 
						name="antiguedadDomicilioAnterior" 
						v-on:keypress="isNumber($event)" />
				</div>
			</div>

			<div class="row w-full -mb-4"> <!-- # Personales -->
				<div class="col-md-12">
					<h5 class="font-bold">Personales</h5> 
				</div>
			</div>

			<div class="row w-full">
				<div class="col-md-3"> 
					<span class="span-placeholder">Identificación que presenta</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="identificaciones" 
						id="identificaciones" 
						v-model="adicionales.identificacionPresenta" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']">
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in identificaciones" :key="tipo.Id">{{ tipo.Nombre }}</option>
					</select>
				</div>
			
				<div class="col-md-6"> 
					<span class="span-placeholder">Número de identificación</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"  
						v-model="adicionales.numeroIfe" 
						name="numeroIfe" />
				</div>

				<div class="col-md-3"> 
					<span class="span-placeholder">Vigencia identificación</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"  
						type="date" 
						v-model="adicionales.vigencia" 
						name="vigencia" />
				</div>
			</div>

			<div class="row w-full">
				<div class="col-md-3"> 
					<span class="span-placeholder">Número de seguro social</span>
						<!-- v-validate="'required|min:11|max:11'"  -->
					<!-- se hace validacion manual por problemas de guardado -->
					<input
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="adicionales.nss" 
						name="nss" 
						v-on:keypress="isNumber($event)" />
					<span
						v-if="!nssValido" 
						class="text-danger span-placeholder">El campo debe tener 11 caracteres</span>
				</div>
			
				<div class="col-md-3"> 
					<span class="span-placeholder">Número de ISSSTE</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="adicionales.issste" 
						name="issste" />
				</div>

				<div class="col-md-3"> 
					<vx-tooltip text="Requerido por: HSBC, INBURSA">
						<span class="span-placeholder">FIEL</span>
						<input 
							v-bind:disabled="isPersonalEdit" 
							v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"  
							v-model="adicionales.fiel" 
							name="fiel" />
					</vx-tooltip>
				</div>

				<div class="col-md-3"> 
					<span class="span-placeholder">Forma de enganche</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="formaEnganche" 
						id="formaEnganche" 
						v-model="adicionales.formaEnganche" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']">
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in formasEnganche" :key="tipo.Id">{{ tipo.Nombre }}</option>
					</select>
				</div>
			</div>

			<div class="row w-full">
				<div class="col-md-3"> 
					<span class="span-placeholder">Máximo grado de estudios</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="gradoEstudios" 
						id="gradoEstudios" 
						v-model="adicionales.gradoEstudios" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']">
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in gradosEstudio" :key="tipo.id">{{ tipo.Nombre }}</option>
					</select>
				</div>
			
				<div class="col-md-3"> 
					<span class="span-placeholder md:text-sm">Tipo de institución de estudios</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"  
						v-model="adicionales.institucionEstudios" 
						name="institucionEstudios" 
						placeholder="Pública o Privada" />
				</div>

				<div class="col-md-3"> 
					<span class="span-placeholder">Compañía celular</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="companiaCelular" 
						v-model="adicionales.companiaCelular" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']">
						<option :value="''">Seleccione...</option>
						<option :value="compania.name" v-for="compania in companiasCelular" :key="compania.name">{{ compania.name }}</option>
					</select>
				</div>

				<div class="col-md-3"> 
					<span class="span-placeholder">Teléfono fijo (10 dígitos)</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"  v-model="adicionales.telefonoFijo" 
						name="name" />
				</div>
			</div>

			<div class="row w-full">
				<div class="col-md-3"> 
					<span class="span-placeholder">Total de gastos mensuales</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"  v-model="adicionales.totalGastosMensuales" 
						name="totalGastosMensuales" 
						v-on:keypress="isNumber($event)" />
				</div>

				<div class="col-md-5"> 
					<span class="span-placeholder">Monto de pensión alimenticia $ (Sí aplica)</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"  
						v-model="adicionales.montoPension" 
						name="montoPension" />
				</div>

				<div class="col-md-3"> 
					<span class="span-placeholder">¿Seguros con el banco?</span>
					<vs-switch v-bind:disabled="isPersonalEdit" v-model="adicionales.seguros" />
				</div>
			</div>

			<div class="row w-full">
				<div class="col-md-6"> 
					<span class="span-placeholder">En caso de haber tenido otro nombre favor de indicarlo</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"  
						v-model="adicionales.otroNombre" 
						name="otroNombre" />
				</div>
			</div>

			<!-- TODO: No includio en la mejora -->
			<div class="row w-full">
				<div class="col-md-4">
					<span class="span-placeholder">País de residencia</span>
					<input 
					v-bind:disabled="isPersonalEdit" 
					v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
					label="País de residencia" 
					v-model="PaisRecidencia" />
				</div>
			</div>

			<div class="row w-full -mb-4"> <!-- # Domicilio de ciudadano extranjero -->
				<div class="col-md-12">
					<h5 class="font-bold">Domicilio de ciudadano extranjero</h5> 
				</div>
			</div>

			<div class="row w-full">
				<div class="col-md-3"> 
					<span class="span-placeholder">Calle</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"  
						v-model="adicionales.calleExtranjero" 
						name="calleExtranjero" />
				</div>

				<div class="col-md-3"> 
					<span class="span-placeholder">Número exterior</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"  
						v-model="adicionales.numeroExteriorExtranjero" name="numeroExteriorExtranjero" />
				</div>

				<div class="col-md-3"> 
					<span class="span-placeholder">Número interior</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"   v-model="adicionales.numeroInteriorExtranjero" 
						name="numeroInteriorExtranjero" />
				</div>

				<div class="col-md-3"> 
					<span class="span-placeholder">Colonia</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"  
						v-model="adicionales.coloniaExtranjero" 
						name="coloniaExtranjero" />
				</div>
			</div>

			<div class="row w-full">
				<div class="col-md-3"> 
					<span class="span-placeholder">Código postal</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"  
						v-model="adicionales.codigoPostalExtranjero" 
						name="codigoPostalExtranjero" />
				</div>

				<div class="col-md-3"> 
					<span class="span-placeholder">Estado</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"   v-model="adicionales.estadoExtranjero" name="estadoExtranjero" />
				</div>

				<div class="col-md-3"> 
					<span class="span-placeholder">País</span>
					<select 
						v-bind:disabled="isPersonalEdit"
						name="paisExtranjero" 
						v-model="adicionales.paisExtranjero" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']">
						<option :value='pais.Nombre' v-for="pais in paisesNac" :key="">{{ pais.Nombre }}</option>
					</select>
				</div>
			</div>

			<div class="row w-full -mb-4"> <!-- # Información Estado Civil -->
				<div class="col-md-12">
					<h5 class="font-bold">Información Estado Civil</h5> 
				</div>
			</div>

			<div class="row w-full">
				<div class="col-md-4">
					<span class="span-placeholder" for="estadoCivil">Estado Civil</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="estadoCivil" 
						id="estadoCivil" 
						v-model="estadoCivil" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
					>
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in estadosCiviles" :key="">{{ tipo.Nombre }}</option>
					</select>
				</div>
				
				<div class="col-md-4" v-if="estadoCivil == 1 || estadoCivil == 4">
					<span class="span-placeholder" for="regimen">Régimen matrimonial</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="regimen" id="regimen" 
						v-model="regimenMatrimonial" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
					>
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in regimenes" :key="">{{ tipo.Nombre }}</option>
					</select>
				</div>

				<div class="col-md-4" v-if="estadoCivil == 1 || estadoCivil == 4">
					<ul class="con-s">
						<li>
							<span class="span-placeholder" for="">Es cónyuge del solicitante</span>
							<vs-switch v-bind:disabled="isPersonalEdit" v-model="conyuge.esConyugue" />
						</li>
					</ul>
				</div>
			</div>

			<div class="row w-full"> <!-- checks -->
				<!-- <div class="col-md-4">
					<ul class="con-s">
						<li>
							<span class="span-placeholder" for="">Es cónyuge del solicitante</span>
							<vs-switch v-bind:disabled="isPersonalEdit" v-model="conyuge.esConyugue" />
						</li>
					</ul>
				</div> -->
				<div class="col-md-4" v-if="(estadoCivil == 1 || estadoCivil == 4) && conyuge.esConyugue">
					<ul class="con-s">
						<li>
							<span class="span-placeholder" for="">¿Participa en el crédito?</span>
							<vs-switch v-bind:disabled="isPersonalEdit" v-model="conyuge.participaCredito" />
						</li>
					</ul>
				</div>
				<div class="col-md-4" v-if="(estadoCivil == 1 || estadoCivil == 4) && conyuge.esConyugue">
					<span class="span-placeholer" for="">¿Será copropietario?</span>
					<vs-switch v-bind:disabled="isPersonalEdit" v-model="conyuge.seraCoPropietario" />
				</div>

				<div class="col-md-4" v-if="(estadoCivil == 1 || estadoCivil == 4) && regimenMatrimonial != 1 && conyuge.esConyugue">
					<span class="span-placeholder" for="">¿Consolida ingresos con el solicitante?</span>
					<vs-switch v-bind:disabled="isPersonalEdit" v-model="conyuge.consolidaIngresos" />
				</div>
			</div>
			
			<!-- <div v-if="!validate" class="row w-full">  -->
				<!-- TODO: Va en el componente conyuge -->
				<!-- <span class="red">Completa la información del conyuge marcada con *</span>
			</div> -->

			<div class="row w-full -mb-4" v-if="!conyuge.participaCredito"> <!-- # Agregar coacreditado -->
				<div class="col-md-12">
					<h5 class="font-bold">Agregar coacreditado</h5> 
				</div>
			</div>

			<div class="row w-full" v-if="!conyuge.participaCredito">
				<div class="col-md-4">
					<span class="span-placeholder">Correo electrónico *</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="coacreditado.email" 
						name="hola" 
						@change="dameCoacreditadoRegistrado()" 
						v-on:blur="validaCorreoSolicitante('Coacreditado')"/>
						<span class="text-danger span-placeholder" v-if="errorEmailCoacreditado">El Correo electronico no puede ser el mismo que del solicitante</span>
				</div>
				<div class="col-md-4">
					<span class="span-placeholder" for="parentesco">Parentesco *</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="cars" id="parentesco" 
						v-model="coacreditado.parentesco" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
					>
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in parentescos" :key="">{{ tipo.Nombre }}</option>
					</select>
				</div>
				<div class="col-md-4">
					<span class="span-placeholder" for="">¿Será copropietario?</span>
					<vs-switch v-bind:disabled="isPersonalEdit" v-model="coacreditado.seraCoPropietario" />
				</div>
			</div>

			<div class="row w-full" v-if="!conyuge.participaCredito">
				<div class="col-md-4">
					<span class="span-placeholder" for="">¿Consolida ingresos con el solicitante?</span>
					<vs-switch v-bind:disabled="isPersonalEdit" v-model="coacreditado.consolidaIngresos" />
				</div>
				<div class="col-md-4">
					<span class="span-placeholder" for="">¿Participa en el crédito?</span>
					<vs-switch v-bind:disabled="isPersonalEdit" v-model="coacreditado.participaCredito" />
				</div>
			</div>

			<div class="row w-full" v-if="!conyuge.participaCredito">
				<div>
					<button class="btn btn-second" @click="registraCoacreditado()" :disabled="!validate3">Añadir Coacreditado</button>
				</div>
				<span v-if="!validate2" class="red block">Completa la información marcada con *</span>
			</div>
			<!-- Nueva organizacion 2024-01-24 -->

			<!-- <div class="row w-full">
				<div class="col-md-3">
					<span class="span-placeholder" for="sexo">Sexo</span>
					<div class="col-md-4 alinear">
						<div class="col-md-6 mr-4">
							<span class="span-placeholder">Hombre</span>
							<vs-radio v-bind:disabled="isPersonalEdit" v-model="sexo" vs-name="sexo" vs-value="H"></vs-radio>
						</div>
						<div class="col-md-6 ml-6">
							<span class="span-placeholder">Mujer</span>
							<vs-radio v-bind:disabled="isPersonalEdit" v-model="sexo" vs-name="sexo" vs-value="M"></vs-radio>
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="form-group">
						<span class="span-placeholder">Código país</span>
						<select 
							v-bind:disabled="isPersonalEdit" 
							name="cars" 
							id="codigo" 
							v-model="codigoSeleccionado" 
							v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
							style="border: 1px solid rgba(0, 0, 0, 0.2)" aria-placeholder="Seleccionar una opción" @change="actualizaCodigo()">
							<option :value="tipo.Lada" v-for="tipo in codigoPaises" :key="tipo.Id">{{ tipo.Pais }}</option>
						</select>
					</div>
				</div>
				<div class="col-md-4">
					<span class="span-placeholder">Teléfono celular (10 dígitos)</span>
					<div class="input-group" style="flex-wrap: no-wrap">
						<div class="input-group-prepend">
							<span class="input-group-text" id="basic-addon1">{{ codigoSeleccionado }}</span>
						</div>
						<input 
							v-bind:disabled="isPersonalEdit" 
							type="text" 
							v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
							v-model="telefono" 
							minlength="10" maxlength="10" placeholder="Número a 10 dígitos" aria-label="Username" aria-describedby="basic-addon1" v-on:keypress="isNumber($event)" />	
							
						<button v-bind:disabled="isPersonalEdit"  class="addTel" id="addCelular2" v-on:click="showCelular2()">
							<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
								<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
								<g id="SVGRepo_iconCarrier"> 
									<path d="M7 12L12 12M12 12L17 12M12 12V7M12 12L12 17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> 
								</g>
							</svg>
						</button>	
					</div>					
				</div>				
				<div class="col-md-3" id="campoCelular2" style="display: none;">
					<span class="span-placeholder">Segundo número de celular<br/> (Sin código de pais)</span>
					<div class="input-group" style="flex-wrap: no-wrap">
						<input 
							v-bind:disabled="isPersonalEdit" 
							type="text" 
							v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
							v-model="telefono2" name="telefono2"
							v-validate="'min:10|max:10'"
							minlength="10" maxlength="10" placeholder="Número a 10 dígitos" aria-label="Username" aria-describedby="basic-addon1" v-on:keypress="SoloNumero($event)" />							
							
						<button  v-bind:disabled="isPersonalEdit" class="addTel" id="SubCelular2"  v-on:click="subCelular2()">
							<svg fill="#000000" width="24px" height="24px" viewBox="-3 0 19 19" xmlns="http://www.w3.org/2000/svg" class="cf-icon-svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g>
								<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
								<g id="SVGRepo_iconCarrier">
									<path d="M12.711 9.182a1.03 1.03 0 0 1-1.03 1.03H1.319a1.03 1.03 0 1 1 0-2.059h10.364a1.03 1.03 0 0 1 1.029 1.03z"></path>
								</g>
							</svg>
						</button>							
					</div>					 -->
					<!--<span class="text-danger span-placeholder" v-show="errors.has('telefono2')">El campo debe tener 10 caracteres</span>	-->
				<!-- </div> -->
				
					<!--generar sms--> 
					<!--<div class="col-md-3">
				<span class="span-placeholder">Teléfono celular (10 dígitos)</span>
				<input class="vs-input" v-model="telefono" name="telefono" v-validate="'required|min:10|max:10'" v-on:keypress="isNumber($event)"/>
				<span class="text-danger span-placeholder" v-show="errors.has('telefono')">El campo debe tener 10 caracteres</span>
				</div>-->
				<!-- <div class="col-md-3">
					<span class="span-placeholder">Fecha de nacimiento</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						type="date" 
						v-model="fechaNacimiento" 
						name="fechaNacimiento" />
					<span class="text-danger span-placeholder" v-show="errors.has('fechaNacimiento')">Campo requerido</span>
				</div>
			</div> -->
			<!-- <div class="row w-full"> -->
				<!-- <div class="col-md-4"> // Se mueve 2024-01-23
					<span class="span-placeholder">CURP</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-if="!AccreditedidV3" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="curp" 
						name="curp" v-validate="'min:18|max:18'" v-mask="'AAAA######AAAAAANN'" value="" onkeyup="javascript:this.value=this.value.toUpperCase();" />
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-if="AccreditedidV3" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="curp" 
						name="curp" 
						v-validate="'min:18|max:18'" v-mask="'AAAA######AAAAAANN'" value="" onkeyup="javascript:this.value=this.value.toUpperCase();" disabled />
					<span class="text-danger span-placeholder" v-show="errors.has('curp')">El campo debe tener 18 caracteres</span>
				</div>
				<div class="col-md-4">
					<span class="span-placeholder">RFC</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="rfc" 
						name="rfc" 
						v-validate="'min:13|max:13'" 
						v-mask="'AAAA######NNN'" onkeyup="javascript:this.value=this.value.toUpperCase();" />
					<span class="text-danger span-placeholder" v-show="errors.has('rfc')">El campo debe tener 13 caracteres</span>
				</div> -->
				<!-- <div class="col-md-4">
					<span class="span-placeholder">País de nacimiento</span>

					<select 
						v-bind:disabled="isPersonalEdit"
						name="name" 
						v-model="paisNacimiento" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
					>
						<option :value='pais.Nombre' v-for="pais in paisesNac" :key="">{{ pais.Nombre }}</option>
					</select> -->
					<!-- <input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						label="País de nacimiento" 
						v-model="paisNacimiento" /> -->
				<!-- </div> -->

				<!-- <div class="col-md-4">
					<span class="span-placeholder">Calle</span>
					<input class="vs-input" v-model="calle" name="name" />
				</div> -->

			<!-- </div> -->

			<!-- <div class="row w-full">
				<div class="col-md-4">
					<span class="span-placeholder" for="estados">Estado de nacimiento</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						id="estados" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="estadoNacimiento">
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in estados" :key="tipo.Id">{{ tipo.Nombre }}</option>
					</select>
				</div>

				<div class="col-md-4">
					<span class="span-placeholder">Municipio de nacimiento</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						label="Municipio de nacimiento" 
						v-model="municipioNacimiento"/>
				</div>
			</div> -->

			<!-- <div class="row w-full"> -->
				<!-- TODO: Pendiente a revisión, campo: País de residencia -->
				<!-- <div class="col-md-4">
					<span class="span-placeholder">País de residencia</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						label="País de residencia" 
						v-model="PaisRecidencia" />
				</div> -->
				<!-- <div class="col-md-4">
					<span class="span-placeholder" for="estado">Estado</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="cars" 
						id="estado" 
						v-model="estado" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
					>
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in estados">{{ tipo.Nombre }}</option>
					</select>
				</div>
				<div class="col-md-4">
					<span class="span-placeholder">Ciudad</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="ciudad" 
						name="name" />
				</div> -->
			<!-- </div> -->

			<!-- <div class="row w-full">
				<div class="col-md-3">
					<span class="span-placeholder">Calle</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="calle" 
						name="name" />
				</div>

				<div class="col-md-3">
					<span class="span-placeholder">Número exterior</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="numeroExterior" 
						name="name" minlength="30" maxlength="30" />
				</div>

				<div class="col-md-3">
					<span class="span-placeholder">Número Interior</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="numeroInterior" 
						name="username" minlength="30" maxlength="30"/>
				</div>

				<div class="col-md-3">
					<span class="span-placeholder">Código postal</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						type="text" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="codigoPostal" 
						v-on:blur="getColoniaMunicipio(1)"
						name="cp" minlength="5" maxlength="5" v-validate="'min:5|max:5'" v-on:keypress="isNumber($event)" />

					<span class="text-danger span-placeholder" v-show="errors.has('cp')">El campo debe tener 5 caracteres</span>
				</div> -->

				<!-- <div class="col-md-3">
					<span class="span-placeholder">Colonia</span>
					<input class="vs-input" v-model="colonia" name="name" />
				</div>
				<div class="col-md-3">
					<span class="span-placeholder">Municipio</span>
					<input class="vs-input" v-model="municipio" name="name" />
				</div> -->
			<!-- </div> -->
			<!-- <div class="row w-full"> -->
				<!-- <div class="col-md-4">
					<span class="span-placeholder">Ciudad</span>
					<input class="vs-input" v-model="ciudad" name="name" />
				</div> -->
				<!-- <div class="col-md-4">
					<span class="span-placeholder">Código postal</span>
					<input type="text" class="vs-input" v-model="codigoPostal" name="cp" minlength="5" maxlength="5" v-validate="'min:5|max:5'" v-on:keypress="isNumber($event)" />

					<span class="text-danger span-placeholder" v-show="errors.has('cp')">El campo debe tener 5 caracteres</span>
				</div> -->
				<!-- <div class="col-md-4">
					<span class="span-placeholder" for="estado">Estado</span>
					<select name="cars" id="estado" v-model="estado" class="vs-input">
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in estados">{{ tipo.Nombre }}</option>
					</select>
				</div> -->

				<!-- <div class="col-md-4">
					<span class="span-placeholder">Municipio</span>
					<input 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="municipio" 
						name="name" disabled />
				</div> -->

				<!-- <div class="col-md-4">
					<span class="span-placeholder" v-if="LabelColoniaObligado">Colonia *</span>
                    <span class="span-placeholder" v-if="LabelColoniaNoObligado">Colonia </span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						v-model="colonia" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
						name="colonia" id="colonia" class="vs-input">
						<option value="" selected>Selecciona una colonia</option>
						<option :value='colonia' v-for="colonia in ColoniasCP" :key="">{{colonia}}</option>
					</select>
					<span class="text-danger span-placeholder" v-if="LabelColoniaObligadoText">Campo requerido</span>
				</div>				 -->

				<!-- <div class="col-md-4">
					<span class="span-placeholder" for="nacionalidad">Nacionalidad</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="cars" id="nacionalidad" 
						v-model="nacionalidad" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
					>
						<option :value="''">Seleccione...</option>
						<option :value="tipo" v-for="tipo in nacionalidades">{{ tipo.Nombre }}</option>
					</select> -->
					<!-- <input class="vs-input" label="Nacionalidad" v-model="nacionalidad" name="username" />-->
				<!-- </div> -->

			<!-- </div> -->
			<!-- <div class="row w-full"> // Campos multiples de estado civil -->
				<!-- <div class="col-md-4">
					<span class="span-placeholder" for="nacionalidad">Nacionalidad</span>
					<select name="cars" id="nacionalidad" v-model="nacionalidad" class="vs-input">
						<option :value="''">Seleccione...</option>
						<option :value="tipo.value" v-for="tipo in nacionalidades">{{ tipo.label }}</option>
					</select> -->
					<!-- <input class="vs-input" label="Nacionalidad" v-model="nacionalidad" name="username" />-->
				<!-- </div> -->
				<!-- <div class="col-md-4">
					<span class="span-placeholder" for="formasEnganche">Estado Civil</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="cars" 
						id="formasEnganche" 
						v-model="estadoCivil" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
					>
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in estadosCiviles" :key="">{{ tipo.Nombre }}</option>
					</select>
				</div>
				<div class="col-md-4" v-if="estadoCivil == 1 || estadoCivil == 4">
					<span class="span-placeholder" for="regimen">Régimen matrimonial</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="regimen" id="regimen" 
						v-model="regimenMatrimonial" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
					>
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in regimenes" :key="">{{ tipo.Nombre }}</option>
					</select>
				</div> -->
			<!-- </div> -->

			<!-- <div class="row w-full" v-if="estadoCivil == 1 || estadoCivil == 4">
				<div class="col-md-4">
					<ul class="con-s">
						<li>
							<span class="span-placeholder" for="">Es cónyuge del solicitante</span>
							<vs-switch v-bind:disabled="isPersonalEdit" v-model="conyuge.esConyugue" />
						</li>
					</ul>
				</div>
				<div class="col-md-4" v-if="conyuge.esConyugue">
					<ul class="con-s">
						<li>
							<span class="span-placeholder" for="">¿Participa en el crédito?</span>
							<vs-switch v-bind:disabled="isPersonalEdit" v-model="conyuge.participaCredito" />
						</li>
					</ul>
				</div>
				<div class="col-md-4" v-if="conyuge.esConyugue">
					<span class="span-placeholer" for="">¿Será copropietario?</span>
					<vs-switch v-bind:disabled="isPersonalEdit" v-model="conyuge.seraCoPropietario" />
				</div>
			</div> -->

			<!-- TODO: Esto se tiene que migrar a otro componente -->
			<!-- <div class="row w-full" v-if="(estadoCivil == 1 || estadoCivil == 4) && regimenMatrimonial != 1">
				<div class="col-md-4" v-if="conyuge.esConyugue">
					<span class="span-placeholder" for="">¿Consolida ingresos con el solicitante?</span>
					<vs-switch v-bind:disabled="isPersonalEdit" v-model="conyuge.consolidaIngresos" />
				</div>
				<div class="col-md-4">
					<span class="span-placeholder">Correo electrónico Cónyuge*</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="conyuge.email" 
						v-on:blur="validaCorreoSolicitante('conyuge')"
						name="conyugeEmail" />
						<span class="text-danger span-placeholder" v-if="errorEmail">El Correo electronico no puede ser el mismo que del solicitante</span>
				</div>
				<div class="col-md-4">
					<span class="span-placeholder">Nombre Cónyuge *</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
						v-model="conyuge.nombre" 
						name="conyugeName" />
				</div>
			</div>

			<div class="row w-full" v-if="estadoCivil == 1 && regimenMatrimonial == 1">
				<div class="col-md-4">
					<span class="span-placeholder">Correo electrónico Cónyuge</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
						v-model="conyuge.email" 
						v-on:blur="validaCorreoSolicitante('conyuge')"
						name="conyugeEmail" />
						<span class="text-danger span-placeholder" v-if="errorEmail">El Correo electronico no puede ser el mismo que del solicitante</span>
				</div>
				<div class="col-md-4">
					<span class="span-placeholder">Nombre Cónyuge</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
						v-model="conyuge.nombre" 
						name="conyugeName" />
				</div>
			</div>

			<div class="row w-full" v-if="estadoCivil == 4 && regimenMatrimonial == 1">
				<div class="col-md-4">
					<span class="span-placeholder">Correo electrónico Cónyuge*</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="conyuge.email" 
						v-on:blur="validaCorreoSolicitante('conyuge')"
						name="conyugeEmail"/>
						<span class="text-danger span-placeholder" v-if="errorEmail">El Correo electronico no puede ser el mismo que del solicitante</span>
				</div>
				<div class="col-md-4">
					<span class="span-placeholder">Nombre Cónyuge *</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="conyuge.nombre" 						
						name="conyugeName" />
				</div>
			</div>

			<div class="row w-full" v-if="(estadoCivil == 1 || estadoCivil == 4) && regimenMatrimonial == 3">
				<div class="col-md-4">
					<span class="span-placeholder">Apellido Paterno Cónyuge*</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
						v-model="conyuge.apellidoPaterno" 
						name="conyugeApP" />
				</div>
				<div class="col-md-4">
					<span class="span-placeholder">Apellido Materno Cónyuge*</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="conyuge.apellidoMaterno" 
						name="conyugeApM" />
				</div>
				<div class="col-md-4">
					<span class="span-placeholder">RFC Cónyuge*</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						label="RFC cónyuge" 
						v-model="conyuge.rfc" 
						name="rfcconyuge" 
						v-validate="'min:13|max:13'" />
					<span class="text-danger span-placeholder" v-show="errors.has('rfcconyuge')">El campo debe tener 13 caracteres</span>
				</div>
			</div>

			<div class="row w-full" v-if="estadoCivil == 4 && (regimenMatrimonial == 1 || regimenMatrimonial == 2)">
				<div class="col-md-4">
					<span class="span-placeholder">Apellido Paterno Cónyuge*</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="conyuge.apellidoPaterno" 
						name="conyugeApP" />
				</div>
				<div class="col-md-4">
					<span class="span-placeholder">Apellido Materno Cónyuge*</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="conyuge.apellidoMaterno" 
						name="conyugeApM" />
				</div>
				<div class="col-md-4">
					<span class="span-placeholder">RFC Cónyuge*</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						label="RFC cónyuge" 
						v-model="conyuge.rfc" 
						name="rfcconyuge" 
						v-validate="'min:13|max:13'" />
					<span class="text-danger span-placeholder" v-show="errors.has('rfcconyuge')">El campo debe tener 13 caracteres</span>
				</div>
			</div>

			<div class="row w-full" v-if="estadoCivil == 1 && regimenMatrimonial == 2">
				<div class="col-md-4">
					<span class="span-placeholder">Apellido Paterno Cónyuge*</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="conyuge.apellidoPaterno" 
						name="conyugeApP" />
				</div>
				<div class="col-md-4">
					<span class="span-placeholder">Apellido Materno Cónyuge</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="conyuge.apellidoMaterno" 
						name="conyugeApM" />
				</div>
				<div class="col-md-4">
					<span class="span-placeholder">RFC Cónyuge</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						label="RFC cónyuge" 
						v-model="conyuge.rfc" 
						name="rfcconyuge" 
						v-validate="'min:13|max:13'" />
					<span class="text-danger span-placeholder" v-show="errors.has('rfcconyuge')">El campo debe tener 13 caracteres</span>
				</div>
			</div>

			<div class="row w-full" v-if="estadoCivil == 1 && regimenMatrimonial == 1">
				<div class="col-md-4">
					<span class="span-placeholder">Apellido Paterno Cónyuge</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="conyuge.apellidoPaterno" 
						name="conyugeApP" />
				</div>
				<div class="col-md-4">
					<span class="span-placeholder">Apellido Materno Cónyuge</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="conyuge.apellidoMaterno" 
						name="conyugeApM" />
				</div>
				<div class="col-md-4">
					<span class="span-placeholder">RFC Cónyuge</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						label="RFC cónyuge" 
						v-model="conyuge.rfc" 
						name="rfcconyuge" 
						v-validate="'min:13|max:13'" />
					<span class="text-danger span-placeholder" v-show="errors.has('rfcconyuge')">El campo debe tener 13 caracteres</span>
				</div>
			</div>

			<div class="row w-full" v-if="estadoCivil == 1 || estadoCivil == 4">
				<div class="col-md-4">
					<span class="span-placeholder">CURP cónyuge</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="conyuge.curp" 
						name="cuprconyuge" 
						v-validate="'min:18|max:18'" />
					<span class="text-danger span-placeholder" v-show="errors.has('cuprconyuge')">El campo debe tener 18 caracteres</span>
				</div>
				<div class="col-md-4">
					<span class="span-placeholder" for="sexo">Genero Cónyuge</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="sexoconyuge" id="sexoconyuge" 
						v-model="conyuge.sexo" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
					>
						<option :value="''">Seleccione...</option>
						<option :value="'H'">MASCULINO</option>
						<option :value="'M'">FEMENINO</option>
					</select>
				</div>
				<div class="col-md-4">
					<span class="span-placeholder">Fecha de nacimiento</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						type="date" 
						v-model="conyuge.fechaNacimiento" 
						name="fechaNacimientoconyuge" />
					<span class="text-danger span-placeholder" v-show="errors.has('fechaNacimiento')">Campo requerido</span>
				</div>
			</div>

			<div class="row w-full" v-if="estadoCivil == 1 || estadoCivil == 4">
				<div class="col-md-3">
					<span class="span-placeholder" for="nacionalidad">Nacionalidad cónyuge</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="cars" id="nacionalidad" 
						v-model="conyuge.nacionalidad" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
					>
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Nombre" v-for="tipo in nacionalidades">{{ tipo.Nombre }}</option>
					</select>
				</div>
				<div class="col-md-6" v-if="conyuge.nacionalidad == 'MEXICANO (A)'">
					<span class="span-placeholder" for="estado">Entidad Federativa Nacimiento Cónyuge</span>
					<select 
						v-bind:disabled="isPersonalEdit" 
						name="cars" 
						id="estado" 
						v-model="conyuge.estado" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
					>
						<option :value="''">Seleccione...</option>
						<option :value="tipo.Id" v-for="tipo in estados">{{ tipo.Nombre }}</option>
					</select>
				</div>
				<div class="col-md-3">
					<span class="span-placeholder">País de residencia</span>
					<input 
						v-bind:disabled="isPersonalEdit" 
						v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
						v-model="conyuge.paisRecidencia" 
						name="conyugePaisRecidencia" />
				</div>
			</div> -->
			<!-- TODO: Esto se tiene que migrar a otro componente -->

			<!-- <br /> -->

			<!-- Mover arriba -->
			<!-- <button class="btn btn-second" @click="agregaConyuge()" :disabled="!validate">Guardar información</button> -->

			<!-- <p v-if="!validate" class="red">Completa la información del conyuge marcada con *</p> -->
			<!-- <br /> -->
			<!-- <div class="row w-full">
				<div class="accordion" id="accordionExampleCO" v-if="!conyuge.participaCredito">
					<div class="accordion-item">
						<h2 class="accordion-header" id="headingOneCO">
							<button v-bind:disabled="isPersonalEdit" class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCO" aria-expanded="true" aria-controls="collapseOneCO">Agregar coacreditado</button>
						</h2>
						<div id="collapseOneCO" class="accordion-collapse collapse show" aria-labelledby="headingOneCO" data-bs-parent="#accordionExampleCO">
							<div class="accordion-body"> -->
								<!-- <div class="row w-full"> -->
									<!-- <div class="col-md-4">
										<span class="span-placeholder">Correo electrónico *</span>
										<input 
											v-bind:disabled="isPersonalEdit" 
											v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
											v-model="coacreditado.email" 
											name="hola" 
											@change="dameCoacreditadoRegistrado()" 
											v-on:blur="validaCorreoSolicitante('Coacreditado')"/>
											<span class="text-danger span-placeholder" v-if="errorEmailCoacreditado">El Correo electronico no puede ser el mismo que del solicitante</span>
									</div>
									<div class="col-md-4">
										<span class="span-placeholder" for="parentesco">Parentesco *</span>
										<select 
											v-bind:disabled="isPersonalEdit" 
											name="cars" id="parentesco" 
											v-model="coacreditado.parentesco" 
											v-bind:class="[ isPersonalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
										>
											<option :value="''">Seleccione...</option>
											<option :value="tipo.Id" v-for="tipo in parentescos" :key="">{{ tipo.Nombre }}</option>
										</select>
									</div>
									<div class="col-md-4">
										<span class="span-placeholder" for="">¿Será copropietario?</span>
										<vs-switch v-bind:disabled="isPersonalEdit" v-model="coacreditado.seraCoPropietario" />
									</div> -->
								<!-- </div>
								<div class="row w-full"> -->
									<!-- <div class="col-md-4">
										<span class="span-placeholder" for="">¿Consolida ingresos con el solicitante?</span>
										<vs-switch v-bind:disabled="isPersonalEdit" v-model="coacreditado.consolidaIngresos" />
									</div>
									<div class="col-md-4">
										<span class="span-placeholder" for="">¿Participa en el crédito?</span>
										<vs-switch v-bind:disabled="isPersonalEdit" v-model="coacreditado.participaCredito" />
									</div> -->
								<!-- </div> -->

								<!-- <br />
								<button class="btn btn-second" @click="registraCoacreditado()" :disabled="!validate3">Añadir Coacreditado</button>
								<p v-if="!validate2" class="red">Completa la información marcada con *</p> -->
							<!-- </div>
						</div>
					</div>
				</div>
			</div> -->
			
		</vx-card>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import { nacionalidades } from '@/assets/jsonFiles/nacionalidades.js'
import { paisesNacimiento } from '@/assets/jsonFiles/paisesNacimiento.js'
export default {
	components: {
		'v-select': vSelect,
	},
	props: {
		conyugePersonalProps: {
			default: {}
		},
	},
	data() {
		return {
			adicionales: { // reorganizacion
				fechaSolicitud: null,
				lugarSolicitud: '',
				usoInmueble: '',
				tipoInmueble: '',
				titularidadDomicilio: '',
				tiempoOcupandoDomicilioActual: '0',
				tiempoOcupandoDomicilioActualMeses: '0',
				antiguedadCiudad: '0',
				antiguedadCiudadMeses: '0',
				antiguedadInmuebleAdquirir: '',
				antiguedadDomicilioAnterior: '',
				identificacionPresenta: '',
				numeroIfe: '',
				vigencia: null,
				nss: '',
				issste: '',
				fiel: '',
				formaEnganche: '',
				gradoEstudios: '',
				institucionEstudios: '',
				companiaCelular: '',
				telefonoFijo: '',
				totalGastosMensuales: '',
				montoPension: '',
				otroNombre: '',
				seguros: true,
				calleExtranjero: '',
				numeroExteriorExtranjero: '',
				numeroInteriorExtranjero: '',
				coloniaExtranjero: '',
				estadoExtranjero: '',
				paisExtranjero: ''
			},
			companiasCelular: [
				{name: 'Telcel'},
				{name: 'AT&T'},
				{name: 'Movistar'},
				{name: 'Unefon'},
				{name: 'Virgin Mobile'},
				{name: 'Freedompop'},
				{name: 'Flash Mobile'},
				{name: 'Weex'},
				{name: 'Cierto'},
				{name: 'Maz Tiempo'},
				{name: 'Oui móvil'},
				{name: 'Oxxo'},
				{name: 'Izzi móvil'},
				{name: 'Pillofon'},
				{name: 'BlueTelecomm'},
				{name: 'YO Mobile'},
				{name: 'Bait'},
				{name: 'Sky Celular'}
			],
			nssValido: true,
			gradosEstudio: [],
			formasEnganche: [],
			identificaciones: [],
			tiposInmuebles: [], 
			domicilioOcupaciones: [],
			usosInmuebles: [], 
			tipoUsoInmuebleScotabank: '',
			gradoEstudiosScotiabank: '', // reorganizacion
			paisesNac: [],
			maskRfc: ['AAAA999999&&&'],
			conyuge: {
				participaCredito: false,
				email: '',
				nombre: '',
				apellidoPaterno: '',
				apellidoMaterno: '',
				curp: '',
				rfc: '',
				esConyugue: true,
				seraCoPropietario: false,
				consolidaIngresos: false,
				parentesco: 1,
				estadoNacimiento: '',
				sexo: '',
				nacionalidad: '',

                calle: '',
                numeroExterior: '',
                numeroInterior: '',
                ciudad: '',
                estado: '',
                codigoPostal: '',
                municipio: '',
                colonia: '',
                tiempoViviendoCiudad: '',
                tiempoViviendoCiudadMeses: '',
                antiguedadEnCiudad: '',
                antiguedadEnCiudadMeses: '',

				viveMismoDomicilioTitular: false, // switch
			},
			coacreditado: {
				email: '',
				email2: '',
				email3: '', //existe coacreditado
				esConyugue: false,
				seraCoPropietario: true,
				consolidaIngresos: true,
				parentesco: '',
				participaCredito: true,
			},
			id: '',
			Id:'',
			key: '',
			email: '',
			nombre: '',
			segNombre: '',
			apellidoPaterno: '',
			apellidoMaterno: '',
			telefono: '',
			telefono2: '',
			fechaNacimiento: '',
			ingresosSinImpuestos: '',
			IngresosConImpuestos: '',
			estadoCivil: '',
			estadoCivilScotiabank: '',
			regimenMatrimonial: '',
			estadosCiviles: [],
			rfc: '',
			curp: '',
			sexo: '',
			calle: '',
			numeroInterior: '',
			numeroExterior: '',
			colonia: '',
			municipio: '',
			ColoniasCP: [],
			MunicipiosCP: [],
			ciudad: '',
			nacionalidad: '',
			estado: '',
			requiredByForeign: '',
			paisNacimiento:'',
			estadoNacimiento:'',
			municipioNacimiento:'',
			PaisRecidencia:'',
			isPersonalEdit: true,

			codigoPostal: '',
			estados: [],
			states: ['AGUASCALIENTES', 'BAJA CALIFORNIA NORTE', 'BAJA CALIFORNIA SUR', 'CAMPECHE', 'CHIAPAS', 'CHIHUAHUA', 'COAHUILA', 'COLIMA', 'CIUDAD DE MEXICO', 'DURANGO', 'ESTADO DE MÉXICO', 'GUANAJUATO', 'GUERRERO', 'HIDALGO', 'JALISCO', 'MICHOACAN', 'MORELOS', 'NAYARIT', 'NUEVO LEON', 'OAXACA', 'PUEBLA', 'QUERETARO', 'QUINTANA ROO', 'SAN LUIS POTOSI', 'SINALOA', 'SONORA', 'TABASCO', 'TAMAULIPAS', 'TLAXCALA', 'VERACRUZ', 'YUCATAN', 'ZACATECAS'],
			parentescos: [],
			regimenes: [],
			// nacionalidades: [
			// 	{ label: 'MEXICANA', value: 'MEXICANO' },
			// 	{ label: 'EXTRANJERA', value: 'EXTRANJERO' },
			// ],
			nacionalidades: [],

			codigoSeleccionado: '',
			codigoPaises: [],
			state_data: 1,
			AccreditedidV3 : false,
			Coacreditado : false,
			// header: {
			// 		"authorization": "Bearer 16JXvQ9CD1eEoVESY3-4d-bElrAAgAfcB0UgYwAB-XyuNugkcBvgntr6n6VjEAFWenS9Exi_jlLv_RBfmf004TXytHP64dNEuSyhlXOBDtO56hW07txWd4evNWfukK5CO1ETRIOk8GWyjjS7daQ8eLHUz3XefoKEHKE0yIy76o8rZZYTGskAn1Zo3oKcPFzfs_fmVlO15cD_Kxx27-1qqDBsfAphhY7N599ly5ppo5L4XHaL8JAv6mTxXVYxrTZPQDmnLqQ9Nwre7Bd4URM5rR3rVMrAVkPzJ8ohQ9-pgvigB-Fra_SMi31c5T-QrSTB"
			// 		},
			header: {},
			solicitanteV3 : '',
			SocGuid:'',
			BrokerId:'',
			LabelColoniaObligado: false,
			LabelColoniaNoObligado: true,
			LabelColoniaObligadoText: true,
			socData: '',

			hasCoacreditado: false,

			errorEmail: false,
			errorEmailCoacreditado: false,
			matchesEmail: false,
			matchesEmailCoacreditado: false,

			error_apm : false			
		}
	},
	computed: {
		validate: function () {		
			console.log("consola: Pais Nac = " + this.paisNacimiento)
			console.log( this.nacionalidad.Id)		

			/*if (this.paisNacimiento =="MÉXICO" || this.nacionalidad.id == 1) {
				return this.apellidoMaterno
			}*/



			
			// valida apeMaterno Conyuge manteniendo la condicion original
			let conyugeExtraVal = true;
			if (this.conyuge.nacionalidad == 'MEXICANO (A)' || !this.conyuge.nacionalidad) {
				this.conyuge.apellidoMaterno ? conyugeExtraVal = true : conyugeExtraVal = false
			} else {
				this.conyuge.apellidoMaterno ? conyugeExtraVal = true : conyugeExtraVal = true
			}

			//if (this.nacionalidad.Nombre == "MEXICANO (A)" && !this.apellidoMaterno) {
			if ( (this.paisNacimiento =="MÉXICO" || this.nacionalidad.Nombre == "MEXICANO (A)") && !this.apellidoMaterno) {						
				this.error_apm = true;				
				return false
			}
			else if( this.paisNacimiento !="MÉXICO" ||  this.nacionalidad.Nombre != "MEXICANO (A)"){				
				this.error_apm = false;			
			}	
			
			/*********************************************************************************************************************************************************************************************************************************** */
			if(this.estadoCivil == 1  && !this.regimenMatrimonial){
				return false
			}

			// ticket 101500
			else if ([1,4].includes(this.estadoCivil)  && [1,2,3].includes(this.regimenMatrimonial) && this.conyuge.esConyugue && !this.conyuge.participaCredito) {
				return true;
			}
			// ticket 101500

			else if (this.telefono2 != '' && (this.estadoCivil == 1 && this.regimenMatrimonial == 3) && (this.codigoPostal == '' || this.codigoPostal == null)) { // Casado - Sociedad Legal
				this.LabelColoniaObligado = false
				this.LabelColoniaNoObligado = true
				this.LabelColoniaObligadoText = false
				return this.conyuge.email != '' && this.matchesEmail && this.errorEmail == false && this.conyuge.nombre != '' && this.conyuge.apellidoPaterno != '' && conyugeExtraVal   && this.telefono2.length == 10
			}else if (this.telefono2 == '' && (this.estadoCivil == 1 && this.regimenMatrimonial == 3) && (this.codigoPostal == '' || this.codigoPostal == null)) { // Casado - Sociedad Legal
				this.LabelColoniaObligado = false
				this.LabelColoniaNoObligado = true
				this.LabelColoniaObligadoText = false
				return this.conyuge.email != '' && this.matchesEmail && this.errorEmail == false && this.conyuge.nombre != '' && this.conyuge.apellidoPaterno != '' && conyugeExtraVal  
			}else if (this.telefono2 != '' && (this.estadoCivil == 1 && this.regimenMatrimonial == 3) && (this.codigoPostal != '' || this.codigoPostal != null)) { // Casado - Sociedad Legal
				this.LabelColoniaObligado = true
				this.LabelColoniaNoObligado = false
				if (this.colonia) {
					this.LabelColoniaObligadoText = false
				}
				else
					this.LabelColoniaObligadoText = true
				return this.conyuge.email != '' && this.matchesEmail && this.errorEmail == false && this.conyuge.nombre != '' && this.conyuge.apellidoPaterno != '' && conyugeExtraVal   && this.colonia != '' && this.telefono2.length == 10
			}else if (this.telefono2 == '' && (this.estadoCivil == 1 && this.regimenMatrimonial == 3) && (this.codigoPostal != '' || this.codigoPostal != null)) { // Casado - Sociedad Legal
				this.LabelColoniaObligado = true
				this.LabelColoniaNoObligado = false
				if (this.colonia) {
					this.LabelColoniaObligadoText = false
				}
				else
					this.LabelColoniaObligadoText = true
				return this.conyuge.email != '' && this.matchesEmail && this.errorEmail == false && this.conyuge.nombre != '' && this.conyuge.apellidoPaterno != '' && conyugeExtraVal   && this.colonia != ''
			} 
			
			/*********************************************************************************************************************************************************************************************************************************** */
			
			else if (this.telefono2 != '' && (this.estadoCivil == 1 && this.regimenMatrimonial == 2) && (this.codigoPostal == '' || this.codigoPostal == null)) { // casado - Sociedad conyugal
				this.LabelColoniaObligado = false
				this.LabelColoniaNoObligado = true
				this.LabelColoniaObligadoText = false
				return this.conyuge.email != '' && this.matchesEmail && this.errorEmail == false && this.conyuge.nombre != '' && this.conyuge.apellidoPaterno != '' && this.telefono2.length == 10
			}else if (this.telefono2 == '' && (this.estadoCivil == 1 && this.regimenMatrimonial == 2) && (this.codigoPostal == '' || this.codigoPostal == null)) { // casado - Sociedad conyugal
				this.LabelColoniaObligado = false
				this.LabelColoniaNoObligado = true
				this.LabelColoniaObligadoText = false
				return this.conyuge.email != '' && this.matchesEmail && this.errorEmail == false && this.conyuge.nombre != '' && this.conyuge.apellidoPaterno != ''
			}else if (this.telefono2 != '' && (this.estadoCivil == 1 && this.regimenMatrimonial == 2) && (this.codigoPostal != '' || this.codigoPostal != null)) { // casado - Sociedad conyugal
				this.LabelColoniaObligado = true
				this.LabelColoniaNoObligado = false
				if (this.colonia) {
					this.LabelColoniaObligadoText = false
				}
				else
					this.LabelColoniaObligadoText = true
				return this.conyuge.email != '' && this.matchesEmail && this.errorEmail == false && this.conyuge.nombre != '' && this.conyuge.apellidoPaterno != '' && this.colonia != '' && this.telefono2.length == 10
			}else if (this.telefono2 == '' && (this.estadoCivil == 1 && this.regimenMatrimonial == 2) && (this.codigoPostal != '' || this.codigoPostal != null)) { // casado - Sociedad conyugal
				this.LabelColoniaObligado = true
				this.LabelColoniaNoObligado = false
				if (this.colonia) {
					this.LabelColoniaObligadoText = false
				}
				else
					this.LabelColoniaObligadoText = true
				return this.conyuge.email != '' && this.matchesEmail && this.errorEmail == false && this.conyuge.nombre != '' && this.conyuge.apellidoPaterno != '' && this.colonia != ''
			} 					

			/*********************************************************************************************************************************************************************************************************************************** */
			
			else if (this.telefono2 != '' && (this.estadoCivil == 1 && this.regimenMatrimonial == 1) && (this.codigoPostal == '' || this.codigoPostal == null)) { // casado - separación de bienes
				this.LabelColoniaObligado = false
				this.LabelColoniaNoObligado = true
				this.LabelColoniaObligadoText = false
				return this.conyuge.email != '' && this.matchesEmail && this.errorEmail == false && this.conyuge.nombre != '' && this.conyuge.apellidoPaterno != '' && this.telefono2.length == 10
			}else if (this.telefono2 == '' && (this.estadoCivil == 1 && this.regimenMatrimonial == 1) && (this.codigoPostal == '' || this.codigoPostal == null)) { // casado - separación de bienes
				this.LabelColoniaObligado = false
				this.LabelColoniaNoObligado = true
				this.LabelColoniaObligadoText = false
				return this.conyuge.email != '' && this.matchesEmail && this.errorEmail == false && this.conyuge.nombre != '' && this.conyuge.apellidoPaterno != ''
			}else if (this.telefono2 != '' && (this.estadoCivil == 1 && this.regimenMatrimonial == 1) && (this.codigoPostal != '' || this.codigoPostal != null)) { // casado - separación de bienes
				this.LabelColoniaObligado = true
				this.LabelColoniaNoObligado = false
				if (this.colonia) {
					this.LabelColoniaObligadoText = false
				}
				else
					this.LabelColoniaObligadoText = true
				return this.conyuge.email != '' && this.matchesEmail && this.errorEmail == false && this.conyuge.nombre != '' && this.conyuge.apellidoPaterno != '' && this.colonia != '' && this.telefono2.length == 10
			}else if (this.telefono2 == '' && (this.estadoCivil == 1 && this.regimenMatrimonial == 1) && (this.codigoPostal != '' || this.codigoPostal != null)) { // casado - separación de bienes
				this.LabelColoniaObligado = true
				this.LabelColoniaNoObligado = false
				if (this.colonia) {
					this.LabelColoniaObligadoText = false
				}
				else
					this.LabelColoniaObligadoText = true
				return this.conyuge.email != '' && this.matchesEmail && this.errorEmail == false && this.conyuge.nombre != '' && this.conyuge.apellidoPaterno != '' && this.colonia != ''
			} 
			
			/*********************************************************************************************************************************************************************************************************************************** */
			
			else if (this.telefono2 != '' && (this.estadoCivil == 4) && (this.codigoPostal == '' || this.codigoPostal == null)) { // Unión Libre				
				this.LabelColoniaObligado = false
				this.LabelColoniaNoObligado = true
				this.LabelColoniaObligadoText = false
				//return this.conyuge.email != '' && this.matchesEmail && this.errorEmail == false && this.conyuge.nombre != '' && this.conyuge.apellidoPaterno != '' && conyugeExtraVal && this.telefono2.length == 10
				return this.matchesEmail && this.errorEmail == false  && this.telefono2.length == 10
			}else if (this.telefono2 == '' && (this.estadoCivil == 4) && (this.codigoPostal == '' || this.codigoPostal == null)) { // Unión Libre				
				this.LabelColoniaObligado = false
				this.LabelColoniaNoObligado = true
				this.LabelColoniaObligadoText = false
				return  this.matchesEmail && this.errorEmail == false 
			}else if (this.telefono2 != '' && (this.estadoCivil == 4) && (this.codigoPostal != '' || this.codigoPostal != null)) { // Unión Libre				
				this.LabelColoniaObligado = true
				this.LabelColoniaNoObligado = false
				if (this.colonia) {
					this.LabelColoniaObligadoText = false
				}
				else
					this.LabelColoniaObligadoText = true
				return  this.matchesEmail && this.errorEmail == false && this.telefono2.length == 10
			}else if (this.telefono2 == '' && (this.estadoCivil == 4) && (this.codigoPostal != '' || this.codigoPostal != null)) { // Unión Libre				
				this.LabelColoniaObligado = true
				this.LabelColoniaNoObligado = false
				if (this.colonia) {
					this.LabelColoniaObligadoText = false
				}
				else
					this.LabelColoniaObligadoText = true
				return  this.matchesEmail && this.errorEmail == false && this.colonia != ''
			}else if (this.telefono2 != '' && (this.codigoPostal == '' || this.codigoPostal == null)) {
				this.LabelColoniaObligado = false
				this.LabelColoniaNoObligado = true
				this.LabelColoniaObligadoText = false
				return this.telefono2.length == 10
			}else if (this.telefono2 == '' && (this.codigoPostal == '' || this.codigoPostal == null)) {
				this.LabelColoniaObligado = false
				this.LabelColoniaNoObligado = true
				this.LabelColoniaObligadoText = false
				return true
			}else if(this.telefono2 != '' && (this.codigoPostal != '' || this.codigoPostal != null)) {
				this.LabelColoniaObligado = true
				this.LabelColoniaNoObligado = false
				if (this.colonia) {
				this.LabelColoniaObligadoText = false
				}
				else
				this.LabelColoniaObligadoText = true

				return this.colonia != '' && this.telefono2.length == 10
			}else if(this.telefono2 == '' && (this.codigoPostal != '' || this.codigoPostal != null)) {
				this.LabelColoniaObligado = true
				this.LabelColoniaNoObligado = false
				if (this.colonia) {
				this.LabelColoniaObligadoText = false
				}
				else{
					this.LabelColoniaObligadoText = true
				}				
				return this.colonia != ''
			}else if(this.telefono2 != '') {
				return this.telefono2.length == 10
			}else {
				return true
			}
			
		},
		validate2: function () {
			return this.coacreditado.email != '' && this.coacreditado.parentesco != ''
		},
		validate3: function () {
			return this.coacreditado.email != '' && this.coacreditado.parentesco != '' && this.isPersonalEdit == false && this.errorEmailCoacreditado == false && this.matchesEmailCoacreditado
		},
		totalMensuales() {
			return this.adicionales.totalGastosMensuales;
		},
		pension() {
			return this.adicionales.montoPension;
		}
	},
	watch: {
		// Formato mayusculas sin acento
		nombre: function(newValue, oldValue) {
			this.nombre = this.textFormat(newValue);
		},
		segNombre: function(newValue, oldValue) {
			this.segNombre = this.textFormat(newValue);
		},
		apellidoPaterno: function(newValue, oldValue) {
			this.apellidoPaterno = this.textFormat(newValue);
		},
		apellidoMaterno: function(newValue, oldValue) {
			this.apellidoMaterno = this.textFormat(newValue);
		},
		'adicionales.nss': function(newValue, oldValue) {
			let regex = /^[0-9]{11}$/;
			if (!regex.test(newValue)) {
				this.nssValido = false;
			} else {
				this.nssValido = true;
			}
		},
		nacionalidad: function(newValue, oldValue) {
			if (newValue.Nombre == "MEXICANO (A)") {
				this.requiredByForeign = 'required|';
				// console.log( 'MEXICANO', newValue.Nombre, this.requiredByForeign );
				// La libreria v-validate no es reactiva, solo escucha eventos
			} else {
				// console.log( 'OTRO', newValue.Nombre, this.requiredByForeign );
				this.requiredByForeign = '';
			}
		},
		regimenMatrimonial: function () {
			this.emitPersonalGeneral(); // actualiza props conyuge
		},
		validate: function(val) {
			// this.emitPersonalGeneral(); // listen validate
		},
		conyugePersonalProps: {
			deep: true, // revisa cambios en cada prop del objeto
			handler: function() {
				// console.log( 'PersonalesGeneral.vue obj conyuge', this.conyugePersonalProps );

				// actualiza demas props de conyuge
				this.conyuge.email = this.conyugePersonalProps.email;
				this.conyuge.nombre = this.conyugePersonalProps.nombre;
				this.conyuge.apellidoPaterno = this.conyugePersonalProps.apellidoPaterno;
				this.conyuge.apellidoMaterno = this.conyugePersonalProps.apellidoMaterno;
				this.conyuge.curp = this.conyugePersonalProps.curp;
				this.conyuge.rfc = this.conyugePersonalProps.rfc;
				this.conyuge.estadoNacimiento = this.conyugePersonalProps.estadoNacimiento;
				this.conyuge.nacionalidad = this.conyugePersonalProps.nacionalidad;
				this.conyuge.sexo = this.conyugePersonalProps.sexo;
				this.conyuge.fechaNacimiento = this.conyugePersonalProps.fechaNacimiento;
				this.conyuge.paisRecidencia = this.conyugePersonalProps.paisRecidencia;

				// campos nuevos conyuge
				this.conyuge.calle = this.conyugePersonalProps.calle;
				this.conyuge.numeroExterior = this.conyugePersonalProps.numeroExterior;
				this.conyuge.numeroInterior = this.conyugePersonalProps.numeroInterior;
				this.conyuge.ciudad = this.conyugePersonalProps.ciudad;
				this.conyuge.estado = this.conyugePersonalProps.estado;
				this.conyuge.codigoPostal = this.conyugePersonalProps.codigoPostal;
				this.conyuge.municipio = this.conyugePersonalProps.municipio;
				this.conyuge.colonia = this.conyugePersonalProps.colonia;
				this.conyuge.tiempoViviendoCiudad = this.conyugePersonalProps.tiempoViviendoCiudad;
				this.conyuge.tiempoViviendoCiudadMeses = this.conyugePersonalProps.tiempoViviendoCiudadMeses;
				this.conyuge.antiguedadEnCiudad = this.conyugePersonalProps.antiguedadEnCiudad;
				this.conyuge.antiguedadEnCiudadMeses = this.conyugePersonalProps.antiguedadEnCiudadMeses;

				this.conyuge.viveMismoDomicilioTitular = this.conyugePersonalProps.viveMismoDomicilioTitular;

				// valida correo
				this.validaCorreoSolicitante(this.conyugePersonalProps.identidad);
			}
		},
		conyuge: { // propiedad local
			deep: true,
			handler: function() {
				this.emitPersonalGeneral(); // emitir de vuelta cada vez que exista cambio
			}
		},
		estadoCivil: function () {
			if (this.estadoCivil == 2 || this.estadoCivil == 3 || this.estadoCivil == 5 || this.estadoCivil == 6) {
				this.regimenMatrimonial = ''
				this.conyuge.participaCredito = false
				this.conyuge.email = ''
				this.conyuge.nombre = ''
				this.conyuge.apellidoPaterno = ''
				this.conyuge.apellidoMaterno = ''
				this.conyuge.curp = ''
				this.conyuge.rfc = ''
				this.conyuge.esConyugue = true
				this.conyuge.seraCoPropietario = true
				this.conyuge.consolidaIngresos = true
				this.conyuge.parentesco = 1
				this.conyuge.fechaNacimiento = ''
				this.conyuge.sexo = ''
				this.conyuge.nacionalidad = ''
				this.conyuge.paisRecidencia = ''
			}
			this.emitPersonalGeneral(); // actuliza props conyuge
		},
		totalMensuales: function(newValue) {
			if (this.adicionales.totalGastosMensuales != null ) {
				this.adicionales.totalGastosMensuales= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
    	},
		pension: function(newValue) {
			if (this.adicionales.montoPension != null ) {
				this.adicionales.montoPension = newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
		},
	},
	mounted() {
		this.id = this.$route.params.id
		this.getNacionalidades()
		this.getPaisesNacimiento()
		this.getApikey()
		this.dameCaso()
		this.getUsoInmueble();
		this.getTiposInmuebles();
		this.getListaDomicilioOcupaciones();
		this.getIdentificaciones();
		this.getFormasEnganche();
		this.getGradosEstudio();
		this.getEstados()
		this.getEstadosCiviles()
		this.getListaRegimenes()
		this.getListaParentescos()
		this.getListaCodigoPaises() //sms
		this.DameCredenciales()	
		
		this.$root.$refs.PersonalGeneralMethods = this; // acceso externo metodos
	},
	methods: {
		// Conversion
		textFormat(strValue) {
			return strValue.normalize('NFD')
							.replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
							.normalize()
							.toUpperCase(); // Eliminar acentos menos Ññ
		},
		async getAuthToken() {
			let objRequestToken = {
				strApiKey: this.key,
				strMetodo: 'DameAuthToken',
			}
			await this.$axios.post('/', objRequestToken,
			{ headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
			.then( response => {
			if (!response.data.error) {
				this.header = { "Authorization" : "Bearer "+ response.data.access_token};
			} else {
				this.$vs.notify({
				title: 'Ocurrio un error al obtener token',
				text: response.data.error_description,
				color: 'danger',
				position: 'top-right',
				})
			}
			}).catch(function (error) {
			self.$vs.notify({
				title: 'Ocurrio un error de sistema',
				text: error,
				color: 'danger',
				position: 'top-right',
			})
			});
		},
		emitPersonalGeneral() {
			let personalConyuge = {
				conyuge: this.conyuge, // las props disponibles
				estadoCivil: this.estadoCivil,
				regimenMatrimonial: this.regimenMatrimonial,
				validate: this.validate,
				errorEmail: this.errorEmail
			}
			this.$emit( 'personalGeneralProps', personalConyuge ) // emitir data
			// console.log( 'PersonalesGeneral.vue dataconyuge', personalConyuge );
		},
		getGradosEstudio() {
			let self = this;
			let objRequestListaEscolaridades = {
				strApiKey: this.key,
				strMetodo: 'ListaEscolaridades',
			}
			this.$axios.post('/',objRequestListaEscolaridades,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if (response.data.intEstatus == 0) {
						this.gradosEstudio = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaEscolaridades',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right'
						})
					}
				}
			).catch(function (error) {
				self.$vs.notify({
					title: 'Ocurrio un error de sistema',
					text: error,
					color: 'danger',
					position: 'top-right'
				});
			})
		},
		getUsoInmueble() {
			let self=this
			let objRequestListaUsoInmuebles = {
				strApiKey: this.key,
				strMetodo: 'ListaUsoInmuebles',
			}
			this.$axios.post('/',objRequestListaUsoInmuebles,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if (response.data.intEstatus == 0) {
						this.usosInmuebles = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaUsoInmuebles',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right'
						})
					}
				}
			).catch(function (error) {
				self.$vs.notify({
					title: 'Ocurrio un error de sistema',
					text: error,
					color: 'danger',
					position: 'top-right'
				})
			})
		},
		getTiposInmuebles() {
			let self=this
			let objRequestListaTipoInmuebles = {
				strApiKey: this.key,
				strMetodo: 'ListaTipoInmuebles',
			}
			this.$axios.post('/',objRequestListaTipoInmuebles,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if (response.data.intEstatus == 0) {
						this.tiposInmuebles = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaTipoInmuebles',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right'
						});
					}
				}
			).catch(function (error) {
				self.$vs.notify({
					title: 'Ocurrio un error de sistema',
					text: error,
					color: 'danger',
					position: 'top-right'
				})
			})
		},
		getListaDomicilioOcupaciones() {
			let self=this
			let objRequestListaDomicilioOcupaciones = {
				strApiKey: this.key,
				strMetodo: 'ListaDomicilioOcupaciones',
			}
			this.$axios.post('/',objRequestListaDomicilioOcupaciones,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if (response.data.intEstatus == 0){
						this.domicilioOcupaciones = response.data.objContenido;
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaDomicilioOcupaciones',
							text: response.data.strError,
							color:'danger',
              				position:'top-right'
            			})
          			}
        		}).catch(function (error) {
					self.$vs.notify({
					title: 'Ocurrio un error de sistema',
					text: error,
					color: 'danger',
          			position: 'top-right'
				})
			})
		},
		getIdentificaciones() {
			let self=this
			let objRequestListaTipoIdentificaciones = {
				strApiKey: this.key,
				strMetodo: 'ListaTipoIdentificaciones',
			}
			this.$axios.post('/',objRequestListaTipoIdentificaciones,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
						let identificacionList = response.data.objContenido;
						identificacionList = identificacionList.filter(identi => identi.Id != 7);
						this.identificaciones = identificacionList;
						// 2023-05-04 Mejora en identificacion
						// this.identificaciones=response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaTipoIdentificaciones',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right'
						})
					}
				})
			.catch(function (error) {
				self.$vs.notify({
					title: 'Ocurrio un error de sistema',
					text: error,
					color: 'danger',
					position: 'top-right'
				})
			})
		},
		getFormasEnganche() {
			let self = this;
			let objRequestListaFormasEnganche = {
				strApiKey: this.key,
				strMetodo: 'ListaFormasEnganche',
			}
			this.$axios.post('/',objRequestListaFormasEnganche,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if (response.data.intEstatus == 0) {
						this.formasEnganche = response.data.objContenido;
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaFormasEnganche',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right'
						})
					}
				}
			).catch(function (error) {
				self.$vs.notify({
					title: 'Ocurrio un error de sistema',
					text: error,
					color: 'danger',
					position: 'top-right'
				})
			})
		},
		registraAdicionales() {
			let self = this;

			// this.$vs.loading({
			// 	container: '#solGenPer',
			// 	scale: 0.6
			// });

			if (this.adicionales.titularidadDomicilio=='1') {
				this.tipoUsoInmuebleScotabank = 'R';
			} else if(this.adicionales.titularidadDomicilio=='2') {
				this.tipoUsoInmuebleScotabank = 'P';
			} else if(this.adicionales.titularidadDomicilio=='3') {
				this.tipoUsoInmuebleScotabank = 'H';
			} else if(this.adicionales.titularidadDomicilio=='4') {
				this.tipoUsoInmuebleScotabank = 'F';
			} else if(this.adicionales.titularidadDomicilio=='5') {
				this.tipoUsoInmuebleScotabank = 'O';
			} else if(this.adicionales.titularidadDomicilio=='6') {
				this.tipoUsoInmuebleScotabank = 'O';
			}
			
			if(this.adicionales.gradoEstudios == '1') {
				this.gradoEstudiosScotiabank='O';
			} else if(this.adicionales.gradoEstudios == '2') {
				this.gradoEstudiosScotiabank = 'O';
			} else if(this.adicionales.gradoEstudios == '3') {
				this.gradoEstudiosScotiabank = 'O';
			} else if(this.adicionales.gradoEstudios == '4') {
				this.gradoEstudiosScotiabank = 'H';
			} else if(this.adicionales.gradoEstudios == '5') {
				this.gradoEstudiosScotiabank = 'O';
			} else if(this.adicionales.gradoEstudios == '6' ) {
				this.gradoEstudiosScotiabank = 'G';
			} else if(this.adicionales.gradoEstudios == '7') {
				this.gradoEstudiosScotiabank = 'H';
			} else if(this.adicionales.gradoEstudios == '8') {
				this.gradoEstudiosScotiabank = 'G';
			} else if(this.adicionales.gradoEstudios == '9') {
				this.gradoEstudiosScotiabank = 'G';
			} else if(this.adicionales.gradoEstudios == '10') {
				this.gradoEstudiosScotiabank = 'S';
			}

			let objRequestAdicionales = {
				strApiKey: this.key,
				strMetodo: 'RegistraAdicionalesSolicitud',
				objCaso: {
					Id: this.id,
					Personales: {
						IdentificacionPresenta: this.adicionales.identificacionPresenta,
						NumeroIdentificacion: this.adicionales.numeroIfe,
						VigenciaIdentificacion: this.adicionales.vigencia,
						Nss: this.adicionales.nss, // sin modificar api
						Issste: this.adicionales.issste,
						Fiel: this.adicionales.fiel,
						CompaniaCelular: this.adicionales.companiaCelular,
						TelefonoFijo: this.adicionales.telefonoFijo,
						TotalGastosMensuales: parseFloat(this.adicionales.totalGastosMensuales.replace(/,/g,"")),
						MontoPension: parseFloat(this.adicionales.montoPension.replace(/,/g,"")),
						SeguroBancario: this.adicionales.seguros,
						CalleExtranjero: this.adicionales.calleExtranjero,
						NumeroExteriorExtranjero: this.adicionales.numeroExteriorExtranjero,
						NumeroInteriorExtranjero: this.adicionales.numeroInteriorExtranjero,
						ColoniaExtranjero: this.adicionales.coloniaExtranjero,
						CodigoPostalExtranjero: this.adicionales.codigoPostalExtranjero,
						EstadoExtranjero: this.adicionales.estadoExtranjero,
						PaisExtranjero: {
							name: this.adicionales.paisExtranjero,
							noSanitiza: true,
						},
					},
					Estudios: {
						GradoEstudios: this.adicionales.gradoEstudios,
						InstitucionEstudios: this.adicionales.institucionEstudios,
						GradoEstudiosScotiabank: this.gradoEstudiosScotiabank
					},
					Nacionalidad: {
						OtroNombre: this.adicionales.otroNombre,
					},
					DomicilioActual: {
						TitularidadDomicilio: this.adicionales.titularidadDomicilio,
						TitularidadDomicilioScotiabank: this.tipoUsoInmuebleScotabank,
						TiempoOcupandoDomicilioActual: this.adicionales.tiempoOcupandoDomicilioActual,
						TiempoViviendoCiudadMeses: this.adicionales.tiempoOcupandoDomicilioActualMeses,
						AntiguedadCiudad: this.adicionales.antiguedadCiudad,
						AntiguedadEnCiudadMeses: this.adicionales.antiguedadCiudadMeses,
						AntiguedadDomicilioAnterior: this.adicionales.antiguedadDomicilioAnterior,
					},
					InmuebleAdquirir:{
						FechaSolicitud: this.adicionales.fechaSolicitud,
						LugarSolicitud: this.adicionales.lugarSolicitud,
						UsoInmueble: this.adicionales.usoInmueble,
						TipoInmueble: this.adicionales.tipoInmueble,
						AntiguedadInmuebleAdquirir: this.adicionales.antiguedadInmuebleAdquirir,
						FormaEnganche: this.adicionales.formaEnganche,
					}
				}
			}

			// console.log( 'registraAdicional', objRequestAdicionales );
			
			this.$axios.post('/', objRequestAdicionales, { headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(response => {
				if(response.data.intEstatus == 0) {
					this.$vs.notify({
						title:`Guardardo exitosamente`,
						position:'top-right'
					});
					
					// this.registraAseguradora() no es necesario
					this.dameCaso();
					// this.dameSolicitante(); ya se realiza dentro de dame caso
					// if (this.AccreditedidV3) { // se hace en el metodo actualizarInfo() en agregaConyuge
					//   this.generaInformacionParaV3(); 
					// }
				} else {
					this.$vs.notify({
						title: 'Ocurrio un error en RegistraAdicionalesSolicitud',
						text: response.data.strError,
						color: 'danger',
						position: 'top-right'
					});
				}
			}).catch(function (error) {
				self.$vs.notify({
					title: 'Ocurrio un error de sistema',
					text: error,
					color: 'danger',
					position: 'top-right'
				});
			});
		},
		validaCorreoSolicitante(identidad){

			switch (identidad) {
				case 'Coacreditado':
							const regexCoac = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
							this.matchesEmailCoacreditado = regexCoac.test(this.coacreditado.email);


								if (this.coacreditado.email == this.email) {
									this.errorEmailCoacreditado = true		
								}else{
									this.errorEmailCoacreditado = false		
								}
					break;

				case 'conyuge':						
						if(this.estadoCivil == 1){
							const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
							this.matchesEmail = regex.test(this.conyuge.email);


							if (this.conyuge.email == this.email) {
								this.errorEmail = true
								this.matchesEmail = false
							}else if(this.conyuge.email != this.email && this.matchesEmail){
								this.errorEmail = false
							}else if(this.conyuge.email != this.email && !this.matchesEmail){
								this.errorEmail = false
							} else{
								this.errorEmail = false
							}
						}
						
				break;

				case 'inicio':				
					let email = ''
					this.matchesEmail = true
					this.errorEmail = false

					
					if (this.conyuge.email) 
							email = this.conyuge.email
					else if(this.coacreditado.email)
							email = this.coacreditado.email

					if (email) {
							const regexInicio = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
								this.matchesEmail = regexInicio.test(email);

								if (email == this.email) {
									this.errorEmail = true
									this.matchesEmail = false
								}else if(email != this.email && this.matchesEmail){
									this.errorEmail = false
								}else if(email != this.email && !this.matchesEmail){
									this.errorEmail = false
								} else{
									this.errorEmail = false
								}				 
						}

				break;
			
				default:
					break;
			}
			console.log("matches = "+ this.matchesEmail)
			console.log("errorEmail = "+ this.errorEmail)
		},
		actualiza_bandera: function(Origen, status) {
			this.$emit('actualiza_bandera', Origen, status)
		},
		getPaisesNacimiento() {
			this.paisesNac = paisesNacimiento;
		},
		getNacionalidades() {
			let nacionalidadesList = [];
			let index = 3;
			for (const nacionalidad of nacionalidades) {
				if (nacionalidad.Nombre === "MEXICANO (A)") {
					nacionalidadesList[0] = nacionalidad;
					continue;
				}
				if (nacionalidad.Nombre === "NORTEAMERICANO (A)") {
					nacionalidadesList[1] = nacionalidad;
					continue;
				}
				if (nacionalidad.Nombre === "CANADIENSE") {
					nacionalidadesList[2] = nacionalidad;
					continue;
				}
				nacionalidadesList[index] = nacionalidad;
				index ++;
			}
			this.nacionalidades = nacionalidadesList;
		},
		toggleSave() {
			this.isPersonalEdit = !this.isPersonalEdit;

			// const fieldList = document.querySelectorAll('.toggleStyle');
			// if (this.isPersonalEdit) {
			// 	fieldList.forEach( field => {
			// 		field.classList.remove('bg-toggle-enable');
			// 		field.classList.add('bg-toggle-disable');
			// 	});
			// } else {
			// 	fieldList.forEach( field => {
			// 		field.classList.remove('bg-toggle-disable');
			// 		field.classList.add('bg-toggle-enable');
			// 	});
			// }
		},
		soloLetras: function (event) {
			var regex = new RegExp('^[áéíóúüña-zÑÁÉÍÓÚA-Z ]+$')
			var key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
			if (!regex.test(key)) {
				event.preventDefault()
				return false
			}
		},
		isNumber: function (evt) {
			evt = evt ? evt : window.event
			var charCode = evt.which ? evt.which : evt.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
				evt.preventDefault()
			} else {
				return true
			}
		},
		SoloNumero: function(evt){
			evt = evt ? evt : window.event
			var charCode = evt.which ? evt.which : evt.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57)) {
				evt.preventDefault()
			} else {
				return true
			}
		},
		actualizaCodigo() {
			this.codigoPais = this.codigoSeleccionado
		},
		getListaCodigoPaises() {
			var objRequestListaPais = {
				strApiKey: this.key,
				strMetodo: 'ListaPaisLada',
			}
			this.$axios
				.post('/', objRequestListaPais, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.codigoPaises = response.data.objContenido

						/*console.log(this.codigoPaises)
						this.codigoPaises.splice(37,1);  // menos CANADA
						this.codigoPaises.splice(139,1);  // Menos Mexico
						this.codigoPaises.splice(213,1);  // menos USA
						this.codigoPaises.splice(0,0,{"Id":"132","Lada":"52","Pais":"México"});
						this.codigoPaises.splice(1,0,{"Id":"223","Lada":"1","Pais":"USA"});
						this.codigoPaises.splice(2,0,{"Id":"224","Lada":"1","Pais":"Canadá"});*/
						
						/**
						 *  this.codigoPaises.splice(0,1);
							this.codigoPaises.push({"Id":"f9e44faa-9986-49b9-a36c-f567051b3876","Name":"Otros"})
							this.codigoPaises.splice(0,1);
						 */
					}
				})
				.catch(function (error) {
					this.makeToast(error)
				})
		},
		dameCoacreditadoRegistrado() {
			let self = this
			var objRequestDameSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'DameSolicitante',
				objSolicitante: {
					EMail: this.coacreditado.email,
				},
			}
			this.$axios
				.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.coacreditado.email3 = this.coacreditado.email
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		registraCoacreditado() {
			let self = this

			if (this.coacreditado.email == this.conyuge.email && (this.coacreditado.email != this.email)) {
				let objRequestRegistraSolicitante = {
					strFolioCaso: this.id,
					strApiKey: this.key,
					strMetodo: 'RegistraSolicitante',
					objSolicitante: {
						EMail: this.conyuge.email,
						Nombres: this.conyuge.nombre,
						ApellidoPaterno: this.conyuge.apellidoPaterno,
						ApellidoMaterno: this.conyuge.apellidoMaterno,
						TelefonoCelular: '',
						FechaNacimiento: '',
						EstadoCivil: 1,
					},
				}
				this.$axios
					.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
					.then((response) => {
						if (response.data.intEstatus == 0) {
							this.agregarCoacreditado()
						} else {
							this.$vs.notify({
								title: 'Ocurrio un error en RegistraSolicitante',
								text: response.data.strError,
								color: 'danger',
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						self.$vs.notify({
							title: 'Ocurrio un error de sistema',
							text: error,
							color: 'danger',
							position: 'top-right',
						})
					})
			} else if (this.coacreditado.email3 != '') {
				this.agregarCoacreditado()
			} else {
				if (this.coacreditado.email2) {
					this.agregarCoacreditado()
				} else {
					var civil = ''
					if (this.coacreditado.parentesco == 1) {
						civil = 1
					}
					let objRequestRegistraSolicitante = {
						strFolioCaso: this.id,
						strApiKey: this.key,
						strMetodo: 'RegistraSolicitante',
						objSolicitante: {
							EMail: this.coacreditado.email,
							Nombres: '',
							ApellidoPaterno: '',
							ApellidoMaterno: '',
							TelefonoCelular: '',
							FechaNacimiento: '',
							EstadoCivil: civil,
						},
					}
					this.$axios
						.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
						.then((response) => {
							if (response.data.intEstatus == 0) {
								this.agregarCoacreditado()
							} else {
								this.$vs.notify({
									title: 'Ocurrio un error en RegistraSolicitantesss',
									text: response.data.strError,
									color: 'danger',
									position: 'top-right',
								})
							}
						})
						.catch(function (error) {
							self.$vs.notify({
								title: 'Ocurrio un error de sistema',
								text: error,
								color: 'danger',
								position: 'top-right',
							})
						})
				}
			}
		},
		agregarCoacreditado() {
			let self = this
			var objRequestRegistraCoacreditado = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraCoacreditado',
				objCaso: {
					Id: this.id,
					EMail: this.coacreditado.email,
					DatosCoacreditado: {
						EsConyuge: this.coacreditado.esConyugue,
						SeraCoPropietario: this.coacreditado.seraCoPropietario,
						ConsolidaIngresos: this.coacreditado.consolidaIngresos,
						Parentesco: this.coacreditado.parentesco,
						ParticipaCredito: this.coacreditado.participaCredito,
					},
				},
			}
			this.$axios
				.post('/', objRequestRegistraCoacreditado, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {

						if (this.AccreditedidV3) { //si el solicitante cuenta con pedidos en v3 entonces actualiza inf en liga cliente.
							this.generaInformacionParaV3();
						}
						
						this.hasCoacreditado = true
						this.$emit('changeCoacreditado', this.hasCoacreditado)

						this.$vs.notify({
							title: `Datos guardados exitosamente`,
							position: 'top-right',
						})	
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraCoacreditado',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		agregaConyugeAsCoacreditado() {
			let self = this
			var objRequestRegistraCoacreditado = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraCoacreditado',
				objCaso: {
					Id: this.id,
					EMail: this.conyuge.email,
					DatosCoacreditado: {
						EsConyuge: this.conyuge.esConyugue,
						SeraCoPropietario: this.conyuge.seraCoPropietario,
						ConsolidaIngresos: this.conyuge.consolidaIngresos,
						Parentesco: this.conyuge.parentesco,
						ParticipaCredito: this.conyuge.participaCredito,
					},
				},
			}
			this.$axios
				.post('/', objRequestRegistraCoacreditado, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Datos guardados exitosamente`,
							position: 'top-right',
						})

						this.$emit('changeCoacreditado', this.hasCoacreditado)
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraCoacreditado',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		agregaConyuge() {
			
			if (this.conyuge.email != '') {
				let self = this
				let sexoConyuge
				if (this.conyuge.sexo == 'H') {
					sexoConyuge = 1
				} else {
					sexoConyuge = 2
				}

					let conyCalle
					let conyNumeroExterior
					let conyNumeroInterior
					let conyColonia
					let conyMunicipio
					let conyCiudad
					let conyEstado
					let conyCodigoPostal

				if(this.conyuge.viveMismoDomicilioTitular==true){
					conyCalle = this.calle,
					conyNumeroExterior = this.numeroExterior,
					conyNumeroInterior = this.numeroInterior,
					conyColonia =this.colonia,
					conyMunicipio =this.municipio,
					conyCiudad =this.ciudad,
					conyEstado =this.estado,
					conyCodigoPostal =this.codigoPostal

				}else{
						
						conyCalle = this.calle,
						conyNumeroExterior = this.numeroExterior,
						conyNumeroInterior = this.numeroInterior,
						conyColonia =this.colonia,
						conyMunicipio =this.municipio,
						conyCiudad =this.ciudad,
						conyEstado =this.estado,
						conyCodigoPostal =this.codigoPostal
				}

				let objRequestRegistraSolicitante = {
					strFolioCaso: this.id,
					strApiKey: this.key,
					strMetodo: 'RegistraSolicitante',
					objSolicitante: {
						EMail: this.conyuge.email,
						Nombres: this.conyuge.nombre,
						ApellidoPaterno: this.conyuge.apellidoPaterno,
						ApellidoMaterno: this.conyuge.apellidoMaterno,
						TelefonoCelular: '',
						FechaNacimiento: this.conyuge.fechaNacimiento,
						Rfc: this.conyuge.rfc,
						Curp: this.conyuge.curp,
						Sexo: sexoConyuge,
						// Nacionalidad: this.conyuge.nacionalidad,
						Nacionalidad: {
							name: this.conyuge.nacionalidad,
							noSanitiza: true,
						},
						PaisRecidencia: this.conyuge.paisRecidencia,
						EstadoNacimiento: this.conyuge.estadoNacimiento,
						

						// nuevos campos conyuge
				   		Calle: conyCalle,
						NumeroExterior: conyNumeroExterior,
						NumeroInterior: conyNumeroInterior,
						Ciudad: conyCiudad,
						Estado:conyEstado,
						CodigoPostal: conyCodigoPostal,
						Municipio: conyMunicipio,
						Colonia: conyColonia,
						TiempoViviendoCiudad: this.conyuge.tiempoViviendoCiudad,
						TiempoViviendoCiudadMeses: this.conyuge.tiempoViviendoCiudadMeses,
						AntiguedadEnCiudad: this.conyuge.antiguedadEnCiudad,
						AntiguedadEnCiudadMeses: this.conyuge.antiguedadEnCiudadMeses,

						/////ViveMismoDomicilioTitular: this.conyuge.viveMismoDomicilioTitular, // switch conyuge 
					},
				}
				this.$axios
					.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
					.then((response) => {
						if (response.data.intEstatus == 0) {
							
							const esConyugue = this.conyuge.esConyugue
							const seraCoPropietario = this.conyuge.seraCoPropietario
							const participaCredito = this.conyuge.participaCredito

							this.hasCoacreditado = ( esConyugue && seraCoPropietario ) || participaCredito

							this.hasCoacreditado &&	this.agregaConyugeAsCoacreditado()							
							 this.actualizarInfo()

						} else {
							this.$vs.notify({
								title: 'Ocurrio un error en RegistraSolicitante',
								text: response.data.strError,
								color: 'danger',
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						self.$vs.notify({
							title: 'Ocurrio un error de sistema',
							text: error,
							color: 'danger',
							position: 'top-right',
						})
					})
			} else {
				this.actualizarInfo()
			}
		},
		actualizarInfo() {

			// Estado de nacimiento para Scotiabank
			let edoNacimiento = this.estados.find(edo => edo.Id === this.estadoNacimiento)

			let self = this
			let sexo
			if (this.sexo == 'H') {
				sexo = 1
			} else if (this.sexo == 'M') {
				sexo = 2
			} else{
				sexo = null
			}
			

			if (this.estadoCivil == '2') {
				this.estadoCivilScotiabank = 'D'
			} else if (this.estadoCivil == '1') {
				this.estadoCivilScotiabank = 'M'
			} else if (this.estadoCivil == '6') {
				this.estadoCivilScotiabank = 'P'
			} else if (this.estadoCivil == '3') {
				this.estadoCivilScotiabank = 'S'
			} else if (this.estadoCivil == '4') {
				this.estadoCivilScotiabank = 'U'
			} else if (this.estadoCivil == '5') {
				this.estadoCivilScotiabank = 'W'
			}

			let objRequestRegistraSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraSolicitante',
				objSolicitante: {
					EMail: this.email,
					Nombres: this.nombre,
					ApellidoPaterno: this.apellidoPaterno,
					ApellidoMaterno: this.apellidoMaterno,
					SegundoNombre: this.segNombre,
					TelefonoCelular: this.telefono,
					TelefonoCelular2: this.telefono2,
					Lada: this.codigoSeleccionado, //agregar sms
					FechaNacimiento: this.fechaNacimiento,
					Rfc: this.rfc,
					Curp: this.curp,
					Sexo: sexo,
					Calle: this.calle,
					NumeroExterior: this.numeroExterior,
					NumeroInterior: this.numeroInterior,
					Colonia: this.colonia,
					Municipio: this.municipio,
					Ciudad: this.ciudad,
					Estado: this.estado,
					CodigoPostal: this.codigoPostal,
					// Nacionalidad: this.nacionalidad,
					Nacionalidad: {
						id: this.nacionalidad.Id,
						name: this.nacionalidad.Nombre,
						updateScotia: true,
					},
					EstadoCivil: this.estadoCivil,
					EstadoCivilScotiabank: this.estadoCivilScotiabank,
					RegimenMatrimonial: this.regimenMatrimonial,
					Conyuge: this.conyuge.email,

					EstadoNacimiento:this.estadoNacimiento,
					MunicipioNacimiento:this.municipioNacimiento,
					// PaisNacimiento:this.paisNacimiento,
					PaisNacimiento: {
						name: this.paisNacimiento,
						noSanitiza: true,
					},

					PaisRecidencia:this.PaisRecidencia,

					ScotiaEstadoNacimiento: edoNacimiento ? edoNacimiento.Nombre : "",
				},
			}
			this.$validator.validateAll().then((result) => {
				if (result) {
					this.$axios
						.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
						.then((response) => {

							const status = response.data.intEstatus
							const dataSolicitante = response.data.objContenido
							this.actualiza_bandera('SolicitanteGenerales',true)			  

							/*if(dataSolicitante.TelefonoCelular2){					
								// document.getElementById('campoCelular2').style.display = 'block';
								// document.getElementById('SubCelular2').style.display = 'block';
								// document.getElementById('addCelular2').style.display = 'none';
							}
							else{
								// document.getElementById('campoCelular2').style.display = 'none';
								// document.getElementById('SubCelular2').style.display = 'none';
								// document.getElementById('addCelular2').style.display = 'block';
							}*/
						

							if ( status ) {

								this.$vs.notify({
								title:'Ocurrio un error en RegistraSolicitante',
								text:response.data.strError,
								color:'danger',
								position:'top-right'
								})

							}

							this.state_data = dataSolicitante.StateData

							/*if (dataSolicitante.Curp && this.socData != null) {		
								//alert("soc data " + this.socData + "curp " + dataSolicitante.Curp)		
								this.GeneraLiga(dataSolicitante)
							}*/

							this.$vs.notify({
								title: `Datos guardados exitosamente`,
								position: 'top-right',
							})
							
							if (this.AccreditedidV3) { //si el solicitante cuenta con pedidos en v3 entonces actualiza inf en liga cliente.
								this.generaInformacionParaV3();
							}

							this.registraAdicionales()

						})
						.catch(function (error) {
							self.$vs.notify({
								title: 'Ocurrio un error de sistema',
								text: error,
								color: 'danger',
								position: 'top-right',
							})
						})
				} else {
					this.$vs.notify({
						title: 'Ocurrio un error',
						text: 'Datos incompletos',
						color: 'danger',
						position: 'top-right',
					})
				}
			})
		},
		async GeneraLiga(solicitante){   
			await this.getAuthToken();

			console.log(solicitante) 

			let sexo
			if(this.sexo== 1){
			sexo='Masculino'
			}else if(this.sexo== 2){
			sexo='Femenino'
			}

			var mostrar = ""
			let objRequestCotizaCaso = {
			AccreditedModel: {
				Name: solicitante.Nombres,
				SName: solicitante.SegundoNombre,
				LastName: solicitante.ApellidoPaterno,
				SecondLastName: solicitante.ApellidoMaterno,
				PersonalMail: solicitante.EMail,
				CellPhone: solicitante.TelefonoCelular,
				CURP: solicitante.Curp,
				RFC: solicitante.Rfc,  
				CivilStatus: null,
				Nationality: null,
				Gender: sexo,
				Age: null,
				StudyGrade: null,
				EconomicDependents: null,
				NumberOfChildren: null,
				ChildrensAge: null,
				CP: null,
				MonetaryIncome: null,
				NumberOfCars: null,
				Activity: null,
				PhoneHome: null,
				DateBirtday: this.FechaNacimiento,
				Cocredited: null,
				Weight: null,
				Height: null,
				NSS: null,
				TypeHousing: null,
				ResidenceTimeHome: null,
				ResidenceTimeTown: null,
				CompanyName: null,
				CompanyAddress: null,
				Position: null,
				TypeCompany: null,
				WorkingTime: null,
			},
			ReferencesModel: {
				Name: null,
				SName: null,
				LastName: null,
				SecondLastName: null,
				Phone: null,
				Relationship: null,
				TimeRelationship: null
			},
			CreditRequestModel: {
				TypeCredit: null,
				HomeValue: null,
				CreditAmount: null,
				Plazo: null
			},
			BrokerEmployeeModel: {
				Id: this.SocGuid,
				BrokerId: this.BrokerId,
				EmployeeName: null,
				EmployeeEmail: null,
				Origin: "Cotizador soc"
			},
			IsQuickQuote:false,
			}

			this.$axios.post('https://apivalidacionriesgos.sisec.mx/api/External/CreateAccredited',objRequestCotizaCaso,{headers : this.header})
			.then(
				response => {
							
				this.IdLiga = response.data.AccreditedId            
				//this.EnviaCorreo()
				this.RegistraAccreditedIdSolicitante()
				
				}
			).catch(function (error) {
				if(error.response.data.Message == "The CellPhone already exists"){
				mostrar = "El telefono ya se encuentra registrado intente con otro"
				}else if(error.response.data.Message == "The email already exists"){
				mostrar = "El correo elecronico ya se encuentra registrado intente con otro"
				}else{
				mostrar = error
				}
				
				self.$vs.notify({
				title:'Ocurrio un error en SISEC',
				text:mostrar,
				color:'danger',
				position:'top-right'
				})
			})
		},
		RegistraAccreditedIdSolicitante(){
			let self = this
			let objRequestRegistraSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraAccreditedIdSolicitante',
				objSolicitante: {
					EMail: this.email,
					AccreditedIdV3: this.IdLiga
				},
			}

			this.$axios
				.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en Registra AccreditedId Solicitante',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		generaInformacionParaV3(){
			let self=this
              var objRequestDameInfo = {
				strFolioCaso: this.id,
                strApiKey: this.key,
                strMetodo: 'generaInformacionParaV3',
                objSolicitante:
                {
                    EMail: this.email
                },
                objCaso: {
                Id: this.Id,
                }
              }
              this.$axios.post('/',objRequestDameInfo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
              .then(
                response => {
                  if(response.data.intEstatus == 0){
                    this.solicitanteV3 = response.data.objContenido
                    this.updateInfoEnV3()
                  }else {
                    this.$vs.notify({
                      title:'Ocurrio un error en generaInformacionParaV3',
                      text:response.data.strError,
                      color:'danger',
                      position:'top-right'
                    })
                  }
                }
              ).catch(function (error) {
                self.$vs.notify({
                  title:'Ocurrio un error de sistema',
                  text:error,
                  color:'danger',
                  position:'top-right'
                })
              })
		},
		async updateInfoEnV3(){
			await this.getAuthToken()
			
			let self=this
				
			var mostrar = ""
			let objRequestUpdateInfo =
			{
			IsQuickQuote:false,
			AccreditedModel: {
				Name: this.solicitanteV3.Nombres,
				SName: this.solicitanteV3.SegundoNombre,
				LastName: this.solicitanteV3.ApellidoPaterno,
				SecondLastName: this.solicitanteV3.ApellidoMaterno,
				PersonalMail: this.email,
				PhoneHome: this.solicitanteV3.TelefonoCasa,
				CellPhone: this.solicitanteV3.TelefonoCelular,
				DateBirtday: this.solicitanteV3.FechaNacimiento,
				Gender: this.solicitanteV3.Genero,
				Nationality: this.solicitanteV3.Nacionalidad,
				CURP: this.solicitanteV3.CURP,
				RFC: this.solicitanteV3.RFC,
				Weight: this.solicitanteV3.Peso,
				Height: this.solicitanteV3.Estatura,
				NSS: this.solicitanteV3.Nss,
				StudyGrade: this.solicitanteV3.GradoEstudios,
				CivilStatus: this.solicitanteV3.EstadoCivil,
				EconomicDependents: this.solicitanteV3.DependientesEconomicos,
				NumberOfChildren: this.solicitanteV3.NumeroHijos,
				ChildrensAge: this.solicitanteV3.EdadHijos,
				Cocredited: this.solicitanteV3.Coacreditado,
				//domicilio
				TypeHousing: this.solicitanteV3.TipoInmueble,
				ResidenceTimeHome: this.solicitanteV3.TiempoResidenciaActual,
				ResidenceTimeTown: this.solicitanteV3.TiempoResidenciaPoblacion,
				CP: this.solicitanteV3.CodigoPostal,
				//Bienes inmuebles
				NumberOfCars: this.solicitanteV3.NumeroAutos,				
				//Ingresos
				Activity: this.solicitanteV3.Sector,
				TypeCompany: this.solicitanteV3.TipoEmpleo,
				CompanyName: this.solicitanteV3.Empresa,
				CompanyAddress: this.solicitanteV3.DomicilioEmpresa,
				MonetaryIncome: this.solicitanteV3.Ingresos,
				Position: this.solicitanteV3.Puesto,
				DateBeginWork: this.solicitanteV3.FechaInicio,

				Age: this.solicitanteV3.Edad,		
			},
			ReferencesModel: {
				Name: this.solicitanteV3.NombreReferencia,
				SName: null,
				LastName: this.solicitanteV3.ApellidoPaternoRef,
				SecondLastName: this.solicitanteV3.ApellidoMaternoRef,
				Phone: this.solicitanteV3.TelCelularRef,
				Relationship: this.solicitanteV3.ParentescoRef,
				TimeRelationship: this.solicitanteV3.AnosConocerloRef
			},
			CreditRequestModel: {
				TypeCredit: null,
				HomeValue: null,
				CreditAmount: null,
				Plazo: null
			},
			BrokerEmployeeModel: {
				Id: this.SocGuid,
				BrokerId: this.BrokerId,
					//para pruebas comentar las dos de arriba, terminando descomentar arriba y commentar abajo
					//Id: "61711b5c-948c-4928-94f0-158b96b58da0",
					//BrokerId:"ca1b7cbd-f2a5-4507-9f03-4c167dcb2663",
				EmployeeName: null,
				EmployeeEmail: null,
				Origin: "Cotizador soc"
				
			},
			MaterialGoodsModel:{
				Properties:{
					NumberProperties: this.solicitanteV3.NumeroInmuebles,
					ApproximateValue: this.solicitanteV3.ValorAproximado,
					EncumberedValue: this.solicitanteV3.ValorGrabado,
				},
				CarOne:{
					ApproximateValueCar: this.solicitanteV3.ValorAuto1,
					MarkCar: this.solicitanteV3.MarcaAuto1,
					ModelCar: this.solicitanteV3.ModeloAuto1,
					YearCar: this.solicitanteV3.AnioAuto1,
				},
				CarTwo:{
					ApproximateValueCar: this.solicitanteV3.ValorAuto2,
					MarkCar: this.solicitanteV3.MarcaAuto2,
					ModelCar: this.solicitanteV3.ModeloAuto2,
					YearCar: this.solicitanteV3.AnioAuto2
				}
			}

			
			}   
			this.$axios.post('https://apivalidacionriesgos.sisec.mx/api/External/CreateAccredited',objRequestUpdateInfo,{headers : this.header})
				.then(
				response => {

				}
				).catch(function (error) {
					if(error.response.data.Message == "The CellPhone already exists"){
					mostrar = "El telefono ya se encuentra registrado intente con otro"
					}else if(error.response.data.Message == "The email already exists"){
					mostrar = "El correo elecronico ya se encuentra registrado intente con otro"
					}else{
					mostrar = error
					}
					
					self.$vs.notify({
						title:'Ocurrio un error en SISEC',
						text:mostrar,
						color:'danger',
						position:'top-right'
					})
				})

		},
		DameCredenciales(){
			let self=this
			let objRequestDameCredenciales = {
				strApiKey: this.key,
				strMetodo: 'DameCredenciales',
				objBroker: {
				ApiKey: this.key
				}
			}
			this.$axios.post('/',objRequestDameCredenciales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
				this.SocGuid = response.data.objContenido.SocGuid
				this.BrokerId = response.data.objContenido.BrokerId

				//this.SocGuid = '61711b5c-948c-4928-94f0-158b96b58da0';//solo pruebas
				//this.BrokerId = 'ca1b7cbd-f2a5-4507-9f03-4c167dcb2663';
				}
			).catch(function (error) {
				self.$vs.notify({
				title:'Ocurrio un error de sistema',
				text:error,
				color:'danger',
				position:'top-right'
				})
			})
		},
		dameCaso() {
			let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'DameCaso',
				objCaso: {
					Id: this.id,
				},
			}
			this.$axios
				.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.email = response.data.objContenido.Solicitante
						this.Id = response.data.objContenido.Id
						let coacreditado = response.data.objContenido.Coacreditado
						if (coacreditado.length) {
							this.Coacreditado = true
							if (coacreditado[0].EsConyuge && coacreditado[0].ParticipaCredito) {
								this.conyuge.esConyugue = coacreditado[0].EsConyuge
								this.conyuge.email = coacreditado[0].EMail								
								this.conyuge.seraCoPropietario = coacreditado[0].SeraCoPropietario
								this.conyuge.consolidaIngresos = coacreditado[0].ConsolidaIngresos
								if (coacreditado[0].Parentesco == null) this.conyuge.parentesco = ''
								else this.conyuge.parentesco = coacreditado[0].Parentesco

								this.conyuge.participaCredito = coacreditado[0].ParticipaCredito
							} else {
								this.coacreditado.esConyugue = coacreditado[0].EsConyuge
								this.coacreditado.email2 = coacreditado[0].EMail
								this.coacreditado.email = coacreditado[0].EMail								
								this.coacreditado.seraCoPropietario = coacreditado[0].SeraCoPropietario
								this.coacreditado.consolidaIngresos = coacreditado[0].ConsolidaIngresos
								if (coacreditado[0].Parentesco == null) this.coacreditado.parentesco = ''
								else this.coacreditado.parentesco = coacreditado[0].Parentesco

								this.coacreditado.participaCredito = coacreditado[0].ParticipaCredito
							}
						}

						// campos migrados
						let caso = response.data.objContenido;
						this.adicionales.fechaSolicitud = caso.FechaSolicitud;
						this.adicionales.lugarSolicitud = caso.LugarSolicitud;

            			if (caso.UsoInmueble == null) {
							this.adicionales.usoInmueble = '';             
						} else {
							this.adicionales.usoInmueble = caso.UsoInmueble;
						}
						
						if (caso.TipoInmueble == null) {
							this.adicionales.tipoInmueble = '';             
						} else {
							this.adicionales.tipoInmueble = caso.TipoInmueble;
						}
						if (caso.FormaEnganche == null) {
							this.adicionales.formaEnganche = '';
						} else {
							this.adicionales.formaEnganche = caso.FormaEnganche;
						}
						this.adicionales.seguros = caso.SeguroBancario;
						// campos migrados

						this.dameSolicitante()						
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameCaso',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		dameConyuge(email) {
			let self = this
			var objRequestDameSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'DameSolicitante',
				objSolicitante: {
					EMail: email,
				},
			}
			this.$axios
				.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						let conyuge = response.data.objContenido
						this.conyuge.nombre = conyuge.Nombres
						this.conyuge.email = conyuge.EMail
						this.conyuge.apellidoPaterno = conyuge.ApellidoPaterno
						this.conyuge.apellidoMaterno = conyuge.ApellidoMaterno
						this.conyuge.rfc = conyuge.Rfc
						this.conyuge.curp = conyuge.Curp
						this.conyuge.fechaNacimiento = conyuge.FechaNacimiento
						if (conyuge.Sexo == 1) {
							this.conyuge.sexo = 'H'
						} else if (conyuge.Sexo == 2) {
							this.conyuge.sexo = 'M'
						}
						this.conyuge.nacionalidad = conyuge.Nacionalidad
						this.conyuge.paisRecidencia = conyuge.PaisRecidencia
						this.conyuge.estadoNacimiento = conyuge.EstadoNacimiento

						// nuevos campos conyuge

						this.conyuge.calle = conyuge.Calle;
						this.conyuge.numeroExterior = conyuge.NumeroExterior;
						this.conyuge.numeroInterior = conyuge.NumeroInterior;
						this.conyuge.ciudad = conyuge.Ciudad;
						this.conyuge.estado = conyuge.Estado;
						this.conyuge.codigoPostal = conyuge.CodigoPostal;
						this.conyuge.municipio = conyuge.Municipio;
						this.conyuge.colonia = conyuge.Colonia;
						this.conyuge.tiempoViviendoCiudad = conyuge.TiempoViviendoCiudad ? conyuge.TiempoViviendoCiudad : 0;
						this.conyuge.tiempoViviendoCiudadMeses = conyuge.TiempoViviendoCiudadMeses ? conyuge.TiempoViviendoCiudadMeses : 0;
						this.conyuge.antiguedadEnCiudad = conyuge.AntiguedadEnCiudad ? conyuge.AntiguedadEnCiudad : 0;
						this.conyuge.antiguedadEnCiudadMeses = conyuge.AntiguedadEnCiudadMeses ? conyuge.AntiguedadEnCiudadMeses : 0;

						this.conyuge.viveMismoDomicilioTitular = conyuge.ViveMismoDomicilioTitular;
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameSolicitante',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		dameSolicitante() {
			let self = this
			var objRequestDameSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'DameSolicitante',
				objSolicitante: {
					EMail: this.email,
				},
			}
			this.$axios
				.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {

					const status = response.data.intEstatus
					const dataSolicitante = response.data.objContenido

					// console.log( 'SOL', dataSolicitante );

          if ( status ) {

						this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })

					}

          this.nombre = dataSolicitante.Nombres
          this.apellidoPaterno = dataSolicitante.ApellidoPaterno
          this.apellidoMaterno = dataSolicitante.ApellidoMaterno
          this.segNombre = dataSolicitante.SegundoNombre
          this.telefono = dataSolicitante.TelefonoCelular.replace(/\s+/g, '').replace(/-/g, '')
		  if (dataSolicitante.TelefonoCelular2 != null) {
			this.telefono2 = dataSolicitante.TelefonoCelular2.replace(/\s+/g, '').replace(/-/g, '')	
		  }		 

		if(dataSolicitante.TelefonoCelular2){
			this.telefono2 = dataSolicitante.TelefonoCelular2.replace(/\s+/g, '').replace(/-/g, '')	
			// document.getElementById('campoCelular2').style.display = 'block';
			// document.getElementById('SubCelular2').style.display = 'block';
			// document.getElementById('addCelular2').style.display = 'none';
		}
		else{
			// document.getElementById('campoCelular2').style.display = 'none';
			// document.getElementById('SubCelular2').style.display = 'none';
			// document.getElementById('addCelular2').style.display = 'block';
		}

		  
          this.fechaNacimiento = dataSolicitante.FechaNacimiento
          this.rfc = dataSolicitante.Rfc

          if (dataSolicitante.Sexo == 1) this.sexo = 'H'
          if (dataSolicitante.Sexo == 2) this.sexo = 'M'

          this.calle = dataSolicitante.Calle
          this.numeroExterior = dataSolicitante.NumeroExterior
          this.numeroInterior = dataSolicitante.NumeroInterior
          this.colonia = dataSolicitante.Colonia
          this.ciudad = dataSolicitante.Ciudad
          this.municipio = dataSolicitante.Municipio
          this.curp = dataSolicitante.Curp
          this.codigoPostal = dataSolicitante.CodigoPostal
					
		  if (dataSolicitante.CodigoPostal != '' || dataSolicitante.CodigoPostal != null) {
			this.getColoniaMunicipio(0)
		  }
		  

          this.estado = dataSolicitante.Estado || ''
          // if (dataSolicitante.Estado == null) this.estado = ''
          // else this.estado = dataSolicitante.Estado


		  this.paisNacimiento = dataSolicitante.PaisNacimiento;
		  this.estadoNacimiento = dataSolicitante.EstadoNacimiento;
		  this.municipioNacimiento = dataSolicitante.MunicipioNacimiento;
		  this.PaisRecidencia = dataSolicitante.PaisRecidencia;
		  
					// campos migrados
					if (dataSolicitante.TipoOcupacion == null ) {
						this.adicionales.titularidadDomicilio = '';
					} else {
						this.adicionales.titularidadDomicilio = dataSolicitante.TipoOcupacion
					}

					this.adicionales.tiempoOcupandoDomicilioActual = dataSolicitante.TiempoViviendoCiudad ? dataSolicitante.TiempoViviendoCiudad : 0;
					this.adicionales.tiempoOcupandoDomicilioActualMeses = dataSolicitante.TiempoViviendoCiudadMeses ? dataSolicitante.TiempoViviendoCiudadMeses : 0;

					this.adicionales.antiguedadCiudad = dataSolicitante.AntiguedadEnCiudad ? dataSolicitante.AntiguedadEnCiudad : 0;
					this.adicionales.antiguedadCiudadMeses = dataSolicitante.AntiguedadEnCiudadMeses ? dataSolicitante.AntiguedadEnCiudadMeses : 0;

					this.adicionales.antiguedadInmuebleAdquirir = dataSolicitante.AntiguedadVivienda;
					this.adicionales.antiguedadDomicilioAnterior = dataSolicitante.AntiguedadDomicilioAnterior;
					
					if (dataSolicitante.Identificacion == null) {
						this.adicionales.identificacionPresenta = '';
					} else {
						this.adicionales.identificacionPresenta = dataSolicitante.Identificacion;
					}
					this.adicionales.numeroIfe = dataSolicitante.NumeroIdentificacion;
					this.adicionales.vigencia = dataSolicitante.VigenciaIdentificacion;
					this.adicionales.nss = dataSolicitante.Nss;
					this.adicionales.issste = dataSolicitante.Issste;
					this.adicionales.fiel = dataSolicitante.Fiel;
					
					if (dataSolicitante.Escolaridad == null) {
						this.adicionales.gradoEstudios = '';
					} else {
						this.adicionales.gradoEstudios = dataSolicitante.Escolaridad;
					}
					this.adicionales.institucionEstudios = dataSolicitante.EscolaridadInstitucion;
					if(dataSolicitante.CompaniaCelular) {
						this.adicionales.companiaCelular = dataSolicitante.CompaniaCelular.replace(/&amp;/g, "&");
					}
					this.adicionales.telefonoFijo = dataSolicitante.TelefonoCasa;
					if (dataSolicitante.GastosMensuales) {
						this.adicionales.totalGastosMensuales = (dataSolicitante.GastosMensuales).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					}
					if (dataSolicitante.PensionAlimenticiaMonto) {
						this.adicionales.montoPension = (dataSolicitante.PensionAlimenticiaMonto).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					}
					this.adicionales.otroNombre = dataSolicitante.OtroNombreAnterior;
					
					this.adicionales.calleExtranjero = dataSolicitante.CalleExtranjero;
					this.adicionales.numeroExteriorExtranjero = dataSolicitante.NumeroExteriorExtranjero;
					this.adicionales.numeroInteriorExtranjero = dataSolicitante.NumeroInteriorExtranjero;
					this.adicionales.coloniaExtranjero = dataSolicitante.ColoniaExtranjero;
					this.adicionales.estadoExtranjero = dataSolicitante.EstadoExtranjero;
					this.adicionales.paisExtranjero = dataSolicitante.PaisExtranjero;
					this.adicionales.codigoPostalExtranjero = dataSolicitante.CodigoPostalExtranjero;
					this.adicionales.NacionalidadDetalle = dataSolicitante.NacionalidadDetalle;
					// campos migrados

          if (dataSolicitante.EstadoCivil == null) this.estadoCivil = ''
          else this.estadoCivil = dataSolicitante.EstadoCivil

          if (dataSolicitante.RegimenMatrimonial == null) this.regimenMatrimonial = ''
          else this.regimenMatrimonial = dataSolicitante.RegimenMatrimonial

		  if (dataSolicitante.ScotiaNacionalidad && dataSolicitante.Nacionalidad) {
			this.nacionalidad = {
				Id: dataSolicitante.ScotiaNacionalidad,
				Nombre: dataSolicitante.Nacionalidad,
			};
		  } else {
			this.nacionalidad = '';
		  }

          if (dataSolicitante.Conyuge != null && (this.estadoCivil == 1 || this.estadoCivil == 4)) {
            this.dameConyuge(dataSolicitante.Conyuge)
          }
          this.codigoSeleccionado = dataSolicitante.Lada
          this.state_data = dataSolicitante.StateData
		  if (dataSolicitante.AccreditedIdV3 !=null && dataSolicitante.AccreditedIdV3 != '') {
			this.AccreditedidV3 = true;
		  }

		  if(dataSolicitante.TelefonoCelular2){			
			this.telefono2 = dataSolicitante.TelefonoCelular2.replace(/\s+/g, '').replace(/-/g, '')	
			// document.getElementById('campoCelular2').style.display = 'block';
			// document.getElementById('SubCelular2').style.display = 'block';
			// document.getElementById('addCelular2').style.display = 'none';
		}
		else{			
			// document.getElementById('campoCelular2').style.display = 'none';
			// document.getElementById('SubCelular2').style.display = 'none';
			// document.getElementById('addCelular2').style.display = 'block';
		}

		this.validaCorreoSolicitante('inicio')

		// Terminan peticiones
		// if (flag == 'guardar') {
		// 	this.$vs.loading.close('#solGenPer > .con-vs-loading');
		// }
		
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getApikey() {
			if (!JSON.parse(localStorage.getItem('userInfo')).ApiKey) {
				this.$router.push('/login')
			} else {
				this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey
				this.socData = JSON.parse(localStorage.getItem('socData'))
			}
		},
		getEstadosCiviles() {
			let self = this
			var objRequestListaEstadosCiviles = {
				strApiKey: this.key,
				strMetodo: 'ListaEstadosCiviles',
			}
			this.$axios
				.post('/', objRequestListaEstadosCiviles, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.estadosCiviles = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaEstadosCiviles',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getEstados() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaEstados',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.estados = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaEstados',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getStateName(id) {
			return this.states[id - 1]
		},
		getStateNumber(stateName) {
			for (var i = 0; i < this.states.length; i++) {
				if (stateName == this.states[i]) {
					return i + 1
				}
			}
		},
		getListaParentescos() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaParentescos',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.parentescos = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaParentescos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getListaRegimenes() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaRegimenesMatrimoniales',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.regimenes = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaRegimenesMatrimoniales',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		showCelular2(){
			//alert("press button add")
			// document.getElementById('campoCelular2').style.display = 'block';
			// document.getElementById('SubCelular2').style.display = 'block';
			// document.getElementById('addCelular2').style.display = 'none';
		},
		subCelular2(){
			// document.getElementById('campoCelular2').style.display = 'none';
			// document.getElementById('SubCelular2').style.display = 'none';
			// document.getElementById('addCelular2').style.display = 'block';
		},
		async getColoniaMunicipio(value){
			
			var origen = 0;
			
			if (value == 1) {
				this.colonia = '';	
				origen = 1;
			}
			
			const getToken = await this.getTokenSisec();
			var token = getToken.objRespuesta.token
			const dataInfoCP = await this.getInformationCP(token)

			if ( !dataInfoCP ) {
				this.colonia = ''
				this.municipio = ''
				return
			}
			
			const colonias = dataInfoCP.map(x => x.colonia)
      		
			this.ColoniasCP = colonias
			this.municipio = dataInfoCP[0].municipio		

			if (origen == 0) {
				if(colonias.indexOf(this.colonia) == -1){  // no se encontro
					this.colonia = '';
				}
			}
		},
		async getTokenSisec() {
			const URL_Method = 'https://cotizador.socasesores.com/consultaSisec/getTokenSisec.php'

			const { data, status } = await this.$axios
						.post(URL_Method,{headers : {'Content-Type':'application/json; charset=UTF-8'}})

			if ( status !== 200 ) {

				this.$vs.notify({
				title: 'Ocurrio un error en getTokenSisec',
				text: data.strError,
				color: 'danger',
				position: 'top-right',
				})

				return false

			}

			if ( data.intRespuesta ) {

				this.$vs.notify({
				title: 'Ocurrio un error en Metodo getTokenSisec',
				text: data.strError,
				color: 'danger',
				position: 'top-right',
				})

				return false

			}

			if ( !data.objRespuesta.status ) {

				this.$vs.notify({
				title: 'Ocurrio un error en API getTokenSisec',
				text: data.strError,
				color: 'danger',
				position: 'top-right',
				})

				return false

			}

			//console.log(data)
			return data;

		},
		async getInformationCP(token) {

				const jsonCP = {
									token,
									CodigoPostal: this.codigoPostal
								}

				const URL_Method = 'https://cotizador.socasesores.com/consultaSisec/getInfoCP.php'

				const { status, data } = await this.$axios
						.post(URL_Method,jsonCP,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
						
				if ( status !== 200 ) {

					this.$vs.notify({
						title: 'Ocurrio un error en getInformationCP',
						text: data.strError,
						color: 'danger',
						position: 'top-right',
					})

					return false

				}

				if ( data.intRespuesta ) {

					this.$vs.notify({
						title: 'Ocurrio un error en Metodo getInformationCP',
						text: data.strError,
						color: 'danger',
						position: 'top-right',
					})

					return false

				}

				if ( data.objRespuesta.message ) {

					this.$vs.notify({
					title: data.objRespuesta.message,
					text: data.strError,
					color: 'danger',
					position: 'top-right',
					})

					return false

				}

				if ( !data.objRespuesta.length ) {
					return false
				}

				return data.objRespuesta

		},		
	},
}
</script>

<style lang="css">
.red{
  color: red;
}
.addTel{
	margin-top: 5px;	
	margin-left: 10%;	
	border: 0;
	background-color: white;
	
}

.input-antiguedad-am {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-antiguedad-fecha {
  margin-top: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: lightgrey;
}
.input-antiguedad-fecha span {
  color: inherit;
  font-size: medium;
  margin: 0;
  text-align: center;
  padding-top: 6px;
  display: block;
}
</style>
