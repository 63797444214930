<template lang="html">
  <div id="container">
    <div class="vx-row">
      <div class="vx-col w-full">
        <vx-card title="Referencias" class="mb-base">
          <vs-alert title="Datos obligatorios" color="rgb(231, 154, 23)" active="true" class="mt-5 text-warning">
              Es obligatorio agregar por lo menos una referencia personal y una referencia familiar
          </vs-alert>
          <div class="row w-full">
            <div class="col-md-4">
              <label class="span-placeholder" for="formasEnganche">Parentesco *</label>
                <select name="cars" id="formasEnganche" v-model="parentesco" class="v-select vs--single vs--searchable vs-input--input">
                  <option :value='tipo.Id' v-for="tipo in parentescos"  :key="">{{tipo.Nombre}}</option>
                </select>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Nombre(s)*</span>
              <vs-input class="" v-model="nombre" name="name"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Apellido Paterno*</span>
              <vs-input v-model="apellidoPaterno" name="name"/>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Apellido Materno</span>
              <vs-input v-model="apellidoMaterno" name="name"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Teléfono celular (10 dígitos) *</span>
              <vs-input v-model="telCelular" minlength="10" maxlength="10" name="name" v-on:keypress="isNumber($event)"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder"></span>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
            <button class="btn btn-second" @click="registraReferencia()" :disabled="!validateForm">Guardar Referencia</button>
            </div>
          </div>
          <br>

          <br>
        </vx-card>
        <vx-card title="" class="mb-base">
          <vs-table :data="referencias" max-items="5"
            pagination
            noDataText="No hay datos para mostrar">
            <template slot="thead">
              <vs-th>
                Nombre(s)
              </vs-th>
              <vs-th>
                Apellido Paterno
              </vs-th>
              <vs-th>
                Apellido Materno
              </vs-th>
              <vs-th>
                Parentesco
              </vs-th>
              <vs-th>
                Teléfono celular
              </vs-th>
              <vs-th>
                Acciones
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].Nombre" >
                  {{data[indextr].Nombre}}
                </vs-td>
                <vs-td :data="data[indextr].ApellidoPaterno">
                  {{data[indextr].ApellidoPaterno}}
                </vs-td>
                <vs-td :data="data[indextr].ApellidoMaterno">
                  {{data[indextr].ApellidoMaterno}}
                </vs-td>
                <vs-td :data="data[indextr].Parentesco">
                  {{data[indextr].Parentesco}}
                </vs-td>
                <vs-td :data="data[indextr].Telefono">
                  {{data[indextr].Telefono}}
                </vs-td>
                <vs-td>
                  <vs-row vs-w="12" vs-justify="space-between">
                    <vx-tooltip text="Editar referencia" position="top">
                      <vs-button color="primary" @click="editar(data[indextr])"><feather-icon icon="EditIcon" svgClasses="w-4 h-4"/></vs-button>
                    </vx-tooltip>
                    <vx-tooltip text="Borrar referencia" position="top">
                      <vs-button color="primary" @click="eliminar(data[indextr].Id)"><feather-icon icon="TrashIcon" svgClasses="w-4 h-4"/></vs-button>
                    </vx-tooltip>
                  </vs-row>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
    <vs-popup fullscreen title="Editar Referencia" :active.sync="popupActive">
      <vx-card title="Datos requeridos" class="mb-base">
        <vs-row vs-w="12" vs-justify="space-between">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12">
            <div class="vx-row w-full">
              <label class="text-sm" for="formasEnganche">Parentesco</label>
              <select name="cars" id="formasEnganche" v-model="parentesco" class="v-select vs--single vs--searchable vs-input--input">
                <option :value='tipo.Id' v-for="tipo in parentescos"  :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12" vs-xs="12">
            <vs-input class="w-full mt-4" label="Nombre(s)" v-model="nombre" name="name"/>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12">
            <vs-input class="w-full mt-4" label="Apellido Paterno" v-model="apellidoPaterno" name="name"/>
          </vs-col>
        </vs-row>
        <br>
        <vs-row vs-w="12" vs-justify="space-between">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12">
            <vs-input class="w-full mt-4" label="Apellido Materno" v-model="apellidoMaterno" name="name"/>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12">
            <vs-input class="w-full mt-4" label="Teléfono celular (10 dígitos)" minlength="10" maxlength="10" v-model="telCelular" name="name" type="number"/>
          </vs-col>
        </vs-row>
        <br>
        <vs-button color="primary" @click="editarReferencia()" :disabled="!validateForm">Editar referencia</vs-button>
      </vx-card>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    'v-select': vSelect,
  },
  data(){
		return{
      popupActive: false,
      nombre:'',
      apellidoPaterno:'',
      apellidoMaterno:'',
      telCelular:'',
      parentesco:'',
      referenciaId:'',
      referencias:[],
      parentescos:[],
      email:'',
      emailCasoNuevo:{'bandera':''},
      normaTipo: {
        Numero: 1,
        LetraEspacio: 2,
        LetraNumero: 3,
        LetraNumeroEspacio: 4,
      }
    }
  },
  computed:{
    validateForm(){
      return this.nombre != '' && this.apellidoPaterno != '' && this.telCelular != '' && this.parentesco != ''
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey
   // this.getApikey()
    this.getListaParentescos()
    this.dameCaso()
  },
  watch: {
    popupActive:function(){
      if (this.popupActive == false) {
        this.limpiaForm()
      }
    },

    // Restriccion Referencias
    nombre: function(newValue, oldValue) {
      let regex = /^[a-zA-ZñÑ ]*$/;
      if (!regex.test(newValue)) {
        this.nombre = oldValue;
      }
      // console.log('New: ', newValue, ' | Old: ', oldValue)
    },
    apellidoPaterno: function(newValue, oldValue) {
      let regex = /^[a-zA-ZñÑ ]*$/;
      if (!regex.test(newValue)) {
        this.apellidoPaterno = oldValue;
      }
    },
    apellidoMaterno: function(newValue, oldValue) {
      let regex = /^[a-zA-ZñÑ ]*$/;
      if (!regex.test(newValue)) {
        this.apellidoMaterno = oldValue;
      }
    },
    telCelular: function(newValue, oldValue) {
      let regex = /^[0-9]*$/;
      if (!regex.test(newValue)) {
        this.telCelular = oldValue;
      }
    },
  },
  methods:{
    normalizaCadena(value, tipo) {
      /*
       1: Numeros 
       2: Letras y espacios 
       3: Letras y numeros 
       4: Letras, numeros y espacios
      */

      const vocalNormalizacion = (str) => {
        let regex = /([aeiou])[\u0301\u0300]/gi;
        return str.normalize('NFD')
                  .replace(regex,"$1")
                  .normalize();
      }

			let cadena = "";
			if (value) {
				let data = value.toString();
				cadena = vocalNormalizacion(data);

				let regex = "";

				switch (tipo) {
					case 1:
					regex = /[^0-9]/g;
					cadena = cadena.replaceAll(regex, '');
					break;
					case 2:
					regex = /[^A-Za-zÑñ ]/g;
					cadena = cadena.replaceAll(regex, '');
					break;
					case 3:
					regex = /[^A-Za-zÑñ0-9]/g;
					cadena = cadena.replaceAll(regex, '');
					break;
					case 4:
					regex = /[^A-Za-zÑñ0-9 ]/g;
					cadena = cadena.replaceAll(regex, '');
					break;
					default:
					break;
				}
			}

      // TODO: Eliminar para prod
      // console.log( 'Original: ', value, 'Normalizando: ', cadena );
      return cadena;
    },

     isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    editarReferencia(){
      let self= this
      var objRequestRegistraCuentasBancarias = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'EditarReferenciasPersonales',
        objSolicitante:{
            EMail:this.email,
            ReferenciasPersonales:[
              {
                Id:this.referenciaId,
                Nombre:this.nombre,
                ApellidoPaterno:this.apellidoPaterno,
                ApellidoMaterno:this.apellidoMaterno,
                Telefono:this.telCelular,
                AnosConocerlo:'0',
                Parentesco:this.parentesco,
              }
            ]
        }
      }
      this.$axios.post('/',objRequestRegistraCuentasBancarias,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos editados exitosamente`,
              position:'top-right'
            })
            this.popupActive=false
            this.limpiaForm()
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en EditarReferenciasPersonales',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    editar(referencia){
      this.nombre=referencia.Nombre
      this.apellidoPaterno=referencia.ApellidoPaterno
      this.apellidoMaterno=referencia.ApellidoMaterno
      this.telCelular=referencia.Telefono
      this.parentesco=referencia.ParentescoId
      this.referenciaId=referencia.Id
      this.popupActive=true
    },
    eliminar(id){
      var eliminarCuentas = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'BorrarReferenciasPersonales',
        objSolicitante:{
            EMail:this.email,
            ReferenciasPersonales:[
              {
                Id:id
              }
            ]
          }
      }
      this.$axios.post('/',eliminarCuentas,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Se elimino exitosamente`,
              position:'top-right'
            })
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en BorrarReferenciasPersonales',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraReferencia(){
      this.$vs.loading({
        container: '#container',
        scale: 0.6
      })
      let self= this
      var objRequestRegistraReferencias = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'RegistraReferenciasPersonales',
        objSolicitante:{
            EMail:this.email,
            ReferenciasPersonales:[
              {
                Nombre:this.nombre,
                ApellidoPaterno:this.apellidoPaterno,
                ApellidoMaterno:this.apellidoMaterno,
                Telefono:this.telCelular,
                AnosConocerlo:'0',
                Parentesco:this.parentesco,
              }
            ]
        }
      }
      this.$axios.post('/',objRequestRegistraReferencias,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.limpiaForm()
            this.dameSolicitante()
            this.$vs.loading.close('#container > .con-vs-loading')

            this.emailCasoNuevo.bandera= 'ReferenciasCompleto'
            this.$emit("bandera",  this.emailCasoNuevo.bandera)
            this.ActualizaBanderaPersonales(false)

          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
     ActualizaBanderaPersonales(tipo){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'ActualizaBanderaScotiabankReferencia',
        objCaso: {
         Id: this.id,
         Bandera: 'Referencias',
         tipo:tipo
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){

            if(tipo==false){
              
              this.emailCasoNuevo.bandera= 'ReferenciasCompleto'
              this.$emit("bandera",  this.emailCasoNuevo.bandera)

            }else{
              
            this.emailCasoNuevo.bandera= 'ReferenciasIncompletas'
            this.$emit("bandera",  this.emailCasoNuevo.bandera)

            }

              
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en Actualiza',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){

            if(response.data.objContenido.ScotiabankReferencias=='Completo'){
               this.emailCasoNuevo.bandera= 'ReferenciasCompleto'
               this.$emit("bandera",  this.emailCasoNuevo.bandera)
            }

            this.email= response.data.objContenido.Solicitante
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            
            // Normalizacion
            let refList = response.data.objContenido.ReferenciasPersonales;
            if (refList[0]) {
              refList.forEach(item => {
                // TODO: Requiere guardar en Scotiabank en referencias como paso adicional para que se muestre en la solicitud maestra
                item.Nombre = this.normalizaCadena(item.Nombre, this.normaTipo.LetraEspacio);
                item.ApellidoPaterno = this.normalizaCadena(item.ApellidoPaterno, this.normaTipo.LetraEspacio);
                item.ApellidoMaterno = this.normalizaCadena(item.ApellidoMaterno, this.normaTipo.LetraEspacio);
                item.Telefono = this.normalizaCadena(item.Telefono, this.normaTipo.Numero);
              });
              // TODO: Posible guardado de referencias una vez normalizados
            }

            // this.referencias=response.data.objContenido.ReferenciasPersonales
            this.referencias = refList;

            if(!this.referencias[0]){
               this.emailCasoNuevo.bandera= 'ReferenciasIncompletas'
                this.$emit("bandera",  this.emailCasoNuevo.bandera)
                this.ActualizaBanderaPersonales(true)
            }else{
                this.emailCasoNuevo.bandera= 'ReferenciasCompleto'
                this.$emit("bandera",  this.emailCasoNuevo.bandera)
                this.ActualizaBanderaPersonales(false)

            }

          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaParentescos(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaParentescos',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.parentescos=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaParentescos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    limpiaForm(){
      this.nombre=''
      this.apellidoPaterno=''
      this.apellidoMaterno=''
      this.telCelular=''
      this.parentesco=''
    },
    getApikey(){
      if(!this.$cookie.get('key')){
        this.$router.push('/login');
      }else {
        this.key=this.$cookie.get('key');
      }
    },
  }
}
</script>

<style lang="css" scoped>
.v-select{
    width: 100%;
}
.label-select{
  margin-top: 3%;
}
</style>