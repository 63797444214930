<template lang="html">
	<div id="container">
	 <vs-prompt title="Aviso"  class="export-options prompt-modal ":active="!completeInfoCirculo" @close="OcultarMensaje()"  style="text-align:center;">
              Los datos no coinciden con los de la solicitud maestra, ¿estás seguro que quieres sobreescribir los datos de la solicitud maestra?



              <div class="row w-full" >
			  <div class="col-md-2"></div>
                <div class="col-md-3">
                  <button class="btn btn-second btn-large" @click="actualizarInfo()">Guardar</button>
                </div>
				<div class="col-md-2"></div>
				 <div class="col-md-3">
                  <button class="btn btn-danger btn-large" @click="OcultarMensaje()">Cancelar</button>
                </div>
              </div>
	 </vs-prompt>

	<!--	<div class="vx-row" v-if="email == ''">
			<div class="vx-col w-full md:w-3/3 sm:w-2/2 mb-base">
				<vx-card class="text-center cursor-pointer">
					<img src="@/assets/images/pages/graphic-2.png" alt="graphic" width="180" class="mx-auto mb-4" />
					<h4 class="mb-2">Añade un coacreditado</h4>
					<small>Regresa al apartado solicitante para agregar un coacreditado</small>
				</vx-card>
			</div>
		</div>-->
		<!--<div class="vx-row" v-else>-->
		<div class="vx-row">
			<div class="vx-col w-full">
				<vx-card title="Coacreditado" class="mb-base">

					<div class="row d-flex justify-content-end">
						<div class="col-6 col-xl-3" v-if="NoConyuge == false">
							<small>*El coacreditado para los productos Scotiabank debe ser cónyuge*</small>
						</div>
						<div class="col-3 col-xl-3 text-end">
							<button class="btn btn-second btn-large" @click="ActiveForm()">{{ txtButtonProgreso }}</button>
						</div>
						<div class="col-3 col-xl-3">
							<vs-button color="danger" class=" btn-large" @click="eliminarCoacreditado()">Eliminar Coacreditado</vs-button>
						</div>
					</div>


					<!-- <vs-row vs-type="flex" vs-justify="flex-end">
						<div class="col-md-4">
							<button class="btn btn-second" @click="actualizarInfo()">Guardar Progreso</button>
						</div>

					<div class="row d-flex justify-content-end">
						<div class="col-6 col-xl-3 text-end">
							<button class="btn btn-second btn-large" @click="ActiveForm()">{{ txtButtonProgreso }}</button>
						</div>
						<div class="col-6 col-xl-3">
							<vs-button color="danger" class=" btn-large" @click="eliminarCoacreditado()">Eliminar Coacreditado</vs-button>
						</div>
					</div>


					<!-- <vs-row vs-type="flex" vs-justify="flex-end">
						<div class="col-md-4">
							<button class="btn btn-second" @click="actualizarInfo()">Guardar Progreso</button>
						</div>
						<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12">
							<vs-button color="danger" @click="eliminarCoacreditado()">Eliminar Coacreditado</vs-button>
						</vs-col>
					</vs-row> -->
					<!-- <br />
					<br /> -->
					<div class="row w-full">
						<h4>Datos personales</h4>
						<div class="col-md-4">
							<span class="span-placeholder">Email</span>
							<vs-input :disabled="isDisabledForm" v-model="email" name="email" />
						</div>
						<div class="col-md-4">
							<span class="span-placeholder">Nombre(s)</span>
							<vs-input v-validate="'required|alpha_spaces'" :disabled="isDisabledForm" v-model="nombre" name="nombres" />
							<span class="text-danger text-sm" v-show="errors.has('nombres')">Campo requerido</span>
						</div>
						<div class="col-md-4">
							<span class="span-placeholder">Apellido Paterno</span>
							<vs-input v-validate="'required|alpha_spaces'" :disabled="isDisabledForm" v-model="apellidoPaterno" name="apellidoPaterno" />
							<span class="text-danger text-sm" v-show="errors.has('apellidoPaterno')">Campo requerido</span>
						</div>
					</div>
					<div class="row w-full">
						<div class="col-md-4">
							<span class="span-placeholder">Apellido Materno</span>
							<vs-input class="w-full mt-1" v-validate="'required|alpha_spaces'" :disabled="isDisabledForm" v-model="apellidoMaterno" name="apellidoMaterno" />
							<span class="text-danger text-sm" v-show="errors.has('apellidoMaterno')">Campo requerido</span>
						</div>
						<div class="col-md-4">
							<span class="span-placeholder"></span>
							<label for="span-placeholder">Sexo</label>
							<div class="alinear">
								<div class="col-md-6">
									<vs-radio :disabled="isDisabledForm" v-model="sexo" vs-name="sexo_coacreditado" vs-value="1">Hombre</vs-radio>
								</div>
								<div class="col-md-6">
									<vs-radio :disabled="isDisabledForm" v-model="sexo" vs-name="sexo_coacreditado" vs-value="2">Mujer</vs-radio>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<span class="span-placeholder">Teléfono celular (10 dígitos)</span>
							<vs-input :disabled="isDisabledForm" v-model="telefono" name="telefono" v-validate="'required|min:10|max:10'" minlength="10" maxlength="10" v-on:keypress="isNumber($event)" />
							<span class="text-danger text-sm" v-show="errors.has('telefono')">El campo debe tener 10 caracteres</span>
						</div>
					</div>
					<div class="row w-full">
						<div class="col-md-4">
							<span class="span-placeholder">Fecha de nacimiento</span>
							<vs-input type="date" :disabled="isDisabledForm" v-model="fechaNacimiento" name="fechaNacimiento" v-validate="'required'" />
							<span class="text-danger text-sm" v-show="errors.has('fechaNacimiento')">Campo requerido</span>
						</div>
						<div class="col-md-4">
							<span class="span-placeholder">RFC</span>
							<vs-input :disabled="isDisabledForm" v-model="rfc" name="rfc" v-validate="'min:13|max:13'" v-mask="'AAAA######NNN'" />
							<span class="text-danger text-sm" v-show="errors.has('rfc')">El campo debe tener 13 caracteres</span>
						</div>
						<!--  <div class="col-md-4">
              <span class="span-placeholder">Curp</span>
              <vs-input :disabled="isDisabledForm" v-model="curp" name="name" />
            </div>-->
						<div class="col-md-4">
							<span class="span-placeholder">CURP</span>
							<input class="vs-input" :disabled="isDisabledForm" v-model="curp" name="name" v-validate="'min:18|max:18'" v-mask="'AAAA######AAAAAANN'" value="" onkeyup="javascript:this.value=this.value.toUpperCase();" />
							<span class="text-danger span-placeholder" v-show="errors.has('curp')">El campo debe tener 18 caracteres</span>
						</div>
					</div>
					<div class="row w-full">
						<div class="col-md-4">
							<label class="span-placeholder" for="estados">Nacionalidad</label>
							<select name="estados" id="estados" :disabled="isDisabledForm" v-model="nacionalidad" class="v-select vs--single vs--searchable vs-input--input">
								<!-- <option :value="tipo.Id" v-for="tipo in nacionalidades" :key="">{{ tipo.Nombre }}</option> -->
								<option :value="tipo" v-for="tipo in nacionalidades" :key="">{{ tipo.Nombre }}</option>
							</select>
						</div>
						<div class="col-md-4">
							<label class="span-placeholder" for="estados">Estado de nacimiento</label>
							<select name="estados" id="estados" :disabled="isDisabledForm" v-model="adicionales.estadoNacimiento" class="v-select vs--single vs--searchable vs-input--input">
								<option :value="tipo.Nombre" v-for="tipo in estados" :key="">{{ tipo.Nombre }}</option>
							</select>
						</div>
						<div class="col-md-4">
							<label class="span-placeholder" for="estados">País de nacimiento</label>
							<select name="estados" id="estados" :disabled="isDisabledForm" v-model="adicionales.paisNacimiento" class="v-select vs--single vs--searchable vs-input--input">
								<option :value="tipo.Id" v-for="tipo in paises" :key="">{{ tipo.Nombre }}</option>
							</select>
						</div>
					</div>
					<div class="row w-full">
						<div class="col-md-4">
							<label class="span-placeholder" for="estados">Identificación que presenta</label>
							<select name="cars" id="estados" :disabled="isDisabledForm" v-model="adicionales.identificacionPresenta" class="v-select vs--single vs--searchable vs-input--input">
								<option :value="tipo.Id" v-for="tipo in identificaciones" :key="">{{ tipo.Nombre }}</option>
							</select>
						</div>
						<div class="col-md-4">
							<span class="span-placeholder">Número de identificación</span>
							<vs-input :disabled="isDisabledForm" v-model="adicionales.numeroIfe" name="name" />
						</div>
						<div class="col-md-4">
							<span class="span-placeholder">Vigencia Identificación</span>
							<vs-input type="date" :disabled="isDisabledForm" v-model="adicionales.vigencia" name="vigencia" />
						</div>
					</div>
					<div class="row w-full">
						<div class="col-md-4">
							<span class="span-placeholder"></span>
						</div>
						<div class="col-md-4">
							<span class="span-placeholder"></span>
						</div>
						<div class="col-md-4">
							<span class="span-placeholder"></span>
						</div>
					</div>
					<br />
					<br />
					<div class="row w-full">
						<h4>Domicilio actual</h4>
						<div class="col-md-4">
							<label class="span-placeholder" for="">¿Es el mismo domicilio del solicitante?</label>
							<vs-switch :disabled="isDisabledForm" v-model="viveMismoDomicilioTitular" />
						</div>
						<div class="col-md-4">
							<label class="span-placeholder" for="estados">País</label>
							<select name="estados" id="estados" :disabled="isDisabledForm" v-model="pais" class="v-select vs--single vs--searchable vs-input--input">
								<option :value="tipo.Id" v-for="tipo in paises" :key="">{{ tipo.Nombre }}</option>
							</select>
						</div>
						<div class="col-md-4">
							<span class="span-placeholder"></span>
						</div>
					</div>
					<br />
					<br />
					<vs-row class="w-full" v-if="!viveMismoDomicilioTitular">
						<div class="col-md-4">
							<span class="span-placeholder">Calle</span>
							<vs-input :disabled="isDisabledForm" v-model="calle" name="name" />
						</div>
						<div class="col-md-4">
							<span class="span-placeholder">Número exterior</span>
							<vs-input :disabled="isDisabledForm" v-model="numeroExterior" name="numeroExterior" maxlength="5" />
							<!-- <input class="vs-input" :disabled="isDisabledForm" v-model="numeroExterior" name="numeroExterior" maxlength="5" onkeyup="javascript:this.value=this.value.toUpperCase();" /> -->
						</div>
						<div class="col-md-4">
							<span class="span-placeholder">Número interior</span>
							<vs-input :disabled="isDisabledForm" v-model="numeroInterior" name="numeroInterior" maxlength="5" />
							<!-- <input class="vs-input" :disabled="isDisabledForm" v-model="numeroInterior" name="numeroInterior" maxlength="5" onkeyup="javascript:this.value=this.value.toUpperCase();" /> -->
						</div>
					</vs-row>
					<div class="row w-full" v-if="!viveMismoDomicilioTitular">
						<div class="col-md-4">
							<span class="span-placeholder">Código postal</span>
							<vs-input :disabled="isDisabledForm" v-model="codigoPostal" name="cp" v-validate="'min:5|max:5'" minlength="5" maxlength="5" v-on:keypress="isNumber($event)" />
							<span class="text-danger text-sm" v-show="errors.has('cp')">El campo debe tener 5 caracteres</span>
						</div>
						<div class="col-md-4">
							<label class="span-placeholder" for="estados">Estado</label>
							<select name="estados" id="estados" :disabled="isDisabledForm" v-model="estado" class="v-select vs--single vs--searchable vs-input--input" @change="getMunicipios()">
								<option :value="tipo.Id" v-for="tipo in estados" :key="">{{ tipo.Nombre }}</option>
							</select>
						</div>

						<div class="col-md-4">
							<label class="span-placeholder" for="municipio">Municipio</label>
							<select name="municipio" id="municipio" :disabled="isDisabledForm" v-model="municipio" class="v-select vs--single vs--searchable vs-input--input" @change="getMunicipiosNombre()">
								<option :value="tipo.Id" v-for="tipo in municipios" :key="">{{ tipo.Nombre }}</option>
							</select>
						</div>
					</div>
					<vs-row class="w-full" v-if="!viveMismoDomicilioTitular">
						<div class="col-md-4">
							<span class="span-placeholder">Colonia</span>
							<vs-input :disabled="isDisabledForm" v-model="colonia" name="name" />
						</div>
					</vs-row>
					<br />
					<br />
					<div class="row w-full">
						<h4>Ocupación Actual</h4>
						<div class="col-md-4">
							<span class="span-placeholder">Empresa</span>
							<vs-input :disabled="isDisabledForm" v-model="ingreso.empresa" name="username" />
						</div>
						<div class="col-md-4">
							<label class="span-placeholder" for="formasEnganche">Giro</label>
							<select name="cars" id="formasEnganche" :disabled="isDisabledForm" v-model="ingreso.giro" class="v-select vs--single vs--searchable vs-input--input">
								<option :value="tipo.Id" v-for="tipo in giros" :key="">{{ tipo.Nombre }}</option>
							</select>
						</div>
						<div class="col-md-4">
							<label class="span-placeholder" for="formasEnganche">Ocupación</label>
							<select name="cars" id="formasEnganche" :disabled="isDisabledForm" v-model="ingreso.ocupacion" class="v-select vs--single vs--searchable vs-input--input">
								<option :value="tipo.Id" v-for="tipo in sectores" :key="">{{ tipo.Nombre }}</option>
							</select>
						</div>
					</div>
					<div class="row w-full">
						<div class="col-md-4">
							<label class="span-placeholder" for="formasEnganche">Actividad</label>
							<select name="cars" id="formasEnganche" :disabled="isDisabledForm" v-model="ingreso.actividad" class="v-select vs--single vs--searchable vs-input--input">
								<option :value="tipo.Id" v-for="tipo in actividades" :key="">{{ tipo.Nombre }}</option>
							</select>
						</div>
						<div class="col-md-4">
							<span class="span-placeholder">Puesto</span>
							<vs-input :disabled="isDisabledForm" v-model="ingreso.puesto" name="name" />
						</div>
						<div class="col-md-4">
							<span class="span-placeholder">Antigüedad Años</span>
							<vs-input :disabled="isDisabledForm" v-model="ingreso.antiguedadAnios" name="name" />
						</div>
						<div class="col-md-4">
							<span class="span-placeholder">Antigüedad Meses</span>
							<vs-input :disabled="isDisabledForm" v-model="ingreso.antiguedadMeses" name="name" />
						</div>
					</div>
					<br />
					<br />

					<div class="row w-full">
						<h4>Actividad Financiera</h4>
						<div class="col-md-4">
							<label class="span-placeholder" for="formasEnganche">Actividad Financiera</label>
							<select name="cars" id="formasEnganche" :disabled="isDisabledForm" v-model="ingreso.actividadFinanciera" class="v-select vs--single vs--searchable vs-input--input">
								<option :value="tipo.Id" v-for="tipo in actividadesFinancieras" :key="">{{ tipo.Nombre }}</option>
							</select>
						</div>
						<div class="col-md-4">
							<label class="span-placeholder" for="formasEnganche">Comprueba ingresos con</label>
							<select name="cars" id="formasEnganche" :disabled="isDisabledForm" v-model="ingreso.comprobanteIngresos" class="v-select vs--single vs--searchable vs-input--input">
								<option :value="tipo.Id" v-for="tipo in comprobantes" :key="">{{ tipo.Nombre }}</option>
							</select>
						</div>
						<div class="col-md-4">
							<span class="span-placeholder">Ingreso mensual</span>
							<vs-input :disabled="isDisabledForm" v-model="ingreso.ingresoMensual" name="username" v-on:keypress="isNumber($event)" />
						</div>
					</div>

					<div class="row w-full">
						<h4>COFINAVIT</h4>
						<div class="col-md-4">
							<label class="span-placeholder" for="saldoSubcuenta">Saldo de subcuenta (SSV)</label>
							<vs-input :disabled="isDisabledForm" v-model="saldoSubcuenta" name="saldoSubcuenta" v-on:keypress="isNumber($event)" />
						</div>
						<div class="col-md-4">
							<label class="span-placeholder" for="creditoInfonavit">Crédito infonavit</label>
							<vs-input :disabled="isDisabledForm" v-model="creditoInfonavit" name="creditoInfonavit" v-on:keypress="isNumber($event)" />
						</div>
						<div class="col-md-4">
							<label class="span-placeholder" for="nss">NSS (solo con cofinavit)</label>
							<vs-input class="" v-validate="'min:11|max:11'" :disabled="isDisabledForm" v-model="adicionales.nss" name="nss"
                v-on:keypress="isNumber($event)" />
              <span class="text-danger text-sm" v-show="errors.has('nss')">El campo debe tener 11 caracteres</span>
						</div>
					</div>
				</vx-card>
			</div>
		</div>
		<!---  <vs-button color="primary" class="float-right" @click="getCaso()">Precalificar en línea</vs-button>-->
		<vs-popup class="holamundo" :title="titulo" :active.sync="popupActive">
			<p>{{ resultado }}</p>
		</vs-popup>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import axios from 'axios'
import { usos } from '@/assets/jsonFiles/usos.js'
import { tipos } from '@/assets/jsonFiles/tipos.js'
import { estadosCiviles } from '@/assets/jsonFiles/estadosCiviles.js'
import { giros } from '@/assets/jsonFiles/giros.js'
import { domicilioOcupaciones } from '@/assets/jsonFiles/domicilioOcupaciones.js'
import { paises } from '@/assets/jsonFiles/paises.js'
import { nacionalidades } from '@/assets/jsonFiles/nacionalidades.js'
import { actividades } from '@/assets/jsonFiles/actividades.js'
import { actividadesFinancieras } from '@/assets/jsonFiles/actividadesFinancieras.js'
import { sectores } from '@/assets/jsonFiles/sectores.js'
import { ocupaciones } from '@/assets/jsonFiles/ocupaciones.js'
import { comprobantes } from '@/assets/jsonFiles/comprobantes.js'
import { identificaciones } from '@/assets/jsonFiles/identificaciones.js'
import { gradosEstudio } from '@/assets/jsonFiles/gradosEstudio.js'
import { municipios } from '@/assets/jsonFiles/municipios.js'
export default {
	components: {
		'v-select': vSelect,
	},
	data() {
		return {
			id: '',
			key: '',
			email: '',
			nombre: '',
			apellidoPaterno: '',
			apellidoMaterno: '',
			telefono: '',
			fechaNacimiento: '',
			ingresosSinImpuestos: '',
			IngresosConImpuestos: '',
			estadoCivil: '',
			regimenMatrimonial: '',
			rfc: '',
			sexo: '',
			viveMismoDomicilioTitular: true,
			calle: '',
			numeroInterior: '',
			numeroExterior: '',
			colonia: '',
			municipio: '',
			estado: '',
			pais: '1',
			curp: '',
			nacionalidad: '1',
			codigoPostal: '',
			dependientesEconomicos: '',
			estados: [],
			municipiosAll: municipios,
			municipios: [],
			municipiosSelecionado: [],
			municipioCasoCompleto: '',
			parentescos: [],
			emailCasoNuevo: { bandera: '' },
			ingreso: {
				ingresoId: '',
				ingresoNeto: '',
				ingresoMensual: '',
				tipoEmpleo: '',
				empresa: '',
				giro: '',
				puesto: '',
				tipoContrato: '',
				comprobanteIngresos: '',
				sectorlaboral: '',
				ocupacion: '',
				actividad: '',
				actividadFinanciera: '',
				antiguedadAnios: '',
				antiguedadMeses: '',
			},

			// COFINAVIT
			saldoSubcuenta: '',
			creditoInfonavit: '',

			solicitante: {},
			tieneIngreso: false,
			adicionales: {
				estadoNacimiento: '',
				titularidadDomicilio: '',
				gradoEstudios: '',
				tiempoOcupandoDomicilioActual: '',
				identificacionPresenta: '',
				numeroIfe: '',
				vigencia: '',
				nss: '',
				antiguedadCiudadAnios: '',
				antiguedadCiudadMeses: '',
				paisNacimiento: '',
				tipoVivienda: '',
			},

			caso: {},
			resultado: '',
			titulo: '',
			popupActive: false,
			usos: usos,
			tipos: tipos,
			estadosCiviles: estadosCiviles,
			giros: giros,
			domicilioOcupaciones: domicilioOcupaciones,
			gradosEstudio: gradosEstudio,
			identificaciones: identificaciones,
			comprobantes: comprobantes,
			sectores: sectores,
			ocupaciones: ocupaciones,
			actividades: actividades,
			actividadesFinancieras: actividadesFinancieras,
			nacionalidades: nacionalidades,
			paises: paises,
			txtButtonProgreso: 'Editar',
			isDisabledForm: true,
			completeInfoCirculo: true,
			NoConyuge: false,
			normaTipo: {
				Numero: 1,
				LetraEspacio: 2,
				LetraNumero: 3,
				LetraNumeroEspacio: 4,
			}
		}
	},
	watch: {
		// Restricciones Coacreditado
		nombre: function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ ]*$/;
			if (!regex.test(newValue)) {
				this.nombre = oldValue;
			}
		},
		apellidoPaterno: function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ ]*$/;
			if (!regex.test(newValue)) {
				this.apellidoPaterno = oldValue;
			}
		},
		apellidoMaterno: function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ ]*$/;
			if (!regex.test(newValue)) {
				this.apellidoMaterno = oldValue;
			}
		},
		telefono: function(newValue, oldValue) {
			let regex = /^[0-9]*$/;
			if (!regex.test(newValue)) {
				this.telefono = oldValue;
			}
		},
		// RFC y CURP tienen un v-mask //
		'adicionales.numeroIfe': function(newValue, oldValue) {
			let regex = /^[0-9]*$/;
			if (!regex.test(newValue)) {
				this.adicionales.numeroIfe = oldValue;
			}
		},
		calle: function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9 ]*$/;
			if (!regex.test(newValue)) {
				this.calle = oldValue;
			}
		},
		numeroExterior: function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9]*$/;
			if (!regex.test(newValue)) {
				this.numeroExterior = oldValue;
			}
		},
		numeroInterior: function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9]*$/;
			if (!regex.test(newValue)) {
				this.numeroInterior = oldValue;
			}
		},
		colonia: function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9 ]*$/;
			if (!regex.test(newValue)) {
				this.colonia = oldValue;
			}
		},
		codigoPostal: function(newValue, oldValue) {
			let regex = /^[0-9]*$/;
			if (!regex.test(newValue)) {
				this.codigoPostal = oldValue;
			}
		},
		'ingreso.empresa': function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9 ]*$/;
			if (!regex.test(newValue)) {
				this.ingreso.empresa = oldValue;
			}
		},
		'ingreso.puesto': function(newValue, oldValue) {
			let regex = /^[a-zA-ZñÑ0-9 ]*$/;
			if (!regex.test(newValue)) {
				this.ingreso.puesto = oldValue;
			}
		},
		'ingreso.antiguedadAnios': function(newValue, oldValue) {
			let regex = /^[0-9]*$/;
			if (!regex.test(newValue)) {
				this.ingreso.antiguedadAnios = oldValue;
			}
		},
		'ingreso.antiguedadMeses': function(newValue, oldValue) {
			let regex = /^[0-9]*$/;
			if (!regex.test(newValue)) {
				this.ingreso.antiguedadMeses = oldValue;
			}
		},
		// Requerimiento 2023-03-28 Cambio en naciolidad, actualizacion 2023-05-02
		nacionalidad: function(newValue, oldValue) {
			if (newValue.Id == '1') {
				this.pais = '1';
			}
			// console.log( 'N: ', newValue, 'O: ', oldValue );
		},
		sector: function (newValue) {
			if (newValue == 1) {
				this.actividades = [
					{ Id: 'ADVO', Nombre: 'ADMINISTRATIVO' },
					{ Id: 'ALBA', Nombre: 'DIRECTOR/SUBDIRECTOR' },
					{ Id: 'CHOF', Nombre: 'CHOFER' },
					{ Id: 'DIGE', Nombre: 'DIRECTOR GENERAL' },
					{ Id: 'EJEC', Nombre: 'EJECUTIVO' },
					{ Id: 'GTES', Nombre: 'GERENTE' },
					{ Id: 'GUAR', Nombre: 'GUARDIA UNIFORMADO' },
					{ Id: 'JUBI', Nombre: 'JUBILADO' },
					{ Id: 'MEDI', Nombre: 'MEDICO' },
					{ Id: 'MTRO', Nombre: 'PROFESOR' },
					{ Id: 'OTRO', Nombre: 'OTRO' },
					{ Id: 'REIN', Nombre: 'REPARADOR / INSTALADOR' },
					{ Id: 'SECR', Nombre: 'SECRETARIA' },
					{ Id: 'SERV', Nombre: 'OTROS SERV. (MESERO, PELUQUERO,ETC.)' },
				]
			} else if (newValue == 2) {
				this.actividades = [
					{ Id: 'AMAC', Nombre: 'AMA DE CASA' },
					{ Id: 'DESE', Nombre: 'DESEMPLEADO CON INGRESOS' },
					{ Id: 'ESTU', Nombre: 'ESTUDIANTE' },
					{ Id: 'INVE', Nombre: 'INVERSIONISTA / ARRENDATARIO' },
					{ Id: 'JUBI', Nombre: 'JUBILADO' },
					{ Id: 'MEDI', Nombre: 'MEDICO' },
					{ Id: 'OTRO', Nombre: 'OTRO' },
					{ Id: 'PROF', Nombre: 'PROFESIONISTA / INDEPENDIENTE' },
					{ Id: 'TAXI', Nombre: 'TAXISTA' },
					{ Id: 'VTAS', Nombre: 'COMISIONISTA / VENTAS' },
				]
			} else if (newValue == 3) {
				this.actividades = [
					{ Id: 'AGGA', Nombre: 'AGRICULTURA  / GANADERIA' },
					{ Id: 'COME', Nombre: 'COMERCIO' },
					{ Id: 'INTR', Nombre: 'INDUSTRIA / SERVICIOS' },
					{ Id: 'MEDI', Nombre: 'MEDICO' },
					{ Id: 'OTRO', Nombre: 'OTRO' },
				]
			} else if (newValue == 4) {
				this.actividades = [
					{ Id: 'ADVO', Nombre: 'ADMINISTRATIVO' },
					{ Id: 'ALBA', Nombre: 'DIRECTOR/SUBDIRECTOR' },
					{ Id: 'CHOF', Nombre: 'CHOFER' },
					{ Id: 'DIGE', Nombre: 'DIRECTOR GENERAL' },
					{ Id: 'EJEC', Nombre: 'EJECUTIVO' },
					{ Id: 'JUBI', Nombre: 'JUBILADO' },
					{ Id: 'MEDI', Nombre: 'MEDICO' },
					{ Id: 'OTRO', Nombre: 'OTRO' },
					{ Id: 'GTES', Nombre: 'GERENTE' },
					{ Id: 'GUAR', Nombre: 'GUARDIA UNIFORMADO' },
					{ Id: 'MTRO', Nombre: 'PROFESOR' },
					{ Id: 'REIN', Nombre: 'REPARADOR / INSTALADOR' },
					{ Id: 'SECR', Nombre: 'SECRETARIA' },
					{ Id: 'SERV', Nombre: 'OTROS SERV. (MESERO, PELUQUERO,ETC.)' },
				]
			}
		},
	},
	computed: {
		sector() {
			return this.ingreso.ocupacion
		},
		ingresoNeto() {
			return this.ingreso.ingresoNeto
		},
		ingresoMensual() {
			return this.ingreso.ingresoMensual
		},
		validateForm() {
			return this.ingreso.tipoEmpleo != '' && this.ingreso.ingresoMensual != '' && this.ingreso.empresa != ''
		},
	},
	mounted() {
		this.id = this.$route.params.id
		this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey
		//this.getApikey()

		this.loadPaises()
		this.loadNaciopnalidades()

		this.dameCaso()
		this.getEstados()
		this.getListaParentescos()
		this.municipios = this.municipiosAll
	},
	methods: {
		normalizaCadena(value, tipo) {
			/*
			1: Numeros 
			2: Letras y espacios 
			3: Letras y numeros 
			4: Letras, numeros y espacios
			*/

			const vocalNormalizacion = (str) => {
				let regex = /([aeiou])[\u0301\u0300]/gi;
				return str.normalize('NFD')
						.replace(regex,"$1")
						.normalize();
			}

			let cadena = "";
			if (value) {
				let data = value.toString();
				cadena = vocalNormalizacion(data);

				let regex = "";

				switch (tipo) {
					case 1:
					regex = /[^0-9]/g;
					cadena = cadena.replaceAll(regex, '');
					break;
					case 2:
					regex = /[^A-Za-zÑñ ]/g;
					cadena = cadena.replaceAll(regex, '');
					break;
					case 3:
					regex = /[^A-Za-zÑñ0-9]/g;
					cadena = cadena.replaceAll(regex, '');
					break;
					case 4:
					regex = /[^A-Za-zÑñ0-9 ]/g;
					cadena = cadena.replaceAll(regex, '');
					break;
					default:
					break;
				}
			}

			// TODO: Eliminar para prod
			// console.log( 'Original: ', value, 'Normalizando: ', cadena );
			return cadena;
		},
		  MostrarMensaje(){

			this.completeInfoCirculo=false

		},

		OcultarMensaje(){

			this.completeInfoCirculo=true

		},
		ActiveForm() {
			//!this.isDisabledForm && this.actualizarInfo() original

			!this.isDisabledForm && this.MostrarMensaje()

			this.isDisabledForm = !this.isDisabledForm
			this.txtButtonProgreso = !this.isDisabledForm ? 'Guardar Progreso' : 'Editar'
		},
		loadPaises() {

			const arrPaises = []
			let count = 2
			for (const pais of paises) {

				const nombre = pais.Nombre
				if ( nombre === 'MÉXICO' ) { 
					arrPaises[0] = pais 
					continue
				}

				if ( nombre === 'ESTADOS UNIDOS' ) { 
					arrPaises[1] = pais 
					continue
				}

				arrPaises[count] = pais

				count++
			}

			this.paises = arrPaises

		},
		loadNaciopnalidades() {

			const arrNacionalidades = []
			let count = 2
			for (const nacionalidad of nacionalidades) {

				const nombre = nacionalidad.Nombre
				if ( nombre === 'MEXICANO (A)' ) { 
					arrNacionalidades[0] = nacionalidad 
					continue
				}

				if ( nombre === 'NORTEAMERICANO (A)' ) { 
					arrNacionalidades[1] = nacionalidad 
					continue
				}

				arrNacionalidades[count] = nacionalidad

				count++
			}

			this.nacionalidades = arrNacionalidades

		},
		getMunicipios() {
			this.municipio = ''
			this.municipios = []
			this.municipios = this.municipiosAll[this.estado - 1]
		},
		getMunicipiosNombre() {
			//alert(this.municipio)
			if (this.municipio != 'undefined') {
				this.municipiosSelecionado = []
				this.municipiosSelecionado = this.municipiosAll[this.estado - 1]

				//alert(this.municipiosSelecionado[this.municipio-1].Nombre)

				//this.municipioCasoCompleto=this.municipiosSelecionado[this.municipio-1].Nombre

				this.municipioCasoCompleto = this.isNombreMunicipio(this.municipio)
			}
		},
		saveScotiaAditionals() {
			let self = this
			var objRequestguardaAdicionales = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraDatosConexiones',
				objSolicitante: {
					EMail: this.email,
					IngresoId: this.ingreso.ingresoId,
					Scotiabank: {
						GiroEmpresa: this.ingreso.giro,
						SectorLaboral: this.ingreso.sectorlaboral,
						Ocupacion: this.ingreso.ocupacion,
						Actividad: this.ingreso.actividad,
						ActividadFinanciera: this.ingreso.actividadFinanciera,
						Comprobante: this.ingreso.comprobanteIngresos,
						Escolaridad: this.adicionales.gradoEstudios,
						DependientesEconomicos: this.dependientesEconomicos,
						TipoIdentificacion: this.adicionales.identificacionPresenta,
						EstadoCivil: this.estadoCivil,
						TipoVivienda: this.adicionales.tipoVivienda,
						PaisDomicilioActual: this.pais,
						AntiguedadEnPoblacionActualAnios: this.adicionales.antiguedadCiudadAnios,
						AntiguedadEnPoblacionActualMeses: this.adicionales.antiguedadCiudadMeses,
						AntiguedadOcupacionActualAnios: this.ingreso.antiguedadAnios,
						AntiguedadOcupacionActualMeses: this.ingreso.antiguedadMeses,
						NumeroIdentificacion: this.adicionales.numeroIfe,
						VigenciaIdentificacion: this.adicionales.vigencia,
						PaisNacimiento: this.adicionales.paisNacimiento,
						EstadoNacimiento: this.adicionales.estadoNacimiento,
						// Nacionalidad: this.nacionalidad,
						Nacionalidad: {
							id: this.nacionalidad.Id,
							name: this.nacionalidad.Nombre,
							updateScotia: true,
						},

						Municipio: this.municipio,

						// COFINAVIT
						SaldoSubcuenta: this.saldoSubcuenta,
						CreditoInfonavit: this.creditoInfonavit,
						Nss: this.adicionales.nss
					},
				},
			}
			this.$axios
				.post('/', objRequestguardaAdicionales, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {

					if (response.data.intEstatus == 0) {
						this.dameSolicitante(true)

						this.emailCasoNuevo.bandera = 'CoacreditadoCompleto'
						this.$emit('bandera', this.emailCasoNuevo.bandera)
						this.ActualizaBanderaPersonales(false)
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en Guarda adicionales Scotia',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		eliminarCoacreditado() {
			var eliminarCoacreditado = {
				strApiKey: this.key,
				strMetodo: 'BorrarCoacreditado',
				objCaso: {
					Id: this.id,
				},
			}
			this.$axios
				.post('/', eliminarCoacreditado, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.emailCasoNuevo.bandera = 'CoacreditadoIncompletas'
						this.$emit('bandera', this.emailCasoNuevo.bandera)

						this.ActualizaBanderaPersonales(true)

						this.$vs.notify({
							title: `Se eliminó exitosamente`,
							position: 'top-right',
						})
						this.email = ''
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en BorrarCoacreditado',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		ActualizaBanderaPersonales(tipo) {
			let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'ActualizaBanderaScotiabankCoacreditado',
				objCaso: {
					Id: this.id,
					Bandera: 'Coacreditado',
					tipo: tipo,
				},
			}
			this.$axios
				.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						if (tipo == false) {
							this.emailCasoNuevo.bandera = 'CoacreditadoCompleto'
							this.$emit('bandera', this.emailCasoNuevo.bandera)
						} else {
							this.emailCasoNuevo.bandera = 'CoacreditadoIncompletas'
							this.$emit('bandera', this.emailCasoNuevo.bandera)
						}
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en Actualiza',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getCaso() {
			let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'DameCaso',
				objCaso: {
					Id: this.id,
				},
			}
			this.$axios
				.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.caso = response.data.objContenido

						// console.log({ coacreditado: this.caso })

						if (response.data.objContenido.ScotiabankCoacreditado == 'Completo') {
							this.emailCasoNuevo.bandera = 'CoacreditadoCompleto'
							this.$emit('bandera', this.emailCasoNuevo.bandera)
						}

						this.precalificacionLinea()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameCaso',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		precalificacionLinea() {
			this.$vs.loading({
				container: '#container',
				scale: 0.6,
			})
			let self = this
			var objRequestSolicitaCaso = {
				strApiKey: this.key,
				strMetodo: 'SolicitarEnLinea',
				objCaso: {
					Id: this.id,
					Banco: 11, //hsbc 6: santander 10: Scotiabank 11
					EMail: this.$store.state.AppActiveUser.EMail,
					DatosCredito: {
						Plazo: this.caso.Plazos[0],
						Programa: this.caso.Programas[0],
						Esquema: this.caso.Esquemas[0],
						Monto: this.caso.MontoSolicitado,
						ValorPropiedad: this.caso.ValorInmueble,
					},
				},
			}
			this.$axios
				.post('/', objRequestSolicitaCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.intEstatus == 0) {
						this.resultado = response.objContenido.strInformacion
						this.titulo = response.objContenido.strTitle
						this.popupActive = true
					} else {
						this.resultado = response.data.objContenido.strTitle
						this.titulo = response.data.objContenido.strInformacion + ' ' + response.data.objContenido.strError
						this.popupActive = true
					}
					this.$vs.loading.close('#container > .con-vs-loading')
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		addIngreso() {
			if (this.tieneIngreso) {
				this.editarIngreso()
			} else {
				this.registraIngreso()
			}
			this.saveScotiaAditionals()
		},
		registraIngreso() {
			let self = this
			var objRequestListaCasos = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraIngresos',
				objSolicitante: {
					EMail: this.email,
					Ingresos: [
						{
							IngresosMensuales: this.ingreso.ingresoMensual,
							IngresosNetos: this.ingreso.ingresoMensual,
							TipoEmpleo: this.ingreso.tipoEmpleo,
							Empresa: this.ingreso.empresa,
							Puesto: this.ingreso.puesto,
						},
					],
				},
			}
			this.$axios
				.post('/', objRequestListaCasos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Datos guardados exitosamente`,
							position: 'top-right',
						})
					}
					this.$vs.loading.close('#container > .con-vs-loading')
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		editarIngreso() {
			let self = this
			var objRequestRegistraCuentasBancarias = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'EditarIngresos',
				objSolicitante: {
					EMail: this.email,
					Ingresos: [
						{
							Id: this.ingreso.ingresoId,
							IngresosMensuales: this.ingreso.ingresoMensual,
							IngresosNetos: this.ingreso.ingresoMensual,
							TipoEmpleo: this.ingreso.tipoEmpleo,
							Empresa: this.ingreso.empresa,
							Puesto: this.ingreso.puesto,
						},
					],
				},
			}

			this.$axios
				.post('/', objRequestRegistraCuentasBancarias, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Datos editados exitosamente`,
							position: 'top-right',
						})
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraCuentasBancarias',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		async actualizarInfo() {
			let self = this
			/* let sexo
      if(this.sexo== 'H'){
        sexo=1
      }else{
        sexo=2
      }*/
			const objRequestRegistraSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraSolicitante',
				objSolicitante: {
					EMail: this.email,
					Nombres: this.nombre,
					ApellidoPaterno: this.apellidoPaterno,
					ApellidoMaterno: this.apellidoMaterno,
					TelefonoCelular: this.telefono,
					FechaNacimiento: this.fechaNacimiento,
					Rfc: this.rfc,
					Sexo: this.sexo,
					Calle: this.calle,
					NumeroExterior: this.numeroExterior,
					NumeroInterior: this.numeroInterior,
					Colonia: this.colonia,
					Municipio: this.municipioCasoCompleto,
					Estado: this.estado,
					Curp: this.curp,
					CodigoPostal: this.codigoPostal,
					RegimenMatrimonial: this.regimenMatrimonial,
					ViveMismoDomicilioTitular: this.viveMismoDomicilioTitular,
					IdCaso: this.id,
					ScotiaConyugeNombre: 'aplica'

				},
			}

			// Validar campos obligatorios
			const validator = await this.$validator.validateAll()

			const response = await this.$axios.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })

			const { intEstatus, objContenido } = response
			
			//console.log(response.data.objContenido)
			
			let dataSolicitante = response.data.objContenido
			if (dataSolicitante.Ingresos[0]) {
				this.tieneIngreso = true
				this.ingreso.ingresoId = dataSolicitante.Ingresos[0].Id				
			}

			if ( intEstatus ) {

				this.$vs.notify({
					title: 'Ocurrio un error en RegistraSolicitante Coacreditado',
					text: response.data.strError,
					color: 'danger',
					position: 'top-right',
				})

				return false

			}

			this.addIngreso()

			this.txtButtonProgreso = 'Editar'
			this.isDisabledForm = true
			this.completeInfoCirculo=true
		},
		dameCaso() {
			let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'DameCaso',
				objCaso: {
					Id: this.id,
				},
			}
			this.$axios
				.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						if (response.data.objContenido.Coacreditado[0]) {

							if(response.data.objContenido.Coacreditado[0].Parentesco==1){
								this.email = response.data.objContenido.Coacreditado[0].EMail
								this.dameSolicitante()
								this.NoConyuge=true
							}else{
								this.email =''
								this.NoConyuge=false
							}


							
						}
				
							//this.dameSolicitante()
						
						
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameCaso',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		dameSolicitante(getData) {
			let self = this
			var objRequestDameSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'DameSolicitante',
				objSolicitante: {
					EMail: this.email,
				},
			}
			this.$axios
				.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						const solicitante = response.data.objContenido

						// console.log(solicitante)

						// Normalizacion de datos solicitados
						this.nombre = this.normalizaCadena(solicitante.Nombres, this.normaTipo.LetraEspacio);
						this.apellidoPaterno = this.normalizaCadena(solicitante.ApellidoPaterno, this.normaTipo.LetraEspacio);
						this.apellidoMaterno = this.normalizaCadena(solicitante.ApellidoMaterno, this.normaTipo.LetraEspacio);
						this.telefono = this.normalizaCadena(solicitante.TelefonoCelular, this.normaTipo.Numero);
						this.telefono = this.telefono.replace(/ /g, '').replace(/-/g, '')
						this.fechaNacimiento = solicitante.FechaNacimiento
						// TODO: El v-mask afecta el formato
						this.rfc = this.normalizaCadena(solicitante.Rfc, this.normaTipo.LetraNumero);

						this.sexo = solicitante.Sexo
						this.viveMismoDomicilioTitular = solicitante.ViveMismoDomicilioTitular
						this.calle = this.normalizaCadena(solicitante.Calle, this.normaTipo.LetraNumeroEspacio); 
						this.numeroExterior = this.normalizaCadena(solicitante.NumeroExterior, this.normaTipo.LetraNumero);
						this.numeroInterior = this.normalizaCadena(solicitante.NumeroInterior, this.normaTipo.LetraNumero);
						this.colonia = this.normalizaCadena(solicitante.Colonia, this.normaTipo.LetraNumeroEspacio);
						this.estado = solicitante.Estado
						// TODO: El v-mask afecta el formato
						this.curp = this.normalizaCadena(solicitante.Curp, this.normaTipo.LetraNumero);
						this.codigoPostal = this.normalizaCadena(solicitante.CodigoPostal, this.normaTipo.Numero);

						// this.nacionalidad = solicitante.ScotiaNacionalidad
						// Cambio en nacionalidad por mejora
						if (solicitante.ScotiaNacionalidad && solicitante.Nacionalidad) {
							this.nacionalidad = {
								Id: solicitante.ScotiaNacionalidad,
								Nombre: solicitante.Nacionalidad,
							};
						} else {
							this.nacionalidad = '';
						}


						this.estadoCivil = solicitante.ScotiaEstadoCivil
						this.regimenMatrimonial = solicitante.RegimenMatrimonial
						this.adicionales.tiempoOcupandoDomicilioActual = solicitante.TiempoViviendoCiudad
						this.adicionales.estadoNacimiento = solicitante.ScotiaEstadoNacimiento
						this.adicionales.paisNacimiento = solicitante.ScotiaPaisNacimiento

						this.adicionales.nss = this.normalizaCadena(solicitante.Nss, this.normaTipo.Numero);

						this.adicionales.numeroIfe = this.normalizaCadena(solicitante.ScotiaNumeroIdentificacion, this.normaTipo.Numero);
						this.adicionales.vigencia = solicitante.VigenciaIdentificacion //solicitante.ScotiaVigenciaIdentificacion
						this.adicionales.gradoEstudios = solicitante.ScotiaEscolaridad
						this.adicionales.antiguedadCiudadAnios = solicitante.ScotiaAntiguedadEnPoblacionActualAnios
						this.adicionales.antiguedadCiudadMeses = solicitante.ScotiaAntiguedadEnPoblacionActualMeses
						this.pais = solicitante.ScotiaPaisDomicilioActual
						this.adicionales.identificacionPresenta = solicitante.ScotiaTipoIdentificacion
						this.adicionales.tipoVivienda = solicitante.ScotiaTipoVivienda
						if (!getData) {
							this.municipios = this.municipios[parseInt(solicitante.Estado) - 1]
						}
						this.municipio = solicitante.ScotiaMunicipio
						if (solicitante.Ingresos[0]) {
							this.tieneIngreso = true
							let ingreso = solicitante.Ingresos[0]
							this.ingreso.tipoEmpleo = ingreso.TipoEmpleoId
							this.ingreso.ingresoNeto = ingreso.IngresosNetos
							this.ingreso.ingresoMensual = ingreso.IngresosMensuales
							this.ingreso.empresa = this.normalizaCadena(ingreso.Empresa, this.normaTipo.LetraNumeroEspacio);
							this.ingreso.ingresoId = ingreso.Id
							this.ingreso.puesto = this.normalizaCadena(ingreso.Puesto, this.normaTipo.LetraNumeroEspacio);
							this.ingreso.giro = ingreso.ScotiaGiroEmpresa
							this.ingreso.sectorlaboral = ingreso.ScotiaSectorLaboral
							this.ingreso.ocupacion = ingreso.ScotiaOcupacion
							this.ingreso.actividad = ingreso.ScotiaActividad
							this.ingreso.actividadFinanciera = ingreso.ScotiaActividadFinanciera
							this.ingreso.comprobanteIngresos = ingreso.ScotiaComprobante
							this.dependientesEconomicos = ingreso.ScotiaDependientesEconomicos
							this.ingreso.antiguedadAnios = this.normalizaCadena(ingreso.ScotiaAntiguedadOcupacionActualAnios, this.normaTipo.Numero);
							this.ingreso.antiguedadMeses = this.normalizaCadena(ingreso.ScotiaAntiguedadOcupacionActualMeses, this.normaTipo.Numero);

							if (solicitante.Nombres == '' || solicitante.ApellidoPaterno == '' || solicitante.ApellidoMaterno == '' || solicitante.TelefonoCelular == '') {
								this.emailCasoNuevo.bandera = 'CoacreditadoIncompletas'
								this.$emit('bandera', this.emailCasoNuevo.bandera)

								this.ActualizaBanderaPersonales(true)
							}
						}

						// COFINAVIT
						this.saldoSubcuenta = solicitante.ScotiaSaldoSubcuenta
						this.creditoInfonavit = solicitante.ScotiaCreditoInfonavit
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameSolicitante',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getApikey() {
			if (!this.$cookie.get('key')) {
				this.$router.push('/login')
			} else {
				this.key = this.$cookie.get('key')
			}
		},
		getEstados() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaEstados',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.estados = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaEstados',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getListaParentescos() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaParentescos',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.parentescos = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaParentescos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		isNumber: function (evt) {
			evt = evt ? evt : window.event
			var charCode = evt.which ? evt.which : evt.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
				evt.preventDefault()
			} else {
				return true
			}
		},
		isNombreMunicipio: function (id) {
			let elemento = id
			let datos = this.municipiosAll[this.estado - 1]
			let elemento_buscado = {}
			datos.forEach(function (item) {
				if (item.Id == id) {
					elemento_buscado = item.Nombre
				}
			})
			return elemento_buscado
		},
	},
}
</script>

<style lang="css">
.label-select{
  margin-top: 3%;
}
.input-custom .vs-con-input input{
  padding: .5rem !important;
}
.vs-component.vs-con-input-label.vs-input.vs-input-primary {
  border: none;
}
label.span-placeholder,
span.vs-radio--label {
  color: #868D96 !important;
}
.accordion label{
  display:flex;
}
</style>
