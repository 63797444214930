<template lang="html">
    <div id="container">        
        <div class="vx-row">           
            <div class="vx-col w-full">
                <vx-card title="HSBC" class="mb-base">
                    <div class="row d-flex justify-content-end">
                        <div class="col-6 col-xl-3 text-end">
                            <button class="btn btn-second btn-large" @click="ActiveForm()" :disabled="disabledFlag">{{ txtButtonProgreso }}</button>                                    
                        </div>
                        <div class="col-6 col-xl-3">
                            <vs-button class="btn btn-blue btn-large" @click="precalificacionLinea()" :disabled="activePrecaExterna">Enviar precalificación</vs-button>
                        </div>
                    </div><br/><br/><br/><br/>
                    
                    <div class="row w-full">

                        <div class="col-md-4">                            
                            <label class="span-placeholder" for="apellidoPaterno">{{apellidoPaternoText}}</label>
                            <input class="v-select vs--single vs--searchable vs-input--input" v-validate="'required|alpha_spaces'" :disabled="isDisabledForm" v-model="apellidoPaterno" name="apellidoPaterno" />
                            <span class="text-danger text-sm" v-show="errors.has('apellidoPaterno')">Campo requerido</span>
                        </div>                               
                        <div class="col-md-4">                            
                            <label class="span-placeholder" for="apellidoMaterno">{{apellidoMaternoText}}</label>
                            <input class="v-select vs--single vs--searchable vs-input--input" v-validate="'required|alpha_spaces'" :disabled="isDisabledForm" v-model="apellidoMaterno" name="apellidoMaterno" />
                            <span class="text-danger text-sm" v-show="errors.has('apellidoMaterno')">Campo requerido</span>
                        </div> 
                        <div class="col-md-4">                            
                            <label class="span-placeholder" for="nombres">{{ nombresText }}</label>
                            <input class="v-select vs--single vs--searchable vs-input--input" v-validate="'required|alpha_spaces'" :disabled="isDisabledForm" v-model="nombre" name="nombres" />
                            <span class="text-danger text-sm" v-show="errors.has('nombres')">Campo requerido</span>
                        </div>
                       
                    </div>   
                    <div class="row w-full">
                        <div class="col-md-2" id="sexoS">  
                            <label class="span-placeholder" for="sexo">Sexo</label>                        
                            <vs-radio :disabled="isDisabledForm" v-model="sexo" vs-name="sexo" name="sexo" vs-value="H">{{sexoH}}</vs-radio>
                            <span class="text-danger text-sm" v-show="errors.has('sexo')">Campo requerido</span>
                        </div>
                        <div class="col-md-2">
                            <label class="" for="sexo">Sexo</label>
                            <vs-radio :disabled="isDisabledForm" v-model="sexo" vs-name="sexo" name="sexo" vs-value="M">{{sexoM}}</vs-radio>
                        </div>
                                                                        
                        <div class="col-md-5  responsive-input ">
                            <span class="span-placeholder" for="fechaNacimiento_dd">Fecha de nacimiento</span>
                            <div class="row w-full responsive-input">
                                <div class="col-md-3">
                                    <input class="vs--single vs--searchable vs-input--input" type="text" :disabled="isDisabledForm" v-model="fechaNacimiento_dd" name="fechaNacimiento_dd" v-validate="'required|max:2'" v-on:keypress="SoloNumero($event)" placeholder="dia"/>
                                    <span class="text-danger text-sm" v-show="errors.has('fechaNacimiento_dd')">max 2 caracteres</span>
                                </div>
                                <div class="col-md-5">
                                    <select name="fechaNacimiento_mm" id="fechaNacimiento_mm" :disabled="isDisabledForm" v-model="fechaNacimiento_mm" v-validate="'required'" class="vs-input--input" placeholder="mes">
                                        <option :value='tipo.id' v-for="tipo in meses">{{tipo.Nombre}}</option>
                                    </select>
                                </div>
                                <div class="col-md-4">
                                    <input class="vs--single vs--searchable vs-input--input" type="text" :disabled="isDisabledForm" v-model="fechaNacimiento_aa" name="fechaNacimiento_aa" v-validate="'required|min:4|max:4'" v-on:keypress="SoloNumero($event)" placeholder="Año" />
                                    <span class="text-danger text-sm" v-show="errors.has('fechaNacimiento_aa')">formato AAAA</span>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3  responsive-input ">
                            <span class="span-placeholder" for="nacionalidad">Nacionalidad</span>
                            <select name="nacionalidad" id="nacionalidad" :disabled="isDisabledForm" v-model="nacionalidad" v-validate="'required'" class="v-select vs--single vs--searchable vs-input--input" style="margin-top: 5px;">
                                <option :value='tipo.id' v-for="tipo in nacionalidades"> {{ tipo.Nombre }}</option>                                
                            </select>
                                            <span class="text-danger text-sm" v-show="errors.has('nacionalidad')">Campo requerido</span>
                        </div>
                    </div>    
                    <div class="row w-full">
                        <div class="col-md-4 responsive-select">
                            <label class="span-placeholder" for="pais">País</label>
                            <select name="pais" id="pais" :disabled="isDisabledForm" v-model="pais" class="v-select vs--single vs--searchable vs-input--input hgs">
                                <option :value='tipo.Nombre' v-for="tipo in listaPaises" >{{tipo.Nombre}}</option>
                            </select>
                        </div>
                        <div class="col-md-4">                            
                            <label class="span-placeholder" for="rfc">RFC</label>
                            <input class="v-select vs--single vs--searchable vs-input--input hgs" :disabled="isDisabledForm" v-model="rfc" name="rfc" v-validate="'min:13|max:13'" v-mask="'AAAA######NNN'" onkeyup="javascript:this.value=this.value.toUpperCase();"/>
                            <span class="text-danger span-placeholder" v-show="errors.has('rfc')">El campo debe tener 13 caracteres</span>
                            
                        </div>
                        <div class="col-md-4">
                            <label class="span-placeholder" for="estadoCivil">Estado Civil</label>
                            <select name="estadoCivil" id="estadoCivil" :disabled="isDisabledForm" v-model="estadoCivil" v-validate="'required'" @change="updateRegimenMatrimonial()" class="v-select vs--single vs--searchable vs-input--input hgs">
                                <option :value='tipo.id' v-for="tipo in estadosCiviles" >{{tipo.Nombre}}</option>
                            </select>
                                            <span class="text-danger text-sm" v-show="errors.has('estadoCivil')">Campo requerido</span>
                        </div>

                    </div>    
                    <div class="row w-full">
                        <div class="col-md-4">
                            <label class="span-placeholder" for="regimen">{{ regimenMatromonialText }}</label>
                            <select name="regimen" id="regimen" :disabled="isDisabledForm" v-model="regimenMatrimonial" class="v-select vs--single vs--searchable vs-input--input hgs" disabled>
                                <option :value='tipo.id' v-for="tipo in regimenes" >{{tipo.Nombre}}</option>
                            </select>
                        </div>
                    </div>    

                    <br/><br/><br/>
                    <div class="row w-full">
                        <h4>Empleos e ingresos</h4>  
                        <div class="col-md-4">
                            <label class="span-placeholder" for="domicilioOcupaciones">{{tipoActividadText}}</label>
                            <select name="cars" id="domicilioOcupaciones" :disabled="isDisabledForm" v-model="ingresos.tipoActividad" @change="actualizaMontoIngreso()" class="v-select vs--single vs--searchable vs-input--input hgs" >
                                <option :value='tipo.id' v-for="tipo in ingresos.TipoActividad" >{{tipo.Nombre}}</option>
                            </select>
                        </div> 
                        <div class="col-md-4">                            
                            <label class="span-placeholder" for="ingresoBruto">Ingreso bruto</label>
                            <input class="v-select vs--single vs--searchable vs-input--input hgs"  id="ingresos.ingresoBruto" :disabled="isDisabledForm" v-model="ingresos.ingresoBruto" name="ingresoBruto" v-on:keypress="SoloNumero($event)"  @change="formateaIngresoBruto()" disabled/>
                            
                        </div>  
                        <div class="col-md-4">                            
                            <label class="span-placeholder" for="ingresoNeto">Ingreso neto</label>
                            <input class="v-select vs--single vs--searchable vs-input--input hgs"  id="ingresos.ingresoNeto" :disabled="isDisabledForm" v-model="ingresos.ingresoNeto" name="ingresoNeto" v-on:keypress="SoloNumero($event)"  @change="formateaIngresoNeto()" disabled/>

                        </div>    
                    </div>
                    <div class="row w-full">
                        <div class="col-md-4 responsive-select">                            
                            <label class="span-placeholder" for="formaComprobarIngresos">{{ formaComprobarIngresoText }}</label>
                            <select name="cars" id="formaComprobarIngresos" v-model="ingresos.comprobanteIngresos" class="v-select vs--single vs--searchable vs-input--input hgs" :disabled="isDisabledForm" disabled>                                
                                <option :value="tipo.id" v-for="tipo in ingresos.ComprobanteIngresos" >{{ tipo.Nombre }}</option>
                            </select>
                        </div>
                        <div class="col-md-5">                            
                            <label class="span-placeholder" for="ingresos.antiguedadLaboral">{{antiguedadLaboralText}}</label>
                            <input class="v-select vs--single vs--searchable vs-input--input hgs sizeIputAntiguedadLaboral" v-validate="'required'" :disabled="isDisabledForm" v-model="ingresos.antiguedadLaboral" name="ingresos.antiguedadLaboral" v-on:keypress="SoloNumero($event)"/><br/>
                            <span class="text-danger text-sm" v-show="errors.has('ingresos.antiguedadLaboral')">Campo requerido</span>
                        </div> 
                    </div>
                    <div class="row w-full">
                        <h4>Domicilio Actual</h4> 
                        <div class="col-md-3">                            
                            <label class="span-placeholder" for="cpIngreso">{{ codigoPostalText }}</label>
                            <input class="v-select vs--single vs--searchable vs-input--input hgs" :disabled="isDisabledForm" v-model="codigoPostal" name="cpIngreso" v-validate="'required|min:5|max:5'"
                                v-on:keypress="isNumber($event)" />
                            <span class="text-danger text-sm" v-show="errors.has('cpIngreso')">El campo debe tener 5 caracteres</span>
                        </div>

                        <div class="col-md-3">                            
                            <label class="span-placeholder" for="calle">{{calleText}}</label>
                            <input class="v-select vs--single vs--searchable vs-input--input hgs" v-validate="'required'" :disabled="isDisabledForm" v-model="calle" name="calle" />
                            <span class="text-danger text-sm" v-show="errors.has('calle')">Campo requerido</span>                            
                        </div>                       
                        <div class="col-md-3">                            
                            <label class="span-placeholder" for="numeroExterior">{{numeroExteriorText}}</label>
                            <input class="v-select vs--single vs--searchable vs-input--input hgs" :disabled="isDisabledForm" v-model="numeroExterior" name="numeroExterior" v-validate="'required'" maxlength="5" />
                            <span class="text-danger text-sm" v-show="errors.has('numeroExterior')">Campo requerido</span>
                        </div>

                        <div class="col-md-3">                            
                            <label class="span-placeholder" for="numeroInterior">{{ numeroInteriorText }}</label>                         
                            <input class="v-select vs--single vs--searchable vs-input--input hgs" :disabled="isDisabledForm" v-model="numeroInterior" name="numeroInterior" maxlength="5" />
                        </div>
                    </div>
                    <div class="row w-full">
                        <div class="col-md-4">                            
                            <label class="span-placeholder" for="antiguedadDomicilioActual">{{antiguedadEnDomicilioText}}</label>
                            <input class="v-select vs--single vs--searchable vs-input--input hgs sizeIputAntiguedadLaboral" v-validate="'required'" :disabled="isDisabledForm" v-model="antiguedadDomicilioActual" name="antiguedadDomicilioActual" v-on:keypress="SoloNumero($event)"/><br/>
                            <span class="text-danger text-sm" v-show="errors.has('antiguedadDomicilioActual')">Campo requerido</span>
                        </div>     
                    </div>

                </vx-card>
            </div>
        </div>

        <vs-prompt color="success" title="Aviso" cancel-text="Aceptar" :active.sync="activePrompt">
            <div class="popup_success">
                Recuerda terminar de llenar los <br/> formularios directo desde el portal <br/> de HSBC
            </div>
            
        </vs-prompt>
        
    </div>
</template>


<script>
import vSelect from 'vue-select'
import { PaisesHSBC } from '@/assets/jsonFiles/PaisesHSBC.js'

export default { 

    components: {
        'v-select': vSelect,        
    },
    props: {
        varianteId: {
            type: Number,
            required: true
        },
        varianteName: {
            type: String,
            required: true
        }
    },
    data(){
        return {
            id: '',
            email: '',
            key: '',
            nombre: '',
			apellidoPaterno: '',
			apellidoMaterno: '',            
            sexo: '',
            nacionalidad: '1',
            estadoCivil: '',
            estadosCiviles: [
                {id: 1, Nombre: 'Casado'},
                {id: 2, Nombre: 'Divorciado'},
                {id: 3, Nombre: 'Soltero'},
                {id: 4, Nombre: 'Union libre'},
                {id: 5, Nombre: 'Viudo'},
            ],
            regimenMatrimonial: '',            
            regimenes: [                
                {id: 1, Nombre: 'Separados'},
                {id: 2, Nombre: 'Mancomunado'},
            ],
            pais: '',
            isDisabledForm: true,
            txtButtonProgreso: 'Editar',
            rfc: '',
            estadoCivil: '',
            comprobantes: '',
            codigoPostal: '',
            calle: '',
            numeroInterior: '',
            numeroExterior: '',
            antiguedadDomicilioActual: '',
            fechaNacimiento_dd: '',
            fechaNacimiento_mm: '',
            fechaNacimiento_aa: '',
            activePrompt: false,
            


            ingresos: {
                ingresoId: '',
                tipoActividad: '',
                ingresoBruto: '',
                ingresoNeto:'',
                comprobanteIngresos : '',
                antiguedadLaboral: '',
                FechaInicio: '',
                TipoActividad: [
                    {id:1, Nombre: 'Asalariado'},
                    {id:42, Nombre: 'Empresario'},
                    {id:14, Nombre: 'Independiente (Honorarios)'}
                ],
                ComprobanteIngresos: [
                    {id: 4, Nombre: 'Declaracion de impuestos'},
                    {id: 18, Nombre: 'Estados de cuenta'},
                ]

            },

            meses: [
                {id: "01", Nombre: "Enero"},
                {id: "02", Nombre: "Febrero"},
                {id: "03", Nombre: "Marzo"},
                {id: "04", Nombre: "Abril"},
                {id: "05", Nombre: "Mayo"},
                {id: "06", Nombre: "Junio"},
                {id: "07", Nombre: "Julio"},
                {id: "08", Nombre: "Agosto"},
                {id: "09", Nombre: "Septiembre"},
                {id: "10", Nombre: "Octubre"},
                {id: "11", Nombre: "Noviembre"},
                {id: "12", Nombre: "Diciembre"},                                
            ],

            nacionalidades: [
                {id: 0, Nombre: 'Seleccione...'},
                {id: 1, Nombre: 'Mexicano'},
                {id: 2, Nombre: 'Extranjero'}
            ],

            listaPaises : PaisesHSBC,
            ingresoNuevo : false,
            idIngreso: '',
           
            caso: {
                Plazos: '',
                Programas: '',
                Esquemas: '',
                Destino: '',
                MontoSolicitado: '',
                ValorInmueble: ''


            },            
            
            //titulos de los inputs
            apellidoPaternoText : 'Apellido Paterno',
            apellidoMaternoText : 'Apellido Materno',
            nombresText : 'Nombre(s)',
            sexoH: 'Hombre',
            sexoM: 'Mujer',
            regimenMatromonialText: 'Régimen matrimonial',
            tipoActividadText: 'Tipo de actividad',
            antiguedadLaboralText : 'Antiguedad laboral empleo actual (años)',
            formaComprobarIngresoText: 'Forma de comprobar ingresos',
            codigoPostalText: 'Código postal',
            calleText: 'Calle',
            numeroExteriorText: 'Número exterior',
            numeroInteriorText: 'Número interior',
            antiguedadEnDomicilioText: 'Antiguedad en el domicilio actual (años)'

        }
    },
    created() {
        this.updateText();
        window.addEventListener('resize', this.updateText);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateText);
    },
    mounted() {
        this.id = this.$route.params.id
		this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey
                
        this.normalizeArray()
        this.dameCaso()              
    },
    methods: {

         normalizeString(str) {
         return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        },
        normalizeArray(){
            for (let index = 0; index < this.listaPaises.length; index++) {
                
                this.listaPaises[index].Nombre = this.normalizeString(this.listaPaises[index].Nombre);               
                //console.log("normalizae ? " + this.listaPaises[index].Nombre)    
            }                        
                       
            

        },
        updateText() {
           
            if (window.innerWidth <= 1200) { //768
                this.apellidoPaternoText = 'Apellido P';
                this.apellidoMaternoText = 'Apellido M';
                this.sexoH = 'H';
                this.sexoM = 'M';
                this.regimenMatromonialText =  'Reg. matrimonial';
                this.tipoActividadText = 'Actividad';
                this.antiguedadLaboralText = 'Antiguedad Laboral';
                this.formaComprobarIngresoText = 'Compr. Ingresos';
                this.codigoPostalText =  'C.P.',
                this.calleText =  'Calle',
                this.numeroExteriorText =  'Número ext.',
                this.numeroInteriorText =  'Número int.',
                this.antiguedadEnDomicilioText = 'Antiguedad';                
            }else{
                this.apellidoPaternoText = 'Apellido Paterno';
                this.apellidoMaternoText = 'Apellido Materno';
                this.sexoH = 'Hombre';
                this.sexoM = 'Mujer';
                this.regimenMatromonialText =  'Régimen matrimonial',
                this.tipoActividadText = 'Tipo de actividad';
                this.antiguedadLaboralText = 'Antiguedad laboral empleo actual (años)';
                this.formaComprobarIngresoText = 'Forma de comprobar ingresos';
                this.codigoPostalText =  'Código postal';
                this.calleText =  'Calle';
                this.numeroExteriorText =  'Número exterior';
                this.numeroInteriorText =  'Número interior';
                this.antiguedadEnDomicilioText =  'Antiguedad en el domicilio actual (años)';      
                                
            }
        },
        ActiveForm() {
			!this.isDisabledForm && this.actualizarInfo(false)

			this.isDisabledForm = !this.isDisabledForm
			this.txtButtonProgreso = !this.isDisabledForm ? 'Guardar Progreso' : 'Editar'
            

            if (this.estadoCivil == 1) {
                document.getElementById('regimen').disabled = false
            }

            if (this.ingresos.tipoActividad == 42) {
                document.getElementById('ingresos.ingresoBruto').disabled = false 
                document.getElementById('formaComprobarIngresos').disabled = false   
            }else if(this.ingresos.tipoActividad == 14){
                document.getElementById('ingresos.ingresoNeto').disabled = false
                document.getElementById('formaComprobarIngresos').disabled = false   
            } else if(this.ingresos.tipoActividad == 1){
                document.getElementById('ingresos.ingresoNeto').disabled = false
            }                       
		},        
        SoloNumero: function(evt){
			evt = evt ? evt : window.event
			var charCode = evt.which ? evt.which : evt.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57)) {
				evt.preventDefault()
			} else {
				return true
			}
		},
        dameCaso() {
			let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'DameCaso',
				objCaso: {
					Id: this.id,
				},
			}
			this.$axios
				.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
                        let data = response.data.objContenido						
                        this.email = data.Solicitante

                        this.caso.Plazos = data.Plazos,
						this.caso.Programas = data.Programas,
						this.caso.Esquemas = data.Esquemas,
                        this.caso.Destino = data.Destino,
                        this.caso.ValorInmueble = data.ValorInmueble,
                        this.caso.MontoSolicitado = data.MontoSolicitado
                        
                        

						this.dameSolicitante()
						
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameCaso',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
        dameSolicitante() {
			let self = this
			var objRequestDameSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'DameSolicitante',
				objSolicitante: {
					EMail: this.email,
				},
			}
			this.$axios
				.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						let solicitante = response.data.objContenido
                        let solicitanteIngresos = solicitante.Ingresos


                        this.nombre = solicitante.Nombres + ' ' + solicitante.SegundoNombre
                        this.apellidoMaterno = solicitante.ApellidoMaterno
                        this.apellidoPaterno = solicitante.ApellidoPaterno

                        if (solicitante.Sexo == 1) {
                            this.sexo = 'H'    
                        }else if(solicitante.Sexo == 2){
                            this.sexo = 'M'
                        }
                        var fechaNac = (solicitante.FechaNacimiento).split('-');                        
                        this.fechaNacimiento_dd = fechaNac[2]
                        this.fechaNacimiento_mm = fechaNac[1]
                        this.fechaNacimiento_aa = fechaNac[0]

                        if(solicitante.Nacionalidad != ''){                                
                            if (solicitante.Nacionalidad == 'MEXICANO (A)') {
                                this.nacionalidad = 1
                            }else{
                                this.nacionalidad = 2
                            }
                        }else
                            this.nacionalidad = 0
                            
                        this.rfc = solicitante.Rfc
                        this.estadoCivil = solicitante.EstadoCivil
                        this.regimenMatrimonial = solicitante.RegimenMatrimonial

                        //INGRESOS
                        
                        if (solicitanteIngresos.length > 0 ) {
                                                        
                            this.ingresos.ingresoId = solicitanteIngresos[0].Id
                            if (solicitanteIngresos[0].TipoEmpleoId == 1) {
                                this.ingresos.tipoActividad = 1                                                                                           
                            }else if(solicitanteIngresos[0].TipoEmpleoId == 42){
                                this.ingresos.tipoActividad = 42
                                //this.ingresos.ingresoBruto = solicitanteIngresos[0].IngresosMensuales
                               
                            }else if(solicitanteIngresos[0].TipoEmpleoId == 14 || solicitanteIngresos[0].TipoEmpleoId == 44){
                                this.ingresos.tipoActividad = 14
                                //this.ingresos.ingresoNeto = solicitanteIngresos[0].IngresosNetos
                                                  
                            }else{
                                this.ingresos.tipoActividad = null
                            }

                            this.ingresos.ingresoNeto = solicitanteIngresos[0].IngresosNetos
                            this.ingresos.ingresoBruto = solicitanteIngresos[0].IngresosMensuales

                            if (solicitanteIngresos[0].ComprobanteIngresosId == 4)
                                this.ingresos.comprobanteIngresos = 4
                            if (solicitanteIngresos[0].ComprobanteIngresosId == 18)
                                this.ingresos.comprobanteIngresos = 18

                            /*if (solicitanteIngresos[0].ComprobanteIngresosId == 4 && (solicitanteIngresos[0].TipoEmpleoId == 42 || solicitanteIngresos[0].TipoEmpleoId == 14 || solicitanteIngresos[0].TipoEmpleoId == 44)) {
                                this.ingresos.comprobanteIngresos = 4
                            }else if (solicitanteIngresos[0].ComprobanteIngresosId == 18 && (solicitanteIngresos[0].TipoEmpleoId == 42 || solicitanteIngresos[0].TipoEmpleoId == 14 || solicitanteIngresos[0].TipoEmpleoId == 44)) {
                                this.ingresos.comprobanteIngresos = 18
                            }*/
                        
                            var fechaActual = this.dameFechaActual()                            
                            var fechaInicio = new Date(solicitanteIngresos[0].FechaInicio).getTime();                                                    
                            var fechaFin    = new Date(fechaActual).getTime();
                            var diff = fechaFin - fechaInicio;
                            var dias = diff/(1000*60*60*24)
                            var anios = dias / 355
                            

                            this.ingresos.antiguedadLaboral = Math.round(anios)
                            

                            this.formateaIngresoBruto()
                            this.formateaIngresoNeto()

                        }else{
                            this.ingresoNuevo = true
                        }


                        // DOMICILIO ACTUAL
                        this.codigoPostal = solicitante.CodigoPostal
                        this.calle = solicitante.Calle
                        this.numeroExterior = solicitante.NumeroExterior
                        this.numeroInterior = solicitante.NumeroInterior
                        this.antiguedadDomicilioActual = solicitante.TiempoViviendoCiudad


                        //this.pais = solicitante.PaisExtranjero
                        if(solicitante.PaisRecidencia)
                            this.pais = this.normalizeString(solicitante.PaisRecidencia.toUpperCase())
                        //console.log("PASIS " + this.)

                        if (solicitante.PaisRecidencia == 'COSTA DE MARFIL' || solicitante.PaisRecidencia == 'ESLOVENIA' || solicitante.PaisRecidencia == 'ESPAÑA' || solicitante.PaisRecidencia == 'ISLAS SALOMÓN'
                            || solicitante.PaisRecidencia == 'SRI LANKA' || solicitante.PaisRecidencia == 'SUDÁN DEL SUR'
                        ) {
                            this.pais = ''
                        }
                        else{                          
                            this.listaPaises.forEach(element => {                                
                                if(this.pais == element.Nombre)
                                    this.pais = element.Nombre                         
                            });
                        }

                    } else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameSolicitante dameSolicitante',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
        async actualizarInfo(){
            
            if (this.ingresos.ingresoBruto != '') {
                this.ingresos.ingresoBruto = parseFloat(this.ingresos.ingresoBruto.replace(/,/g, ''))
            }

            if (this.ingresos.ingresoNeto != '') {
                this.ingresos.ingresoNeto = parseFloat(this.ingresos.ingresoNeto.replace(/,/g, ''))
            }



            var Sexo
            var Nacionalidad

            this.sexo == 'H' ? Sexo = 1 : Sexo = 2
            this.nacionalidad == 1 ? Nacionalidad = 'MEXICANO (A)' : Nacionalidad = 'NORTEAMERICANO (A)'

            let self = this
			var objRequestActualizaInfo = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraSolicitante',
				objSolicitante: {
                    EMail: this.email,
					ApellidoMaterno: this.apellidoMaterno,
                    ApellidoPaterno: this.apellidoPaterno,
                    Nombres: this.nombre,
                    SegundoNombre: '',
                    Sexo: Sexo,
                    FechaNacimiento : this.fechaNacimiento_aa + "-" + this.fechaNacimiento_mm + "-" + this.fechaNacimiento_dd,                  
                    Nacionalidad: {
						id: this.nacionalidad,
						name: Nacionalidad,
                        updateScotia:true
					},
                    PaisRecidencia: this.pais,
                    Rfc: this.rfc,
                    EstadoCivil: this.estadoCivil,
                    RegimenMatrimonial: this.regimenMatrimonial,
                    CodigoPostal: this.codigoPostal,
                    Calle: this.calle,
                    NumeroExterior: this.numeroExterior,
                    NumeroInterior: this.numeroInterior,
                    TiempoViviendoCiudad: this.antiguedadDomicilioActual
				},
			}


            
			const validator = await this.$validator.validateAll()            			

			if ( !validator ) {

				this.$vs.notify({
					title: 'Ocurrio un error',
					text: 'Datos incompletos',
					color: 'danger',
					position: 'top-right',
				})
                this.formateaIngresoBruto()
                this.formateaIngresoNeto()

				return false

			}

            const response = await this.$axios.post('/', objRequestActualizaInfo, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })

			const { intEstatus, objContenido } = response

            if ( intEstatus ) {

                this.$vs.notify({
                    title: 'Ocurrio un error en RegistraSolicitante',
                    text: response.data.strError,
                    color: 'danger',
                    position: 'top-right',
                })

                return false

            }
            this.$vs.loading({
				container: '#container',
				scale: 0.6,
			})

            if (this.ingresos.antiguedadLaboral > 0) {
                var arrayFecha = (this.dameFechaActual()).split('-')
                var anioInicio = parseFloat(arrayFecha[0]) - parseFloat(this.ingresos.antiguedadLaboral)
                console.log(arrayFecha)
                console.log(anioInicio)
                this.ingresos.FechaInicio = anioInicio + "-"+arrayFecha[1]+"-"+arrayFecha[2]
                console.log(this.ingresos.FechaInicio)
            }                        
            this.ingresoNuevo ? this.registraIngreso() : this.editarIngreso()  

            this.formateaIngresoBruto()
            this.formateaIngresoNeto()
            
            document.getElementById('regimen').disabled = true   
            document.getElementById('ingresos.ingresoBruto').disabled = true
            document.getElementById('ingresos.ingresoNeto').disabled = true
            document.getElementById('formaComprobarIngresos').disabled = true
        },      
        registraIngreso(){                                      
            let self = this
			var objRequestListaCasos = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraIngresos',
				objSolicitante: {
					EMail: this.email,
					Ingresos: [
						{
							IngresosMensuales: this.ingresos.ingresoBruto,
							IngresosNetos: this.ingresos.ingresoNeto,
							TipoEmpleo: this.ingresos.tipoActividad,							
					        FechaInicio: this.ingresos.FechaInicio,							
							ComprobanteIngresos: this.ingresos.comprobanteIngresos,							
						},
					]					
				},
			}
			this.$axios
				.post('/', objRequestListaCasos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Datos guardados exitosamente`,
							position: 'top-right',
						})						
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraIngresos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
					this.$vs.loading.close('#container > .con-vs-loading')
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})

        },
        editarIngreso(){
                      
            var objRequestRegistraCuentasBancarias = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'EditarIngresos',
				objSolicitante: {
					EMail: this.email,
					Ingresos: [
						{
                            Id: this.ingresos.ingresoId,
							IngresosMensuales:this.ingresos.ingresoBruto,
							IngresosNetos:this.ingresos.ingresoNeto,
							TipoEmpleo: this.ingresos.tipoActividad,
                            ComprobanteIngresos: this.ingresos.comprobanteIngresos,
                            FechaInicio: this.ingresos.FechaInicio,							
						},
					],					
				},
			}
			this.$axios
				.post('/', objRequestRegistraCuentasBancarias, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Datos guardados exitosamente`,
							position: 'top-right',
						})	
                        this.$vs.loading.close('#container > .con-vs-loading')					
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en EditarIngresos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})

        },
        precalificacionLinea() {
			this.$vs.loading({
				container: '#container',
				scale: 0.6,
			})          

           var objRequestRegistraCuentasBancarias = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'SolicitarEnLineaHsbc',
				objCaso: {					
                    Id: this.id,
                    EMail: this.email,
					Banco: 6, //hsbc 6: santander 10: Scotiabank 11		
                    DatosCredito: {
						Plazo: this.caso.Plazos[0],
						Programa: this.caso.Programas[0],
						Esquema: this.caso.Esquemas[0],
                        Destino: this.caso.Destino,		
                        MontoSolicitado: this.caso.MontoSolicitado,
						ValorPropiedad: this.caso.ValorInmueble,				
						Variante: this.varianteId
					},	                    
				},
			}
			this.$axios
				.post('/', objRequestRegistraCuentasBancarias, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {

                    this.$vs.loading.close('#container > .con-vs-loading')
                                  
                    this.$vs.dialog({
                        color:'primary',
                        title: 'Aviso',
                        text: 'Recuerda terminar de llenar los \n formularios directo desde el portal \n de HSBC',
                        acceptText: 'Aceptar',                        
                    });

                    }
                })
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})

            //
        },
        updateRegimenMatrimonial(){
            if ( this.estadoCivil == 1) {
                document.getElementById("regimen").disabled = false                
                this.regimenMatrimonial = 1
            }else{
                document.getElementById("regimen").disabled = true
                this.regimenMatrimonial = ''
            }
        },
        actualizaMontoIngreso(){            
            if (this.ingresos.tipoActividad == 42) {
                //this.ingresos.ingresoNeto = ''
                //this.ingresos.ingresoBruto = '0'
                //this.ingresos.comprobanteIngresos = 4
                document.getElementById('ingresos.ingresoNeto').disabled = true
                document.getElementById('ingresos.ingresoBruto').disabled = false
                document.getElementById('formaComprobarIngresos').disabled = false
                
            }else{
                    //this.ingresos.ingresoNeto = '0'
                    //this.ingresos.ingresoBruto = ''
                    document.getElementById('ingresos.ingresoBruto').disabled = true
                    document.getElementById('ingresos.ingresoNeto').disabled = false
                    
                    if (this.ingresos.tipoActividad == 14) {
                        document.getElementById('formaComprobarIngresos').disabled = false
                        //this.ingresos.comprobanteIngresos = 4
                    }else if(this.ingresos.tipoActividad == 1) {
                        //this.ingresos.comprobanteIngresos = ''
                        document.getElementById('formaComprobarIngresos').disabled = true

                    }

            }
            console.log(this.ingresos.ingresoNeto + " > " +this.ingresos.ingresoBruto)
        },

        dameFechaActual(){
            var now = new Date()                                                    
            var mes = 0
                                       
            switch (now.getMonth()) {
                    case 0:
                        mes = "01"
                        break;
                    case 1:
                        mes = "02"
                        break;
                    case 2:
                        mes = "03"
                        break;
                    case 3:
                        mes = "04"
                        break;
                    case 4:
                        mes = "05"
                        break;
                    case 5:
                        mes = "06"
                        break;
                    case 6:
                        mes = "07"
                        break;
                    case 7:
                        mes = "08"
                        break;
                    case 8:
                        mes = "09"
                        break;
                    case 9:
                        mes = "10"
                    break;
                    case 10:
                        mes = "11"
                        break;
                    case 11:
                        mes = "12"
                        break;                                                        
                }   
            return now.getFullYear() + "-" + mes + "-"+ now.getDate();
        },
        formateaIngresoBruto() {
			if (this.ingresos.ingresoBruto == '') {
				this.ingresos.ingresoBruto = 0
			}
			this.ingresos.ingresoBruto = parseFloat(this.ingresos.ingresoBruto).toFixed(2)
			const exp = /(\d)(?=(\d{3})+(?!\d))/g
			const rep = '$1,'
			this.ingresos.ingresoBruto = this.ingresos.ingresoBruto.toString().replace(exp, rep)
		},
        formateaIngresoNeto() {
			if (this.ingresos.ingresoNeto == '') {
				this.ingresos.ingresoNeto = 0
			}
			this.ingresos.ingresoNeto = parseFloat(this.ingresos.ingresoNeto).toFixed(2)
			const exp = /(\d)(?=(\d{3})+(?!\d))/g
			const rep = '$1,'
			this.ingresos.ingresoNeto = this.ingresos.ingresoNeto.toString().replace(exp, rep)
		},

    },

}

</script>



<style lang="css">
    .btn-large {
        min-width: 100%;
        height: 100%;
        margin: 0 !important;
    }
    .sizeIputAntiguedadLaboral{
        width: 30%;
    }
    .popup_success{
        display: flex;
        justify-content: center;
        text-align: center;
        font-weight: bold;
    }
    .hgs{
        height: 45%;
    }
    .responsive-input {             
        padding-top: 5px;   
    }    

    
@media (max-width: 1200px) {
    .responsive-input {
        width: 80%; 
        /*font-size: 10px; */
        padding-top: 10px;   
        margin-top: 5%;    
    }
    .responsive-select{
        font-size: 10px;
    }
}
   
</style>


