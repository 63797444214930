<template>
    <div id="solicitudAdicionalMod">
        <!-- main component -->
		<vx-card class="row w-full p-0">

            <div class="accordion">
                <div class="accordion-item">
                    <h2 
                        class="accordion-header" 
                        id="headingOne">
                        <button 
                            class="accordion-button" 
                            type="button" 
                            data-bs-toggle="collapse" 
                            data-bs-target="#collapseOne" 
                            aria-expanded="true" 
                            aria-controls="collapseOne">
                            Solicitudes de PPR (Plan Personal de Retiro)
                        </button>
                    </h2>
                    <div 
                        id="collapseOne" 
                        class="accordion-collapse collapse" 
                        aria-labelledby="headingOne" 
                        data-bs-parent="#solicitudAdicionalAccordion">
                        <div class="accordion-body">

                            <div class="flex">

                                <!-- skandia -->
                                <div
                                    @click="generaSolicitudAdicional('Solicitud_Skandia.php')" 
                                    class="solicitud-inversion-skandia">
                                    <!-- la clase es individual por si existen solicitudes con diferentes estilos -->
                                    <img
                                        class=""
                                        src="@/assets/images/solicitudAdicional/skandiaLogo.png"
                                        alt="Skandia Logo"
                                    />
                                    <span>Skandia</span>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>         

            <!--  SE RETIRA SECCION <SOLICITUDES DE AUTOMOVILES > POR REQUERIMIENTO "PRECALIFICACION DE AUTOS"
                <div class="accordion-item">
                    <h2 
                        class="accordion-header" 
                        id="headingOne">
                        <button 
                            class="accordion-button" 
                            type="button" 
                            data-bs-toggle="collapse" 
                            data-bs-target="#collapseTwo" 
                            aria-expanded="true" 
                            aria-controls="collapseTwo">
                            Solicitudes de automóvil
                        </button>
                    </h2>
                    <div class="row">    

                        <div 
                            id="collapseTwo" 
                            class="accordion-collapse collapse col-auto col-md-auto" 
                            aria-labelledby="headingOne" 
                            data-bs-parent="#solicitudAdicionalAccordion">
                            <div class="accordion-body pr-0">

                                <div class="flex">                                
                                    <div
                                        @click="generaSolicitudAdicional('Solicitud_Hey_Banco_PF.php', 'automoviles')" 
                                        class="solicitudes-autos">                                    
                                        <img
                                            class=""
                                            src="@/assets/images/solicitudAdicional/logo_hey_banco.png"
                                            alt="hey banco Logo"
                                        />
                                        <span class="hey-banco">Hey, Banco – PF</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div 
                            id="collapseTwo" 
                            class="accordion-collapse collapse col-auto col-md-auto" 
                            aria-labelledby="headingOne" 
                            data-bs-parent="#solicitudAdicionalAccordion">
                            <div class="accordion-body pr-0">

                                <div class="flex">                                
                                    <div
                                        @click="generaSolicitudAdicional('Solicitud_Hey_Banco_PM.php','automoviles')" 
                                        class="solicitudes-autos">                                    
                                        <img
                                            class=""
                                            src="@/assets/images/solicitudAdicional/logo_hey_banco.png"
                                            alt="hey banco Logo"
                                        />
                                        <span class="hey-banco">Hey, Banco – PM</span>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div 
                            id="collapseTwo" 
                            class="accordion-collapse collapse col-auto col-md-auto" 
                            aria-labelledby="headingOne" 
                            data-bs-parent="#solicitudAdicionalAccordion">
                            <div class="accordion-body pr-0">

                                <div class="flex">                                
                                    <div
                                        @click="generaSolicitudAdicional('Solicitud_Afirme_autos.php', 'automoviles')" 
                                        class="solicitudes-autos">                                    
                                        <img
                                            class=""
                                            src="@/assets/images/solicitudAdicional/logo_afirme.jpg"                              
                                            alt="Afirme Logo"
                                        />
                                        <span class="afirme">Afirme</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        
                        <div 
                            id="collapseTwo" 
                            class="accordion-collapse collapse col-auto col-md-auto" 
                            aria-labelledby="headingOne" 
                            data-bs-parent="#solicitudAdicionalAccordion">
                            <div class="accordion-body pr-0">

                                <div class="flex">                                
                                    <div
                                        @click="generaSolicitudAdicional('Solicitud_HSBC_autos.php', 'automoviles', 'titular')" 
                                        class="solicitudes-autos">                                    
                                        <img
                                            class=""
                                            src="@/assets/images/solicitudAdicional/logo_hsbc.png"                              
                                            alt="HSBC Logo"
                                        />
                                        <span class="hsbc">HSBC – Titular</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div 
                            id="collapseTwo" 
                            class="accordion-collapse collapse col-auto col-md-auto" 
                            aria-labelledby="headingOne" 
                            data-bs-parent="#solicitudAdicionalAccordion">
                            <div class="accordion-body pr-0">

                                <div class="flex">                                
                                    <div
                                        @click="generaSolicitudAdicional('Solicitud_HSBC_autos.php', 'automoviles', 'coacreditado')" 
                                        class="solicitudes-autos">                                    
                                        <img
                                            class=""
                                            src="@/assets/images/solicitudAdicional/logo_hsbc.png"                              
                                            alt="HSBC Logo"
                                            width="100%"
                                        />
                                        <span class="hsbc">HSBC – Coacreditado</span>
                                    </div>

                                </div>

                            </div>
                        </div>   

                        <div 
                            id="collapseTwo" 
                            class="accordion-collapse collapse col-auto col-md-auto" 
                            aria-labelledby="headingOne" 
                            data-bs-parent="#solicitudAdicionalAccordion">
                            <div class="accordion-body">

                                <div class="flex">                                
                                    <div
                                        @click="generaSolicitudAdicional('Solicitud_Scotiabank_autos_COS.php', 'automoviles', 'titular')" 
                                        class="solicitudes-autos">                                    
                                        <img
                                            class=""
                                            src="@/assets/images/solicitudAdicional/logo_scotiabank.jpg"                              
                                            alt="Scotiabank Logo"
                                        />
                                        <span class="scotiabank-cos">Scotiabank - Con <br>Obligado Solidario</span>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div 
                            id="collapseTwo" 
                            class="accordion-collapse collapse col-auto col-md-auto" 
                            aria-labelledby="headingOne" 
                            data-bs-parent="#solicitudAdicionalAccordion">
                            <div class="accordion-body">

                                <div class="flex">                                
                                    <div
                                        @click="generaSolicitudAdicional('Solicitud_Scotiabank_autos_SOS.php', 'automoviles', 'titular')" 
                                        class="solicitudes-autos">                                    
                                        <img
                                            class=""
                                            src="@/assets/images/solicitudAdicional/logo_scotiabank.jpg"                              
                                            alt="Scotiabank Logo"
                                        />
                                        <span class="scotiabank-cos">Scotiabank - Sin <br>Obligado Solidario</span>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            -->

            </div>

        </vx-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            idCaso: ''
        }
	},
    mounted() {
        if (this.$store.state.AppActiveUser.ApiKey) {
            this.idCaso = this.$route.params.id;
            // console.log( this.idCaso );
        } else {
            this.$router.push('/login').catch(() => {})
        }
    },
    watch: {},
    computed: {},
    methods: {
        generaSolicitudAdicional(solicitud, origen, entidad) {
            const url = `https://cotizador.socasesores.com/admin/formatos/SolicitudesAdicionales/${solicitud}?s=${this.idCaso}&o=${entidad}`;                         

            if (origen == 'automoviles') {
                window.open(url, '_blank');
            }else{
                window.location.href = url;            
            }
            
        }
    },
}
</script>

<style lang="scss">
#solicitudAdicionalMod .accordion-button:not(.collapsed)::after,
#solicitudAdicionalMod .accordion-button::after {
  background-image: unset !important;
}
#solicitudAdicionalMod .vx-card__body {
    padding: 1rem 0.5rem;
}

.solicitud-inversion-skandia {
    cursor: pointer;
    img {
        border-top: 1px solid #8E8E8E;
        border-left: 1px solid #8E8E8E;
        border-right: 1px solid #8E8E8E;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 5px;
        height: 150px;
        background-color: #FFFFFF;
    }
    span {
        display: block;
        color: #00C83C;
        text-align: center;
        font-weight: 600;
        border: 1px solid #8E8E8E;
        border-end-end-radius: 5px;
        border-end-start-radius: 5px;
        background-color: #FFFFFF;
    }
}
.solicitud-inversion-skandia:hover {
    img {
        border-top: 1px solid #171717;
        border-left: 1px solid #171717;
        border-right: 1px solid #171717;
    }
    span {
        border: 1px solid #171717;
        color: #FFFFFF;
        background-color: #00C83C;
    }
}
.solicitudes-autos{
    cursor: pointer;
    img {
        border-top: 1px solid #8E8E8E;
        border-left: 1px solid #8E8E8E;
        border-right: 1px solid #8E8E8E;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 5px;
        height: 150px;
        background-color: #FFFFFF;
    }
    span {
        display: block;
        color: #000;
        text-align: center;
        font-weight: 600;
        border: 1px solid #8E8E8E;
        border-end-end-radius: 5px;
        border-end-start-radius: 5px;
        background-color: #FFFFFF;
    }
    
}
.solicitudes-autos:hover {
    img {
        border-top: 1px solid #171717;
        border-left: 1px solid #171717;
        border-right: 1px solid #171717;
    }
    .hey-banco {
        border: 1px solid #171717;
        color: #FFFFFF;
        background-color: #000;
    }
    .afirme {
        border: 1px solid #171717;
        color: #FFFFFF;
        background-color: #00974B;
    }
    .hsbc{
        border: 1px solid #171717;
        color: #FFFFFF;
        background-color: #E52713;
    }
    .scotiabank-cos {
        border: 1px solid #171717;
        color: #FFFFFF;
        background-color: #E52713;
    }
}
</style>