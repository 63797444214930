export const ocupaciones = [
  {Id:'H', Nombre: "ACCIONISTA/ARRENDADOR"},
  {Id:'A', Nombre: "AGRICULTOR, GANADERO"},
  {Id:'M', Nombre: "AMA DE CASA"},
  {Id:'1', Nombre: "ARTISTA/DEPORTISTA"},
  {Id:'X', Nombre: "CHOFER (TAXI, PESERO, ETC.)"},
  {Id:'C', Nombre: "COMERCIANTE INDEPENDIENTE"},
  {Id:'W', Nombre: "DESEMPLEADO CON INGRESOS"},
  {Id:'D', Nombre: "DIRECTOR GENERAL O PRESIDENTE (EMPRESA GRANDE)"},
  {Id:'F', Nombre: "DIRECTOR-SUBDIRECTOR (EMPLEADO)"},
  {Id:'B', Nombre: "EMPLEADO"},
  {Id:'E', Nombre: "EMPRESARIO, PROPIETARIO DE EMPR DE SERV"},
  {Id:'S', Nombre: "ESTUDIANTE"},
  {Id:'G', Nombre: "GERENTE"},
  {Id:'U', Nombre: "GUARDIA UNIFORMADO"},
  {Id:'I', Nombre: "INDUSTRIAL"},
  {Id:'Y', Nombre: "JEFE DE DEPARTAMENTO SECCION"},
  {Id:'J', Nombre: "JUBILADO/RETIRADO"},
  {Id:'R', Nombre: "MAESTRO"},
  {Id:'Q', Nombre: "MANDOS MEDIOS Y SUPERIORES (EJECUTIVO, GERENTE, ET"},
  {Id:'3', Nombre: "MEDICO"},
  {Id:'2', Nombre: "OTRO-TRABAJADOR INDEPENDIENTE (VENTAS COMISIONISTA"},
  {Id:'O', Nombre: "OTROS"},
  {Id:'V', Nombre: "OTROS EMPLEADOS (AUX. ADVO. OFICINISTA, ETC)"},
  {Id:'P', Nombre: "PROFESIONISTA INDEPENDIENTE"},
  {Id:'K', Nombre: "REPARADOR / INSTALADOR (EMPLEADOS)"},
  {Id:'N', Nombre: "SECRETARIA"},
  {Id:'Z', Nombre: "TECNICO ESPECIALIZADO"},
  {Id:'L', Nombre: "TECNICO INDEPENDIENTE U OFICIOS (MESERO, PELUQUERO"},
  {Id:'T', Nombre: "VENTAS / COMISIONISTA"},
]
