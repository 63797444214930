<template lang="html"> 
  <div class="">
    <div class="">
      <div class="">
        <vx-card  class="">
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder" for="producto">Producto</span>
              <select name="producto" id="producto" v-validate="'required'" v-model="credito.producto" class="vs-input" @change="updateValores()">
                <option :value='tipo.Id' v-for="tipo in productos"  :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger span-placeholder" v-show="errors.has('producto')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Tipo de liquidez</span>
              <select name="liquidez" id="liquidez" v-validate="'required'" v-model="credito.tipoLiquidez" class="vs-input" @change="updateValores()">
                <option :value='tipo.Id' v-for="tipo in tiposLiquidez"  :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger span-placeholder" v-show="errors.has('liquidez')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Tipo de cofinanciamiento</span>
              <select name="confinanciamiento" id="confinanciamiento" v-validate="'required'" v-model="credito.cofinanciamiento" class="vs-input" @change="updateValores()">
                <option :value='tipo.Id' v-for="tipo in confinanciamientos"  :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger span-placeholder" v-show="errors.has('confinanciamiento')">Campo requerido</span>
            </div>
          </div>
        <div class="row w-full" v-if="destinoCaso==1 || destinoCaso==5 || destinoCaso==20 ||destinoCaso==21 || destinoCaso==24 || destinoCaso==31">
            <div class="col-md-4" v-if="destinoCaso==1 || destinoCaso==5 || destinoCaso==20 ||destinoCaso==21 || destinoCaso==24 || destinoCaso==31">
              <span class="span-placeholder">Monto de Crédito INFONAVIT</span>
              <VueNumberFormat class="vs-input" type="text"
                                   v-model="credito.montoCreditoInfonavit" name="montoCreditoInfonvit" maxlength="11" 
                              :options="{
                              prefix: '',
                              suffix: '',
                              decimal: '.',
                              thousand: ',',
                              precision: 2,
                              prefill:false,
                              nullValue:'',
                              reverseFill: false,
                              prefill: false
                              //reverseFill:true,
                            }"></VueNumberFormat>
              <!--<input class="vs-input" type="text" v-validate="'required'"   v-model="credito.montoCreditoInfonavit" name="montoCreditoInfonvit" maxlength="11" @change="updateValores()" />-->
             <!-- <span class="text-danger span-placeholder" v-show="errors.has('montoCreditoInfonvit')">Campo requerido</span>-->
            </div>  
            <div class="col-md-4" v-if="destinoCaso==1 || destinoCaso==5 || destinoCaso==20 ||destinoCaso==21 || destinoCaso==24 || destinoCaso==31">
              <span class="span-placeholder" for="saldoSubcuenta">Saldo en la subcuenta</span>
              <VueNumberFormat class="vs-input"
                                   v-model="credito.saldoSubcuenta" v-validate="'required'" name="saldoSubcuenta" maxlength="11" 
                              :options="{
                              prefix: '',
                              suffix: '',
                              decimal: '.',
                              thousand: ',',
                              precision: 2,
                              prefill:false,
                              nullValue:'',
                              reverseFill: false,
                              prefill: false
                              //reverseFill:true,
                            }"></VueNumberFormat>
             <!--<input class="vs-input" v-validate="'required'"  v-model="credito.saldoSubcuenta" name="saldoSubcuenta"  maxlength="11" @change="updateValores()"/>-->
             <span class="text-danger span-placeholder" v-show="errors.has('saldoSubcuenta')">Campo requerido</span>
            </div>
            <div class="col-md-4" v-if="destinoCaso==1 || destinoCaso==5 || destinoCaso==20 ||destinoCaso==21 || destinoCaso==24 || destinoCaso==31">
              <span class="span-placeholder" for="descuentoMensual" >Descuento mensual</span>
              <VueNumberFormat class="vs-input"
                                   v-model="credito.descuentoMensual" v-validate="'required'" name="descuentoMensual" maxlength="11" 
                              :options="{
                              prefix: '',
                              suffix: '',
                              decimal: '.',
                              thousand: ',',
                              precision: 2,
                              prefill:false,
                              nullValue:'',
                              reverseFill: false,
                              prefill: false
                              //reverseFill:true,
                            }"></VueNumberFormat>
             <!--<input class="vs-input" label="descuentoMensual" v-validate="'required'" v-model="credito.descuentoMensual" name="descuentoMensual" maxlength="11" @change="updateValores()"/>-->
             <span class="text-danger span-placeholder" v-show="errors.has('descuentoMensual')">Campo requerido</span>
            </div>
        </div>
        <div class="row w-full" v-if="(destinoCaso==1 || destinoCaso==5 || destinoCaso==20 ||destinoCaso==21 || destinoCaso==24 || destinoCaso==31) && credito.cofinanciamiento==62">
            <div class="col-md-4" v-if="(destinoCaso==1 || destinoCaso==5 || destinoCaso==20 ||destinoCaso==21 || destinoCaso==24 || destinoCaso==31) && credito.cofinanciamiento==62">
              <span class="span-placeholder">Monto crédito cónyuge</span>
              <VueNumberFormat class="vs-input"
                                   v-model="credito.conyuge.montoCredito" v-validate="'required'" name="montoCreditoConyuge" maxlength="11" 
                              :options="{
                              prefix: '',
                              suffix: '',
                              decimal: '.',
                              thousand: ',',
                              precision: 2,
                              prefill:false,
                              nullValue:'',
                              reverseFill: false,
                              prefill: false
                              //reverseFill:true,
                            }"></VueNumberFormat>
                        <span class="text-danger span-placeholder" v-if="validaMontoCredito">El monto del crédito no puede ser mayor al valor de la vivienda</span>    
              <!--<input class="vs-input" type="text"  v-validate="'required'"  v-model="credito.conyuge.montoCredito" name="montoCreditoConyuge" @change="updateValores()"/>-->
              <span class="text-danger span-placeholder" v-show="errors.has('montoCreditoConyuge')">Campo requerido</span>
            </div>  
            <div class="col-md-4" v-if="(destinoCaso==1 || destinoCaso==5 || destinoCaso==20 ||destinoCaso==21 || destinoCaso==24 || destinoCaso==31) && credito.cofinanciamiento==62">
              <span class="span-placeholder" for="saldoSubcuentaConyuge"  maxlength="11" >Saldo en la subcuenta cónyuge</span>
              <VueNumberFormat class="vs-input"
                                   v-model="credito.conyuge.saldoSubcuenta" v-validate="'required'" name="saldoSubcuentaConyuge" maxlength="11" 
                              :options="{
                              prefix: '',
                              suffix: '',
                              decimal: '.',
                              thousand: ',',
                              precision: 2,
                              prefill:false,
                              nullValue:'',
                              reverseFill: false,
                              prefill: false
                              //reverseFill:true,
                            }"></VueNumberFormat>
             <!--<input class="vs-input" v-validate="'required'" v-model="credito.conyuge.saldoSubcuenta" name="saldoSubcuentaConyuge" @change="updateValores()"/>-->
             <span class="text-danger span-placeholder" v-show="errors.has('saldoSubcuentaConyuge')">Campo requerido</span>
            </div>
            <div class="col-md-4" v-if="(destinoCaso==1 || destinoCaso==5 || destinoCaso==20 ||destinoCaso==21 || destinoCaso==24 || destinoCaso==31) && credito.cofinanciamiento==62">
              <span class="span-placeholder"  for="descuentoMensualConyuge">Descuento mensual cónyuge</span>
              <VueNumberFormat class="vs-input"
                                   v-model="credito.conyuge.descuentoMensual" v-validate="'required'" name="descuentoMensualConyuge" maxlength="11" 
                              :options="{
                              prefix: '',
                              suffix: '',
                              decimal: '.',
                              thousand: ',',
                              precision: 2,
                              prefill:false,
                              nullValue:'',
                              reverseFill: false,
                              prefill: false
                              //reverseFill:true,
                            }"></VueNumberFormat>
              <!--<input class="vs-input" label="tipoCasa" v-validate="'required'" v-model="credito.conyuge.descuentoMensual" name="descuentoMensualConyuge" maxlength="11" @change="updateValores()"/>-->
              <span class="text-danger span-placeholder" v-show="errors.has('descuentoMensualConyuge')">Campo requerido</span>
            </div>
        </div>   
        <div class="row w-full">
            <div class="col-md-4" v-if="destinoCaso==19">
              <span class="span-placeholder">Saldo Actual</span>
              <VueNumberFormat class="vs-input"
                                   v-model="credito.saldoActual" v-validate="'required'" name="saldoActual" maxlength="11" 
                              :options="{
                              prefix: '',
                              suffix: '',
                              decimal: '.',
                              thousand: ',',
                              precision: 2,
                              prefill:false,
                              nullValue:'',
                              reverseFill: false,
                              prefill: false
                              //reverseFill:true,
                            }"></VueNumberFormat>
              <span class="text-danger span-placeholder" v-if="validaSaldoActual">No debe ser menor a $100,000.00, no debe ser mayor al valor de la vivienda</span>               
              <!--<input class="vs-input" type="text" v-validate="'required'"  v-model="credito.saldoActual" name="saldoActual" @change="updateValores()"/>-->
              <span class="text-danger span-placeholder" v-show="errors.has('saldoActual')">Campo requerido</span>
            </div>  
            <div class="col-md-4">
              <span class="span-placeholder" for="destino" >Destino del inmueble</span>
              <select name="destino" id="destino" v-model="credito.destino" class="vs-input" @change="updateValores()">
                <option :value='tipo.Id' v-for="tipo in destinos"  :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
        </div>    
        </vx-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
     data(){
		return{
           productos:[], 
           confinanciamientos:[],
           tiposLiquidez:[],
           destinos:[],
           credito:{ 
            producto:'',
            tipoLiquidez:'',
            cofinanciamiento:'',
            destino:'',
            montoCreditoInfonavit:'',
            saldoSubcuenta:'',
            descuentoMensual:'',
            conyuge:{
                montoCredito:'',
                saldoSubcuenta:'',
                descuentoMensual:''
            },
            saldoActual:'',
            validaCredito:false 
           }
           ,
            destinoCaso:'',
            validarForminput:'',
            validaFormIndividual:false,
            validaFormMejora:false,
            validaFormConyugal:false,
            validaMontoCredito:false,
            valorVivienda:'',
            validaSaldoActual:false
        }
    }, 
    watch:{
        creditoInfinavit:function(){
            if (this.credito.montoCreditoInfonavit !='') {
                this.updateValores()
            }
        },
        saldoSubcuenta:function(){
            if (this.credito.saldoSubcuenta !='') {
                this.updateValores()
            }
        },
        descuentoMensual:function(){
            if (this.credito.descuentoMensual !='') {
                this.updateValores()
            }
        },
        montoCreditoCoyuge:function(){
            if (this.credito.conyuge.montoCredito !='') {
                this.updateValores()
            }
        },
        saldoSubcuentaConyuge:function(){
            if (this.credito.conyuge.saldoSubcuenta !='') {
                this.updateValores()
            }
        },
        descuentoMensualConyuge:function(){
            if (this.credito.conyuge.descuentoMensual !='') {
                this.updateValores()
            }
        },
        saldoActual:function(){
            if (this.credito.saldoActual !='') {
                this.updateValores()
            }
        },
        montoCredito:function(){
            this.validaMontoCredito=false
            if(this.credito.conyuge.montoCredito>this.valorVivienda){
                this.validaMontoCredito=true
            }
        },
        saldoActual:function(){
            this.validaSaldoActual=false
            if(this.credito.saldoActual>this.valorVivienda || this.credito.saldoActual<100000){
                this.validaSaldoActual=true
            }
        }
    },
    computed:{
       saldoActual(){
           return this.credito.saldoActual
       }, 
       montoCredito(){
           return this.credito.conyuge.montoCredito
       }, 
       creditoInfinavit(){
            return this.credito.montoCreditoInfonavit
        },
        saldoSubcuenta(){
            return this.credito.saldoSubcuenta
        },
        descuentoMensual(){
            return this.credito.descuentoMensual
        },
        montoCreditoCoyuge(){
            return this.credito.conyuge.montoCredito
        },
        saldoSubcuentaConyuge(){
            return this.credito.conyuge.saldoSubcuenta
        },
        descuentoMensualConyuge(){
            return this.credito.conyuge.descuentoMensual
        },
        saldoActual(){
            return this.credito.saldoActual
        },
    }, 
    mounted(){
        this.id=this.$route.params.id
        this.getApikey()
        this.dameCaso()
        this.getDestinos()
        this.getConfinanciamientos()
        this.getProductos()
        this.getTipoLiquidez()
        //this.updateValores()
    },
    methods:{
        dameDatosCredito(){
            let self= this
            var objRequestDameSolicitante = {
                strApiKey: this.key,
                strMetodo: "DamePrecalificacionBancos",
                objPrecalificacionBanco:
                {
                    IdPrecalificacion: this.id
                }
            }
            this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(
                response => {
                if(response.data.intEstatus == 0){
                    let solicitante =response.data.objContenido
                    this.credito.producto=solicitante[0].SantanderExProducto
                    this.credito.tipoLiquidez=solicitante[0].SantanderExTipoLiquidez
                    this.credito.montoCreditoInfonavit=solicitante[0].MontoCreditoINFONAVIT
                    this.credito.saldoSubcuenta=solicitante[0].SaldoSubcuenta
                    this.credito.cofinanciamiento=solicitante[0].SantanderExTipoCofinanciamiento
                    this.credito.descuentoMensual=solicitante[0].DescuentoMensual
                    this.credito.conyuge.montoCredito=solicitante[0].MontoCreditoConyuge
                    this.credito.conyuge.saldoSubcuenta=solicitante[0].SaldoSubcuentaConyuge
                    this.credito.conyuge.descuentoMensual=solicitante[0].DescuentoMensualConyuge
                    this.credito.saldoActual=solicitante[0].SaldoActual
                    this.credito.destino=solicitante[0].SantanderExDestinoInmueble                    
                    this.updateValores()
                }else{
                    this.updateValores()
                }
                }
            ).catch(function (error) {
                self.$vs.notify({
                title:'Ocurrio un error de sistema',
                text:error,
                color:'danger',
                position:'top-right'
                })
            })
        },
        updateValores(){
            
            if((this.destinoCaso==1 || this.destinoCaso==5 || this.destinoCaso==20 || this.destinoCaso==21 || this.destinoCaso==24 || this.destinoCaso==31)&& this.credito.cofinanciamiento==61 && (this.credito.producto != '' && this.credito.producto !== null) && (this.credito.tipoLiquidez != '' && this.credito.tipoLiquidez !==null)  && (this.credito.cofinanciamiento != '' &&this.credito.cofinanciamiento!==null) && (this.credito.destino != '' &&this.credito.destino !==null) && (this.credito.montoCreditoInfonavit != '' && this.credito.montoCreditoInfonavit !== null) && (this.credito.saldoSubcuenta != '' && this.credito.saldoSubcuenta !==null) && (this.credito.descuentoMensual != ''&& this.credito.descuentoMensual!==null)){
                this.validaFormIndividual=true
            }else if(this.credito.cofinanciamiento != '' && this.destinoCaso!=19 && this.credito.cofinanciamiento==62){
                this.validaFormIndividual=true
            }else if(this.destinoCaso!=19 && this.destinoCaso!=1 && this.destinoCaso!=5 && this.destinoCaso!=20 && this.destinoCaso!=21 && this.destinoCaso!=24 && this.destinoCaso!=31 && (this.credito.producto != '' && this.credito.producto !== null) && (this.credito.tipoLiquidez != '' && this.credito.tipoLiquidez !==null)  && (this.credito.cofinanciamiento != '' &&this.credito.cofinanciamiento!==null) && (this.credito.destino != '' &&this.credito.destino !==null)){
                this.validaFormIndividual=true
            }else{
                this.validaFormIndividual=false
            }
            
            if((this.destinoCaso==1 || this.destinoCaso==5 || this.destinoCaso==20 || this.destinoCaso==21 || this.destinoCaso==24 || this.destinoCaso==31)&& this.credito.cofinanciamiento==62 &&  (this.credito.producto != '' && this.credito.producto !== null)  && (this.credito.tipoLiquidez != '' && this.credito.tipoLiquidez !==null)  && (this.credito.cofinanciamiento != '' && this.credito.cofinanciamiento!==null) && (this.credito.destino != '' && this.credito.destino !==null) && (this.credito.montoCreditoInfonavit != '' && this.credito.montoCreditoInfonavit !== null)&& (this.credito.saldoSubcuenta != '' && this.credito.saldoSubcuenta !==null) && (this.credito.descuentoMensual != ''&& this.credito.descuentoMensual!==null) && (this.credito.conyuge.montoCredito != '' && this.credito.conyuge.montoCredito !==null) && (this.credito.conyuge.saldoSubcuenta != ''&&this.credito.conyuge.saldoSubcuenta !==null) && (this.credito.conyuge.descuentoMensual != '' && this.credito.conyuge.descuentoMensual !==null)){
                this.validaFormConyugal=true
            }else if( this.credito.cofinanciamiento != '' && this.destinoCaso!=19 && this.credito.cofinanciamiento==61){ 
                this.validaFormConyugal=true
            }else{
                this.validaFormConyugal=false
            }

            
            if(this.destinoCaso==19 && this.credito.producto != '' && this.credito.tipoLiquidez != ''  && this.credito.cofinanciamiento != '' && this.credito.destino != '' && (this.credito.saldoActual !=''&&this.credito.saldoActual !==null)){
              this.validaFormMejora=true 
                this.validaFormConyugal=true  
                 this.validaFormIndividual=true
            }else if(this.destinoCaso!=19){
                this.validaFormMejora=true
            }else{
                this.validaFormMejora=false
            }

            /*if(this.destinoCaso!=19 && this.destinoCaso!=1 && this.destinoCaso!=5 && this.destinoCaso!=20 && this.destinoCaso!=21 && this.destinoCaso!=24 && this.destinoCaso!=31 && (this.credito.producto != '' && this.credito.producto !== null) && (this.credito.tipoLiquidez != '' && this.credito.tipoLiquidez !==null)  && (this.credito.cofinanciamiento != '' &&this.credito.cofinanciamiento!==null) && (this.credito.destino != '' &&this.credito.destino !==null)){
                this.validaFormIndividual=true
                this.validaFormConyugal=true
                this.validaFormMejora=true
            }else{
                this.validaFormMejora=false
            }*/
           
            if(this.validaFormIndividual && this.validaFormConyugal && this.validaFormMejora){
                this.credito.validaCredito=true
            }else{
                this.credito.validaCredito=false
            }
            //alert(this.validaFormIndividual+'--'+this.validaFormConyugal+'--'+this.validaFormMejora)
            this.$emit('updateValores',this.credito)
        },
        dameCaso(){
            let self= this
            var objRequestDameCaso = {
                strApiKey: this.key,
                strMetodo: 'DameCaso',
                objCaso: {
                Id: this.id
                }
            }
            this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(
                response => {
                if(response.data.intEstatus == 0){
                    let cotizacion= response.data.objContenido
                    this.destinoCaso=cotizacion.Destino
                    this.valorVivienda=cotizacion.ValorInmueble
                    this.dameDatosCredito()
                }else {
                    this.$vs.notify({
                    title:'Ocurrio un error en DameCaso',
                    text:response.data.strError,
                    color:'danger',
                    position:'top-right'
                    })
                }
                }
            ).catch(function (error) {
                self.$vs.notify({
                title:'Ocurrio un error de sistema',
                text:error,
                color:'danger',
                position:'top-right'
                })
      })
    },
        getApikey(){
            if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
                this.$router.push('/login');
            }else {
                this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
            }
        },
        getDestinos(){
            let self=this
            var objRequestListaDestinos = {
                strApiKey: this.key,
                strMetodo: 'CatalogosGeneralesBancos',
                objCatalogo:
                {
                    TipoCatalogo: "12"
                }
            }
            this.$axios.post('/',objRequestListaDestinos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(
                response => {
                if(response.data.intEstatus == 0){ 
                    this.destinos=response.data.objContenido 
                }else {
                    this.$vs.notify({
                    title:'Ocurrio un error en ListaDestinos',
                    text:response.data.strError,
                    color:'danger',
                    position:'top-right'
                    })
                }
                }
            ).catch(function (error) {
                self.$vs.notify({
                title:'Ocurrio un error de sistema',
                text:error,
                color:'danger',
                position:'top-right'
                })
      })
    },
        getConfinanciamientos(){
            let self=this
            var objRequestListaConfinanciamientos = {
                strApiKey: this.key,
                strMetodo: 'CatalogosGeneralesBancos',
                objCatalogo:
                {
                    TipoCatalogo: "11"
                }
            }
            this.$axios.post('/',objRequestListaConfinanciamientos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(
                response => {
                if(response.data.intEstatus == 0){ 
                    this.confinanciamientos=response.data.objContenido 
                }else {
                    this.$vs.notify({
                    title:'Ocurrio un error en ListaConfinanciamientos',
                    text:response.data.strError,
                    color:'danger',
                    position:'top-right'
                    })
                }
                }
            ).catch(function (error) {
                self.$vs.notify({
                title:'Ocurrio un error de sistema',
                text:error,
                color:'danger',
                position:'top-right'
                })
      })
    },
        getProductos(){
            let self=this
            var objRequestListaProductos = {
                strApiKey: this.key,
                strMetodo: 'CatalogosGeneralesBancos',
                objCatalogo:
                {
                    TipoCatalogo: "9"
                }
            }
            this.$axios.post('/',objRequestListaProductos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(
                response => {
                if(response.data.intEstatus == 0){ 
                    this.productos=response.data.objContenido 
                }else {
                    this.$vs.notify({
                    title:'Ocurrio un error en ListaProductos',
                    text:response.data.strError,
                    color:'danger',
                    position:'top-right'
                    })
                }
                }
            ).catch(function (error) {
                self.$vs.notify({
                title:'Ocurrio un error de sistema',
                text:error,
                color:'danger',
                position:'top-right'
                })
      })
    },
    getTipoLiquidez(){
            let self=this
            var objRequestListaTipoLiquidez = {
                strApiKey: this.key,
                strMetodo: 'CatalogosGeneralesBancos',
                objCatalogo:
                {
                    TipoCatalogo: "10"
                }
            }
            this.$axios.post('/',objRequestListaTipoLiquidez,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(
                response => {
                if(response.data.intEstatus == 0){ 
                    this.tiposLiquidez=response.data.objContenido 
                }else {
                    this.$vs.notify({
                    title:'Ocurrio un error en ListaTiposLiquidez',
                    text:response.data.strError,
                    color:'danger',
                    position:'top-right'
                    })
                }
                }
            ).catch(function (error) {
                self.$vs.notify({
                title:'Ocurrio un error de sistema',
                text:error,
                color:'danger',
                position:'top-right'
                })
      })
    },
    }
}
</script>
<style scoped>
.formularioCompleto{
    background-color: rgb(84, 190, 22) !important;
    color: white !important;
}
</style>