<template lang="html"> 
  <div class="">
    <div class="">
      <div class="">
        <vx-card :title="'Solicitante: '+email" class="">
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Nombre(s)</span>
              <input class="vs-input inputMayus" v-validate="'required|alpha_spaces'" v-model="datosPersonales.nombre" name="nombres" maxlength="30" @change="updateValores()"  v-on:keypress="soloLetras($event)"/>
              <span class="text-danger span-placeholder" v-show="errors.has('nombres')">Campo requerido</span>
            </div>
           <div class="col-md-4">
              <span class="span-placeholder">Apellido Paterno</span>
              <input class="vs-input inputMayus" v-validate="'required|alpha_spaces'" v-model="datosPersonales.apellidoPaterno" name="apellidoPaterno" maxlength="30" @change="updateValores()" v-on:keypress="soloLetras($event)"/>
              <span class="text-danger span-placeholder" v-show="errors.has('apellidoPaterno')">Campo requerido</span>
            </div>
            <div class="col-md-4" v-if="datosPersonales.nacionalidad=='MEXICANO'">
              <span class="span-placeholder">Apellido Materno</span>
              <input class="vs-input inputMayus" v-validate="'required|alpha_spaces'"  v-model="datosPersonales.apellidoMaterno" name="apellidoMaterno" maxlength="30" @change="updateValores()" v-on:keypress="soloLetras($event)"/>
              <span class="text-danger span-placeholder" v-show="errors.has('apellidoMaterno')">Campo requerido</span>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Fecha de nacimiento</span>
              <input class="vs-input" type="date"  v-validate="'required'"  v-model="datosPersonales.fechaNacimiento" :max="fecha_maxima" @change="updateValores()" name="fechaNacimiento" id="fechaNacimiento" />
              <span class="text-danger span-placeholder" v-show="errors.has('fechaNacimiento')">Campo requerido</span>
            </div>  
            <div class="col-md-4">
              <span class="span-placeholder" for="nacionalidad">Nacionalidad</span>
              <select name="nacionalidad" id="nacionalidad" v-validate="'required'" v-model="datosPersonales.nacionalidad" @change="updateValores()" class="vs-input">
                <option :value='tipo.value' v-for="tipo in nacionalidades"  :key="">{{tipo.label}}</option>
              </select>
              <span class="text-danger span-placeholder" v-show="errors.has('nacionalidad')">Campo requerido</span>
             <!-- <input class="vs-input" label="Nacionalidad" v-model="nacionalidad" name="username" />-->
           </div>
            <div class="col-md-4" v-if="datosPersonales.nacionalidad=='MEXICANO'">
              <span class="span-placeholder" for="estadoNacimiento">Lugar de nacimiento</span>
              <select name="estadoNacimiento" id="estadoNacimiento" v-model="datosPersonales.estadoNacimiento" @change="updateValores()" class="vs-input">
                <option :value='tipo.Id' v-for="tipo in estados"  :key="">{{tipo.Nombre}}</option>
              </select>
              <!--<span class="text-danger span-placeholder" v-show="errors.has('estadoNacimiento')">Campo requerido</span>-->
             <!-- <input class="vs-input" label="Nacionalidad" v-model="nacionalidad" name="username" />-->
            </div>
        </div>    
        <div class="row w-full">    
            <div class="col-md-4">
                <span class="span-placeholder" for="sexo">Sexo</span>
                  <select name="sexo" id="sexo" v-model="datosPersonales.sexo" v-validate="'required'" class="vs-input" @change="updateValores()">
                    <option value='H' :key="">Hombre</option>
                    <option value="M" :key="">Mujer</option>
                  </select>
                <!--<span class="text-danger span-placeholder" v-show="errors.has('sexo')">El campo debe tener 13 caracteres</span>-->
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">RFC</span>
              <input class="vs-input" v-model="datosPersonales.rfc" name="rfc" v-validate="'min:13|max:13'" maxlength="13" @change="updateValores(); solorfc($event)"/>
              <span class="text-danger span-placeholder" v-show="errors.has('rfc')">El campo debe tener 13 caracteres</span>
              <span class="text-danger span-placeholder" v-if="rfcValido">Estructura de RFC invalida</span>
            </div>  
            <div class="col-md-4" v-if="datosPersonales.nacionalidad=='MEXICANO'">
              <span class="span-placeholder">CURP</span>
              <input class="vs-input" v-model="datosPersonales.curp" name="curp" v-validate="'min:18|max:18'" @change="updateValores()"/>
              <span class="text-danger span-placeholder" v-show="errors.has('curp')">El campo debe tener 18 caracteres</span>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder" for="estadoCivil">Estado Civil</span>
              <select name="estadoCivil" id="estadoCivil" v-model="datosPersonales.estadoCivil" v-validate="'required'" class="vs-input" @change="updateValores()">
                <option :value='tipo.Id' v-for="tipo in estadosCiviles"  :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger span-placeholder" v-show="errors.has('estadoCivil')">El campo es requerido</span>
            </div>
            <div class="col-md-4" v-if="datosPersonales.estadoCivil==1">
              <span class="span-placeholder" for="regimen">Régimen matrimonial</span>
              <select name="regimen" id="regimen" v-validate="'required'" v-model="datosPersonales.regimenMatrimonial" class="vs-input" @change="updateValores()">
                <option :value='tipo.Id' v-for="tipo in regimenes"  :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger span-placeholder" v-show="errors.has('regimen')">El campo es requerido</span>
            </div>
             <div class="col-md-4">
                <span class="span-placeholder" for="dependientes">Dependientes</span>
                <input class="vs-input" v-model="datosPersonales.dependientes" name="dependientes" v-validate="'required|between:0,50'"  maxlength="2" v-on:keypress="isNumber($event)" @change="updateValores()"/>
                <span class="text-danger span-placeholder" v-show="errors.has('dependientes')">El campo debe tener valores del 0 al 50</span>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
                <span class="span-placeholder">Teléfono móvil (10 dígitos)</span>
                <input class="vs-input" v-model="datosPersonales.telefono" name="telefono" v-validate="'required|min:10|max:10'" maxlength="10" v-on:keypress="isNumber($event)" @change="updateValores()"/>
                <span class="text-danger span-placeholder" v-show="errors.has('telefono')">El campo debe tener 10 caracteres</span>
            </div>
            <div class="col-md-4">
                <span class="span-placeholder">Correo electrónico</span>
                <input class="vs-input" v-model="datosPersonales.email" name="correo" v-validate="'required|email'" @change="updateValores()"/>
                <span class="text-danger span-placeholder" v-show="errors.has('correo')">El campo es requerido y debe conener una estructura correcta</span>
            </div>
            <div class="col-md-4">
                <span class="span-placeholder">Compañía movil</span>
                <select name="companiaMovil" id="companiaMovil" v-model="datosPersonales.companiaMovil" v-validate="'required'" class="vs-input" @change="updateValores()">
                <option :value='tipo.Id' v-for="tipo in companiasMovil"  :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger span-placeholder" v-show="errors.has('companiaMovil')">El campo es requerido</span>
            </div>
          </div>
          <div class="row w-full">
             <div class="col-md-4" v-if="datosPersonales.nacionalidad=='EXTRANJERO'">
                <span class="span-placeholder" for="regimen">Forma migratoria</span>
                <select name="formaMigratoria" id="formaMigratoria" v-model="datosPersonales.formaMigratoria" v-validate="'required'" class="vs-input" @change="updateValores()">
                <option :value='tipo.Id' v-for="tipo in formasMigratorias"  :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger span-placeholder" v-show="errors.has('formaMigratoria')">El campo es requerido</span>
            </div>
            <div class="col-md-4 alinear">
                <div class="col-md-8 span-placeholder" for="">Agregar Participante </div>
                <div class="">
                  <vs-switch v-model="datosPersonales.participa" @change="updateValores()"/>
                </div>
                <!-- <ul class="con-s" style="margin-top:5%;margin-left:5%">
                  <li>
                    <span class="span-placeholder" for="">Agregar Participante </span>
                    <vs-switch v-model="datosPersonales.participa" @change="updateValores()"/>
                  </li>
                </ul> -->
              </div>
            <div class="col-md-4" v-if="datosPersonales.participa">
                <span class="span-placeholder" for="parentesco">Parentesco</span>
                <select name="parentesco" id="parentesco" v-model="datosPersonales.parentesco" v-validate="'required'" class="vs-input" @change="updateValores()">
                <option :value='tipo.Id' v-for="tipo in parentescos"  :key="">{{tipo.Nombre}}</option>
              </select>
              <span class="text-danger span-placeholder" v-show="errors.has('parentesco')">El campo es requerido</span>
            </div>
            <div class="col-md-4 alinear" v-if="datosPersonales.participa">
                <div class="col-md-8 span-placeholder" for="">¿Participa con ingresos?</div>
                <div class="" >                    
                  <vs-switch v-model="datosPersonales.participaIngresos" @change="updateValores()"/>
                </div>
                <!-- <ul class="con-s" style="margin-top:5%;margin-left:5%">
                  <li>
                    <span class="span-placeholder" for="">¿Participa con ingresos?</span>
                    <vs-switch v-model="datosPersonales.participaIngresos" @change="updateValores()"/>
                  </li>
                </ul> -->

              </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components:{
    'v-select': vSelect,
  },
  data(){
	return{
    datosPersonales:{
      parentesco:'',
      participa:false,
      participaIngresos:false,
      dependientes:'',
      formaMigratoria:'',
      companiaMovil:'',
      email:'',
      nombre:'',
      apellidoPaterno:'',
      apellidoMaterno:'',
      telefono:'',
      fechaNacimiento:'',
      nacionalidad:'',
      estadoNacimiento:'',
      sexo:'',
      estadoCivil:'',
      regimenMatrimonial:'',
      rfc:'',
      curp:'',
      dependientes:'',
      validaFormMexicano:false,
    },
    fecha_maxima:'',
     rfcValido:false,
     estadosCiviles:[],
     formasMigratorias:[],
     dependientesEco:[],
     casa:'',
     //fechaMaxima:'',
     companiasMovil:[],
      
      id:'',
      key:'',
      email:'',
      parentescos:[],
      regimenes:[],
      nacionalidades:[
        { label: 'MEXICANO',  value: 'MEXICANO' },
        { label: 'EXTRANJERO',  value: 'EXTRANJERO' }
      ],
      estados:[],
      validarRegimen:false,
      validarApellidoMaterno:false,
      validaParentesco:false
    }
  },
  computed:{
    validate: function () {
      if (this.estadoCivil == 1 || this.estadoCivil==4) {
        return this.conyuge.email!='' && this.conyuge.nombre!='' &&
        this.conyuge.apellidoPaterno!='' && this.conyuge.apellidoMaterno!=''
      }else {
        return true
      }
    },
    validate2: function () {
      return this.coacreditado.email != '' && this.coacreditado.parentesco != ''
    },
  },
  watch:{
    /*estadoCivil:function(){
      if (this.estadoCivil == 2 || this.estadoCivil== 3 || this.estadoCivil== 5 || this.estadoCivil== 6) {
        this.regimenMatrimonial=''
        this.conyuge.participaCredito=false
        this.conyuge.email=''
        this.conyuge.nombre=''
        this.conyuge.apellidoPaterno=''
        this.conyuge.apellidoMaterno=''
        this.conyuge.curp=''
        this.conyuge.rfc=''
        this.conyuge.esConyugue=true
        this.conyuge.seraCoPropietario=true
        this.conyuge.consolidaIngresos=true
        this.conyuge.parentesco=1
      }
    },
    /*rfc() {
      const regex = /^([A-Z,Ñ,&,a-z,ñ]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z,a-z|\d]{3})$/
      // const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const matches = regex.test(this.rfc);
      return this.rfc == null || matches ? true:false
    }*/
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    this.validaFechaNacimiento()
    this.dameCaso()
    this.getEstados()
    this.getEstadosCiviles()
    this.getListaRegimenes()
    //this.getListaParentescos()
    //this.fecha()
    this.getCompanyMovil()
    this.getFormasMigratorias()
    this.getParentescos()
   // this.updateValores()
   //this.pruebaIon()
  },
  methods:{  
   /* pruebaIon(){
      // var session_url = 'https://chronos-wiki-support-files.s3.amazonaws.com/v1.2/leads';
      var username = '38ql15s581b5qav9fc7dd604cg';
      var password = 'jphv1erifpebumv69hu6cnc2k44scs91rotk3rgeq0dioeqojk2 ';
      var basicAuth = 'Basic ' + btoa(username + ':' + password);
      var objCampos={
          person_type: "NATURAL",
          product: "LIQUIDITY",
          required_amount: {
            amount: 50000.5,
            currency: "MXN"
          },
          monthly_income: {
          amount: 5000000.0,
          currency: "MXN"
          },
          real_estate_value: {
          amount: 50000.5,
          currency: "MXN"
          },
          term: 20
        }
      this.$axios.post('https://chronos-wiki-support-files.s3.amazonaws.com/v1.2/simulation',objCampos,{ headers: { 'Authorization': + basicAuth }})
      .then(
        response => {
           console.log('Authenticated'+response);
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },*/
    validaFechaNacimiento(){
      var fecha = new Date();
      var anio = fecha.getFullYear();
      var dia = fecha.getDate();
      var _mes = fecha.getMonth(); //viene con valores de 0 al 11
      _mes = _mes + 1; //ahora lo tienes de 1 al 12
      if (_mes < 10) //ahora le agregas un 0 para el formato date
      {
        var mes = "0" + _mes;
      } else {
        var mes = _mes.toString;
      }

      this.fecha_maxima = anio + '-' + mes + '-' + dia; // Nueva variable
    },
    quitarAcentos(s){
      var chars={
        "á":"a", "é":"e", "í":"i", "ó":"o", "ú":"u",
        "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u",
        "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U",
        "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U"}
      var expr=/[áàéèíìóòúù]/ig;
      if(s!=null){
      var res=s.replace(expr,function(e){return chars[e]});
      }
      return res;
    },
    updateValores(){

      if(this.datosPersonales.estadoCivil == 1 && (this.datosPersonales.regimenMatrimonial != '' && this.datosPersonales.regimenMatrimonial !==null)){
        this.validarRegimen =  true
      }else  if(this.datosPersonales.estadoCivil != 1){
        this.validarRegimen =  true
      }else{
        this.validarRegimen =  false
      } 
      //validar nacionalidad y campos adicionales
      if(this.datosPersonales.nacionalidad == 'MEXICANO' && this.datosPersonales.apellidoMaterno != '' && (this.datosPersonales.curp!='' && this.datosPersonales.curp!==null)){
        this.validarApellidoMaterno =  true
      }else if(this.datosPersonales.nacionalidad == 'EXTRANJERO' && (this.datosPersonales.apellidoMaterno !='' || this.datosPersonales.apellidoMaterno == '') && this.datosPersonales.formaMigratoria!='' && this.datosPersonales.formaMigratoria!==null){
        this.validarApellidoMaterno =  true
      }else{
        this.validarApellidoMaterno =  false
      } 
      //validar participacion y campor adicionales
      if(this.datosPersonales.participa === true && this.datosPersonales.parentesco !='' && this.datosPersonales.parentesco !==null){
        this.validaParentesco =true
      }else if(this.datosPersonales.participa === false){
        this.validaParentesco =true
      }else{
        this.validaParentesco =false
      }
      
      this.datosPersonales.validaFormMexicano=(this.datosPersonales.nombre != '' && this.datosPersonales.apellidoPaterno != ''  &&  this.datosPersonales.fechaNacimiento != '' && this.datosPersonales.nacionalidad != '' && this.datosPersonales.estadoNacimiento != '' && this.datosPersonales.sexo != '' && this.datosPersonales.rfc != ''  && this.datosPersonales.estadoCivil !='' && this.datosPersonales.dependientes !='' && this.datosPersonales.TelefonoCelular !='' && this.datosPersonales.email != '' && this.datosPersonales.companiaMovil != '' && this.validarRegimen && this.validarApellidoMaterno && this.validaParentesco) 
    
      this.$emit('updateValores',this.datosPersonales)
    },
    /*fecha(){
     var tiempoTranscurrido = Date.now()
     fechaMaxima = new Date(tiempoTranscurrido);
    //alert(hoy.toLocaleDateString())
    },  */
    getParentescos(){
      let self=this
      var objRequestListaParentescos = {
        strApiKey: this.key,
        strMetodo: 'CatalogosGeneralesBancos',
        objCatalogo:
        {
            TipoCatalogo: "3"
        }
      }
      this.$axios.post('/',objRequestListaParentescos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){ 
            this.parentescos=response.data.objContenido 
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaParentescos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getFormasMigratorias(){
      let self=this
      var objRequestListaFormasMigratorias = {
        strApiKey: this.key,
        strMetodo: 'CatalogosGeneralesBancos',
        objCatalogo:
        {
            TipoCatalogo: "2"
        }
      }
      this.$axios.post('/',objRequestListaFormasMigratorias,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){ 
            this.formasMigratorias=response.data.objContenido 
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaFormasMigratorias',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getCompanyMovil(){
      let self=this
      var objRequestListaCompaniaMovil = {
        strApiKey: this.key,
        strMetodo: 'CatalogosGeneralesBancos',
        objCatalogo:
        {
            TipoCatalogo: "1"
        }
      }
      this.$axios.post('/',objRequestListaCompaniaMovil,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){ 
            this.companiasMovil=response.data.objContenido 
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaCompañiaMovil',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getEstados(){
      let self=this
      var objRequestListaEstados = {
        strApiKey: this.key,
        strMetodo: 'ListaEstados',
      }
      this.$axios.post('/',objRequestListaEstados,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            var allEstados = response.data.objContenido   
            //this.estados=response.data.objContenido 
            for(var i=0;i<allEstados.length;i++){
                if(allEstados[i].Id !== 33){
                    if(allEstados[i].Id == 34){
                        this.estados.push({Nombre:'Estado extranjero', Id: allEstados[i].Id})
                    }else{
                     this.estados.push({Nombre:allEstados[i].Nombre, Id: allEstados[i].Id})
                    }
                }
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstados',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },  
    registraCoacreditado(){
      let self= this
      if (this.coacreditado.email == this.conyuge.email) {
        let objRequestRegistraSolicitante = {
          strApiKey: this.key,
          strMetodo: 'RegistraSolicitante',
          objSolicitante: {
            EMail: this.conyuge.email,
            Nombres: this.conyuge.nombre,
            ApellidoPaterno: this.conyuge.apellidoPaterno,
            ApellidoMaterno: this.conyuge.apellidoMaterno,
            TelefonoCelular: '',
            FechaNacimiento: '',
          }
        }
        this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              this.agregarCoacreditado()
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en RegistraSolicitante',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }

        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      }else {
        if (this.coacreditado.email2) {
          this.agregarCoacreditado()
        }else {
          let objRequestRegistraSolicitante = {
            strApiKey: this.key,
            strMetodo: 'RegistraSolicitante',
            objSolicitante: {
              EMail: this.coacreditado.email,
              Nombres: '',
              ApellidoPaterno: '',
              ApellidoMaterno: '',
              TelefonoCelular: '',
              FechaNacimiento: '',
            }
          }
          this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
          .then(
            response => {
              if(response.data.intEstatus == 0){
                this.agregarCoacreditado()
              }else {
                this.$vs.notify({
                  title:'Ocurrio un error en RegistraSolicitante',
                  text:response.data.strError,
                  color:'danger',
                  position:'top-right'
                })
              }
            }

          ).catch(function (error) {
            self.$vs.notify({
              title:'Ocurrio un error de sistema',
              text:error,
              color:'danger',
              position:'top-right'
            })
          })
        }
      }
    },
    agregarCoacreditado(){
      let self= this
      var objRequestRegistraCoacreditado = {
        strApiKey: this.key,
        strMetodo: 'RegistraCoacreditado',
        objCaso: {
          Id : this.id,
          EMail: this.coacreditado.email,
          DatosCoacreditado:{
            EsConyuge:this.coacreditado.esConyugue,
            SeraCoPropietario:this.coacreditado.seraCoPropietario,
            ConsolidaIngresos:this.coacreditado.consolidaIngresos,
            Parentesco:this.coacreditado.parentesco,
            ParticipaCredito:this.coacreditado.participaCredito
          }
        }
      }
      this.$axios.post('/',objRequestRegistraCoacreditado,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCoacreditado',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    agregaConyugeAsCoacreditado(){
      let self= this
      var objRequestRegistraCoacreditado = {
        strApiKey: this.key,
        strMetodo: 'RegistraCoacreditado',
        objCaso: {
          Id : this.id,
          EMail: this.conyuge.email,
          DatosCoacreditado:{
            EsConyuge:this.conyuge.esConyugue,
            SeraCoPropietario:this.conyuge.seraCoPropietario,
            ConsolidaIngresos:this.conyuge.consolidaIngresos,
            Parentesco:this.conyuge.parentesco,
            ParticipaCredito:this.conyuge.participaCredito
          }
        }
      }
      this.$axios.post('/',objRequestRegistraCoacreditado,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCoacreditado',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    agregaConyuge(){
      if (this.conyuge.email != '') {
        let self= this
        let objRequestRegistraSolicitante = {
          strApiKey: this.key,
          strMetodo: 'RegistraSolicitante',
          objSolicitante: {
            EMail: this.conyuge.email,
            Nombres: this.conyuge.nombre,
            ApellidoPaterno: this.conyuge.apellidoPaterno,
            ApellidoMaterno: this.conyuge.apellidoMaterno,
            TelefonoCelular: '',
            FechaNacimiento: '',
            Rfc:this.conyuge.rfc,
            Curp:this.conyuge.curp
          }
        }
        this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              if (this.conyuge.participaCredito) {
                this.agregaConyugeAsCoacreditado()
              }
              this.actualizarInfo()
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en RegistraSolicitante',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      }else {
        this.actualizarInfo()
      }
    },
    actualizarInfo(){
      let self= this
      let sexo
      if(this.sexo== 'H'){
        sexo=1
      }else{
        sexo=2
      }
      let objRequestRegistraSolicitante = {
        strApiKey: this.key,
        strMetodo: 'RegistraSolicitante',
        objSolicitante: {
          EMail: this.email,
          Nombres: this.nombre,
          ApellidoPaterno: this.apellidoPaterno,
          ApellidoMaterno: this.apellidoMaterno,
          TelefonoCelular: this.telefono,
          FechaNacimiento: this.fechaNacimiento,
          Rfc: this.rfc,
          Curp: this.curp,
      		Sexo:sexo,
      		Calle:this.calle,
      		NumeroExterior:this.numeroExterior,
          NumeroInterior:this.numeroInterior,
      		Colonia:this.colonia,
      		Municipio:this.municipio,
      		Ciudad:this.ciudad,
      		Estado:this.estado,
      		CodigoPostal:this.codigoPostal,
          Nacionalidad:this.nacionalidad,
          EstadoCivil:this.estadoCivil,
          RegimenMatrimonial:this.regimenMatrimonial,
          Conyuge: this.conyuge.email,
        }
      }
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
          .then(
            response => {
              if(response.data.intEstatus == 0){
                this.$vs.notify({
                  title:`Datos guardados exitosamente`,
                  position:'top-right'
                })
              }else {
                this.$vs.notify({
                  title:'Ocurrio un error en RegistraSolicitante',
                  text:response.data.strError,
                  color:'danger',
                  position:'top-right'
                })
              }
            }

          ).catch(function (error) {
            self.$vs.notify({
              title:'Ocurrio un error de sistema',
              text:error,
              color:'danger',
              position:'top-right'
            })
          })
        } else {
          this.$vs.notify({
            title:'Ocurrio un error',
            text:'Datos incompletos',
            color:'danger',
            position:'top-right'
          })
        }
      })
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.email= response.data.objContenido.Solicitante
            this.datosPersonales.email=this.email
            //let coacreditado=response.data.objContenido.Coacreditado
            /*if (coacreditado.length) {
              if (coacreditado[0].EsConyuge && coacreditado[0].ParticipaCredito) {
                this.conyuge.esConyugue=coacreditado[0].EsConyuge
                this.conyuge.email=coacreditado[0].EMail
                this.conyuge.seraCoPropietario=coacreditado[0].SeraCoPropietario
                this.conyuge.consolidaIngresos=coacreditado[0].ConsolidaIngresos
                this.conyuge.parentesco=coacreditado[0].Parentesco
                this.conyuge.participaCredito=coacreditado[0].ParticipaCredito
              }else {
                this.coacreditado.esConyugue=coacreditado[0].EsConyuge
                this.coacreditado.email2=coacreditado[0].EMail
                this.coacreditado.email=coacreditado[0].EMail
                this.coacreditado.seraCoPropietario=coacreditado[0].SeraCoPropietario
                this.coacreditado.consolidaIngresos=coacreditado[0].ConsolidaIngresos
                this.coacreditado.parentesco=coacreditado[0].Parentesco
                this.coacreditado.participaCredito=coacreditado[0].ParticipaCredito
              }
            }*/
            this.dameSolicitante()
            
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameConyuge(email){
      let self= this
      var objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
      	 EMail: email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.dameDatosPersonales()
            let conyuge =response.data.objContenido
            this.conyuge.nombre= conyuge.Nombres
            this.conyuge.email= conyuge.EMail
            this.conyuge.apellidoPaterno= conyuge.ApellidoPaterno
            this.conyuge.apellidoMaterno= conyuge.ApellidoMaterno
            this.conyuge.rfc=conyuge.Rfc
            this.conyuge.curp = conyuge.Curp
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self= this
      var objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
      	 EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let solicitante =response.data.objContenido
            this.datosPersonales.nombre= this.quitarAcentos(solicitante.Nombres)
            this.datosPersonales.apellidoPaterno= this.quitarAcentos(solicitante.ApellidoPaterno)
            this.datosPersonales.apellidoMaterno= this.quitarAcentos(solicitante.ApellidoMaterno)
            this.datosPersonales.telefono= solicitante.TelefonoCelular.replace(/\s+/g, '').replace(/-/g, '')
           // this.fechaNacimiento= solicitante.FechaNacimiento no empatar
            this.datosPersonales.estadoNacimiento=solicitante.EstadoNacimiento
            this.datosPersonales.rfc=solicitante.Rfc
            if(solicitante.Sexo== 1){
              this.datosPersonales.sexo='H'
            }else if (solicitante.Sexo== 2) {
              this.datosPersonales.sexo='M'
            }
            this.datosPersonales.calle = solicitante.Calle
            this.datosPersonales.numeroExterior = solicitante.NumeroExterior
            this.datosPersonales.numeroInterior = solicitante.NumeroInterior
            this.datosPersonales.colonia = solicitante.Colonia
            this.ciudad = solicitante.Ciudad
            this.datosPersonales.municipio = solicitante.Municipio
            this.datosPersonales.curp = solicitante.Curp
            this.datosPersonales.codigoPostal = solicitante.CodigoPostal
            this.estado = solicitante.Estado
            //this.estadoCivil=solicitante.EstadoCivil
           // this.regimenMatrimonial=solicitante.RegimenMatrimonial
            this.datosPersonales.nacionalidad=solicitante.Nacionalidad
             this.dameDatosPersonales()
            /*if (solicitante.Conyuge != null && (this.estadoCivil== 1 || this.estadoCivil== 4)) {
              this.dameConyuge(solicitante.Conyuge)
            }*/
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameDatosPersonales(){
      let self= this
      var objRequestDameSolicitante = {
         strApiKey: this.key,
        strMetodo: "DamePrecalificacionBancos",
        objPrecalificacionBanco:
        {
            IdPrecalificacion: this.id
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let solicitante =response.data.objContenido
            this.datosPersonales.fechaNacimiento=solicitante[0].FechaNacimiento
            this.datosPersonales.estadoCivil=solicitante[0].EstadoCivil
            this.datosPersonales.regimenMatrimonial=solicitante[0].RegimenMatrimonial
            this.datosPersonales.dependientes=solicitante[0].DependientesEconomicos
            this.datosPersonales.participa=solicitante[0].AgregarParticipante
            this.datosPersonales.participaIngresos=solicitante[0].ParticipaIngresos
            this.datosPersonales.companiaMovil=solicitante[0].SantanderExCompaniaTelefonica
            this.datosPersonales.parentesco=solicitante[0].SantanderExParentesco
            this.datosPersonales.email=solicitante[0].EMail
            this.datosPersonales.formaMigratoria=solicitante[0].SantanderExFormaMigratoria
            this.updateValores()
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    getEstadosCiviles(){
      let self= this
      var objRequestListaEstadosCiviles = {
        strApiKey: this.key,
        strMetodo: 'ListaEstadosCiviles',
      }
      this.$axios.post('/',objRequestListaEstadosCiviles,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            var allEstadosCiviles= response.data.objContenido
            for(var i=0; i<allEstadosCiviles.length;i++){
              if(allEstadosCiviles[i].Id!==6){
               this.estadosCiviles.push({Nombre:allEstadosCiviles[i].Nombre, Id: allEstadosCiviles[i].Id})
              } 
            }
            //this.estadosCiviles=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstadosCiviles',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getStateName(id){
      return this.states[id-1]
    },
    getStateNumber(stateName){
      for (var i = 0; i < this.states.length; i++) {
        if(stateName == this.states[i]){
          return i+1
        }
      }
    },
    getListaParentescos(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaParentescos',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.parentescos=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaParentescos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaRegimenes(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaRegimenesMatrimoniales',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
             var allRegimenes= response.data.objContenido
            for(var i=0; i<allRegimenes.length;i++){
              if(allRegimenes[i].Id!==3){
               this.regimenes.push({Nombre:allRegimenes[i].Nombre, Id: allRegimenes[i].Id})
              } 
            }
            //this.regimenes=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaRegimenesMatrimoniales',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    soloLetras: function(event) {
        var regex = new RegExp("^[áéíóúüña-zÑÁÉÍÓÚA-Z ]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
          event.preventDefault();
          return false;
        }
    },
    solorfc: function() {
      const regex = /^([A-Z,Ñ,&,a-z,ñ]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z,a-z|\d]{3})$/
      // const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const matches = regex.test(this.datosPersonales.rfc);
      if(matches){
        this.rfcValido=false
      }else{
        this.rfcValido=true
      }

    },
  }
}
</script>

<style lang="css">
.inputMayus{
    text-transform: uppercase;
}
.red{
  color: red;
}
</style>
